import { OrgList, CompanyDetail, CustomerDetail } from "./org";
import { RouteProps } from "@models";
import Store from "./store";

const orgRoutes: RouteProps[] = [
  { path: "/org/customers", component: OrgList, private: true },
  { path: "/org/orgs", component: OrgList, private: true },
  {
    path: "/org/company/:id",
    component: CompanyDetail,
    private: true,
    isCancelCache: true,
  },
  {
    path: "/org/client/:id",
    component: CustomerDetail,
    private: true,
    isCancelCache: true,
  },
  { path: "/org/store", component: Store, private: true },
];

export { orgRoutes };
