import React, { useState, useEffect } from 'react';
import Select, { Props as SelectProps } from '../Select';

import { Context, Http } from '@utils';
const { Option } = Select;

interface Props extends SelectProps<string> {
  setFormOrgNameValue: (orgNameVal: any) => void,
  getDeptList?: (deptList: JSONObject[]) => void,
  isKeyAsValue?: boolean,
  isShowOptionTitle?: boolean,
  nameOrId?: any,
  mul?: boolean,
  single?: boolean, // 是否进行单体公司控制,默认为是
};

const DeptSelect = ({
  getDeptList,
  setFormOrgNameValue,
  isKeyAsValue = true,
  isShowOptionTitle = true,
  nameOrId,
  mul = false,
  single = true,
  ...resetProps
}: Props) => {

  const [options, setOptions] = useState<{k: string, v: string}[]>([]);
  const [deptNameOrId, setDeptNameOrId] = useState<string | string[]>();

  const [isOpen, setIsOpen] = useState(true);

  const filterOption = (input: any, option: any) => {
    return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
  };

  const getCompanyOption = async() => {
    await Http.get('/org/orgs/kv')
      .then((res) => {
        setOptions(res);
        getDeptList && getDeptList(res)
      });
  };

  const getCurrentDeptInfo = () => {
    const { orgId, deptName } = Context.getContext();
    const _deptNameOrId: string =
    isKeyAsValue
      ? orgId
      : (deptName === '大自然集团' ? '' : deptName);

      _deptNameOrId && setDeptNameOrId(mul ? [_deptNameOrId] : _deptNameOrId);
    setFormOrgNameValue(mul ? [_deptNameOrId] : _deptNameOrId);
    single && setIsOpen(deptName === '大自然集团');
  };

  const handleSelectChange = (option: any) => {
    setDeptNameOrId(option);
    setFormOrgNameValue(option);
  };

  useEffect(() => {
    ((async() => {
      await getCompanyOption();
      getCurrentDeptInfo();
    })())
    
  }, []);

  useEffect(() => {
    if (nameOrId) {
      setDeptNameOrId(nameOrId)
      setFormOrgNameValue(nameOrId)
    }
  }, [nameOrId])

  return (
    <Select {...resetProps} value={deptNameOrId}
            filterOption={filterOption}
            {...(isOpen ? { allowClear: true } : { open: false, allowClear: false })}
            showSearch
            onChange={handleSelectChange}>
      {
        options.map((option) => {
          return (
            <Option key={option.k}
                    // {...(mul && Context.getContext().deptName !== '大自然集团' && ({disabled: true}))}
                    value={isKeyAsValue ? option.k : option.v}
                    title={isShowOptionTitle ? option.v : ''}>{option.v}
            </Option>
          )
        })
      }
    </Select>
  );
};

export default DeptSelect;
