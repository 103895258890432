import React, { useState, FC } from 'react';
import { Button, Form, DatePicker, Select, Input, Popconfirm } from 'antd';

import { Page, Card, Table, Modal } from '@comps';

import { Http, Message, DateUtils } from '@utils'; 

import { ColumnProps } from '@comps/types'; 

const { Item } = Form;
const { RangePicker } = DatePicker;
const { Option } = Select;

const Rebate: FC = () => {

  const [deleteLoading, setDeleteLoading] = useState(false);

  let [table, setTable] = useState<Table>();

  const orderDateFrom = [
    DateUtils.monthFirstDay(), 
    DateUtils.monthLastDay(),
  ];

  const onTableRef = (tableRef: Table) => {
    setTable(tableRef);
    table = tableRef;
  };

  const handleDelete = (ids: number[]) => {
    Http.del('', ids)
      .then(() => {
        Message.success('删除成功');
        table?.refresh();
      })
      .finally(() => {
        setDeleteLoading(false);
      })
  };

  const handleBatchDelete = () => {
    const ids = table?.getSelectedRowKeys() ?? [];
    const idsLength = ids.length;

    if (idsLength === 0) {
      Message.warn('请至少勾选一条数据');
      return;
    };

    Modal.confirm({
      title: '删除多条记录',
      content: `是否确认删除 ${idsLength} 条记录`,
      onOk: () => {
        setDeleteLoading(true);
        handleDelete(ids);
      }
    });
  };
  
  const columns: ColumnProps[] = [
    { key: 'orderNo', title: '单据号', width: 160, fixed: 'left' },
    { key: 'businessType', title: '业务类型', width: 140,
      render: (businessType: string) => (
        businessType === 'DISCOUNT' ? '折扣单据' : '返利单据'
      )
    },
    { key: 'customerName', title: '客户名称', width: 230 },
    { key: 'customerCode', title: '客户编码', width: 140 },
    { key: 'description', title: '摘要', width: 330 },
    { key: 'withTaxAmount', title: '金额', width: 150, format: 'amount' },
    { key: 'invoicedAmount', title: '已核销金额', width: 150, format: 'amount' },
    { key: 'remainAmount', title: '未核销金额', width: 150, format: 'amount' },
    { key: 'orderDate', title: '业务订单创建日期', width: 200, align: 'center' },
    { key: 'sourceSystem', title: '来源系统', width: 140 }
  ];
  
  return (
    <Page>
      <Card 
        title="折扣返利单据" 
      >
        <Table
          onTableRef={onTableRef}
          url="/outputPolicyOrder"
          queryProps={{
            initialValues: {
              orderDateFrom
            }
          }}
          columns={columns}
          scroll={{ y: window.innerHeight - 300 }}
        >
          <Item name="orderDateFrom">
            <RangePicker placeholder={['单据日期从', '单据日期至']} inputReadOnly/>
          </Item>
          <Item name="orderNo">
            <Input placeholder="单据号" />
          </Item>
          <Item name="businessType">
            <Select placeholder="业务类型">
              <Option value="DISCOUNT">折扣单据</Option>
              <Option value="RETURN">返利单据</Option>
            </Select>
          </Item>
          <Item name="customer">
            <Input placeholder="客户名称/客户编码" />
          </Item>
        </Table>
      </Card>
    </Page>
  );
};

export default Rebate;