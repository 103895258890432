import React from 'react';
import { Space, Form, Input, InputNumber } from 'antd';
import { Card, Button } from '@comps'
import { Http, Message } from '@utils'

const Item = Form.Item

const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 12 },
};

export interface Props {
    onCancel: () => void,
    onConfirm: () => void,
    goods?: JSONObject,
}

const Add: React.FC<Props> = ({
    onCancel, onConfirm, goods = {},
}) => {
    const [form] = Form.useForm();

    if (!goods.id) {
        goods.conversionRate = 1
    }

    async function handleSave() {
        await form.validateFields()
        const submitData = {
            id: goods?.id,
            ...form.getFieldsValue()
        };
        if (submitData.id) {
            await Http.put('/tax/goodsConversion', submitData);
        } else {
            await Http.post('/tax/goodsConversion', submitData);
        }
        Message.ok();
        onConfirm();
    }

    return (
        <Card title='商品对应表' style={{ marginBottom: '0' }}>
            <Form {...layout} form={form} initialValues={goods}>
                <Item label="ERP商品名称" name='srcName' rules={[{ required: true }]}><Input /></Item>
                <Item label="ERP规格型号" name='srcSpec' rules={[{ required: true }]}><Input /></Item>
                <Item label="ERP单位" name='srcUnit'><Input /></Item>
                <Item label="商品名称" name='newName' rules={[{ required: true }]}><Input /></Item>
                <Item label="规格型号" name='newSpec' rules={[{ required: true }]}><Input /></Item>
                <Item label="单位" name='newUnit'><Input /></Item>
                <Item label="对换率" name='conversionRate' rules={[{ required: true }]}><InputNumber /></Item>
                <Item label="对碰客户名称" name='customerName'><Input /></Item>
                <Item label="商品编码" name='goodsCode'><Input /></Item>

                <div style={{ textAlign: 'center' }}>
                    <Space>
                        <Button type="primary" clickCallback={handleSave}>保存</Button>
                        <Button onClick={onCancel}>取消</Button>
                    </Space>
                </div>
            </Form>
        </Card>
    );
};

export default Add;
