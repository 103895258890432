import React, { FC, useState } from 'react';
import { Space, Form, Input, DatePicker, Select, Col } from 'antd';
import moment from 'moment';

import { Page, Card, Button, Table, SBUSelect, TableSummaryRow, PermissionButton } from '@comps';

import { FormUtil, DateUtils, Math } from '@utils';
import { ColumnProps } from '@comps/types';

const { Item } = Form;
const { Option } = Select;
const { RangePicker } = DatePicker;

const OutputDetail: FC = () => {
  let [table, setTable] = useState<Table>();
  const [totalRemainQuantity, setTotalRemainQuantity] = useState(0);
  const [totalRemainAmount, setTotalRemainAmount] = useState(0);
  const [totalInvoicedQuantity, setTotalInvoicedQuantity] = useState(0);
  const [totalInvoicedAmount, setTotalInvoicedAmount] = useState(0);
  const [totalQuantity, setTotalQuantity] = useState(0);
  const [totalOrderAmount, setTotalOrderAmount] = useState(0);

  const currentPeriod = moment();

  const onTableRef = (tableRef: Table) => {
    setTable(tableRef);
    table = tableRef;
  };

  const getValidTableParams = () => {
    const formParams = table?.getQueryPanelParams() ?? {};
    const { period, shipmentDate } = formParams;

    // if (period) {
    //   formParams.period = DateUtils.momentToStr(period, 'YYYY-MM');
    // }

    // if (shipmentDate) {
    //   formParams.shipmentDate = DateUtils.momentToStr(shipmentDate, 'YYYY-MM-DD');
    // }

    return formParams;
  };

  const period = [
    DateUtils.monthFirstDay(), 
    DateUtils.monthLastDay(),
  ];

  const exportReport = () => {
    const formParams = getValidTableParams();
    const ids = table?.getSelectedRowKeys();

    FormUtil.exportExcelFile(
      '/outputReport/exportLineReport',
      {
        ...formParams,
        ids,
      }
    );
  };

  const columns: ColumnProps[] = [
    { key: 'goodsNo', title: '产品编码', width: 180, fixed: 'left' },
    { key: 'contractNo', title: '合同号', width: 160 },
    { key: 'orgName', title: '公司名称', width: 250 },
    { key: 'period', title: '会计期间', width: 150 },
    { key: 'voucherNo', title: '总账凭证号', width: 150 },
    { key: 'orderNo', title: '销售订单号', width: 150 },
    { key: 'orderDate', title: '业务单据日期', width: 150 },
    { key: 'orderTypeDesc', title: '单据类型', width: 150 },
    { key: 'sourceSystem', title: '单据来源系统', width: 150 },
    { key: 'shipmentDate', title: '发货日期', width: 150 },
    { key: 'customerCode', title: '客户编码', width: 150 },
    { key: 'customerName', title: '客户名称', width: 220 },
    { key: 'goodsNo', title: '产品编码', width: 220 },
    { key: 'largeCategory', title: '物料大类', width: 150 },
    { key: 'mediumCategory', title: '物料中类', width: 150 },
    { key: 'smallCategory', title: '物料小类', width: 200 },
    { key: 'goodsName', title: '物料描述', width: 450 },
    { key: 'sbuName', title: 'SBU 名称', width: 150 },
    { key: 'sbuCode', title: 'SBU 代码', width: 150 },
    { key: 'quantity', title: '数量', width: 150, align: 'right' },
    { key: 'auxQuantity', title: '辅数量', width: 150, align: 'right' },
    { key: 'price', title: '订单单价', width: 150, align: 'right' },
    { key: 'amount', title: '订单金额', width: 150, align: 'right' },
    { key: 'accountant', title: '创建会计', width: 180, align: 'center' },
    { key: 'sbuCode', title: 'SBU 编码', width: 220 },
    { key: 'requestNo', title: '开票申请单号', width: 180 },
    { key: 'requestor', title: '申请人', width: 150 },
    { key: 'requestStatus', title: '开票申请状态', width: 150 },
    { key: 'customerCompanyName', title: '客户公司名称', width: 250 },
    { key: 'customerTaxNo', title: '客户购方税号', width: 180 },
    { key: 'bankAccount', title: '开户银行账号', width: 220 },
    { key: 'addrTel', title: '地址及电话', width: 300 },
    { key: 'withoutTaxAmount', title: '不含税金额', width: 150, align: 'right' },
    { key: 'taxAmount', title: '税额', width: 150, align: 'right' },
    { key: 'withTaxAmount', title: '含税金额', width: 150, align: 'right' },
    { key: 'invoiceCode', title: '发票代码', width: 200, align: 'center' },
    { key: 'invoiceNo', title: '发票号码', width: 150 },
    { key: 'invoiceTypeDesc', title: '发票类型', width: 150 },
    { key: 'invoiceDate', title: '开票日期', width: 100 },
    { key: 'invoicingQuantity', title: '开票中数量', width: 150, align: 'right' },
    { key: 'invoicingAmount', title: '开票中含税金额', width: 150, align: 'right' },
    { key: 'invoicedQuantity', title: '已开数量', width: 150, align: 'right' },
    { key: 'remainQuantity', title: '未开数量', width: 150, align: 'right' },
    { key: 'invoicedAmount', title: '已开含税金额', width: 130, align: 'right', fixed: 'right' },
    { key: 'remainAmount', title: '未开含税金额', width: 130, align: 'right', fixed: 'right' },
  ];

  const setTableSummaryData = ({
    totalRemainQuantity,
    totalRemainAmount, 
    totalInvoicedQuantity,
    totalInvoicedAmount,
    totalQuantity,
    totalOrderAmount

  }: JSONObject) => {

    setTotalRemainQuantity(totalRemainQuantity);
    setTotalRemainAmount(totalRemainAmount);
    setTotalInvoicedQuantity(totalInvoicedQuantity);
    setTotalInvoicedAmount(totalInvoicedAmount);
    setTotalQuantity(totalQuantity);
    setTotalOrderAmount(totalOrderAmount);
  };

  const tableSummary = () => {
    return (
      <>
        <TableSummaryRow>
          <Col span={4}>
            合计未开数量: {Math.numThousandth(totalRemainQuantity)}
          </Col>
          <Col span={4}>
            合计未开含税金额: {Math.num2money(totalRemainAmount)}
          </Col>
          <Col span={4}>
            合计已开数量: {Math.numThousandth(totalInvoicedQuantity)}
          </Col>
          <Col span={4}>
            合计已开含税金额: {Math.num2money(totalInvoicedAmount)}
          </Col>
          <Col span={4}>
            合计总数量: {Math.numThousandth(totalQuantity)}
          </Col>
          <Col span={4}>
            合计订单金额: {Math.num2money(totalOrderAmount)}
          </Col>
        </TableSummaryRow>
     </> 
    );
  };

  return (
    <Page>
      <Card
        title="销售开票情况明细表"
        actions={
          <Space>
            <PermissionButton
              permissions='OutputReport.exportLineReport'
              type="primary"
              onClick={exportReport}
            >
              导出
            </PermissionButton>
          </Space>
        }
      >
        <Table
          onTableRef={onTableRef}
          columns={columns}
          url="/outputReport/queryOutputLine"
          queryProps={{
            initialValues: {
              period
            }
          }}
          // dateFormat={{
          //   period: 'YYYY-MM',
          // }}
          selectedRowKeys={[]}
          summary={tableSummary}
          setTableSummaryData={setTableSummaryData}
          scroll={{ y: window.innerHeight - 300 }}
        >
           {/* <Item name="period">
            <DatePicker
              placeholder="所属期间"
              picker="month"
              style={{ width: '100%' }}
            />
          </Item> */}
          <Item name="period">
            <RangePicker
              placeholder={['所属日期从', '所属日期至']}
              format="YYYY-MM"
              picker="month"
              inputReadOnly
            />
          </Item>
          <Item name="shipmentDateFromTo">
            <RangePicker
              placeholder={['发货日期从', '发货日期至']}
              format="YYYY-MM-DD"
              inputReadOnly
            />
          </Item>
          <Item name="contractNo">
            <Input placeholder="合同号" />
          </Item>
          <Item name="customerCode">
            <Input placeholder="客户编码" />
          </Item>
          <Item name="customerCompanyName">
            <Input placeholder="客户公司名称" />
          </Item>
          <Item name="customerName">
            <Input placeholder="客户名称" />
          </Item>
          <Item name="customerTaxNo	">
            <Input placeholder="客户购方税号" />
          </Item>
          <Item name="goodsName">
            <Input placeholder="商品描述" />
          </Item>
          <Item name="goodsNo">
            <Input placeholder="产品编码" />
          </Item>
          <Item name="invoiceCode">
            <Input placeholder="发票代码" />
          </Item>
          <Item name="invoiceNo">
            <Input placeholder="发票号码" />
          </Item>
          <Item name="sbuIds">
            <SBUSelect />
          </Item>
          <Item name="sourceSystem">  
              <Select placeholder="单据来源系统" allowClear>
                <Option value="KA">KA</Option>
                <Option value="ERP">ERP</Option>
                <Option value="LINK">LINK</Option>
                <Option value="EXCEL">EXCEL</Option>
              </Select>
            </Item>
        </Table>
      </Card>
    </Page>
  )
};

export default OutputDetail;
