import { Col, DatePicker, Form, Input, InputNumber, Select, Space } from "antd";
import React, { FC, useEffect, useRef, useState } from "react";

import {
  Card,
  Modal,
  Page,
  PermissionButton,
  Table,
  TableSummaryRow,
} from "@comps";

import { ColumnProps } from "@comps/types";
import { Api, Context, DateUtils, FormUtil, Http, Math } from "@utils";

const { Item } = Form;
const { RangePicker } = DatePicker;

const InvoiceDetailsManagement: FC = () => {
  const [totalQuantity, setTotalQuantity] = useState(0);
  const [modal, modalHolder] = Modal.useModal();
  const [taxDate, setTaxDate] = useState<any[]>(["", ""]);
  const [invoicer, setInvoicer] = useState<string>("");
  const [deptList, setDeptList] = useState<any[]>([]);
  const [empOptions, setEmpOptions] = useState<any[]>();
  const [taxRateList, setTaxRateList] = useState<any[]>([]);
  const { permissions, deptId } = Context.getContext();
  const applyListPermissions = ["OutputInvoice.dept"];
  const hasPermissions = applyListPermissions.some((permission) =>
    permissions.includes(permission)
  );

  let [table, setTable] = useState<Table>();
  const onTableRef = (tableRef: Table) => {
    table = tableRef;
    setTable(tableRef);
  };

  const handleTaxDate = (date: any, dateString: string[]) => {
    date
      ? setTaxDate([DateUtils.date2int(date[0]), DateUtils.date2int(date[1])])
      : setTaxDate(["", ""]);
  };
  const resetEmptyFileds = () => {
    // setTaxDate(["", ""]);
    setInvoicer("");
    return {
      // invoiceDateFromTo: "",
      // invoiceDateFrom: "",
      invoicer: "",
    };
  };

  useEffect(() => {
    (async () => {
      let newTaxRateList: any[] = [];
      await Http.get("/tax/rates/kv").then((res) => {
        res.map((item: JSONObject) => {
          newTaxRateList.push({
            label: item.v,
            value: item.k,
          });
        });
        setTaxRateList(newTaxRateList);
      });
    })();
  }, [table]);
  useEffect(() => {
    (async () => {
      await Api.getKv("/sys/users/deptEmpKv").then((data) => {
        setEmpOptions(data);
      });
    })();
  }, [table]);

  useEffect(() => {
    (async () => {
      let newDeptList: any[] = [];
      let newDeptIds: number[] = [];
      await Http.get("/tax/outputInvoices/dept").then((res) => {
        res.map((item: JSONObject) => {
          newDeptList.push({
            label: item.deptName,
            value: item.id,
          });
          newDeptIds.push(item.id);
        });
        setDeptList(newDeptList);
      });
    })();
  }, [table]);

  const handleExport = (isSummary: boolean) => {
    const ids = table?.getSelectedRowKeys();

    let url = "";
    if (isSummary) {
      url = "/tax/outputInvoices/sumExportManger";
    } else {
      url = "/tax/details/detailExportManger";
    }

    const tableFormData: JSONObject = table?.getQueryPanelParams() ?? {};

    FormUtil.exportFileByPost(url, {
      ...tableFormData,
      invIds: ids,
    });
  };

  const setTableSummaryData = ({ total }: JSONObject) => {
    setTotalQuantity(total);
  };

  const columns: ColumnProps[] = [
    { key: "invoiceCode", title: "发票代码", width: 120, fixed: "left" },
    {
      key: "invoiceNo",
      dataIndex: "invoiceNo",
      title: "发票号码",
      width: 190,
    },
    { key: "electricNo", title: "数电号码", width: 190 },
    { key: "invoiceType", title: "发票类型", width: 210 },
    { key: "industryNo", title: "业态名称", width: 140 },
    { key: "invoiceDate", title: "开票日期", width: 100 },
    { key: "lineNum", title: "商品行号", width: 100 },
    { key: "goodsName", title: "商品名称", width: 120 },
    { key: "invoiceGoodsName", title: "票面商品名称", width: 120 },
    { key: "spec", title: "规格型号", width: 100 },
    { key: "unit", title: "计量单位", width: 100 },
    { key: "quantity", title: "数量", width: 100 },
    { key: "price", title: "不含税单价", width: 150 },
    { key: "amount", title: "不含税金额", width: 150 },
    { key: "taxRate", title: "税率", width: 100 },
    { key: "taxAmount", title: "税额", width: 100 },
    { key: "withTaxAmount", title: "价税合计", width: 100 },
    { key: "taxCode", title: "税收分类编码", width: 200 },
    {
      key: "statusCode",
      title: "发票状态",
      width: 100,
      tag: {
        colors: {
          正常: "green",
          已作废: "grey",
          被红冲: "red",
        },
      },
    },
    { key: "noteNO", dataIndex: "noteNo", title: "小票号", width: 100 },
    { key: "buyerName", title: "购方名称", width: 240 },
    { key: "buyerCode", title: "购方编码", width: 240 },
    { key: "buyerTaxNo", title: "购方税号", width: 200 },
    { key: "buyerBank", title: "购方银行账号", width: 200 },
    { key: "buyerAddrTel", title: "购方地址电话", width: 200 },
    { key: "cashier", title: "收款人", width: 100 },
    { key: "checker", title: "复核人", width: 100 },
    { key: "invoicer", title: "开票人", width: 100 },
    { key: "remark", title: "备注", width: 200 },
    { key: "sellerName", title: "销方名称", width: 240 },
    { key: "sellerTaxNo", title: "销方税号", width: 200 },
    { key: "sellerBank", title: "销售方银行账户", width: 200 },
    { key: "sellerAddrTel", title: "销方地址电话", width: 200 },
    { key: "machineNo", title: "开票机号", width: 100 },
    { key: "checkCode", title: "校验码", width: 100 },
    { key: "pdfUrl", title: "电子发票下载地址", width: 200 },
  ];

  const totalAmountRef = useRef(0);
  const totalTaxAmountRef = useRef(0);
  const totalWithTaxAmountRef = useRef(0);
  const getOtherTableInfo = (res: JSONObject) => {
    totalAmountRef.current = res.totalAmount;
    totalTaxAmountRef.current = res.totalTaxAmount;
    totalWithTaxAmountRef.current = res.totalWithTaxAmount;
  };

  const tableSummary = (invoices: JSONObject[], selectIds: number[] = []) => {
    const selectInvoices = invoices.filter((invoice) =>
      selectIds?.includes(invoice.id)
    );
    const invoiceTotal = selectInvoices.reduce(
      (total, invoice) => {
        console.log("invoice :>> ", invoice);
        total.amount += Number(invoice.amount);
        total.taxAmount += Number(invoice.taxAmount);
        return total;
      },
      {
        amount: 0,
        taxAmount: 0,
      }
    );
    return (
      <TableSummaryRow>
        <Col span={4}>总数量: {Math.numThousandth(totalQuantity)}</Col>
        <Col span={4}>
          选中不含税金额: {Math.num2money(invoiceTotal.amount)}
        </Col>
        <Col span={4}>选中税额: {Math.num2money(invoiceTotal.taxAmount)}</Col>
        <Col span={4}>
          本页查询结果不含税金额: {Math.num2money(totalAmountRef.current)}
        </Col>
        <Col span={4}>
          本页查询结果含税金额: {Math.num2money(totalWithTaxAmountRef.current)}
        </Col>
        <Col span={4}>
          本页查询结果税额: {Math.num2money(totalTaxAmountRef.current)}
        </Col>
      </TableSummaryRow>
    );
  };
  const handleCreatedBy = (v: string, object: JSONObject) => {
    if (object) {
      let label = object?.label;
      setInvoicer(label?.split(" | ")[0]);
    } else {
      setInvoicer("");
    }
  };
  const invoiceDateFromTo = [
    DateUtils.monthFirstDay(),
    DateUtils.monthLastDay(),
  ];
  return (
    <Page>
      <Card
        title="发票明细管理"
        actions={
          <Space>
            <PermissionButton
              type="primary"
              permissions="OutputInvoiceDetail.detailExportManger"
              onClick={() => handleExport(false)}
            >
              明细导出
            </PermissionButton>
          </Space>
        }
      >
        {modalHolder}
        <Table
          onTableRef={onTableRef}
          url="/tax/details"
          columns={columns}
          elementSizeOfRow={6}
          isExpandAllItem={true}
          queryProps={{
            initialValues: {
              invoiceDateFromTo,
              deptIdList: deptId,
            },
          }}
          formAdditionalParameters={{
            invoiceDateFrom: taxDate[0],
            invoiceDateTo: taxDate[1],
            invoicer,
          }}
          getOtherTableInfo={getOtherTableInfo}
          selectedRowKeys={[]}
          resetEmptyFileds={resetEmptyFileds}
          pagination={{ hideOnSinglePage: false }}
          setTableSummaryData={setTableSummaryData}
          scroll={{ y: window.innerHeight - 300 }}
          summary={tableSummary}
        >
          <Item name="invoiceNo">
            <Input placeholder="发票号码" title="发票号码" />
          </Item>
          <Item name="buyerName">
            <Input placeholder="购方名称" title="购方名称" />
          </Item>
          <Item name="goodsName">
            <Input placeholder="商品名称" title="商品名称" />
          </Item>
          <Item name="bizNo">
            <Input placeholder="小票号" />
          </Item>
          <Item name="createdBy">
            {/* <Input placeholder="开票人" /> */}
            <Select
              placeholder="开票人"
              showSearch
              allowClear
              optionFilterProp="label"
              onChange={handleCreatedBy}
              options={empOptions}
            />
          </Item>
          <Item name="invoiceDateFromTo">
            <RangePicker
              format="YYYYMMDD"
              placeholder={["开票日期从", "开票日期至"]}
              inputReadOnly
              onChange={handleTaxDate}
              defaultValue={[
                DateUtils.monthFirstDay(),
                DateUtils.monthLastDay(),
              ]}
              style={{ width: "100%" }}
            />
          </Item>
          <Item name="taxRate">
            <Select placeholder="税率" options={taxRateList} allowClear />
          </Item>
          <Item name="taxCode">
            <Input placeholder="税编" />
          </Item>
          <Item name="totalAmountFrom">
            <InputNumber placeholder="含税金额起" style={{ width: "100%" }} />
          </Item>
          <Item name="totalAmountTo">
            <InputNumber placeholder="含税金额止" style={{ width: "100%" }} />
          </Item>
          {hasPermissions ? (
            <Item name="deptIdList">
              <Select placeholder="选择门店" options={deptList} allowClear />
            </Item>
          ) : (
            <></>
          )}
        </Table>
      </Card>
    </Page>
  );
};

export default InvoiceDetailsManagement;
