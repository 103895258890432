import React, { FC, useState, useEffect } from 'react';
import { Space, Form, Input, Button, Select, DatePicker, Tag } from 'antd';

import { Page, Card, Table, LinkText, Lookup, Modal, SBUSelect, PermissionButton } from '@comps';
import ImportContractMD from './modal/importContract.md';

import { Context, Http, Message, DateUtils, FormUtil } from '@utils';
import { ColumnProps } from '@comps/types';
import { LedgerSource } from '../common';

const { Item } = Form;
const { RangePicker } = DatePicker;

const Contract: FC = () => {
  let [table, setTable] = useState<Table>();
  const [regionOptions, setRegionOptions] = useState<JSONObject[]>([]);

  const [modal, modalHolder] = Modal.useModal();

  const signDateFromTo = [
    DateUtils.monthFirstDay(),
    DateUtils.monthLastDay(),
  ];

  useEffect(() => {
    getregionOptions();
  }, []);

  const onTableRef = (tableRef: Table) => {
    setTable(tableRef);
    table = tableRef;
  };

  const getregionOptions = () => {
    Http.get('/sys/regions/findRegionByUser')
      .then((res: JSONObject[]) => {
        setRegionOptions(res);
      });
  };

  const showImportContractMD = () => {
    Message.info('1001工程事业部”的业务员将无法完成【导入合同】操作！')
    modal.open({
      width: 300,
      content: (_1, reject) => (
        <ImportContractMD onClose={reject} />
      )
    });
  };

  const handleContractCreate = () => {
    // Message.info('1001工程事业部”的业务员将无法完成【创建合同】操作！')
    Context.clearCacheKey('/output/contract/create');

    Context.handleAdditionalPane(
      '创建合同',
      '/output/contract/create',
      '/output/contract/create',
    )
  };

  const showDetail = (row: JSONObject) => {
    const { id, sourceSystem } = row;
    const state = {
      sourceSystem
    };

    const route = `/output/contract/detail/${id}`;

    Context.handleAdditionalPane('合同详情', route, route, state);
  };

  const handleContractStatusChange = (row: JSONObject) => {
    const { id, frozen } = row;

    Http.put('/contracts/updateContractStatus', { id, frozen: !frozen })
      .then(() => {
        Message.success(frozen ? '解冻成功' : '冻结成功');
        table?.refresh();
      })
  };

  const showLedger = (contractNo: string, contractName: string, id: string) => {
    const state = {
      contractName,
      id
    };
    const route = `/output/contract/ledger/${LedgerSource.CONTACT}/${contractNo}`;

    Context.handleAdditionalPane(
      '合同台账',
      route,
      route,
      state
    );
  };

  const exportContract = () => {
    const queryParamater = table?.getQueryPanelParams() ?? {};
    const ids = table?.getSelectedRowKeys() ?? [];

    FormUtil.exportExcelFile('/contracts/excelExport', {
      ids,
      ...queryParamater,
    });
  };

  const columns: ColumnProps[] = [
    { key: 'contractName', title: '合同名称', width: 200, fixed: 'left' },
    { key: 'contractNo', title: '合同编号', width: 160 },
    { key: 'contractAttrDesc', title: '合同属性', width: 120 },
    { key: 'orgName', title: '合同主体', width: 220 },
    {
      key: 'signDate', title: '签署日期', width: 160,
      render: (signDate: string) => signDate.split(' ')[0]
    },
    {
      key: 'changed', title: '合同状态', width: 120,
      render: (changed: boolean) => {
        return changed
          ? <Tag color="orange">已变更</Tag>
          : <Tag color="green">正常</Tag>
      }
    },
    {
      key: 'frozen', title: '状态', width: 120,
      render: (frozen: boolean) => {
        return frozen
          ? <Tag color="red">已冻结</Tag>
          : <Tag color="green">正常</Tag>
      }
    },
    { key: 'regionName', title: '区域', width: 130 },
    { key: 'sourceSystemDesc', title: '来源系统', width: 130 },
    { key: 'sbuCode', title: 'SBU 代码', width: 150 },
    { key: 'sbuName', title: 'SBU 名称', width: 150 },
    { key: 'salerName', title: '业务员', width: 100 },
    { key: 'lastUpdateTime', title: '更新日期', width: 160 },
    { key: 'customerCode', title: '客户编码', width: 160 },
    { key: 'customerName', title: '客户名称', width: 220 },
    { key: 'contractAttrDesc', title: '合同类型', width: 130 },
    { key: 'dealerName', title: '经销商名称', width: 220 },
    { key: 'contractAmount', title: '合同金额', width: 150, align: 'right' },
    { key: 'uninvoicedAmt', title: '剩余未开票金额', width: 150, align: 'right' },
    {
      key: 'id', title: '操作', width: 200, align: 'center', fixed: 'right',
      render: (id: number, row: JSONObject) => {
        return (
          <Space size="large">
            <LinkText
              label={row.frozen ? '解冻' : '冻结'}
              onClick={() => handleContractStatusChange(row)}
            />
            <LinkText
              label="详情"
              onClick={() => showDetail(row)}
            />
            <LinkText label="合同台账" onClick={() => showLedger(row.contractNo, row.contractName, row.id)} />
          </Space>
        )
      }
    }
  ]

  return (
    <Page>
      {modalHolder}
      <Card title="合同管理" actions={
        <Space>
          <PermissionButton permissions='Contract.excelExport' type="primary" onClick={exportContract}>导出合同</PermissionButton>
          <PermissionButton permissions='	Contract.excelImpor' type="primary" onClick={showImportContractMD}>导入合同</PermissionButton>
          <PermissionButton permissions='Contract.create' type="primary" onClick={handleContractCreate}>创建合同</PermissionButton>
        </Space>
      }>
        <Table
          onTableRef={onTableRef}
          url="/contracts"
          queryProps={{
            initialValues: {
              signDateFromTo
            }
          }}
          columns={columns}
          selectedRowKeys={[]}
          scroll={{ y: window.innerHeight - 300 }}
        >
          <Item name="key">
            <Input placeholder="合同名称 / 编号 / 签署主体" />
          </Item>
          <Item name="contractAttr">
            <Lookup lookupType="CONTRACT_TYPE" placeholder="合同属性" />
          </Item>
          <Item name="signDateFromTo">
            <RangePicker
              placeholder={['签署日期从', '签署日期至']}
              format="YYYY-MM-DD"
              inputReadOnly
            />
          </Item>
          <Item name="customerKey">
            <Input placeholder="客户名称 / 客户编码" />
          </Item>
          <Item name="sourceSystem">
            <Lookup placeholder="来源系统" lookupType="CONTRACT_SOURCE" />
          </Item>
          <Item name="dealerName">
            <Input placeholder="经销商名称" />
          </Item>
          <Item name="sbuIds">
            <SBUSelect />
          </Item>
          <Item name="salerKey">
            <Input placeholder="业务员 / 业务员编码" />
          </Item>
          <Item name="regionCodes">
            <Select
              placeholder="区域"
              mode="multiple"
              showSearch={false}
              options={regionOptions.map(
                SBUOption => ({
                  value: SBUOption.code,
                  label: SBUOption.name,
                })
              )}
            />
          </Item>
        </Table>
      </Card>
    </Page>
  );
};

export default Contract;