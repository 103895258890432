import * as React from 'react';
import { Descriptions, Space } from 'antd';
import { Page, Card, Table, Download } from '@comps';
import { ColumnProps } from '@comps/types';
import { Http } from '@utils';
import * as Router from 'react-router';
import { Transfer } from './interface';

export interface Props {};

const XXXPage: React.FC<Props> = () => {
  const [transfer, setTransfer] = React.useState<Optional<Transfer>>();
  const { id } = Router.useParams();

  const columns: ColumnProps[] = [
    { key: 'actionTime', title: '操作时间', width: 150, },
    { key: 'actionTypeDesc', title: '操作类型', width: 150, },
    { key: 'roleName', title: '操作角色', width: 250, },
    { key: 'userName', title: '操作人', width: 250, },
  ];

  React.useEffect(() => {
    (async () => {
      const transferResult = await Http.get<Transfer>(`/tax/transfers/${id}`);
      setTransfer(transferResult);
    })();
  }, [id]);

  return (
    <Page backable>
      <Card title='发票交接'>
        <Descriptions layout="vertical" column={5} colon={false}>
          <Descriptions.Item label="发票类型">
            {transfer?.invoiceTypeDesc}
          </Descriptions.Item>
          <Descriptions.Item label="发票代码">
            {transfer?.invoiceCode}
          </Descriptions.Item>
          <Descriptions.Item label="发票号码">
            {transfer?.invoiceNo}
          </Descriptions.Item>
          <Descriptions.Item label="开票日期">
            {transfer?.invoiceDate}
          </Descriptions.Item>
          <Descriptions.Item label="价税合计">
            {transfer?.withTaxAmount}
          </Descriptions.Item>
          <Descriptions.Item label="销方名称">
            {transfer?.orgName}
          </Descriptions.Item>
          <Descriptions.Item label="客户名称" span={4}>
            {transfer?.buyerName}
          </Descriptions.Item>
          <Descriptions.Item label="开票员">
            {transfer?.invoicerName}
          </Descriptions.Item>
          <Descriptions.Item label="大区财务">
            {transfer?.financialOfficer}
          </Descriptions.Item>
          <Descriptions.Item label="业务员">
            {transfer?.clerkName}
          </Descriptions.Item>
          <Descriptions.Item label="客户签收单">
            {transfer?.allowPreview && (<Space>
                <Download url={transfer.signFileUrl} preview></Download>
                <Download url={transfer.signFileUrl}></Download>
            </Space>)}
          </Descriptions.Item>
        </Descriptions>
      </Card>
      <Card title='发票交接'>
        <Table url={`/tax/transfers/${id}/actions`}
          pagination={false}
          columns={columns}>
        </Table>
      </Card>
    </Page>
  );
};

export default XXXPage;
