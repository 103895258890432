import { RouteProps } from "@models";

import Contract from "./contract";
import ContractDetail from "./contract/detail";
import ContractLedger from "./contract/ledger";
import Bill from "./bill";
import Rebate from "./rebate";
import Apply from "./apply";
import ApplyDetail from "./apply/detail";
import Wait from "./wait";
import WaitInvoiceDetail from "./wait/detail";
import Invoice from "./invoice";
import InvoiceDetail from "./invoice/detail";
import changeRouters from "./change/routes";
import WaitInvoiceRecord from "./wait/record/waitInvoice";
import RedInvoice from "./redInvoice";
import InfoConfirm from "./redInvoice/infoConfirm";
import BlueInvoice from "./blueInvoice";
import InvoiceDetailsManagement from "./invoiceDetail";

const outputRoutes: RouteProps[] = [
  {
    path: "/output/contract",
    component: Contract,
    private: true,
  },
  {
    path: "/output/contract/create",
    component: ContractDetail,
    private: true,
  },
  {
    path: "/output/contract/detail/:contractId",
    component: ContractDetail,
    private: true,
    isCancelCache: true,
  },
  {
    path: "/output/contract/ledger/:ledgerSource/:contractNo",
    component: ContractLedger,
    private: true,
    isCancelCache: true,
  },
  {
    path: "/output/bill",
    component: Bill,
    private: true,
  },
  {
    path: "/output/rebate",
    component: Rebate,
    private: true,
  },
  {
    path: "/output/apply",
    component: Apply,
    private: true,
  },
  {
    path: "/output/apply/create/:applyType",
    component: ApplyDetail,
    private: true,
  },
  {
    path: "/output/apply/detail/:applyId/:applyType",
    component: ApplyDetail,
    private: true,
    isCancelCache: true,
  },
  {
    path: "/tax/outputInvoice/wait",
    component: Wait,
    private: true,
  },
  {
    path: "/tax/outputInvoice/wait/:requestNo/detail",
    component: WaitInvoiceDetail,
    private: true,
    isCancelCache: true,
  },
  {
    path: "/output/wait",
    component: Wait,
    private: true,
  },
  {
    path: "/tax/outputInvoice",
    component: Invoice,
    private: true,
  },
  {
    path: "/output/invoice",
    component: Invoice,
    private: true,
  },
  {
    path: "/tax/outputInvoice/:requestNo/detail",
    component: InvoiceDetail,
    private: true,
    isCancelCache: true,
  },
  {
    path: "/tax/outputInvoice/waitRecord",
    component: WaitInvoiceRecord,
    private: true,
  },
  {
    path: "/tax/redInvoice/confirmationList",
    component: RedInvoice,
    private: true,
  },
  {
    path: "/tax/redInvoice/infoConfirm",
    component: InfoConfirm,
    private: true,
  },
  {
    path: "/tax/blueInvoice/invoiceMakeout/:type/:businessType?/:requestNo?",
    component: BlueInvoice,
    private: true,
  },
  {
    path: "/tax/outputInvoice/invoiceDetail",
    component: InvoiceDetailsManagement,
    private: true,
  },

  ...changeRouters,
];

export default outputRoutes;
