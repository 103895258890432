import React, { FC, useState } from 'react';
import { Form, Space, Radio, Row, Col, InputNumber, Select } from 'antd';

import { Card, Button } from '@comps';

import { Http, Message } from '@utils';

const { Item } = Form;
const { Group } = Radio;
const { Option } = Select;

interface Props {
  isBatchMerge: boolean;
  requestId: number;
  ids: number[];
  setAllModuleData: (data: JSONObject) => void;
  onClose: () => void;
}

const MergeType: FC<Props> = ({ 
  isBatchMerge,
  requestId,
  ids,
  setAllModuleData,
  onClose,
}) => {

  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);

  const handleMerge = () => {
    const formData = form.getFieldsValue();   
    
    setLoading(true);
  
    Http.post('/request/mergeLine', {
      ids,
      requestId,
      batchMerge: isBatchMerge,
      ...formData
    })
      .then(data => {
        setAllModuleData(data);
        onClose();
        Message.success('合并成功');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Card title="合并">
      <Form
        form={form}
        initialValues={{ 
          specIsNullable: false,
          quantitySetOne: false, 
          mergeType: 1,
          precision: 2,
        }}
        style={{ marginTop: 10}}
        labelCol={{ span: 6 }}
        onFinish={handleMerge}
      >
        <Row>
          <Col span={24}>
            <Item 
              label="规格型号为空" 
              name="specIsNullable"
              rules={[{ required: true }]}
            >
              <Group>
                <Space size="large">
                  <Radio value={true}>是</Radio>
                  <Radio value={false}>否</Radio>
                </Space>
              </Group>
            </Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Item 
              label="全部数量为 1" 
              name="quantitySetOne"
              rules={[{ required: true }]}
            >
              <Group>
                <Space size="large">
                  <Radio value={true}>是</Radio>
                  <Radio value={false}>否</Radio>
                </Space>
              </Group>
            </Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Item 
              label="合并类型"
              name="mergeType"
              rules={[{ required: true }]}
            >
              <Select>
                <Option value={1}>开票商品名称、规格型号、单位、单价</Option>
                <Option value={2}>开票商品名称、规格型号、单价</Option>
                <Option value={3}>开票商品名称、单位、单价</Option>
                <Option value={4}>开票商品名称、单价</Option>
                <Option value={5}>开票商品名称、规格型号、单位</Option>
                <Option value={6}>开票商品名称、规格型号</Option>
                <Option value={7}>开票商品名称、单位</Option>
                <Option value={8}>开票商品名称</Option>
              </Select>
            </Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Item
              label="单价精度"
              name="precision"
              rules={[{ required: true }]}
            >
              <InputNumber min={0} max={8} />
            </Item>
          </Col>
        </Row>
        <Row style={{ marginTop: 12 }}>
          <Col span={24} style={{ textAlign: 'center' }}>
            <Space size="large">
              <Button 
                type="primary" 
                htmlType="submit" 
                loading={loading}
              >
                确定
              </Button>
              <Button onClick={onClose}>取消</Button>
            </Space>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};

export default MergeType;
