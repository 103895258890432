
  export enum InvoicingTableType {
    SALE = '销售订单',
    OTHER = '其他单据',
    APPLY = '开票申请明细',
    MERGE = '合并明细',
    TOBO_OPENED = '待开发票明细',
  }

  export interface SaleTableProps {
    isShowDelete: boolean;
  }

  export interface OtherTableProps {
    isShowDelete: boolean;
  }

  export interface ApplyTableProps {
    isShowDelete: boolean;
    isShowEdit: boolean;
    isShowCrossCheck: boolean;
    isShowSee: boolean;
    isShowCopy: boolean;
  }

  export interface MergeTableProps {
    isShowRestore: boolean;
    isShowEdit: boolean;
    isShowCrossCheck : boolean;
    isShowSee : boolean;
  }

  export interface ToBeOpenedTableProps {
    isShowDelete: boolean;
  }

  export enum CrossCheckType {
    /**
     * 销售单 
     */
    SO = 'SO',
    /**
     * 搬运单 
     */
    CARRY = 'CARRY',
  }