import React, { FC, useState, useEffect, memo } from 'react';
import { Space, Row, Col, Form, Button, Input, InputNumber } from 'antd';

import { Card, HiddenFormItem } from '@comps';

import { Http, Message } from '@utils';

interface Props {
  baseFormId: number,
  isEdit: boolean,
  editRowData: JSONObject,
  refreshTable: () => void,
  onClose: () => void
};

const { Item } = Form;

const AddOrEditMD: FC<Props> = memo(({
  baseFormId,
  isEdit,
  editRowData,
  refreshTable,
  onClose
}) => {

  const [form] = Form.useForm();
  const formLayout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 17 }
  };
  
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    handleAddOrEdit();
  }, []);

  const getInitFormData = () => {
    Http.get(`/request/${baseFormId}/initFund`)
      .then(data => {
        form.setFieldsValue(data);
      })
  };

  const handleAddOrEdit = () => {
    isEdit
      ? form.setFieldsValue(editRowData)
      : getInitFormData()
  };

  const handleSave = () => {
    setLoading(true);

    const url = 
      isEdit 
        ? `/request/${baseFormId}/updateFund`
        : `/request/${baseFormId}/createFund`;

    const formData = form.getFieldsValue();
    
    Http.post(url, formData)
      .then(() => {
        refreshTable();
        Message.success(isEdit ? '编辑成功' : '录入成功');
        onClose();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Card title={isEdit  ? ' 编辑资金流水': '录入资金流水'}>
      <Form 
        {...formLayout}
        form={form}  
        onFinish={handleSave}
      >
        <Row style={{ marginTop: 20 }}>
          <Col span={12}>
            <HiddenFormItem name="id" />
            <Item 
              label="资金流水号" 
              name="fundNo"
              rules={[{ required: true }]}
            >
              <Input />
            </Item>
          </Col>
          <Col span={12}>
            <Item 
              label="收款金额" 
              name="proceedAmount"
              rules={[{ required: true }]}
            >
              <InputNumber style={{ width: '100%' }} /> 
            </Item>
          </Col>
          <Col span={12}>
            <Item 
              label="收款账户名称" 
              name="proceedName"
            >
              <Input />
            </Item>
          </Col>
          <Col span={12}>
            <Item 
              label="付款账户名称" 
              name="paymentName"
            >
              <Input />
            </Item>
          </Col>
          <Col span={12}>
            <Item 
              label="收款银行" 
              name="proceedBank"
            >
              <Input />
            </Item>
          </Col>
          <Col span={12}>
            <Item 
              label="付款银行" 
              name="paymentBank"
            >
              <Input />
            </Item>
          </Col>
          <Col span={12}>
            <Item 
              label="收款账户" 
              name="proceedAccount"
            >
              <Input />
            </Item>
          </Col>
          <Col span={12}>
            <Item 
              label="付款账户" 
              name="paymentAccount"
            >
              <Input />
            </Item>
          </Col>
        </Row>
        <Row>
          <Col span={24} style={{ textAlign: 'center' }}>
            <Space size="large">
              <Button 
                type="primary" 
                htmlType="submit" 
                loading={loading}
              >
                确定
              </Button>
              <Button onClick={onClose}>取消</Button>
            </Space>
          </Col>
        </Row>
      </Form>
    </Card >
  );
});

export default AddOrEditMD;

