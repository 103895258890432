import { Button, Card } from "@comps";
import { Space } from "antd";
import * as React from "react";
import { forwardRef, useImperativeHandle } from "react";

export interface Props {
  ref: any;
  invoiceInfo: JSONObject;
  table: any;
  reject: Function;
  handleBatchIssuance: Function;
}

const LimitInfoModal: React.FC<Props> = forwardRef(
  ({ invoiceInfo, table, reject, handleBatchIssuance }, ref) => {
    const [loading, setLoading] = React.useState(false);
    useImperativeHandle(ref, () => ({
      handleBatchInvoiceLoading,
    }));
    const handleBatchInvoiceLoading = (loading: boolean) => {
      setLoading(loading);
    };
    return (
      <Card title="批量开发票">
        <div style={{ padding: "16px" }}>
          <div>
            当前企业可用发票额度：
            {invoiceInfo?.remainCreditLine ?? 0}
          </div>
          <div>
            当前开具发票额度：
            {invoiceInfo?.usedCreditLine ?? 0}
          </div>
        </div>
        <div style={{ textAlign: "center" }}>
          <Space>
            <Button
              onClick={() => {
                reject();
                setLoading(false);
                table?.refresh();
              }}
            >
              取消
            </Button>
            <Button
              loading={loading}
              type="primary"
              onClick={() => {
                handleBatchIssuance(reject);
              }}
            >
              确认开具
            </Button>
          </Space>
        </div>
      </Card>
    );
  }
);

export default LimitInfoModal;
