import * as React from 'react';
import { Form, Row, Col, DatePicker, message, Select } from 'antd';
import { Card, Button } from '@comps'
import { Http, Message, DateUtils } from '@utils'
import moment from 'moment';

/**
 * 同步发票
 */
const Item = Form.Item;
const { Option } = Select;
const { RangePicker } = DatePicker;

export interface Props {
    onCancel: () => void,
    onConfirm: () => void,
}

const SynchronousInvoice: React.FC<Props> = ({
    onCancel, onConfirm
}) => {

    const [invoiceForm] = Form.useForm();
    const [latestTime, setLatestTime] = React.useState('');
    const [dateInfo, setDateInfo] = React.useState<JSONObject>({});
    const [loading,setLoading]=React.useState(false)

    React.useEffect(() => {
        getNewTime();
        getDateInfo();
    }, [])

    const getDateInfo = async () => {
      const res = await Http.get('/input/gx/period')
      setDateInfo(res)
    }

    const disabledDate = (current: any) => {
        return !(current > moment().startOf('month') && current < moment().endOf('month'));
    }

    const confirmInvoice = () => {
      const { gxInterfaceTypeDesc, taxNo } = dateInfo
      const { time, invType } = invoiceForm.getFieldsValue() || {};
      if (!time) {
        message.warning("请选择同步发票开票日期范围！")
        return
      }
      if (!taxNo) {
        message.warning("获取公司税号失败")
        return
      }
      setLoading(true)
      // if (gxInterfaceTypeDesc === "全电接口") {
      //   const param = {
      //     taxNo,
      //     invType,
      //     invoiceDateFrom: DateUtils.date2str(time[0], "YYYYMMDD"),
      //     invoiceDateTo: DateUtils.date2str(time[1], "YYYYMMDD"),
      //   }
      //   Http.get("/input/biz/full/wholeInvoice", param).then(res => {
      //     Message.success('操作成功')
      //   }).finally(() => {
      //     onConfirm();
      //   })
      // } else {
        const param = {
          getTime: DateUtils.date2str(time, "YYYY-MM"),
          invType: invType ? invType : 2,
        }
        Http.get("/input/biz/WholeInvoice", param).then(res => {
          Message.success('操作成功')
        }).finally(() => {
          setLoading(false)
          onConfirm();
        })
      // }
    }

    const getNewTime = () => {
        Http.get("/tax/outputInvoices/queryWholeInvoiceLastTime").then(res =>{
            console.log(res);
            setLatestTime(res.lastUpdateTime);
        })
    }
    
    return (
        <Card title='同步发票'>
          <Form form={invoiceForm} layout="inline" initialValues={{ invType: 1 }}>
            <Row style={{marginLeft: 20}}>
                {/* <Item name="time" label="同步发票开票日期范围">
                    <RangePicker 
                    format="YYYY-MM-DD" 
                    picker="date"
                    disabledDate={disabledDate}
                    inputReadOnly
                    >
                    </RangePicker>
                </Item> */}
                {dateInfo.gxInterfaceTypeDesc === "全电接口" ? 
                <>
                <Item name="invType" label="发票类型">
                  <Select style={{ minWidth: 100, marginBottom: 10 }}>
                    <Option value={1}>销项</Option>
                    <Option value={2}>进项</Option>
                  </Select>
                </Item>
                <Item name="time" label="同步发票开票日期范围">
                  {/* <RangePicker 
                    format="YYYYMMDD" 
                    picker="date"
                    inputReadOnly /> */}
                    <DatePicker format="YYYY-MM" picker="month" inputReadOnly/>
                </Item>
                </> :
                <Item name="time" label="请选择归集日期"><DatePicker format="YYYY-MM" picker="month" inputReadOnly></DatePicker></Item>}
            </Row>
            <Row style={{marginLeft: 20, marginTop: 10}}>
            <div style={{color:'#169bd5'}}>当前月份最新一次同步数据时间：{latestTime}</div>
            </Row>
          </Form>
          <Row style={{marginTop: 20}}>
            <Col offset={8} span={4}>
            <Button type="primary" onClick={confirmInvoice} loading={loading}>确定</Button>
            </Col>
            <Col span={4}>
            <Button onClick={onCancel}>取消</Button>
            </Col>
          </Row>
        </Card>
    );
};

export default SynchronousInvoice;
