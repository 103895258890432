import * as React from "react";
import { Space, Form, Row, Col, DatePicker } from "antd";
import { Card, Button, DisplayInput } from "@comps";
import { DateUtils, Http, Message } from "@utils";
import { useState } from "react";
import moment from "moment";

export interface Props {
  onCancel: () => void;
  onConfirm: () => void;
}
const { RangePicker } = DatePicker;
const SyncBureau: React.FC<Props> = ({ onCancel, onConfirm }) => {
  const [taxDate, setTaxDate] = useState<any[]>([
    DateUtils.monthFirstDay(),
    DateUtils.monthLastDay(),
  ]);
  const [syncLoading, setSyncLoading] = useState(false);

  const handleSync = (resolve: any) => {
    setSyncLoading(true);
    const dateFrom = DateUtils.date2int(taxDate[0])
    const dateTo = DateUtils.date2int(taxDate[1]);
    // console.log(taxDate[0], dateFrom, moment(taxDate[0]),DateUtils.date2int(taxDate[0]),DateUtils.date2int(taxDate[1]));
    Http.post(
      `/tax/outputInvoices/sync-invoices?dateFrom=${dateFrom}&dateTo=${dateTo}`
    )
      .then((res) => {
        console.log(res);
      })
      .finally(() => {
        setSyncLoading(false);
        resolve();
      });
  };
  const handleSyncBureau = (date: any, dateString: string[]) => {
    date
      ? setTaxDate([date[0], date[1]])
      : setTaxDate(["", ""]);
    console.log(date, "====");
  };

  return (
    <Card title="税局同步">
      <RangePicker
        format="YYYYMMDD"
        placeholder={["申请日期从", "申请日期至"]}
        defaultValue={[DateUtils.monthFirstDay(), DateUtils.monthLastDay()]}
        inputReadOnly
        onChange={handleSyncBureau}
        style={{ margin: "32px" }}
      />
      <div style={{ textAlign: "center" }}>
        <Button
          loading={syncLoading}
          type="primary"
          onClick={() => handleSync(()=>onConfirm())}
          style={{ marginRight: "5px" }}
        >
          同步
        </Button>
        <Button onClick={()=>onCancel}>取消</Button>
      </div>
    </Card>
  );
};

export default SyncBureau;
