export interface TableRow {
  id: number,
  requestNo: string,
  statusCode: number,
  statusCodeDesc: string,
  needRedNoticeNo: boolean,
  allowRetry?: boolean,
  submitTime: string,
  submitterName: string,
  approverName: string,
  approveTime: string,
  approveRemarks: string,
  picUrl: string
}

export enum SignStatus {
  /*
  * 取消标记
  */
  UNMARK = '0',
  /**
   * 标记抵扣
   */
  MARK_DEDUCTION = '1',
  /**
   * 标记不抵扣
   */
  MARK_NO_DEDUCTION = '2'
}


