import React, { FC, useState, useEffect } from 'react';
import { Button, Form, DatePicker, Select, Input, Popconfirm, Space } from 'antd';

import { Page, Card, Table, LinkText, Modal, Lookup, PermissionButton } from '@comps';
import SubTable from './component/subTable';
import ImportBillMD from './modal/importBill.md';

import { Http, Message, FormUtil, DateUtils } from '@utils'; 
import { ColumnProps } from '@comps/types'; 

const { Item } = Form;
const { RangePicker } = DatePicker;

interface IKV {
  key: string,
  value: string
};

const Bill: FC = () => {

  const [modal, modalHolder] = Modal.useModal();

  const [deleteLoading, setDeleteLoading] = useState(false);
  const [SBUOptions, setSBUOptions] = useState([]);

  const orderDateFromTo = [
    DateUtils.monthFirstDay(), 
    DateUtils.monthLastDay(),
  ];

  useEffect(() => {
    getSBUOptions();
  }, []);

  const getSBUOptions = () => {
    Http.get('/sys/sbu/findSbuByUser') 
      .then((res) => {
        const options = res.map((option: any) => ({
          label: option.name,
          value: option.id
        }))

        setSBUOptions(options);
      });
  };

  const businessTypeOptions: IKV[] = [
    { key: 'PROJECT', value: '工程类' },
    { key: 'RETAIL', value: '零售类' },
    { key: 'OTHER', value: '其他类型' }
  ];
 
  let [table, setTable] = useState<Table>();
  const onTableRef = (tableRef: Table) => {
    setTable(tableRef);
    table = tableRef;
  };

  const expandedRowRender = ({ id }: JSONObject) => {
    return (
      <SubTable orderId={id} />
    );
  };

  const handleDelete = (ids: number[]) => {
    Http.post('/outputOrder', { ids })
      .then(() => {
        table?.refresh();
        Message.success('删除成功');
      })
      .finally(() => {
        setDeleteLoading(false);
      })
  };

  const handleBatchDelete = () => {
    const ids = table?.getSelectedRowKeys() ?? [];
    const idsLength = ids.length;

    if (idsLength === 0) {
      Message.warn('请至少勾选一条数据');
      return;
    };

    Modal.confirm({
      title: '删除多条记录',
      content: `是否确认删除 ${idsLength} 条记录`,
      onOk: () => {
        setDeleteLoading(true);
        handleDelete(ids);
      }
    });
  };

  const showImportContratMD = () => {
    modal.open({
      width: 300,
      content: (_1, reject) => (
        <ImportBillMD onClose={reject} />
      )
    });
  };

  const handleExportBill = () => {
    const ids = table?.getSelectedRowKeys();
    const tableFormData: JSONObject = table?.getQueryPanelParams() ?? {};

    FormUtil.exportFileByPost(
      '/outputOrder/export', 
      {
        ...tableFormData, 
        orderIds: ids, 
      }
    );
  };
  
  const columns: ColumnProps[] = [
    { key: 'orderNo', title: '单据号', width: 180, fixed: 'left' },
    { key: 'customerCode', title: '客户编码', width: 160 },
    { key: 'customerName', title: '客户名称', width: 260 },
    { key: 'withTaxAmount', title: '总金额', width: 160, format: 'amount' },
    { key: 'invoicedAmount', title: '已开发票金额', width: 160, format: 'amount' },
    { key: 'remainAmount', title: '未开发票金额', width: 160, format: 'amount' },
    { key: 'sbuCode', title: 'SBU 代码', width: 180, align: 'center' },
    { key: 'sbuName', title: 'SBU 名称', width: 160 },
    { key: 'orderTypeDesc', title: '订单类型', width: 160, align: 'center' },
    { key: 'businessType', title: '业务类型', width: 160, format: 'amount',
      render: (businessType: string) => {
        const currentObj: JSONObject = businessTypeOptions.find(
          option => option.key === businessType
        ) ?? {};
        
        return currentObj.value;
      }
    },
    { key: 'sourceSystem', title: '来源系统', width: 130 },
    { key: 'mesNo', title: '橱衣柜订单识别码', width: 160 },
    { key: 'contractNo', title: '项目合同编码', width: 180 },
    { key: 'mesNo', title: 'MES 订单号码', width: 180 },
    { key: 'custPoNo', title: '客户 PO 单据号', width: 180 },
    { key: 'orderDate', title: '单据日期', width: 140,
      render: (orderDate: string) => orderDate.split(' ')[0]
    },
    { key: 'statusCode', title: '更新状态', width: 140, 
      render: (statusCode: string) => statusCode === '2' ? '已更新' : '未更新'
    },
    { key: 'salerCode', title: '业务员编码', width: 160 },
    { key: 'salerName', title: '业务员', width: 160 },
    { key: 'customerTaxNo', title: '纳税人识别号', width: 160 },
    { key: 'id', title: '操作', width: 130, align: 'center', fixed: 'right',
      render: (id: number) => (
        <Popconfirm
          title="是否确认删除本条记录 ?"
          onConfirm={() => handleDelete([id])}
        >
          <LinkText label="删除" />
        </Popconfirm>
      )
    }
  ];

  return (
    <Page>
      {modalHolder}
      <Card 
        title="业务单据管理" 
        actions={
          <Space>
            <PermissionButton
              permissions='OutputOrder.export'
              type="primary" 
              onClick={handleExportBill}
            >
              导出单据
            </PermissionButton>
            <PermissionButton 
              permissions='OutputOrder.excelImport'
              type="primary" 
              onClick={showImportContratMD}
            >
              导入单据
            </PermissionButton>
            <PermissionButton 
              permissions='OutputOrder.delete'
              type="primary"
              loading={deleteLoading} 
              onClick={handleBatchDelete}
            >
              批量删除
            </PermissionButton>
          </Space>
        }
      >
        <Table
          onTableRef={onTableRef}
          url="/outputOrder"
          queryProps={{
            initialValues: {
              orderDateFromTo
            }
          }}
          columns={columns}
          selectedRowKeys={[]}
          expandable={{ expandedRowRender }}
          scroll={{ y: window.innerHeight - 300 }}
        >
          <Item name="orderDateFromTo">
            <RangePicker placeholder={['单据日期从', '单据日期至']} inputReadOnly/>
          </Item>
          <Item name="orderType">
            <Lookup placeholder="订单类型" lookupType="OUTPUT_ORDER_TYPE" />
          </Item>
          <Item name="orderNo">
            <Input placeholder="单据号" />
          </Item>
          <Item name="custPoNo">
            <Input placeholder="客户 PO 单据号" />
          </Item>
          <Item name="sbuIds">
            <Select 
              placeholder="SBU 名称"
              showSearch
              optionFilterProp="label"
              // mode="multiple"
              options={SBUOptions} 
            />
          </Item>
          <Item name="customerCode">
            <Input placeholder="客户编码" />
          </Item>
          <Item name="customerName">
            <Input placeholder="客户名称" />
          </Item>
          <Item name="contractNo">
            <Input placeholder="合同编号" />
          </Item>
          <Item name="salerName">
            <Input placeholder="业务员" />
          </Item>
          <Item name="sourceSystem">
            <Input placeholder="来源系统" />
          </Item>
          <Item name="mesNo">
            <Input placeholder="橱衣柜订单识别码" />
          </Item>
        </Table>
      </Card>
    </Page>
  );
};

export default Bill;