import React, { useState } from "react";
import { Card } from "@comps";
import { Form, Space, DatePicker, Button, Radio } from "antd";
import { DateUtils, Http, Message } from "@utils";

export interface Props {
  onClose: () => void;
  taxNo: string;
}

const Item = Form.Item;
const { RangePicker } = DatePicker;

const SyncBill: React.FC<Props> = ({ onClose, taxNo }: Props) => {
  const [form] = Form.useForm();

  const [loading, setLoading] = useState<boolean>(false);

  const handleSyncBill = async () => {
    setLoading(true);

    let fieldsValues = form.getFieldsValue();
    fieldsValues.taxNo = taxNo;

    await Http.get("/input/gx/sync", fieldsValues)
      .then((data) => {
        Message.success("同步发票成功");
      })
      .catch((err) => {
        Message.error("同步发票 失败");
      })
      .finally(() => {
        setLoading(false);
      });

    onClose();
  };

  return (
    <Card title="同步发票">
      <Form
        form={form}
        labelCol={{ span: 6 }}
        initialValues={{
          syncDateFromTo: [DateUtils.monthFirstDay(), DateUtils.monthLastDay()],
          flag:true
        }}
      >
        <Item
          name="syncDateFromTo"
          label="同步发票日期范围"
          rules={[{ required: true }]}
        >
          <RangePicker format="YYYY-MM-DD" inputReadOnly />
        </Item>
        <Item
          name="flag"
          label="同步已认证"
          rules={[{ required: true }]}
        >
          <Radio.Group>
            <Radio value={true}>是</Radio>
            <Radio value={false}>否</Radio>
          </Radio.Group>
        </Item>
        <Form.Item wrapperCol={{ offset: 1 }}>
          <Space>
            <Button loading={loading} type="primary" onClick={handleSyncBill}>
              确定
            </Button>
            <Button onClick={() => onClose()}>取消</Button>
          </Space>
        </Form.Item>
      </Form>
    </Card>
  );
};

export default SyncBill;
