import React, { useState } from 'react';
import { Card } from '@comps';
import { Space, Button } from 'antd';
import { Http, Message } from '@utils';

export interface Props {
  showMailOrderInfo: () => void,
  onClose: () => void
}

const BulkOrder: React.FC<Props> = ({ showMailOrderInfo, onClose }: Props) => {
  const [loading, setLoading] = useState<boolean>(false)

  const handleCancelOrder = () => {
    showMailOrderInfo();
    onClose();
  }

  return (
    <Card title="批量下单">
      <h3 style={{ marginTop: 24, marginBottom: 24 }}>选择的发票购方不同，是否确认统一邮寄下单 ?</h3>
      <Space size="large">
        <Button loading={loading} type="primary" onClick={handleCancelOrder}>确定</Button>
        <Button onClick={onClose}>取消</Button>
      </Space>
    </Card >)
}

export default BulkOrder
