import React, { useEffect, useState } from "react";
import { Space, Form, Input, Select, InputNumber, Switch } from "antd";
import { Card, Button, Lookup } from "@comps";
import { Http, Message, Api } from "@utils";
import { useSelector } from "react-redux";
import { UserContext } from "@models";

const Item = Form.Item;

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 12 },
};

export interface Props {
  onCancel: () => void;
  onConfirm: () => void;
  agent?: JSONObject;
}

const Add: React.FC<Props> = ({ onCancel, onConfirm, agent = {} }) => {
  const [orgs, setOrgs] = useState<any>();
  const [isFull, setIsFull] = useState<boolean>(false);
  const [isFULL_ELECTRIC, setIsFULL_ELECTRIC] = useState<boolean>(true);
  const [isREDIS, setIsREDIS] = useState<boolean>(false);
  const [isEQK, setIsEQK] = useState<boolean>(false);
  const [isHTTP, setIsHTTP] = useState<boolean>(false);

  const [form] = Form.useForm();
  const userInfo = useSelector<JSONObject, UserContext>((state) => state.ctx);

  useEffect(() => {
    const { agentType } = agent;
    if (agentType) {
      setIsFull(agentType === "FULL");
      setIsFULL_ELECTRIC(agentType === "FULL_ELECTRIC");
      setIsREDIS(agentType === "REDIS");
      setIsEQK(agentType === "EQK");
      setIsHTTP(agentType === "HTTP");
    }
    Api.getKv("/org/orgs/kv").then((data) => {
      setOrgs(data);
    });
  }, []);

  async function handleSave() {
    await form.validateFields();
    const submitData = {
      id: agent?.id,
      certPassword: agent?.id ? agent?.certPassword : "12345678",
      ...form.getFieldsValue(),
    };
    if (submitData.id) {
      await Http.put("/tax/agents", submitData);
    } else {
      await Http.post("/tax/agents", submitData);
    }
    Message.ok();
    onConfirm();
  }

  const handleAgent = (e: any) => {
    setIsFull(e === "FULL");
    setIsFULL_ELECTRIC(e === "FULL_ELECTRIC");
    setIsREDIS(e === "REDIS");
    setIsEQK(e === "EQK");
    setIsHTTP(e === "HTTP");
  };
  console.log("useInfo :>> ", userInfo);
  const initialValues = {
    orgId: userInfo.orgId,
    taxRegionCode: "guangdong",
    machineNo: 0,
    endpoint: "9883",
    agentType: "FULL_ELECTRIC",
    taxUserRole: "7",
    taxLoginMethod: "2",
    allowSmkp: false,
    certPassword: "12345678",
    agentPhysicalType: "3",
    signatureMechanism: "1",
    taxInvoicer: userInfo.fullName,
    taxPhone: userInfo.mobile,
    taxIdCardNo: userInfo.username,
  };
  return (
    <Card title="税盘客户端" style={{ marginBottom: "0" }}>
      <Form
        {...layout}
        form={form}
        initialValues={{ ...initialValues, ...agent }}
      >
        <Item label="企业名称" name="orgId" rules={[{ required: true }]}>
          <Select showSearch optionFilterProp="label" options={orgs} disabled />
        </Item>
        <Item label="税盘类型" name="agentType" rules={[{ required: true }]}>
          <Lookup
            lookupType="TAX_AGENT_TYPE"
            placeholder="发票类型"
            onChange={handleAgent}
          />
        </Item>
        <Item label="税盘编码" name="agentCode">
          <Input placeholder="请输入税盘编码" />
        </Item>
        {(isREDIS || isEQK) && (
          <Item label="分机号" name="machineNo" rules={[{ required: true }]}>
            <InputNumber disabled={agent?.id ? true : false} />
          </Item>
        )}
        {isEQK && (
          <Item label="税盘编号" name="agentNo" rules={[{ required: true }]}>
            <Input />
          </Item>
        )}
        <Item
          label="自动开票税盘"
          name="allowSmkp"
          valuePropName="checked"
          rules={[{ required: true }]}
        >
          <Switch />
        </Item>
        {isHTTP && (
          <Item label="端口号" name="endpoint" rules={[{ required: true }]}>
            <Input />
          </Item>
        )}
        {/* <Item label="税盘密码" name="certPassword" rules={[{ required: true }]}>
          <Input type="password" />
        </Item> */}
        {(isHTTP || isREDIS || isEQK) && (
          <Item
            label="设备类型"
            name="agentPhysicalType"
            rules={[{ required: true }]}
          >
            <Lookup lookupType="TAX_AGENT_DEVICE_TYPE" />
          </Item>
        )}
        {(isHTTP || isREDIS || isEQK) && (
          <Item
            label="签章机制"
            name="signatureMechanism"
            rules={[{ required: true }]}
          >
            <Lookup lookupType="SIGNATURE_MECHANISM" />
          </Item>
        )}
        {isFULL_ELECTRIC && (
          <>
            <Item
              label="开票员身份证号码"
              name="taxIdCardNo"
              rules={[{ required: true }]}
            >
              <Input />
            </Item>
            <Item
              label="开票员名称"
              name="taxInvoicer"
              rules={[{ required: true }]}
            >
              <Input />
            </Item>
            <Item label="地区编码" name="taxRegionCode">
              <Lookup lookupType="TAX_AGENT_ADDRESS_CODE" allowClear />
            </Item>
            <Item label="开票员电子税务局账号" name="taxAccountNo">
              <Input />
            </Item>
            <Item label="开票员电子税务局密码" name="taxPassword">
              <Input type="password" />
            </Item>
            <Item label="绑定手机号" name="taxPhone">
              <Input />
            </Item>
            <Item label="登录电子税务局身份" name="taxUserRole">
              <Lookup lookupType="TAX_AGENT_IDENTITY" allowClear />
            </Item>
            <Item label="电子税务局登录方式" name="taxLoginMethod">
              <Lookup lookupType="TAX_AGENT_LOGIN_METHOD" allowClear />
            </Item>
          </>
        )}
        {isFull && (
          <>
            <Item
              label="电子税务局账号"
              name="taxAccountNo"
              rules={[{ required: true }]}
            >
              <Input />
            </Item>
            <Item
              label="电子税务局密码"
              name="taxPassword"
              rules={[{ required: true }]}
            >
              <Input type="password" />
            </Item>
            <Item
              label="电子税务局用户角色"
              name="taxUserRole"
              rules={[{ required: true }]}
            >
              <Input />
            </Item>
          </>
        )}
        <div style={{ textAlign: "center" }}>
          <Space>
            <Button type="primary" clickCallback={handleSave}>
              保存
            </Button>
            <Button onClick={onCancel}>取消</Button>
          </Space>
        </div>
      </Form>
    </Card>
  );
};

export default Add;
