import * as React from 'react';

import { Card, Lookup } from '@comps';
import { Form, Space, DatePicker, Button, Input } from 'antd';

import { Http, Message } from '@utils';
import moment from 'moment'

export interface Props {
  onClose: () => void,
  IDs: string,
  taxAmount: number,
  refreshTable: () => void
}

const Item = Form.Item

const FullAmount: React.FC<Props> = ({ IDs, taxAmount, refreshTable, onClose }: Props) => {
  const [form] = Form.useForm()
  const handleTransferOut = async () => {
    let fieldsValues = form.getFieldsValue()

    const transferedPeriod_str = moment(fieldsValues.transferedPeriod).format('YYYYMM')
    const transferedPeriod_num = parseInt(transferedPeriod_str)

    fieldsValues.transferedPeriod = transferedPeriod_num
    fieldsValues.id = IDs
    fieldsValues.isFullTransfered = true

    await Http.post('/input/certifiedManagement/invoiceTransferOut', fieldsValues)
      .then(res => {
        Message.success('转出成功')
        refreshTable()
      })
      
    onClose()
  }

  return (
    <Card title='全额转出'>
      <Form form={form} labelCol={{ span: 6 }}
            initialValues={{ transferedTaxAmount: taxAmount }}>
        <Item name='transferedTaxAmount' label="转出税额" wrapperCol={{ offset: 1 }}>
          <Input readOnly />
        </Item>     
        <Item name="transferedType" label="转出原因" wrapperCol={{ offset: 1 }}>
          <Lookup lookupType="TRANSFERED_REASON_TYPE"/>
        </Item>
        <Item name="transferedPeriod" label="转出所属期" wrapperCol={{ offset: 1 }}>
          <DatePicker picker="month" inputReadOnly/>
        </Item>
        <Item wrapperCol={{ offset: 7 }}>
          <Space>
            <Button type="primary" onClick={handleTransferOut}>确定</Button>
            <Button onClick={() => onClose()}>取消</Button>
          </Space>
        </Item>
      </Form>
  </Card >)
}

export default FullAmount
