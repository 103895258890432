import { message } from "antd";
import io from "socket.io-client"

var Result = function (code, msg, data) {
	this.code = code;
	this.msg = msg;
	this.data = data;
}
function WebScan(options) {
	if (options.url === '' || options.url === null || options.url === undefined) {
		WebScan.prototype.url = "http://localhost:18989/WebScan/";
	} else {
		WebScan.prototype.url = options.url;
	}
	if (options.wsUrl === '' || options.wsUrl === null || options.wsUrl === undefined) {
		WebScan.prototype.wsUrl = "http://localhost:28989";
	} else {
		WebScan.prototype.wsUrl = options.wsUrl;
	}
	WebScan.prototype.licence = options.licence;
	return WebScan.prototype;
}
WebScan.prototype = {
	constructor: WebScan,
	clientId: '',
	isInit: false,
	isInUse: false,
	callback: null,
	//初始化
	initSef: function (pid, callback) {
		if (this.licence === null || this.licence === '' || this.licence === undefined) {
			return null;
		}
		var that = this;
		fetch(`${this.url}/getVersionInfo`, {
			method: 'POST',
			body: JSON.stringify({
				licence: this.licence,
				pid: pid
			})
		}).then((res) => {
			return res.json()
		}).then((resolve) => {
			console.log(resolve)
			if (resolve.code == 200) {
				that.initSocketIo(resolve.data);
				that.clientId = resolve.data;
				that.isInit = true;
			}
			callback(resolve);
		}, (reject) => {
			callback(reject)
		})
	},
	//获取设备
	getDevices: function (callback) {
		fetch(`${this.url}/getDevices?pid=${this.clientId}`, {
			method: 'GET',
		}).then((res) => {
			return res.json()
		}).then((resolve) => {
			console.log(resolve)
			callback(resolve);
		}, (reject) => {
			callback(reject)
		})
	},
	setParams: function (params, callback) {
		fetch(`${this.url}/setParams`, {
			method: 'POST',
			body: JSON.stringify({
				pid: this.clientId,
				params: JSON.stringify(params)
			})
		}).then((res) => {
			return res.json()
		}).then((resolve) => {
			callback(resolve);
		}, (reject) => {
			callback(reject)
		})
	},
	saveLicence(licence, callback) {
		fetch(`${this.url}/saveLicence`, {
			method: 'POST',
			body: JSON.stringify({
				licence: licence,
				pid: this.clientId
			})
		}).then((res) => {
			return res.json()
		}).then((resolve) => {
			callback(resolve);
		}, (reject) => {
			callback(reject)
		})
	},


	getParams: function (callback) {
		fetch(`${this.url}/getParams?pid=${this.clientId}`, {
			method: 'GET',
		}).then((res) => {
			return res.json()
		}).then((resolve) => {
			callback(resolve);
		}, (reject) => {
			callback(reject)
		})
	},
	//获取序列号
	getSerialNumber: function (device, callback) {
		fetch(`${this.url}/getSerialNumber?device=${device}`, {
			method: 'GET',
		}).then((res) => {
			return res.json()
		}).then((resolve) => {
			callback(resolve);
		}, (reject) => {
			callback(reject)
		})
	},
	getFileExplore: function (parentPath, isDirectory, callback) {
		fetch(`${this.url}/getFilePath?parentPath=${parentPath}&isDirectory=${isDirectory}`, {
			method: 'GET',
		}).then((res) => {
			return res.json()
		}).then((resolve) => {
			callback(resolve);
		}, (reject) => {
			callback(reject)
		})
	},
	//获取图像
	getImageByName: function (name, callback) {
		fetch(`${this.url}/getImageByName?pid=${this.clientId}&imageName=${name}`, {
			method: 'GET',
		}).then((res) => {
			return res.json()
		}).then((resolve) => {
			callback(resolve);
		}, (reject) => {
			callback(reject)
		})
	},
	majorOFD: function (isAuto, callback) {
		var formData = {
			"isAuto": isAuto,
			"pid": this.clientId
		}
		fetch(`${this.url}/majorOfd`, {
			method: 'POST',
			body: JSON.stringify({
				formDataString: JSON.stringify(formData),
			})
		}).then((res) => {
			return res.json()
		}).then((resolve) => {
			callback(resolve);
		}, (reject) => {
			callback(reject)
		})
	},
	majorPDF: function (callback) {
		var formData = {
			"pid": this.clientId,
		}
		fetch(`${this.url}/majorPdf`, {
			method: 'POST',
			body: JSON.stringify({
				formDataString: JSON.stringify(formData),
			})
		}).then((res) => {
			return res.json()
		}).then((resolve) => {
			callback(resolve);
		}, (reject) => {
			callback(reject)
		})
	},
	//上传图像
	uploadImage: function (uploadParam, callback) {
		uploadParam.pid = this.clientId;
		fetch(`${this.url}/uploadImage`, {
			method: 'POST',
			body: JSON.stringify({
				formDataString: JSON.stringify(uploadParam),
			})
		}).then((res) => {
			return res.json()
		}).then((resolve) => {
			callback(resolve);
		}, (reject) => {
			callback(reject)
		})
	},
	//批量获取图像
	getBatchImage: function (startIndex, endIndex, callback) {
		fetch(`${this.url}/image/getBatchImage`, {
			method: 'POST',
			body: JSON.stringify({
				pid: this.clientId,
				startIndex: startIndex,
				endIndex: endIndex
			})
		}).then((res) => {
			return res.json()
		}).then((resolve) => {
			callback(resolve);
		}, (reject) => {
			callback(reject)
		})
	},
	saveImage: function (imageName, base64, callback) {
		fetch(`${this.url}/image/saveImage`, {
			method: 'POST',
			body: JSON.stringify({
				pid: this.clientId,
				imageName: imageName,
				image: base64
			})
		}).then((res) => {
			return res.json()
		}).then((resolve) => {
			callback(resolve);
		}, (reject) => {
			callback(reject)
		})
	},
	getAllImages: function (callback) {
		fetch(`${this.url}/image/getImageByPid?pid=${this.clientId}`, {
			method: 'GET',
		}).then((res) => {
			return res.json()
		}).then((resolve) => {
			callback(resolve);
		}, (reject) => {
			callback(reject)
		})
	},
	deleteImage: function (imageName, callback) {
		fetch(`${this.url}/image/deleteImage`, {
			method: 'POST',
			body: JSON.stringify({
				pid: this.clientId,
				imageName: imageName,
			})
		}).then((res) => {
			return res.json()
		}).then((resolve) => {
			callback(resolve);
		}, (reject) => {
			callback(reject)
		})
	},
	deleteByIndexes: function (indexs, callback) {
		fetch(`${this.url}/image/deleteByIndexes`, {
			method: 'POST',
			body: JSON.stringify({
				pid: this.clientId,
				indexs: indexs,
			})
		}).then((res) => {
			return res.json()
		}).then((resolve) => {
			callback(resolve);
		}, (reject) => {
			callback(reject)
		})
	},
	deleteAllImage: function (callback) {
		fetch(`${this.url}/image/deleteAllImage`, {
			method: 'POST',
			body: JSON.stringify({
				pid: this.clientId,
			})
		}).then((res) => {
			return res.json()
		}).then((resolve) => {
			callback(resolve);
		}, (reject) => {
			callback(reject)
		})
	},
	split: function (imageName, isHorizontal, x1, y1, x2, y2, callback) {
		fetch(`${this.url}/image/split`, {
			method: 'POST',
			body: JSON.stringify({
				pid: this.clientId,
				imageName: imageName,
				isHorizontal: isHorizontal,
				x1: parseInt(x1),
				y1: parseInt(y1),
				x2: parseInt(x2),
				y2: parseInt(y2)
			})
		}).then((res) => {
			return res.json()
		}).then((resolve) => {
			callback(resolve);
		}, (reject) => {
			callback(reject)
		})

	},
	merge: function (isHorizontal, indexs, callback) {
		fetch(`${this.url}/image/mergeHorizontal`, {
			method: 'POST',
			body: JSON.stringify({
				pid: this.clientId,
				isHorizontal: isHorizontal,
				indexs:indexs
			})
		}).then((res) => {
			return res.json()
		}).then((resolve) => {
			callback(resolve);
		}, (reject) => {
			callback(reject)
		})
	},
	getLastBatch: function (callback) {
		fetch(`${this.url}/getLastBatch`, {
			method: 'GET',
		}).then((res) => {
			return res.json()
		}).then((resolve) => {
			callback(resolve);
		}, (reject) => {
			callback(reject)
		})
	},
	//开始扫描
	startScan: function (callback, scanData) {
		if (this.isInUse === true) {
			return new Result(500, "设备使用中", null)
		}
		if (this.SocketClient != null && this.SocketClient != undefined) {
			this.SocketClient.emit('scan', JSON.stringify(scanData));
			this.callback = callback;
			this.isInUse = true;
			return new Result(200, "开启成功", null);
		} else {
			return new Result(500, "连接服务失败", null)
		}
	},
	//初始化socketIO

	initSocketIo: function (id) {
		this.SocketClient = io.connect(this.wsUrl + "?id=" + id, {
			transports: ['websocket']
		});
		var that = this;
		if (this.SocketClient != null && this.SocketClient != undefined) {
			this.SocketClient.on("error", function (data) {
				if (that.callback != null && that.callback != undefined) {
					var result = { "code": 500, "msg": data };
					that.callback(result);
				}
				that.isInUse = false;
			});
			this.SocketClient.on("success", function (data) {
				if (that.callback != null && that.callback != undefined) {
					var result = { "code": 200, "msg": data };
					that.callback(result);
				}
				that.isInUse = false;
			});
			this.SocketClient.on("image", function (data) {
				if (that.callback != null && that.callback != undefined) {
					that.callback(data);
				}
			});
			this.SocketClient.on("result", function (data) {
				if (that.callback != null && that.callback != undefined) {
					that.callback(data);
				}
				that.isInUse = false;
			});
			this.SocketClient.on("connect_error", function () {
				message.error("初始化连接服务失败")
				return new Result(500, "初始化连接服务失败");
			})
		}
	},
	getClientId: function () {
		return this.clientId;
	},
	setClientId: function (pid) {
		this.clientId = pid;
	}
};
window.WebScan = WebScan;

export default WebScan