import React, { FC, useState, useEffect } from 'react';

import { Card } from '@comps';

import { Http } from '@utils';

import './index.css';

interface Props {
  logId: number
}; 

const LogList: FC<Props> = ({ logId }) => {
  const [logList, setLogList] = useState<JSONObject[]>([]);
    
  useEffect(() => {
    Http.get(`/request/${logId}/requestLogs`)
      .then(data => {
        setLogList(data);
      });
  }, []);

  return (
    <Card title="日志记录">
      <div className="log-list">
        <ul className="log-list-header">
          <li>操作时间</li>
          <li>操作人</li>
          <li>操作类型</li>
          <li>备注</li>
        </ul>
        {logList.map(log => (
          <ul 
            className="log-list-row" 
            key={log.id}
          >
            <li>{log.operateTime}</li>
            <li>{log.operator}</li>
            <li>{log.operateTypeDesc}</li>
            <li>{log.remark}</li>
          </ul>
        ))}
      </div>
    </Card>
  );
};

export default LogList;