import * as React from 'react';
import { Card, Kv, Button } from '@comps';
import { Form, Space } from 'antd';
import { Http, Lang } from '@utils';

export interface Props extends React.Props<HTMLElement>,
  React.BaseHTMLAttributes<HTMLElement> {
  id: string,
  onConfirm: () => void,
  onClose: () => void,
};

const UpdateEmployeeMd: React.FC<Props> = ({ id, onClose, onConfirm }: Props) => {
  const [form] = Form.useForm();

  const handleConfirm = React.useCallback(() => Lang.noError(async () => {
    await form.validateFields();
    const formData = form.getFieldsValue();
    await Http.put('/tax/outputInvoices/updateEmp', {
      invoiceId: id,
      userId: formData.userId,
    });
    onConfirm();
  }), [form, id, onConfirm]);

  return (<Card title='请选择业务员' style={{ marginBottom: '0' }}>
    <Form form={form} labelCol={{ span: 6 }}
      style={{ padding: '30px 60px' }}>
      <Form.Item label="业务员"
        name="userId"
        rules={[{ required: true, message: '请选择业务员' }]}>
        <Kv url="/sys/users/empKv"></Kv>
      </Form.Item>
      <Form.Item wrapperCol={{ offset: 6 }}>
        <Space>
          <Button type="primary"
            clickCallback={handleConfirm}>确认修改</Button>
          <Button onClick={() => onClose()}>取消</Button>
        </Space>
      </Form.Item>
    </Form>
  </Card>);
};

export default UpdateEmployeeMd;
