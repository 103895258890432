import * as React from 'react';
import { Space, Row, Input } from 'antd';
import { ColumnProps } from '@comps/types';
import { Table, Card, Button } from '@comps';
import { Http, Context } from '@utils';
import { ChangeLine } from '../interface';

export interface Props {
  id: string,
  onConfirm: () => void;
  onClose: () => void;
};

const PreviewMd: React.FC<Props> = ({
  id, onConfirm, onClose
}: Props) => {

  const tableRef = React.useRef<Table>();
  const [isShow,setIsShow] = React.useState(true)

  const handleConfirm = React.useCallback(async () => {
    await Http.put(`/tax/changes/${id}/submit`);
    Context.route('/tax/change');
    onConfirm();
  }, [id, onConfirm]);

  // 切换到编辑模式
  const switchToEditModel = React.useCallback(async (changeLine: ChangeLine) => {
    setIsShow(false);
    console.log(changeLine,tableRef)
    changeLine._isEdit = true
    changeLine._remark=changeLine.remark
    tableRef.current?.resetDataSource();
  }, []);

  // 取消编辑
  const cancelEditModel = React.useCallback(async (changeLine: ChangeLine) => {
    changeLine._isEdit = false
    tableRef.current?.resetDataSource();
  }, []);

  // 保存行
  const saveLine = React.useCallback(async (changeLine: ChangeLine) => {
    await Http.put('/tax/changes/updateRemark', {
      id: changeLine.id,
      remark:changeLine.remark
    }); 
    // tableRef.current?.refresh();
    tableRef.current?.resetDataSource();
    changeLine._isEdit = false
    // tableRef.current?.resetDataSourceMin(tableRef.current?.getTableData());
    setIsShow(true);
  }, []);

  const columns: ColumnProps[] = [
    { key: 'seq', title: '序号', width: 60 },
    { key: 'groupNo', title: '票号', width: 60, render:(value, row) => (row.actionType === 3 ? value: '') },
    { key: 'invoiceTypeDesc', title: '发票类型', width: 150, },
    { key: 'invoiceDate', title: '开票日期', width: 120, },
    { key: 'invoiceNo', title: '发票号码', width: 120, },
    { key: 'actionTypeDesc', title: '处理方式', width: 120, },
    { key: 'buyerName', title: '客户名称', width: 150 },
    { key: 'amount', title: '不含税金额', width: 150, format: 'amount', },
    { key: 'taxAmount', title: '税额', format: 'amount', width: 120 },
    { key: 'withTaxAmount', title: '价税合计', width: 150, format: 'amount' },
    { 
      key: 'remark', title: '备注', width: 180, 
      render(value, changeLine: ChangeLine) {
        if (changeLine._isEdit) {
          return (<Input defaultValue={value}
                               onChange={(event) => {
                                 changeLine.remark = event.target.value;
                               }} />);
        }
        return value;
      },
    },
    { key: 'buyerTaxNo', title: '购方税号', width: 180, },
    { key: 'buyerAddrTel', title: '地址电话', width: 180, },
    { key: 'buyerBankAccount', title: '银行账号', width: 180, },
    {
      key: 'id', title: '操作', fixed: "right", width: 150,
      render(id, changeLine: ChangeLine) {
        return (<>
            <React.Fragment>
              <Button type="link" size="small" clickCallback={() => switchToEditModel(changeLine)}>修改</Button>
            </React.Fragment>
          {changeLine._isEdit &&
            (<Button type="link" size="small" clickCallback={() => saveLine(changeLine)}>保存</Button>)}
          {changeLine._isEdit &&
            (<Button type="link" size="small" clickCallback={() => cancelEditModel(changeLine)}>取消</Button>)}
        </>);
      },
    },
  ];
  return (
    <Card title='请您确认改票信息' style={{ marginBottom: '0' }}>
      <Space direction="vertical" style={{ width: '100%' }}>
        <Table url={`/tax/changes/${id}/preview`}
          style={{ minHeight: '200px' }}
          pagination={false}
          columns={columns}
          onTableRef={(ref) => {
            tableRef.current = ref;
          }} 
          />
        <Row justify="center">
          { isShow &&
          <Space>
            <Button type="primary" clickCallback={handleConfirm}>确认提交</Button>
            <Button onClick={() => onClose()}>返回修改</Button>
          </Space>
          }
        </Row>
      </Space>
    </Card>
  );
};

export default PreviewMd;
