import { Card, LinkText, Modal, Page, PermissionButton, Table } from "@comps";
import { ColumnProps } from "@comps/types";
import { UserContext } from "@models";
import { Http } from "@utils";
import { DatePicker, Form, Popconfirm, Space, Tag, message } from "antd";
import React, { FC, useState } from "react";
import { useSelector } from "react-redux";
import AddapprovalProcessModal from "./modal/addapprovalProcessModal";
import ApprovalModal from "./modal/approvalModal";

const { Item } = Form;
const { RangePicker } = DatePicker;

const zzsColumns: ColumnProps[] = [
  { key: "projectName", title: "项目名称", width: 140, align: "center" },
  {
    key: "amount",
    title: "本期发生",
    width: 140,
    align: "center",
    render(value, tableRow: JSONObject) {
      if (tableRow.projectName === "增值税税负率") {
        return value === null ? 0 : (value * 100).toFixed(2) + "%";
      } else {
        return value === null ? 0 : value.toLocaleString();
      }
    },
  },
  {
    key: "amountAccumulated",
    title: "本年累计",
    width: 140,
    align: "center",
    render(value, tableRow: JSONObject) {
      if (tableRow.projectName === "增值税税负率") {
        return value === null ? 0 : (value * 100).toFixed(2) + "%";
      } else {
        return value === null ? 0 : value.toLocaleString();
      }
    },
  },
];

const otherColumns: ColumnProps[] = [
  { title: "项目", key: "projectName", width: 100 },
  {
    title: "金额",
    key: "amount",
    width: 100,
    render(value: any, record: any, index: number) {
      if (record.projectName === "企业所得税税负率") {
        return value ? value + "%" : 0;
      } else {
        return value ? value.toLocaleString() : 0;
      }
    },
  },
];

const ApprovalProcessDefine: FC = () => {
  // const period = moment().subtract(1, 'months')
  const [modal, modalHolder] = Modal.useModal();
  const userInfo = useSelector<JSONObject, UserContext>((state) => state.ctx);
  const [orgId, setOrgId] = useState<number>(userInfo.orgId || 0);

  let [table, setTable] = useState<Table>();
  const onTableRef = (tableRef: Table) => {
    table = tableRef;
    setTable(tableRef);
  };

  const startuse = () => {
    const selectedRow = table?.getSelectedRowData();
    // let isstart = true
    // selectedRow?.forEach((value: any) => {
    //     if (value.status === 1)
    //     {
    //         isstart = false
    //         message.warning("该流程")
    //     }

    // })
    // if (isstart) {
    if (!selectedRow || selectedRow.length === 0) {
      message.warning("请选择一条审批事项");
      return;
    }
    let ids = selectedRow.map((value: any) => {
      return value.id;
    });

    let str = ids.join(",");
    Http.put(`/outputInvoiceApprovalProcess/status`, { id: str, status: 1 })
      .then((data) => {
        console.log(data);
        table?.refresh();
      })
      .finally(() => {});
    // }
  };

  const abandonuse = async () => {
    const selectedRow = table?.getSelectedRowData();
    // let isstart = true
    // selectedRow?.forEach((value: any) => {
    //     if (value.status === 0)
    //         isstart = false
    // })
    // if (isstart) {
    if (!selectedRow || selectedRow.length === 0) {
      message.warning("请选择一条审批事项");
      return;
    }

    let ids = selectedRow.map((value: any) => {
      return value.id;
    });

    let str = ids.join(",");
    Http.put(`/outputInvoiceApprovalProcess/status`, { id: str, status: 0 })
      .then((data) => {
        console.log(data);
        table?.refresh();
      })
      .finally(() => {});
    // }
  };

  const draftModal = async (rowData: JSONObject) => {
    const { taxName } = rowData;
    switch (taxName) {
      case "增值税":
        rowData = { ...rowData, approvalColumns: zzsColumns };
        break;
      case "企业所得税（季度）":
        rowData = { ...rowData, approvalColumns: otherColumns };
        break;
      default:
        break;
    }
    await modal.open({
      width: "800px",
      content(_1, reject) {
        return (
          <ApprovalModal
            data={rowData}
            onClose={reject}
            query={table?.refresh}
          ></ApprovalModal>
        );
      },
    });
  };

  const setFormOrgNameValue = (orgNameVal: string) => {
    setOrgId(Number(orgNameVal || 0));
  };

  const addNewData = () => {
    modal.open({
      width: 500,
      content(resolve, reject) {
        return (
          <AddapprovalProcessModal
            currentType="add"
            onCancel={reject}
            onConfirm={resolve}
            refresh={table?.refresh}
          />
        );
      },
    });
  };

  // 查看功能
  const editData = (record: JSONObject) => {
    let dataCopy = JSON.parse(JSON.stringify(record));
    modal.open({
      width: 500,
      content(resolve, reject) {
        return (
          <AddapprovalProcessModal
            currentType="edit"
            Modaldata={dataCopy}
            onCancel={reject}
            onConfirm={resolve}
            data={record}
            refresh={table?.refresh}
          ></AddapprovalProcessModal>
        );
      },
    });
  };

  const deleteData = (record: any) => {
    Http.del(`/outputInvoiceApprovalProcess/${record.id}`)
      .then((data) => {
        console.log(data);
        table?.refresh();
      })
      .finally(() => {});
  };

  const columns: ColumnProps[] = [
    { key: "approveName", title: "审批名称", width: 140 },
    {
      key: "process",
      title: "审批流程",
      width: 140,
      render(value, record, index) {
        let str: string = "";
        record.outputInvoiceApprovalLevels.forEach((value: any, key: any) => {
          if (key === 0) str += value.positionName;
          else str = str + "->" + value.positionName;
        });
        return str;
      },
    },
    {
      key: "amountStart",
      title: "审批金额",
      width: 140,
      render(value, record, index) {
        return `${record.amountStart} ~ ${record.amountEnd}`;
      },
    },
    {
      key: "status",
      title: "状态",
      width: 140,
      render(value, record, index) {
        return value === 1 ? (
          <Tag color="blue">启用</Tag>
        ) : (
          <Tag color="gray">弃用</Tag>
        );
      },
    },
    { key: "lastUpdateTime", title: "更新时间", width: 140 },
    {
      key: "id",
      title: "操作",
      width: 100,
      fixed: "right",
      render: (value, record) => {
        return (
          <Space>
            <LinkText
              label="编辑"
              onClick={() => {
                let Modaldata = JSON.parse(JSON.stringify(record));
                editData(Modaldata);
              }}
            />
            <Popconfirm
              title="您确认要删除吗?"
              onConfirm={() => deleteData(record)}
            >
              <LinkText label="删除" />
            </Popconfirm>
          </Space>
        );
      },
    },
  ];
  return (
    <Page>
      {modalHolder}
      <Card
        title="审批流定义"
        actions={
          <Space>
            <PermissionButton
              permissions="OutputInvoiceApprovalProcess.updateStatus"
              type="primary"
              onClick={startuse}
            >
              启用
            </PermissionButton>
            <PermissionButton
              permissions="OutputInvoiceApprovalProcess.updateStatus"
              type="primary"
              onClick={abandonuse}
            >
              弃用
            </PermissionButton>
            <PermissionButton
              permissions="OutputInvoiceApprovalProcess.save"
              type="primary"
              onClick={addNewData}
            >
              新增
            </PermissionButton>
          </Space>
        }
      >
        <Table
          onTableRef={onTableRef}
          url="/outputInvoiceApprovalProcess"
          columns={columns}
          isExpandAllItem={true}
          // queryProps={{
          //     initialValues: {
          //         statusCode: "1",
          //         approver: defaultApprover,
          //     },
          // }}
          // summary={tableSummary}
          pagination={{ hideOnSinglePage: false }}
          selectedRowKeys={[]}
          scroll={{ y: window.innerHeight - 300 }}
        ></Table>
      </Card>
    </Page>
  );
};

export default ApprovalProcessDefine;
