import React, { useState, FC } from 'react'
import { Button, Space, Input, Tag, Form } from 'antd';

import { Page, Table, Card, LinkText, Modal } from '@comps';
import AddOrEditMD from './addOrEdit.md';

import { Http, Message} from '@utils';

import { ColumnProps } from '@comps/types';

const { Item } = Form;

const SBUManage: FC = () => {
  const [deleteLoading, setDeleteLoading] = useState(false);

  const [modal, modalHolder] = Modal.useModal();

  let [table, setTable] = useState<Table>();
  const onTableRef = (tableRef: Table) => {
    setTable(tableRef);
    table = tableRef;
  };

  const refreshTable = () => {
    table?.refresh();
  };

  const handleAdd = () => {
    modal.open({
      width: 600,
      content: (_1, reject) => (
        <AddOrEditMD 
          isAdd={true} 
          rowObj={{}} 
          onClose={reject} 
          refreshTable={refreshTable}
        />
      )
    });
  };

  const handleEdit = (row: JSONObject) => {
    modal.open({
      width: 600,
      content: (_1, reject) => (
        <AddOrEditMD 
          isAdd={false} 
          rowObj={row} 
          onClose={reject} 
          refreshTable={refreshTable}
        />
      )
    });
  };

  const handleDelete = () => {
    const IDs = table?.getSelectedRowKeys();
    
    if (IDs?.length === 0) {
      Message.warn('请至少勾选一条数据');
      return;
    };

    setDeleteLoading(true);

    Http.del('/sys/sbu/batchDelete', { ids: IDs })
      .then(() => {
        Message.success('删除成功');
        table?.refresh();
      })
      .finally(() => {
        setDeleteLoading(false);
      });
  };

  const handleStatusChange = (row: JSONObject) => {
    const { id, enabled } = row;

    Http.put('/sys/sbu/updateStatus', { id, enabled: !enabled })
      .then(() => {
        Message.success(enabled ? '禁用成功' : '启用成功');
        table?.refresh();
      })
  };

  const columns: ColumnProps[] = [
    { key: 'superiorSbu',title: '上级 SBU', width: 200 },
    { key: 'code',title: 'SBU代码', width: 160 },
    { key: 'name',title: 'SBU名称', width: 160 },
    { key: 'invRecTransactionName',title: '应收发票事务名称', width: 220 },
    { key: 'redInvRecTransactionName',title: '红字应收发票事务名称', width: 220 },
    { key: 'discountRate',title: '允许最大折扣金额比例', width: 160, tag: {} },
    { key: 'enabled',title: '状态', width: 80, fixed: 'right',
      render: (enabled: boolean) => {
        return enabled 
          ? <Tag color="green">启用</Tag>
          : <Tag color="red">禁用</Tag>
      }
    },
    { key: 'id',title: '操作', width: 100, align: 'center', fixed: 'right',
      render: (_1, row: JSONObject) => (
        <Space size="large">
          <LinkText label="修改" onClick={() => handleEdit(row)}></LinkText>
          <LinkText 
            label={row.enabled ? '禁用' : '启用'} 
            onClick={() => handleStatusChange(row) } 
          />
        </Space>
      )
    }
  ];

  return (
    <Page>
      {modalHolder}
      <Card 
        title="SBU 管理" 
        actions={
          <Space>
            <Button type="primary" onClick={handleAdd}>新增</Button>
            <Button loading={deleteLoading} onClick={handleDelete}>删除</Button>
          </Space>
        }>
        <Table 
          onTableRef={onTableRef}
          url='/sys/sbu' 
          columns={columns} 
          selectedRowKeys={[]}
          scroll={{ y: window.innerHeight - 300 }}
        >
          <Item name="args">
            <Input placeholder='SBU 代码/SBU 名称' />
          </Item>
        </Table>
      </Card>
    </Page>
  );
};

export default SBUManage;