import React, { FC } from "react";
import { Card } from "@comps";
import { Button, Col, Form, Input, Row, DatePicker } from "antd";
import { DateUtils, Http } from "@utils";

const { Item } = Form

const layout = {
  labelCol: { span: 8},
  wrapperCol: { span: 15 }
}

interface Props {
  rowData: JSONObject,
  onClose: () => void
}

const RedMask:FC<Props> = ({
  rowData,
  onClose
}) => {
  const [form] = Form.useForm()

  const handleConfirm = async() => {
    await form.validateFields().then(async () => {
      const { id } = rowData
      const { invCode, invNo, invDate, redNoticeNo, checkCode } = form.getFieldsValue()
      const params = {
        invoiceId: id,
        invCode: invCode ?? "",
        invNo,
        invDate: DateUtils.date2str(invDate, "YYYYMMDD"),
        redNoticeNo: redNoticeNo ?? "",
        checkCode: checkCode ?? "",
      }
      console.log(params, "handleConfirm");
      await Http.post(`/tax/outputInvoices/markRedInvoice`, params)
      onClose()
    })
  }
  
  return (
    <Card
      title="标记已红冲">
        <Form form={form} {...layout}>
          <Row>
            <Col span={12}>
              <Item name="invCode" label="发票代码">
                <Input />
              </Item>
            </Col>
            <Col span={12}>
              <Item name="invNo" label="发票号码" rules={[{required: true}]}>
                <Input />
              </Item>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <Item name="invDate" label="开票日期" rules={[{required: true}]}>
                <DatePicker 
                  inputReadOnly
                  style={{ width: "100%" }}
                  format="YYYYMMDD"/>
              </Item>
            </Col>
            <Col span={12}>
              <Item name="redNoticeNo" label="红字通知单号">
                <Input />
              </Item>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <Item name="checkCode" label="校验码">
                <Input />
              </Item>
            </Col>
          </Row>
        </Form>
        <Row justify="center">
          <Button type="primary" style={{ marginRight: 10 }} onClick={handleConfirm}>确认</Button>
          <Button type="primary" onClick={onClose}>取消</Button>
        </Row>
    </Card>
  )
}

export default RedMask