import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Select,
  Space,
  Tag,
  message,
} from "antd";
import moment from "moment";
import React, {
  FC,
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";

import {
  Card,
  LinkText,
  Lookup,
  Modal,
  Page,
  PermissionButton,
  Table,
  TableSummaryRow,
} from "@comps";
import InvoiceCancel from "./modal/cancel.md";
import InvoiceRed from "./modal/red.md";
import RedMask from "./modal/redMask.md";
import SignMd from "./modal/sign.md";
import SynchronousInvoice from "./modal/synchronousInvoice.md";
import UpdateEmployee from "./modal/updateEmployee.md";

import { ColumnProps } from "@comps/types";
import {
  Api,
  Context,
  DateUtils,
  FormUtil,
  Http,
  Lang,
  Math,
  Message,
} from "@utils";
import { useForm } from "antd/lib/form/Form";
import PrinterServer from "utils/Print";
import { ApplyType, ApplyTypeVal } from "../apply/interface";
import InvoiceDetail from "./detail";
import SyncBureau from "./modal/syncBureau";
import ToVoidModal from "./modal/toVoidModal";

const { Item } = Form;
const { RangePicker } = DatePicker;
const { Option } = Select;

const List: FC = () => {
  const [totalQuantity, setTotalQuantity] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [totalTaxAmount, settotalTaxAmount] = useState(0);

  const [modal, modalHolder] = Modal.useModal();
  const [voidForm] = useForm();
  const [manualWriteBackLoading, setManualWriteBackLoading] = useState(false);
  const [KAWriteBackLoading, setKAWriteBackLoading] = useState(false);
  const [uploadLoading, setUploadLoading] = useState(false);
  const defaultApprover = Context.getCurrentUserName();
  const [invoicer, setInvoicer] = useState<string>("");
  const [deptList, setDeptList] = useState<any[]>([]);
  const [empOptions, setEmpOptions] = useState<any[]>();
  const [collectForm] = Form.useForm();
  const [invoiceForm] = Form.useForm();
  const { permissions, deptId } = Context.getContext();
  const [printLoading, setPrintLoading] = useState(false);
  const applyListPermissions = ["OutputInvoice.dept"];
  const hasPermissions = applyListPermissions.some((permission) =>
    permissions.includes(permission)
  );

  let [table, setTable] = useState<Table>();
  const onTableRef = (tableRef: Table) => {
    table = tableRef;
    setTable(tableRef);
  };

  const invoiceDateFromTo = [
    DateUtils.monthFirstDay(),
    DateUtils.monthLastDay(),
  ];

  const applyTypeObj: JSONObject = {
    [ApplyTypeVal.RETAIL]: ApplyType.RETAIL,
    [ApplyTypeVal.ADVANCE]: ApplyType.ADVANCE,
    [ApplyTypeVal.PROGRESS]: ApplyType.PROGRESS,
    [ApplyTypeVal.SETTLE]: ApplyType.SETTLE,
    [ApplyTypeVal.OTHER]: ApplyType.OTHER,
    [ApplyTypeVal.MAIN_ASSISTANT]: ApplyType.MAIN_ASSISTANT,
  };

  const industryItem = [
    {
      value: 101,
      label: "百货",
    },
    { value: 102, label: "超市" },
    { value: 103, label: "电商" },
    { value: 104, label: "卡券" },
  ];

  const synchronousInvoice = async () => {
    await modal.open({
      width: 500,
      content(resolve, reject) {
        return <SynchronousInvoice onConfirm={resolve} onCancel={reject} />;
      },
    });
  };
  useEffect(() => {
    (async () => {
      let newDeptList: any[] = [];
      let newDeptIds: number[] = [];
      if (hasPermissions) {
        await Http.get("/tax/outputInvoices/dept").then((res) => {
          res.map((item: JSONObject) => {
            newDeptList.push({
              label: item.deptName,
              value: item.id,
            });
            newDeptIds.push(item.id);
          });
          setDeptList(newDeptList);
        });
      }
    })();
  }, [table]);
  const handleOnCancel = async (invoice?: JSONObject) => {
    await modal.open({
      width: 560,
      content(resolve, reject) {
        return (
          <InvoiceCancel
            invoice={invoice}
            onConfirm={resolve}
            onCancel={reject}
          />
        );
      },
    });

    table?.refresh();
  };

  useEffect(() => {
    (async () => {
      await Api.getKv("/sys/users/deptEmpKv").then((data) => {
        setEmpOptions(data);
      });
    })();
  }, [table]);
  const handleOnRed = async (invoice?: JSONObject) => {
    await modal.open({
      width: 560,
      content(resolve, reject) {
        return (
          <InvoiceRed invoice={invoice} onConfirm={resolve} onCancel={reject} />
        );
      },
    });

    table?.refresh();
  };

  const handleOnMaskAsInvoiced = async (invoice?: JSONObject) => {
    await modal.open({
      width: 560,
      content(resolve) {
        return <SignMd invoice={invoice ? invoice : {}} onClose={resolve} />;
      },
    });

    table?.refresh();
  };

  const handleRedInvoice = (row?: JSONObject) => {
    Http.post(`/tax/output-red-request/apply/${row?.id}`).then((res) => {
      console.log(res);
      table?.refresh();
    });
  };

  const handleOnRedAsInvoiced = async () => {
    const tableData = table?.getSelectedRowData() ?? [];
    if (tableData.length !== 1) {
      message.warning("请选择一条数据");
      return;
    }
    await modal.open({
      width: 700,
      content(_1, reject) {
        return <RedMask rowData={tableData[0]} onClose={reject}></RedMask>;
      },
    });
  };

  const getIds = useCallback(() => {
    return table?.getSelectedRowKeys();
  }, [table]);

  const handleUpdateEmployee = useCallback(
    (invoice: JSONObject) =>
      Lang.noError(async () => {
        const ids = getIds();
        await modal.open({
          width: 700,
          ignoreError: false,
          content(resolve, reject) {
            return (
              <UpdateEmployee
                ids={ids || []}
                onConfirm={resolve}
                onClose={reject}
              />
            );
          },
        });

        message.success("修改成功");
        table?.refresh();
      }),
    [modal, table, getIds]
  );

  const handleExport = (isSummary: boolean) => {
    const ids = table?.getSelectedRowKeys();

    let url = "";
    if (isSummary) {
      url = "/tax/outputInvoices/sumExportManger";
    } else {
      url = "/tax/outputInvoices/detailExportManger";
    }

    const tableFormData: JSONObject = table?.getQueryPanelParams() ?? {};

    FormUtil.exportFileByPost(url, {
      ...tableFormData,
      invIds: ids,
    });
  };

  const handleWriteBack = (isManualWriteBack: boolean) => {
    const ids: number[] = table?.getSelectedRowKeys() ?? [];

    if (ids.length === 0) {
      Message.warn("请至少勾选一条数据");
    }

    let url = "";

    if (isManualWriteBack) {
      url = "/tax/outputInvoices/writeBack";
      setManualWriteBackLoading(true);
    } else {
      url = "/tax/outputInvoices/writeBackGB";
      setKAWriteBackLoading(true);
    }

    Http.post(url, { ids })
      .then((data) => {
        Message.success(data, 6);
      })
      .finally(() => {
        isManualWriteBack
          ? setManualWriteBackLoading(false)
          : setKAWriteBackLoading(false);
      });
  };

  const handleCollect = () => {
    modal.open({
      width: "900px",
      content(_1, reject) {
        return (
          <Card title="归集发票">
            <Form form={collectForm} layout="inline">
              <Item name="time" label="请选择归集日期">
                <DatePicker
                  format="YYYY-MM"
                  picker="month"
                  inputReadOnly
                ></DatePicker>
              </Item>
            </Form>
            <Row>
              1、同一个开票月份的每一类发票每24小时申请下载请求不超过1次
            </Row>
            <Row>
              2、当前开票月份的每一类发票最多申请5次发票下载，历史开票月份的每一类发票每30天可申请一次发票下载
            </Row>
            <Row style={{ marginTop: 10 }}>
              <Col offset={8} span={4}>
                <Button type="primary" onClick={() => confirmCollect(reject)}>
                  确定
                </Button>
              </Col>
              <Col span={4}>
                <Button onClick={reject}>取消</Button>
              </Col>
            </Row>
          </Card>
        );
      },
    });
  };

  const confirmCollect = (close: Function) => {
    const { time } = collectForm.getFieldsValue() || {};
    if (!time) {
      message.warning("请选择归集日期");
      close();
      return;
    }
    const param = {
      getTime: DateUtils.date2str(time, "YYYY-MM"),
      invType: 2,
    };
    Http.get("/input/biz/WholeInvoice", param)
      .then((res) => {})
      .finally(() => {
        close();
      });
  };

  const disabledDate = (current: any) => {
    return !(
      current > moment().startOf("month") && current < moment().endOf("month")
    );
  };

  const confirmInvoice = (close: Function) => {
    const { time } = invoiceForm.getFieldsValue() || {};
    if (!time) {
      message.warning("请选择同步发票开票日期范围！");
      return;
    }
  };

  const setTableSummaryData = ({
    totalQuantity,
    totalAmount,
    totalTaxAmount,
  }: JSONObject) => {
    setTotalQuantity(totalQuantity);
    setTotalAmount(totalAmount);
    settotalTaxAmount(totalTaxAmount);
  };

  const getSelectedRow = () => {
    return table?.getSelectedRowData();
  };

  const showDetail = (row: JSONObject) => {
    const state = {
      routerFrom: "发票管理",
    };
    if (row.requestId == 0) {
      // const route = `/tax/outputInvoice/${row.requestNo}/detail`;
      // Context.handleAdditionalPane('发票管理', route, route, state);
      modal.open({
        width: 1200,
        content(resolve, reject) {
          return <InvoiceDetail requestNo={row.requestNo} onClose={reject} />;
        },
      });
    } else {
      Http.get(`/request/${row.requestId}/detail`).then((data) => {
        const route = `/output/apply/detail/${row.requestId}/${
          applyTypeObj[data.request.requestType]
        }`;
        Context.handleAdditionalPane("申请详情", route, route, state);
      });
    }
  };

  const columns: ColumnProps[] = [
    {
      key: "invoiceNo",
      dataIndex: "invoiceNo",
      title: "发票号码",
      width: 190,
      fixed: "left",
      render: (value, row) => (
        <>
          <LinkText label={value} onClick={() => showDetail(row)} />
        </>
      ),
    },
    { key: "electricNo", title: "数电号码", width: 190 },
    { key: "invoiceTypeDesc", title: "发票类型", width: 210 },
    { key: "industryNo", title: "业态名称", width: 140 },
    { key: "invoiceDate", title: "开票日期", width: 100 },
    {
      key: "bizNo",
      title: "单据编号",
      width: 260,
      render: (value, row) => (
        <>
          <LinkText label={row.requestNo} onClick={() => showDetail(row)} />
        </>
      ),
    },
    // { key: 'contractNo', title: '合同号', width: 240, },
    { key: "sellerName", title: "销方名称", width: 240 },
    { key: "buyerName", title: "购方名称", width: 240 },
    { key: "buyerTaxNo", title: "购方税号", width: 200 },
    { key: "invoiceAmount", title: "不含税金额", width: 120, format: "amount" },
    { key: "taxAmount", title: "税额", width: 120, format: "amount" },
    { key: "totalAmount", title: "价税合计", width: 120, format: "amount" },
    { key: "invoicer", title: "开票人", width: 100 },
    { key: "invoiceCode", title: "发票代码", width: 120 },
    { key: "approver", title: "审批人", width: 130 },
    {
      key: "mailPostStatusDesc",
      title: "邮箱推送状态",
      width: 130,
      align: "center",
      render: (text, record) => {
        if (text === null) {
          return <span>无需投递</span>;
        } else {
          return text;
        }
      },
    },
    {
      key: "smsPostStatusDesc",
      title: "短信推送状态",
      width: 130,
      align: "center",
      render: (text, record) => {
        if (text === null || text === "") {
          return <span>待投递</span>;
        } else {
          return text;
        }
      },
    },
    // { key: 'machineNo', title: '分机号', width: 100 },
    {
      key: "returnFlag",
      title: "回写状态",
      width: 100,
      render: (printFlag: boolean) =>
        printFlag ? (
          <Tag color="green">已回写</Tag>
        ) : (
          <Tag color="orange">未回写</Tag>
        ),
    },
    { key: "creationTime", title: "单据时间", width: 180 },
    {
      key: "generateWayDesc",
      title: "发票产生方式",
      width: 130,
    },
    {
      key: "printFlag",
      title: "纸票打印标识",
      width: 130,
      render: (printFlag: boolean) =>
        printFlag ? (
          <Tag color="green">已打印</Tag>
        ) : (
          <Tag color="orange">未打印</Tag>
        ),
    },
    {
      key: "statusCodeDesc",
      title: "状态",
      width: 90,
      fixed: "right",
      tag: {
        colors: {
          待开票: "blue",
          开票成功: "green",
          开票失败: "orange",
          已作废: "grey",
          已红冲: "red",
        },
      },
    },
    {
      key: "id",
      title: "操作",
      width: 280,
      fixed: "right",
      render: (value, row) => (
        <Fragment>
          {/* <Link to={`/tax/outputInvoice/${row.requestNo}/detail`}>详情</Link> */}
          <LinkText label="详情" onClick={() => showDetail(row)} />
          {/* {row.allowCancel && (
            <PermissionButton
              type="link"
              permissions="OutputInvoice.cancel"
              onClick={() => handleOnCancel(row)}
            >
              作废
            </PermissionButton>
          )} */}
          {/* {row.allowCancel && (
            <PermissionButton
              type="link"
              permissions="OutputInvoice.electricCancel"
              onClick={() => handleToVoid(row)}
            >
              作废数电纸质票
            </PermissionButton>
          )} */}
          {renderCancle(row)}
          {row.allowRed && (
            <PermissionButton
              type="link"
              permissions="OutputInvoice.createRedInvoice"
              onClick={() => handleOnRed(row)}
            >
              红冲
            </PermissionButton>
          )}
          {row.allowMaskAsInvoiced && (
            <PermissionButton
              type="link"
              permissions="OutputInvoice.MaskAsInvoiced"
              onClick={() => handleOnMaskAsInvoiced(row)}
            >
              标记为已开票
            </PermissionButton>
          )}
          {row.allowRed && (
            <PermissionButton
              permissions="OutputRedRequest.redRequestApply"
              type="link"
              onClick={() => handleRedInvoice(row)}
            >
              红字申请
            </PermissionButton>
          )}
        </Fragment>
      ),
    },
  ];
  const renderCancle = (row: JSONObject) => {
    if (row.allowCancel) {
      if (row.invoiceType === 85 || row.invoiceType === 86) {
        return (
          <PermissionButton
            type="link"
            permissions="OutputInvoice.electricCancel"
            onClick={() => handleToVoid(row)}
          >
            作废
          </PermissionButton>
        );
      } else {
        return (
          <PermissionButton
            type="link"
            permissions="OutputInvoice.cancel"
            onClick={() => handleOnCancel(row)}
          >
            作废
          </PermissionButton>
        );
      }
    }
  };
  const handleToVoid = (row: JSONObject) => {
    modal.open({
      width: 600,
      content(resolve, reject) {
        return (
          <ToVoidModal
            row={row}
            table={table}
            voidForm={voidForm}
            reject={reject}
          />
        );
      },
    });
  };
  const totalAmountRef = useRef(0);
  const totalTaxAmountRef = useRef(0);
  const totalWithTaxAmountRef = useRef(0);
  const getOtherTableInfo = (res: JSONObject) => {
    totalAmountRef.current = res.totalAmount;
    totalTaxAmountRef.current = res.totalTaxAmount;
    totalWithTaxAmountRef.current = res.totalWithTaxAmount;
  };
  const tableSummary = (invoices: JSONObject[], selectIds: number[] = []) => {
    const selectInvoices = invoices.filter((invoice) =>
      selectIds?.includes(invoice.id)
    );

    const invoiceTotal = selectInvoices.reduce(
      (total, invoice) => {
        total.invoiceAmount += invoice.invoiceAmount;
        total.taxAmount += invoice.taxAmount;
        total.totalAmount += invoice.totalAmount;
        return total;
      },
      {
        invoiceAmount: 0,
        taxAmount: 0,
        totalAmount: 0,
      }
    );
    return (
      <TableSummaryRow>
        <Col span={4}>总数量: {Math.numThousandth(totalQuantity)}</Col>
        <Col span={4}>
          选中不含税金额: {Math.num2money(invoiceTotal.invoiceAmount)}
        </Col>
        <Col span={4}>选中税额: {Math.num2money(invoiceTotal.taxAmount)}</Col>
        <Col span={4}>
          本页查询结果不含税金额: {Math.num2money(totalAmountRef.current)}
        </Col>
        <Col span={4}>
          本页查询结果含税金额: {Math.num2money(totalWithTaxAmountRef.current)}
        </Col>
        <Col span={4}>
          本页查询结果税额: {Math.num2money(totalTaxAmountRef.current)}
        </Col>
      </TableSummaryRow>
    );
  };

  const syncTaxBureau = () => {
    modal.open({
      width: 560,
      content(resolve, reject) {
        return <SyncBureau onCancel={reject} onConfirm={resolve} />;
      },
    });
  };

  const handleCreatedBy = (v: string, object: JSONObject) => {
    if (object) {
      let label = object?.label;
      setInvoicer(label?.split(" | ")[0]);
    } else {
      setInvoicer("");
    }
  };
  const resetEmptyFileds = () => {
    setInvoicer("");
    return {
      invoicer: "",
    };
  };

  let printerServer: any = new PrinterServer();
  const handleToPrint = () => {
    const ids = table?.getSelectedRowKeys() ?? [];
    const invoiceType = table?.getSelectedRowAttr("invoiceType");
    const voided = table?.getSelectedRowAttr("statusCode");
    let flag = voided?.every((item) => item !== 6); //类型不为已作废
    if (ids?.length === 1) {
      if (invoiceType?.includes(85) || invoiceType?.includes(86)) {
        if (flag) {
          setPrintLoading(true);
          Http.post(`/tax/outputInvoices/printXml/${ids[0]}`)
            // Http.post(`/tax/outputInvoices/printXml/8154194271862784`)
            .then((res) => {
              handlePrintServer(res);
            })
            .finally(() => {
              setPrintLoading(false);
            });
        } else {
          message.warning("已作废的发票不允许打印");
        }
      } else {
        message.warning("请选择正确的发票类型");
      }
    } else {
      message.warning("请选择一条数据");
    }
  };
  const handlePrintServer = (base64: string) => {
    printerServer.checkPrinterVersion((result: any) => {
      message.info(result);
      if (result !== "stopped") {
        printerServer.print(0, "02", base64, (result: any) => {
          message.info(result);
        });
      }
    });
  };
  return (
    <Page>
      <Card
        title="发票管理"
        actions={
          <Space>
            <PermissionButton
              permissions="OutputInvoice.printXml"
              type="primary"
              onClick={handleToPrint}
              loading={printLoading}
            >
              打印数电纸质票
            </PermissionButton>
            <PermissionButton
              permissions="OutputInvoice.syncInvoices"
              type="primary"
              onClick={syncTaxBureau}
            >
              税局同步
            </PermissionButton>
            <PermissionButton
              permissions="OutputInvoice.markRedInvoice"
              type="primary"
              onClick={handleOnRedAsInvoiced}
            >
              标记已红冲
            </PermissionButton>
            <PermissionButton
              permissions="InputInv.wholeInvoices"
              type="primary"
              onClick={synchronousInvoice}
            >
              同步发票
            </PermissionButton>
            {/* <Button type="primary" onClick={handleCollect}>发起归集</Button> */}
            {/* <BatchPrintMd
              getIds={getIds}
              getSelectedRow={getSelectedRow}
              permissions="OutputInvoice.batchPrint"
              onTimerEnd={() => {
                table?.refresh();
              }}
            />
            <Upload
              accept=".xlsx"
              action={`${Env.getBaseUrl()}/tax/outputInvoices/importFromExcel`}
              showUploadList={false}
              headers={{
                Authorization: Context.getToken(),
              }}
              onChange={async ({ file: { status, response = {} } }) => {
                setUploadLoading(true);
                if (!["done", "error"].includes(status as string)) {
                  return;
                }
                const { data = {}, code, msg } = response;

                if (String(code) !== "200") {
                  Message.error(msg || "未知错误, 请联系管理员");
                  setUploadLoading(false);
                  return;
                }
                const {
                  headerTotal,
                  headerSuccessSize,
                  headerErrorSize,
                  headerHasError,
                  headerErrorFileUrl,
                  lineTotal,
                  lineSuccessSize,
                  lineErrorSize,
                  lineHasError,
                  lineFileUrl,
                } = data;
                if (!headerHasError && !lineHasError) {
                  table?.refresh();
                  Message.success(`全部导入成功,共导入 ${headerTotal} 张发票`);
                  return;
                }

                table?.refresh();

                await modal.open({
                  width: "600px",
                  content(_1, reject) {
                    return (
                      <Card title="导入结果" style={{ marginBottom: "0" }}>
                        <Space direction="vertical" style={{ width: "100%" }}>
                          <Row justify="center">
                            {`发票头总数量: ${headerTotal} 条, 导入成功: ${headerSuccessSize} 条, 导入失败${headerErrorSize} 条`}
                          </Row>
                          <Row justify="center">
                            {`发票行总数量: ${lineTotal} 条, 验证通过: ${lineSuccessSize} 条, 验证失败${lineErrorSize} 条`}
                          </Row>
                          <Row justify="center">
                            <Download
                              url={headerErrorFileUrl}
                              label="点击下载失败的发票头记录"
                            />
                          </Row>
                          <Row justify="center">
                            <Download
                              url={lineFileUrl}
                              label="点击下载失败的发票行记录"
                            />
                          </Row>
                          <Row justify="center">
                            <Button onClick={() => reject()}>关闭</Button>
                          </Row>
                        </Space>
                      </Card>
                    );
                  },
                });
                setUploadLoading(false);
              }}
            >
              <PermissionButton
                permissions="OutputInvoice.importFromExcel"
                type="primary"
                loading={uploadLoading}
              >
                Excel导入
              </PermissionButton>
            </Upload> */}
            <PermissionButton
              type="primary"
              permissions="OutputInvoice.sumExportManger"
              onClick={() => handleExport(true)}
            >
              汇总导出
            </PermissionButton>
            <PermissionButton
              type="primary"
              permissions="OutputInvoice.detailExportManger"
              onClick={() => handleExport(false)}
            >
              明细导出
            </PermissionButton>
            <PermissionButton
              permissions="OutputInvoice.linkWriteBackKA"
              type="primary"
              loading={KAWriteBackLoading}
              onClick={() => handleWriteBack(false)}
            >
              订单系统回写
            </PermissionButton>
            <PermissionButton
              permissions="OutputInvoice.linkWriteBack	"
              type="primary"
              loading={manualWriteBackLoading}
              onClick={() => handleWriteBack(true)}
            >
              手工回写
            </PermissionButton>
          </Space>
        }
      >
        {modalHolder}
        <Table
          onTableRef={onTableRef}
          url="/tax/outputInvoices?func=INVOICE"
          columns={columns}
          isExpandAllItem={true}
          queryProps={{
            initialValues: {
              approver: defaultApprover,
              invoiceDateFromTo,
              deptIdList: deptId,
            },
          }}
          formAdditionalParameters={{
            invoicer,
          }}
          getOtherTableInfo={getOtherTableInfo}
          selectedRowKeys={[]}
          resetEmptyFileds={resetEmptyFileds}
          pagination={{ hideOnSinglePage: false }}
          setTableSummaryData={setTableSummaryData}
          scroll={{ y: window.innerHeight - 300 }}
          summary={tableSummary}
        >
          <Item name="key">
            <Input
              placeholder="发票代码/号码/购方名称/购方税号"
              title="发票代码/号码/购方名称/购方税号"
            />
          </Item>
          <Item name="statusCode">
            {/* <Lookup
              lookupType="TAX_OUTPUT_INVOICE_STATUS"
              placeholder="发票状态"
            /> */}
             <Select placeholder="发票状态">
              <Option value="1">待开票</Option>
              <Option value="3">开票成功</Option>
              <Option value="4">开票失败</Option>
              <Option value="5">待作废</Option>
              <Option value="6">已作废</Option>
            </Select>
          </Item>
          {/* <Item name="requestDateFromTo">
            <RangePicker
              format="YYYYMMDD"
              placeholder={["申请日期从", "申请日期至"]}
              defaultValue={[
                DateUtils.monthFirstDay(),
                DateUtils.monthLastDay(),
              ]}
              inputReadOnly
              style={{ width: "100%" }}
            />
          </Item> */}
          <Item name="invoiceType">
            {/* <Lookup lookupType="TAX_INVOICE_TYPE" placeholder="发票类型" /> */}
            <Select  placeholder="发票类型">
              <Option value="31">数电票（增值税专用发票）</Option>
              <Option value="32">数电票（增值税普通发票）</Option>
              <Option value="85">数电纸质票（增值税专用发票）</Option>
              <Option value="86">数电纸质票（增值税普通发票）</Option>
            </Select>
          </Item>
          <Item name="orderNo">
            <Input placeholder="单据号/小票号" />
          </Item>
          {/* <Item name="blueInvoiceNo">
            <Input placeholder="蓝字发票号码" />
          </Item> */}
          <Item name="createdBy">
            {/* <Input placeholder="开票人" /> */}
            <Select
              placeholder="开票人"
              showSearch
              allowClear
              optionFilterProp="label"
              onChange={handleCreatedBy}
              options={empOptions}
            />
          </Item>
          {/* <Item name="printFlag">
            <Select placeholder="打印标识" allowClear>
              <Option value={1}>已打印</Option>
              <Option value={0}>未打印</Option>
            </Select>
          </Item> */}
          <Item name="generateWay">
            <Lookup
              lookupType="TAX_INVOICE_GENERATE_WAY"
              placeholder="发票来源"
            />
          </Item>
          <Item name="invoiceDateFromTo">
            <RangePicker
              format="YYYYMMDD"
              placeholder={["开票日期从", "开票日期至"]}
              defaultValue={[
                DateUtils.monthFirstDay(),
                DateUtils.monthLastDay(),
              ]}
              inputReadOnly
              style={{ width: "100%" }}
            />
          </Item>
          <Item name="industryNo">
            <Select placeholder="业态名称" options={industryItem} allowClear />
          </Item>
          {hasPermissions ? (
            <Item name="deptIdList">
              <Select placeholder="选择门店" options={deptList} allowClear />
            </Item>
          ) : (
            <></>
          )}
        </Table>
      </Card>
    </Page>
  );
};

export default List;
