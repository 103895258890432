import React, { FC, useState, useEffect } from 'react';
import { Button, Space, Form, Input, Checkbox, Select } from 'antd';
import { Link } from 'react-router-dom';

import { Page, Table, Card, LinkText, Modal, DisplayInput } from '@comps';

import { Layout, Http, Message, Api } from '@utils';
import { ColumnProps } from '@comps/types';

const { Item } = Form;

const RoleList: FC = () => {

  const [selectedRoleId, setSelectedRoleId] = useState(-1);
  const [isModalVisible, setModalVisible] = useState(false);
  const [menuOptions, setMenuOptions] = useState<any>();

  const [form] = Form.useForm();

  let [table, setTable] = useState<Table>();
  const onTableRef = (tableRef: Table) => {
    table = tableRef;
    setTable(tableRef);
  };

  useEffect(() => {
    getRootMenus();
  }, [])

  const getRootMenus = () => {
    Api.getKv('/sys/menus/rootMenusKv')
      .then(data => {
        setMenuOptions(data)
      })
  };

  const saveRole = async () => {
    await form.validateFields();

    const values = form.getFieldsValue();
    
    if (selectedRoleId < 0) {
      await Http.post('/sys/roles', values);
    } else {
      await Http.put('/sys/roles', { ...values, id: selectedRoleId });
    }

    Message.saveOk();
    setModalVisible(false);
    form.resetFields();
    table?.refresh();
  };

  const onAddOrEditClick = (roleId: number = -1, role?: any) => {
    setSelectedRoleId(roleId);
    setModalVisible(true);

    if (roleId > 0) {
      form.setFieldsValue({ ...role });
    }

    table?.refresh();
  };

  const onCancel = () => {
    form.resetFields();
    setModalVisible(false);
  };

  const columns: ColumnProps[] = [
    {
      key: 'roleCode',
      title: '角色编码',
    },
    {
      key: 'roleName',
      title: '角色名称',
    },
    {
      key: 'enabled',
      title: '启用',
      render: (value) => (value ? '启用' : '禁用'),
    },
    {
      key: 'id',
      title: '操作',
      render: (id, row) => (
        <Space size='large'>
          <Link to={{ 
            pathname: `/sys/menus/${row.pcMenuId}/roles/${id}`,
            state: {
              roleName: 
              row.roleName
            }
          }}>
            PC 菜单
          </Link>
          <LinkText 
            label='修改' 
            onClick={() => onAddOrEditClick(id, row)} 
          />
        </Space>
      )
    }
  ];

    return (
      <Page>
        <Card 
          title="角色管理" 
          actions={
            <Button 
              type='primary' 
              onClick={() => onAddOrEditClick()}
            >
              新增
            </Button>
          }
        >
          <Table 
            url="/sys/roles" 
            columns={columns} 
            rowKey="id"
            onTableRef={onTableRef}
            scroll={{ y: window.innerHeight - 300 }}
          >
            <Item name="roleName">
              <Input placeholder='角色名称' />
            </Item>
          </Table>
        </Card>

        <Modal 
          title='角色管理' 
          visible={isModalVisible}
          onCancel={onCancel}
          onOk={saveRole}
        >
          <Form 
            name="form" 
            form={form} 
            {...Layout.modal_1c}
          >
          {(selectedRoleId < 0) && (
            <Item 
              label="角色编码" 
              name="roleCode" 
              required
            >
              <Input />
            </Item>
          )}
          {(selectedRoleId >= 0) && (
            <Item 
              label='角色编码' 
              name="roleCode" 
              required
            >
              <DisplayInput />
            </Item>
          )}
          <Item 
            label="角色名称" 
            name="roleName" 
            required
          >
            <Input />
          </Item>
          {(selectedRoleId < 0) && (
            <Item 
              label='PC 端菜单树' 
              name='pcMenuId' 
              required
            >
              <Select options={menuOptions} />
            </Item>
          )}
          <Item 
            label="启用"
            name="enabled" 
            valuePropName="checked"
            required
          >
            <Checkbox />
          </Item>
        </Form>
      </Modal>
    </Page>
  );
};

export { RoleList };