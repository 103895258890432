import { Button, Card } from "@comps";
import { Space } from "antd";
import * as React from "react";
import { forwardRef, useImperativeHandle } from "react";

export interface Props {
  ref: any;
  invoiceInfo: JSONObject;
  remainQuantity: JSONObject;
  table: any;
  reject: Function;
  handleBatchIssuance: Function;
}

const PaperInvoiceInfo: React.FC<Props> = forwardRef(
  (
    { invoiceInfo, remainQuantity, table, reject, handleBatchIssuance },
    ref
  ) => {
    const [loading, setLoading] = React.useState(false);
    useImperativeHandle(ref, () => ({
      handleBatchInvoiceLoading,
    }));
    const handleBatchInvoiceLoading = (loading: boolean) => {
      setLoading(loading);
    };
    return (
      <Card title="批量开发票">
        <div style={{ padding: "16px" }}>
          <div>
            当前账号剩余库存：
            {invoiceInfo?.invoiceStock ?? 0}
          </div>
          <div>
            当前账号剩余纸质发票（增值税专用发票）份数：
            {remainQuantity?.zpStock ?? 0}
          </div>
          <div>
            当前账号剩余纸质发票（普票发票）份数：
            {remainQuantity?.ppStock ?? 0}
          </div>
          <div>
            <span style={{ marginRight: "16px" }}>
              下一张发票代码：{invoiceInfo?.nextInvoiceCode ?? ""}
            </span>
            <span>号码：{invoiceInfo?.nextInvoiceNo ?? ""}</span>
          </div>
        </div>
        <div style={{ textAlign: "center" }}>
          <Space>
            <Button
              onClick={() => {
                reject();
                setLoading(false);
                table?.refresh();
              }}
            >
              取消
            </Button>
            <Button
              loading={loading}
              type="primary"
              onClick={() => handleBatchIssuance(reject)}
            >
              确认开具
            </Button>
          </Space>
        </div>
      </Card>
    );
  }
);

export default PaperInvoiceInfo;
