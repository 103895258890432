import React, { FC } from 'react';
import { useParams } from 'react-router-dom';
import { useLocation } from 'react-router';
import { Tabs, Button } from 'antd';

import { Page, Card, BackTag } from '@comps';
import BillDetail from './billDetail';
import InvoiceDetail from './invoiceDetail';
import Statistics from './statistics';

import { Context } from '@utils';
import { LedgerSource } from '../../common';

import './index.css';

const { TabPane } = Tabs;

const ContractLedger: FC = () => {
  const routerParam: JSONObject = useParams();
  const { contractNo, ledgerSource } = routerParam;
  const routerState: any = useLocation().state;
  
  let ledgerSourceTitle = '';

  const getLedgerSourceTitle = () => {
    switch (+ledgerSource) {
      case LedgerSource.APPLY_DETAIL:
        ledgerSourceTitle = '申请详情';
        break;

      case LedgerSource.CONTACT:
        ledgerSourceTitle = '合同管理';
        break;
    
      default:
        break;
    }
  };
  getLedgerSourceTitle();

  const hanbleBack = () => {
    const { 
      key,
      title, 
      route, 
    } = Context.getTabPanesByTitle(ledgerSourceTitle);

    Context.handleAdditionalPane(title, route, key);
  };

  const lookDetail = () => {
    if(routerState) {
    const route = `/output/contract/detail/${routerState.id}`;

    Context.handleAdditionalPane('合同台账', route, route);
    }
  }

  return (
    <Page>
      <Card style={{ paddingTop: 0 }}>
        <div>
          合同编号：{contractNo} / 合同名称：{routerState ? routerState.contractName : ''}
          <Button style={{marginLeft:30}} onClick={()=>lookDetail()}>查看合同详情</Button>
        </div>
        <div className="contract-ledger-card" style={{ position: 'relative' }}>
          <BackTag 
            style={{ left: 280, top: 12 }}
            onClick={hanbleBack} 
          />
          <Tabs defaultActiveKey="1">
            <TabPane tab="单据详情" key="1">
              <BillDetail contractNo={contractNo} />
            </TabPane>
            <TabPane tab="发票详情" key="2">
              <InvoiceDetail contractNo={contractNo} />
            </TabPane>
            <TabPane tab="品类开票统计" key="3">
              <Statistics contractNo={contractNo} />
            </TabPane>
          </Tabs>
        </div>
      </Card>
    </Page>
  );
};

export default ContractLedger;