import React, { FC, useState, useRef, Fragment } from 'react';
import { Link } from 'react-router-dom'; 
import { Space, Tabs, message, Descriptions, Row, Input, Table as AdTable } from 'antd';

import { Page, Card, Table, PermissionButton, Modal, Button } from '@comps';
import OrderImportBorrowMd from "../modal/orderImportBorrow.md";
import ConfirmMd from './confirm.md';
import RejectMd from './reject.md';
import UpdateMd from './update.md';
import UpdateGoods from './updateGoods.md';

import { Http, Context, Lang, Math } from '@utils';
import { ColumnProps } from '@comps/types';
import { Change, Status } from '../interface';

export interface Props {};

const Update: FC<Props> = (props: any) => {
  const id = Context.getPathVar(props);

  const [change, setChange] = useState<Change>();
  const [isCancleStatus, setIsCancleStatus] = useState(false);

  const resultTableRef = useRef<Table>();
  const detailTableRef = useRef<Table>();

  const [model, modalHolder] = Modal.useModal();

  React.useEffect(() => {
    loadDetail();
  }, []);

  const loadDetail = async () => {
    const changeResult = await Http.get<Change>(`/tax/changes/${id}`);
    const _isCancleStatus = changeResult?.statusCode === Status.CANCEL;

    setChange(changeResult);
    setIsCancleStatus(_isCancleStatus);
  };

  const refreshInvoice = async () => {
    const changeResult = await Http.get<Change>(`/tax/changes/${id}`);
    setChange(changeResult);
    resultTableRef.current?.refresh()
    detailTableRef.current?.refresh()
  }

  const redNoticeNos = React.useMemo(() => change?.redNoticeNos || [], [change]);

  const handleConfirm = React.useCallback(() => Lang.noError(async () => {
    const params: JSONObject = {
      id: change?.id,
    };

    if (change?.needRedNoticeNo) {
      params.redNoticeNos = await model.open({
        width: '800px',
        ignoreError: false,
        content(resolve, reject) {
          return (<ConfirmMd redNoticeNos={redNoticeNos}
            onConfirm={resolve}
            onClose={reject} />);
        },
      });
    } else {
      await model.open({
        width: '600px',
        ignoreError: false,
        content(resolve, reject) {
          return (
            <Card 
              title='您确认开具发票吗？'
              style={{ marginBottom: '0' }}
            >
              <Row justify="center" style={{padding: "20px"}}>
                <Space>
                  <Button type="primary" onClick={() => resolve()}>确认开票</Button>
                  <Button onClick={() => reject()}>取消</Button>
                </Space>
              </Row>
            </Card>);
        },
      });
    }

    await Http.put('/tax/changes/confirm', params);
    Context.route('/tax/change?loadMQ=true');
  }), [change, model, redNoticeNos]);

  const handleRetry = React.useCallback(() => Lang.noError(async () => {
    await Http.get(`/tax/changes/${change?.id}/retry`);
    message.info('系统正在处理中，请耐心等待');
  }), [change]);

  const handReject = React.useCallback(() => Lang.noError(async () => {
    await model.open({
      width: 600,
      ignoreError: false,
      content(resolve, reject) {
        return (
          <RejectMd 
            id={id}
            onConfirm={resolve}
            onClose={reject} 
          />
        );
      }
    });

    message.success('拒绝成功');
    Context.route('/tax/change');
  }), [id, model]);

  const handlePrint = React.useCallback((invoice: JSONObject) => Lang.noError(async () => {
    Http.get('/tax/outputInvoices/print', {
      requestNo: invoice.invoiceId,
    });

    message.success('发票打印中,请稍后...');
  }), []);

  const handleUpdate = React.useCallback((invoice: JSONObject) => Lang.noError(async () => {
    await model.open({
      width: '600px',
      content(resolve) {
        return (
          <UpdateMd 
            invoice={invoice}
            onClose={resolve} 
          />
        );
      }
    });

    resultTableRef.current?.refresh();
  }), [model])

  const handLineEdit = React.useCallback((invoice: JSONObject) => {
    invoice._isEdit = true;
    invoice._redNoticeNo = invoice.redNoticeNo;
    resultTableRef.current?.resetDataSource();
  }, []);

  const handLineSave = React.useCallback((line: JSONObject) => Lang.noError(async () => {
    await Http.put('/tax/changes/action/redNoticeNo', {
      id: line.id,
      redNoticeNo: line._redNoticeNo
    });

    line._isEdit = false;
    resultTableRef.current?.refresh();
  }), []);

  const handLineCancel = React.useCallback((line: JSONObject) => {
    line._isEdit = false;
    resultTableRef.current?.resetDataSource();
  }, []);

  const handleRedNoticeNoChange = React.useCallback((redNoticeNo: string, line: JSONObject) => {
    line._redNoticeNo = redNoticeNo;
  }, []);

  // 更新商品信息
  const handleUpdateGoods = React.useCallback((line: JSONObject) => Lang.noError(async () => {
    await model.open({
      width: '600px',
      content(resolve) {
        return (
          <UpdateGoods 
            line={line}
            onClose={resolve} 
          />
        );
      }
    });

    detailTableRef.current?.refresh();
  }), [model])

  //导入借贷项
  const importBorrow = React.useCallback((lineId,buyerCode) => Lang.noError(async () => {
    await model.open({
      width: '1150px',
      ignoreError: false,
      content(resolve, reject) {
        return (
          <OrderImportBorrowMd 
            onRefresh={refreshInvoice} 
            onClose={reject} 
            onConfirm={resolve} 
            lineId={lineId} 
            buyerCode={buyerCode} 
            isChange={true} 
          />
        );
      }
    });
  }), [model]);

  const resultColumns: ColumnProps[] = [
    { key: 'seq', title: '序号', width: 60 },
    { key: 'groupNo', title: '票号', width: 60, render:(value, row) => (row.actionType === 3 ? value: '')  },
    { key: 'invoiceTypeDesc', title: '发票类型', width: 90, },
    { key: 'invoiceDate', title: '开票日期', width: 100, },
    { key: 'invoiceNo', title: '发票号码', width: 100, },
    { key: 'actionTypeDesc', title: '处理方式', width: 90, },
    { key: 'statusDesc', title: '处理状态', width: 90,},
    { key: 'machineNo', title: '分机号', width: 80 },
    { key: 'redNoticeNo', title: '红字通知单号', width: 200,
      render(redNoticeNo, line: JSONObject) {
        if (line._isEdit) {
          return (
            <Input 
              defaultValue={redNoticeNo}
              onChange={(event) => handleRedNoticeNoChange(event.target.value, line)} 
            />
          );
        }

        return redNoticeNo;
      }
    },
    { key: 'buyerName', title: '客户名称', width: 200 },
    { key: 'buyerTaxNo', title: '客户税号', width: 180 },
    { key: 'amount', title: '不含税金额', width: 150, format: 'amount', },
    { key: 'taxAmount', title: '税额', format: 'amount', width: 120 },
    { key: 'withTaxAmount', title: '价税合计', width: 150, format: 'amount' },
    { key: 'buyerAddrTel', title: '地址电话', width: 180, },
    { key: 'buyerBankAccount', title: '银行账号', width: 180, },
    { key: 'remark', title: '发票备注', width: 180, },
    { key: 'failureReason', title: '失败原因', width: 180, },
    {
      key: 'id', title: '操作', fixed: 'right',
      render: (_1, invoice: JSONObject) => {
        if (invoice._isEdit) {
          return (
            <>
              <Button 
                type="link" 
                size='small'
                clickCallback={() => handLineSave(invoice)}
              >
                保存
              </Button>
              <Button 
                type="link" 
                size='small'
                onClick={() => handLineCancel(invoice)}
              >
                取消
              </Button>
            </>
          );
        }
        return (
          <Space>
            {invoice.allowViewInvoice && (
              <Link to={`/tax/outputInvoice/${invoice.invoiceId}/detail`}>查看发票</Link>
            )}
            {invoice.allowPrint && (
              <Button
                type='link'
                clickCallback={() => handlePrint(invoice)}
              >
                打印
              </Button>
            )}
            {(invoice.actionType === 2 && 
              invoice.invoiceType === 0) && 
            (<Button
                 type="link" 
                 size='small' 
                 onClick={() => handLineEdit(invoice)}
              >
                &nbsp;&nbsp;编辑
              </Button>
            )}
            {invoice.redInvoiceId && (
              <Link 
                to={`/tax/outputInvoice/${invoice.redInvoiceId}/detail`} 
                target='_blank'
              >
                红票
              </Link>
            )}
            {invoice.allowUpdate && (
              <PermissionButton
                permissions="ChangeRequest.updateActions"
                type='link'
                onClick={() => handleUpdate(invoice)}
              >
                修改
              </PermissionButton>
            )}
          </Space>
        );
      }
    },
  ];
  const columns: ColumnProps[] = [
    { key: 'labelSeq', title: '序号', width: 60 },
    { key: 'groupNo', title: '票号', width: 60 },
    { key: 'goodsInvoiceName', title: '商品名称', width: 250, },
    { key: 'taxCode', title: '税收分类编码', width: 200,},
    { key: 'spec', title: '规格型号', width: 100, },
    { key: 'unit', title: '单位', width: 60, },
    { key: 'quantity', title: '数量', width: 80, },
    { key: 'price', title: '不含税单价', width: 110 },
    { key: 'taxRate', title: '税率', width: 60 },
    { key: 'amount', title: '不含税金额', format: 'amount', width: 120 },
    { key: 'taxAmount', title: '税额', width: 100, format: 'amount' },
    { key: 'withTaxAmount', title: '含税金额', width: 130, format: 'amount',},
    { key: 'invoiceTypeDesc', title: '新类型', width: 80, },
    { key: 'oriInvoiceNo', title: '原发票号码', width: 110, },
    { key: 'oriInvoiceTypeDesc', title: '原类型', width: 80, },
    { key: 'id', title: '操作', width: 100, fixed:'right',
      render:(id, line:JSONObject) => (
        <Fragment>
          <PermissionButton
            permissions="ChangeRequest.updateGoods"
            type='link'
            onClick={() => handleUpdateGoods(line)}
          >
            修改
          </PermissionButton>
        </Fragment>
      )
    }
  ];
  
  const summary = (<>
    {[
      ['totalStats', '合计'],
      ['specialStats', '专票合计'],
      ['normalStats', '普票合计'],
    ].map(([propName, label] ) => {
      return (
        <AdTable.Summary.Row key={propName}>
          <AdTable.Summary.Cell index={1} colSpan={6}>{label}</AdTable.Summary.Cell>
          <AdTable.Summary.Cell index={2}>
            {(change as JSONObject)?.[propName]?.quantity}
          </AdTable.Summary.Cell>
          <AdTable.Summary.Cell index={3} colSpan={3}>
            <div style={{ textAlign: 'right' }}>
              { Math.num2money((change as JSONObject)?.[propName]?.amount) }
            </div>
          </AdTable.Summary.Cell>
          <AdTable.Summary.Cell index={4}>
            <div style={{ textAlign: 'right' }}>
              { Math.num2money((change as JSONObject)?.[propName]?.taxAmount) }
            </div>
          </AdTable.Summary.Cell>
          <AdTable.Summary.Cell index={5}>
            <div style={{ textAlign: 'right' }}>
              { Math.num2money((change as JSONObject)?.[propName]?.withTaxAmount) }
            </div>
          </AdTable.Summary.Cell>
          <AdTable.Summary.Cell index={6} colSpan={20}></AdTable.Summary.Cell>
        </AdTable.Summary.Row>
      );
    })}
  </>);

  return (
    <Page backable>
      {modalHolder}
      <Card title={`改票申请 ${change?.requestNo} ${change?.statusCodeDesc}` }
        actions={(
        <Space>
          {change?.allowRetry && (
            <PermissionButton 
              type="primary"
              clickCallback={handleRetry}
              permissions="ChangeRequest.retry"
            >
              重试
            </PermissionButton>
          )}
          {change?.statusCode === Status.IN_APPROVAL && (
            <>
              <PermissionButton 
                type="primary"
                clickCallback={handleConfirm}
                permissions="ChangeRequest.confirm">
                确认开票
              </PermissionButton>
              <PermissionButton 
                type="primary"
                clickCallback={handReject}
                permissions="ChangeRequest.reject">
                拒绝开票
              </PermissionButton>
            </>
          )}
        </Space>)}>
        <Descriptions layout="vertical" column={5}>
          <Descriptions.Item label="申请人">
            {change?.submitterName}
          </Descriptions.Item>
          <Descriptions.Item label="申请时间">
            {change?.submitTime}
          </Descriptions.Item>
          <Descriptions.Item label="审批人">
            {change?.approverName}
          </Descriptions.Item>
          <Descriptions.Item label="审批时间">
            {change?.approveTime}
          </Descriptions.Item>
          <Descriptions.Item label="拒绝原因">
            {change?.approveRemarks}
          </Descriptions.Item>
        </Descriptions>
        <Tabs defaultActiveKey='1'>  
          <Tabs.TabPane tab="改票明细" key="1">
            <Table 
              url={`/tax/changes/${id}/lines`}
              onTableRef={ref => detailTableRef.current = ref}
              pagination={false}
              columns={columns}
              summary={() => summary}>
            </Table>
          </Tabs.TabPane>
          {!isCancleStatus && (
            <Tabs.TabPane tab="改票结果" key="2">
              <Table url={`/tax/changes/${id}/actions`}
                onTableRef={ref => resultTableRef.current = ref}
                pagination={false}
                columns={resultColumns}>
              </Table>
            </Tabs.TabPane>
          )}
        </Tabs>
      </Card>
    </Page>
  );
};

export default Update;
