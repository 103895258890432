import { Card, LinkText, Modal, Page, PermissionButton, Table } from "@comps";
import { ColumnProps } from "@comps/types";
import { UserContext } from "@models";
import { FormUtil, Http } from "@utils";
import { Button, DatePicker, Form, Input, Space, message } from "antd";
import React, { FC, useState } from "react";
import { useSelector } from "react-redux";
import ApprovalModal from "./modal/approvalModal";
import InvoiceApprovalModal from "./modal/invoiceApprovalModal";
import InvoiceApprovaldetailModal from "./modal/invoiceApprovaldetailModal";
import Makesuretheapprove from "./modal/makesuretheapprove";

const { Item } = Form;
const { RangePicker } = DatePicker;

const zzsColumns: ColumnProps[] = [
  { key: "projectName", title: "项目名称", width: 140, align: "center" },
  {
    key: "amount",
    title: "本期发生",
    width: 140,
    align: "center",
    render(value, tableRow: JSONObject) {
      if (tableRow.projectName === "增值税税负率") {
        return value === null ? 0 : (value * 100).toFixed(2) + "%";
      } else {
        return value === null ? 0 : value.toLocaleString();
      }
    },
  },
  {
    key: "amountAccumulated",
    title: "本年累计",
    width: 140,
    align: "center",
    render(value, tableRow: JSONObject) {
      if (tableRow.projectName === "增值税税负率") {
        return value === null ? 0 : (value * 100).toFixed(2) + "%";
      } else {
        return value === null ? 0 : value.toLocaleString();
      }
    },
  },
];

const otherColumns: ColumnProps[] = [
  { title: "项目", key: "projectName", width: 100 },
  {
    title: "金额",
    key: "amount",
    width: 100,
    render(value: any, record: any, index: number) {
      if (record.projectName === "企业所得税税负率") {
        return value ? value + "%" : 0;
      } else {
        return value ? value.toLocaleString() : 0;
      }
    },
  },
];

const InvoiceApproval: FC = () => {
  // const period = moment().subtract(1, 'months')
  const [modal, modalHolder] = Modal.useModal();
  const userInfo = useSelector<JSONObject, UserContext>((state) => state.ctx);
  const [orgId, setOrgId] = useState<number>(userInfo.orgId || 0);
  const desc = React.useRef<Input>(null);
  let [table, setTable] = useState<Table>();
  const onTableRef = (tableRef: Table) => {
    table = tableRef;
    setTable(tableRef);
  };

  const correct = () => {
    modal.open({
      width: 500,
      content(resolve, reject) {
        return <InvoiceApprovalModal onCancel={reject} onConfirm={resolve} />;
      },
    });
  };

  const approval = async () => {
    const selectedRow = table?.getSelectedRowData();
    if (!selectedRow || selectedRow.length !== 1) {
      message.warning("请选择一条审批事项");
      return;
    }
    const { sourceId, approveType } = selectedRow[0];
    let param = selectedRow[0];
    if (approveType === 1) {
      const [dg] = await Promise.all([
        Http.get(`/taxtypedg/manuscriptApproval/${sourceId}`),
      ]);
      param = { ...param, dg };
    }
    await draftModal(param);
  };

  const draftModal = async (rowData: JSONObject) => {
    const { taxName } = rowData;
    switch (taxName) {
      case "增值税":
        rowData = { ...rowData, approvalColumns: zzsColumns };
        break;
      case "企业所得税（季度）":
        rowData = { ...rowData, approvalColumns: otherColumns };
        break;
      default:
        break;
    }
    await modal.open({
      width: "800px",
      content(_1, reject) {
        return (
          <ApprovalModal
            data={rowData}
            onClose={reject}
            query={table?.refresh}
          ></ApprovalModal>
        );
      },
    });
  };

  const setFormOrgNameValue = (orgNameVal: string) => {
    setOrgId(Number(orgNameVal || 0));
  };

  const exportExcel = () => {
    const { taxName, approveStatus, period, matterName } =
      table?.getFormParam() ?? {};
    FormUtil.exportExcelFile(`/approval/excelExport`, {
      orgId,
      taxName: taxName ? taxName : "",
      approveStatus: approveStatus ? approveStatus : "",
      period: period ? period : "",
      matterName: matterName ? matterName : "",
    });
  };

  // 查看功能
  const viewData = (record: any) => {
    Http.get(`/outputInvoiceApproval/${record.id}`).then((res) => {
      console.log(res);
      modal.open({
        width: 1250,
        content(reslove, reject) {
          return (
            <InvoiceApprovaldetailModal
              table={table}
              record={record}
              onClose={reject}
              data={res}
            />
          );
        },
      });
    });
  };

  const approveData = (record: any) => {
    modal.open({
      width: 500,
      content(reslove, reject) {
        return (
          <Makesuretheapprove
            refersh={table?.refresh}
            data={record}
            onClose={reject}
          />
        );
      },
    });
  };

  const columns: ColumnProps[] = [
    { key: "serialNo", title: "审批流水号", width: 140, align: "center" },
    { key: "bizNo", title: "单据号", width: 140, align: "center" },
    // { key: 'matterName', title: "客户名称", width: 140, align: "center" },
    { key: "approvaler", title: "当前审批人", width: 140, align: "center" },
    {
      key: "approvalStatus",
      title: "审批状态",
      width: 140,
      align: "center",
      render: (value, record) => {
        let label: string = "";
        switch (value) {
          case 2:
            label = "审批驳回";
            break;
          case 1:
            label = "审批通过";
            break;
          default:
            label = "审批中";
            break;
        }
        return <span>{label}</span>;
      },
    },
    { key: "deptName", title: "门店名称", width: 140, align: "center" },
    { key: "invoiceNo", title: "发票号码", width: 140, align: "center" },
    // { key: 'submitter', title: "提交人", width: 140, align: "center" },
    { key: "creationTime", title: "申请时间", width: 140, align: "center" },
    {
      key: "id",
      title: "操作",
      width: 100,
      fixed: "right",
      align: "center",
      render: (value, record) => {
        return (
          <Space>
            <LinkText label="查看" onClick={() => viewData(record)} />
            {/* <LinkText label="审批" onClick={() => approveData(record)} /> */}
            <Button
              type="link"
              style={{
                color:
                  record.approvalStatus === 1 ||
                  record.approvalStatus === 2 ||
                  !record.isCurrentApprovaler
                    ? "#a8a8a8"
                    : "#2680D8",
              }}
              onClick={() => approveData(record)}
              disabled={
                record.approvalStatus === 1 ||
                record.approvalStatus === 2 ||
                !record.isCurrentApprovaler
              }
            >
              审批
            </Button>

            {/* <Popconfirm
              title="您确认要删除吗?"
              onConfirm={() => delData(record)}
            >
              <LinkText label="删除" />
            </Popconfirm> */}
          </Space>
        );
      },
    },
  ];
  return (
    <Page>
      {modalHolder}
      <Card
        title="审批事项"
        // actions={
        //   <Space>
        //     <PermissionButton
        //       permissions="Approval.correctApprovalStatus"
        //       type="primary"
        //       onClick={correct}
        //     >
        //       批量审批
        //     </PermissionButton>
        //   </Space>
        // }
      >
        <Table
          url="/outputInvoiceApproval/list"
          columns={columns}
          selectedRowKeys={[]}
          onTableRef={onTableRef}
          // formAdditionalParameters={{
          //     orgId
          // }}
          resetEmptyFileds={{ orgId: userInfo.orgId || 0 }}
          scroll={{ y: window.innerHeight - 350 }}
        >
          <Item name="bizNo">
            <Input placeholder="单据号" />
          </Item>
          <Item name="taxName">
            <DatePicker style={{ width: "100%" }} />
          </Item>
          {/* <Item name="invoiceNo">
                        <Lookup lookupType="APPROVE_RESULT" placeholder="请选择审批状态"></Lookup> */}
          {/* <Select
                            //   onChange={onIdentityChange}
                            placeholder="请选择审批状态"
                            options={[
                                {
                                    value: ,
                                    label: "审批中",
                                },
                                {
                                    value: ,
                                    label: "审批通过",
                                },
                                {
                                    value: ,
                                    label: "审批驳回",
                                }
                            ]}
                        /> */}

          {/* </Item> */}
          <Item name="deptName">
            <Input placeholder="门店名称" />
          </Item>
          {/* <Item name="buyerName">
            <Input placeholder="购方名称" />
          </Item> */}
          <Item name="invoiceNo">
            <Input placeholder="发票号码" />
          </Item>
        </Table>
      </Card>
    </Page>
  );
};

export default InvoiceApproval;
