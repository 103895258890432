import React, { FC, useState, useImperativeHandle, forwardRef, memo } from 'react';
import { Button, Space, Popconfirm } from 'antd';

import { Card, Table, LinkText, Modal } from '@comps';
import UploadAnnexMd from './modal/upload-annex';
 
import { Http, Message } from '@utils';
import { ColumnProps } from '@comps/types';

interface Props {
  ref: any,
  detailId: number,
  setPageLoading: (isLoading: boolean) => void
};

const AnnexTable: FC<Props> = forwardRef(({
  detailId,
  setPageLoading
 }, ref) => {

  const [modal, modalHolder] = Modal.useModal();

  const [isShowAnnexUploadBTN, setIsShowAnnexUploadBTN] = useState(true);

  let [table, setTable] = useState<Table>();
  const onTableRef = (tableRef: Table) => {
    setTable(tableRef);
    table = tableRef;
  };

  useImperativeHandle(ref, () => ({
    setIsShowAnnexUploadBTN
  }));

  const refreshTable = () => {
    table?.refresh();
  };

  const showUploadAnnexMD = () => {
    modal.open({
      width: 400,
      content: (_1, reject) => (
        <UploadAnnexMd
          refreshTable={refreshTable}
          baseFormId={detailId}
          onClose={reject}
        />
      )
    })
  };

  const hanldeDelete = (id: number) => {
    setPageLoading(true);

    Http.del(`/request/deleteAttachment/${id}`)
      .then(() => {
        Message.success('删除成功');
        table?.refresh();
      })
      .finally(() => {
        setPageLoading(false);
      })
  };

  const columns: ColumnProps[] = [
    { key: 'attachmentName', title: '附件名称', width: 300, fixed: 'left' },
    { key: 'uploadTime', title: '上传时间', width: 280 },
    { key: 'remark', title: '备注', width: 250 },
    { key: 'id', title: '操作', width: 130, align: 'center', fixed: 'right',
      render: (id: number, row: JSONObject) => (
        <Space size="large">
          {/* <LinkText label="查看" /> */}
          <a href={row.fileUrl} target="_self">下载</a>
          <Popconfirm
            title={`是否删除【${row.attachmentName}】?`}
            onConfirm={() => hanldeDelete(id)}
          >
            <LinkText label="删除" />
          </Popconfirm>
        </Space>
      )
    }
  ];

  return (
    <Card title="附件" actions={
      isShowAnnexUploadBTN
        ? <Button type="primary" onClick={showUploadAnnexMD}>上传附件</Button>
        : <></>
    }>
      {modalHolder}
      <Table
        onTableRef={onTableRef}
        url={`/request/${detailId}/attachment`}
        columns={columns}
        pagination={false}
      />
    </Card>
  );
});

export default AnnexTable;
