import React, { FC, useState, useMemo } from 'react';
import { Space, Select, Form, DatePicker, Input, Popconfirm, Modal as AdModal } from 'antd';
import { Link } from 'react-router-dom';

import { Page, Card, Button, Table, Modal, PermissionButton, Lookup, Download } from '@comps';
import CancelOrderMD from './cancelOrder.md';
import { ColumnProps } from '@comps/types'

import { Context, DateUtils, Http, Env, Message } from '@utils'
import { MailingStatus } from './interface'

import './index.css'

const { Option } = Select
const { Item } = Form
const { confirm } = AdModal
const { RangePicker } = DatePicker

const Mail: FC = () => {
  const [modal, modalHolder] = Modal.useModal()

  let currentExportType: number = 0

  const [table, setTable] = useState<Table>()
  const onTableRef = (tableRef: Table) => {
    setTable(tableRef)
  }

  const initQuery = useMemo<JSONObject>(() => ({
    mailingDateFromTo: [DateUtils.monthFirstDay(), DateUtils.monthLastDay()]
  }), [])
  
  const handleDelete = (ids: number[]) => {
    Http.del('/tax/mailingManagement/deleteMailingOrder', { ids })
      .then(() => {
        Message.success('删除成功')
        table?.refresh()
      })
  }
  
  const handleBatchDelete = async () => {
    const IDs: number[] = table?.getSelectedRowKeys() || [];

    if (IDs?.length === 0) {
      Message.warn('请至少勾选一条数据');
      return
    }

    handleDelete(IDs);
  }

  const showExportAllConfirm = () => {
    confirm({
      title: '提示',
      content: '是否导出所有数据',
      onOk: handleExportAllData
    })
  }

  const handleCancelOrder = (orderId: string) => {
    modal.open({
      width: '300px',
      content(_1, reject) {
        return (<CancelOrderMD orderId={ orderId }  onClose={reject} />)
      }
    });
  }

  const handleSplicingURL = () => {
    const recordExportURL = '/tax/mailingManagement/exportMailingRecord'
    const invoiceExportURL = '/tax/mailingManagement/exportMailingInvoicesRecord'
    
    let currentURL = currentExportType ? invoiceExportURL : recordExportURL
    let URL = `${Env.getBaseUrl()}${currentURL}?accessToken=${Context.getToken()}`

     // 导出时添加查询参数
     const queryParams = table?.getQueryPanelParams()
     for (let key in queryParams) {
      URL += `&${key}=${queryParams[key]}`
     }
     
     return URL
  }

  const handleExportData = () => {
    if (table?.getSelectedRowKeys()?.length === 0) {
      showExportAllConfirm()
      return
    }

    let URL = handleSplicingURL()
    URL += `&ids=${table?.getSelectedRowKeys()?.join()}`
    window.open(URL, '_blank')
  }

  const cancleOrderBTNClick = () => {
    const selectedNum: number = table?.getSelectedRowKeys()?.length || 0
    const mailingStatusArr: number[] = table?.getSelectedRowAttr('mailingStatus') || []

    if (selectedNum === 0) {
      Message.warn('请选择一条记录')
      return
    } else if (selectedNum > 1) {
      let isAllOrdered = true
      mailingStatusArr?.forEach(mailingStatus => {
        if (mailingStatus !== MailingStatus.ORDERER) {
          isAllOrdered = false
          Message.warn('必须选择邮寄状态为已下单的记录')
          return
        }
      })

      if (isAllOrdered) {
        Message.warn('仅允许选择一条记录')
        return
      }
    } 
    
    if (selectedNum === 1 && mailingStatusArr[0] === MailingStatus.ORDERER) {
      const orderIDArr: string[] = table?.getSelectedRowAttr('orderId') || []
      handleCancelOrder(orderIDArr[0])
    } else {
      Message.warn('必须选择邮寄状态为已下单的记录')
    }
  }

  const handleExportAllData = () => {
    const URL = handleSplicingURL()
    window.open(URL, '_blank')
  }

  const handleExportTypeChange = (type: any) => {
    currentExportType = type
    handleExportData()
  }

  const handlePush = (orderId: number) => {
    Http.post('/tax/mailingManagement/pushMailingPrint', { orderId: orderId + '' })
      .then(() => {
        Message.success('推送成功')
        table?.refresh()
      })
  }

  const columns: ColumnProps[] = [
    { key: 'trackingNumber', title: '快递单号', width: 220, fixed: 'left' },
    { key: 'orderId', title: '订单号', width: 280 },
    { key: 'mailingDate', title: '邮寄日期', width: 160 },
    { key: 'sender', title: '寄件人', width: 120 },
    { key: 'senderTel', title: '寄件人联系电话', width: 160 },
    { key: 'senderAddress', title: '寄件人详细地址' , width: 260 },
    { key: 'recipient', title: '收件人', width: 100 },
    { key: 'recipientTypeDesc', title: '收件人类别', width: 160 },
    { key: 'receivingCompany', title: '收件公司名称', width: 260 },
    { key: 'recipientTel', title: '收件人联系电话', width: 160 },
    { key: 'recipientAddress', title: '收件人详细地址', width: 260 },
    { key: 'userName', title: '操作用户', width: 100 },
    { key: 'mailingStatus', title: '邮寄状态', width: 100, fixed:'right',
      render: status => {
        if (status === 1) {
          return <span style={{ color: '#41a841' }}>已下单</span>
        } else if (status === 2) {
          return '邮寄中'
        } else if (status === 3) {
          return '已签收'
        } else if (status === 4) {
          return <span style={{ color: '#f00' }}>取消下单</span>
        } else if (status === 5) {
          return <span style={{ color: '#f00' }}>退回</span>
        } else {
          return '未知'
        }
      }
    },
    {
      key: 'orderId', title: '操作', width: 180, fixed:'right', align: 'center',
      render (orderId, row) {
        return (
          <>
            {
              row.print 
                ?<Download url={row.pathPdfUrl} label='打印' type="linkButton" preview={true} />
                :<Popconfirm title='您确认要推送吗?' onConfirm={() => {handlePush(orderId)}}>
                  <Button type="link">推送</Button>
                 </Popconfirm>            
            }
            <Link to={`/tax/mail/${orderId}/detail`} style={{ paddingLeft: 8, paddingRight: 10 }}>详情</Link>
            <Popconfirm title='您确认要删除吗?' onConfirm={() => handleDelete([row.id])}>
              <Button type="link">删除</Button>
            </Popconfirm>
          </>
        )
      }
    }
  ]

  return (
    <Page>
      {modalHolder}

      <Card title="邮寄管理" actions={(
        <Space className="card-space">
            <Button type="primary" 
                    onClick={cancleOrderBTNClick}>
                    取消下单</Button>
            <Select placeholder="导出" onSelect={handleExportTypeChange} style={{ width: 130 }}>
              <Option value={0}>邮寄记录导出</Option>
              <Option value={1}>邮寄发票导出</Option>
            </Select>
            <Button type="primary" 
                    onClick={handleBatchDelete}>
                    批量删除</Button>
          </Space>
        )}>
        <Table url='/tax/mailingManagement'
            queryProps={{
              initialValues: initQuery
            }}
            selectedRowKeys={[]}
            pagination={{hideOnSinglePage:false}}
            columns={columns}
            onTableRef={onTableRef}>
            <Item name='infos'>
              <Input placeholder="快递单号/订单号/寄件人/收件公司" title="快递单号/订单号/寄件人/收件公司" />
            </Item>
            <Item name="mailingStatus">
              <Select placeholder="邮寄状态">
                <Option value={1}>已下单</Option>
                <Option value={2}>邮寄中</Option>
                <Option value={3}>已签收</Option>
                <Option value={4}>取消下单</Option>
                <Option value={5}>退回</Option>
              </Select>
            </Item> 
            <Item name='mailingDateFromTo'>
              <RangePicker placeholder={['邮寄日期起', '邮寄日期止']} format='YYYYMMDD' style={{ width: '100%' }}
                defaultValue={[DateUtils.monthFirstDay(), DateUtils.monthLastDay()]} inputReadOnly/>
            </Item>
            <Item name="recipientType">
              <Lookup placeholder="收件人类别" lookupType="MAILING_ROLE"/>
            </Item> 
          </Table>
      </Card>
    </Page>
  )
}

export default Mail