import React, { FC, useState, useEffect, memo } from 'react';
import { Space, Form, Row, Col, Button, Input, InputNumber, Select } from 'antd';

import { Card, MatterCategory, DisplayInput } from '@comps';

import { Http, Message, Context } from '@utils';

interface Props {
  baseFormId: number;
  isCopy: boolean;
  copyRowData: JSONObject;
  setAllModuleData: (data: JSONObject) => void;
  onClose: () => void;
}

const { Item } = Form;

const AddOrCopyApplyDetail: FC<Props> = memo(({
  baseFormId,
  isCopy,
  copyRowData,
  setAllModuleData,
  onClose,
}) => {

  const [form] = Form.useForm();
  const formLayout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 17 },
  };

  const [loading, setLoading] = useState(false);
  const [contractNumOptions, setContractNumOptions] = useState<any[]>([]);
  const [contractInfo, setContractInfo] = useState<JSONObject[]>([]);

  useEffect(() => {
    handleAddOrCopy();
    getContractNum();
  }, []);

  const handleAddOrCopy = () => {
    isCopy && form.setFieldsValue(copyRowData);
  };

  const getTaxCode = () => {
    const { goodsName } = form.getFieldsValue();

    Http.get('/request/queryTaxCode', { goodsName })
      .then(data => {
        if (data) {
          const { taxCode } = data;
          form.setFieldsValue({ taxCode });

          Message.success('获取成功');
        } else {
          Message.info('没有对应的税收分类编码');
        }
      })
  };

  const handleSave = () => {
    setLoading(true);

    const formData = form.getFieldsValue();
    
    Http.post(`/request/${baseFormId}/addRequestLine`, formData)
      .then(data => {
        setAllModuleData(data);
        Message.success(isCopy ? '复制成功' : '添加成功');
        onClose();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getContractNum = () => {
    Http.get(`/tax/goods/global/list?parentId=${-1}`)
      .then(data => {
        setContractInfo(data);
        setContractNumByContractInfo(data);
      })
  }

  const setContractNumByContractInfo = (contractInfo: JSONObject[]) => {
    const _contractNumOptions = contractInfo.map(contractItem => ({
      label: contractItem.goodsName, 
      value: contractItem.id,
      title: contractItem.goodsName, 
    }));

    setContractNumOptions(_contractNumOptions);
  }

  const handleContractNumChange = (contractNum: any) => {
    const currentSelected = contractInfo.find(
      contractItem => contractItem.id === contractNum
    ) ?? {};
    
    form.setFieldsValue({
      goodsName: currentSelected.goodsName,
      taxCode: currentSelected.taxCode  
    });
  }

  return (
    <Card title="添加开票申请明细">
      <Form 
        {...formLayout}
        form={form}  
        onFinish={handleSave}
      >
        <Row style={{ marginTop: 20 }}>
          <Col span={8}>
          <Item 
            label="开票商品名称"
            name="goodsName"
            rules={[{ required: true }]}
          >
            <Select
              showSearch
              optionFilterProp="label" 
              options={contractNumOptions}
              onChange={handleContractNumChange}
            />
          </Item>
        </Col>
        <Col span={8}>
          <Item label="税收分类编码" name="taxCode">
            <DisplayInput />
          </Item>
        </Col>
          <Col span={8}>
            <Item 
              label="产品编码" 
              name="goodsNo"
            >
              <Input />
            </Item>
          </Col>
          <Col span={8}>
            <Item 
              label="商品描述" 
              name="goodsDescription"
            >
              <Input />
            </Item>
          </Col>
          <Col span={8}>
            <Item 
              label="规格型号" 
              name="spec"
            >
              <Input />
            </Item>
          </Col>
          <Col span={8}>
            <Item 
              label="单位" 
              name="unit"
              rules={[{ required: true }]}
            >
              <Input />
            </Item>
          </Col>
          <Col span={8}>
            <Item 
              label="含税单价" 
              name="withTaxPrice"
              rules={[{ required: true }]}
            >
              <InputNumber 
                style={{ width: '100%' }} 
              />
            </Item>
          </Col>
          <Col span={8}>
            <Item 
              label="数量" 
              name="quantity"
              rules={[{ required: true }]}
            >
              <InputNumber 
                style={{ width: '100%' }} 
              />
            </Item>
          </Col>
          <MatterCategory
            form={form}
            isEdit={isCopy}
            rowData={copyRowData}
            categoryNames={[
              'largeCategory', 
              'mediumCategory', 
              'smallCategory',
            ]}
            colSpan={8}
            isMiddleCategoryRequired={true}
          />
          {/* <Col 
            span={8} 
            style={{ textAlign: 'center' }}
          > 
            <Button 
              onClick={getTaxCode}
            >
              获取税收分类编码
            </Button>
          </Col> */}
        </Row>
        <Row>
          <Col 
            span={24} 
            style={{ textAlign: 'center' }}
          >
            <Space size="large">
              <Button 
                type="primary" 
                htmlType="submit" 
                loading={loading}
              >
                确定
              </Button>
              <Button 
                onClick={onClose}
              >
                取消
              </Button>
            </Space>
          </Col>
        </Row>
      </Form>
    </Card >
  );
});

export default AddOrCopyApplyDetail;

