/**
 * 角色菜单与权限分配
 */
import React, { FC, useState, useEffect, Fragment } from 'react'
import { useLocation } from 'react-router'
import { Page, Card, LinkText, BackTag } from '@comps'
import { Http, Message } from '@utils'
import { Row, Checkbox, Col, Space } from 'antd'

interface LeafMenuProps {
    key?: number,
    roleId: number,
    menuId: number,
    menuName: number,
    isRoleGranted: boolean,
    permissions: [],
}

interface PermissionProps {
    key?: number,
    permissionId: number,
    permissionName: number,
    isRoleGranted: boolean,
    onChange: (permissionId: number, checked: boolean) => void,
}

interface MenuProps {
    roleId: number,
    menuId: number,
    menuName: number,
    isRoleGranted: boolean,
    subMenus: [],
}

const Permission: FC<PermissionProps> = (props) => {

    const onChange = (e: any) => {
        props.onChange(props.permissionId, e.target.checked)
    }

    return (
        <Checkbox checked={props.isRoleGranted} onChange={onChange} key={props.key}>{props.permissionName}</Checkbox>
    )
}
/**
 * 叶子菜单
 */
const LeafMenu: FC<LeafMenuProps> = (props) => {
    let checkedPermissionSize = 0
    props.permissions?.map((e: any) => { if (e.isRoleGranted) { checkedPermissionSize++ } })

    let [menuChecked, setMenuChecked] = useState(props.isRoleGranted)
    let [permissionSize, setPermissionSize] = useState(checkedPermissionSize)
    let [permissions, setPermissions] = useState(props.permissions)
    let roleId = props.roleId

    const onMenuChange = (e: any) => {
        let checked = e.target.checked
        setMenuChecked(checked)

        let params = { roleId: roleId, menuId: props.menuId }
        if (checked) {
            Http.post(`/sys/menus/roleMenus`, params)
        } else {
            Http.del(`/sys/menus/roleMenus`, params)
        }

        permissions.map((e: any) => e.isRoleGranted = checked)
        setPermissions(permissions)

        Message.ok()
    }

    /** 权限发生改变 */
    const onPermissionChange = (permissionId: number, checked: boolean) => {
        let size = permissionSize + (checked ? +1 : -1)
        let params = { roleId: roleId, menuId: props.menuId, permissionId: permissionId }
        if (checked) {
            Http.post('/sys/menus/rolePermission', params).then(data => { })
        } else {
            Http.del('/sys/menus/rolePermission', params).then(data => { })
        }

        setPermissionSize(size)
        setMenuChecked(size > 0)
        permissions.map((e: any) => {
            if (e.permissionId === permissionId) {
                e.isRoleGranted = checked
            }
            return e;
        })
        setPermissions(permissions)

        Message.ok()
    }

    return (
        <Fragment>
            <Row>
                <Col offset={1}>
                    <Checkbox checked={menuChecked} onChange={onMenuChange}>{props.menuName}</Checkbox>
                </Col>
            </Row>
            <Row>
                <Col offset={2} span={22}>
                    <Row>
                        {permissions && permissions.map((permission: any) =>
                            <Col span={4}>
                                <Permission {...permission} onChange={onPermissionChange} key={permission.id} />
                            </Col>
                        )}
                    </Row>
                </Col>
            </Row>
            <br />
        </Fragment>
    )
}

const Menu: FC<MenuProps> = (props) => {
    let [expand, setExpand] = useState(true)

    const onExpand = () => {
        setExpand(!expand)
    }

    return (
        <div style={{ backgroundColor: '#FAFAFA', padding: '20px', marginBottom: '20px' }}>
            <Row>
                <Space size='large'>
                    <span style={{ fontWeight: 'bold', fontSize: '16px' }}>{props.menuName}</span>
                    <LinkText label={expand ? '收起' : '展开'} onClick={onExpand} />
                </Space>
            </Row>
            <div style={{ marginTop: '10px' }}>
                {expand && props.subMenus?.map((leafMenu: any) => <LeafMenu {...leafMenu} roleId={props.roleId} />)}
            </div>
        </div>)
}

const RoleMenu: FC = (props: any) => {
    let params: any = props.match.params
    let { menuId, roleId } = params

    const state: any = useLocation().state;
    const roleName = state.roleName;

    let [menus, setMenus] = useState<any[]>()

    useEffect(() => {
        Http.get(`/sys/menus/roleMenuTree?roleId=${roleId}&menuId=${menuId}`).then((data: any) => {
            setMenus(data.subMenus)
        })
    }, [roleId, menuId])

    return (
        <Page> 
            <Card title={`角色菜单与权限管理（${roleName}）`} style={{ position: 'relative' }}>
                <BackTag style={{ top: 18, left: 300 }} />
                {menus?.map(menu => !menu.isLeaf && <Menu {...menu} roleId={roleId} />)}
            </Card>
        </Page>
    )
}

export { RoleMenu }