import React, { FC, Fragment, useEffect, useRef, useState } from "react";
import { Card, Lookup, Page, Table, LinkText, Download, DeptSelect, PermissionButton, Modal as _Modal } from "@comps";
import { Form, DatePicker, Input, Button, Space, Select, Popconfirm, Tabs, Modal, message } from "antd"
import { ColumnProps } from '@comps/types';
import { Http } from "@utils";
import { UserContext } from "@models";
import { useSelector } from "react-redux";
import MeansActionModal from "../component/meansActionModal";
import TextArea from "antd/lib/input/TextArea";

import Status from "./modal/status";

const { Item } = Form
const { RangePicker } = DatePicker
const { TabPane } = Tabs;
const { Option } = Select;

let columns: ColumnProps[] = [
  // {
  //   title: "序号",
  //   key: "index",
  //   width: 120,
  //   render: (text, record, index) => `${index + 1}`
  // },
  {
    title: "状态", key: "status", width: 120, render: (value) => {
      switch (value) {
        case 0:
          return "草稿"
        case 1:
          return "已发布"
        default:
          break;
      }
    }
  },
  { title: "公司名称", key: "orgName", width: 200 },
  { title: "档案名称", key: "meansName", width: 150 },
  { title: "是否被替换", key: "whetherReplace", width: 150,
  render(value, record) {
    if (value === 1) {
      return "是"
    } else {
      return "否"
    }
  } },
  { title: "建档时间", key: "establishDate", width: 120 },
  { title: "当前负责人", key: "chargePeople", width: 120 },
]

const Means: FC = () => {

  const actionJsx = ({
    key: 'id', title: '操作', width: 250,
    render: (id: number, row: JSONObject) => {
      return (
        <Space size={'middle'}>
          <LinkText label='查看' onClick={() => { select(id) }} />
          {row.status !== 1 && <LinkText label='修改' onClick={() => { edit(id, row.status) }} />}
          {row.status === 0 && <Fragment>
            <Popconfirm title='您确认要发布吗?' onConfirm={() => { publicMeans(id) }}>
              <LinkText label='发布' />
            </Popconfirm>
            <Popconfirm title='您确认要删除吗?' onConfirm={() => { deleteMeans(id) }} >
              <LinkText label='删除' />
            </Popconfirm>
          </Fragment>
          }
          <Download
            label='下载附件'
            url={`/document/attachment/downloadZip/${id}?type=3`}
            type='link'
          />
        </Space>
      )
    }
  })
  const { orgId: userOrgId } = useSelector<JSONObject, UserContext>((state) => state.ctx);
  const [form] = Form.useForm()
  const [modal, modalHolder] = _Modal.useModal()
  const [orgId, setOrgId] = useState<any>(userOrgId === null ? '' : userOrgId);
  const [fileModal, setFileModal] = useState({ visible: false, title: '', name: '' })
  const [peopleModal, setPeopleModal] = useState(false)
  const [period, setPeriod] = useState<object>();
  let [table, setTable] = useState<Table>();
  const [delModal, setDelModal] = useState(false);
  const [tabData, setTabData] = useState<any>([])
  const tableFirstIdRef: any = useRef()
  const [tableData, setTableData] = useState<any>(
    {
      url: '/tax/means', params: {
        fileCabinetId: 0
      }, columns: [columns[0], columns[1], columns[2], columns[3],
      {
        title: "证件有效期", key: "id", width: 180, render: (id: number, row: JSONObject) => {
          return ((row.expirationDateStart !== null && row.expirationDateEnd !== null) && <span>{`${row.expirationDateStart}-${row.expirationDateEnd}`}</span>)
        }
      },
      columns[4], columns[5],
        actionJsx]
    }
  );
  const [option, setOption] = useState([]);
  const [allOption, setAllOption] = useState([])

  const onTableRef = (tableRef: Table) => {
    table = tableRef
    setTable(tableRef);
  }

  const modalRef: any = useRef()
  const keyRef: any = useRef('0')
  const modalActionRef: any = useRef()
  const deptListRef: any = useRef([])
  const deptName: any = useRef()
  const menuKeyRef: any = useRef([])

  useEffect(() => {
    Http.get('/cabinet').then((res) => {
      setTabData(res)
      tableFirstIdRef.current = res[0].id
      menuKeyRef.current = res
    })
  }, [])

  useEffect(() => {
    table?.handleColumns()
    table?.fetchTableData()
  }, [tableData])

  useEffect(() => {
    Http.get(`/tax/means/getConnectPeople?orgId=${orgId}&fileCabinetId=${keyRef.current}`)
      .then((res: any) => {
        const option = res.map((item: any, index: any) => {
          return { label: item, value: item }
        })
        setOption(option);
      })
  }, [keyRef.current, orgId])

  const publicMeans = async (id: number) => {
    await Http.post(`/tax/means/release/${id}`)
    message.success('发布成功')
    table?.refresh()
  }

  const deleteMeans = async (id: number) => {
    await Http.del(`/tax/means/${id}`)
    message.success('删除草稿成功')
    table?.refresh()
  }

  const modifyPeople = () => {
    const { currentPeople, selectPeople, remark } = form.getFieldsValue()
    form.validateFields().then(async () => {
      const obj2: any = allOption.find((item: any) => { return item.value === selectPeople })
      if (currentPeople === obj2.label) return message.error('修改前后的负责人不能是同一个')
      let url = ''
      if (remark === undefined) {
        url = `/tax/means/exchange?cabinetId=${keyRef.current}&connectPeople=${obj2.label}&orgId=${orgId}&transferPeople=${currentPeople}`
      } else {
        url = `/tax/means/exchange?cabinetId=${keyRef.current}&connectPeople=${obj2.label}&orgId=${orgId}&transferPeople=${currentPeople}&remark=${remark}`
      }
      await Http.post(url)
      message.success("修改成功")
      resetPeopleModal()
      table?.refresh()
      setPeopleModal(false)
    })
  }

  const sumbitFile = async () => {
    if (fileModal.name === '' || fileModal.name === undefined) return message.error('档案柜名称不能为空')
    if (modalActionRef.current === 1) {
      await Http.post(`/cabinet?name=${fileModal.name}`)
    } else {
      await Http.put(`/cabinet/${keyRef.current}?name=${fileModal.name}`)
    }
    message.success('操作成功')
    Http.get('/cabinet').then((res) => {
      setTabData(res)
    })
    setFileModal({ visible: false, name: '', title: '' })
  }

  const delFile = async () => {
    await Http.del(`/cabinet/${keyRef.current}`)
    message.success('删除成功')
    Http.get('/cabinet').then((res) => {
      setTabData(res)
      setDelModal(false)
    })
  }

  // const openPeopleModal = async () => {
  //   if (orgId === '') {
  //     return message.warn("请先选择公司")
  //   }
  //   Api.getKv('/sys/users/kv?includeMobile=false')
  //     .then((res: any) => {
  //       setAllOption(res);
  //       setPeopleModal(true)
  //     })
  // }

  const fetch = () => table?.refresh()

  const handleReplace = async() => {
    const selected = table?.getSelectedRowData()
    if (!selected || selected.length === 0) {
      message.warning("至少选择一条数据")
      return
    }
    const draft = selected.filter(item => item.status === 0)
    if (draft.length > 0) {
      message.warning("不可对草稿状态税务资料修改替换状态")
      return
    }
    const keys:string[] = []
    selected.map(item => keys.push(item.id))
    await modal.open({
      width: 600,
      content(_1, reject) {
        return (
          <Status
            tableData={keys}
            close={reject}
            query={fetch}/>
        )
      }
    })
  }

  const periodChange = (date: any, dateString: any) => {
    setPeriod({ expirationDateStart: dateString[0], expirationDateEnd: dateString[1] })
  }

  const resetEmptyFileds = (): JSONObject => {
    setPeriod({ establishDateStart: "", establishDateEnd: "" })
    return {
      establishDateStart: "",
      establishDateEnd: "",
      orgId: userOrgId || '',
    }
  }

  const openModal = () => {
    if (orgId === '') return message.warning("请先选择公司")
    const obj = menuKeyRef.current?.find((item: { id: any; }) => item.id === Number(keyRef.current))
    const name = obj?.name
    modalRef?.current.openModal({ visibleVaule: true, title: `新增${name}档案`, action: 0, type: keyRef.current, orgId: orgId, orgName: deptName.current })
  }

  const select = (id: number) => {
    const obj = menuKeyRef.current?.find((item: { id: any; }) => item.id === Number(keyRef.current))
    const name = obj?.name
    modalRef?.current.openModal({ visibleVaule: true, title: `查看${name}档案`, action: 1, type: keyRef.current, id: id })
  }

  const edit = (id: number, stauts: number) => {
    const obj = menuKeyRef.current?.find((item: { id: any; }) => item.id === Number(keyRef.current))
    const name = obj?.name
    modalRef?.current.openModal({ visibleVaule: true, title: `修改${name}档案`, action: 2, type: keyRef.current, id: id, status: stauts })
  }

  const setFormOrgNameValue = (orgNameVal: string) => {
    const obj: { k: number, v: string } = deptListRef.current.find((item: any) => {
      return item.k === Number(orgNameVal)
    })
    if (obj !== undefined) {
      deptName.current = obj.v
    }
    setOrgId(Number(orgNameVal) || '')
  }

  const fileInputChange = (e: any) => {
    setFileModal({ ...fileModal, name: e.currentTarget.value })
  }

  const openFileModal = (action: number) => {
    modalActionRef.current = action
    if (action === 1) {
      setFileModal({ visible: true, title: '新增档案柜', name: '' })
    } else {
      const { name } = tabData?.find((item: any) => item.id === Number(keyRef.current))
      setFileModal({ visible: true, title: '编辑档案柜', name: name })
    }
  }

  const getDeptList = (deptList: any) => {
    const obj: { k: number, v: string } = deptList.find((item: any) => {
      return item.k === Number(orgId)
    })
    if (obj !== undefined) {
      deptName.current = obj.v
    }
    deptListRef.current = deptList
  }

  const resetfileModal = () => {
    setFileModal({ ...fileModal, visible: false, name: '' })
  }

  const resetPeopleModal = () => {
    form.resetFields()
    setPeopleModal(false)
  }

  // tab页切换
  const onChange = (key: string) => {
    if (key === String(tabData[0].id)) {
      keyRef.current = key
      setTableData({
        url: '/tax/means', params: {
          fileCabinetId: tabData[0].id
        }, columns: [columns[0], columns[1], columns[2], columns[3],
        {
          title: "证件有效期", key: "id", width: 180, render: (id: number, row: JSONObject) => {
            return ((row.expirationDateStart !== null && row.expirationDateEnd !== null) && <span>{`${row.expirationDateStart}-${row.expirationDateEnd}`}</span>)
          }
        },
        columns[4], columns[5],
          actionJsx]
      })
    } else if (key === String(tabData[1].id)) {
      keyRef.current = key
      setTableData({
        url: '/tax/means', params: {
          fileCabinetId: tabData[1].id
        }, columns: [columns[0], columns[1], columns[2], columns[3],
        {
          title: "档案有效期", key: "id", width: 180, render: (id: number, row: JSONObject) => {
            return ((row.expirationDateStart !== null && row.expirationDateEnd !== null) && <span>{`${row.expirationDateStart}-${row.expirationDateEnd}`}</span>)
          }
        },
        columns[4], columns[5],
          actionJsx]
      })
    } else if (key === String(tabData[2].id)) {
      keyRef.current = key
      setTableData({
        url: '/tax/means', params: {
          fileCabinetId: tabData[2].id
        }, columns: [columns[0], columns[1], columns[2], columns[3],
        {
          title: "资料有效期", key: "id", width: 180, render: (id: number, row: JSONObject) => {
            return ((row.expirationDateStart !== null && row.expirationDateEnd !== null) && <span>{`${row.expirationDateStart}-${row.expirationDateEnd}`}</span>)
          }
        },
        columns[4], columns[5],
          actionJsx]
      })
    } else if (key === String(tabData[3].id)) {
      keyRef.current = key
      setTableData({
        url: '/tax/means', params: {
          fileCabinetId: tabData[3].id
        }, columns: [columns[0], columns[1], columns[2], columns[3],
        { title: "申报税种", key: "taxTypeName", width: 120 },
        {
          title: "所属期", key: "id", width: 180, render: (id: number, row: JSONObject) => {
            return ((row.declareDateStart !== null && row.declareDateEnd !== null) && <span>{`${row.declareDateStart}-${row.declareDateEnd}`}</span>)
          }
        },
        columns[4], columns[5],
          actionJsx]
      })
    } else if (key === String(tabData[4].id)) {
      keyRef.current = key
      setTableData({
        url: '/tax/means', params: {
          fileCabinetId: tabData[4].id
        }, columns: [columns[0], columns[1], columns[2], columns[3],
        { title: "申报税种", key: "taxTypeName", width: 120 },
        { title: "完税凭证号", key: "dutyPaidProof", width: 150 },
        { title: "缴款日期", key: "payInDate", width: 120 },
        columns[4], columns[5],
          actionJsx]
      })
    } else {
      keyRef.current = key
      setTableData({
        url: '/tax/means', params: {
          fileCabinetId: key
        }, columns: [columns[0], columns[1], columns[2], columns[3],
        {
          title: "档案有效期", key: "id", width: 180, render: (id: number, row: JSONObject) => {
            return (<span>{`${row.expirationDateStart}-${row.expirationDateEnd}`}</span>)
          }
        },
        columns[4], columns[5],
          actionJsx]
      })
    }
  }

  return (
    <Page>
      {modalHolder}
      <Card title="税务资料"
        actions={
          <Space>
            <PermissionButton
              permissions='FileCabinet.add'
              onClick={() => { openFileModal(1) }}
            > 新增档案柜</PermissionButton>
            <PermissionButton
              permissions='FileCabinet.delete'
              onClick={() => { setDelModal(true) }}
              danger
            > 删除档案柜</PermissionButton>
            <PermissionButton
              permissions='FileCabinet.update'
              onClick={() => { openFileModal(2) }}
            > 编辑档案柜</PermissionButton>
            {/* <Button type="primary" onClick={openPeopleModal} >修改当前负责人</Button> */}
            <PermissionButton permissions='TaxMeans.replace' type="primary" onClick={handleReplace} >修改替换状态</PermissionButton>
            <PermissionButton permissions='TaxMeans.add' type="primary" onClick={openModal}>新增档案</PermissionButton>
          </Space>
        }>
        <Tabs defaultActiveKey="0" onChange={onChange} >
          {
            tabData?.map((item: any) => {
              return (
                <TabPane tab={item.name} key={item.id}>
                </TabPane>
              )
            })
          }
        </Tabs>
        <Table
          url={tableData.url}
          columns={tableData.columns}
          isExpandAllItem={true}
          onTableRef={onTableRef}
          selectedRowKeys={[]}
          formAdditionalParameters={{ ...period, 'fileCabinetId': tableData.params?.fileCabinetId, orgId }}
          resetEmptyFileds={resetEmptyFileds}
          scroll={{ y: window.innerHeight - 300 }}
          dateFormat={{
            invoicingDate: 'YYYYMM',
          }}
        >
          <Item name="orgId">
            <DeptSelect isKeyAsValue={true} setFormOrgNameValue={setFormOrgNameValue} getDeptList={getDeptList} placeholder={'公司'} />
          </Item>
          <Item name="meansName">
            <Input placeholder="档案名称" />
          </Item>
          {keyRef.current === '0' || keyRef.current === '1' || keyRef.current === '2' ?
            (<Item name="chargePeople">
              <Select options={option} placeholder={"当前负责人"} showSearch optionFilterProp="label" allowClear ></Select>
            </Item>) : (<Item name="taxType">
              <Lookup lookupType="TAX_TYPE" placeholder="申报税种" ></Lookup>
            </Item>)
          }
          <Item name="period">
            <RangePicker format="YYYY-MM-DD" placeholder={["日期开始", "日期结束"]} onChange={periodChange} inputReadOnly></RangePicker>
          </Item>
          <Item name="whetherReplace">
            <Select allowClear placeholder="是否被替换">
              <Option value={1}>是</Option>
              <Option value={2}>否</Option>
            </Select>
          </Item>
        </Table>
      </Card>
      <MeansActionModal ref={modalRef} refresh={() => { table?.refresh() }} ></MeansActionModal>
      <Modal title="修改负责人" visible={peopleModal} onOk={modifyPeople} onCancel={resetPeopleModal} forceRender  >
        <Form layout='vertical' form={form} >
          <Form.Item name="currentPeople" label="当前负责人："
            rules={[{ required: true, message: "请选择当前负责人" }]}>
            <Select options={option} style={{ width: '200px' }} showSearch optionFilterProp="label" allowClear ></Select>
          </Form.Item>
          <Form.Item name="selectPeople" label="将选择的当前负责人的档案全部更改为："
            rules={[{ required: true, message: "请选择更改的负责人" }]}>
            <Select options={allOption} showSearch optionFilterProp="label" style={{ width: '200px' }} allowClear ></Select>
          </Form.Item>
          <Form.Item name="remark" label="备注：">
            <TextArea placeholder="备注" ></TextArea>
          </Form.Item>
        </Form>
      </Modal>
      <Modal title={fileModal.title} visible={fileModal.visible} onCancel={resetfileModal} onOk={sumbitFile} forceRender >
        <Item label="档案柜名称：" style={{ marginTop: '30px' }}>
          <Input onChange={fileInputChange} value={fileModal.name} ></Input>
        </Item>
      </Modal>
      <Modal title={'删除档案柜'}
        visible={delModal} onOk={delFile} onCancel={() => { setDelModal(false) }}>
        <p>当前档案柜已新增档案不能删除，</p>
        <p> 如需删除请先删除当前档案柜里面的所有档案。</p>
      </Modal>
    </Page>
  )
}

export default Means