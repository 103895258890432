import React, { FC, useState, useEffect } from 'react';
import { Button, Form, DatePicker, Select, Input, Space } from 'antd';

import { Card, Table, Lookup } from '@comps';
import Subtable from './component/subtable';

import { Http, Message, DateUtils } from '@utils';
import { ApplyTypeVal } from '../../interface';
import { ColumnProps } from '@comps/types';

const { Item } = Form;
const { RangePicker } = DatePicker;
const { Option } = Select;

interface IKV {
  key: string,
  value: string
};

interface Props {
  refreshTable: () => void;
  onClose: () => void;
}

const BatchRetailMD: FC<Props> = ({
  refreshTable,
  onClose,
}) => {

  const [SBUOptions, setSBUOptions] = useState([]);

  const [saveLoading, setSaveLoading] = useState(false);

  const formInitVal = {
    orderDateFromTo: [DateUtils.monthFirstDay(), DateUtils.now()]
  };

  useEffect(() => {
    getSBUOptions();
  }, []);

  const getSBUOptions = () => {
    Http.get('/sys/sbu/findSbuByUser')
      .then((res) => {
        const options = res.map((option: any) => ({
          label: option.name,
          value: option.id
        }))

        setSBUOptions(options);
      });
  };

  const businessTypeOptions: IKV[] = [
    { key: 'PROJECT', value: '工程类' },
    { key: 'RETAIL', value: '零售类' },
    { key: 'OTHER', value: '其他类型' }
  ];

  let [table, setTable] = useState<Table>();
  const onTableRef = (tableRef: Table) => {
    setTable(tableRef);
    table = tableRef;
  };

  const expandedRowRender = ({ id }: JSONObject) => {
    return (
      <Subtable orderId={id} />
    );
  };

  const handleSave = () => {
    const ids = table?.getSelectedRowKeys();
    if (ids?.length === 0) {
      Message.warn('请至少选择一条数据');
      return;
    }

    setSaveLoading(true);

    Http.post('/request/batchCreate', {
      ids,
      requestType: ApplyTypeVal.RETAIL,
    })
      .then(() => {
        refreshTable();
        onClose();
        Message.success('创建成功');
      })
      .finally(() => {
        setSaveLoading(false);
      });
  };

  const columns: ColumnProps[] = [
    { key: 'orderNo', title: '单据号', width: 180, fixed: 'left' },
    { key: 'customerCode', title: '客户编码', width: 160 },
    { key: 'customerName', title: '客户名称', width: 260 },
    { key: 'withTaxAmount', title: '总金额', width: 160, format: 'amount' },
    { key: 'invoicedAmount', title: '已开发票金额', width: 160, format: 'amount' },
    { key: 'remainAmount', title: '未开发票金额', width: 160, format: 'amount' },
    { key: 'sbuCode', title: 'SBU 代码', width: 180, align: 'center' },
    { key: 'sbuName', title: 'SBU 名称', width: 160 },
    { key: 'orderTypeDesc', title: '订单类型', width: 160, align: 'center' },
    { key: 'businessType', title: '业务类型', width: 160, format: 'amount',
      render: (businessType: string) => {
        const currentObj: JSONObject = businessTypeOptions.find(
          option => option.key === businessType
        ) ?? {};

        return currentObj.value;
      }
    },
    { key: 'sourceSystem', title: '来源系统', width: 130 },
    { key: 'mesNo', title: '橱衣柜订单识别码', width: 160 },
    { key: 'contractNo', title: '项目合同编码', width: 180 },
    { key: 'mesNo', title: 'MES 订单号码', width: 180 },
    { key: 'custPoNo', title: '客户 PO 单据号', width: 180 },
    { key: 'orderDate', title: '单据日期', width: 140,
      render: (orderDate: string) => orderDate.split(' ')[0]
    },
    { key: 'statusCode', title: '更新状态', width: 140,
      render: (statusCode: string) => statusCode === '2' ? '已更新' : '未更新'
    },
    { key: 'salerCode', title: '业务员编码', width: 160 },
    { key: 'salerName', title: '业务员', width: 160 },
    { key: 'customerTaxNo', title: '纳税人识别号', width: 160 },
  ];

  return (
    <Card
      title="批量创建开票申请（零售）"
      style={{ paddingRight: 50 }}
      actions={
        <Space>
          <Button
            type="primary"
            loading={saveLoading}
            onClick={handleSave}
          >
            保存
          </Button>
        </Space>
      }
    >
      <Table
        onTableRef={onTableRef}
        queryProps={{
          initialValues: formInitVal
        }}
        url="/request/queryBatchOutputOrder"
        formAdditionalParameters={{ requestType: ApplyTypeVal.RETAIL }}
        columns={columns}
        selectedRowKeys={[]}
        expandable={{ expandedRowRender }}
        scroll={{ y: window.innerHeight - 230 }}
        pagination={false}
      >
        <Item name="orderDateFromTo">
          <RangePicker placeholder={['单据日期从', '单据日期至']} inputReadOnly/>
        </Item>
        <Item name="orderType">
          {/* <Lookup lookupType="OUTPUT_ORDER_TYPE" /> */}
          <Select placeholder="订单类型" allowClear>
            <Option value="SO">销售订单</Option>
            <Option value="SR">销售退货订单</Option>
            <Option value="AR">应收发票</Option>
          </Select>
        </Item>
        <Item name="orderNo">
          <Input placeholder="单据号" />
        </Item>
        <Item name="custPoNo">
          <Input placeholder="客户 PO 单据号" />
        </Item>
        <Item name="sbuIds">
          <Select
            placeholder="SBU 名称"
            showSearch
            optionFilterProp="label"
            options={SBUOptions}
          />
        </Item>
        <Item name="customerCode">
          <Input placeholder="客户编码" />
        </Item>
        <Item name="customerName">
          <Input placeholder="客户名称" />
        </Item>
        <Item name="contractNo">
          <Input placeholder="合同编号" />
        </Item>
        <Item name="salerName">
          <Input placeholder="业务员" />
        </Item>
        <Item name="sourceSystem">
          <Input placeholder="来源系统" />
        </Item>
        <Item name="mesNo">
          <Input placeholder="橱衣柜订单识别码" />
        </Item>
      </Table>
    </Card>
  );
};

export default BatchRetailMD;
