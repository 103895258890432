import React, { useState, useEffect, FC } from 'react';
import { Button, Input, Form, Select, Radio, message, Space } from 'antd';

import { Card, Page, BackTag } from '@comps';

import { Http, Context, Api } from '@utils';

const { Item } = Form;

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 8 },
};

const validateMessages = {
  required: '请输入 ${label}',
  types: {
      email: '请输入正确的邮箱'
  }
};

const Add: FC = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [roleOptions, setRoleOptions] = useState<[]>([]);
  const [SBUOptions, setSBUOptions] = useState<[]>([]);
  const [regionOptions, setRegionOptions] = useState<[]>([]);

  const [isAddAllSBUIds, setIsAddAllSBUIds] = useState(false);

  useEffect(() => {
   getSelectOptions();
  }, []);

  const getSelectOptions = () => {
    Api.getKv('/sys/roles/kv')
      .then((data: any) => {
        setRoleOptions(data)
      })

    Api.getKv('/sys/sbu/kv')
      .then((data: any) => {
        setSBUOptions(data)
      })

    Api.getKv('/sys/regions/kv')
      .then((data: any) => {
        setRegionOptions(data)
      })
  };

  const handOnSave = () => {
    form?.validateFields()
      .then((values: any) => {
        setLoading(true);

        Http.post('/sys/users', values)
          .then(() => {
            message.info('创建成功');
            Context.route('/sys/users');
          })
          .finally(() => {
            setLoading(false);
          })
    });
  };

  const handleAddAllSBUId = () => {
    setIsAddAllSBUIds(!isAddAllSBUIds);

    if (isAddAllSBUIds) {
      form.setFieldsValue({ sbuIds: [] });
    } else {
      const allSBUIds = SBUOptions.map((SBU: JSONObject) => SBU.value);
      form.setFieldsValue({ sbuIds: allSBUIds });
    }
  };

  return (
    <Page>
      <Card title='新增用户' loading={loading}>
        <BackTag />
        <Form 
          {...layout} 
          name="form-user-edit" 
          form={form}
          initialValues={{ status: 'ACTIVE' }}
          validateMessages={validateMessages}
        >
          <Item 
            name='userName' 
            label="用户名" 
            rules={[{ required: true }]}
          >
            <Input />
          </Item>
          <Item name='lastName' label='姓名'>
            <Input />
          </Item>
          <Item 
            name='password' 
            label='密码' 
            rules={[{ required: true }]}
          >
            <Input.Password />
          </Item>
          <Item 
            name='emailAddress' 
            label='邮箱' 
            rules={[{ type: 'email' }]}
          >
            <Input />
          </Item>
          <Item name='mobile' label='手机号'>
            <Input />
          </Item>
          <Item name='gender' label='性别'>
            <Radio.Group >
              <Radio value='M'>男</Radio>
              <Radio value='W'>女</Radio>
            </Radio.Group>
          </Item>
          <Item name='status' label='状态'>
            <Radio.Group>
              <Radio value='ACTIVE'>有效</Radio>
              <Radio value='INACTIVE'>已失效</Radio>
              <Radio value='FROZEN'>冻结</Radio>
            </Radio.Group>
          </Item>
          <div style={{ position: 'relative' }}>
            <Item name='roleIds' label='角色'>
              <Select showSearch mode="multiple" options={roleOptions} />
            </Item>
            <Button 
              style={{ position: 'absolute', bottom: -44, right: 290 }} 
              type="primary"
              onClick={handleAddAllSBUId}
            >
              { isAddAllSBUIds ? '取消全部' : '添加全部' }
            </Button>
          </div>
          <Item name='sbuIds' label='SBU 标识' >
            <Select showSearch optionFilterProp="label" mode="multiple" options={SBUOptions} />  
          </Item>
          <Item name='regionIds' label='区域'>
            <Select showSearch mode="multiple" options={regionOptions} />
          </Item>
          <Item wrapperCol={{ ...layout.wrapperCol, offset: 8 }}>
            <Space>
              <Button type="primary" onClick={handOnSave}>保存</Button>
            </Space>
          </Item>
        </Form>
      </Card>
    </Page>
  );
};

export default Add;
