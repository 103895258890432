import React, { FC, useState, useRef, useEffect, ReactNode, memo } from 'react';
import { Space, Button, Row, Col, Form, Input, InputNumber, Select } from 'antd';

import { Card, HiddenFormItem, DisplayInput } from '@comps';

import { Http, Message } from '@utils';
import { ApplyType } from '../../../interface';

const { Item } = Form;

interface Props {
  applyType: string;
  rowData: JSONObject;
  setAllModuleData: (data: JSONObject) => void;
  onClose: () => void;
}

const EditApplyDetail: FC<Props> = memo(({
  applyType,
  rowData,
  setAllModuleData,
  onClose,
}) => {

  const [form] = Form.useForm();
  const isCanEditNumRef = useRef(true);
  const [rates, setRates] = useState<KV[]>([]);
  const [taxCodes, setTaxCodes] = useState<KV[]>([]);
  const formLayout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 17 }
  };
  
  const ORDER_TYPE_BYHAND = 'BYHAND';
  const orderType = rowData.orderType;
  let renderItems: ReactNode = <></>;

  const [loading, setLoading] = useState(false);
 
  const advanceItems: ReactNode = 
    <>
      <Col span={8}>
        <Item label="商品描述" name="goodsDescription">
          <Input />
        </Item>
      </Col>
      <Col span={8}>
        <Item label="税收分类编码" name="taxCode">
          <Select
            showSearch
            optionFilterProp="label"
            options={taxCodes.map(({ k, v }) => ({ label: v, value: k, title: v }))}
          />
        </Item>
      </Col>
      <Col span={8}>
        <Item label="规格型号" name="spec">
          <Input />
        </Item>
      </Col>
      <Col span={8}>
        <Item label="单位" name="unit" rules={[{ required: true }]}>
          <Input />
        </Item>
      </Col>
      <Col span={8}>
        <Item label="含税单价" name="withTaxPrice">
          <InputNumber
            style={{ width: '100%' }}
          />
        </Item>
      </Col>
      <Col span={8}>
        <Item label="数量" name="quantity">
          {isCanEditNumRef.current
            ? <InputNumber style={{ width: '100%' }}/>
            : <DisplayInput />
          }
        </Item>
      </Col>
      <Col span={8}>
        <Item label="税率(%)" name="taxRate">
          <Select
            showSearch
            optionFilterProp="label"
            options={rates.map(({ k, v }) => ({ label: v, value: k }))}
          />
        </Item>
      </Col>
      <Col span={8}>
        <Item label="产品编码" name="goodsNo">
          <Input />
        </Item>
      </Col>
    </>;

  const mainAssiatantItems: ReactNode = 
    <>
      <Col span={8}>
        <Item label="税收分类编码" name="taxCode">
          <Select
            showSearch
            optionFilterProp="label"
            options={taxCodes.map(({ k, v }) => ({ label: v, value: k, title: v }))}
          />
        </Item>
      </Col>
      <Col span={8}>
        <Item label="规格型号" name="spec">
          <Input />
        </Item>
      </Col>
      <Col span={8}>
        <Item 
          label="单位" 
          name="unit"
          rules={[{ required: true }]}
        >
          <Input />
        </Item>
      </Col>
      <Col span={8}>
        <Item 
          label="税率(%)" 
          name="taxRate"
          rules={[{ required: true }]}
        >
          <Select
            showSearch
            optionFilterProp="label"
            options={rates.map(({ k, v }) => ({ label: v, value: k }))}
          />
        </Item>
      </Col>
    </>;

  const commonItems: ReactNode = 
    <>
      <Col span={8}>
        <Item label="税收分类编码" name="taxCode">
          <Select
            showSearch
            optionFilterProp="label"
            options={taxCodes.map(({ k, v }) => ({ label: v, value: k, title: v }))}
          />
        </Item>
      </Col>
      <Col span={8}>
        <Item label="规格型号" name="spec">
          <Input />
        </Item>
      </Col>
      <Col span={8}>
        <Item label="单位" name="unit">
          <Input />
        </Item>
      </Col>
      <Col span={8}>
        <Item label="含税单价" name="withTaxPrice">
          <InputNumber
            style={{ width: '100%' }}
          />
        </Item>
      </Col>
      <Col span={8}>
        <Item label="数量" name="quantity">
          {isCanEditNumRef.current
            ? <InputNumber style={{ width: '100%' }}/>
            : <DisplayInput />
          }
        </Item>
      </Col>
      <Col span={8}>
        <Item label="税率(%)" name="taxRate">
          <Select
            showSearch
            optionFilterProp="label"
            options={rates.map(({ k, v }) => ({ label: v, value: k }))}
          />
        </Item>
      </Col>
    </>;
  
  const handleByApplyType = () => {
    switch (applyType) {
      case ApplyType.ADVANCE:
        isCanEditNumRef.current = (orderType === ORDER_TYPE_BYHAND); 
        renderItems = advanceItems;
        break;
  
      case ApplyType.PROGRESS:
        isCanEditNumRef.current = (orderType === ORDER_TYPE_BYHAND);
        renderItems = commonItems;
        break;
  
      case ApplyType.SETTLE: 
        isCanEditNumRef.current = false;
        renderItems = commonItems;
        break;
  
      case ApplyType.MAIN_ASSISTANT:
        renderItems = mainAssiatantItems;
        break;
  
      default:
        renderItems = commonItems;
        break;
    };
  };

  handleByApplyType();

  useEffect(() => {
    setFormFieldsVal();
  }, []);

  const setFormFieldsVal = async () => {

    const [_rates, _taxCodes] = await Promise.all([
      Http.get<KV[]>('/tax/rates/kv'),
      Http.get<KV[]>('/tax/taxCodes/kv')
    ]);

    setRates(_rates);
    setTaxCodes(_taxCodes);

    form.setFieldsValue(rowData);

  };

  const handleSave = () => {
    setLoading(true);

    const formData = form.getFieldsValue();

    Http.post('/request/updateRequestLine', {...rowData, ...formData })
      .then(data => {
        Message.success('修改成功');
        setAllModuleData(data);
        onClose();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleWithTaxPriceChange = (withTaxPrice: any) => {
    const quantity: number = form.getFieldValue('quantity');
    form.setFieldsValue({ withTaxAmount: withTaxPrice * quantity });
  };

  const handleQuantityChange = (quantity: any) => {
    const withTaxAmount: number = form.getFieldValue('withTaxAmount');
    form.setFieldsValue({ withTaxPrice: withTaxAmount / quantity });
  };

  return (
    <Card title="修改开票申请明细">
      <Form
        {...formLayout}
        form={form}
        onFinish={handleSave}
      >
        <Row style={{ marginTop: 20 }}>
          <Col span={8}>
            <HiddenFormItem name="id" />
            <Item
              label="开票商品名称"
              name="goodsName"
              rules={[{ required: true }]}
            >
              <Input />
            </Item>
          </Col>
          {renderItems}
        </Row>
        <Row>
          <Col span={24} style={{ textAlign: 'center' }}>
            <Space size="large">
              <Button type="primary" htmlType="submit" loading={loading}>确定</Button>
              <Button onClick={onClose}>取消</Button>
            </Space>
          </Col>
        </Row>
      </Form>
    </Card >
  );
});

export default EditApplyDetail;

