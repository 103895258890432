import React, { useState, FC, memo } from "react";
import { Space, Button, Upload, Row } from "antd";

import { Card, Download, Modal } from "@comps";

import { Message, Context, Env } from "@utils";

interface Props {
  onClose: () => void;
  table: any;
}

const ImportBillMD: FC<Props> = memo(({ onClose, table }) => {
  const [modal, modalHolder] = Modal.useModal();

  const [uploadLoading, setUploadLoading] = useState(false);

  return (
    <Card title="导入单据">
      {modalHolder}
      <div style={{ marginTop: 30, marginBottom: 20 }}>
        <Space size="large">
          <Upload
            accept=".xlsx,.xls"
            action={`${Env.getBaseUrl()}/tax/orders/import`}
            showUploadList={false}
            headers={{
              Authorization: Context.getToken(),
            }}
            onChange={async ({ file: { status, response = {} } }) => {
              setUploadLoading(true);

              if (!["done", "error"].includes(status as string)) {
                return;
              }

              const { data, code, msg } = response;

              if (code === "200" && !data) {
                // 导入成功
                Message.success("导入成功");
              } else if (code === "200" && data) {
                // 导入失败
                await modal.open({
                  width: 300,
                  content(_1, reject) {
                    return (
                      <Card title="导入失败" style={{ marginBottom: "0" }}>
                        <Space direction="vertical" style={{ width: "100%" }}>
                          <Row justify="center">
                            <span>
                              导入失败，请下载导入失败原因文件，请调整后重新尝试导入
                            </span>
                          </Row>
                          <Row justify="center">
                            <Download
                              url={`/outputOrder/errorFile/download?fileId=${data}`}
                              label="下载文件"
                              type="button"
                            />
                          </Row>
                          <Row justify="center">
                            <Button onClick={() => reject()}>关闭</Button>
                          </Row>
                        </Space>
                      </Card>
                    );
                  },
                });
              } else {
                // 其他情况
                Message.error(msg);
              }

              setUploadLoading(false);
              onClose();
              table?.refresh();
            }}
          >
            <Button type="primary" loading={uploadLoading}>
              选择文件
            </Button>
          </Upload>
          <Download
            label="下载导入单据模板"
            url="/tax/orders/template/download"
          />
        </Space>
      </div>
    </Card>
  );
});

export default ImportBillMD;
