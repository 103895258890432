import {
  Card as AdCard,
  Cascader,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
} from "antd";
import * as React from "react";
import { useState } from "react";

interface Props {
  form: any;
  // ref: any;
  //   onClose: Function;
}

const RealEstateSales: React.FC<Props> = (props) => {
  const { form } = props;
  const [loading, setLoading] = useState(false);
  const cityData = require("./province-city-china.json");
  const [levelData, setLevalData] = useState(cityData);
  const { RangePicker } = DatePicker;
  const Option = Select.Option;

  return (
    <>
      <AdCard
        title="编辑不动产信息"
        loading={loading}
        style={{ marginBottom: "0" }}
      >
        <Form form={form}>
          <Row gutter={16}>
            <Col span={8}>
              <Form.Item
                label="产权证号"
                // name="BDCXS_CQZH"
                name={["businessInfo", "BDCXS_CQZH"]}
                rules={[{ required: true, message: "请输入产权证号" }]}
              >
                <Input placeholder="请输入产权证号" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="地区地址"
                // name="BDCXS_DQDZ"
                name={["businessInfo", "BDCXS_DQDZ"]}
                rules={[{ required: true, message: "请输入地区地址" }]}
              >
                <Cascader options={levelData} placeholder="请选择" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="详细地址"
                // name="BDCXS_XXDZ"
                name={["businessInfo", "BDCXS_XXDZ"]}
                rules={[{ required: true, message: "请输入详细地址" }]}
              >
                <Input placeholder="请输入详细地址" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="合同编号"
                // name="BDCXS_HTBH"
                name={["businessInfo", "BDCXS_HTBH"]}
                rules={[{ required: true, message: "请输入合同编号" }]}
              >
                <Input placeholder="请输入合同编号" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="项目编号"
                // name="BDCXS_XMBH"
                name={["businessInfo", "BDCXS_XMBH"]}
                rules={[{ required: true, message: "请输入项目编号" }]}
              >
                <Input placeholder="请输入项目编号" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="计税价格"
                // name="BDCXS_JSJG"
                name={["businessInfo", "BDCXS_JSJG"]}
                rules={[{ required: true, message: "请输入计税价格" }]}
              >
                <InputNumber
                  style={{ width: "100%" }}
                  controls={false}
                  //@ts-ignore
                  min="0"
                  //@ts-ignore
                  max="999999999999.9999"
                  maxLength={16}
                  stringMode
                  placeholder="请输入计税价格"
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="成交金额"
                // name="BDCXS_CJJE"
                name={["businessInfo", "BDCXS_CJJE"]}
                rules={[{ required: true, message: "请输入成交金额" }]}
              >
                <InputNumber
                  style={{ width: "100%" }}
                  controls={false}
                  //@ts-ignore
                  min="0"
                  //@ts-ignore
                  max="999999999999.9999"
                  maxLength={16}
                  stringMode
                  placeholder="请输入成交金额"
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="跨地（市）标志"
                // name="BDC_KSBZ"
                name={["businessInfo", "BDCXS_KSBZ"]}
                rules={[{ required: true, message: "请输入跨地（市）标志" }]}
              >
                <Select placeholder="请选择">
                  <Option value="Y">是</Option>
                  <Option value="N">否</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="面积单位"
                // name="BDC_PIDW"
                name={["businessInfo", "BDCXS_PIDW"]}
                rules={[{ required: true, message: "请输入面积单位" }]}
              >
                <Select placeholder="请选择面积单位">
                  <Option value="平方千米">平方千米</Option>
                  <Option value="平方米">平方米</Option>
                  <Option value="公顷">公顷</Option>
                  <Option value="亩">亩</Option>
                  <Option value="h㎡">h㎡</Option>
                  <Option value="k㎡">k㎡</Option>
                  <Option value="㎡">㎡</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </AdCard>
    </>
  );
};

export default RealEstateSales;
