import React, { FC, useEffect, useRef, useState } from "react";
import { Card, LinkText, Modal } from "@comps";
import {
  Button,
  message,
  Space,
} from "antd";
import { Http, Message, Env, Context, WebScan } from "@utils";
import moment from "moment";
import { RcFile } from "antd/lib/upload";
import ScannerResult from "./scannerResult";
import ImportResults from "../component/uploadFIle/importResults";

export interface Props {
  onCancel: () => void;
}

const ScannerTable: FC<Props> = ({ onCancel }) => {
  let ws: any = null;
  const [loading, setLoading] = useState(false);
  const [dataSource, setDataSource] = useState<JSONObject[]>([]);
  const [scannerEntryLoading, setscannerEntryLoading] = useState(false);
  const [fileList, setFileList] = useState<RcFile[]>([]);
  const [modal, modalHolder] = Modal.useModal();
  const [currentDevice, setCurrentDevice] = useState("");
  const [isInit, setIsInit] = useState(false);

  let webScan: any;

  useEffect(() => {
    webScan.initSef("", function (result: any) {
      if (result.code !== 200) {
        console.log("初始化失败，返回错误：" + result.msg);
        return;
      }
      setIsInit(true);
      webScan.getDevices(function (result: any) {
        if (result.code !== 200) {
          console.log("获取设备信息失败，返回错误：" + result.msg);
          return;
        }
        if (
          result.data != null &&
          result.data != undefined &&
          result.data.length > 0
        ) {
          setCurrentDevice(result.data[0]);
        } else {
          console.log("获取设备失败");
          return;
        }
        console.log(result.data);
      });
      webScan.getParams(function (result: any) {
        if (result.code !== 200) {
          console.log("获取参数失败，返回错误：" + result.msg);
          return;
        }
        console.log(result.data, "0000000");
      });
    });
  }, []);

  const scannerEntry = () => {
    setscannerEntryLoading(true);
    StartL7200();
  };

  webScan = new WebScan({
    url: "http://127.0.0.1:18989/WebScan",
    wsUrl: "http://127.0.0.1:28989/",
    licence: "lXahnrH6jlXXAbDXBOg1Jg==",
  });
  let arr = new Array();
  let string = new String();

  function StartL7200() {
    if (!isInit) {
      message.warn("请先进行初始化");
      setscannerEntryLoading(false);
      return;
    }
    arr.splice(0);
    webScan.startScan(scanCallBack, null);
  }

  function scanCallBack(result: any) {
    let code = result.code;
    if (code == 500) {
      message.warn(result.msg);
      setscannerEntryLoading(false);
    }
    if (code == 201) {
      let str = result.image;
      console.log("未处理:" + str);
      let split = str.split(",");
      console.log("处理完:" + split[1]);
      arr.push(split[1]);
      string = arr.toString();
    } else {
      // scan(arr);
      scan(string);
    }
  }
  function scan(arr: any) {
    if (arr.length == 0) {
      return false;
    }
    const formData = new FormData();
    formData.append("baseArray", arr);
    Http.post("/inputInvManagement/recognizeBase64s", formData).then(
      (res) => {
        setscannerEntryLoading(false)
        onCancel()
        // console.log(res)
        // let results = result.data;
        // if (result.code == "200") {
        //   if (results.msg !== "") {
        //     message.info(results.msg);
        //   }
        //   if (results.objects !== null && results.objects?.length !== 0) {
        //     let data = results.objects;
        //     // data.map((item:any)=>{
        //     //   item['picPath']=item.fileUrl
        //     // })
        //     console.log(data);
        //     for (let i = 0; i < data.length; i++) {
        //       let obj = data[i];
        //       if (obj.invNo == "") {
        //         continue;
        //       }
        //       modal.open({
        //         width: 1000,
        //         content(resolve, rejects) {
        //           return (
        //             <ImportResults
        //               data={data}
        //               label="扫描仪录入"
        //               onCancel={rejects}
        //               reject={onCancel}
        //             />
        //           );
        //         },
        //       });
        //     }
        //   }
        // } else {
        //   message.info(results.msg);
        //   setscannerEntryLoading(false);
        // }
      }
    );
  }

  return (
    <Card title="扫描仪录入" style={{ marginBottom: "0" }}>
      {modalHolder}
      <Button
        type="primary"
        loading={scannerEntryLoading}
        onClick={scannerEntry}
      >
        扫描仪录入
      </Button>
      <div style={{ textAlign: "center", marginTop: 10 }}>
        <Space>
          <Button onClick={onCancel}>取消</Button>
        </Space>
      </div>
    </Card>
  );
};

export default ScannerTable;
