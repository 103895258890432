import * as React from "react";
import {
  Form,
  DatePicker,
  Input,
  message,
  Space,
  Button,
  Divider,
  Descriptions,
  Table as AdTable,
  Row,
  Col,
  Radio,
  Switch,
  Result,
} from "antd";
import {
  Page,
  Card,
  PermissionButton,
  Lookup,
  //   Table,
  LinkText,
  Modal,
} from "@comps";
import { ColumnProps } from "@comps/types";
import { DateUtils, Http, Context, Env, Math } from "@utils";
import _ from "lodash";
import { useState } from "react";
import { RollbackOutlined } from "@ant-design/icons";
import InvoicePreview from "../modal/InvoicePreview";

interface Props {
  handelStep: Function;
}

const InvoiceResult: React.FC<Props> = ({ handelStep }) => {
  const [loading, setLoading] = useState(false);
  const [modal, modalHolder] = Modal.useModal();

  const onBackToIndex = () => {
    handelStep(0);
  };
  const invoiceData = {}
  const handlePreview = () => {
    modal.open({
      width: 1200,
      content(resolve, reject) {
        return <InvoicePreview onClose={reject} invoice={invoiceData} />;
      },
    });
  };
  return (
    <Card loading={loading} style={{ marginBottom: "0" }}>
      {modalHolder}
      <Result
        status="success"
        title="开票成功"
        extra={
          <div>
            <div style={{ marginBottom: "32px" }}>发票已传递至对方数字账号</div>
            <Descriptions
              title="发票简要信息"
              style={{
                textAlign: "left",
                backgroundColor: "#f9fafd",
                padding: "30px 20px",
                marginBottom: "16px",
              }}
            >
              <Descriptions.Item label="确认方名称">
                {`${"Zhou Maomao"}`}
              </Descriptions.Item>
              <Descriptions.Item label="开具时间">
                {`${"Zhou Maomao"}`}
              </Descriptions.Item>
              <Descriptions.Item label="发票号码">
                {`${"Zhou Maomao"}`}
              </Descriptions.Item>
              <Descriptions.Item label="价税合计（元）">{`${"Zhou Maomao"}`}</Descriptions.Item>
              <Descriptions.Item label="备注信息">
                {`${"Zhou Maomao"}`}
              </Descriptions.Item>
            </Descriptions>
            <Button style={{ marginRight: "16px" }} onClick={onBackToIndex}>
              返回选择
            </Button>
            <Button type="primary" ghost onClick={() => handlePreview()}>
              查看发票
            </Button>
          </div>
        }
      />
    </Card>
  );
};

export default InvoiceResult;
