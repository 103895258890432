import React, { FC, useState } from 'react';
import { Form, Col, DatePicker, Input } from 'antd';

import { Card, Table, TableSummaryRow, Lookup, DisplayInput } from '@comps';

import { Http, Math } from '@utils';
import { ColumnProps } from '@comps/types';

const { Item } = Form;
const { RangePicker } = DatePicker;

interface Props {
  contractNo: string
}

interface TableSummaryProps {
  amount: number;
  count: number;
  taxAmount: number;
  withTaxAmount: number;
}

const InvoiceDetail: FC<Props> = ({ contractNo }) => {
  const [tableSummaryData, setTableSummaryData] = useState<TableSummaryProps>({
    amount: 0,
    count: 0,
    taxAmount: 0,
    withTaxAmount: 0,
  });

  const getTableSummaryData = (queryParameter: JSONObject) => {
    Http.post('/contracts/ledger/invoiceDetails/sum', queryParameter)
      .then(data => {
        setTableSummaryData(data);
      })
  };

  const columns: ColumnProps[] = [
    { key: 'invoiceNo', title: '发票号码', width: 150, fixed: 'left' },
    { key: 'invoiceCode', title: '发票代码', width: 150, fixed: 'left' },
    { key: 'invoiceTypeDesc', title: '发票类型', width: 150, },
    { key: 'bizNo', title: '开票申请单号', width: 200, },
    { key: 'requestDes', title: '开票类型', width: 150 },
    { key: 'requestDate', title: '申请日期', width: 120 },
    { key: 'invoiceDate', title: '开票日期', width: 120},
    { key: 'invoiceAmount', title: '不含税金额', width: 150 },
    { key: 'taxAmount', title: '税额', width: 150},
    { key: 'totalAmount', title: '价税合计', width: 150 },
    { key: 'buyerName', title: '购买方', width: 220 },
    { key: 'sellerName', title: '销售方', width: 220 },
    { key: 'statusCodeDesc', title: '状态', width: 120, align: 'center', fixed: 'right' }
  ];

  const tableSummary = () => {
    return (
      <TableSummaryRow>
        <Col span={6}>
          发票数量: {Math.numThousandth(tableSummaryData.count)}
        </Col>
        <Col span={6}>
          不含金额汇总: {Math.num2money(tableSummaryData.amount)}
        </Col>
        <Col span={6}>
          税额汇总: {Math.num2money(tableSummaryData.taxAmount)}
        </Col>
        <Col span={6}>
          价税合计汇总: {Math.num2money(tableSummaryData.withTaxAmount)}
        </Col>
      </TableSummaryRow>
    );
  };
  
  return (
    <div>
      <Card 
        title="发票详情" 
        style={{ boxShadow: 'none' }}
      >
        <Table
          method="post"
          url="/contracts/ledger/invoiceDetails"
          formAdditionalParameters={{ contractNo }}
          columns={columns}
          summary={tableSummary} 
          getTableSummaryInfo={getTableSummaryData}
        >
          <Item name="">
            <RangePicker placeholder={['开票日期从', '开票日期至']} inputReadOnly/>
          </Item>
          <Item name="invStatus">
            <Lookup placeholder="发票状态" lookupType="TAX_OUTPUT_INVOICE_STATUS" />
          </Item>
          <Item name="invType">
            <Lookup placeholder="发票类型" lookupType="TAX_INVOICE_TYPE" />
          </Item>       
          <Item name="name">
            <Input placeholder="销售方 / 购买方" />
          </Item>
          <Item name="no">
            <Input placeholder="发票代码 / 发票号码" />
          </Item>
          <Item name="requestNo">
            <Input placeholder="开票申请单号" />
          </Item>
          <Item name="contractNo">
            <DisplayInput value={contractNo} />
          </Item>
        </Table>
      </Card>
    </div>
  )
};

export default InvoiceDetail;