import React, { useState, useEffect, FC, memo } from 'react';
import { Space, Button, Form, Row, Col, Input, InputNumber, Select, Radio } from 'antd';

import { Card } from '@comps';
import { Http, Message } from '@utils';

const { Item } = Form;
const { Option } = Select;

interface Props {
  isEdit: boolean,
  formData: JSONObject,
  level: number,
  parentId: number,
  refreshTree: () => void,
  onResolve: () => void
  onClose: () => void
};

const AddOrEditMD: FC<Props> = memo(({ 
  isEdit, 
  formData, 
  level,
  parentId,
  refreshTree,
  onResolve,
  onClose 
}) => {

  const { id } = formData;

  const [form] = Form.useForm();
  const [rates, setRates] = useState<KV[]>([]);
  const [taxCodes, setTaxCodes] = useState<KV[]>([]);
  const [policies, setPolicies] = useState<KV[]>([]);
  
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setFormFieldsVal();
  }, []);

  const setFormFieldsVal = async () => {
    form.setFieldsValue({ level: level + 1 });
    !isEdit && form.setFieldsValue({ hasDiscountPolicy: false });

    const [_rates, _taxCodes, _policies] = await Promise.all([
      Http.get<KV[]>('/tax/rates/kv'),
      Http.get<KV[]>('/tax/taxCodes/kv'),
      Http.get<KV[]>('/tax/policies/discountsKv')
    ]);

    setRates(_rates);
    setTaxCodes(_taxCodes);
    setPolicies(_policies);

    isEdit && form.setFieldsValue(formData);
  };

  const handleAddOrEdit = async () => {
    setLoading(true);

    const queryData = form.getFieldsValue();
    queryData.parentId = parentId;

    let httpMethod: any = Http.post;

    if (isEdit) {
      queryData.id = id;
      httpMethod = Http.put;
    };

    httpMethod('/tax/goodsCategoryMapping', queryData)
      .then(() => {
        isEdit 
          ? Message.success('修改成功')
          : Message.success('新增成功');
          
        onResolve();
        refreshTree();
      })
      .finally(() => {
        setLoading(false);
        onClose();
      });
  };

  return (
    <Card title={isEdit ? '修改' : '新增'}>
      <Form 
        form={form} 
        onFinish={handleAddOrEdit} 
        labelCol={{ span: 8 }} 
        wrapperCol={{ span: 13 }}
      >
        <Row>
          <Col span={12}>
            <Item 
              label="类别" 
              name="level" 
              rules={[{ required: true }]}
            >
              <Select disabled>
                <Option value={1}>大类</Option>
                <Option value={2}>中类</Option>
                <Option value={3}>小类</Option>
              </Select>
            </Item>
          </Col>
          <Col span={12}>
            <Item 
              label="分类名称" 
              name="categoryName" 
              rules={[{ required: true }]}
            >
              <Input />
            </Item>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <Item 
              name='taxCode' 
              label='税收分类编码' 
              rules={[{ required: true }]}
            >
              <Select 
                showSearch 
                optionFilterProp="label"
                options={taxCodes.map(({ k, v }) => ({ label: v, value: k, title: v }))} 
              />
            </Item>
          </Col>
          <Col span={12}>
            <Item 
              label="开票商品名称" 
              name="goodsName" 
              rules={[{ required: true }]}
            >
              <Input />
            </Item>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <Item 
              name="productDesc" 
              label="产品描述"
            >
              <Input />
            </Item>
          </Col>
          <Col span={12}>
            <Item 
              label="税率(%)" 
              name='taxRate' 
              rules={[{ required: true, message: '请选择税率'}]}
            >
              <Select 
                showSearch 
                optionFilterProp="label"
                options={rates.map(({ k, v }) => ({ label: v, value: k }))} 
              />
            </Item>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <Item name="lossRate" label="损耗率">
              <InputNumber min={0} max={1} style={{ width: '100%' }} />
            </Item>
          </Col>
          <Col span={12}>
            <Item 
              label="享受税收优惠政策" 
              name='hasDiscountPolicy' 
              rules={[{ required: true, message: '请选择享受税收优惠政策'}]}
            >
              <Radio.Group 
                size="large"
                options={[
                  { label: '享受', value: true },
                  { label: '不享受', value: false }
                ]}
              />
            </Item>
          </Col>
        </Row>
        <Row>
          <Item shouldUpdate noStyle>
          {
            () => {
              return form.getFieldValue('hasDiscountPolicy')
                ? <Col span={12}>
                    <Item 
                      label="税收优惠政策类型" 
                      name='policyId' 
                      rules={[{ required: true, message: '请选择税收优惠政策类型' }]}
                    >
                      <Select 
                        showSearch 
                        optionFilterProp="label"
                        options={policies.map(({ k, v }) => ({ label: v, value: k }))} 
                      />
                    </Item>
                  </Col>
                : <Item></Item>
            }
          }
          </Item>
        </Row>
        <Row>
          <Col span={24} style={{ marginTop: 14, marginBottom: 14, textAlign: 'center' }}>
            <Space>
              <Button loading={loading} type="primary" htmlType="submit">确定</Button>
              <Button onClick={onClose}>取消</Button>
            </Space>
          </Col>
        </Row>
      </Form>
    </Card >
  );
});

export default AddOrEditMD;
