import * as React from 'react';

import { Card } from '@comps';
import Zmage from 'react-zmage'

import './index.css'

export interface Props {
    onClose: () => void,
    picUrl: string,
    backdrop?: string
}

const ViewInvoice: React.FC<Props> = ({ onClose, picUrl, backdrop = '#eff2f55a' }: Props) => {
  return (
  <Card title='查看图片'>
    <div className="zmage">
      <Zmage src={picUrl} alt="发票图片" backdrop={backdrop}/>
    </div>
  </Card >)
}

export default ViewInvoice
