import moment from 'moment'

/**
 * 日期转字符串
 * @param value 日期
 * @param format  格式, 默认 YYYY-MM-DD
 */
const date2str = (value: string, format?: string): string => {
  return format ? moment(value).format(format) : moment(value).format('YYYY-MM-DD')
}

const date2int = (value: string): number | null => {
  return value ? parseInt(moment(value).format('YYYYMMDD')) : null;
}

/**
 * 日期转字符串
 * @param value
 * @param format
 */
const datetime2str = (value: string): string => {
  return moment(value).format('YYYY-MM-DD HH:mm:ss')
}

const monthFirstDay = (): moment.Moment => {
  return moment().startOf('month')
}

const monthLastDay = (): moment.Moment => {
  return moment().endOf('month')
}

/**
 * 上个月的第一天和最后一天
 */

const preMonthFirstDay = (): moment.Moment => {
  return moment().subtract(1, 'months').startOf('month')
}

const preMonthLastDay = (): moment.Moment => {
  return moment().subtract(1, 'months').endOf('month')
}

/**
 * 上个月
 */
const preMonth = (): moment.Moment => {
  return moment().subtract(1, 'months')
}

/**
 * 上个月转字符串
 * @param value 日期
 * @param format  格式, 默认 YYYMM
 */
const preMonthStr = (format?: string): string => {
  return format ? moment().subtract(1, 'months').format(format) : moment().subtract(1, 'months').format("YYYYMM")
}

/**
 * 某月的第一个和最后一天
 */
 const someMonthFirstDay = (month: string): moment.Moment => {
  return moment(month).startOf('month')
}

const someMonthLastDay = (month: string): moment.Moment => {
  return moment(month).endOf('month')
}

/**
 * 某季度的第一个和最后一天
 */
 const someQuaterFirstDay = (quarter: string): moment.Moment => {
  return moment(quarter).startOf('quarter')
}

const someQuaterLastDay = (quarter: string): moment.Moment => {
  return moment(quarter).endOf('quarter')
}

/**
 * 某年的第一个和最后一天
 */
 const someYearFirstDay = (year: string): moment.Moment => {
  return moment(year).startOf('year')
}

const someYearLastDay = (year: string): moment.Moment => {
  return moment(year).endOf('year')
}

/**
 * 一周前
 */
function aWeekAgo(): moment.Moment {
  return moment().subtract(7, 'day');
}

/**
 * 此时此刻
 */
function now(): moment.Moment {
  return moment();
}

/**
 * 今天以后的时间不可选中
 */
const disabledDate = (current: moment.Moment) => {
  return current && current > moment().endOf('day')
}

/**
 * moment 格式转 数字
 */
const momentToNum = (date: moment.Moment, format: string = 'YYYYMM' ): number => {
  const dateStr: string = moment(date).format(format);
  const dateNum: number = parseInt(dateStr);
  return dateNum;
}

/**
 * moment 格式转 字符串
 */
const momentToStr = (date: moment.Moment, format: string = 'YYYYMM'): string => {
  const dateStr: string = moment(date).format(format);
  return dateStr;
}

const Date = {
  date2str,
  date2int,
  datetime2str,
  monthFirstDay,
  monthLastDay,
  aWeekAgo,
  now,
  preMonth,
  preMonthStr,
  preMonthFirstDay,
  preMonthLastDay,
  someMonthFirstDay,
  someMonthLastDay,
  someQuaterFirstDay,
  someQuaterLastDay,
  someYearFirstDay,
  someYearLastDay,
  momentToNum,
  momentToStr,
  disabledDate
}

export default Date
