import React, { useState, FC, memo } from 'react';
import { Space, Button, DatePicker, Form } from 'antd';

import { Card } from '@comps';

import moment from 'moment';

interface Props {
  onClose: () => void,
  ids?: any,
  generateWriteOff: (GLDate: any,trxDate: any) => void,
};


const { Item } = Form;

const SelectCheckPeriodMD: FC<Props> = memo(({ 
  onClose,
  ids,
  generateWriteOff
}) => {
  const dateMonth: string = moment().startOf('day').format('YYYYMMDD');

  const thisMonthDate = moment(moment().format());
  const [form] = Form.useForm();
  const [GLDate, setGLDate] = useState(dateMonth);
  const [trxDate, setTrxDate] = useState(dateMonth);

  const onFinish = () => {
    generateWriteOff(GLDate,trxDate);
    onClose();
  }

  const handleGLDate = (date: any, dateString: string) => {
    setGLDate(dateString);
  }

  const handleTrxDate = (date: any, dateString: string) => {
    setTrxDate(dateString);
  }
 
  return (
    <Card title="选择回写日期">
      <Form
        onFinish={onFinish}
        form={form}
        initialValues={{
          GLDate: thisMonthDate,
          trxDate: thisMonthDate,      
        }}
      >
        <div style={{ marginTop: 30, marginBottom: 20 }}>
          <Space size="large">
            <Item label="总账日期" name="GLDate" rules={[{ required: true }]}>
              <DatePicker
                format="YYYYMMDD" 
                style={{ width: '150%' }}
                onChange={handleGLDate}
                inputReadOnly
              />
            </Item>
          </Space>
          <Space size="large">
            <Item label="事务处理日期" name="trxDate" rules={[{ required: true }]}>
              <DatePicker
                format="YYYYMMDD" 
                style={{ width: '150%' }}
                onChange={handleTrxDate}
                inputReadOnly
              />
            </Item>
          </Space>
        </div>
        <div style={{textAlign:'center'}}>
          <Space size="large">
            <Button type="primary" htmlType="submit">确定</Button>
            <Button onClick={onClose}>取消</Button>
          </Space>
        </div>
      </Form>
    </Card>
  );
});

export default SelectCheckPeriodMD;
