import React, { FC } from "react";
import { Resizable } from 'react-resizable'

interface Props {
  onResize: () => void,
  width: number,
}

const ResizeableTitle:FC<Props> = (props) => {
  const { onResize, width, ...restProps } = props;
 
  if (!width) {
    return <th {...restProps} />;
  }
 
  return (
    <Resizable
      width={width}
      height={0}
      onResize={onResize}
      draggableOpts={{ enableUserSelectHack: false }}
    >
      <th {...restProps} />
    </Resizable>
  );
}

export default ResizeableTitle