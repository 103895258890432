import * as React from "react";
import {
  Form,
  DatePicker,
  Input,
  message,
  Space,
  Button,
  Select,
  Table as AdTable,
} from "antd";
import {
  Page,
  Card,
  PermissionButton,
  Lookup,
  Table,
  LinkText,
  Modal,
} from "@comps";
import { ColumnProps } from "@comps/types";
import { DateUtils, Http, Context, Env } from "@utils";
import DetailViewer from "./modal/detailViewer";
import _, { forEach, result } from "lodash";
import { useState } from "react";
import moment from "moment";
import SyncModal from "./modal/syncModal";

export interface Props {}
interface NObject {
  [key: string]: string | number | undefined | null | void;
}

const RedInvoice: React.FC<Props> = () => {
  // let [table, setTable] = useState<Table>()

  // const onTableRef = (tableRef: Table) => {
  //   table = tableRef
  //   setTable(tableRef)
  // }

  // const tableRef = React.useRef<Table>();
  // const initQuery = React.useMemo<JSONObject>(() => ({
  //   invoiceDateFromTo: [DateUtils.monthFirstDay(), DateUtils.now()],
  // }), []);
  const [modal, modalHolder] = Modal.useModal();
  const [redRequestApplyReason, setRedRequestApplyReason] = useState<NObject>(
    {}
  );

  const handleViwer = async (record: any) => {
    await Http.get(`/tax/output-red-request/query/${record.id}/detail`).then(
      (res) => {
        modal.open({
          width: 1200,
          content(resolve, reject) {
            return <DetailViewer data={res} reject={reject} />;
          },
        });
      }
    );
  };
  const handleRevoke = (record: any) => {
    console.log(record, "============");
    Http.post(`/tax/output-red-request/cancel/${record?.id}`).then((res) => {
      console.log(res, "======");
    });
  };
  //   React.useEffect(() => {
  //     (async () => {
  //       await Http.get("/sys/lookups/kv", {
  //         lookupType: "RED_REQUEST_APPLY_REASON",
  //       }).then((res) => {
  //         console.log(res);
  //         let reasonObj = {} as NObject;
  //         res.map((item: any) => {
  //           console.log(item.k, item.v);
  //           reasonObj[item.k] = item.v;
  //         });
  //         console.log(reasonObj, res);
  //         setRedRequestApplyReason(reasonObj);
  //       });
  //     })();
  //   }, []);
  const reasonEnum: NObject = {
    "0": "已抵扣",
    "7": "未抵扣无法认证",
    "8": "开票有误购买方拒收",
  };

  const initColumns: ColumnProps[] = [
    {
      key: "identity",
      dataIndex: "identity",
      title: "购销身份",
      width: 140,
      fixed: "left",
    },
    {
      key: "buyerName",
      dataIndex: "buyerName",
      title: "对方纳税人名称",
      width: 170,
      fixed: "left",
    },
    {
      key: "buyerTaxNo",
      dataIndex: "buyerTaxNo",
      title: "纳税人识别号",
      width: 140,
    },
    {
      key: "blueInvoiceNo",
      dataIndex: "blueInvoiceNo",
      title: "对应蓝票数电票号码",
      width: 200,
    },
    {
      key: "blueInvoiceCode",
      dataIndex: "blueInvoiceCode",
      title: "对应蓝票发票代码",
      width: 160,
    },
    {
      key: "blueInvoiceNo",
      dataIndex: "blueInvoiceNo",
      title: "对应蓝票发票号码",
      width: 160,
    },
    {
      key: "redNoticeNo",
      dataIndex: "redNoticeNo",
      title: "红字通知单编号",
      width: 140,
    },
    {
      key: "totalAmount",
      dataIndex: "totalAmount",
      title: "发票金额",
      width: 120,
    },
    { key: "taxAmount", dataIndex: "taxAmount", title: "发票税额", width: 120 },
    {
      key: "redReason",
      dataIndex: "redReason",
      title: "冲红原因",
      width: 120,
      render: (value, row) => {
        if (value == "0") {
          return "已抵扣";
        } else if (value === "7") {
          return "未抵扣无法认证";
        } else if (value == "8") {
          return "开票有误购买方拒收";
        }
      },
    },
    {
      key: "confirmInvoiced",
      dataIndex: "confirmInvoiced",
      title: "确认即开票",
      width: 120,
    },
    {
      key: "recordedStatus",
      dataIndex: "recordedStatus",
      title: "状态",
      width: 120,
    },
    { key: "remark", dataIndex: "remark", title: "备注", width: 120 },
    {
      key: "invoiceStatus",
      dataIndex: "invoiceStatus",
      title: "开具状态",
      width: 120,
    },
    {
      key: "action",
      title: "操作",
      width: 120,
      fixed: "right",
      render: (value: string, record) => (
        <>
          <LinkText label="查看" onClick={() => handleViwer(record)} />
          {record?.allowCancel ? (
            <LinkText
              label="撤销"
              onClick={() => handleRevoke(record)}
              style={{ marginLeft: "10px" }}
            />
          ) : null}
        </>
      ),
    },
  ];
  const [columns, setColumns] = useState(initColumns);
  let [table, setTable] = useState<Table>();
  const [invoiceDateF, setInvoiceDateF] = useState(
    moment(DateUtils.preMonthFirstDay()).format("YYYYMMDD")
  );
  const [invoiceDateT, setInvoiceDateT] = useState(
    moment(DateUtils.monthLastDay()).format("YYYYMMDD")
  );
  const handleSync=()=>{
    modal.open({
      width: 500,
      content(resolve, reject) {
        return <SyncModal  onCancel={reject} onConfirm={resolve} />;
      },
    });
  }

  //   const onIdentityChange = (v: any) => {
  //     console.log(v);
  //     let isSeller = true;
  //     if (v == "销售方") {
  //       isSeller = true;
  //     } else {
  //       isSeller = false;
  //     }
  //     const newColumns = columns.map((item) => {
  //       if (item.dataIndex === "buyerName" || item.dataIndex === "sellerName") {
  //         return {
  //           ...item,
  //           dataIndex: isSeller ? "buyerName" : "sellerName",
  //         };
  //       } else if (
  //         item.dataIndex === "buyerTaxNo" ||
  //         item.dataIndex === "sellerTaxNo"
  //       ) {
  //         return {
  //           ...item,
  //           dataIndex: isSeller ? "buyerTaxNo" : "sellerTaxNo",
  //         };
  //       }
  //       return item;
  //     });
  //     // console.log(newColumns, table?.props.columns,"====");

  //     setColumns(newColumns);
  //     table?.refresh();
  //   };
  const onInvoiceDateFromChange = (time: any) => {
    console.log(time, "==========", moment(time).format("YYYYMMDD"));
    if(time){
        moment(time).format("YYYYMMDD");
        setInvoiceDateF(moment(time).format("YYYYMMDD"));
    }
    else
    setInvoiceDateF("");
  };
  const onInvoiceDateToChange = (time: any) => {
    console.log(time, "==========", moment(time).format("YYYYMMDD"));
    if(time){
    moment(time).format("YYYYMMDD");
    setInvoiceDateT(moment(time).format("YYYYMMDD"));
    }
    else
    setInvoiceDateT("");
  };

  const invoiceDateFrom = DateUtils.preMonthFirstDay();
  const invoiceDateTo = DateUtils.monthLastDay();
  return (
    <Page>
      {modalHolder}
      <Card
        title="红字发票确认信息"
        actions={
          <>
            <Button type="primary" onClick={()=>handleSync()}>同步</Button>
          </>
        }
      >
        <Table
          url="/tax/output-red-request/query"
          queryProps={{
            initialValues: {
              invoiceDateFrom,
              invoiceDateTo,
            },
          }}
          formAdditionalParameters={{
            invoiceDateFrom: invoiceDateF,
            invoiceDateTo: invoiceDateT,
          }}
          //   onTableRef={onTableRef}
          pagination={{ hideOnSinglePage: false }}
          //   scroll={{ y: 430 }}
          columns={columns}
        >
          <Form.Item name="applyType">
            <Select
              placeholder="销方选择"
              //   onChange={onIdentityChange}
              options={[
                {
                  value: "1",
                  label: "销售方",
                },
                {
                  value: "0",
                  label: "购买方",
                },
              ]}
            />
          </Form.Item>
          <Form.Item name="requestStatus">
            <Lookup lookupType="RED_REQUEST_STATUS" placeholder="确认单状态" />
          </Form.Item>
          <Form.Item name="taxName">
            <Input placeholder="对方纳税人名称" />
          </Form.Item>
          <Form.Item name="confirmInvoiced">
            <Select
              placeholder="确认即开票"
              options={[
                {
                  value: "true",
                  label: "是",
                },
                {
                  value: "false",
                  label: "否",
                },
              ]}
            />
          </Form.Item>
          <Form.Item name="invoiceDateFrom">
            <DatePicker  style={{ width: "100%" }} format="YYYY-MM-DD" onChange={onInvoiceDateFromChange} />
          </Form.Item>
          <Form.Item name="invoiceDateTo">
            <DatePicker  style={{ width: "100%" }} format="YYYY-MM-DD" onChange={onInvoiceDateToChange} />
          </Form.Item>
          <Form.Item name="invoiceStatus">
            <Select
              placeholder="开票状态"
              options={[
                {
                  value: "true",
                  label: "已开具",
                },
                {
                  value: "false",
                  label: "未开具",
                },
              ]}
            />
          </Form.Item>
          {/* <Form.Item name="invoiceType" label="录入方身份">
            <Select
              placeholder="请选择"
              options={[
                {
                  value: "销售方",
                  label: "销售方",
                },
                {
                  value: "购买方",
                  label: "购买方",
                },
              ]}
            />
          </Form.Item> */}
        </Table>
      </Card>
    </Page>
  );
};

export default RedInvoice;
