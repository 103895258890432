import React, { FC, useState, useEffect } from 'react';
import { 
  Form, 
  Select, 
  Input, 
  Popconfirm, 
  Space, 
  Row, 
  Col, 
  Spin, 
  DatePicker 
} from 'antd';
import { 
  QuestionCircleTwoTone, 
  CheckCircleTwoTone, 
  ExclamationCircleTwoTone, 
} from '@ant-design/icons';

import { 
  Page, 
  Card, 
  Table, 
  Modal, 
  LinkText, 
  Lookup, 
  TableSummaryRow, 
  SBUSelect, 
  PermissionButton 
} from '@comps';
import BatchRetail from './batch/retail';
import BatchApprove from './modal/approve-res';
import InvoicedList from './modal/invoiced-list';

import { Http, Message, Context, Math, emitter, DateUtils } from '@utils'; 
import { ColumnProps } from '@comps/types'; 
import { ApplyStatus, ApplyType, ApplyTypeVal, statusCodeObj } from './interface';
import SelectCheckPeriodMD from './modal/selectCheckPeriod.md';
import moment from 'moment';

interface completeInfoProps { 
  isComplete: boolean; 
  msg: string;
}

const { Item } = Form;
const { Option } = Select;
const { RangePicker } = DatePicker;

const Apply: FC = () => {

  let [table, setTable] = useState<Table>();
  const [totalQuantity, setTotalQuantity] = useState(0);
  const [totalWithTaxAmount, setTotalWithTaxAmount] = useState(0); 
  const [totalTaxAmount, settotalTaxAmount] = useState(0);
  const [totalWithTaxDiscountAfterAmount, setTotalWithTaxDiscountAfterAmount] = useState(0);
  const [totalDiscountTaxAmount, setTotalDiscountTaxAmount] = useState(0);

  const [form] = Form.useForm();
  const [regionOptions, setRegionOptions] = useState<JSONObject[]>([]);

  const [pageLoading, setPageLoading] = useState(false);
  const [batchSubmitLoading, setBatchSubmitLoading] = useState(false);
  const [produceInvoiceLoading, setProduceInvoiceLoading] = useState(false);
  const [modal, modalHolder] = Modal.useModal();

  const currentRequestDate = [
    DateUtils.monthFirstDay(), 
    DateUtils.monthLastDay(),
  ];

  useEffect(() => {
    getregionOptions();
  }, []);

  const onTableRef = (tableRef: Table) => {
    setTable(tableRef);
    table = tableRef;
  };

  const applyTypeObj: JSONObject = {
    [ApplyTypeVal.RETAIL]: ApplyType.RETAIL,
    [ApplyTypeVal.ADVANCE]: ApplyType.ADVANCE,
    [ApplyTypeVal.PROGRESS]: ApplyType.PROGRESS,
    [ApplyTypeVal.SETTLE]: ApplyType.SETTLE,
    [ApplyTypeVal.OTHER]: ApplyType.OTHER,
    [ApplyTypeVal.MAIN_ASSISTANT]: ApplyType.MAIN_ASSISTANT,
  };

  const getregionOptions = () => {
    Http.get('/sys/regions/findRegionByUser')
      .then((res: JSONObject[]) => {
        setRegionOptions(res);
      });
  };

  const refreshTable = () => {
    table?.refresh();
  };

  const handleDelete = (id: number) => {
    Message.info('删除中');
    setPageLoading(true);

    Http.del(`/request/${id}`)
      .then(() => {
        Message.success('删除成功');
        refreshTable();
      })
      .finally(() => {
        setPageLoading(false);
      });
  };

  const showRetailBatchMD = () => {
    modal.open({
      width: 1300,
      content: (_1, reject) => (
        <BatchRetail
          refreshTable={refreshTable}
          onClose={reject} 
        />
      )
    })
  }

  const handleBatchInvApplyTypeChange = (type: any) => {
    form.resetFields();

    switch (type) {
      case ApplyType.RETAIL:
        showRetailBatchMD();
        break;
    
      default:
        break;
    }
  };

  const handleInvApplyTypeChange = (type: any) => {
    Context.clearCacheKey('/output/apply/create/:applyType');
    const state = {
      routerFrom:'开票申请',
    };
  
    Context.handleAdditionalPane(
      '创建申请', 
      `/output/apply/create/${type}`,  
      `/output/apply/create/${type}`,
      state
    )

    form.resetFields();
  };

  const addListener = () => {
    emitter.addListener('refreshApplyTable', () => {
      refreshTable();
      removeListener();
    });
  };

  const removeListener = () => {
    emitter.removeListener('refreshApplyTable', () => {});
  };

  const showDetail = (row: JSONObject) => {
    removeListener();

    const { id, requestType } = row;
    const state = {
      routerFrom:'开票申请',
    };
    
    const route = `/output/apply/detail/${id}/${applyTypeObj[requestType]}`;
    Context.handleAdditionalPane('申请详情', route, route, state);

    addListener();
  };

  const showInvoicedListMD = (rowId: number) => {
    modal.open({
      width: 900,
      content: (_1, _2) => (
        <InvoicedList rowId={rowId} />
      )
    })
  };

  const setTableSummaryData = ({
    totalQuantity,
    totalWithTaxAmount, 
    totalTaxAmount, 
    totalWithTaxDiscountAfterAmount, 
    totalDiscountTaxAmount 
  }: JSONObject) => {

    setTotalQuantity(totalQuantity);
    setTotalWithTaxAmount(totalWithTaxAmount);
    settotalTaxAmount(totalTaxAmount);
    setTotalWithTaxDiscountAfterAmount(totalWithTaxDiscountAfterAmount);
    setTotalDiscountTaxAmount(totalDiscountTaxAmount);
  };

  const getStatusIds = (status: string): number[] | undefined => {
    const selectedRow: JSONObject[] = table?.getSelectedRowData() ?? [];
    const ids: number[] = [];
    let isAllRight = true;

    if (selectedRow.length < 1) {
      Message.warn('请至少选择一条数据');
      return;
    }
    
    for (let i = 0; i < selectedRow.length; i++) {
      const { id, statusCode } = selectedRow[i];

      if (statusCode !== status) {
        isAllRight = false;
        break;
      }

      ids.push(id);
    }

    if (!isAllRight) {
      Message.warn(`请选择 【${statusCodeObj[status]}】的单据`);
      return;
    }

    return ids;
  };

  const handleBatchSubmit = () => {
    const ids = getStatusIds(ApplyStatus.NEW);

    if (ids) {
      setBatchSubmitLoading(true);

      Http.post('/request/batchSubmit', { ids })
        .then(() => {
          refreshTable();
          Message.success('提交成功');
        })
        .finally(() => {
          setBatchSubmitLoading(false);
        })
    }
  };

  const handleProduceInvoice = () => {
    const ids = getStatusIds(ApplyStatus.APPROVED) ?? [];
    
    if (ids.length > 1) {
      Message.warn('请选择一条数据');
      return;
    }

    const generateWriteOff = (GLDate: any,trxDate: any) => {
      Http.post(`/request/${ids}/createArInvoice?GLDate=${GLDate}&trxDate=${trxDate}`)
        .then(() => {
          refreshTable();
          Message.success('生成AR发票成功');
        })
    }
    
    if (ids.length === 1) {
      // setProduceInvoiceLoading(true);

      // Http.post(`/request/${ids}/createArInvoice`)
      //   .then(() => {
      //     refreshTable();
      //     Message.success('生成AR发票成功');
      //   })
      //   .finally(() => {
      //     setProduceInvoiceLoading(false);
      //   })

      modal.open({
        width: 450,
        content: (_1, reject) => (
          <SelectCheckPeriodMD 
            onClose={reject} 
            ids={ids}
            generateWriteOff={generateWriteOff}
          />
        )
      });
    }
  };

  const showBatchApproveMD = () => {
    const ids = getStatusIds(ApplyStatus.INPROGRESS);

    if (ids) {
      modal.open({
        width: 400,
        content: (_1, reject) => (
          <BatchApprove 
            ids={ids} 
            refreshTable={refreshTable}
            onClose={reject} 
          />
        )
      })
    }
  };

  const renderQuestCircle = (
    id: number, 
    row: JSONObject,
  ) => {

    const { 
      customerComplete, 
      lineComplete, 
      existDiscountLine, 
      batchCreate,
    } = row;

    const isCompleteDiscountLine = 
      (existDiscountLine === false) || 
      (batchCreate === false);
    
    const completeInfoArr: completeInfoProps[] = [
      {
        isComplete: customerComplete,
        msg: '购方信息不完整',
      }, 
      {
        isComplete: lineComplete,
        msg: '待开明细行信息不完整',
      }, 
      {
        isComplete: isCompleteDiscountLine,
        msg: '存在折扣行',
      },
    ];

    let isRemind = false;
    const msgArr: string[] = [];

    completeInfoArr.forEach(completeInfo => {
      if (!completeInfo.isComplete) {
        isRemind = true;
        msgArr.push(completeInfo.msg);
      } 
    });

    return isRemind 
      ? (<QuestionCircleTwoTone title={msgArr.join('、')} />) 
      : (<CheckCircleTwoTone twoToneColor="#35e01f" />);
  };

  const renderExclamation = (
    id: number, 
    row: JSONObject,
  ) => {
    const { existMergeException } = row;

    if (existMergeException) {
      return (
        <ExclamationCircleTwoTone 
          twoToneColor="orange" 
          title="存在合并为 0 的明细"
        />
      )
    }
  }
  
  const renderARInvoiceStatus = (arInvoiceStatus: number) => {
    let arInvoiceStatusText = ''

    switch (arInvoiceStatus) {
      case 1:
        arInvoiceStatusText = '未回写';
        break;
    
      case 2:
        arInvoiceStatusText = '已回写';
        break;
    
      case 3:
        arInvoiceStatusText = '已勾稽';
        break;
    
      case 4:
        arInvoiceStatusText = '撤销勾稽';
        break;
    
      case 5:
        arInvoiceStatusText = '回写失败';
        break;
    
      case 6:
        arInvoiceStatusText = '冲销回写失败';
        break;
    
      default:
        break;
    }

    return arInvoiceStatusText;
  };
  
  const columns: ColumnProps[] = [
    { key: 'id', title: '', width: 42, fixed: 'left',
      render: renderQuestCircle
    },
    { key: 'id', title: '', width: 42, fixed: 'left',
      render: renderExclamation
    },
    { key: 'requestNo', title: '单据号', width: 170, fixed: 'left',
      render: (requestNo: string, row: JSONObject) => (
        <LinkText 
          label={requestNo} 
          onClick={() => showDetail(row)} 
        />
      )
    },
    { key: 'requestorName', title: '申请人', width: 100 },
    { key: 'requestTime', title: '申请时间', width: 180 },
    { key: 'customerName', title: '客户名称', width: 300 },
    { key: 'contractNo', title: '合同号', width: 160 },
    { key: 'contractName', title: '合同名称', width: 400 },
    // { key: 'invoiceDate', title: '开票日期', width: 150 },
    { key: 'regionName', title: '区域', width: 150 },
    { key: 'remark', title: '申请说明', width: 140 },
    { key: 'withTaxAmount', title: '申请开票金额', width: 140, format: 'amount' },
    { key: 'taxAmount', title: '申请开票税额', width: 140, format: 'amount' },
    { key: 'withTaxDiscountAmount', title: '折扣总金额', width: 140, format: 'amount' },
    { key: 'discountTaxAmount', title: '折扣总税额', width: 140, format: 'amount' },
    { key: 'sbuName', title: 'SBU 名称', width: 180, align: 'center' },
    { key: 'orgName', title: '公司名称', width: 220 },
    { key: 'requestTypeDesc', title: '开票申请类型', width: 160 },
    { key: 'bigCustomerName', title: '大客户名称', width: 220 },
    { key: 'approverName', title: '审批人', width: 140 },
    { key: 'invoiceRemark', title: '审批备注', width: 140 },
    { key: 'arInvoiceStatus', title: 'AR 发票状态', width: 110, 
      render: renderARInvoiceStatus 
    },
    { key: 'statusCode', title: '状态', width: 80, align: 'center', fixed: 'right',
      render: (statusCode: string) => statusCodeObj[statusCode]
    },
    { key: 'id', title: '操作', width: 150, align: 'center', fixed: 'right',
      render: (id: number, row: JSONObject) => (
       <Space size="large">
         <LinkText label="查看" onClick={() => showDetail(row)} />
         <LinkText label="发票" onClick={() => showInvoicedListMD(id)} />
         {row.statusCode === 'CANCEL' || 
          <Popconfirm
            title="是否确认删除本条记录 ?"
            onConfirm={() => handleDelete(id)}
          >
            <LinkText label="删除" />
          </Popconfirm>
         }
       </Space>
      )
    }
  ];

  const tableSummary = () => {
    return (
      <TableSummaryRow>
        <Col span={4}>
          总数量: {Math.numThousandth(totalQuantity)}
        </Col>
        <Col span={5}>
          总开票申请金额: {Math.num2money(totalWithTaxAmount)}
        </Col>
        <Col span={5}>
          总开票申请税额: {Math.num2money(totalTaxAmount)}
        </Col>
        <Col span={5}>
          总折扣金额: {Math.num2money(totalWithTaxDiscountAfterAmount)}
        </Col>
        <Col span={5}>
          总折扣税额: {Math.num2money(totalDiscountTaxAmount)}
        </Col>
      </TableSummaryRow>
    );
  };

  return (
    <Spin spinning={pageLoading}>
      <Page>
        {modalHolder} 
        <Card 
          title="开票申请" 
          actions={(
            <Space className="card-space">
               <PermissionButton 
                permissions="OutputRequest.batchSubmit"
                type="primary"
                onClick={handleProduceInvoice}
                loading={produceInvoiceLoading}
              >
                生成AR发票
              </PermissionButton>
              <PermissionButton 
                permissions="OutputRequest.batchSubmit"
                type="primary"
                onClick={handleBatchSubmit}
                loading={batchSubmitLoading}
              >
                批量提交
              </PermissionButton>
              <PermissionButton 
                permissions="OutputRequest.batchApprove"
                type="primary"
                onClick={showBatchApproveMD}
              >
                批量审批
              </PermissionButton>
              <Form 
                form={form}  
              >
                <Row>
                  <Col style={{ marginRight: 6 }}>
                    <Item 
                      name="invApply" 
                      style={{ marginBottom: 0 }}
                    >
                      <Select 
                        placeholder="批量创建开票申请" 
                        onChange={handleBatchInvApplyTypeChange}
                        style={{ width: 160 }}
                      >
                        <Option value={ApplyType.RETAIL}>零售</Option>
                      </Select>
                    </Item>
                  </Col>
                  <Col>
                    <Item 
                      name="invApply" 
                      style={{ marginBottom: 0 }}
                    >
                      <Select 
                        placeholder="创建开票申请" 
                        onChange={handleInvApplyTypeChange}
                        style={{ width: 130 }}
                      >
                        <Option value={ApplyType.RETAIL}>零售</Option>
                        <Option value={ApplyType.ADVANCE}>预付款</Option>
                        <Option value={ApplyType.PROGRESS}>进度款</Option>
                        <Option value={ApplyType.SETTLE}>结算款</Option>
                        <Option value={ApplyType.OTHER}>其他</Option>
                        <Option value={ApplyType.MAIN_ASSISTANT}>全国性家装</Option>
                      </Select>
                    </Item>
                  </Col>
                </Row>
              </Form>
            </Space>
          )}
        >
          <Table
            onTableRef={onTableRef}
            url="/request"
            queryProps={{
              initialValues: {
                requestDateFromTo: currentRequestDate
              }
            }}
            columns={columns}
            selectedRowKeys={[]}
            summary={tableSummary} 
            setTableSummaryData={setTableSummaryData}
            scroll={{ y: window.innerHeight - 300 }}
          >
            <Item name="requestNo">
              <Input placeholder="申请单号" />
            </Item>
            <Item name="requestDateFromTo">
              <RangePicker 
                placeholder={['申请日期从', '申请日期至']} 
                format="YYYY-MM-DD" 
                inputReadOnly
              />
            </Item>
            <Item name="sbuIds">
              <SBUSelect />
            </Item>
            <Item name="statusCode">
              <Lookup 
                placeholder="审批状态" 
                lookupType="OUTPUT_REQUEST_STATUS" 
                mode="multiple"
                showSearch={false} 
              />
            </Item>
            <Item name="key">
              <Input placeholder="客户编码/客户名称/申请人" />
            </Item>
            <Item name="orderNo">
              <Input placeholder="业务单据号" />
            </Item>
            <Item name="requestType">
              <Lookup 
                placeholder="开票申请类型" 
                lookupType="OUTPUT_REQUEST_TYPE" 
              />
            </Item>
            <Item name="contractNo">
              <Input placeholder="合同号" />
            </Item>
            <Item name="regionCodes">
              <Select 
                placeholder="区域"
                mode="multiple"
                showSearch={false}
                options={regionOptions.map(
                  SBUOption => ({ 
                    value: SBUOption.code, 
                    label: SBUOption.name, 
                  })
                )} 
              />
            </Item>
            <Item name="arInvoiceStatus">
              <Lookup 
                placeholder="AR 发票状态" 
                lookupType="AR_INVOICE_STATUS" 
              />
            </Item>
            <Item name="invoiceNo">
              <Input placeholder="发票号码" />
            </Item>
          </Table>
        </Card>
      </Page>
    </Spin>
  );
};

export default Apply;