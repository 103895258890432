import { Button, Card, Lookup, Table, TableSummaryRow } from "@comps";
import { ColumnProps } from "@comps/types";
import { Col, DatePicker, Form, Input, Select, Space, message } from "antd";
import React, { FC, useEffect, useState } from "react";

import { Http } from "@utils";

interface Props {
  onClose: () => void;
  onConfirm: () => void;
  parentTable: any;
}

const { Item } = Form;
const { RangePicker } = DatePicker;
const { Option } = Select;

interface TableSummaryProps {
  count: number;
}

const AsyncOrderSystem: FC<Props> = ({ onConfirm, onClose, parentTable }) => {
  const [dataSource, setDataSource] = useState();
  let [table, setTable] = useState<Table>();
  const [storeNo, setStoreNo] = useState("");
  const onTableRef = (tableRef: Table) => {
    table = tableRef;
    setTable(tableRef);
  };

  useEffect(() => {
    Http.get("/tax/orders/industryNo").then((res) => {
      table?.setFormValue({
        storeNo: res,
      });
      setStoreNo(res);
    });
  }, [table]);

  const columns: ColumnProps[] = [
    { key: "noteNo", title: "小票号", width: 110, align: "center" },
    { key: "storeNo", title: "门店编号", width: 110, align: "center" },
    { key: "storeName", title: "门店名称", width: 110, align: "center" },
    { key: "noteAmount", title: "金额", width: 110, align: "center" },
    { key: "industryNo", title: "业态编码", width: 110, align: "center" },
  ];

  const importOrder = () => {
    const tableData = table?.getSelectedRowData();
    let ids = tableData?.map((value: any) => {
      return value.id;
    });
    if (ids?.length === 0) {
      message.warning("请选择需要引入的单据");
    } else {
      Http.post("/tax/orders/note/import", ids).then(() => {
        onClose();
        parentTable?.refresh();
      });
    }
  };
  return (
    <Card
      title="同步订单系统待开单据"
      actions={
        <Space>
          <Button type="primary" onClick={importOrder}>
            引入
          </Button>
          <Button
            type="primary"
            onClick={() => {
              onClose();
            }}
          >
            返回
          </Button>
        </Space>
      }
      style={{ paddingInlineEnd: "3rem" }}
    >
      <Table
        onTableRef={onTableRef}
        url="/tax/orders/note/query"
        method="post"
        shouldFetchData={true}
        columns={columns}
        isExpandAllItem={true}
        resetEmptyFileds={{ storeNo }}
        shouldQueryNoAfterReset={true}
        formAdditionalParameters={{ billSource: 1 }}
        // queryProps={{
        //   initialValues: initQuery,
        // }}
        summary={() => {
          return (
            <TableSummaryRow>
              <Col span={6}>选中数量: </Col>
            </TableSummaryRow>
          );
        }}
        pagination={{ hideOnSinglePage: false }}
        selectedRowKeys={[]}
        scroll={{ y: window.innerHeight - 300 }}
      >
        {/* <Item>
          <RangePicker
            placeholder={["单据日期从", "单据日期至"]}
            format="YYYYMMDD"
            defaultValue={[DateUtils.monthFirstDay(), DateUtils.monthLastDay()]}
            inputReadOnly
          />
        </Item> */}
        <Item name="storeNo">
          <Input placeholder="门店编码" disabled />
        </Item>
        <Item
          name="noteNo"
          rules={[{ required: true, message: "请输入小票号" }]}
        >
          <Input placeholder="小票号" />
        </Item>
        <Item
          name="industryNo"
          rules={[{ required: true, message: "请输入业态编码" }]}
        >
          <Lookup lookupType="GB_INDUSTRY_NO" placeholder="业态编码" />
        </Item>
      </Table>
    </Card>
  );
};

export default AsyncOrderSystem;
