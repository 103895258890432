import React, { FC, useState, useEffect } from 'react';
import { Form, Space, Row, Col, InputNumber } from 'antd';

import { Card, Button, DisplayInput, HiddenFormItem } from '@comps';

import { Http, Message } from '@utils'; 

const { Item } = Form;

interface Props {
  rowData: JSONObject;
  setAllModuleData: (data: JSONObject) => void;
  onClose: () => void;
}

const AddItemEdit: FC<Props> = ({ 
  rowData,
  setAllModuleData,
  onClose,
}) => {

  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    rowData.price = rowData.withTaxPrice;
    form.setFieldsValue(rowData);
  }, []);

  const handlePriceChange = (price: any) => { 
    const { quantity } = form.getFieldsValue();  
    const withTaxAmount = price * quantity;

    form.setFieldsValue({
      withTaxAmount
    });
  };

  const handleEdit = () => {
    const formData = form.getFieldsValue();   
    
    setLoading(true);
  
    Http.post('/request/updatePrice', formData)
      .then(data => {
        setAllModuleData(data);
        onClose();
        Message.success('编辑成功');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Card title="增项编辑">
      <Form
        form={form}
        style={{ marginTop: 20}}
        labelCol={{ span: 6 }}
        onFinish={handleEdit}
      >
        <Row>
          <Col span={24}>
            <HiddenFormItem name="id" />
            <Item 
              label="含税单价"  
              name="price"
              rules={[{ required: true }]} 
            >
              <InputNumber 
                style={{ width: '100%' }}
                onChange={handlePriceChange} 
              />
            </Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Item 
              label="数量" 
              name="quantity"
            >
              <DisplayInput />
            </Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Item 
              label="含税金额" 
              name="withTaxAmount"
            >
              <DisplayInput />
            </Item>
          </Col>
        </Row>
        <Row style={{ marginTop: 12 }}>
          <Col span={24} style={{ textAlign: 'center' }}> 
            <Space size="large">
              <Button 
                type="primary" 
                htmlType="submit" 
                loading={loading}
              >
                确定
              </Button>
              <Button onClick={onClose}>取消</Button>
            </Space>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};

export default AddItemEdit;
