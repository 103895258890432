import axios from 'axios'
import { message } from 'antd'
import { Env, Context } from '@utils'
import { store } from '../index'
import { history } from '../routes'


// constants

// base url
const base = Env.getBaseUrl()
const loginPath = Env.getLoginPath()
// 需要重新登陆的状态码
const reLoginStatusCode = ['401']

axios.defaults.timeout = 5 * 60 * 1000

/**
 * 请求前拦截
 */
axios.interceptors.request.use(
  config => {
    return config;
  },
  err => {
    return Promise.reject(err);
  }
);

/**
 * 请求后拦截
 */
axios.interceptors.response.use(
  res => {
    const contentType: string = res.headers['content-type']
    if (contentType && 'application/json;charset=UTF-8' === contentType) {
      const code = res.data.code;

      // 当接口不遵守 {code, msg, data} 数据结构时不做处理
      if (code) {
        const msg = res.data.msg;
        const data = res.data.data

        if ("200" !== code) {
          // 用户尚未登陆, 跳转到登陆页面
          if (reLoginStatusCode.indexOf(code) > -1) {
            Context.dispatch({ type: 'ACTION_TOKEN_EXPIRE' })
            history.push(loginPath ? loginPath : '')

          } else {
            if (msg) {
              message.warn(`${msg}`, 3);
            }
          }

          // 抛出异常, 阻断后续程序执行
          const error = `[${code}]${msg}`
          throw error

        } else {
          return data;
        }
      } else {
        return res.data;
      }
    }

    return res.data;
  },
  (err) => {
    let errMsg: string = err.message
    console.log('err:', err, '  msg:', errMsg)
    if (errMsg && errMsg.includes('Network')) {
      message.warn('您的网络异常，请检查网络情况');
    } else if (err.isAxiosError && err.response && `${err.response.status}` === '504') {
      message.error('连接系统超时，请重试操作');
    } else {
      message.error('系统出现未知错误，请重试或联系管理员');
    }
    return Promise.reject(err);
  }
);

/**
 * 解析 URL 参数
 * @param {*} url
 * @param {*} params map
 */
function parseUrlParams(url: string, params?: any) {
  let actualUrl = url
  if (!url.includes("?")) {
    actualUrl += "?"
  }

  if (params) {
    Object.keys(params).forEach(key => {
      actualUrl = actualUrl + "&" + key + "=" + params[key]
    })
  }

  return actualUrl
}

function buildHeaders(config?: any) {
  let headers = {
    ...config
  }

  if (!headers.Authorization) {
    const state = store.getState()
    const token = state.ctx.token
    if (token) {
      headers.Authorization = token
    }
  }

  return headers
}

const getUrl = (url: string) => {
  return url.indexOf("http") > 0 ? url : `${base}${url}`;
}

/**
 * POST
 * @param {String} url
 * @param {Map} params
 * @param {*} config headers
 */
function post<D = any>(url: string, params?: any, config?: any): Promise<D> {
  let headers = buildHeaders(config)

  if (!headers['Content-Type']) {
    headers['Content-Type'] = 'application/json'
  }

  const result = axios({
    method: "post",
    url: getUrl(url),
    data: params,
    headers: headers
  }) as any;

  return result as Promise<D>;
};

/**
 * POST
 * @param {String} url
 * @param {Map} params
 * @param {*} config headers
 */
function postQuery<D = any>(url: string, params?: any, config?: any): Promise<D> {
  let headers = buildHeaders(config)

  if (!headers['Content-Type']) {
    headers['Content-Type'] = 'application/json'
  }

  let ret = "";
  for (let it in params) {
    ret +=
      it + "=" + params[it] + "&";
  }

  const result = axios({
    method: "post",
    url: `${getUrl(url)}?${ret}`,
    data: params,
    headers: headers
  }) as any;

  return result as Promise<D>;
};



/**
 * POST application/x-www-form-urlencoded
 * @param {String} url
 * @param {Map} params
 * @param {*} config headers
 */
const postWithUrlencoded = (url: string, params?: any, config?: any) => {
  let headers = buildHeaders(config)

  if (!headers['Content-Type']) {
    headers['Content-Type'] = 'application/x-www-form-urlencoded'
  }

  return axios({
    method: "post",
    url: getUrl(url),
    data: params,
    transformRequest: [
      function (data) {
        let ret = "";
        for (let it in data) {
          ret +=
            encodeURIComponent(it) + "=" + encodeURIComponent(data[it]) + "&";
        }
        return ret;
      }
    ],
    headers: headers
  })
};

/**
 * POST 下载excel文件
 * @param {String} url
 * @param {Map} params
 * @param {*} config headers
 */
const postDownload = (filename: string, url: string, params?: any, config?: any) => {
  let headers = buildHeaders(config)

  if (!headers['Content-Type']) {
    headers['Content-Type'] = 'application/json'
  }

  return axios({
    method: "post",
    url: getUrl(url),
    data: params,
    headers: headers,
    responseType: "blob"
  }).then((res: any) => {
    const blob = new Blob([res], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8' })
    if ('download' in document.createElement('a')) { // 非IE下载
      const downloadElement = document.createElement('a')
      downloadElement.download = filename + '.xlsx'
      downloadElement.style.display = 'none'
      downloadElement.href = URL.createObjectURL(blob)
      document.body.appendChild(downloadElement)
      downloadElement.click()
      document.body.removeChild(downloadElement)// 下载完成移除元素
      window.URL.revokeObjectURL(downloadElement.href) // 释放掉blob对象
    } else { // IE10+下载
      navigator.msSaveBlob(blob, filename + '.xlsx')
    }
  })
};

/**
 * GET 下载excel文件
 * @param {String} url
 * @param {Map} params
 * @param {*} config headers
 */
 const getDownload = (filename: string, url: string, params?: any, config?: any) => {
  let headers = buildHeaders(config)

  if (!headers['Content-Type']) {
    headers['Content-Type'] = 'application/json'
  }

  return axios({
    method: "get",
    url: getUrl(url),
    data: params,
    headers: headers,
    responseType: "blob"
  }).then((res: any) => {
    const blob = new Blob([res], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8' })
    if ('download' in document.createElement('a')) { // 非IE下载
      const downloadElement = document.createElement('a')
      downloadElement.download = filename + '.xlsx'
      downloadElement.style.display = 'none'
      downloadElement.href = URL.createObjectURL(blob)
      document.body.appendChild(downloadElement)
      downloadElement.click()
      document.body.removeChild(downloadElement)// 下载完成移除元素
      window.URL.revokeObjectURL(downloadElement.href) // 释放掉blob对象
    } else { // IE10+下载
      navigator.msSaveBlob(blob, filename + '.xlsx')
    }
  })
};


/**
 * POST multipart/form-data
 * @param {String} url
 * @param {Map} params
 * @param {*} config headers
 */
const postWithFormData = (url: string, params?: any, config?: any) => {
  let headers = buildHeaders(config)

  if (!headers['Content-Type']) {
    headers['Content-Type'] = 'multipart/form-data'
  }

  return axios({
    method: "post",
    url: getUrl(url),
    data: params,
    headers: headers
  });
};

/**
 * POST multipart/form-data; boundary=----WebKitFormBoundarynl6gT1BKdPWIejNq
 * @param {String} url
 * @param {Map} params
 * @param {*} config headers
 */
const postWithFormFile = (url: string, params?: any, config?: any) => {
  let headers = buildHeaders(config)

  if (!headers['Content-Type']) {
    headers['Content-Type'] = 'multipart/form-data; boundary=----WebKitFormBoundarynl6gT1BKdPWIejNq'
  }

  return axios({
    method: "post",
    url: getUrl(url),
    data: params,
    headers: headers
  });
};

/**
 * PUT
 * @param {String} url
 * @param {Map} params
 * @param {*} config headers
 */
const put = (url: string, params?: any, config?: any) => {
  let headers = buildHeaders(config)

  if (!headers['Content-Type']) {
    headers['Content-Type'] = 'application/json'
  }

  return axios({
    method: "put",
    url: getUrl(url),
    data: params,
    headers: headers
  });
};

/**
 * DELETE
 * @param {String} url
 * @param {Map} params
 * @param {*} config headers
 */
const del = (url: string, params?: any, config?: any) => {
  let actualUrl = parseUrlParams(url, params)
  let headers = buildHeaders(config)
  return axios({
    method: "delete",
    url: getUrl(actualUrl),
    headers: headers
  });
};


/**
 * DELETE JSON
 * @param {String} url
 * @param {Map} params
 * @param {*} config headers
 */
const delWithJson = (url: string, params?: any, config?: any) => {
  let headers = buildHeaders(config)

  return axios({
    method: "delete",
    url: getUrl(url),
    data: params,
    headers: headers
  });
};

/**
 * GET
 * @param {String} url
 * @param {Map} params
 * @param {*} config headers
 */
function get<D = any>(url: string, params?: any, config?: any): Promise<D> {
  let actualUrl = parseUrlParams(url, params)
  let headers = buildHeaders(config)

  const result = axios({
    method: "get",
    url: getUrl(actualUrl),
    headers: headers
  }) as any;
  return result as Promise<D>;
}

let Http = {
  get: get,
  post: post,
  postQuery: postQuery,
  postDownload: postDownload,
  getDownload: getDownload,
  postWithUrlencoded: postWithUrlencoded,
  postWithFormData: postWithFormData,
  put: put,
  del: del,
  delWithJson: delWithJson,
  postWithFormFile: postWithFormFile,
}

export default Http
