import React, { FC, useEffect, useState, useImperativeHandle, forwardRef, useRef  } from 'react';
import { Modal, Form, Row, Col, Space, Button, InputNumber } from 'antd';

import { Card, DisplayInput, Download } from '@comps';

import { Http, FormUtil, Message } from '@utils';

interface Props {
  visible: boolean;
  getTableData: (params: JSONObject) => void;
  onCancle: () => void;
  ref: any;
  checkedCurrentPeriod: boolean;
}

const { Item } = Form;

const CheckSreultMD: FC<Props> = forwardRef(({
  visible,
  getTableData,
  onCancle,
  checkedCurrentPeriod
}, ref) => {
  const [form] = Form.useForm();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isDownload, setIsDownload] = useState('');
  const checkInfoRef: JSONObject = useRef();
  const [isInvoices, setIsInvoices] = useState('');
  const [loading, setLoading] = useState(false);

  useImperativeHandle(ref, () =>({
    getCheckResult
  }));

  const filterInvalidFormData = (formData: JSONObject): JSONObject => {
    const keys = Object.keys(formData);

    for (var key of keys) {
      const val = formData[key];
      if (typeof val === 'undefined') {
        delete formData[key];
      } else if (val === null) {
        delete formData[key];
      }
    };

    return formData;
  };

  const handleRetrieveInvoice = () => {
    setIsModalVisible(true);
    return;
    const formData = FormUtil.filterInvalidFormData(form.getFieldsValue());
    getTableData(formData);

    onCancle();
  };

  const handleOk = () => {
    setIsModalVisible(false);
    getSecondaryData('1');
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const getCheckResult = (checkResultData: JSONObject) => {
    form.setFieldsValue(checkResultData);
    setIsDownload(checkResultData.fileUrl)
    setIsInvoices(checkResultData.invoices)
  }

  const getSecondaryData = async (type: string) => {
    setLoading(true)
    
    let formData: JSONObject = {};
    const res: JSONObject = await Http.get('/input/gx/period');
    checkInfoRef.current = res;
    
    formData = filterInvalidFormData(formData);
    formData.period = checkInfoRef.current.period;
    formData.taxNo = checkInfoRef.current.taxNo;
    formData.trxType = type;
    formData.invoices = isInvoices;

    Http.post('/input/gx/gx', formData)
    .then(res => {
      Message.info('操作完成')
      setLoading(false)
    })
    .finally(() => {
      handleCancel();
      onCancle();
    })
  };

  return (
    <Modal
      centered
      width={820}
      footer={null} 
      visible={visible}
      onCancel={onCancle}
    >
        <Card 
          title="检索结果" 
          loading={loading}
          style={{ 
            padding: 0, 
            boxShadow: 'none' 
          }}
        >
          <Form 
            form={form}
            labelCol={{ span: 12 }}
            style={{ paddingRight: 30 }}  
            onFinish={handleRetrieveInvoice}
          >
            <div style={{marginLeft:30,marginTop: 40}}>本期已勾选税额：{checkedCurrentPeriod}</div>
            <Row style={{ marginTop: 10 }}>
              <Col span={10}>
                <Item label="本期预勾选总税额" name="totalTaxAmount">
                  <DisplayInput />
                </Item>
              </Col>
              <Col span={8}>
                <Item label="本期预勾选总份数" name="quantity">
                  <DisplayInput />
                </Item>
              </Col>
              <Col span={5}>
                <Item label="误差值" name="lapse">
                  <DisplayInput />
                </Item>
              </Col>
            </Row>
            <div style={{marginLeft:30,marginBottom:40,color:'#1085bb',textDecoration:'underline',cursor:'pointer'}}>
              <Download url={isDownload} label='下载详细数据' />
            </div>
            <Row>
              <Col 
                span={24} 
                style={{ 
                  marginTop: 20,
                  textAlign: 'center', 
                }}
              >
                <Button 
                  type="primary"
                  htmlType="submit" 
                  style={{ 
                    marginRight: 40,
                    textAlign: 'center', 
                    }}
                >
                  确定
                </Button>
                <Button onClick={onCancle}>取消</Button>
              </Col>
            </Row>
          </Form>
        </Card>
        <Modal title="提示" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
          <div style={{textAlign:'center'}}>是否按已选数据进行智能勾选？</div>
        </Modal>
    </Modal>
  );
});

export default CheckSreultMD;