import React, { FC, useState, useEffect, useRef, memo } from 'react';
import { Button, Popconfirm, Space } from 'antd';

import { Card, Table, LinkText, Modal } from '@comps';
import AddOrEditMd from './modal/add-edit';

import { Http, Message } from '@utils';
import { ColumnProps } from '@comps/types';

interface Props {
  ref?: any,
  baseFormId: number,
  setPageLoading: (isLoading: boolean) => void
};

const FundTable: FC<Props> = memo(({
  baseFormId,
  setPageLoading
}) => {
 
  const baseFormIdRef = useRef(baseFormId);

  const [modal, modalHolder] = Modal.useModal();

  const [deleteLoading, setDeleteLoading] = useState(false);

  let [table, setTable] = useState<Table>();
  const onTableRef = (tableRef: Table) => {
    setTable(tableRef);
    table = tableRef;
  };

  useEffect(() => {
    baseFormIdRef.current = baseFormId;
  }, [baseFormId]);

  const refreshTable = () => {
    table?.refresh();
  };

  const handleAddOrEdit = (
    isEdit: boolean, 
    editRowData: JSONObject
  ) => {

    modal.open({
      width: 1000,
      content: (_1, reject) => (
        <AddOrEditMd
          baseFormId={baseFormId}
          isEdit={isEdit}
          editRowData={editRowData}
          refreshTable={refreshTable}
          onClose={reject}
        />
      )
    })
  };
  
  const handleDelete = (
    isBatch: boolean, 
    ids: number[]
  ) => {
   
    if (isBatch) {
      const _ids: number[] = table?.getSelectedRowKeys() ?? [];
    
      if (_ids?.length === 0) {
        Message.warn('请至少选择一条数据');
        return;
      }

      setDeleteLoading(true);
      ids = _ids;
    } else {
      setPageLoading(true);
    }

    Http.del(`/request/${baseFormIdRef.current}/deleteFund`, { ids })
      .then(() => {
        table?.refresh();
        Message.success('删除成功');
      })
      .finally(() => {
        setDeleteLoading(false);
        setPageLoading(false);
      });
  }

  const columns: ColumnProps[] = [
    { key: '', title: '序号', width: 60, fixed: 'left', 
      render: (_1, _2, index) => (index + 1) 
    },
    { key: 'fundNo', title: '资金流水号', width: 200, fixed: 'left' },
    { key: 'proceedAmount', title: '收款金额', width: 160 },
    { key: 'proceedName', title: '收款账户名称', width: 240 },
    { key: 'proceedBank', title: '收款银行', width: 240 },
    { key: 'proceedBank', title: '收款账户', width: 240 },
    { key: 'paymentName', title: '付款账户名称', width: 240 },
    { key: 'paymentBank', title: '付款银行', width: 240 },
    { key: 'paymentAccount', title: '付款账户', width: 240 },
    { key: 'id', title: '操作', width: 130, align: 'center', fixed: 'right',
      render: (id: number, row: JSONObject) => (
        <Space size="large">
          <Popconfirm
            title="是否确认删除该行 ?"
            onConfirm={() => handleDelete(false, [id])}
          >
            <LinkText label="删除" />
          </Popconfirm>
          <LinkText 
            label="编辑" 
            onClick={() => handleAddOrEdit(true, row)}
          />
        </Space>
      )
    }
  ];

  return (
    <Card title="资金" actions={
      <Space>     
        <Button 
          type="primary" 
          loading={deleteLoading}
          onClick={() => handleDelete(true, [])}
        >
          删除
        </Button>
        <Button 
          type="primary" 
          onClick={() => handleAddOrEdit(false, {})}
        >
          录入资金
        </Button>
      </Space>
    }>
      {modalHolder}
      <Table
        onTableRef={onTableRef}
        url={`/request/${baseFormId}/queryFund`}
        columns={columns}
        selectedRowKeys={[]}
        pagination={false}
      />
    </Card>
  );
});

export default FundTable;
