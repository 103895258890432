import { Button, Card, Modal } from "@comps";
import { Http } from "@utils";
import { Checkbox, Col, Form, Input, Row, Tree, message } from "antd";
import * as React from "react";

export interface Props {
  onCancel: () => void;
  onConfirm: () => void;
  onValueChange?: (value: any) => void;
  value?: any;
}

const ChocieApproverModal: React.FC<Props> = ({
  onCancel,
  onConfirm,
  onValueChange,
  value,
}) => {
  const [form] = Form.useForm();
  const [modal, modalHolder] = Modal.useModal();
  const [loading, setLoading] = React.useState(false);
  let [menuTree, setMenuTree] = React.useState<any[]>();
  let [jobAndWorkers, setjobAndWorkers] = React.useState<any[]>([]);
  let [jobAndWorkerselect, setjobAndWorkerselect] = React.useState<any[]>([]);
  let [optionsWithDisabled, setoptionsWithDisabled] = React.useState<any[]>();
  let [theSelectedOptions, setTheSelectedOptions] = React.useState<any[]>();

  const layout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 5 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 17 },
    },
  };

  const onMenuClick = (event: any, node: any) => {
    const menuId = node.key;

    let optionsWithDisabled: any[] = [];
    Http.get(
      `/outputInvoiceApprovalProcess/employee?positionId=${menuId}`
    ).then((res) => {
      res.forEach((value: any) => {
        optionsWithDisabled.push({
          label: value.fullName,
          value: value.id,
        });
      });
      setoptionsWithDisabled(optionsWithDisabled);
      let item = {
        jobName: node.title,
        optionsWithDisabled: optionsWithDisabled,
        positionId: menuId,
      };
      setjobAndWorkers([item]);
    });
  };

  React.useEffect(() => {
    Http.get("/outputInvoiceApprovalProcess/dept").then((res) => {
      let dataArr: any[] = [];
      res.forEach((value: any, keys: any) => {
        let optiondataArr: any[] = [];
        value.hrPositions.forEach((data: any, key: any) => {
          optiondataArr.push({ title: data.positionName, key: data.id });
        });
        dataArr.push({
          title: value.deptName,
          key: value.id,
          children: optiondataArr,
        });
      });
      setMenuTree(dataArr);
    });
  }, []);

  const onFinish = (values: any) => {
    setLoading(true);
    if (theSelectedOptions?.length === 0) {
      message.warning("请选择审批人");
      setLoading(false);
    } else {
      if (onValueChange) onValueChange([jobAndWorkerselect, value]);
      setLoading(false);
      onCancel();
    }
  };

  const handleCheckboxChange = (e: any) => {
    setTheSelectedOptions(e);

    let options: any[] = [];

    options = jobAndWorkers[0].optionsWithDisabled.filter((index: any) => {
      // if(e.includes(index.value))
      return e.includes(index.value);
    });
    let item = {
      jobName: jobAndWorkers[0].jobName,
      optionsWithDisabled: options,
      employeeId: e.join(","),
      positionId: jobAndWorkers[0].positionId,
    };
    setjobAndWorkerselect([item]);
  };

  return (
    <Card title="选择审批人">
      {modalHolder}
      <Form
        form={form}
        // style={{ padding: "16px" }}
        {...layout}
        onFinish={onFinish}
      >
        <Form.Item
          name="applyType"
          label="审批人"
          rules={[{ message: "请选择申请类型" }]}
        >
          <div style={{ display: "flex" }}>
            <Input placeholder="输入内容" />
            <Button style={{ marginLeft: "16px" }} type="primary">
              搜索
            </Button>
          </div>
        </Form.Item>

        <Row style={{ width: "100%", marginBlock: "16px" }}>
          <Col offset={4} span={8}>
            <Tree
              style={{ backgroundColor: "#FBFCFC" }}
              treeData={menuTree}
              onClick={onMenuClick}
            />
          </Col>
          <Col span={8}>
            <Checkbox.Group
              onChange={handleCheckboxChange}
              options={optionsWithDisabled}
              style={{
                display: "flex",
                flexDirection: "column",
                backgroundColor: "#f5f5f5",
                padding: "8px",
              }}
            />
          </Col>
        </Row>

        <div style={{ textAlign: "center" }}>
          <Button type="primary" htmlType="submit" loading={loading}>
            确认
          </Button>
          <Button
            onClick={() => {
              onCancel();
            }}
            style={{ marginLeft: "16px" }}
          >
            取消
          </Button>
        </div>
      </Form>
    </Card>
  );
};

export default ChocieApproverModal;
