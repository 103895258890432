import * as React from "react";
import { Form, Row, Col, DatePicker, message, Select, Input } from "antd";
import { Card, Button, Lookup } from "@comps";
import { Http, Message, DateUtils } from "@utils";
import moment from "moment";

export interface Props {
  onCancel: () => void;
  onConfirm: () => void;
}

const SyncModal: React.FC<Props> = ({ onCancel, onConfirm }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = React.useState(false);
  const layout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 5 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 17 },
    },
  };
  const onFinish = (values: any) => {
    setLoading(true);
    console.log("Received values of form: ", values);
    values.dateFrom = moment(values.dateFrom).format("YYYYMMDD");
    values.dateTo = moment(values.dateTo).format("YYYYMMDD");
    Http.post("/tax/output-red-request/sync", values)
      .then((res) => {
        console.log(res);
        setLoading(false);
        onConfirm();
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };
  return (
    <Card title="申请单同步">
      <Form
        form={form}
        // style={{ padding: "16px" }}
        {...layout}
        onFinish={onFinish}
      >
        <Form.Item
          name="applyType"
          label="申请类型"
          rules={[{ required: true, message: "请选择申请类型" }]}
        >
          {/* <Lookup lookupType="RED_REQUEST_STATUS" placeholder="请选择" /> */}
          <Select
            placeholder="请选择"
            //   onChange={onIdentityChange}
            options={[
              {
                value: "1",
                label: "销售方",
              },
              {
                value: "0",
                label: "购买方",
              },
            ]}
          />
        </Form.Item>
        <Form.Item name="buyerTaxNo" label="购方税号">
          <Input placeholder="请输入" />
        </Form.Item>
        <Form.Item
          name="dateFrom"
          label="起始日期"
          rules={[{ required: true, message: "请选择起始日期" }]}
        >
          <DatePicker format="YYYYMMDD" />
        </Form.Item>
        <Form.Item
          name="dateTo"
          label="终止日期"
          rules={[{ required: true, message: "请选择终止日期" }]}
        >
          <DatePicker format="YYYYMMDD" />
        </Form.Item>
        <Form.Item name="infoNo" label="信息表编号">
          <Input placeholder="请输入" />
        </Form.Item>
        <Form.Item name="sellerTaxNo" label="销方税号">
          <Input placeholder="请输入" />
        </Form.Item>
        <div style={{ textAlign: "center" }}>
          <Button type="primary" htmlType="submit" loading={loading}>
            确认
          </Button>
          <Button
            onClick={() => {
              onCancel();
            }}
            style={{ marginLeft: "16px" }}
          >
            取消
          </Button>
        </div>
      </Form>
    </Card>
  );
};

export default SyncModal;
