import React, { FC, useState, useEffect } from 'react';
import { Form, InputNumber, Space, Button, message } from 'antd';

import { Card, DisplayInput, Modal } from '@comps';
import MergeOrSplitParentTable from '../component/mergeOrSplitParentTable';
import { OperationType, SplitType } from '../interface';

import { PlusCircleFilled, MinusCircleFilled } from '@ant-design/icons';

import { Math } from '@utils';
import { split } from 'lodash';
import { Http } from '@utils';

const { Item } = Form;

interface SplitSeparateProps {
  splitType: number,
  checkIsCanSplit: (
    splitType: number, 
    closeSplitAmountMD: () => void,
    splitAmounts: number, 
  ) => void,
  onClose: () => void,
  totalAmount: number,
  invId:number,
  refreshTable: () => void,
  handleClose: () => void,
};

let splitAmountArr:Array<any> = [];

const SplitSeparate: FC<SplitSeparateProps> = ({
  splitType,
  checkIsCanSplit,
  onClose,
  totalAmount,
  invId,
  refreshTable,
  handleClose
}) => {

  const [len, setLen] = useState(1);
  let [residue, setResidue] = useState(totalAmount);

  const isSplitAll = splitType === SplitType.SEPARATE;
  const [form] = Form.useForm();
  const [modal, modalHolder] = Modal.useModal();

  const handleAdd = () => {
    setLen( len + 1 )
  }

  const compReducer = (e:any, i:any) => {
    splitAmountArr.splice(i,1,e.target.value)
    let arrNum = splitAmountArr.reduce(function(total,current){
      return total + current * 1
    },0)
    if (arrNum <= totalAmount) {
      let residue = totalAmount - arrNum;
      setResidue(residue);
    } else if (arrNum > totalAmount) {
      message.info('该行输入的金额已大于剩余金额，请重新输入！')
      return;
    }
  }

  const list = (len: number) => {
    let arr = [];
    for (let i = 0; i < len; i++) {
      arr.push(<div key={i}>
        <Item
          label="拆分金额"
          name="totalAmount"
          rules={[{ message: '请输入拆分金额' }]}
        >
          <InputNumber 
            style={{ width: '60%', alignItems:'center', marginRight:'10px' }} 
            placeholder="请输入拆分金额" 
            onBlur={ e => compReducer(e, i) }
          />
          <PlusCircleFilled style={{ fontSize: '24px', marginRight:'10px', display: i+1 == len ? '' : 'none' }} onClick={ handleAdd } />
          <MinusCircleFilled style={{ fontSize: '24px', display: i === 0 ? 'none' : (i+1 == len ? '' : 'none') }} onClick={ e => handleReducer(e, i) } />
        </Item>
      </div>)
    }
    return arr
  }

  const handleReducer = (e:any, i:any) => {
    setLen( len - 1 )
    splitAmountArr.splice(i,1)
    let arr = splitAmountArr.reduce(function(total,current){
      return total + current * 1
    },0)
    let residue = totalAmount - arr;
    setResidue(residue);
  }

  const handleClick = () => {
    let arr = splitAmountArr.reduce(function(total,current){
      return total + current * 1
    },0)
    if(arr > totalAmount) {
      message.info('该行输入的金额已大于剩余金额，请重新输入！')
      return;
    }
    for(let i = 0; i < splitAmountArr.length; i++) {
      if(splitAmountArr[i] === '0') {
        message.info("存在拆分金额为0或为空的行，无法进行拆分预览！")
        return;
      }
    }
    
    Http.post('/tax/orders/split',{
      invId,
      splitType,
      splitAmounts:[...splitAmountArr,residue.toFixed(2)],
    }).then((data: JSONObject) => {
        modal.open({
          width: 1400,
          content(_1, reject) {
            return (
              <MergeOrSplitParentTable
                operationType={OperationType.SPLIT}
                dataSource={data.invList}
                mergeId={0}
                splitId={data.confirmId} 
                onClose={reject}
                refreshTable={refreshTable}
                closeMergeTypeMD={() => {}}
                closeSplitTypeMD={handleClose}
                closeSplitAmountMD={() => {}}
              />
            )
          }
        });
      })
  };

  const handleCancel = () => {
    onClose();
    splitAmountArr = [];
  }

  return(
    <Card title={'自定义金额、份数拆分'}>
      {modalHolder}
      <Form
        form={form}
        onFinish={() => handleClick()}
        style={{ marginTop: 20, textAlign: 'center' }}
        labelCol={{ span: 6 }}
      >
        <div>
            <Item
              label="总金额"
            >
              {Math.num2money(totalAmount)}
            </Item>
          {
            list(len)
          }
          <p style={{ color: '#f00' }}>剩余未拆分：{ Math.num2money(residue) }</p>
          <p style={{ color: '#f00' }}>注意：若剩余未拆分有余额，将会一并开出！</p>
        </div>
        <div style={{ marginTop: 12, paddingLeft: 20 }}>
          <Space size="large">
            <Button type="primary" htmlType="submit">拆分预览</Button>
            <Button onClick={handleCancel}>取消</Button>
          </Space>
        </div>
      </Form>
    </Card>
  );
};

export default SplitSeparate;
