import React, { FC, useEffect, useState } from 'react';
import { Tree, Row, Col, Form, Input, Space, Button, Upload } from 'antd';

import { Page, Card, Table, Modal, Download, PermissionButton } from '@comps';
import AddOrEditMD from './addOrEdit.md';
import DeleteMD from './delete.md';

import { Http, Message, Context, Env, FormUtil } from '@utils';
import { ColumnProps } from '@comps/types';

const { Item } = Form;

const MaterialCode: FC = () => {
  const [treeData, setTreeData] = useState([]);
  const [selectedParentId, setSelectedParentId] = useState(0);
  const [currentTreeLevel, setCurrentTreeLevel] = useState(0);

  const [modal, modalHolder] = Modal.useModal();

  const [uploadLoading, setUploadLoading] = useState(false);

  let [table, setTable] = useState<Table>();
  const onTableRef = (tableRef: Table) => {
    setTable(tableRef);
    table = tableRef;
  };

  useEffect(() => {
    refreshTree();
  }, []);

  const refreshTree = () => {
    Http.get('/tax/goodsCategoryMapping/categories/tree')
      .then((data: any) => {
        setTreeData(data.children);
      });
  };

  const handleOnSelect = (selectedKeys: any[], info: any) => {
    const selected: boolean = info.selected;
    const { key, level } = info.node;
    setCurrentTreeLevel(level);
    
    selected
      ? setSelectedParentId(key)
      : setSelectedParentId(-1);
    
    setTimeout(() => { table?.refresh() }, 200);
  };

  const handleAddOrEdit = async (rowObj: JSONObject, isEdit: boolean) => {
    if (!isEdit) {
      if (currentTreeLevel === 3) {
        Message.warn('当前层级不可新增');
        return;
      } 
    }

    await modal.open({
      width: 1000,
      content(resolve, reject) {
        return (
          <AddOrEditMD 
            isEdit={isEdit} 
            formData={rowObj}
            level={currentTreeLevel}
            parentId={selectedParentId}
            refreshTree={refreshTree}
            onResolve={resolve}
            onClose={reject} 
          />
        )
      }
    });

    table?.refresh();
  };

  const handleDelete = () => {
    const IDs: number[] = table?.getSelectedRowKeys() || [];

    if (IDs?.length === 0) {
      Message.warn('请至少勾选一条数据');
      return;
    };

    const refreshTable = () => {
      table?.refresh();
    };

    modal.open({
      width: '300px',
      content(_1, reject) {
        return (
          <DeleteMD 
            IDs={IDs} 
            refreshTable={refreshTable} 
            refreshTree={refreshTree} 
            onClose={reject} 
          />
        )
      }
    });
  };

  const exportReport = () => {
    const key = table?.getQueryPanelParams() ?? {};
    const parentId = table?.getSelectedRowKeys();

    FormUtil.exportExcelFile(
      '/tax/goodsCategoryMapping/export',
      {
        ...key,
        parentId,
      }
    );
  };

  const columns: ColumnProps[] = [
    { key: 'goodsName', title: '开票商品名称', width: 180, fixed: 'left' },
    { key: 'combCategoryName', title: '组合分类', width: 180 }, 
    { key: 'productDesc', title: '产品描述', width: 200 },
    { key: 'taxCode', title: '税收分类编码', width: 220 },
    { key: 'taxShortName', title: '税收分类编码简称', width: 200 },
    { key: 'invoiceGoodsName', title: '开票合并名称', width: 200 },
    { key: 'taxRate', title: '税率（%）', width: 130, tag: {} },
    {
     key: 'id', title: '操作', width: 130, fixed:'right', align: 'center',
     render (id, row) {
       return (
         <Space>
           <Button type="link" onClick={() => {handleAddOrEdit(row, true)}}>修改</Button>
         </Space>
       )
      }
    }
  ];

  return (
    <Page>
      {modalHolder}
      <Card title="物料分类编码" actions={ 
        <Space className="card-space">
          <Download 
            label="下载模板"
            type="button"
            url="/tax/goodsCategoryMapping/template/download" 
          />
          {/* <Download 
            label="导出"
            type="button"
            url="/tax/goodsCategoryMapping/export" 
          /> */}
           <Space>
            <PermissionButton
              permissions="TaxGoodsCategoryMapping.export"
              type="primary"
              onClick={exportReport}
            >
              导出
            </PermissionButton>
          </Space>
          <Upload 
            accept=".xlsx,.xls"
            action={`${Env.getBaseUrl()}/tax/goodsCategoryMapping/importFromExcel`}
            showUploadList={false}
            headers={{
              Authorization: Context.getToken()
            }}
            onChange={async ({ file: { status, response = {} } }) => {
              setUploadLoading(true);

              if (!['done', 'error'].includes(status as string)) {
                return;
              }

              const { data = {}, code, msg } = response;
            
              if (String(code) !== '200') {
                Message.error(msg || '未知错误, 请联系管理员');
                setUploadLoading(false);
                return;
              }

              const { 
                errorFileUrl, 
                errorSize, 
                hasError,
                successSize, 
                total 
              } = data;
              
              setUploadLoading(false);

              // 导入成功
              if (!hasError) {
                await modal.open({
                  width: 400,
                  content(_1, reject) {
                    return (
                      <Card title="导入成功" style={{ marginBottom: '0' }}>
                        <Space direction="vertical" style={{ width: '100%' }}>
                          <Row justify="center">
                            {`成功导入 ${successSize} 条`}
                          </Row>
                          <Row justify="center">
                            <Button onClick={() => reject()}>确定</Button>
                          </Row>
                        </Space>
                      </Card>
                    );
                  },
                });
                return;
              }

              // 导入失败
              await modal.open({
                width: 400,
                content(_1, reject) {
                  return (
                    <Card title="导入失败" style={{ marginBottom: '0' }}>
                      <Space direction="vertical" style={{ width: '100%' }}>
                        <Row justify="center">
                          {`总共 ${total} 条，导入失败 ${errorSize} 条。`}
                        </Row>
                        <Row justify="center">
                          <Download url={errorFileUrl} label='点击下载失败的导入记录'/>
                        </Row>
                        <Row justify="center">
                          <Button onClick={() => reject()}>关闭</Button>
                        </Row>
                      </Space>
                    </Card>
                  );
                }
              });
            }}
          >
            <PermissionButton permissions='TaxGoodsCategoryMapping.importExcel' type="primary" loading={uploadLoading}>Excel 导入</PermissionButton>
          </Upload>
          <PermissionButton permissions='TaxGoodsCategoryMapping.create' type="primary" onClick={() => {handleAddOrEdit({}, false)}}>新增</PermissionButton>
          <PermissionButton permissions='TaxGoodsCategoryMapping.delTaxGoodsMapping' onClick={handleDelete}>删除</PermissionButton>
        </Space>
      }>
        <Row>
          <Col span={6}>
            <Tree
              height={window.innerHeight - 200}
              treeData={treeData}
              onSelect={handleOnSelect}
            />
          </Col>
          <Col span={18}>
            <Table 
              url={`/tax/goodsCategoryMapping?parentId=${selectedParentId}`} 
              onTableRef={onTableRef}
              columns={columns}
              selectedRowKeys={[]}
              scroll={{ y: window.innerHeight - 300 }}
            >
              <Item name="key">
                <Input placeholder="商品名称" />
              </Item>
            </Table>
          </Col>
        </Row>
      </Card>
    </Page>
  );
};

export default MaterialCode;