import React, { useEffect, useState, FC } from "react";
import {
  Tree,
  Space,
  Row,
  Col,
  Form,
  Input,
  Table,
  Checkbox,
  Select,
} from "antd";

import { Page, Card, DisplayInput, Modal, LinkText, Lookup } from "@comps";

import { Http, Message, Layout, Api } from "@utils";

const { Item } = Form;

const Dept: FC = () => {
  const [deptTree, setDeptTree] = useState<[]>([]);
  const [selectedDeptId, setSelectedDeptId] = useState(-1);
  const [parentDeptId, setParentDeptId] = useState<any>();

  const [positionDs, setPositionDs] = useState<JSONObject[]>();
  const [empDs, setEmpDs] = useState<JSONObject[]>();

  const [basicInfoForm] = Form.useForm();
  const [positionForm] = Form.useForm();
  const [deptForm] = Form.useForm();
  const [empForm] = Form.useForm();
  const [dispatchForm] = Form.useForm();

  const [userOptions, setUserOptions] = useState<any[]>();
  const [empOptions, setEmpOptions] = useState<any[]>();
  const [positionOptions, setPositionOptions] = useState<any[]>();
  const [orgOptions, setOrgOptions] = useState<any[]>();

  const [positionModalVisible, setPositionModalVisible] = useState(false);
  const [deptModalVisible, setDeptModalVisible] = useState(false);
  const [empModalVisible, setEmpModalVisible] = useState(false);
  const [dispatchModalVisible, setDispatchModalVisible] = useState(false);
  const [selectedKeys, setSelectedKeys] = useState<React.Key[]>([]);

  useEffect(() => {
    renderDeptTree();
    getOrgOptions();
  }, []);

  const getOrgOptions = () => {
    Api.getKv("/org/orgs/kv").then((data) => {
      setOrgOptions(data);
    });
  };

  /**
   * 渲染部门树
   */
  const renderDeptTree = () => {
    Http.get("/hr/depts/deptTree/antd").then((data: any) => {
      setDeptTree(data);
    });
  };
  /**获取最近的父节点的信息 */
  const findParentNode = (data: JSONObject[], checkedKeys: number[]) => {
    function traverse(node: JSONObject, checkedKeys: number[], parent: any) {
      if (checkedKeys.includes(node.key)) {
        return parent;
      }
      if (node.children && node.children.length > 0) {
        for (const child of node.children) {
          const closestParent: any = traverse(child, checkedKeys, node);
          if (closestParent) {
            return closestParent;
          }
        }
      }
      return null;
    }
    for (const node of data) {
      const closestParent = traverse(node, checkedKeys, null);
      if (closestParent) {
        return closestParent;
      }
    }
    return null;
  };
  /**
   * 选中/取消选中树上的部门
   * @param selectedKeys
   * @param info
   */
  const onDeptSelected = (selectedKeys: any[], info: any) => {
    setSelectedKeys(selectedKeys);
    const selected: boolean = info.selected;
    const node = info.node;
    const key = node.key;
    const parentNode: any = findParentNode(deptTree, selectedKeys);
    setParentDeptId(parentNode?.key);
    if (selected) {
      setSelectedDeptId(key);

      Http.get(`/hr/depts/${key}`).then((data) => {
        basicInfoForm.setFieldsValue({ ...data });

        renderPositions(key);
        renderDeptEmployees(key);
      });
    }
  };

  const renderDeptInfo = (key: number) => {
    setSelectedDeptId(key);
    Http.get(`/hr/depts/${key}`).then((data) => {
      basicInfoForm.setFieldsValue({ ...data });
      renderPositions(key);
      renderDeptEmployees(key);
    });
  };

  /**
   * 渲染部门下的岗位
   * @param deptId 部门ID
   */
  const renderPositions = (deptId: number) => {
    Http.get(`/hr/positions/deptPositions?deptId=${deptId}`).then((data) => {
      setPositionDs(data);
    });
  };

  /**
   * 渲染部门下的员工
   * @param deptId 部门ID
   */
  const renderDeptEmployees = (deptId: number) => {
    Http.get(`/hr/depts/${deptId}/employees`).then((data) => {
      setEmpDs(data);
    });
  };

  /**
   * 渲染岗位下的员工
   * @param positionId 岗位ID
   */
  const renderPositionEmployees = (positionId: number) => {
    Http.get(`/hr/positions/${positionId}/employees`).then((data) => {
      setEmpDs(data);
    });
  };

  /**
   * 删除岗位
   * @param id
   */
  const deletePosition = (id: number) => {
    Http.del(`/hr/positions/${id}`).then(() => {
      renderPositions(selectedDeptId);
      Message.ok();
    });
  };

  /**
   * 在弹出框中展示岗位信息
   * @param id 岗位ID
   */
  const showPositionDetail = (id: number) => {
    setPositionModalVisible(true);
    Http.get(`/hr/positions/${id}`).then((data) => {
      console.log(
        'data.openInvoiceType.split(",") :>> ',
        data?.openInvoiceType.split(",")
      );
      positionForm.setFieldsValue({
        ...data,
        openInvoiceType: data.openInvoiceType?.split(","),
      });
    });
  };

  /**
   * 展示分派员工窗口
   * @param positionId
   */
  const showDispathModal = (positionId: number, positionName: string) => {
    setDispatchModalVisible(true);

    dispatchForm.setFieldsValue({ positionId, positionName });

    Api.getKv("/hr/employees/kv").then((data) => {
      setEmpOptions(data);
    });
  };

  /**
   * 在弹出框中展示部门信息
   * @param id
   */
  const showDeptDetail = (id: number) => {
    setDeptModalVisible(true);

    Http.get(`/hr/depts/${id}`).then((data) => {
      deptForm.setFieldsValue({ ...data });
    });
  };

  /**
   * 删除部门
   * @param id
   */
  const deleteDept = (id: number) => {
    Http.del(`/hr/depts/${id}`).then(() => {
      renderDeptTree();
      setSelectedKeys([parentDeptId ?? ""]);
      renderDeptInfo(parentDeptId ?? "");
      Message.ok();
    });
  };

  /**
   * 创建或更新岗位
   */
  const createOrUpdatePosition = () => {
    const id = positionForm.getFieldValue("id");

    positionForm.validateFields().then((values) => {
      if (Array.isArray(values.openInvoiceType)) {
        values.openInvoiceType = values.openInvoiceType?.join(",");
      }
      if (id < 0) {
        Http.post("/hr/positions", { ...values, deptId: selectedDeptId }).then(
          () => {
            setPositionModalVisible(false);
            positionForm.resetFields();
            renderPositions(selectedDeptId);
            Message.saveOk();
          }
        );
      } else {
        Http.put("/hr/positions", { ...values, deptId: selectedDeptId }).then(
          () => {
            setPositionModalVisible(false);
            positionForm.resetFields();
            renderPositions(selectedDeptId);
            Message.saveOk();
          }
        );
      }
    });
  };

  /**
   * 创建或更新部门
   */
  const createOrUpdateDept = () => {
    const id = deptForm.getFieldValue("id");

    deptForm.validateFields().then((values) => {
      if (id < 0) {
        Http.post("/hr/depts", {
          ...values,
          parentDeptId: selectedDeptId,
        }).then(async (res) => {
          setDeptModalVisible(false);
          deptForm.resetFields();
          renderDeptTree();
          setSelectedKeys([res.id ?? ""]);
          renderDeptInfo(res.id ?? "");
          Message.saveOk();
        });
      } else {
        Http.put("/hr/depts", { ...values }).then((res: JSONObject) => {
          setDeptModalVisible(false);
          deptForm.resetFields();
          renderDeptTree();
          renderDeptInfo(res.id ?? "");
          Message.saveOk();
        });
      }
    });
  };

  /**
   * 点击新增员工
   */
  const onCreateEmpClick = () => {
    Api.getKv("/sys/users/kv").then((data) => {
      setUserOptions(data);
    });

    Api.getKv(`/hr/positions/kv?deptId=${selectedDeptId}`).then((data) => {
      setPositionOptions(data);
    });
  };

  /**
   * 创建新员工
   */
  const createEmp = () => {
    empForm.validateFields().then((values) => {
      Http.post("/hr/employees", values).then(() => {
        empForm.resetFields();
        setEmpModalVisible(false);
        renderDeptEmployees(selectedDeptId);
        Message.saveOk();
      });
    });
  };

  /**
   * 给员工分派岗位
   */
  const dispatchEmpPosition = () => {
    dispatchForm.validateFields().then((values) => {
      Http.post("/hr/employees/positions", values).then(() => {
        Message.ok();
        dispatchForm.resetFields();
        setDispatchModalVisible(false);
        renderDeptEmployees(selectedDeptId);
      });
    });
  };

  /**
   * 解除员工岗位
   */
  const revokeEmpPosition = (empId: number, positionId: number) => {
    Http.del(
      `/hr/employees/positions?employeeId=${empId}&positionId=${positionId}`
    ).then(() => {
      Message.ok();
      renderDeptEmployees(selectedDeptId);
    });
  };

  const postColumns: JSONObject[] = [
    { title: "序号", dataIndex: "seq" },
    { title: "岗位编码", dataIndex: "positionCode" },
    { title: "岗位名称", dataIndex: "positionName" },
    {
      title: "主管岗位",
      dataIndex: "managerFlag",
      render: (value: boolean) => (value ? "是" : "否"),
    },
    {
      title: "开票类型",
      dataIndex: "openInvoiceType",
      render: (value: any) => {
        let TheInvoiceType: any = {
          "0": "全部",
          "32": "数电票(增值税普通发票)",
          "31": "数电票(增值税专用发票)",
        };
        return TheInvoiceType[value];
      },
    },
    {
      title: "操作",
      dataIndex: "id",
      width: 250,
      render: (positionId: number, row: JSONObject) => (
        <Space>
          <LinkText
            label="修改"
            onClick={() => showPositionDetail(positionId)}
          />
          <LinkText
            label="查看员工"
            onClick={() => renderPositionEmployees(positionId)}
          />
          <LinkText
            label="分配员工"
            onClick={() => showDispathModal(positionId, row.positionName)}
          />
          <LinkText label="删除" onClick={() => deletePosition(positionId)} />
        </Space>
      ),
    },
  ];

  const staffColumns: JSONObject[] = [
    { title: "员工编号", dataIndex: "employeeNo" },
    { title: "员工名称", dataIndex: "fullName" },
    { title: "岗位名称", dataIndex: "positionName" },
    {
      title: "在岗状态",
      dataIndex: "partTimeFlag",
      render: (partTimeFlag: boolean) => (partTimeFlag ? "兼职岗位" : "主岗位"),
    },
    {
      title: "操作",
      dataIndex: "id",
      render: (epmId: number, row: JSONObject) =>
        row.partTimeFlag ? (
          <LinkText
            label="解除岗位"
            onClick={() => revokeEmpPosition(epmId, row.positionId)}
          />
        ) : (
          <span></span>
        ),
    },
  ];

  return (
    <Page>
      <Card title="组织架构">
        <Row>
          <Col span={7}>
            <Tree
              treeData={deptTree}
              onSelect={onDeptSelected}
              selectedKeys={selectedKeys}
            />
          </Col>
          <Col span={17}>
            <Card
              title="基本信息"
              actions={
                selectedDeptId > 0 && (
                  <Space>
                    <LinkText
                      label="创建子部门"
                      onClick={() => setDeptModalVisible(true)}
                    />
                    <LinkText
                      label="创建员工"
                      onClick={() => {
                        setEmpModalVisible(true);
                        onCreateEmpClick();
                      }}
                    />
                    <LinkText
                      label="修改"
                      onClick={() => showDeptDetail(selectedDeptId)}
                    />
                    <LinkText
                      label="删除"
                      onClick={() => deleteDept(selectedDeptId)}
                    />
                  </Space>
                )
              }
            >
              <Form form={basicInfoForm}>
                <Row>
                  <Col span={10}>
                    <Item name="deptName" label="部门名称">
                      <DisplayInput />
                    </Item>
                    <Item name="orgName" label="关联公司">
                      <DisplayInput />
                    </Item>
                  </Col>
                  <Col span={8}>
                    <Item name="parentDeptName" label="上级部门">
                      <DisplayInput />
                    </Item>
                    <Item name="seq" label="序号">
                      <DisplayInput />
                    </Item>
                  </Col>
                  <Col span={6}>
                    <Item name="deptCode" label="部门编码">
                      <DisplayInput />
                    </Item>
                    <Item name="agentCode" label="税盘编码">
                      <DisplayInput />
                    </Item>
                  </Col>
                </Row>
              </Form>
            </Card>

            <Card
              title="岗位信息"
              actions={
                selectedDeptId > 0 && (
                  <Space>
                    <LinkText
                      label="新增岗位"
                      onClick={() => setPositionModalVisible(true)}
                    />
                  </Space>
                )
              }
            >
              <Table
                rowKey="id"
                dataSource={positionDs}
                columns={postColumns}
                pagination={false}
              />
            </Card>

            <Card title="员工信息">
              <Table
                dataSource={empDs}
                columns={staffColumns}
                pagination={false}
              />
            </Card>
          </Col>
        </Row>
      </Card>

      <Modal
        title="新增/更新岗位"
        visible={positionModalVisible}
        onCancel={() => {
          setPositionModalVisible(false);
          positionForm.resetFields();
        }}
        onOk={createOrUpdatePosition}
      >
        <Form form={positionForm} {...Layout._1c_1_2}>
          <Item name="id" style={{ display: "none" }} initialValue={-1}>
            <Input />
          </Item>
          <Item label="序号" name="seq" required>
            <Input
              type="number"
              min={1}
              placeholder="推荐以10为单位递增,如:10,20..."
            />
          </Item>
          <Item label="岗位名称" name="positionName" required>
            <Input />
          </Item>
          <Item label="岗位编码" name="positionCode">
            <Input placeholder="请使用OA系统编码或留空" />
          </Item>
          <Item label="开票类型" name="openInvoiceType" required>
            <Lookup
              lookupType="OPEN_INVOICE_TYPE"
              placeholder="开票类型"
              mode="multiple"
            />
          </Item>
          <Item
            label="主管岗位"
            name="managerFlag"
            required
            valuePropName="checked"
          >
            <Checkbox />
          </Item>
        </Form>
      </Modal>

      <Modal
        title="新增子部门"
        visible={deptModalVisible}
        onCancel={() => {
          setDeptModalVisible(false);
          deptForm.resetFields();
        }}
        onOk={createOrUpdateDept}
      >
        <Form form={deptForm} {...Layout._1c_1_2}>
          <Item name="id" style={{ display: "none" }} initialValue={-1}>
            <Input />
          </Item>
          <Item label="序号" name="seq" required>
            <Input
              type="number"
              min={1}
              placeholder="推荐以 10 为单位递增,如:10,20..."
            />
          </Item>
          <Item label="部门名称" name="deptName" required>
            <Input />
          </Item>
          <Item label="部门编码" name="deptCode">
            <Input placeholder="请使用OA系统编码或留空" />
          </Item>
          <Item label="关联公司" name="orgId">
            <Select showSearch optionFilterProp="label" options={orgOptions} />
          </Item>
          <Item label="税盘编码" name="agentCode">
            <Input placeholder="请输入税盘编码或留空" />
          </Item>
        </Form>
      </Modal>

      <Modal
        title="新增员工"
        visible={empModalVisible}
        onCancel={() => {
          setEmpModalVisible(false);
          empForm.resetFields();
        }}
        onOk={createEmp}
      >
        <Form form={empForm} {...Layout._1c_1_2}>
          <Item label="员工工号" name="employeeNo">
            <Input placeholder="请使用OA系统编码或留空" />
          </Item>
          <Item label="关联系统用户" name="userId" required>
            <Select showSearch optionFilterProp="label" options={userOptions} />
          </Item>
          <Item label="主岗位" name="mainPositionId" required>
            <Select
              showSearch
              optionFilterProp="label"
              options={positionOptions}
            />
          </Item>
        </Form>
      </Modal>

      <Modal
        title="分配员工"
        visible={dispatchModalVisible}
        onCancel={() => {
          setDispatchModalVisible(false);
          dispatchForm.resetFields();
        }}
        onOk={dispatchEmpPosition}
      >
        <Form form={dispatchForm} style={{ marginLeft: 50 }}>
          <Item name="positionId" style={{ display: "none" }} initialValue={-1}>
            <Input />
          </Item>
          <Item label="岗位名称" name="positionName">
            <DisplayInput />
          </Item>
          <Item label="员工" name="employeeId" required>
            <Select showSearch optionFilterProp="label" options={empOptions} />
          </Item>
          <Item label="主岗位" name="mainPositionFlag" valuePropName="checked">
            <Checkbox />
          </Item>
          <Item>
            <p style={{ fontSize: 13 }}>
              <span style={{ color: "#f00" }}>提示：</span>
              <span>分配岗位后，重新登录后生效。</span>
            </p>
          </Item>
        </Form>
      </Modal>
    </Page>
  );
};

export { Dept };
