import { Card, LinkText, Modal, Page, PermissionButton, Table } from "@comps";
import { ColumnProps } from "@comps/types";
import { Http } from "@utils";
import { Space, Tag, message } from "antd";
import React, { FC, useState } from "react";
import AddOrEdit from "./modal/addOrEdit";

const Store: FC = () => {
  const [modal, modalHolder] = Modal.useModal();

  let [table, setTable] = useState<Table>();
  const [qrcodeLoading, setQrcodeLoading] = useState(false);

  const onTableRef = (tableRef: Table) => {
    table = tableRef;
    setTable(tableRef);
  };
  const base64ToBlob = (base64String: string) => {
    const byteCharacters = atob(base64String);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
      const slice = byteCharacters.slice(offset, offset + 512);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, { type: "image/jpeg" });
  };

  const handleDownload = (base64: string) => {
    const blob = base64ToBlob(base64);
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "qrcode.jpg";
    a.click();
    window.URL.revokeObjectURL(url);
  };

  const handleQrcodeUrl = async (record: JSONObject) => {
    const { id = "" } = record;
    const base64 = await Http.post(`/org/depts/qrCode`, { id });
    await modal.open({
      width: "350px",
      content(resolve, reject) {
        return (
          <Card
            loading={qrcodeLoading}
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "20px",
              height: "350px",
              position: "relative",
            }}
          >
            <>
              <LinkText
                label="下载"
                style={{ position: "absolute", top: "25px" }}
                onClick={() => handleDownload(base64.qrCode)}
              />
              <img
                src={"data:image/jpg;base64," + base64.qrCode}
                style={{ height: "100%", width: "300px" }}
              />
            </>
          </Card>
        );
      },
    });
  };

  const columns: ColumnProps[] = [
    { key: "orgName", title: "公司名称", width: 140, align: "center" },
    {
      key: "deptName",
      title: "门店名称",
      width: 140,
      align: "center",
    },
    {
      key: "deptCode",
      title: "门店编号",
      width: 140,
      align: "center",
    },
    {
      key: "deptUrl",
      title: "门店URL",
      width: 140,
      align: "center",
    },
    {
      key: "enabled",
      title: "状态",
      width: 140,
      align: "center",
      render: (text, record) => {
        if (text === true) {
          return <Tag color="green">启用</Tag>;
        } else if (text === false) {
          return <Tag color="red">禁用</Tag>;
        }
      },
    },
    {
      key: "option",
      title: "操作",
      width: 140,
      align: "center",
      render: (text: any, record: JSONObject) => {
        return (
          <Space>
            <LinkText
              label="编辑"
              onClick={() => {
                handleAddOrEdit("edit", record);
              }}
            />
            <LinkText
              label="生成二维码"
              onClick={() => {
                handleQrcodeUrl(record);
              }}
            />
          </Space>
        );
      },
    },
  ];

  const updateStatus = (status: boolean) => {
    const selectedRow = table?.getSelectedRowAttr();
    if (!selectedRow || selectedRow.length === 0) {
      message.warning("请选择一条数据");
      return;
    }
    Http.put(`/org/depts/status`, { ids: selectedRow, enabled: status })
      .then((data) => {
        message.success("操作成功");
        table?.refresh();
      })
      .finally(() => {});
  };
  const handleAddOrEdit = (type: "add" | "edit", record: JSONObject) => {
    modal.open({
      width: "700px",
      content(_1, reject) {
        return (
          <AddOrEdit
            type={type}
            data={record}
            onClose={reject}
            refresh={table?.refresh}
          />
        );
      },
    });
  };
  return (
    <Page>
      {modalHolder}
      <Card
        title="门店管理"
        actions={
          <Space>
            <PermissionButton
              permissions="Dept.updateStatus"
              type="primary"
              onClick={() => updateStatus(true)}
            >
              启用
            </PermissionButton>
            <PermissionButton
              permissions="Dept.updateStatus"
              type="primary"
              onClick={() => updateStatus(false)}
            >
              禁用
            </PermissionButton>
          </Space>
        }
      >
        <Table
          onTableRef={onTableRef}
          url="/org/depts"
          columns={columns}
          isExpandAllItem={true}
          pagination={{ hideOnSinglePage: false }}
          selectedRowKeys={[]}
          scroll={{ y: window.innerHeight - 300 }}
        />
      </Card>
    </Page>
  );
};

export default Store;
