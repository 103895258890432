import { AutoComplete, Col, Form, Input, Row, Select, Space, Spin } from "antd";
import React, { FC, Fragment, useEffect, useRef, useState } from "react";

import {
  Button,
  Card,
  DisplayInput,
  Modal,
  Page,
  PermissionButton,
  Table,
} from "@comps";
import Adjustment from "./modal/adjustment.md";
import EditRow from "./modal/editRow.md";
import OrderImportBorrow from "./modal/orderImportBorrow.md";

import { ColumnProps } from "@comps/types";
import { Context, Http, Lang, Message } from "@utils";
import { history } from "routes";

export interface Invoice {
  id: number;
  invoiceNo: string;
  orderNo: string;
  invoiceTypeDesc: string;
  requestDate: string;
  needRedNoticeNo: boolean;
  needBlueCodeAndNo: boolean;
  buyerCode: string;
  allowUpdate: boolean;
}

const { Item } = Form;

const { Option } = Select;

/**
 * 待开票发票详情
 */
const WaitInvoiceDetail: FC = (props: any) => {
  const requestNo = Context.getPathVar(props, "requestNo");

  const [invoice, setInvoice] = useState<Optional<Invoice>>();
  const invoiceRef: any = useRef();
  const lineTableRef = useRef<Table>();

  const [form] = Form.useForm();
  const [model, modalHolder] = Modal.useModal();

  const [searchValue, setSearchValue] = useState<string | undefined>(undefined);
  const [options, setOptions] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const [issueLoading, setIssueLoading] = useState(false);

  const context: JSONObject = Context.getContext();
  const isAllowEdit = true;
  // context.roleCode === 'ADMIN' ||
  // context.roleCode === 'CW'||
  // context.roleCode === 'KPY';

  const refreshInvoice = async () => {
    const invoice = await Http.get(`/tax/outputInvoices/${requestNo}`);

    form.setFieldsValue(invoice);
    lineTableRef.current?.refresh();
    setInvoice(invoice);
  };

  const getBillInfo = async () => {
    const invoice = await Http.get(`/tax/outputInvoices/${requestNo}`);

    form.setFieldsValue(invoice);
    setInvoice(invoice);
    invoiceRef.current = invoice;
  };

  useEffect(() => {
    getBillInfo();
  }, [form, requestNo]);

  const orderImportBorrow = React.useCallback(
    (lineId) =>
      Lang.noError(async () => {
        await model.open({
          width: 1150,
          ignoreError: false,
          content(resolve, reject) {
            return (
              <OrderImportBorrow
                onRefresh={refreshInvoice}
                onClose={reject}
                onConfirm={resolve}
                lineId={lineId}
                buyerCode={invoiceRef.current.buyerCode}
                isChange={false}
              />
            );
          },
        });
      }),
    [model]
  );

  const handSave = React.useCallback(async () => {
    try {
      const params = form.getFieldsValue();

      await Http.post(`/tax/outputInvoices/${requestNo}`, {
        ...params,
        requestNo,
      });

      Message.success("保存成功");
    } catch (error) {
      console.error(error);
    }
  }, [form, requestNo]);

  const handInvoicing = () => {
    setIssueLoading(true);
    const params = form.getFieldsValue();

    Http.post(`/tax/outputInvoices/${requestNo}`, {
      ...params,
      requestNo,
    }).then(() => {
      Http.post("/tax/orders/batchInvoice", {
        ids: [invoice?.id],
      })
        .then((res) => {
          Message.success("开具成功");
          history.replace("/tax/outputInvoice/wait", {
            params: res,
          });
        })
        .finally(() => {
          setIssueLoading(false);
        });
    });
  };

  const refreshTable = () => {
    lineTableRef.current?.refresh();
  };

  const handLineEdit = (rowData: JSONObject) => {
    model.open({
      width: 1000,
      content: (_1, reject) => (
        <EditRow
          rowData={rowData}
          refreshTable={refreshTable}
          onClose={reject}
        />
      ),
    });
  };

  const resetDataSource = () => {
    lineTableRef.current?.resetDataSource();
  };

  const handRowSave = (line: JSONObject) => {
    return Http.put("/tax/outputInvoices/lines", {
      id: line.id,
      goodsName: line.goodsName,
      taxCode: line.taxCode,
      spec: line.spec,
      unit: line.unit,
    });
  };

  const handLineSave = React.useCallback(
    (line: JSONObject) =>
      Lang.noError(async () => {
        const data: any = await Http.put("/tax/outputInvoices/lines", {
          id: line.id,
          // invoiceGoodsName: line._invoiceGoodsName,
          goodsName: line._goodsName,
          taxCode: line._taxCode,
          spec: line._spec,
          unit: line._unit,
          withTaxAmount: line._withTaxAmount,
          // invoiceGoodsName: line._invoiceGoodsName
        });

        const withTaxAmtChanged = line._withTaxAmount !== line.withTaxAmount;

        line.invoiceGoodsName = data.invoiceGoodsName;
        line.taxCode = data.taxCode;

        line.goodsName = line._goodsName;
        line.spec = line._spec;
        line.unit = line._unit;
        line.withTaxAmount = line._withTaxAmount;
        line._isEdit = false;

        lineTableRef.current?.resetDataSource();

        if (withTaxAmtChanged) {
          lineTableRef.current?.refresh();
          await refreshInvoice();
        }
        // eslint-disable-next-line
      }),
    []
  );

  const handLineCancel = React.useCallback((line: JSONObject) => {
    line._isEdit = false;
    lineTableRef.current?.resetDataSource();
  }, []);

  const handleInvoiceGoodsNameChange = React.useCallback(
    (invoiceGoodsName: string, line: JSONObject) => {
      line._invoiceGoodsName = invoiceGoodsName;
    },
    []
  );

  const handleGoodNameChange = React.useCallback(
    (goodsName: string, line: JSONObject) => {
      line._goodsName = goodsName;
    },
    []
  );

  const handleTaxCodeChange = React.useCallback(
    (taxCode: string, line: JSONObject) => {
      line._taxCode = taxCode;
    },
    []
  );

  const handleSpecChange = React.useCallback(
    (spec: string, line: JSONObject) => {
      line._spec = spec;
    },
    []
  );

  const handleUnitChange = React.useCallback(
    (unit: string, line: JSONObject) => {
      line._unit = unit;
    },
    []
  );

  const handleWithTaxAmtChange = React.useCallback(
    (withTaxAmount: string, line: JSONObject) => {
      line._withTaxAmount = withTaxAmount;
    },
    []
  );

  const handleAdjustment = async (id: number) => {
    await model.open({
      width: 600,
      content(resolve, reject) {
        return (
          <Adjustment
            id={id}
            source="I"
            onConfirm={resolve}
            onCancel={reject}
          />
        );
      },
    });

    lineTableRef.current?.refresh();
    await refreshInvoice();
  };

  /**
   * 删除折扣行
   * @param id 发票行ID
   */
  const deleteDiscountLine = async (id: number) => {
    await Http.del(`/tax/outputInvoices/discountLines/${id}`);

    lineTableRef.current?.refresh();
    Message.ok();
  };

  const columns: ColumnProps[] = [
    { key: "lineNum", title: "序号", width: 60 },
    { key: "invoiceGoodsName", title: "开票合并名称", width: 330 },
    {
      key: "goodsName",
      title: "开票商品名称",
      width: 330,
      render(goodsName, line: JSONObject) {
        if (line._isEdit) {
          return (
            <Input
              defaultValue={goodsName}
              onChange={(event) =>
                handleGoodNameChange(event.target.value, line)
              }
            />
          );
        }
        return goodsName;
      },
    },
    {
      key: "taxCode",
      title: "税收分类编码",
      width: 200,
      render(taxCode, line: JSONObject) {
        if (line._isEdit) {
          return (
            <Input
              defaultValue={taxCode}
              onChange={(event) =>
                handleTaxCodeChange(event.target.value, line)
              }
            />
          );
        }
        return taxCode;
      },
    },
    {
      key: "spec",
      title: "规格型号",
      width: 120,
      render(spec, line: JSONObject) {
        if (line._isEdit) {
          return (
            <Input
              defaultValue={spec}
              onChange={(event) => handleSpecChange(event.target.value, line)}
            />
          );
        }
        return spec;
      },
    },
    {
      key: "unit",
      title: "单位",
      width: 60,
      render(unit, line: JSONObject) {
        if (line._isEdit) {
          return (
            <Input
              defaultValue={unit}
              onChange={(event) => handleUnitChange(event.target.value, line)}
            />
          );
        }
        return unit;
      },
    },
    { key: "quantity", title: "数量", width: 80 },
    { key: "price", title: "不含税单价", width: 120 },
    { key: "withTaxPrice", title: "含税单价", width: 120 },
    { key: "amount", title: "不含税金额", width: 120 },
    { key: "taxRate", title: "税率", width: 60 },
    { key: "taxAmount", title: "税额", width: 150 },
    { key: "withTaxAmount", title: "含税金额", width: 120 },
    {
      key: "id",
      title: "操作",
      width: 160,
      align: "center",
      fixed: "right",
      render(_1, line: JSONObject) {
        if (line._isEdit) {
          return (
            <>
              <Button
                type="link"
                size="small"
                clickCallback={() => handLineSave(line)}
              >
                保存
              </Button>
              <Button
                type="link"
                size="small"
                onClick={() => handLineCancel(line)}
              >
                取消
              </Button>
            </>
          );
        }
        return (
          <Fragment>
            <Button type="link" size="small" onClick={() => handLineEdit(line)}>
              编辑
            </Button>
            {line.withTaxAmount > 0 && (
              <PermissionButton
                permissions="OutputInvoice.orderImportBorrow"
                type="link"
                onClick={() => orderImportBorrow(line.id)}
              >
                添加借贷项
              </PermissionButton>
            )}
            {/* {line.discountLineFlag && (
              <Popconfirm
                title="删除后商品将不再包含折扣,且无法恢复,确认要删除吗?"
                onConfirm={() => deleteDiscountLine(line.id)}
              >
                <LinkText label="删除" />
              </Popconfirm>
            )} */}
          </Fragment>
        );
      },
    },
  ];

  const handleInputChange = (value: string) => {
    setSearchValue(value);
  };

  React.useEffect(() => {
    let debounceTimer: NodeJS.Timeout;
    const debounceHandleInputChange = (value: string) => {
      setLoading(true);
      // 在这里执行您的处理逻辑
      simulateApiCall(value)
        .then((result) => {
          setOptions(result);
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error:", error);
          setLoading(false);
        });
    };

    debounceTimer = setTimeout(() => {
      debounceHandleInputChange(searchValue!);
    }, 500);

    return () => {
      clearTimeout(debounceTimer);
    };
  }, [searchValue]);

  const simulateApiCall = (value: string): Promise<string[]> => {
    return new Promise((resolve) => {
      // 在此处调用远程接口传递搜索关键字，并返回 Promise
      // 这里使用 setTimeout 模拟异步请求
      setTimeout(() => {
        Http.get(`/tax/orders/title?name=${value}`).then((res) => {
          console.log(res);
          if (res !== null) {
            let Options: any[] = res.map((value: any) => {
              return {
                label: value.name,
                key: value.taxId,
              };
            });
            resolve(Options);
          }
        });
      }, 0);
    });
  };

  const handleOptionClick = (value: string) => {
    console.log("value", value);
    options.forEach((item: any) => {
      if (item.label === value) {
        console.log(item.key);
        form.setFieldsValue({ buyerTaxNo: item.key });
      }
    });
  };

  return (
    <Page>
      {modalHolder}
      <Card
        showBackTag={true}
        title={`单据详情 | ${invoice?.orderNo} | ${invoice?.invoiceTypeDesc} | ${invoice?.requestDate}`}
        actions={
          <Space>
            <Button
              type="primary"
              onClick={handInvoicing}
              loading={issueLoading}
            >
              开具发票
            </Button>
            <Button type="primary" clickCallback={handSave}>
              保存
            </Button>
          </Space>
        }
      >
        {/* <BackTag style={{ left: 0, top: 18 }} /> */}
        <Form form={form}>
          <Row gutter={[16, 8]}>
            <Col span={12}>
              <Item name="buyerName" label="购方名称">
                {isAllowEdit ? (
                  // <Select
                  //   allowClear
                  //   placeholder="请选择选项"
                  //   value={searchValue}
                  //   optionLabelProp="label"
                  //   showSearch
                  //   onBlur={handleBlur}
                  //   notFoundContent={loading ? <Spin size="small" /> : null}
                  //   filterOption={false}
                  //   onSelect={handleOptionClick}
                  //   onSearch={handleInputChange}
                  //   style={{ width: "100%" }}
                  // >
                  //   {options.map((options: any) => (
                  //     <Option
                  //       key={option.taxId}
                  //       value={option.label}
                  //       label={option.label}
                  //     >
                  //       {option.label}
                  //     </Option>
                  //   ))}
                  // </Select>
                  <AutoComplete
                    allowClear
                    placeholder="请选择选项"
                    onSelect={handleOptionClick}
                    onSearch={handleInputChange}
                    style={{ width: "100%" }}
                    notFoundContent={loading ? <Spin size="small" /> : null}
                    filterOption={false}
                    options={options.map((option: any) => ({
                      label: option.label,
                      value: option.label,
                      key: option.taxId,
                    }))}
                  />
                ) : (
                  <DisplayInput />
                )}
              </Item>
            </Col>
            <Col span={4}>
              <Item name="invoiceAmount" label="不含税金额">
                <DisplayInput />
              </Item>
            </Col>
            <Col span={4}>
              <Item name="taxAmount" label="税额">
                <DisplayInput />
              </Item>
            </Col>
            <Col span={4}>
              <Item name="totalAmount" label="价税合计">
                <DisplayInput />
              </Item>
            </Col>
            <Col span={12}>
              <Item name="buyerTaxNo" label="购方税号">
                {isAllowEdit ? <Input /> : <DisplayInput />}
              </Item>
            </Col>

            <Col span={12}>
              {invoice?.needRedNoticeNo && (
                <Item name="redNoticeNo" label="红字通知单号">
                  <Input />
                </Item>
              )}
              {invoice?.needBlueCodeAndNo && (
                <Space>
                  <Item name="blueInvoiceCode" label="蓝字发票代码">
                    <Input />
                  </Item>
                  <Item name="blueInvoiceNo" label="蓝字发票号码">
                    <Input />
                  </Item>
                </Space>
              )}{" "}
            </Col>
            <Col span={12}>
              <Space direction="vertical" style={{ width: "100%" }}>
                <Item name="buyerAddrTel" label="地址电话">
                  <Input />
                </Item>
                <Item name="buyerBank" label="银行账号">
                  <Input />
                </Item>
              </Space>
            </Col>
            <Col span={12}>
              <Item name="remark" label="发票备注">
                <Input.TextArea
                  rows={4}
                  disabled={!invoice?.allowUpdate}
                  style={{ lineHeight: "18px" }}
                />
              </Item>
            </Col>
            <Col span={12}>
              <Item name="buyerContact" label="邮箱">
                {isAllowEdit ? <Input /> : <DisplayInput />}
              </Item>
            </Col>
            <Col span={12}>
              <Item name="buyerPhone" label="手机">
                {isAllowEdit ? <Input /> : <DisplayInput />}
              </Item>
            </Col>
          </Row>
          <Table
            url={`/tax/outputInvoices/${requestNo}/lines`}
            columns={columns}
            style={{ margin: "20px 0", minHeight: "200px" }}
            onTableRef={(ref) => {
              lineTableRef.current = ref;
            }}
          />
          <Row gutter={[16, 8]}>
            <Col span={12}>
              <Item name="sellerName" label="销方名称">
                <DisplayInput />
              </Item>
            </Col>
            <Col span={12}>
              <Item name="sellerTaxNo" label="纳税人识别号">
                <DisplayInput />
              </Item>
            </Col>
            <Col span={12}>
              <Item name="sellerAddrTel" label="销方地址电话">
                <DisplayInput />
              </Item>
            </Col>
            <Col span={12}>
              <Item name="sellerBank" label="银行账号">
                <DisplayInput />
              </Item>
            </Col>
            <Col span={8}>
              <Item name="cashier" label="收款人">
                <Input />
              </Item>
            </Col>
            <Col span={8}>
              <Item name="checker" label="复核人">
                <Input />
              </Item>
            </Col>
          </Row>
        </Form>
      </Card>
    </Page>
  );
};

export default WaitInvoiceDetail;
