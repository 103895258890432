import React, { useState } from 'react';
import { Card } from '@comps';
import { Space, Button } from 'antd';
import { Http, Message } from '@utils';

export interface Props {
  IDs: number[],
  refreshTable: () => void,
  refreshTree: () => void,
  onClose: () => void
}

const DeleteMD: React.FC<Props> = ({ 
  IDs, 
  refreshTable, 
  refreshTree,
  onClose 
}) => {
  const [loading, setLoading] = useState(false);

  const handleDeleteData = async () => {
    setLoading(true);
    
    Http.del('/tax/goodsCategoryMapping', { ids: IDs })
      .then(() => {
        Message.success('删除成功');
        refreshTable();
        refreshTree();
      })
      .finally(() => {
        setLoading(false);
        onClose();
      });
  }

  return (
    <Card title='删除'>
      <p style={{ fontSize: 14, marginTop: 16 }}>已选择 {IDs.length} 条记录，是否确认删除 ? </p>
      <div style={{ textAlign: 'center' }}>
        <Space size="large">
          <Button loading={loading} type="primary" onClick={handleDeleteData}>确定</Button>
          <Button onClick={onClose}>取消</Button>
        </Space>
      </div>
    </Card >)
}

export default DeleteMD
