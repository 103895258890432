import React, { FC, useEffect, useState } from "react";
import { Button, Col, Form, Input, message, Row, Select, Spin } from "antd";
import { Card, DisplayInput, Table } from "@comps";
import { Http, Lang } from "@utils";

const { Item } = Form
const { TextArea } = Input
const { Option } = Select

interface Props {
  data: JSONObject,
  onClose: () => void,
  query?: () => void,
}

const ApprovalModal:FC<Props> = ({
  data,
  onClose,
  query,
}) => {
  const { approveType, taxName, approvalColumns, dg } = data
  const [form] = Form.useForm()
  const [approvalLoading, setApprovalLoading] = useState<boolean>(false)
  const disabledTable = ["印花税", "房产税", "工会经费", "城镇土地使用税", "消费税", "工会经费"]
  const draftType = [1, 3]
  const submitterType = [2, 4]
  const orgNameType = [2, 4]
  const remarkType = [2]
  const masterNameType = [4]
  const analysisContentType = [4]

  useEffect(() => {
    form.setFieldsValue({
      ...data,
      approvalOpinion: ""
    })
    approveType === 4 && fetchPolicy()
  }, [])

  // 获取政策解析详情
  const fetchPolicy = () => {
    const { sourceId } = data
    Http.get(`/analysis/${sourceId}`).then(res => {
      const { analysisContent } = res
      form.setFieldsValue({
        analysisContent
      })
    })
  }

  // 确认审批
  const handleConfirm = async () => {
    await form.validateFields()
    const { result, approvalOpinion } = form.getFieldsValue()
    if (result === 2 && !approvalOpinion) {
      message.warning("审批结果不通过时, 审批意见不允许为空")
      return
    }
    setApprovalLoading(true)
    const { id } = data
    Http.put("/approval", Lang.filterObjectNil({
      id,
      result,
      approvalOpinion,
    })).then(() => {
      message.success("操作成功")
      onClose()
      query && query()
    }).finally(() => {
      setApprovalLoading(false)
    })
  }

  const renderTitle = () => {
    if (draftType.includes(approveType)) {
      return taxName + `底稿审批`
    } else if (approveType === 2) {
      return '知识查阅审批'
    } else if (approveType === 4) {
      return '政策解析审批'
    }
  }

  return (
    <Spin spinning={approvalLoading}>
      <Card title={renderTitle()}>
        <Form labelCol={{span: 4}} form={form} autoComplete="off">
          {draftType.includes(approveType) && <Item name="orgName" label="公司名称"><DisplayInput/></Item>}
          {draftType.includes(approveType) && <Item name="period" label="税款所属期"><DisplayInput/></Item>}
          {!disabledTable.includes(taxName) && draftType.includes(approveType) && <Item name="table">
            <Table columns={approvalColumns}
              pagination={false}
              dataSource={dg}
              rowKey="key"></Table>
          </Item>}
          {orgNameType.includes(approveType) && <Item name="orgName" label="申请人所属机构"><DisplayInput/></Item>}
          {masterNameType.includes(approveType) && <Item name="matterName" label="事项名称">
            <TextArea 
              disabled
              autoSize
              style={{ border: 0, backgroundColor: "white", color: "rgba(0, 0, 0, 0.75)" }}></TextArea>
          </Item>}
          {submitterType.includes(approveType) && <Item name="submitter" label="申请人"><DisplayInput/></Item>}
          {remarkType.includes(approveType) && <Item name="remark" label="借阅原因"><DisplayInput/></Item>}
          <Item name="result" label="审批结果"
            rules={[{required: true, message: "请填写审批结果"}]}>
            <Select>
              <Option value={1}>通过</Option>
              <Option value={2}>不通过</Option>
            </Select>
          </Item>
          <Item name="approvalOpinion" label="审批意见">
            <TextArea minLength={2}></TextArea>
          </Item>
          {analysisContentType.includes(approveType) && <Item name="analysisContent" label="政策解析内容">
            <TextArea 
              disabled
              autoSize={{
                maxRows: 7
              }}
              style={{ border: 0, backgroundColor: "white", color: "rgba(0, 0, 0, 0.75)" }}></TextArea>
          </Item>}
          <Row style={{justifyContent: 'center'}}>
            <Col style={{marginRight: 10}}>
              <Button type="primary" onClick={handleConfirm}>确认</Button>
            </Col>
            <Col>
              <Button onClick={onClose}>取消</Button>
            </Col>
          </Row>
        </Form>
      </Card>
    </Spin>
  )
}

export default ApprovalModal
