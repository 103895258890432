import React, { FC, Fragment, useEffect, useState, useCallback } from "react";
import {
  Space,
  Form,
  DatePicker,
  Input,
  Button,
  Popconfirm,
  Select,
  Dropdown,
  message ,
  Menu,
  Row,
  Col,
  Table as AdTable,
  Popover,
  Tag,
} from "antd";
import { Link } from "react-router-dom";
import {
  Page,
  Card,
  Table,
  Modal,
  PermissionButton,
  Lookup,
  Download,
  ViewInvoice,
  Upload,
  LinkText,
  HighBeatMeterUpload,
} from "@comps";

import { UploadFile } from "./component";
import ManualAdd from "./modal/ManualAdd";
import { ApplyTypeVal, ApplyType } from "../../output/apply/interface";
import CheckResMD from "./modal/CheckRes.md";
import EditRowMD from "./modal/EditRow.md";
import { EntryMethod, Export } from "./interface";

import {
  Http,
  Message,
  FormUtil,
  Math,
  DateUtils,
  Context,
  Lang,
} from "@utils";
import { ColumnProps } from "@comps/types";
import {
  CheckCircleFilled,
  DownOutlined,
  ExclamationCircleFilled,
} from "@ant-design/icons";
import ScannerTable from "./modal/scannerTable";
// import InvoiceDetail from "./detail";
import InvoiceDetail2 from "./detail";
import AddOrEdit from "./modal/addOrEdit";

interface NObject {
  [key: string]: string | number | undefined | null | void;
}

const { Item } = Form;
const { RangePicker } = DatePicker;
const { Option } = Select;

const PersonalTicket: FC = () => {
  const [form] = Form.useForm();
  const [viewInvoiceModal, viewInvoiceModalHolder] = Modal.useModal();
  const [modal, modalHolder] = Modal.useModal();

  let [table, setTable] = useState<Table>();
  const onTableRef = (tableRef: Table) => {
    setTable(tableRef);
    table = tableRef;
  };

  const [checkLoading, setCheckLoading] = useState(false);
  const [checkRemainingTimesLoading, setCheckRemainingTimesLoading] =
    useState(false);
  const [batchDelLoading, setBatchDelLoading] = useState(false);
  const [entryLoading, setEntryLoading] = useState(false);
  const [currentType, setCurrentType] = useState("");
  const [taxDate, setTaxDate] = useState<any[]>(["", ""]);
  const [tableSummaryInfo, setTableSummaryInfo] = useState<JSONObject>({});
  const [checkLastTimes, setCheckLastTimes] = useState();
  const [checkUsedTimes, setCheckUsedTimes] = useState();
  const [invoiceTypeEnum, setInvoiceTypeEnum] = useState<NObject>({});
  const [inputSourceTypeEnum, setInputSourceTypeEnum] = useState<NObject>({});
  const applyTypeObj: JSONObject = {
    [ApplyTypeVal.RETAIL]: ApplyType.RETAIL,
    [ApplyTypeVal.ADVANCE]: ApplyType.ADVANCE,
    [ApplyTypeVal.PROGRESS]: ApplyType.PROGRESS,
    [ApplyTypeVal.SETTLE]: ApplyType.SETTLE,
    [ApplyTypeVal.OTHER]: ApplyType.OTHER,
    [ApplyTypeVal.MAIN_ASSISTANT]: ApplyType.MAIN_ASSISTANT,
  };

  useEffect(() => {
    Http.get("/inputInvManagement/interfaceUseTime").then((res) => {
      setCheckLastTimes(res.checkLastTimes);
      setCheckUsedTimes(res.checkUsedTimes);
    });
  }, []);

  //发票类型枚举
  React.useEffect(() => {
    (async () => {
      const kvEnum: NObject = {};
      await Http.get("/sys/lookups/kv", {
        lookupType: "INPUT_INVOICE_TYPE",
      }).then((result) => {
        result.map((item: any) => {
          kvEnum[item.k] = item.v;
        });
      });
      setInvoiceTypeEnum(kvEnum);
    })();
  }, []);

  //数据来源枚举
  React.useEffect(() => {
    (async () => {
      const kvEnum: NObject = {};
      await Http.get("/sys/lookups/kv", {
        lookupType: "INPUT_SOURCE_TYPE",
      }).then((result) => {
        result.map((item: any) => {
          kvEnum[item.k] = item.v;
        });
      });
      setInputSourceTypeEnum(kvEnum);
    })();
  }, []);

  const showManualAddMD = async () => {
    await modal.open({
      width: "40%",
      content(resolve, reject) {
        return <ManualAdd onConfirm={resolve} onCancel={reject} />;
      },
    });

    table?.refresh();
  };

  const handleScannerEntry = async () => {
    await modal.open({
      width: 400,
      content(resolve, reject) {
        return <ScannerTable onCancel={reject} />;
      },
    });

    table?.refresh();
  };

  const handleDelete = (id: number) => {
    Http.del(`/inputInvManagement/deleteInvTemporary/${id}`).then(() => {
      table?.refresh();
      Message.success("删除成功");
    });
  };

  const handleCheck = () => {
    const ids = table?.getSelectedRowKeys();

    if (ids?.length === 0) {
      Message.warn("请至少选择一条数据");
      return;
    }

    setCheckLoading(true);

    Http.post(`/inputInvManagement/checkInvTemporary/${ids}`)
      .then((data) => {
        // modal.open({
        //   width: 400,
        //   content: (_1, reject) => (
        //     <CheckResMD checkResObject={data} onClose={reject} />
        //   ),
        // });
        console.log(data);
        table?.refresh();
      })
      .finally(() => {
        setCheckLoading(false);
      });
  };

  const renderCheckStatus = (checkType: number) => {
    if (checkType === 0) {
      return <Tag color="default">未查验</Tag>;
    } else if (checkType === 1) {
      return <Tag color="success">已查验</Tag>;
    } else if (checkType === 2) {
      return <Tag color="processing">查验中</Tag>;
    } else if (checkType === 3) {
      return <Tag color="error">查验失败</Tag>;
    }
  };

  const handleBatchDel = () => {
    const ids = table?.getSelectedRowKeys();
    if (ids?.length === 0) {
      Message.warn("请至少勾选一条数据");
      return;
    }

    setBatchDelLoading(true);
    Http.del(`/inputInvManagement/deleteInvTemporary/${ids}`)
      .then(() => {
        table?.refresh();
        Message.success("删除成功");
      })
      .finally(() => {
        setBatchDelLoading(false);
      });
  };

  const handleSummaryExport = () => {
    const ids = table?.getSelectedRowKeys();
    FormUtil.exportExcelFile("/inputInvManagement/export", { ids });
  };

  const refreshTable = () => {
    table?.refresh();
  };

  const showEditMD = (rowDate: JSONObject) => {
    modal.open({
      width: 400,
      content: (_1, reject) => (
        <EditRowMD
          rowData={rowDate}
          refreshTable={refreshTable}
          onClose={reject}
        />
      ),
    });
  };

  //   const showDetailMD = (rowDate: JSONObject) => {
  //     modal.open({
  //       width: 400,
  //       content: (_1, reject) => (
  //         <InvoiceDetail
  //           rowData={rowDate}
  //           refreshTable={refreshTable}
  //           onClose={reject}
  //         />
  //       ),
  //     });
  //   };
  
  const handleViewInvoice = useCallback(
    (row: JSONObject) => {
      if (row.pictureUrl !== "") {
        Lang.noError(async () => {
          await viewInvoiceModal.open({
            width: 800,
            ignoreError: false,
            content(resolve, reject) {
              return <ViewInvoice onClose={reject} picUrl={row.pictureUrl} />;
            },
          });
        });
      } else {
        message.warning('该发票未找到对应的图片信息');
      }
    },
    [viewInvoiceModal]
  );

  const showHighBeatMeterUploadMD = () => {
    modal.open({
      width: 560,
      content: (_1, reject) => (
        <HighBeatMeterUpload refreshTable={refreshTable} onClose={reject} />
      ),
    });
  };

  const resetSelect = () => {
    form.resetFields();
  };

  const showDetail = (row: JSONObject) => {
    const state = {
      routerFrom: "发票管理",
    };
    // if (row.invoiceType) {
      // const route = `/tax/outputInvoice/${row.requestNo}/detail`;
      // Context.handleAdditionalPane('发票管理', route, route, state);
      Http.get(`/input/biz/getInvoice?id=${row.id}`).then((data) => {
        modal.open({
          width: 1200,
          content(resolve, reject) {
            return <InvoiceDetail2 request={data} onClose={reject} />;
          },
        });
      });
      //   modal.open({
      //     width: 1200,
      //     content(resolve, reject) {
      //       return <img src={row.pictureUrl} alt="" style={{width:"80vw"}}/>;
      //     }
      //   });
    // } else {
    //   Http.get(`/request/${row.requestId}/detail`).then((data) => {
    //     const route = `/output/apply/detail/${row.requestId}/${
    //       applyTypeObj[data.request.requestType]
    //     }`;
    //     Context.handleAdditionalPane("申请详情", route, route, state);
    //   });
    // }
  };

  const BlankSpace: FC = () => {
    return <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>;
  };

  const columns: ColumnProps[] = [
    {
      key: "invoiceNo",
      title: "票据号码",
      width: 130,
      fixed: "left",
      render: (value, row) => (
        <>
          <LinkText label={value} onClick={() => showDetail(row)} />
        </>
      ),
    },
    { key: "invoiceCode", title: "票据代码", width: 130, fixed: "left" },
    {
      key: "invoiceType",
      title: "票据类型",
      dataIndex: "invoiceType",
      width: 160,
      // @ts-ignore
      render: (text, record) =>
        invoiceTypeEnum[text] ? invoiceTypeEnum[text] : "",
    },
    { key: "invoiceDate", title: "开票日期", width: 120 },
    { key: "checkCode", title: "校验码", width: 120 },
    {
      key: "checkedType",
      title: "验证状态",
      width: 110,
      render: renderCheckStatus,
    },
    { key: "failRemark", title: "查验描述", width: 130 },
    { key: "sellerName", title: "销方名称", width: 300 },
    { key: "taxAmount", title: "税额", width: 130, format: "amount" },
    { key: "withTaxAmount", title: "含税金额", width: 130, format: "amount" },
    {
      key: "uploadType",
      title: "数据来源",
      dataIndex: "uploadType",
      width: 130,
      // @ts-ignore
      render: (text, record) =>
        inputSourceTypeEnum[text] ? inputSourceTypeEnum[text] : "",
    },
    { key: "uploadDate", title: "录入时间", width: 170 },
    {
      key: "id",
      title: "操作",
      width: 180,
      fixed: "right",
      align: "center",
      render(id: number, row: JSONObject) {
        return (
          <Space size="large">
            {/* <Link to={`/income/personalTicket/${id}/detail`}>详情</Link> */}
            <LinkText label="查看" onClick={() => handleViewInvoice(row)} />
            {/* <LinkText label="编辑" onClick={() => showEditMD(row)} /> */}
            <LinkText
              label="编辑"
              onClick={() => {
                handleAddOrEdit(row, false);
              }}
            />

            <Popconfirm
              title="您确认要删除吗?"
              onConfirm={() => handleDelete(id)}
            >
              <LinkText label="删除" />
            </Popconfirm>
          </Space>
        );
      },
    },
  ];
  // const exportMenu = (
  //   <Menu>
  //     <Menu.Item>
  //       <div onClick={handleSummaryExport}>汇总导出</div>
  //     </Menu.Item>
  //     <Menu.Item>
  //       <div onClick={handleSummaryExport}>明细导出</div>
  //     </Menu.Item>
  //   </Menu>
  // );

  const entryMenu = (
    <Menu>
      <Menu.Item>
        {/* <div onClick={showManualAddMD}>手工录入</div> */}
        <div
          onClick={() => {
            handleAddOrEdit({}, false);
          }}
        >
          手工录入
        </div>
      </Menu.Item>
      {/* <Menu.Item>
        <div onClick={showHighBeatMeterUploadMD}>高拍仪录入</div>
      </Menu.Item> */}
      <Menu.Item>
        <div onClick={handleScannerEntry}>扫描仪录入</div>
      </Menu.Item>
      <Menu.Item>
        <UploadFile
          label="图片录入"
          acceptFileType=".jpg,.jpeg,.png"
          sourceType={11}
          refreshTable={refreshTable}
          setLoading={setEntryLoading}
        />
      </Menu.Item>
      <Menu.Item>
        <UploadFile
          label="PDF 导入"
          acceptFileType=".pdf"
          sourceType={6}
          refreshTable={refreshTable}
          setLoading={setEntryLoading}
        />
      </Menu.Item>
      <Menu.Item>
        <UploadFile
          label="OFD 导入"
          acceptFileType=".ofd"
          sourceType={8}
          refreshTable={refreshTable}
          setLoading={setEntryLoading}
        />
      </Menu.Item>
    </Menu>
  );

  const content = (
    <div>
      <p>
        <CheckCircleFilled style={{ color: "#1890ff", marginRight: "8px" }} />
        查验已用次数：{checkUsedTimes}
      </p>
      <p>
        <ExclamationCircleFilled
          style={{ color: "#2DB82A", marginRight: "8px" }}
        />
        查验剩余次数：{checkLastTimes}
      </p>
    </div>
  );
  const handleTaxDate = (date: any, dateString: string[]) => {
    date
      ? setTaxDate([DateUtils.date2int(date[0]), DateUtils.date2int(date[1])])
      : setTaxDate(["", ""]);
  };
  const resetEmptyFileds = () => {
    setTaxDate(["", ""]);
    return {
      invoiceDateFromTo: "",
      invoiceDateFrom: "",
    };
  };

  const handleAddOrEdit = async (row: JSONObject, isScanGun: boolean) => {
    await modal.open({
      width: 500,
      content(resolve, reject) {
        return <AddOrEdit row={row} onCancel={reject} isScanGun={isScanGun} />;
      },
    });

    table?.refresh();
  };

  return (
    <Page>
      {viewInvoiceModalHolder}
      {modalHolder}
      <Card
        title="个人票夹"
        actions={
          <Space>
            <Dropdown overlay={entryMenu} trigger={["click"]}>
              <Button type="primary">
                <Space>
                  发票录入
                  <DownOutlined />
                </Space>
              </Button>
            </Dropdown>
            {/* <Download
              type="button"
              url="/input/invoices/importTemplate"
              label="下载录入模板"
            />
            <Upload
              label="Excel 录入"
              url="/input/invoices/import"
              onFinish={() => {
                table?.refresh();
              }}
            /> */}
            <Popover placement="bottom" content={content}>
              <Button
                type="primary"
                loading={checkLoading}
                onClick={handleCheck}
              >
                发票查验
              </Button>
            </Popover>

            {/* <Button
            type="primary"
            loading={checkRemainingTimesLoading}
            onClick={showCheckRemainingTimesMD}
          >
            验伪剩余次数
          </Button> */}
            {/* <Dropdown overlay={exportMenu} trigger={["click"]}>
              <Button type="primary">
                <Space>
                  导出
                  <DownOutlined />
                </Space>
              </Button>
            </Dropdown> */}
            <Button type="primary" onClick={handleSummaryExport}>
              导出
            </Button>
            <Popconfirm
              title="删除后数据无法复原，是否继续？"
              onConfirm={handleBatchDel}
            >
              <Button type="primary" loading={batchDelLoading}>
                批量删除
              </Button>
            </Popconfirm>
          </Space>
        }
      >
        <Table
          onTableRef={onTableRef}
          url="/inputInvManagement/invTemporary"
          columns={columns}
          selectedRowKeys={[]}
          formAdditionalParameters={{
            invoiceDateFrom: taxDate[0],
            // invoiceDateFromTo: taxDate,
          }}
          resetEmptyFileds={resetEmptyFileds}
          summary={(invoices, selectIds) => {
            // 选中的数据
            // console.log('take',invoices,selectIds);

            const selectInvoices = invoices.filter((invoice) =>
              selectIds?.includes(invoice.id)
            );
            const invoiceTotal = selectInvoices.reduce(
              (total, invoice) => {
                total.invoiceAmount += invoice.amount;
                total.taxAmount += invoice.taxAmount;
                total.totalAmount += invoice.withTaxAmount;
                return total;
              },
              {
                invoiceAmount: 0,
                taxAmount: 0,
                totalAmount: 0,
              }
            );
            //全部的数据
            const totalSumInfo = invoices.reduce(
              (total: any, invoice: any) => {
                total.invoiceAmount += invoice.amount;
                total.taxAmount += invoice.taxAmount;
                total.totalAmount += invoice.withTaxAmount;
                return total;
              },
              {
                invoiceAmount: 0,
                taxAmount: 0,
                totalAmount: 0,
              }
            );

            return (
              <>
                <AdTable.Summary.Row style={{ height: 36 }}>
                  <Row
                    gutter={14}
                    style={{ position: "absolute", left: 10, right: 0 }}
                  >
                    <Col span={6}>选中数量: {selectInvoices?.length || 0}</Col>
                    <Col span={6}>
                      选中不含税金额:
                      {Math.num2money(invoiceTotal.invoiceAmount)}
                    </Col>
                    <Col span={6}>
                      选中税额: {Math.num2money(invoiceTotal.taxAmount)}
                    </Col>
                    <Col span={6}>
                      选中含税金额: {Math.num2money(invoiceTotal.totalAmount)}
                    </Col>
                  </Row>
                </AdTable.Summary.Row>
                <AdTable.Summary.Row style={{ height: 36 }}>
                  <Row
                    gutter={14}
                    style={{ position: "absolute", left: 10, right: 0 }}
                  >
                    <Col span={6}>全部数量: {invoices?.length}</Col>
                    <Col span={6}>
                      全部不含税金额:{" "}
                      {Math.num2money(totalSumInfo.invoiceAmount)}
                    </Col>
                    <Col span={6}>
                      全部税额: {Math.num2money(totalSumInfo.taxAmount)}
                    </Col>
                    <Col span={6}>
                      全部含税金额: {Math.num2money(totalSumInfo.totalAmount)}
                    </Col>
                  </Row>
                </AdTable.Summary.Row>
              </>
            );
          }}
        >
          <Item name="key">
            <Input placeholder="票据代码/号码，销方名称" />
          </Item>
          <Item name="invoiceType">
            <Lookup placeholder="票据类型" lookupType="INPUT_INVOICE_TYPE" />
          </Item>
          <Item name="invoiceDateFromTo">
            <RangePicker
              placeholder={["开票日期起", "开票日期止"]}
              format="YYYY-MM-DD"
              onChange={handleTaxDate}
            />
          </Item>
          <Item name="checkType">
            <Select placeholder="票据状态" allowClear>
              <Option value={0}>未查验</Option>
              <Option value={1}>已查验</Option>
              <Option value={2}>查验中</Option>
              <Option value={3}>查验失败</Option>
            </Select>
          </Item>
          <Item name="uploadType">
            <Lookup placeholder="数据来源" lookupType="INPUT_SOURCE_TYPE" />
          </Item>
        </Table>
      </Card>
    </Page>
  );
};

export default PersonalTicket;
