import * as React from 'react';
import { Http } from '@utils';
import Select, { Props as SelectProps } from '../Select';

export interface Props extends SelectProps<string> {
  url: string,
  query?: JSONObject,
  header?: JSONObject,
  isKey?: boolean,
  KVList?:(list:JSONObject[]) => void,
}

const Lookup: React.FC<Props> = ({
  url, query, header,
  isKey = true,
  KVList,
  ...resetProps
}: Props) => {
  const [kvs, setKvs] = React.useState<KV[]>([]);

  React.useEffect(() => {
    ((async () => {
      const _kvs = await Http.get<KV[]>(url, query, header);
      setKvs(_kvs);
      KVList && KVList(_kvs)
    })());
  }, [url, query, header]);

  return (<Select {...resetProps} options={kvs.map(kv => ({label: kv.v, value: isKey ? kv.k : kv.v}))} />);
};

export default Lookup;
