import React, { FC, useState, useRef, memo } from 'react';
import { Space, Button, Form, Input, DatePicker, Col } from 'antd';

import { Card, Table, TableSummaryRow } from '@comps';
import CrossCheckSubTable from '../component/cross-check-subtable';

import { Message, Http } from '@utils';
import { ColumnProps } from '@comps/types';

const { Item } = Form;
const { RangePicker } = DatePicker;

interface Props {
  crossCheckRowId: number;
  baseFormId: number;
  setAllModuleData: (data: JSONObject) => void;
  orderType: string;
  isToBeOpenedTable: boolean;
  onClose: () => void;
}

const CrossCheck: FC<Props> = memo(({ 
  crossCheckRowId,
  baseFormId,
  setAllModuleData,
  orderType,
  isToBeOpenedTable,
  onClose,
}) => {

  const crossCheckedRowRef = useRef<JSONObject[]>([]);
  const childTableIdsRef = useRef<number[]>([]);
  const advanceTableRef = useRef<JSONObject[]>([]);

  const [crossCheckedQuantity, setCrossCheckedQuantity] = useState(0);
  const [willCrossCheckedQuantity, setWillCrossCheckedQuantity] = useState(0);

  const [autoImportLoading, setAutoImportLoading] = useState(false);
  const [crossCheckLoading, setCrossCheckLoading] = useState(false);

  let [table, setTable] = useState<Table>();
  const onTableRef = (tableRef: Table) => {
    setTable(tableRef);
    table = tableRef;
  };

  const businessTypeObj: JSONObject = {
    PROJECT: '工程类',
    RETAIL: '零售类',
    OTHER: '其它类型'
  };

  const expandedRowRender = ({ id }: JSONObject) => {
    const dataSource: JSONObject[] = table?.getTableData();
    const currentRow: JSONObject = 
      dataSource.find((row: JSONObject) => row.id === id) ?? {};

    const subTableDataSource: JSONObject[] = currentRow?.orderLines ?? [];

    return (
      <CrossCheckSubTable 
        orderId={id} 
        dataSource={subTableDataSource}
        crossCheckedRowRef={crossCheckedRowRef} 
        childTableIdsRef={childTableIdsRef}
      />
    );
  };

  const handleCrossCheck = () => {
    const crossCheckedData: JSONObject[] = crossCheckedRowRef.current; 
    const validDataArr: JSONObject[] = [];

    for(let i = 0; i < crossCheckedData.length; i++) {
      const currentApplyQuantity = crossCheckedData[i].applyQuantity;

      if (currentApplyQuantity === 0 || currentApplyQuantity) {
        validDataArr.push(crossCheckedData[i]);
      }
    };

    if (validDataArr.length === 0) {
      Message.warn('请输入提交申请数量');
      return;
    }
  
    setCrossCheckLoading(true);

    Http.post(`/request/${baseFormId}/${crossCheckRowId}/linkOutputOrder`, {
      importLines: validDataArr,
      linkInvLine: isToBeOpenedTable
    }).then(data => {
      setAllModuleData(data);
      Message.success('勾稽成功');
      onClose();
    }).finally(() => {
      setCrossCheckLoading(false);
    })
  }

  const setDataSourceHierarchy = (data: JSONObject) => {
    return data.outputOrders;
  };

  const summary = () => {
    return (
      <TableSummaryRow>
        <Col span={5}>
          已勾稽数量: {crossCheckedQuantity}
        </Col>
        <Col span={5}>
          待勾稽数量: {willCrossCheckedQuantity}
        </Col>
      </TableSummaryRow>
    );
  };

  const setTableSummaryData = (data: JSONObject) => {
    setCrossCheckedQuantity(data.linkQuantity);
    setWillCrossCheckedQuantity(data.remainLinkQuantity);
  };

  const columns: ColumnProps [] = [
    { key: 'orderNo', title: '单据号', width: 180, fixed: 'left' },
    { key: 'salerName', title: '业务员', width: 140 },
    { key: 'orderDate', title: '单据日期', width: 140 },
    { key: 'sourceSystem', title: '来源系统', width: 140, align: 'center' },
    { key: 'sbuName', title: 'SBU 名称', width: 140 },
    { key: 'withTaxAmount', title: '总金额', width: 140, format: 'amount' },
    { key: 'invoicedAmount', title: '已开发票金额', width: 140, format: 'amount' },
    { key: 'remainAmount', title: '未开发票金额', width: 140, format: 'amount' },
    { key: 'contractNo', title: '项目合同编码', width: 180, align: 'center' },
    { key: 'mesNo', title: 'MES 订单号码', width: 140 },
    { key: 'custPoNo', title: '客户 PO 单据号', width: 140 },
    { key: 'businessType', title: '业务类型', width: 140, align: 'center',
      render: (businessType: string) => businessTypeObj[businessType]
    },
    { key: 'orderTypeDesc', title: '订单类型', width: 140, align: 'center' },
    { key: 'sbuCode', title: 'SBU 代码', width: 140 },
    { key: 'statusCode', title: '更新状态', width: 120, align: 'center', fixed: 'right',
      render: (statusCode: string) => statusCode === '2' ? '已更新' : '未更新'
    }
  ];

  return (
    <Card 
      title="勾稽" 
      style={{ paddingRight: 50 }}
      actions={
        <Space>
          <Button 
            type="primary"
            loading={crossCheckLoading}
            onClick={handleCrossCheck}
          >
            勾稽
          </Button>
        </Space>
      }
    >
      <Table
        onTableRef={onTableRef}
        formAdditionalParameters={{ orderType }}
        url={`/request/${baseFormId}/${crossCheckRowId}/queryLinkOrder`}
        setDataSourceHierarchy={setDataSourceHierarchy}
        columns={columns}
        isExpandAllItem={true}
        selectedRowKeys={[]} 
        expandable={{ 
          expandedRowRender,
          defaultExpandAllRows: true 
        }}
        summary={summary}
        setTableSummaryData={setTableSummaryData}
        scroll={{ y: window.innerHeight - 230 }}
        pagination={false}
      >
        <Item name="orderDateFromTo">
          <RangePicker placeholder={['单据日期从', '单据日期至']} inputReadOnly/>
        </Item>
        <Item name="orderNo">
          <Input placeholder="单据号" />
        </Item>
        <Item name="custPoNo">
          <Input placeholder="客户 PO 单据号" />
        </Item>
        <Item name="customerCode">
          <Input placeholder="客户编码" />
        </Item>
        <Item name="customerName">
          <Input placeholder="客户名称" />
        </Item>
        <Item name="salerName">
          <Input placeholder="业务员" />
        </Item>
      </Table>
    </Card>
  );
});

export default CrossCheck;
