import React, { useState, useEffect, FC, memo } from 'react';
import { Space, Button, Form, Row, Col, Input, Select, InputNumber, Radio } from 'antd';

import { Card, Lookup, DisplayInput } from '@comps';
import { Http, Message } from '@utils';

const { Item } = Form;

interface Props {
  isEdit: boolean,
  formData: JSONObject,
  onResolve: () => void
  onClose: () => void
};

interface GoodsCodeItemProps {
  label: string, 
  maxLength: number
};

interface GoodsCodeProps {
  [key: string]: GoodsCodeItemProps
};

const AddOrEditMD: FC<Props> = memo(({ 
  isEdit, 
  formData, 
  onResolve,
  onClose 
}) => {

  const { id } = formData;

  const [form] = Form.useForm();
  const [rates, setRates] = useState<KV[]>([]);
  const [taxCodes, setTaxCodes] = useState<KV[]>([]);
  const [policies, setPolicies] = useState<KV[]>([]);

  const goodsCodeObj: GoodsCodeProps = {
    P: { label: '产品编码', maxLength: 100 },
    C: { label: 'MES 订单识别码', maxLength: 1 }
  };

  const [goodsCodeLabel, setGoodsCodeLabel] = useState(goodsCodeObj.P.label);
  const [goodsCodeMaxLength, setGoodsCodeMaxLength] = useState(goodsCodeObj.P.maxLength);

  const [loading, setLoading] = useState(false);
  
  useEffect(() => {
    setFormFieldsVal();
  }, []);

  const setFormFieldsVal = async () => {
    if (!isEdit) {
      form.setFieldsValue({ goodsType: 'P', hasDiscountPolicy: false });
    }

    const [_rates, _taxCodes, _policies] = await Promise.all([
      Http.get<KV[]>('/tax/rates/kv'),
      Http.get<KV[]>('/tax/taxCodes/kv'),
      Http.get<KV[]>('/tax/policies/discountsKv')
    ]);

    setRates(_rates);
    setTaxCodes(_taxCodes);
    setPolicies(_policies);

    if (isEdit) {
      setGoodsCodeProps(formData.goodsType);
      form.setFieldsValue(formData);
    };
  };

  const handleTaxCode = (val: string, option: any) => {
    const labelFullName: string = option.label;
    const labelPreName: string = labelFullName.split(' ')[0]
    form.setFieldsValue({ taxShortName: labelPreName });
  };

  const handleAddOrEdit = () => {
    setLoading(true);

    const queryData = form.getFieldsValue();
    let httpMethod: any = Http.post;

    if (isEdit) {
      queryData.id = id;
      httpMethod = Http.put;
    };

    httpMethod('/tax/goodsMapping', queryData)
      .then(() => {
        isEdit 
          ? Message.success('修改成功')
          : Message.success('新增成功');
          
        onResolve();
      })
      .finally(() => {
        setLoading(false);
        onClose();
      });
  };

  const setGoodsCodeProps = (key: string) => {
    setGoodsCodeLabel(goodsCodeObj[key].label);
    setGoodsCodeMaxLength(goodsCodeObj[key].maxLength);
  };

  return (
    <Card title={isEdit ? '修改' : '新增'}>
      <Form 
        form={form} 
        onFinish={handleAddOrEdit} 
        labelCol={{ span: 8 }} 
        wrapperCol={{ span: 13 }}
      >
        <Row>
          <Col span={12}>
            <Item 
              label="商品类别" 
              name="goodsType" 
              rules={[{ required: true }]}
            >
              <Lookup 
                placeholder="请选择类别" 
                lookupType="TAX_GOODS_TYPE" 
                onChange={setGoodsCodeProps}
              />
            </Item>
          </Col>
          <Col span={12}>
            <Item 
              label={<span>{goodsCodeLabel}</span>}
              name='code' 
              rules={[{ required: true }]} 
            >
              <Input maxLength={goodsCodeMaxLength} />
            </Item>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <Item 
              name="productDesc" 
              label="产品描述"
            >
              <Input />
            </Item>
          </Col>
          <Col span={12}>
            <Item 
              name='taxCode' 
              label='税收分类编码' 
              rules={[{ required: true }]}
            >
              <Select 
                showSearch 
                optionFilterProp="label"
                options={taxCodes.map(({ k, v }) => ({ label: v, value: k }))} 
                onChange={handleTaxCode}
              />
            </Item>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <Item 
              name="goodsName" 
              label="开票商品名称" 
              rules={[{ required: true }]}
            >
              <Input />
            </Item>
          </Col>
          <Col span={12}>
            <Item name='taxShortName' label="税收分类编码简称">
              <DisplayInput />
            </Item>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <Item name="lossRate" label="损耗率">
              <InputNumber min={0} max={1} style={{ width: '100%' }} />
            </Item>
          </Col>
          <Col span={12}>
            <Item 
              label="税率(%)" 
              name='taxRate' 
              rules={[{ required: true, message: '请选择税率'}]}
            >
              <Select 
                showSearch 
                optionFilterProp="label"
                options={rates.map(({ k, v }) => ({ label: v, value: k }))} 
              />
            </Item>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <Item 
              label="享受税收优惠政策" 
              name='hasDiscountPolicy' 
              rules={[{ required: true, message: '请选择享受税收优惠政策'}]}
            >
              <Radio.Group 
                size="large"
                options={[
                  { label: '享受', value: true },
                  { label: '不享受', value: false }
                ]}
              />
            </Item>
          </Col>
          <Item shouldUpdate noStyle>
            {
              () => {
                return form.getFieldValue('hasDiscountPolicy')
                  ? <Col span={12}>
                      <Item 
                        label="税收优惠政策类型" 
                        name='policyId' 
                        rules={[{ required: true, message: '请选择税收优惠政策类型' }]}
                      >
                        <Select 
                          showSearch 
                          optionFilterProp="label"
                          options={policies.map(({ k, v }) => ({ label: v, value: k }))} 
                        />
                      </Item>
                    </Col>
                  : null
              }
            }
          </Item>
        </Row>
        <Row>
          <Col span={24} style={{ marginTop: 14, marginBottom: 14, textAlign: 'center' }}>
            <Space>
              <Button loading={loading} type="primary" htmlType="submit">确定</Button>
              <Button onClick={onClose}>取消</Button>
            </Space>
          </Col>
        </Row>
      </Form>
    </Card >
  );
});

export default AddOrEditMD;
