import React, { useEffect, useState } from 'react';
import { Form, Row, Col } from 'antd';
import { Card, DisplayInput } from '@comps'
import { Http } from '@utils'

const Item = Form.Item

const layout = {
    labelCol: { span: 12 },
    wrapperCol: { span: 12 },
};

export interface Props {
    onCancel: () => void,
    onConfirm: () => void,
    agent?: JSONObject,
}

const Stock: React.FC<Props> = ({
    onCancel, onConfirm, agent = {},
}) => {
    // e 电票 n普票 s专票 es 电子专票
    const [eForm] = Form.useForm();
    const [nForm] = Form.useForm();
    const [sForm] = Form.useForm();
    const [esForm] = Form.useForm();
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        Http.get(`/tax/agents/${agent.id}/stock`).then(data => {
            eForm.setFieldsValue(data.einvoice)
            nForm.setFieldsValue(data.normalInvoice)
            sForm.setFieldsValue(data.specialInvoice)
            esForm.setFieldsValue(data.especialInvoice)
        }).finally(() =>
            setLoading(false)
        )
    },
        // eslint-disable-next-line
        [])

    return (
        <Card title='发票库存' loading={loading}>
            <Form {...layout} form={eForm} layout='vertical'>
                <Row>
                    <Col span={5}>增值税电子发票</Col>
                    <Col span={5}><Item label="发票库存" name='invoiceStock'><DisplayInput /></Item></Col>
                    <Col span={7}><Item label="下一张发票代码" name='nextInvoiceCode'><DisplayInput /></Item></Col>
                    <Col span={7}><Item label="下一张发票号码" name='nextInvoiceNo'><DisplayInput /></Item></Col>
                </Row>
            </Form>
            <Form {...layout} form={esForm} layout='vertical'>
                <Row>
                    <Col span={5}>增值税电子专用发票</Col>
                    <Col span={5}><Item label="发票库存" name='invoiceStock'><DisplayInput /></Item></Col>
                    <Col span={7}><Item label="下一张发票代码" name='nextInvoiceCode'><DisplayInput /></Item></Col>
                    <Col span={7}><Item label="下一张发票号码" name='nextInvoiceNo'><DisplayInput /></Item></Col>
                </Row>
            </Form>
            <Form {...layout} form={nForm} layout='vertical'>
                <Row>
                    <Col span={5}>增值税普通发票</Col>
                    <Col span={5}><Item label="发票库存" name='invoiceStock'><DisplayInput /></Item></Col>
                    <Col span={7}><Item label="下一张发票代码" name='nextInvoiceCode'><DisplayInput /></Item></Col>
                    <Col span={7}><Item label="下一张发票号码" name='nextInvoiceNo'><DisplayInput /></Item></Col>
                </Row>
            </Form>
            <Form {...layout} form={sForm} layout='vertical'>
                <Row>
                    <Col span={5}>增值税专用发票</Col>
                    <Col span={5}><Item label="发票库存" name='invoiceStock'><DisplayInput /></Item></Col>
                    <Col span={7}><Item label="下一张发票代码" name='nextInvoiceCode'><DisplayInput /></Item></Col>
                    <Col span={7}><Item label="下一张发票号码" name='nextInvoiceNo'><DisplayInput /></Item></Col>
                </Row>
            </Form>
        </Card>
    );
};

export default Stock;
