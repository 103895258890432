import { Env, Context } from '@utils';

/**
 * 过滤无效（undefined 和 null）的表单对象属性
 * @param formData 原始表单对象
 * @returns 过滤后的表单对象
 */
const filterInvalidFormData = (formData: JSONObject): JSONObject => {
  const keys = Object.keys(formData);

  for (var key of keys) {
    const val = formData[key];
    
    if (typeof val === 'undefined') {
      delete formData[key];
    } else if (val === null) {
      delete formData[key];
    }
  }

  return formData;
};

/**
 * 根据表单信息导出 Excel 文件
 * @param URL 导出 URL
 * @param formData 表单对象
 */
const exportExcelFile = (URL: string, formData: JSONObject) => {
  let exportURL = `${Env.getBaseUrl()}${URL}?accessToken=${Context.getToken()}`;
  const filteredFormObj = filterInvalidFormData(formData);

  for (let key in filteredFormObj) {
    exportURL += `&${key}=${filteredFormObj[key]}`;
  }

  window.open(exportURL, '_blank');
};

/**
 * POST 方式导出文件
 * @param url URL
 * @param params 参数
 */
 const exportFileByPost = (url: string, params: JSONObject) => {
  const form = document.createElement('form');

  form.style.display = 'none';
  form.action = `${Env.getBaseUrl()}${url}?accessToken=${Context.getToken()}`;
  form.method = 'post';
  document.body.appendChild(form);

  for (let key in params) {
    const input = document.createElement('input');
    input.type = 'hidden';
    input.name = key;
    input.value = params[key];
    form.appendChild(input);
  }

  form.submit();
  form.remove();
};

/**
 * 布尔值转数字
 * @param formData 表单对象
 * @param keys 要转化的 key
 */
const booleanToNum = (formData: JSONObject, keys: string[]) => {
  keys.forEach(key => {
    formData[key] = formData[key] ? 1 : 0; 
  });
};

const Form = {
  filterInvalidFormData,
  exportExcelFile,
  exportFileByPost,
  booleanToNum,
};

export default Form;


