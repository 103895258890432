import React from 'react';
import { Form, Space, Radio, Row, Col, Select, InputNumber } from 'antd';

import { Card, Button, Modal } from '@comps';
import MergeOrSplitParentTable from '../component/mergeOrSplitParentTable';

import { Http } from '@utils';

import { OperationType } from '../interface';

const { Item } = Form;
const { Group } = Radio;
const { Option } = Select;

interface Props {
  ids: number[],
  refreshTable: () => void,
  onClose: () => void
};

const MergeDocMD: React.FC<Props> = ({
  ids,
  refreshTable,
  onClose
}) => {

  const [form] = Form.useForm();
  const [modal, modalHolder] = Modal.useModal();

  const checkIsCanMerge = async (mergerParams: JSONObject) => {
    return Http.post('/tax/orders/merger/line', { 
      ...mergerParams, 
      invId: ids[0] 
    });
  };

  const showMergePreviewMD = () => {
    checkIsCanMerge(form.getFieldsValue())
      .then((data: JSONObject) => {
        modal.open({
          width: 1400,
          content(_1, reject) {
            return (
              <MergeOrSplitParentTable
                operationType={OperationType.MERGE}
                dataSource={[data.invoice]}
                mergeId={data.confirmMergeID}
                splitId={0}
                onClose={reject}
                refreshTable={refreshTable}
                closeMergeTypeMD={onClose}
                closeSplitTypeMD={() => {}}
                closeSplitAmountMD={() => {}}
              />
            )
          }
        })
      })
  };

  return (
    <Card title="合并单据">
      {modalHolder}
      <Form
        form={form}
        initialValues={{ 
          specNull: false,
          numOneFlag: false, 
          mergerLineType: 1,
          pericePrecision: 2,
        }}
        style={{ marginTop: 20 }}
        labelCol={{ span: 6 }}
        onFinish={showMergePreviewMD}
      >
        <Row>
          <Col span={24}>
            <Item 
              label="规格型号为空" 
              name="specNull"
              rules={[{ required: true }]}
            >
              <Group>
                <Space size="large">
                  <Radio value={true}>是</Radio>
                  <Radio value={false}>否</Radio>
                </Space>
              </Group>
            </Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Item 
              label="全部数量为 1" 
              name="numOneFlag"
              rules={[{ required: true }]}
            >
              <Group>
                <Space size="large">
                  <Radio value={true}>是</Radio>
                  <Radio value={false}>否</Radio>
                </Space>
              </Group>
            </Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Item 
              label="合并类型"
              name="mergerLineType"
              rules={[{ required: true }]}
            >
              <Select>
                <Option value={1}>开票商品名称、规格型号、单位、单价</Option>
                <Option value={2}>开票商品名称、规格型号、单价</Option>
                <Option value={3}>开票商品名称、单位、单价</Option>
                <Option value={4}>开票商品名称、单价</Option>
                <Option value={5}>开票商品名称、规格型号、单位</Option>
                <Option value={6}>开票商品名称、规格型号</Option>
                <Option value={7}>开票商品名称、单位</Option>
                <Option value={8}>开票商品名称</Option>
              </Select>
            </Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Item
              label="单价精度"
              name="pericePrecision"
              rules={[{ required: true }]}
            >
              <InputNumber min={0} max={8} style={{ width: '100%' }} />
            </Item>
          </Col>
        </Row>
        <Row style={{ marginTop: 12 }}>
          <Col span={24} style={{ textAlign: 'center' }}>
            <Space size="large">
              <Button type="primary" htmlType="submit">合并预览</Button>
              <Button onClick={onClose}>取消</Button>
            </Space>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};

export default MergeDocMD;
