import { LinkText, Table } from "@comps";
import { ColumnProps } from "@comps/types";
import { Card as AdCard, Button, Form, Input } from "antd";
import * as React from "react";
import { useState } from "react";

interface Props {
  // ref: any;
  onClose: Function;
  handleChoose: Function;
}

const NameModal: React.FC<Props> = ({ onClose, handleChoose }, ref) => {
  const [dataSource, setDataSource] = useState<JSONObject[]>([]);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);

  // useImperativeHandle(ref, () => ({
  //   isNameModalShow() {
  //     console.log("99999999");
  //     setVisible(!visible);
  //   },
  // }));

  const columns: ColumnProps[] = [
    {
      key: "",
      title: "序号",
      width: 70,
      fixed: "left",
      render: (text, record, index) => index + 1,
      align: "center",
    },
    {
      key: "customerName",
      dataIndex: "customerName",
      width: 300,
      title: "客户名称",
      align: "left",
    },
    {
      key: "taxNo",
      dataIndex: "taxNo",
      title: "统一社会信用代码/纳税人识别号",
      width: 300,
      align: "left",
    },
    { key: "address", dataIndex: "address", title: "地址", align: "left" },
    { key: "tel", dataIndex: "tel", title: "电话", align: "left" },
    {
      key: "bankName",
      dataIndex: "bankName",
      title: "开户行",
      align: "left",
    },
    {
      key: "bankAccount",
      dataIndex: "bankAccount",
      title: "银行账号",
      align: "left",
      render: (text, record) => {
        const words = text?.split(" ");
        return words.length ? words[1] : "";
      },
    },
    // {
    //   key: "taxDate",
    //   dataIndex: "taxDate",
    //   title: "客户信息分类",
    //   align: "left",
    // },
    {
      key: "action",
      dataIndex: "taxDate",
      title: "操作",
      align: "left",
      fixed: "right",
      render: (text, record, index) => (
        <LinkText
          label="选择"
          onClick={() => {
            handleChoose(record);
            onClose();
          }}
        />
      ),
    },
  ];

  return (
    <>
      {/* <Drawer
        title="客户信息查询"
        placement="right"
        closable={false}
        onClose={() => setVisible(false)}
        visible={visible}
        width={1000}
      > */}
      <AdCard title="客户分类" loading={loading} style={{ marginBottom: "0" }}>
        <Table
          columns={columns}
          dataSource={dataSource}
          url="/customer"
          //   rowKey={record=>record.invNo}
          pagination={{
            showQuickJumper: true,
            showSizeChanger: true,
            // showTotal: (total) => (`共 ${total} 条`)
          }}
          scroll={{ y: window.innerHeight - 300 }}
        >
          <Form.Item label="客户名称" name="key">
            <Input placeholder="客户名称/纳税人识别号/简码" />
          </Form.Item>
        </Table>
        <div style={{ textAlign: "center", marginTop: 32 }}>
          <Button
            type="primary"
            onClick={() => onClose()}
            style={{ marginRight: "10px" }}
          >
            确认
          </Button>
          <Button onClick={() => onClose()}>关闭</Button>
        </div>
      </AdCard>
      {/* </Drawer> */}
    </>
  );
};

export default NameModal;
