import React, { useState } from 'react';
import { Card } from '@comps';
import { Form, Space, DatePicker, Button } from 'antd';
import { Http, Message } from '@utils';

export interface Props {
  type: string,
  title: string,
  statisticsData: JSONObject,
  queryData: JSONObject,
  onClose: () => void
}

const Item = Form.Item

const Secondary: React.FC<Props> = ({ onClose, type, title, statisticsData, queryData }: Props) => {
  const [loading, setLoading] = useState<boolean>(false)

  const fontColorStyle = { color: '#3C95F4' }

  const handleSecondary = async () => {
    setLoading(true)
    
    queryData.invoiceDateFromTo = queryData.invoiceDateFromTo.join()
    if (queryData.smartScreening) {
      queryData.smartScreening = queryData.smartScreening.join()
    }
  
    Http.post('/input/gx/fastGX', queryData)
      .then(res => {
        Message.info('操作完成')
      })
      .finally(() => {
        setLoading(false)
        onClose()
      })
  }

  return (
    <Card title={title}>
      <p style={{ fontSize: 14, marginTop: 16 }}>
        本次勾选<span style={fontColorStyle}> {statisticsData.totalQuantity} </span> 张，
        金额<span style={fontColorStyle}> {statisticsData.totalAmount} </span>元，
        税额<span style={fontColorStyle}> {statisticsData.totalTaxAmount} </span>元，
        有效税额<span style={fontColorStyle}> {statisticsData.totalValidTaxAmount} </span>元</p>
      <p style={{ fontSize: 14 }}>是否确认进行{title} ?</p>
      <Space size="large">
        <Button loading={loading} type="primary" onClick={handleSecondary}>确定</Button>
        <Button onClick={onClose}>取消</Button>
      </Space>
    </Card >)
}

export default Secondary
