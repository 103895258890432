import React, { useState, useEffect } from 'react';
import { Space, Button, Form, Input, Select, DatePicker } from 'antd';
import moment from 'moment';

import { Card, Modal } from '@comps';
import SelectRecipientsMD from './selectRecipients.md';

import { Http, Message } from '@utils';
import { RecipientType } from './interface';

const { Item } = Form;
const { Option } = Select;

export interface Props {
  isWaiting: boolean,
  invoiceIds: number[],
  mainOrderInfo: JSONObject,
  onClose: () => void
}

const MailOrder: React.FC<Props> = ({ isWaiting, mainOrderInfo, invoiceIds, onClose }: Props) => {
  const [form] = Form.useForm();
  const [isShowSelectBTN, setIsShowSelectBTN] = useState(true);
  const [isReadOnlyRecipient, setIsReadOnlyRecipient] = useState(false);
  const [loading, setLoading] = useState(false);

  const [modal, modalHolder] = Modal.useModal();

  const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 16, offset: 1 }
  }

  const buttonLayout = {
    wrapperCol: { span: 10, offset: 7 }
  }

  const showSelectRecipientsMD = () => {
    const params: JSONObject = {};
    const recipientType = form.getFieldValue('recipientType');

    params.recipientType = recipientType;
    params.orgName = form.getFieldValue('orgName');
    params.invoiceIds = invoiceIds;

    const setRecipientInfo = (info: JSONObject) => {
      form.setFieldsValue(info);
    }

    modal.open({
      width: '600px',
      ignoreError: true,
      content(resolve, reject) {
        return (
        <SelectRecipientsMD params={params} recipientType={recipientType} setRecipientsInfo={setRecipientInfo} onClose={reject} />);
      }
    });
  }

  const showMailOrderInfo = () => {
    mainOrderInfo.recipientType = RecipientType.CLIENT;
    form.setFieldsValue(mainOrderInfo);
  }

  const handleMailOrder = () => {
    setLoading(true);
    const formParam = form.getFieldsValue();
    formParam.invoiceIds = invoiceIds;
    formParam.isWaiting = isWaiting;
    formParam.sendStartTm = moment(formParam.sendStartTm).format('YYYY-MM-DD HH:mm:ss');

    Http.post('/tax/mailingManagement/placeAnOrderByMail', formParam)
      .then(() => {
        Message.success('邮寄下单成功');
      })
      .finally(() => {
        setLoading(false);
        onClose();
      })
  }

  const handleRecipientTypeSelect = (type: any) => {
    showSelectRecipientsMD();
    
    if (type === RecipientType.SALESMAN) { // 业务员
      setIsReadOnlyRecipient(true);
      setIsShowSelectBTN(false);
    } else if (type === RecipientType.REGIONAL_FINANCE) { // 大区财务
      setIsReadOnlyRecipient(true);
      setIsShowSelectBTN(true);
    } else { // 其他
      setIsReadOnlyRecipient(false);
      setIsShowSelectBTN(true);
    }
  } 

  useEffect(() => {
    showMailOrderInfo();
  }, []);

  return (
    <Card title="邮寄下单">
      {modalHolder}
      <Form form={form} 
            onFinish={handleMailOrder} 
            style={{ paddingLeft: 12, position: 'relative' }} 
            {...layout}>
        <br/>
        <h4 style={{ color: '#16295C' }}>寄件人信息</h4>
        <Item label='寄件人' name='sender' rules={[{ required: true }]}>
          <Input />
        </Item>
        <Item label='联系电话' name='senderTel' rules={[{ required: true }]}>
          <Input />
        </Item>
        <Item label='详细地址' name='senderAddress' rules={[{ required: true }]}>
          <Input.TextArea />
        </Item>

        <br/>
        <h4 style={{ color: '#16295C' }}>收件人信息</h4>
        <Item label='收件人类别' name='recipientType' rules={[{ required: true }]}>
          <Select onSelect={handleRecipientTypeSelect}>
            <Option value="DQCW">大区财务</Option>
            <Option value="YWY">业务员</Option>
            <Option value="KH">客户</Option>
          </Select>
        </Item>
        <Item label='公司名称' name='orgName'>
          <Input />
        </Item>
        <Item label='收件人' name='recipient' rules={[{ required: true }]} >
          <Input readOnly={isReadOnlyRecipient} style={{ width: '58%', marginRight: 20, border: isReadOnlyRecipient ? 'none' : '' }}  />
        </Item>
        {
          isShowSelectBTN 
          ? <Item style={{ position: 'absolute', top: 340, right: 18 }}>
              <Button type="primary" onClick={showSelectRecipientsMD}>选择</Button>
            </Item> 
          : null
        }
        <Item label='联系电话' name='recipientTel' rules={[{ required: true }]}>
          <Input />
        </Item>
        <Item label='详细地址' name='recipientAddress' rules={[{ required: true }]}>
          <Input.TextArea />
        </Item>
        <Item label='上门时间' name='sendStartTm' rules={[{ required: true }]}>
          <DatePicker showTime inputReadOnly/>
        </Item>
        <Item {...buttonLayout}>
          <Space size="large">
            <Button loading={loading} type="primary" htmlType="submit">确定</Button>
            <Button onClick={onClose}>取消</Button>
          </Space>
        </Item>
      </Form>
    </Card >)
}

export default MailOrder
