import React, { FC, useState, useMemo } from "react";
import {
  Space,
  Select,
  Table as AdTable,
  Row,
  Col,
  Form,
  DatePicker,
  Input,
  Modal as AdModal,
} from "antd";
import moment from "moment";

import { Page, Card, Table, PermissionButton, Modal } from "@comps";
import { Context, Math, Http, Env, Lang, Message } from "@utils";
import { ColumnProps } from "@comps/types";

import FullAmont from "./fullAmount.md";
import Proportion from "./proportion.md";

import "./index.css";

const { confirm } = AdModal;
const { Item } = Form;
const { Option } = Select;

const Certified: FC = () => {
  const preMonth: string = moment().subtract(1, "months").format("YYYYMM");
  const [period, setPeriod] = useState(preMonth);

  const initQuery = useMemo<JSONObject>(
    () => ({
      period: moment(preMonth),
    }),
    []
  );

  let [table, setTable] = useState<Table>();
  const [tableSummaryInfo, setTableSummaryInfo] = useState<JSONObject>({});

  const [fullAmountModal, fullAmountModalHolder] = Modal.useModal();
  const [proportionModal, proportionModalHolder] = Modal.useModal();

  const [cancelOutLoading, setCancelOutLoading] = useState(false);

  const onTableRef = (tableRef: Table) => {
    table = tableRef;
    setTable(tableRef);
  };

  const showExportAllConfirm = () => {
    confirm({
      title: "提示",
      content: "是否导出所有数据",
      onOk: handleExportAllData,
    });
  };

  const getTableSummaryInfo = async (queryObj: JSONObject) => {
    const summaryRes = await Http.get("/input/certifiedManagement", queryObj);
    setTableSummaryInfo(summaryRes);
  };

  const handleSplicingURL = () => {
    let URL = `${Env.getBaseUrl()}/input/certifiedManagement/exportCertfiedInvoice?accessToken=${Context.getToken()}`;

    // 导出时添加查询参数
    const queryParams: any = table?.getQueryPanelParams();

    // 实施期间传参时用 YYYYMM 格式
    const periodNum = moment(queryParams.period).format("YYYYMM");

    for (let key in queryParams) {
      if (key === "period") {
        URL += `&${key}=${periodNum}`;
      } else {
        URL += `&${key}=${queryParams[key]}`;
      }
    }

    return URL;
  };

  const handleExportAllData = () => {
    const URL = handleSplicingURL();
    window.open(URL, "_blank");
  };

  const handleExport = async () => {
    if (table?.getSelectedRowKeys()?.length === 0) {
      showExportAllConfirm();
      return;
    }

    let URL = handleSplicingURL();
    URL += `&ids=${table?.getSelectedRowKeys()?.join()}`;

    window.open(URL, "_blank");
  };

  const checkSelectedTransferState = (isTransfered: boolean): boolean => {
    let isAllTransferedOrNotTransfered = isTransfered;
    const selectedIsTransferedArr =
      table?.getSelectedRowAttr("transfered") || [];

    for (let i = 0; i < selectedIsTransferedArr.length; i++) {
      if (selectedIsTransferedArr[i] !== isTransfered) {
        isAllTransferedOrNotTransfered = !isTransfered;
        break;
      }
    }

    return isAllTransferedOrNotTransfered;
  };

  const handleFullAmountOut = React.useCallback(
    () =>
      Lang.noError(async () => {
        const IDsArr = table?.getSelectedRowKeys() || [];
        if (IDsArr.length === 0) {
          Message.warn("请至少勾选一条数据");
          return;
        }

        if (checkSelectedTransferState(false)) {
          Message.warn("勾选的数据转出状态必须全为 [ 未转出 ]");
          return;
        }

        const IDs = IDsArr.join();
        const tableData = table?.getTableData();
        const selectedTableData: JSONObject[] = tableData.filter(
          (row: JSONObject) => {
            return IDsArr?.includes(row.id);
          }
        );

        let totalTaxAmount = 0;
        for (let row of selectedTableData) {
          totalTaxAmount += row.validTaxAmount;
        }

        await fullAmountModal.open({
          width: "400px",
          ignoreError: false,
          content(resolve, reject) {
            return (
              <FullAmont
                taxAmount={totalTaxAmount}
                IDs={IDs}
                refreshTable={() => table?.refresh()}
                onClose={reject}
              />
            );
          },
        });
      }),
    [fullAmountModal]
  );

  const handleOpenProportionOutModal = () => {
    const selectedIDs = table?.getSelectedRowKeys();
    if (selectedIDs?.length !== 1) {
      Message.warn("请至少勾选一条数据");
      return;
    }
    handleProportionOut();
  };

  const handleProportionOut = React.useCallback(
    () =>
      Lang.noError(async () => {
        const IDsArr: any = table?.getSelectedRowKeys();

        let taxAmount = 0;
        const tableData: JSONObject[] = table?.getTableData();

        for (let i = 0; i < tableData.length; i++) {
          if (tableData[i].id === IDsArr[0]) {
            taxAmount = tableData[i].validTaxAmount;
            break;
          }
        }

        await proportionModal.open({
          width: "400px",
          ignoreError: false,
          content(resolve, reject) {
            return (
              <Proportion
                taxAmount={taxAmount}
                refreshTable={() => table?.refresh()}
                IDs={IDsArr[0]}
                onClose={reject}
              />
            );
          },
        });
      }),
    [proportionModal]
  );

  const handleCancleOut = () => {
    const IDs = table?.getSelectedRowKeys();
    if (IDs?.length === 0) {
      Message.warn("请至少勾选一条数据");
      return;
    }

    if (!checkSelectedTransferState(true)) {
      Message.warn("勾选的数据转出状态必须全为 [ 已转出 ]");
      return;
    }

    setCancelOutLoading(true);

    Http.get("/input/certifiedManagement/cancelInvoiceTransferOut", {
      ids: IDs?.join(),
    })
      .then((res) => {
        Message.success("取消转出成功");
        table?.refresh();
      })
      .finally(() => {
        setCancelOutLoading(false);
      });
  };

  const handlePeriodChange = (date: any, dateString: string) => {
    setPeriod(dateString);
  };

  const handleClearQuerySpecialFormat = () => {
    const empVal = "";
    setPeriod(empVal);
  };

  const columns: ColumnProps[] = [
    { key: "invoiceNo", title: "发票号码", width: 140, fixed: "left" },
    { key: "invoiceCode", title: "发票代码", width: 140, fixed: "left" },
    {
      key: "invoiceType",
      title: "发票类型",
      width: 200,
      render: (type) => {
        type = parseInt(type);
        if (type === 1) {
          return "增值税专用发票";
        } else if (type === 2) {
          return "货运运输业增值税专用发票";
        } else if (type === 3) {
          return "机动车增值税专用发票";
        } else if (type === 4) {
          return "增值税普通发票";
        } else if (type === 10) {
          return "电子普通发票";
        } else if (type === 11) {
          return "卷式发票";
        } else if (type === 13) {
          return "增值税电子专用发票";
        } else if (type === 14) {
          return "通行费发票";
        } else if (type === 15) {
          return "二手车发票";
        } else if (type === 16) {
          return "成品油发票";
        } else if (type === 17) {
          return "火车票";
        } else if (type === 18) {
          return "客运汽车票";
        } else if (type === 19) {
          return "航空运输电子行程票";
        } else if (type === 20) {
          return "出租车票";
        } else if (type === 21) {
          return "船票";
        } else if (type === 22) {
          return "过桥、过闸通行费";
        } else if (type === 23) {
          return "通用机打发票";
        } else if (type === 24) {
          return "定额发票";
        } else if (type === 99) {
          return "其他";
        } else {
          return "";
        }
      },
    },
    { key: "invoiceDate", title: "开票日期", width: 120 },
    { key: "buyerName", title: "购方名称", width: 200 },
    { key: "sellerName", title: "销方名称", width: 200 },
    { key: "invoiceAmount", title: "不含税金额", width: 120, format: "amount" },
    { key: "taxAmount", title: "税额", width: 120, format: "amount" },
    {
      key: "validTaxAmount",
      title: "认证有效税额",
      width: 120,
      format: "amount",
    },
    {
      key: "totalAmount",
      title: "含税金额",
      width: 120,
      format: "amount",
      align: "center",
    },
    { key: "gxTime", title: "勾选时间", width: 200 },
    { key: "rzTime", title: "认证时间", width: 200 },
    { key: "rzPeriod", title: "认证所属期", width: 100 },
    {
      key: "transferedTaxAmount",
      title: "转出税额",
      width: 120,
      format: "amount",
      align: "center",
    },
    { key: "deductionFlag", title: "是否可抵扣", width: 140, align: "center" },
    {
      key: "transferedReson",
      title: "转出原因",
      width: 200,
      render: (reasonType) => {
        if (reasonType === "01") {
          return "免税项目用";
        } else if (reasonType === "02") {
          return "集体福利";
        } else if (reasonType === "03") {
          return "个人消费";
        } else if (reasonType === "04") {
          return "非正常损失";
        } else if (reasonType === "10") {
          return "简易计税方法征税项目用";
        } else if (reasonType === "11") {
          return "免抵退税办法不得抵扣的进项税额";
        } else if (reasonType === "14") {
          return "纳税检查调整抵减欠税";
        } else if (reasonType === "15") {
          return "红字专用发票信息表注明的进项税额";
        } else if (reasonType === "99") {
          return "其他";
        } else {
          return "";
        }
      },
    },
    { key: "transferedPeriod", title: "转出日期", width: 100 },
    {
      key: "transfered",
      title: "转出状态",
      width: 160,
      fixed: "right",
      align: "center",
      render: (isTransfered) => {
        if (isTransfered) {
          return "已转出";
        } else {
          return "未转出";
        }
      },
    },
  ];

  return (
    <Page>
      {fullAmountModalHolder}
      {proportionModalHolder}

      <Card
        title="已认证发票管理"
        actions={
          <Space className="card-space">
            <PermissionButton
              permissions="CertifiedManagement.invoiceTransferOut"
              type="primary"
              onClick={handleFullAmountOut}
            >
              全额转出
            </PermissionButton>

            <PermissionButton
              permissions="CertifiedManagement.invoiceTransferOut"
              type="primary"
              onClick={handleOpenProportionOutModal}
            >
              按比例转出
            </PermissionButton>

            <PermissionButton
              permissions="CertifiedManagement.cancelInvoiceTransferOut"
              loading={cancelOutLoading}
              type="primary"
              onClick={handleCancleOut}
            >
              取消转出
            </PermissionButton>

            {/* <Button type="primary">显示调整</Button> */}
            <PermissionButton
              permissions="CertifiedManagement.exportInputGX"
              type="primary"
              onClick={handleExport}
            >
              导出
            </PermissionButton>
          </Space>
        }
      >
        <Table
          url="/input/certifiedManagement"
          queryProps={{
            initialValues: initQuery,
          }}
          selectedRowKeys={[]}
          pagination={{ hideOnSinglePage: false }}
          columns={columns}
          onTableRef={onTableRef}
          getTableSummaryInfo={getTableSummaryInfo}
          querySpecialFormat={{ period }}
          clearQuerySpecialFormat={handleClearQuerySpecialFormat}
          scroll={{ y: window.innerHeight - 300 }}
          summary={(invoices, selectIds) => {
            // 选中的数据
            const selectInvoices = invoices.filter((invoice) =>
              selectIds?.includes(invoice.id)
            );
            const invoiceTotal = selectInvoices.reduce(
              (total, invoice) => {
                total.invoiceAmount += invoice.invoiceAmount;
                total.taxAmount += invoice.validTaxAmount;
                total.totalAmount += invoice.transferedTaxAmount;
                return total;
              },
              {
                invoiceAmount: 0,
                taxAmount: 0,
                totalAmount: 0,
              }
            );

            return (
              <>
                <AdTable.Summary.Row style={{ height: 36 }}>
                  <Row
                    gutter={14}
                    style={{
                      position: "absolute",
                      left: "10px",
                      right: "0",
                      zIndex: 100,
                    }}
                  >
                    <Col span={6}>选中数量: {selectInvoices?.length || 0}</Col>
                    <Col span={6}>
                      选中不含税金额:{" "}
                      {Math.num2money(invoiceTotal.invoiceAmount)}
                    </Col>
                    <Col span={6}>
                      选中有效税额: {Math.num2money(invoiceTotal.taxAmount)}
                    </Col>
                    <Col span={6}>
                      选中转出税额: {Math.num2money(invoiceTotal.totalAmount)}
                    </Col>
                  </Row>
                </AdTable.Summary.Row>

                <AdTable.Summary.Row style={{ height: 36 }}>
                  <Row
                    gutter={14}
                    style={{
                      position: "absolute",
                      left: "10px",
                      right: "0",
                      zIndex: 100,
                    }}
                  >
                    <Col span={6}>
                      全部数量: {tableSummaryInfo.totalQuantity}
                    </Col>
                    <Col span={6}>
                      全部不含税金额:{" "}
                      {Math.num2money(tableSummaryInfo.totalAmount)}
                    </Col>
                    <Col span={6}>
                      全部有效税额:{" "}
                      {Math.num2money(tableSummaryInfo.totalValidTaxAmount)}
                    </Col>
                    <Col span={6}>
                      全部转出税额:{" "}
                      {Math.num2money(
                        tableSummaryInfo.totalTransferedTaxAmount
                      )}
                    </Col>
                  </Row>
                </AdTable.Summary.Row>
              </>
            );
          }}
        >
          <Item name="key">
            <Input placeholder="发票代码/号码/销方名称" />
          </Item>
          <Item name="period">
            <DatePicker
              picker="month"
              placeholder="所属期间"
              format="YYYYMM"
              onChange={handlePeriodChange}
              style={{ width: "100%" }}
              inputReadOnly
            />
          </Item>
          <Item name="invoiceType">
            <Select placeholder="发票类型">
              <Option value="">全部</Option>
              <Option value="01">增值税专用发票</Option>
              <Option value="13">增值税电子专用发票</Option>
              <Option value="14">通行费发票</Option>
              <Option value="03">机动车销售统一发票</Option>
            </Select>
          </Item>
          <Item name="transfered">
            <Select placeholder="转出状态" allowClear>
              <Option value="false">未转出</Option>
              <Option value="true">已转出</Option>
            </Select>
          </Item>
          <Item name="deductionFlag">
            <Select placeholder="是否可抵扣" allowClear>
              <Option value={1}>是</Option>
              <Option value={0}>否</Option>
            </Select>
          </Item>
        </Table>
      </Card>
    </Page>
  );
};

export default Certified;
