import { Button, Card, DisplayInput } from "@comps";
import { Http } from "@utils";
import { Col, Form, Input, Row, Space, message } from "antd";
import * as React from "react";
import { useEffect, useState } from "react";

export interface Props {
  type: "add" | "edit";
  onClose: () => void;
  refresh: any;
  data: JSONObject;
}

const AddOrEdit: React.FC<Props> = ({ type, onClose, refresh, data = {} }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const { Item } = Form;

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };
  useEffect(() => {
    if (type === "edit") {
      form.setFieldsValue(data);
    }
  }, []);
  const handleSave = () => {
    setLoading(true);
    form.validateFields().then((values) => {
      if (type === "edit") {
        values["id"] = data?.id;
      }
      let params = {
        id: data?.id,
        deptUrl: values?.deptUrl,
        deptName: values?.deptName,
      };
      Http.put("/org/depts", params)
        .then((res) => {
          message.success("操作成功");
          onClose();
          refresh();
        })
        .finally(() => {
          setLoading(false);
        });
    });
  };
  return (
    <Card
      title={type === "edit" ? "修改" : "新增"}
      style={{ marginBottom: "0" }}
    >
      <Form {...layout} form={form} initialValues={data}>
        <Row>
          <Col span={12}>
            <Item label="公司名称" name="orgName">
              <DisplayInput />
            </Item>
          </Col>
          <Col span={12}>
            <Item label="门店名称" name="deptName">
              <DisplayInput />
            </Item>
          </Col>
          <Col span={12}>
            <Item label="门店编号" name="deptCode">
              <DisplayInput />
            </Item>
          </Col>
          <Col span={12}>
            <Item
              label="门店URL"
              name="deptUrl"
              rules={[
                {
                  required: true,
                  message: "请输入门店URL",
                },
              ]}
            >
              <Input placeholder="请输入门店URL" style={{ width: "100%" }} />
            </Item>
          </Col>
        </Row>
        <div style={{ textAlign: "center" }}>
          <Space>
            <Button type="primary" loading={loading} onClick={handleSave}>
              保存
            </Button>
            <Button onClick={onClose}>取消</Button>
          </Space>
        </div>
      </Form>
    </Card>
  );
};

export default AddOrEdit;
