import React, { forwardRef, Fragment, memo, useEffect, useImperativeHandle, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { UploadOutlined } from '@ant-design/icons'
import { Lookup, Table } from '@comps'
import { ColumnProps } from '@comps/types'
import { UserContext } from '@models'
import { Api, Http } from '@utils'
import { Button, Col, DatePicker, Form, Input, message, Modal, Row, Select, Upload } from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import moment from 'moment'



const disabledDate = (current: any) => current && current > moment().endOf('day')
const { RangePicker } = DatePicker

let columns: ColumnProps[] = [
  { title: "移交人", key: "transferPeople", width: 150 },
  { title: "交接人", key: "connectPeople", width: 150 },
  { title: "交接日期", key: "connectDateCn", width: 150 },
  {
    title: "备注", key: "remark", width: 150, render: (id, row) => {
      return (row.remark !== undefined || row.remark !== null) && <span>{row.remark}</span>
    }
  },
]

let fileColumns: ColumnProps[] = [
  { title: "文件名称", key: "attachmentName", width: 150 },
  { title: "上传时间", key: "creationTime", width: 150 },
  { title: "文件类型", key: "attachmentType", width: 150 },
  { title: "文件大小", key: "attachmentSize", width: 150 },
]

let fileChangeColumns: ColumnProps[] = [
  {
    title: "变更内容", key: "changeContent", width: 200, render: (id, row) => {
      return row.changeContent === 1 ? <span>档案信息</span> : <span>档案附件</span>
    }
  },
  { title: "变更人", key: "operatePeople", width: 200 },
  { title: "变更时间", key: "changeDate", width: 200 },
]

interface parmas {
  visibleVaule: boolean, title: string, action?: number, type?: string | undefined, id?: number | undefined, status?: number | undefined,
  orgId?: number | undefined, orgName?: string
}

const MeansActionModal = forwardRef((props: any, ref) => {
  const refreshTable = props.refresh
  const userInfo = useSelector<JSONObject, UserContext>((state) => state.ctx)
  const [parmas, setParmas] = useState<parmas>({ visibleVaule: false, title: '', action: 0, type: undefined, id: undefined, orgId: undefined })
  const [option, setOption] = useState([])
  const [fileList, setFileList] = useState<any[]>([]);
  const [form] = Form.useForm()
  let [table, setTable] = useState<Table>();
  let [peopleTable, setPeopleTable] = useState<Table>();
  let [fileTable, setFileTable] = useState<Table>()
  const fileListRef: any = useRef([])
  const uploadRef: any = useRef()
  const fileNum = useRef<number>(0)


  useImperativeHandle(ref, () => ({
    openModal
  }));


  useEffect(() => {
    Api.getKv('/sys/users/kv?includeMobile=false')
      .then((res: any) => {
        setOption(res);
      })
  }, [])

  useEffect(() => {
    if (parmas.id && parmas.visibleVaule === true) {
      Http.get(`/tax/means/${parmas.id}`).then(res => {
        setFormData(res)

      })
    } else {
      form.resetFields()
    }
  }, [parmas.id, parmas.visibleVaule])

  const TableRef = (tableRef: Table) => {
    table = tableRef;
    setTable(tableRef);
  }

  const peopleTableRef = (tableRef: Table) => {
    peopleTable = tableRef;
    setPeopleTable(tableRef);
  }

  const fileTableRef = (tableRef: Table) => {
    fileTable = tableRef;
    setFileTable(tableRef);
  }

  const saveFiles = () => {
    form.validateFields().then(async () => {
      const data = await getParams()
      // action :0 是新增， 1 是查看 ，2是编辑   
      if (parmas.action === 0) {
        await Http.post('/tax/means', data)
      } else {
        await Http.put('/tax/means', data)
      }
      closeModal()
      fileListRef.current = []
      refreshTable()
      message.success('保存成功')
    })

  }

  const sumbitFIles = () => {
    form.validateFields().then(async () => {
      const data = await getParams()
      data.status = 1
      if (parmas.action === 2) {
        await Http.put('/tax/means', data)
      } else {
        await Http.post('/tax/means', data)
      }
      closeModal()
      fileListRef.current = []
      refreshTable()
      message.success('发布成功')

    })
  }

  //上传文件时的处理
  const uploadOk = async () => {
    const newfileList = fileList.filter((item: {
      uid: any
    }) => !fileListRef.current.some((ele: { id: any; }) => ele.id === item.uid));
    if (fileList.length === 0) return
    for (const key in newfileList) {
      const formData = new FormData();
      formData.append('file', newfileList[key]);
      await Http.postWithFormFile("/cms/files",
        formData
      ).then((res: any) => {
        fileListRef.current.push({
          attachmentName: res.fileName,
          attachmentSize: res.humanFileSize,
          attachmentStoragePath: res.storagePath,
          attachmentType: res.fileType,
          attachmentUrl: res.fileUrl,
          id: res.id,
          uploadDate: moment().format('YYYY-MM-DD')
        })

      })
    }
  }

  const customRequest = (options: any) => {
    const { file } = options
    setFileList([...fileList, file])
    const index = file.name.lastIndexOf('.')
    const name = file.name.slice(index + 1, file.name.length);
    const newFile = {
      id: file.uid,
      attachmentName: file.name,
      creationTime: moment().format('YYYY-MM-DD HH:mm'),
      attachmentType: name,
      attachmentSize: sizeTostr(file.size)
    }
    const dataSource = table?.getTableData()
    fileNum.current = fileNum.current + 1
    form.setFieldsValue({
      fileNumber: fileNum.current
    })
    table?.setDataSource([...dataSource, newFile])
  }

  const removeFile = async () => {
    const dataSource = table?.getTableData()
    const delId: any = table?.getSelectedRowKeys()
    if (delId.length === 0) return message.warn("至少选择一条数据")
    const newDataSource = dataSource.filter((item: any) => {
      return !(delId?.some((temId: any) => temId === item.id))
    })
    if (parmas.action === 2) {
      fileListRef.current = fileListRef.current.filter((item: any) => {
        return !(delId?.some((temId: any) => temId === item.id))
      })
    }
    const newFileData = fileList.filter((item: any) => {
      return !(delId?.some((temId: any) => temId === item.uid))
    })
    fileNum.current = newFileData?.length
    form.setFieldsValue({
      fileNumber: fileNum.current
    })
    await setFileList(newFileData)
    table?.setDataSource(newDataSource)
    message.success('移除成功')
  }

  //获取表单字段
  const getParams = async () => {
    await uploadOk()
    const formData = form.getFieldsValue()
    let data = {
      fileNumber: Number(formData.fileNumber),
      content: formData.content,
      chargePeople: formData.chargePeople,
      establishDate: moment(formData.establishDate).format('YYYY-MM-DD'),
      establishPeople: formData.establishPeople || userInfo.fullName,
      id: parmas.action === 0 ? undefined : parmas.id,
      meansName: formData.meansName,
      orgName: formData.orgName,
      status: 0,
      fileCabinetId: Number(parmas.type),
      documentAttachmentList: fileListRef.current,
      orgId: parmas.orgId || undefined
    }
    if (data.chargePeople !== userInfo.fullName && data.chargePeople) {
      const objOption: any = option.find((item: any) => { return item.value === formData.chargePeople })
      if (objOption !== undefined) data.chargePeople = objOption?.label
    }
    if (parmas.type === '2') {
      data = { ...data, ...{ expirationDateStart: moment(formData.date[0]).format('YYYY-MM-DD'), expirationDateEnd: moment(formData.date[1]).format('YYYY-MM-DD') } }
    } else if (parmas.type === '3') {
      data = {
        ...data, ...{
          taxType: formData.taxType,
          declareDateStart: moment(formData?.declareDate[0]).format('YYYY-MM-DD'),
          declareDateEnd: moment(formData?.declareDate[1]).format('YYYY-MM-DD'),
        }
      }
    } else if (parmas.type === '4') {
      data = {
        ...data, ...{
          taxType: formData.taxType, dutyPaidProof: formData.dutyPaidProof,
          payInDate: moment(formData.payInDate).format('YYYY-MM-DD'),
        }
      }
    } else {
      data = { ...data, ...{ expirationDateStart: moment(formData.date[0]).format('YYYY-MM-DD'), expirationDateEnd: moment(formData.date[1]).format('YYYY-MM-DD') } }
    }

    return data

  }

  // 设置表单字段
  const setFormData = (data: any) => {
    form.setFieldsValue({
      fileNumber: data.fileNumber,
      content: data.content,
      chargePeople: data.chargePeople,
      declareDate: data.declareDateStart || data.ddeclareDateEnd ? [moment(data.declareDateStart), moment(data.ddeclareDateEnd)] : [],
      establishDate: moment(data.establishDate),
      establishPeople: data.establishPeople,
      date: data.expirationDateStart || data.expirationDateEnd ? [moment(data.expirationDateStart), moment(data.expirationDateEnd)] : [],
      meansName: data.meansName,
      orgName: data.orgName,
      payInDate: moment(data.payInDate),
      dutyPaidProof: data.dutyPaidProof,
      taxType: data.taxType,
    })
    if (data.documentAttachmentList.length !== 0) {
      fileListRef.current = [...fileListRef.current, ...data.documentAttachmentList]
      const fileList = data.documentAttachmentList?.map((item: { id: any; attachmentName: any; }) => {
        return { uid: item.id, name: item.attachmentName }
      })
      fileNum.current = data.documentAttachmentList.length
      setFileList(fileList)
    }
    table?.setDataSource(data.documentAttachmentList)
    peopleTable?.setDataSource(data.chargePeopleList)
    fileTable?.setDataSource(data.changeRecordList)
  }

  const openModal = (obj: parmas) => {
    if (obj.action === 0) {
      form.setFieldsValue({ orgName: obj.orgName })
      table?.setDataSource([])
      peopleTable?.setDataSource([])
    }
    setParmas(obj)
  }

  const closeModal = (flag?: boolean) => {
    fileListRef.current = []
    fileNum.current = 0
    setFileList([])
    if (parmas.action === 0) {
      form.resetFields()

    }
    setParmas({ ...parmas, visibleVaule: false })
  }

  const sizeTostr = (size: number) => {
    var data = "";
    if (size < 0.1 * 1024) { //如果小于0.1KB转化成B  
      data = size.toFixed(2) + "B";
    } else if (size < 0.1 * 1024 * 1024) {//如果小于0.1MB转化成KB  
      data = (size / 1024).toFixed(2) + "KB";
    } else if (size < 0.1 * 1024 * 1024 * 1024) { //如果小于0.1GB转化成MB  
      data = (size / (1024 * 1024)).toFixed(2) + "MB";
    } else { //其他转化成GB  
      data = (size / (1024 * 1024 * 1024)).toFixed(2) + "GB";
    }
    var sizestr = data + "";
    var len = sizestr.indexOf("\.");
    var dec = sizestr.substr(len + 1, 2);
    if (dec == "00") {//当小数点后为00时 去掉小数部分  
      return sizestr.substring(0, len) + sizestr.substr(len + 3, 2);
    }
    return sizestr;
  }

  const checkFileSize = (file: any) => {
    return file.size / 1024 / 1024 < 50;
  }

  return (
    <Modal visible={parmas.visibleVaule} title={parmas.title}
      width={'800px'} onCancel={() => { closeModal(false) }} onOk={sumbitFIles}
      footer={
        parmas.id && parmas.action === 1 ?
          [
            <Button key="back" onClick={() => { closeModal(false) }}>
              取消
            </Button>,
          ]
          :
          [(parmas.id === undefined || parmas.status === 0) && <Button type="primary" onClick={saveFiles}>存为草稿</Button>,
          <Button key="sumbit" type="primary" onClick={sumbitFIles}>
            发布
          </Button>,
          <Button key="back" onClick={() => { closeModal(false) }}>
            取消
          </Button>,

          ]
      }
    >
      <Form layout='horizontal' form={form} initialValues={{
        establishPeople: userInfo.fullName,
        chargePeople: userInfo.fullName, establishDate: moment(),
        orgName: parmas.orgName,
        attachmentQuantity: 0
      }}>
        <Row>
          <Col span={12}>
            <Form.Item name="orgName" label="公司名称：" labelCol={{ span: 6 }} wrapperCol={{ span: 16 }}
              rules={[{ required: true, message: "请填写公司名称" }]}>
              <Input disabled={true} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="meansName" label="档案名称：" labelCol={{ span: 6 }} wrapperCol={{ span: 16 }}
              rules={[{ required: true, message: "请填写档案名称" }]}>
              <Input />
            </Form.Item>
          </Col>
        </Row>
        {(parmas.type === '3' || parmas.type === '4') &&
          <Row>
            <Col span={12}>
              <Form.Item name="taxType" label="申报税种：" labelCol={{ span: 6 }} wrapperCol={{ span: 16 }}
                rules={[{ required: true, message: "请选择申报税种" }]}>
                <Lookup lookupType="TAX_TYPE" placeholder="税种" ></Lookup>
              </Form.Item>
            </Col>
            {parmas.type === '3' &&
              <Col span={12}>
                <Form.Item name="declareDate" label="申报所属期：" labelCol={{ span: 6 }} wrapperCol={{ span: 16 }}
                  rules={[{ required: true, message: "请选择申报所属期" }]}>
                  <RangePicker format="YYYY-MM-DD" inputReadOnly style={{ width: '100%' }} ></RangePicker>
                </Form.Item>
              </Col>
            }
            {parmas.type === '4' &&
              <Col span={12}>
                <Form.Item name="dutyPaidProof" label="完税凭证号" labelCol={{ span: 6 }} wrapperCol={{ span: 16 }}
                  rules={[{ required: true, message: "请填写完税凭证号" }]}>
                  <Input />
                </Form.Item>
              </Col>
            }
          </Row>
        }
        <Row>
          <Col span={12}>
            <Form.Item name="fileNumber" label="附件数量：" labelCol={{ span: 6 }} wrapperCol={{ span: 16 }}
              rules={[{ pattern: /^[0-9]+$/, message: '数量只能为正整数或0' }]}>
              <Input style={{ width: '100%' }} disabled={true} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="establishPeople" label="建档人：" labelCol={{ span: 6 }} wrapperCol={{ span: 16 }}
              rules={[{ required: true, message: "请填写建档人" }]}>
              <Input disabled={true} />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <Form.Item name="establishDate" label="建档时间：" labelCol={{ span: 6 }} wrapperCol={{ span: 16 }}
              rules={[{ required: true, message: "请选择建档时间" }]}>
              <DatePicker format="YYYY-MM-DD" disabledDate={disabledDate} disabled={true} style={{ width: '100%' }} />
            </Form.Item>
          </Col>
          {(parmas.type !== '3' && parmas.type !== '4') &&
            <Col span={12}>
              <Form.Item name="date" label={parmas.type === '0' || parmas.type === undefined ? '证件有效期：' : '档案有效期'} labelCol={{ span: 6 }} wrapperCol={{ span: 16 }}
                rules={[{ required: true, message: "请填写有效期" }]}>
                <RangePicker format="YYYY-MM-DD" inputReadOnly ></RangePicker>
              </Form.Item>
            </Col>
          }
          <Col span={12}>
            <Form.Item name="chargePeople" label="当前负责人：" labelCol={{ span: 6 }} wrapperCol={{ span: 16 }}
              rules={[{ required: true, message: "请填写当前负责人" }]}>
              <Select options={option} showSearch optionFilterProp="label" allowClear />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          {parmas.type === '4' &&
            <Col span={12}>
              <Form.Item name="payInDate" label="缴款日期：" labelCol={{ span: 6 }} wrapperCol={{ span: 16 }}
                rules={[{ required: true, message: "请选择缴款日期" }]} >
                <DatePicker format="YYYY-MM-DD" inputReadOnly style={{ width: '100%' }}></DatePicker>
              </Form.Item>
            </Col>
          }
        </Row>
        <Form.Item name="content" label="内容简介：" labelCol={{ span: 3 }}>
          <TextArea rows={4} placeholder="内容简介" />
        </Form.Item>
        <Form.Item label="上传资料详情:" style={{ marginTop: '10px' }}>
          {parmas.action !== 1 &&
            <Fragment>
              <Upload accept="*" customRequest={customRequest} showUploadList={false}
                beforeUpload={checkFileSize} ref={uploadRef} fileList={fileList} multiple={true}
              >
                <Button icon={<UploadOutlined />}>上传</Button>
              </Upload>
              <Button style={{ marginLeft: '20px' }} onClick={removeFile}>删除</Button>
            </Fragment>
          }
        </Form.Item>
        <Table
          columns={fileColumns}
          pagination={false}
          onTableRef={TableRef}
          selectedRowKeys={[]}
          rowKey={'id'}
        ></Table>
        <Form.Item label="负责人员变更记录：" >
        </Form.Item>
        <Table columns={columns} pagination={false} onTableRef={peopleTableRef} rowKey={'id'}  ></Table>
        <Form.Item label="档案变更记录：" >
        </Form.Item>
        <Table columns={fileChangeColumns} pagination={false} onTableRef={fileTableRef} rowKey={'id'} ></Table>
        <Row style={{ marginTop: '20px', marginRight: '20px' }}></Row>
        <Row style={{ marginTop: '20px', marginRight: '20px' }}>
        </Row>
      </Form>
    </Modal>
  )

})


export default memo(MeansActionModal)