import React, { FC, useEffect, useRef, useState } from 'react'
import { Download, LinkText, Page, Modal, Card } from '@comps';
import { Context, Http } from '@utils'
import { Button, Col, Form, message, Row, Space } from 'antd';
import AnalysisList from "./component/analysisList"
import TextArea from 'antd/lib/input/TextArea';
import { useSelector } from 'react-redux';
import { UserContext } from '@models';

import moment from 'moment';

const { Item } = Form

const KnowledgeContent: FC = (props: any) => {

  const id = props.match.params.id;
  const userInfo = useSelector<JSONObject, UserContext>((state) => state.ctx);
  const [modal, modalHolder] = Modal.useModal()
  const [knowledge, setKnowledge] = useState<JSONObject>();
  const [time, setTime] = useState<any>(10)//倒计时时间
  const [textAreaValue, setTextAreaValue] = useState<any>()
  const timeRef = useRef<any>();//设置定时器
  useEffect(() => {
    getKonwledgeDetail(true)
  }, [id])

  //倒计时
  useEffect(() => {
    //判断倒计时时间
    if (time && time !== 0) {
      timeRef.current = setTimeout(() => {
        setTime((time: number) => time - 1)
      }, 1000)
    } else {
      Http.post(`/knowledge/read/${id}`).then((res) => {
        message.success('阅读量+1')
      })
    }

    //清除定时器
    return () => {
      clearTimeout(timeRef.current)
    }
  }, [time])

  const getKonwledgeDetail = (flag?: boolean) => {
    Http.get(`/knowledge/${id}`).then((res) => {
      res.correlationList = []
      for (let i in res.correlationMap) {
        res.correlationList.push({ id: i, value: res.correlationMap[i] })
      }
      setKnowledge(res)
      flag === true && setTextAreaValue(res?.analysisDraft?.analysisContent)
    })
  }

  const setKnowledgeTop = async () => {
    await Http.post(`/top/${id}`)
    message.success('置顶成功')
    getKonwledgeDetail()
  }

  const cancleKnowledgeTop = async () => {
    if (knowledge?.whetherTop === 1) {
      await Http.del(`/top/${id}`)
      message.success('取消置顶成功')
      getKonwledgeDetail()
    } else {
      return message.warning('已经取消置顶了')
    }
  }

  const saveAnalysis = async () => {
    if (textAreaValue === '' || textAreaValue === undefined) return message.warning("内容不能为空")
    const obj = {
      analysisBy: userInfo.userId,
      analysisContent: textAreaValue ? textAreaValue : knowledge?.analysisDraft?.analysisContent,
      analysisDate: moment().format("YYYY-MM-DD"),
      analysisName: userInfo.fullName,
      knowledgeId: id,
      // status: 0,
      type: 1,
      id: undefined
    }
    if (knowledge?.analysisDraft) {
      obj.id = knowledge?.analysisDraft.id
      await Http.put(`/analysis`, obj)
    } else {
      await Http.post(`/analysis`, obj)
    }
    message.success('存置草稿成功')
    getKonwledgeDetail(true)
  }

  const publicAnalysis = async () => {
    if (textAreaValue === '' || textAreaValue === undefined) return message.warning("内容不能为空")
    await modal.open({
      width: 600,
      content(_1, reject) {
        return (
          <Card title="政策解析申请">
            <div style={{ marginBottom: 10 }}>申请人所属机构：<span>{userInfo.deptName === "大自然集团" ? "大自然家居（中国）有限公司" : userInfo.deptName}</span></div>
            <div style={{ marginBottom: 10 }}>申请人：<span>{userInfo.fullName}</span></div>
            <div style={{ marginBottom: 10 }}>审批人：<span>税务经理</span></div>
            <Row style={{ justifyContent: 'center' }}>
              <Col>
                <Button type="primary" style={{ marginRight: 10 }} onClick={() => handleConfirm(reject)}>确认</Button>
              </Col>
              <Col>
                <Button onClick={reject}>取消</Button>
              </Col>
            </Row>
          </Card>
        )
      }
    })
  }

  const handleConfirm = async(close: Function) => {
    const obj:JSONObject = {
      analysisBy: userInfo.userId,
      analysisContent: textAreaValue ? textAreaValue : knowledge?.analysisDraft?.analysisContent,
      analysisDate: moment().format("YYYY-MM-DD"),
      analysisName: userInfo.fullName,
      knowledgeId: id,
      // status: 1
      type: 2
    }
    if (knowledge?.analysisDraft) {
      obj.id = knowledge?.analysisDraft.id
      await Http.put(`/analysis`, obj)
    } else {
      await Http.post(`/analysis`, obj)
    }
    close()
    message.success('新增税务解析成功')
    setTextAreaValue('')
    getKonwledgeDetail(true)
  }

  const analysisChange = (e: any) => {
    setTextAreaValue(e.currentTarget.value)
  }

  const renderStatus = (flag: number) => {
    switch (flag) {
      case 1:
        return "审核中"
      case 2:
        return "审核通过"
      case 3:
        return "审核不通过"
      case 4:
        return "待审核"
      default:
        break;
    }
  }

  return (
    <Page>
      {modalHolder}
      <Row style={{ display: 'flex', flexDirection: 'row-reverse', marginRight: '20px' }}>
        <Space>
          <Button style={{ backgroundColor: '#d7d7d7' }} onClick={() => { Context.route('/taxKnowledge/knowledgeConsult', { back: new Date() }) }}>{time !== 0 ? `返回(${time}s)` : '返回'}</Button>
          <Button type='primary' onClick={setKnowledgeTop}>置顶</Button>
          <Button type='primary' onClick={cancleKnowledgeTop}>取消置顶</Button>
        </Space>
      </Row>
      <h2 style={{ textAlign: 'center', borderBottom: '3px solid #d7d7d7', margin: '0 40px 0 40px', fontWeight: 'bold' }}>{knowledge?.title}</h2>
      <div style={{ textAlign: 'center', fontSize: '16px', color: 'red', fontWeight: 'bold' }}>{knowledge?.proof}</div>
      <div style={{ display: 'flex', flexDirection: 'row-reverse', marginRight: '20px' }}>
        <Space>
          <span style={{ fontSize: '12px', color: 'red', fontWeight: 'bold' }}>{knowledge?.validity === 1 ? '全文有效' : knowledge?.validity === 2 ? '条款失效' : knowledge?.validity === 3 ? '全文废止' : ''}</span>
          <span style={{ fontSize: '12px', fontWeight: 'bold', marginTop: '-10  px' }} >成文日期：{knowledge?.releaseDate}</span>
        </Space>
      </div>
      <div>
        <p dangerouslySetInnerHTML={{ __html: knowledge?.content }} style={{ padding: '0 40px 0 40px', }}></p>
      </div>
      <Row>
        <Col span={2} style={{ paddingLeft: '20px', fontWeight: 'bold' }}>附件:</Col>
        <Col span={21}>
            {
              knowledge?.fileList.map((item: any) => {
                return (
                  <div>
                    <Download key={item.id} url={item.fileUrl} label={item.fileName} />
                  </div>
                )
              })
            }
        </Col>
      </Row>
      <Row style={{ margin: '10px 0 10px 0' }}>
        <Col span={2} style={{ paddingLeft: '20px', fontWeight: 'bold' }}>相关文章:</Col>
        <Col span={21}>
          {
            knowledge?.correlationList.map((item: any) => {
              return (
                <LinkText key={item.id} style={{ display: 'block', width: "1500px" }} label={item.value} onClick={() => { Context.route(`/taxKnowledge/knowledgeContent/${item.id}`) }}></LinkText>
              )
            })
          }
        </Col>
      </Row>
      <Row>
        <Col span={2} style={{ paddingLeft: '20px', fontWeight: 'bold' }}>网站链接:</Col>
        <Col span={21}>
          <a href={knowledge?.netUrl} target="_blank">{knowledge?.netUrl}</a>
        </Col>
      </Row>
      <Row style={{ margin: '10px 0 10px 0' }} >
        <Col span={2} style={{ paddingLeft: '20px', fontWeight: 'bold' }}>政策解析:</Col>
      </Row>
      <div style={{ marginLeft: '20px' }}>
        {knowledge?.analysisDtoList.length !== 0 ? <AnalysisList analysisDtoList={knowledge?.analysisDtoList} knowledgeId={id}></AnalysisList> : <></>}
      </div>
      <Row style={{ margin: '10px 0 10px 0', maxWidth: '1130px' }}>
        <Col span={3} style={{ paddingLeft: '20px', fontWeight: 'bold' }}>添加政策解析:</Col>
      </Row>
      <span style={{ fontSize: '12px', color: 'gray', marginLeft: '20px' }}>解析人：{userInfo.fullName}</span>
      <span style={{ fontSize: '12px', color: 'gray', marginLeft: '20px' }}>{moment().format("YYYY-MM-DD")}</span>
      <span style={{ fontSize: '12px', color: 'gray', marginLeft: '20px' }}>{knowledge?.analysisDraft ? renderStatus(knowledge?.analysisDraft?.approvalStatus) : ""}</span>
      <Row style={{ margin: '0 0 10px 20px' }}>
        <TextArea
          rows={6}
          style={{ maxWidth: '1130px' }}
          placeholder="解读内容"
          value={textAreaValue !== undefined ? textAreaValue : knowledge?.analysisDraft?.analysisContent}
          onChange={analysisChange}
          disabled={knowledge?.analysisDraft?.approvalStatus === 1}>
        </TextArea>
      </Row>
      <Row>
        <div style={{ width: '1150px', display: 'flex', justifyContent: 'flex-end' }}>
          <Space>
            <Button type='primary' onClick={saveAnalysis}>存为草稿</Button>
            <Button type='primary' onClick={publicAnalysis}>发布</Button>
          </Space>
        </div>
      </Row>
    </Page>
  )
}

export default KnowledgeContent
