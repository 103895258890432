import React, { FC, useEffect, useState, useRef } from "react";
import { useLocation } from "react-router";
import { dropByCacheKey } from "react-router-cache-route";
import {
  Form,
  Input,
  Row,
  Col,
  Space,
  Select,
  Popconfirm,
  InputNumber,
  DatePicker,
  AutoComplete,
} from "antd";
import moment from "moment";

import {
  Page,
  Table,
  Card,
  Modal,
  Button,
  LinkText,
  Download,
  DisplayInput,
  HiddenFormItem,
  BackTag,
} from "@comps";
import RowAddOrUpdateMD from "./modal/rowAddOrUpdate.md";
import UploadAnnexMD from "./modal/uploadAnnex.md";

import { Http, Context, Message, DateUtils } from "@utils";
import { ColumnProps } from "@comps/types";
import { TableType, ContractSourceType } from "./interface";

const { Item } = Form;
const { Option } = Select;

const ContractDetail: FC = (props: JSONObject) => {
  const contractId: number = Context.getPathVar(props, "contractId") ?? 0;
  const isCreateContract = contractId ? false : true;

  const routerState: any = useLocation().state;
  const isShowBTNsRef = useRef(false);
  const isCreateOrShowBTNsRef = useRef(isCreateContract);

  const checkBTNsIsShow = () => {
    if (!isCreateContract) {
      const sourceSystem = routerState?.sourceSystem ?? "";

      isShowBTNsRef.current =
        sourceSystem === ContractSourceType.MANUAL ||
        sourceSystem === ContractSourceType.EXCEL;

      isCreateOrShowBTNsRef.current = isCreateContract || isShowBTNsRef.current;
    }
  };

  checkBTNsIsShow();

  const [saleForm] = Form.useForm();
  const [baseForm] = Form.useForm();
  const [customerForm] = Form.useForm();
  const [distributorForm] = Form.useForm();

  const [SBUOptions, setSBUOptions] = useState<JSONObject[]>([]);
  const [regionOptions, setRegionOptions] = useState<JSONObject[]>([]);

  const [customerInfo, setCustomerInfo] = useState<JSONObject[]>([]);
  const [contactsList, setContactsList] = useState<JSONObject[]>([]);
  let tempContacts = "";

  const [updateContractLoading, setUpdateContractLoading] = useState(false);
  const [createContractLoading, setCreateContractLoading] = useState(false);
  const [saveCustomerInfoLoading, setSaveCustomerInfoLoading] = useState(false);

  let [detailTable, setDetailTable] = useState<Table>();
  let [planTable, setPlanTable] = useState<Table>();
  let [annexTable, setAnnexTable] = useState<Table>();

  const [modal, modalHolder] = Modal.useModal();

  const deleteTableURL: JSONObject = {
    [TableType.DETAIL]: "/contracts/deleteLine",
    [TableType.PLAN]: "/contracts/deleteArPlan",
    [TableType.ANNEX]: "/contracts/deleteAttachment",
  };

  const planTypeObj: JSONObject = {
    AP: "预付款",
    PP: "进度款",
    SP: "结算款",
    QP: "质保金",
  };

  useEffect(() => {
    getSBUOptions();
    getregionOptions();
    getCustomerInfo();
  }, []);

  useEffect(() => {
    judgeCreateOrUpdateContract();
  }, []);

  const judgeCreateOrUpdateContract = () => {
    if (isCreateContract) {
      const { deptName, fullName, employeeNo } = Context.getContext();

      saleForm.setFieldsValue({
        orgName: deptName,
        salerName: fullName,
        salerCode: employeeNo,
      });

      baseForm.setFieldsValue({
        contractAttr: "S",
        contractCategory: "S",
      });
    } else {
      getContractInfo();
    }
  };

  const getContractInfo = () => {
    Http.get(`/contracts/${contractId}`).then((res) => {
      const { contract } = res;
      contract.signDate = moment(contract.signDate);
      contract.customerAddressTel = contract.customerAddressTel;

      saleForm.setFieldsValue(contract);
      baseForm.setFieldsValue(contract);
      customerForm.setFieldsValue(contract);
      distributorForm.setFieldsValue(contract);
    });
  };

  const onDetailTableRef = (tableRef: Table) => {
    setDetailTable(tableRef);
    detailTable = tableRef;
  };

  const onPlanTableRef = (tableRef: Table) => {
    setPlanTable(tableRef);
    planTable = tableRef;
  };

  const onAnnexTableRef = (tableRef: Table) => {
    setAnnexTable(tableRef);
    annexTable = tableRef;
  };

  const getSBUOptions = () => {
    Http.get("/sys/sbu/findSbuByUser").then((res: JSONObject[]) => {
      setSBUOptions(res);
    });
  };

  const getregionOptions = () => {
    Http.get("/sys/regions/findRegionByUser").then((res: JSONObject[]) => {
      setRegionOptions(res);
    });
  };

  const validateForm = () => {
    return Promise.all([
      saleForm.validateFields(),
      baseForm.validateFields(),
      customerForm.validateFields(),
      distributorForm.validateFields(),
    ]);
  };

  const getUniteFormInfo = (forms: JSONObject[]) => {
    const targetObj: JSONObject = { id: +contractId };

    forms.forEach((form) => {
      Object.assign(targetObj, form);
    });

    return targetObj;
  };

  const handleUpdateContract = () => {
    validateForm().then((forms) => {
      setUpdateContractLoading(true);

      const uniteFormInfo = getUniteFormInfo(forms);
      uniteFormInfo.signDate = DateUtils.momentToStr(
        uniteFormInfo.signDate,
        "YYYY-MM-DD"
      );

      Http.put("/contracts", uniteFormInfo)
        .then(() => {
          Message.success("更新成功");
          dropByCacheKey("/output/contract");
          Context.route("/output/contract");
        })
        .finally(() => {
          setUpdateContractLoading(false);
        });
    });
  };

  const handleCreateContract = () => {
    validateForm().then((forms) => {
      setCreateContractLoading(true);

      const uniteFormInfo = getUniteFormInfo(forms);
      const { signDate } = uniteFormInfo;
      uniteFormInfo.signDate = DateUtils.momentToStr(signDate, "YYYY-MM-DD");

      const detailTableData = detailTable?.getTableData();
      const planTableData = planTable?.getTableData();
      const annexTableData = annexTable?.getTableData();

      const requestData = {
        contract: uniteFormInfo,
        contractLines: detailTableData,
        contractArPlans: planTableData,
        contractAttachments: annexTableData,
      };

      Http.post("/contracts", requestData)
        .then(() => {
          Message.success("创建成功");
          dropByCacheKey("/output/contract");
          Context.route("/output/contract");
        })
        .finally(() => {
          setCreateContractLoading(false);
        });
    });
  };

  const handleRowAddOrEdit = (
    tableType: number,
    isAdd: boolean,
    formData: JSONObject,
    rowId: number = -1
  ) => {
    const contractAmount: number =
      baseForm.getFieldValue("contractAmount") ?? 0;

    const tableTypeObj: JSONObject = {
      [TableType.DETAIL]: detailTable,
      [TableType.PLAN]: planTable,
      [TableType.ANNEX]: annexTable,
    };

    const currentTable: Table = tableTypeObj[tableType];

    const addTempTableData = (data: JSONObject) => {
      currentTable.addTemporaryData(data);
    };

    const editTempTableData = (data: JSONObject) => {
      currentTable.editTemporaryData(data, rowId);
    };

    const refreshTable = () => {
      currentTable.refresh();
    };

    modal.open({
      width: 860,
      content: (_1, reject) => (
        <RowAddOrUpdateMD
          contractId={contractId}
          contractAmount={contractAmount}
          tableType={tableType}
          isAddRow={isAdd}
          isCreateContract={isCreateContract}
          formData={formData}
          rowId={rowId}
          addTempData={addTempTableData}
          editTempData={editTempTableData}
          refreshTable={refreshTable}
          onClose={reject}
        />
      ),
    });
  };

  const handleRowDelete = (tableType: number, rowIds: number[]) => {
    isCreateContract
      ? handleTempRowDelete(tableType, rowIds)
      : handleActualRowDelete(tableType, rowIds);
  };

  const handleTempRowDelete = (tableType: number, rowIds: number[]) => {
    const tableTypeObj: JSONObject = {
      [TableType.DETAIL]: detailTable,
      [TableType.PLAN]: planTable,
      [TableType.ANNEX]: annexTable,
    };

    const currentTable: Table = tableTypeObj[tableType];

    if (rowIds.length === 0) {
      rowIds = currentTable.getSelectedRowKeys() || [];

      if (rowIds.length === 0) {
        Message.warn("请至少勾选一条数据");
        return;
      }
    }

    currentTable?.deleteTemporaryData(rowIds);
  };

  const handleActualRowDelete = (tableType: number, rowIds: number[]) => {
    const id: number = rowIds[0];
    const currentDeleteTableURL = `${deleteTableURL[tableType]}/${id}`;
    const tableTypeObj: JSONObject = {
      [TableType.DETAIL]: detailTable,
      [TableType.PLAN]: planTable,
      [TableType.ANNEX]: annexTable,
    };

    const currentTable: Table = tableTypeObj[tableType];

    Http.del(currentDeleteTableURL).then(() => {
      currentTable.refresh();
      Message.success("删除成功");
    });
  };

  const deleteAnnexTableRow = (fileURL: string) => {
    Http.del(`/contracts/deleteAttachment`, { fileUrl: fileURL }).then(() => {
      annexTable?.refresh();
      Message.success("删除成功");
    });
  };

  const showUploadAnnexMD = () => {
    const addTempRow = (data: JSONObject) => {
      annexTable?.addTemporaryData(data);
    };

    modal.open({
      width: 400,
      content: (_1, reject) => (
        <UploadAnnexMD
          contractId={contractId}
          isCreateContract={isCreateContract}
          addTempRow={addTempRow}
          onClose={reject}
        />
      ),
    });
  };

  const handleSBUOptionChange = (optionVal: any) => {
    const currentSBUItem =
      SBUOptions.find((SBUItem) => SBUItem.id === optionVal) ?? {};
    saleForm.setFieldsValue({
      sbuCode: currentSBUItem.code,
      sbuName: currentSBUItem.name,
    });
  };

  const getContractsList = (customerId: number) => {
    Http.get(`/customer/${customerId}/contacts`).then((data: JSONObject[]) => {
      setContactsList(data);
    });
  };

  const handleContractsChange = (customerContacts: any) => {
    const currentContactsInfo =
      contactsList.find((contacts) => contacts.fullName === customerContacts) ??
      {};

    customerForm.setFieldsValue({ customerEmail: currentContactsInfo.email });
  };

  const handleContactsFocus = () => {
    tempContacts = customerForm.getFieldValue("customerContacts");
    customerForm.setFieldsValue({ customerContacts: "" });
  };

  const handleContactsBlur = () => {
    if (customerForm.getFieldValue("customerContacts") === "") {
      customerForm.setFieldsValue({ customerContacts: tempContacts });
    }
  };

  const getContactsList = (customerId: number) => {
    Http.get(`/customer/${customerId}/contacts`).then((data: JSONObject[]) => {
      setContactsList(data);
      const contactsInfo =
        data.find((contact: JSONObject) => contact.defaultFlag === true) ?? {};
      data.length === 0
        ? customerForm.setFieldsValue({
            customerContacts: "",
            customerEmail: "",
            contactsPhone: "",
            contactsAddress: "",
          })
        : customerForm.setFieldsValue({
            customerContacts: contactsInfo.fullName,
            customerEmail: contactsInfo.email,
            contactsPhone: contactsInfo.phone,
            contactsAddress: contactsInfo.address,
          });
    });
  };

  const handleCustomerCodeSelect = (value: any) => {
    const currentSelectedCustomerInfo =
      customerInfo.find(
        (customer: JSONObject) => customer.customerCode === value
      ) ?? {};

    const { id, taxNo, shortName } = currentSelectedCustomerInfo;

    getContactsList(id);

    currentSelectedCustomerInfo.customerTaxNo = taxNo;
    currentSelectedCustomerInfo.bigCustomerName = shortName;

    customerForm.setFieldsValue(currentSelectedCustomerInfo);
  };

  const getCustomerInfo = () => {
    Http.get("/customer/all").then((data) => {
      setCustomerInfo(data);
    });
  };

  const handleSaveClientInfo = () => {
    const customerFormData = customerForm.getFieldsValue();
    const { customerTaxNo } = customerFormData;
    console.log(customerFormData);
    customerFormData["customerBankAccount"] =
      customerFormData.customerBankAcount;
    customerFormData["customerAddrTel"] = customerFormData.customerAddressTel;
    delete customerFormData.customerBankAcount;
    delete customerFormData.customerAddressTel;
    console.log(customerFormData);

    if (!customerTaxNo) {
      Message.warn("请补充公司税号");
      return;
    }

    setSaveCustomerInfoLoading(true);

    Http.post("/request/saveCustomer", customerFormData)
      .then(() => {
        Message.success("保存成功");
      })
      .finally(() => {
        setSaveCustomerInfoLoading(false);
      });
  };

  const handleBack = () => {
    Context.handleAdditionalPane(
      "合同管理",
      "/output/contract",
      "/output/contract"
    );
  };

  const detailColumns: ColumnProps[] = [
    { key: "goodsNo", title: "物料编码", width: 180, fixed: "left" },
    { key: "goodsName", title: "商品描述", width: 180 },
    { key: "largeCategory", title: "大类", width: 130 },
    { key: "mediumCategory", title: "中类", width: 130 },
    { key: "smallCategory", title: "小类", width: 130 },
    { key: "spec", title: "规格型号", width: 150 },
    { key: "unit", title: "单位", width: 50 },
    { key: "quantity", title: "数量", width: 120 },
    { key: "withTaxPrice", title: "含税单价", width: 200 },
    { key: "withTaxAmount", title: "含税金额", width: 200 },
    { key: "taxRate", title: "税率", width: 120, align: "center" },
    {
      key: "id",
      title: "操作",
      width: 160,
      align: "center",
      fixed: "right",
      render: (id: number, row: JSONObject) => {
        if (isCreateOrShowBTNsRef.current) {
          return (
            <Space size="large">
              <LinkText
                label="修改"
                onClick={() =>
                  handleRowAddOrEdit(TableType.DETAIL, false, row, id)
                }
              />
              <Popconfirm
                title="确定删除改行 ?"
                onConfirm={() => handleRowDelete(TableType.DETAIL, [id])}
                okText="确定"
                cancelText="取消"
              >
                <LinkText label="删除" />
              </Popconfirm>
            </Space>
          );
        }
      },
    },
  ];

  const planColumns: ColumnProps[] = [
    {
      key: "type",
      title: "款项",
      width: 180,
      render: (type: string) => planTypeObj[type],
    },
    { key: "rate", title: "收款比例", width: 110 },
    { key: "amount", title: "应收金额", width: 130, format: "amount" },
    { key: "planDate", title: "计划收款日期", width: 200, align: "center" },
    { key: "comment", title: "备注", width: 300 },
    {
      key: "id",
      title: "操作",
      width: 160,
      align: "center",
      fixed: "right",
      render: (id: number, row: JSONObject) => {
        if (isCreateOrShowBTNsRef.current) {
          return (
            <Space size="large">
              <LinkText
                label="修改"
                onClick={() =>
                  handleRowAddOrEdit(TableType.PLAN, false, row, id)
                }
              />
              <Popconfirm
                title="确定删除改行 ?"
                onConfirm={() => handleRowDelete(TableType.PLAN, [id])}
                okText="确定"
                cancelText="取消"
              >
                <LinkText label="删除" />
              </Popconfirm>
            </Space>
          );
        }
      },
    },
  ];

  const annexColumns: ColumnProps[] = [
    { key: "fileName", title: "附件名称", width: 260 },
    { key: "uploadTime", title: "上传时间", width: 260 },
    { key: "remark", title: "备注", width: 400 },
    {
      key: "id",
      title: "操作",
      width: 160,
      align: "center",
      fixed: "right",
      render: (id: number, row: JSONObject) => (
        <Space size="large">
          <Download url={`${row.fileUrl}`} />
          {isCreateOrShowBTNsRef.current && (
            <Popconfirm
              title="确定删除该行 ?"
              onConfirm={() => handleRowDelete(TableType.ANNEX, [id])}
              okText="确定"
              cancelText="取消"
            >
              <LinkText label="删除" />
            </Popconfirm>
          )}
        </Space>
      ),
    },
  ];

  return (
    <Page>
      {modalHolder}
      <Card
        title="销售信息"
        style={{ position: "relative" }}
        actions={
          <Space>
            {isCreateContract ? (
              <Button
                type="primary"
                loading={createContractLoading}
                onClick={handleCreateContract}
              >
                创建合同
              </Button>
            ) : (
              isShowBTNsRef.current && (
                <Button
                  type="primary"
                  loading={updateContractLoading}
                  onClick={handleUpdateContract}
                >
                  更新合同
                </Button>
              )
            )}
          </Space>
        }
      >
        <BackTag
          style={{ top: 18, left: 100 }}
          // onClick={handleBack}
        />
        <Form form={saleForm} labelCol={{ span: 10 }} wrapperCol={{ span: 14 }}>
          <Row>
            <Col span={8} pull={1}>
              <HiddenFormItem name="sbuCode" />
              <HiddenFormItem name="sbuName" />
              <Item label="签约主体" name="orgName">
                <DisplayInput />
              </Item>
            </Col>
            <Col span={8}>
              <Item
                name="signDept"
                label="签约部门"
                rules={[{ required: true }]}
              >
                <Input />
              </Item>
            </Col>
            <Col span={8}>
              <Item label="业务员" name="salerName">
                <Input />
              </Item>
            </Col>
          </Row>
          <Row>
            <Col span={8} pull={1}>
              <Item label="业务员工编号" name="salerCode">
                <Input />
              </Item>
            </Col>
            <Col span={8}>
              <Item label="SBU 标识" name="sbuId" rules={[{ required: true }]}>
                <Select
                  options={SBUOptions.map((SBUOption) => ({
                    value: SBUOption.id,
                    label: SBUOption.sbuMark,
                  }))}
                  onChange={handleSBUOptionChange}
                />
              </Item>
            </Col>
            <Col span={8}>
              <Item label="区域" name="regionId">
                <Select
                  options={regionOptions.map((SBUOption) => ({
                    value: SBUOption.id,
                    label: SBUOption.name,
                  }))}
                />
              </Item>
            </Col>
          </Row>
        </Form>
      </Card>

      <Card title="基本信息">
        <Form form={baseForm} labelCol={{ span: 10 }} wrapperCol={{ span: 14 }}>
          <Row>
            <Col span={8} pull={1}>
              <Item
                label="合同名称"
                name="contractName"
                rules={[{ required: true }]}
              >
                <Input />
              </Item>
            </Col>
            <Col span={8}>
              <Item
                label="合同分类"
                name="contractCategory"
                rules={[{ required: true }]}
              >
                <Select>
                  <Option value="S">销售合同</Option>
                  <Option value="P">采购合同</Option>
                </Select>
              </Item>
            </Col>
            <Col span={8}>
              <Item
                label="合同金额"
                name="contractAmount"
                rules={[{ required: true }]}
              >
                <InputNumber style={{ width: "100%" }} />
              </Item>
            </Col>
          </Row>
          <Row>
            <Col span={8} pull={1}>
              <Item
                label="合同属性"
                name="contractAttr"
                rules={[{ required: true }]}
              >
                <Select>
                  <Option value="S">只供货</Option>
                  <Option value="I">包安装</Option>
                </Select>
              </Item>
            </Col>
            <Col span={8}>
              <Item
                label="合同号"
                name="contractNo"
                rules={[{ required: true }]}
              >
                <Input />
              </Item>
            </Col>
            <Col span={8}>
              <Item
                label="签署日期"
                name="signDate"
                rules={[{ required: true }]}
              >
                <DatePicker
                  format="YYYY-MM-DD"
                  style={{ width: "100%" }}
                  inputReadOnly
                />
              </Item>
            </Col>
          </Row>
        </Form>
      </Card>
      <Form
        form={customerForm}
        labelCol={{ span: 5 }}
        wrapperCol={{ span: 19 }}
        onFinish={handleSaveClientInfo}
      >
        <Card
          title="客户信息"
          actions={
            (isCreateContract || isShowBTNsRef.current) && (
              <Button
                type="primary"
                loading={saveCustomerInfoLoading}
                htmlType="submit"
              >
                保存客户信息
              </Button>
            )
          }
        >
          <Row>
            <Col span={12}>
              <Item
                label="客户编码"
                name="customerCode"
                rules={[{ required: true }]}
              >
                <AutoComplete
                  allowClear
                  onSelect={handleCustomerCodeSelect}
                  filterOption={(inputValue, option) =>
                    option!.title.includes(inputValue)
                  }
                  options={customerInfo.map((customer) => ({
                    title: `${customer.customerCode} ${customer.customerName}`,
                    label: `${customer.customerCode} ${customer.customerName}`,
                    value: customer.customerCode,
                  }))}
                />
              </Item>
            </Col>
            <Col span={12}>
              <Item label="开户行及账号" name="customerBankAcount">
                <Input />
              </Item>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <Item label="客户名称" name="bigCustomerName">
                <DisplayInput />
              </Item>
            </Col>
            <Col span={12}>
              <Item label="地址及电话" name="customerAddressTel">
                <Input />
              </Item>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <Item
                label="公司名称"
                name="customerName"
                rules={[{ required: true }]}
              >
                <Input />
              </Item>
            </Col>
            <Col span={12}>
              <Item label="纳税人识别号" name="customerTaxNo">
                <Input />
              </Item>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <Item label="联系人" name="customerContacts">
                <AutoComplete
                  allowClear
                  onFocus={handleContactsFocus}
                  onBlur={handleContactsBlur}
                  onChange={handleContractsChange}
                  filterOption={(inputValue, option) =>
                    option!.title.includes(inputValue)
                  }
                  options={contactsList.map((item) => ({
                    title: item.fullName,
                    label: item.fullName,
                    value: item.fullName,
                  }))}
                />
              </Item>
            </Col>
            <Col span={12}>
              <Item label="邮箱" name="customerEmail">
                <Input />
              </Item>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <Item label="联系人地址" name="contactsAddress">
                <Input />
              </Item>
            </Col>
            <Col span={12}>
              <Item label="联系人电话" name="contactsPhone">
                <Input />
              </Item>
            </Col>
          </Row>
        </Card>
      </Form>

      <Card title="经销商">
        <Form
          form={distributorForm}
          labelCol={{ span: 10 }}
          wrapperCol={{ span: 14 }}
        >
          <Row>
            <Col span={8} pull={2}>
              <Item label="类型" name="dealerType">
                <Input />
              </Item>
            </Col>
            <Col span={8}>
              <Item label="经销商公司名称" name="dealerName">
                <Input />
              </Item>
            </Col>
          </Row>
        </Form>
      </Card>

      <Card
        title="商品明细"
        actions={
          <Space>
            {isCreateOrShowBTNsRef.current && (
              <>
                <Button
                  type="primary"
                  onClick={() => handleRowAddOrEdit(TableType.DETAIL, true, {})}
                >
                  新增
                </Button>
                {/* <Button 
                  onClick={() => handleRowDelete(TableType.DETAIL, [])}
                >
                  删除
                </Button> */}
              </>
            )}
          </Space>
        }
      >
        <Table
          url={`/contracts/${contractId}/lines`}
          columns={detailColumns}
          onTableRef={onDetailTableRef}
          selectedRowKeys={[]}
          pagination={false}
        ></Table>
      </Card>

      <Card
        title="收款计划"
        actions={
          <Space>
            {isCreateOrShowBTNsRef.current && (
              <>
                <Button
                  type="primary"
                  onClick={() => handleRowAddOrEdit(TableType.PLAN, true, {})}
                >
                  新增
                </Button>
                {/* <Button 
                  onClick={() => handleRowDelete(TableType.PLAN, [])}
                >
                  删除
                </Button> */}
              </>
            )}
          </Space>
        }
      >
        <Table
          url={`/contracts/${contractId}/arplans`}
          columns={planColumns}
          onTableRef={onPlanTableRef}
          selectedRowKeys={[]}
          pagination={false}
        ></Table>
      </Card>

      <Card
        title="附件"
        actions={
          <Space>
            {isCreateOrShowBTNsRef.current && (
              <>
                <Button type="primary" onClick={showUploadAnnexMD}>
                  上传附件
                </Button>
                {/* <Button 
                  onClick={() => handleRowDelete(TableType.ANNEX, [])}
                >
                  删除
                </Button> */}
              </>
            )}
          </Space>
        }
      >
        <Table
          url={`/contracts/${contractId}/attachments`}
          columns={annexColumns}
          onTableRef={onAnnexTableRef}
          selectedRowKeys={[]}
          pagination={false}
        ></Table>
      </Card>
    </Page>
  );
};

export default ContractDetail;
