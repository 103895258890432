import React, { FC, CSSProperties } from 'react';
import { Tag } from 'antd';

interface Props {
  label?: string, 
  style?: CSSProperties,
  onClick?: () => void
};

const defaultStyle: CSSProperties = {
  position: 'absolute',  
  zIndex: 1000,
  left: 130, 
  top: 16,
  cursor: 'pointer'
};

const BackTag: FC<Props> = ({
  label = '返回',
  style,
  onClick
}) => {

  const currentStyle = {};

  style
    ? Object.assign(currentStyle, defaultStyle, style)
    : Object.assign(currentStyle, defaultStyle);

  const handleClick = () => {
    onClick
      ? onClick()
      : window.history.back()
  }
  
  return (
    <Tag 
      style={currentStyle}
      color="geekblue"
      onClick={handleClick}
    >
      {label}
    </Tag>
  )
};

export default BackTag;
