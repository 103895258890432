import React, { FC, useState, useEffect, useRef } from 'react';
import { Steps, Space, Select, Row, Col, Form, DatePicker, Input, Radio, Upload, Table as AdTable } from 'antd';

import { Page, Card, Button, Download, Modal } from '@comps';
import Secondary from './secondary.md';
import SmartCheckMD from './modal/smart-check';

import { Context, DateUtils, Http, Message, Env, Math } from '@utils';

import { TableUtil } from "@comps/Table/util";
import { ColumnProps } from '@comps/types';
import { InputTransactionType } from './interface';

import '../index.css';

const { Step } = Steps;
const { Option } = Select;
const { Item } = Form;
const { RangePicker } = DatePicker;

const Check: FC = () => {
  const [form] = Form.useForm();
  const [modal, modalHolder] = Modal.useModal();

  const [checkInfo, setCheckInfo] = useState<JSONObject>({});
  const checkInfoRef: JSONObject = useRef();

  const [currentStep, setCurrentStep] = useState(1);
  const [checkState, setCheckState] = useState(0);

  const [tableData, setTableData] = useState<JSONObject[]>([]);
  const tableDataRef: JSONObject = useRef();

  const [loading, setLoading] = useState(false);

  const [total, setTotal] = useState(0);
  const [selectedRowKeys, setSelectedRowKeys] = useState<number[]>([]);
  const selectedRowKeysRef: JSONObject = useRef();
  const [tableSummaryData, setTableSummaryData] = useState<JSONObject>({});

  const [pageNum, setPageNum] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const pageSizeOptions = ['5', '10', '20', '50', '100', '500'];
  const formRef: any = useRef();

  const [isShowDeductionCheck, setIsShowDeductionCheck] = useState(true);
  const [isShowCancelDeductionCheck, setIsShowCancelDeductionCheck] = useState(false);
  const [isShowManualGet, setIsShowManualGet] = useState(true);
  const [isShowNoDeductionCheck, setIsShowNoDeductionCheck] = useState(true);
  const [isShowMoreOperations, setIsShowMoreOperations] = useState(true);
  const [isShowCheckDate, setIsShowCheckDate] = useState(false);
  const [isShowSamrtCheckMD, setIsShowSmartCheckMD] = useState(false);

  const [deductionCheckLoading, setDeductionCheckLoading] = useState(false);
  const [cancelDeductionCheckLoading, setCancelDeductionCheckLoading] = useState(false);
  const [manualGetLoading, setManualGetLoading] = useState(false);

  // 表格宽度,高度
  let xScroll = 0;

  // 表头所有筛选和排序字段, 需要传递给查询接口的字段名
  let filterKeys: any = {};

  // 表格默认列宽
  const DEFAULT_COL_WIDTH: number = 150;

  // 默认分页参数
  const FILED_PAGE_NUM: string = "pageNum";
  const FILED_PAGE_SIZE: string = "pageSize";

  const getCheckInfo = async () => {
    const res: JSONObject = await Http.get('/input/gx/period');

    const _period = res.period + '';
    setCheckInfo(res);
    checkInfoRef.current = res;

    form.setFieldsValue({
      invoiceDateFromTo: [
        DateUtils.someMonthFirstDay(_period),
        DateUtils.someMonthLastDay(_period)
      ],
      gx_time: [
        DateUtils.someMonthFirstDay(_period),
        DateUtils.now()
      ]
    });

    fetchTableData();
  };

  const filterInvalidFormData = (formData: JSONObject): JSONObject => {
    const keys = Object.keys(formData);

    for (var key of keys) {
      const val = formData[key];
      if (typeof val === 'undefined') {
        delete formData[key];
      } else if (val === null) {
        delete formData[key];
      }
    };

    return formData;
  };

  useEffect(() => {
    getCheckInfo();
  }, []);

  const onSelectChange = (selectedRowKeys: any) => {
    setSelectedRowKeys(selectedRowKeys);
    selectedRowKeysRef.current = selectedRowKeys;
  };

  const rowSelection: any = {
    selectedRowKeys,
    onChange: onSelectChange
  };

  const getTableData = (params: JSONObject) => {
    setLoading(true);
    Http.get('/input/gx', params)
      .then((data: any) => {
        setTableData(data.content);
        tableDataRef.current = data.content;

        setTotal(data.total);

        // 表格汇总数据
        const {
          totalAmount,
          totalQuantity,
          totalTaxAmount,
          totalValidTaxAmount
        } = data;

        setTableSummaryData({
          totalAmount,
          totalQuantity,
          totalTaxAmount,
          totalValidTaxAmount
        });
      }).finally(() => {
      setLoading(false);
    });
  };

  const fetchTableData = (
    tableHeaderParams?: any,
    pageSize: number = 10,
    pageNum: number = 1
  ) => {

    // 删除查询面板参数中的空值
    let queryPanelParams = form.getFieldsValue();
    queryPanelParams = filterInvalidFormData(queryPanelParams);

    // 查询参数 = 表头查询参数 + 查询面板参数 + 分页参数
    const queryParams = { ...tableHeaderParams, ...queryPanelParams };
    queryParams[FILED_PAGE_SIZE] = pageSize;
    queryParams[FILED_PAGE_NUM] = pageNum;
    queryParams.period = checkInfoRef.current?.period;

    getTableData(queryParams);
  };

  const handleStepChange = (current: number) => {
    if (current === 0) {
      Context.route('/input/gx');
    } else if (current === 2) {
      Context.route('/income/authentication/statistics');
    };
  };

  const handleShowOrHideButtons = (value: number) => {
    if (value === 0) { // 未勾选
      setIsShowDeductionCheck(true);
      setIsShowCancelDeductionCheck(false);
      setIsShowManualGet(true);
      setIsShowNoDeductionCheck(true);
      setIsShowMoreOperations(true);

      setIsShowCheckDate(false);
      return;
    } else if (value === 1) { // 抵扣勾选
      setIsShowDeductionCheck(false);
      setIsShowCancelDeductionCheck(false);
      setIsShowManualGet(true);
      setIsShowNoDeductionCheck(false);
      setIsShowMoreOperations(true);

      setIsShowCheckDate(false);
      return;
    } else if (value === 2) { // 已抵扣勾选
      setIsShowDeductionCheck(false);
      setIsShowCancelDeductionCheck(true);
      setIsShowManualGet(true);
      setIsShowNoDeductionCheck(false);
      setIsShowMoreOperations(true);

      setIsShowCheckDate(true);
      form.setFieldsValue({
        invoiceDateFromTo: []
      });
      return;
    } else if (value === 3) { // 撤销抵扣勾选中
      setIsShowDeductionCheck(false);
      setIsShowCancelDeductionCheck(false);
      setIsShowManualGet(true);
      setIsShowNoDeductionCheck(false);
      setIsShowMoreOperations(true);

      setIsShowCheckDate(false);
      return
    } else if (value === 4) { // 不抵扣勾选中
      setIsShowDeductionCheck(false);
      setIsShowCancelDeductionCheck(false);
      setIsShowManualGet(true);
      setIsShowNoDeductionCheck(false);
      setIsShowMoreOperations(true);

      setIsShowCheckDate(false);
      return
    } else if (value === 5) { // 已不抵扣勾选
      setIsShowDeductionCheck(false);
      setIsShowCancelDeductionCheck(false);
      setIsShowManualGet(true);
      setIsShowNoDeductionCheck(false);
      setIsShowMoreOperations(true);

      setIsShowCheckDate(true);
      return;
    } else { // 撤销不抵扣勾选中
      setIsShowDeductionCheck(false);
      setIsShowCancelDeductionCheck(false);
      setIsShowManualGet(true);
      setIsShowNoDeductionCheck(false);
      setIsShowMoreOperations(true);

      setIsShowCheckDate(false);
    };
  };

  const handleCheckState = (e: any) => {
    const value = e.target.value;
    setCheckState(value);
    handleShowOrHideButtons(value);
  };

  const handleFormChange = (
    changedFields: any,
    allFields: any
  ) => {
    formRef.current.setFieldsValue(allFields);
  };

  const handleFormReset = () => {
    formRef.current.resetFields();
    fetchTableData();
  };

  const handOnTableChange = (
    pagination: any,
    filters: any,
    sorter: any,
  ) => {
    const { current, pageSize } = pagination;
    setPageSize(pageSize);
    fetchTableData(null, pageSize, current);
  };

  const handleFormQuery = (values: any) => {
    fetchTableData(null, pageSize, pageNum)
  };

  const getCheckTextByType = (type: string) => {
    if (type === InputTransactionType.DEDUCTION_CHECK) {
      return '抵扣统计';
    } else if (type === InputTransactionType.CANCEL_DEDUCTION_CHECK) {
      return '撤销抵扣统计';
    } else {
      return '';
    };
  };

  const getSecondaryData = (type: string) => {
    let formData: JSONObject = form.getFieldsValue();

    formData = filterInvalidFormData(formData);
    formData.period = checkInfoRef.current.period;
    formData.taxNo = checkInfoRef.current.taxNo;
    formData.tryType = parseInt(type);

    const checkText = getCheckTextByType(type) ;

    Http.get('/input/gx/statisticsGX', formData)
      .then((res) => {
        modal.open({
          width: '700px',
          content(_1, reject) {
            return (
              <Secondary
                title={checkText}
                type={type}
                statisticsData={res}
                queryData={formData}
                onClose={reject}
              />
            )
          }
        });
      });
  };

  const handleDeductionOrCancelDeduction = (type: string) => {
    const selectedKeys = selectedRowKeysRef.current;

    if (!selectedKeys) {
      getSecondaryData(type);
    } else {
      if (type === InputTransactionType.DEDUCTION_CHECK) {
        setDeductionCheckLoading(true);
      } else {
        setCancelDeductionCheckLoading(true);
      }

      const selectedInvoices = tableDataRef.current.filter((invoice: any) => {
        return selectedKeys.includes(invoice.id);
      });

      selectedInvoices.map((invoice: any) => {
        invoice.gxStatus = checkState;
      });

      const parameter: JSONObject = {};
      parameter.period = checkInfoRef.current.period;
      parameter.taxNo = checkInfoRef.current.taxNo;
      parameter.trxType = type;
      parameter.invoices = selectedInvoices;

      Http.post('/input/gx/gx', parameter)
        .then(() => {
          Message.submitOk();
          fetchTableData();
        })
        .finally(() => {
          setDeductionCheckLoading(false);
          setCancelDeductionCheckLoading(false);
        });
    };
  };

  const handleManualGetInfo = () => {
    setManualGetLoading(true);

    Http.get('/input/gx/getGXResult')
      .then(() => {
        Message.success('手动获取数据成功');
      })
      .catch(err => {
        Message.error(err);
      })
      .finally(() => {
        setManualGetLoading(false);
      });
  };

  const handleMoreOperation = (value: any) => {
    if (value === 'export') {
      handleExportData();
    };
  };

  const handleExportData = () => {
    const gxStatus = form.getFieldValue('gxStatus');

    if (![0, 2].includes(gxStatus)) {
      Message.warn('只能导出勾选状态为 [ 未勾选 ] 或 [ 已抵扣勾选 ] 的发票', 5);
      return;
    };

    // 删除查询面板参数中的空值
    let queryPanelParams = form.getFieldsValue();

    if (queryPanelParams) {
      const keys = Object.keys(queryPanelParams);

      for (var key of keys) {
        const val = queryPanelParams[key];

        if (typeof val === 'undefined') {
          delete queryPanelParams[key];
        } else if (val === null) {
          delete queryPanelParams[key];
        };
      };
    };

    let url = `${Env.getBaseUrl()}/input/gx/exportInputGX?accessToken=${Context.getToken()}`;

    // 拼接 URL
    const filterKeys = Object.keys(queryPanelParams);
    filterKeys.map((key: string) => {
      url += `&${key}=${queryPanelParams[key]}`;
    });

    // 税款所属期
    const period = checkInfoRef.current?.period
    if (period) {
      url += `&period=${period}`;
    };

    let selectedKeys: number[] = [];
    if (selectedRowKeysRef.current) {
      selectedKeys = selectedRowKeysRef.current;
    };

    if (selectedKeys.length !== 0) {
      url += `&ids=${selectedKeys.join()}`;
    };

    window.open(url, '_blank');
  }

  const handleSmartCheckMDShow = (isShow: boolean) => {
    setIsShowSmartCheckMD(isShow);
  }

  const columns: ColumnProps[] = [
    { key: 'invoiceCode', title: '发票代码', width: 130 },
    { key: 'invoiceNo', title: '发票号码', width: 150 },
    { key: 'invoiceDate', title: '开票日期', width: 80 },
    { key: 'invoiceAmount', title: '不含税金额', format: 'amount', width: 120 },
    { key: 'taxAmount', title: '税额', format: 'amount', width: 120 },
    { key: 'validTaxAmount', title: '有效税额', format: 'amount', width: 120, align: 'center',
    },
    { key: 'statusCode', title: '发票状态', width: 80, align: 'center',
      render: text => {
        if (text === 0) {
          return '正常'
        } else if (text === 1) {
          return '失控'
        } else if (text === 2) {
          return '作废'
        } else if (text === 3) {
          return '红冲'
        } else if (text === 4) {
          return '异常'
        } else {
          return ''
        }
      }
    },
    {key: 'sellerName', title: '销方名称', width: 280,},
    {
      key: 'invoiceType', title: '发票类型', width: 200,
      render: text => {
        if (text === '01') {
          return '增值税专用发票'
        } else if (text === '02') {
          return '货运运输业增值税专用发票'
        } else if (text === '03') {
          return '机动车增值税专用发票'
        } else if (text === '08') {
          return '增值税电子专用发票'
        } else if (text === '10') {
          return '增值税电子普通发票'
        } else if (text === '14') {
          return '通行费发票'
        } else if (text === '31') {
          return '全电发票(增值税专用发票)'
        } else if (text === '32') {
          return '全电发票(普通发票)'
        } else {
          return ''
        }
      }
    },
    { key: 'gxTime', title: '勾选时间', width: 160 },
    { key: 'voucherDate', title: '过账日期', width: 120 },
    { key: 'uploadDate', title: '上传日期', width: 120 },
    {
      key: 'manageStatus', title: '管理状态', width: 80, fixed:'right', align: 'center',
      render: text => {
        if (text === 0) {
          return '正常'
        } else {
          return '非正常'
        }
      }
    }
  ]

  columns.map(column => {
    const key = column.key;

    // 设置 Column 默认宽度
    if (!column.width) {
      column.width = DEFAULT_COL_WIDTH;
    }

    // 通过 React key 属性自动对 antd Column 的 dataIndex 赋值
    if (!column.dataIndex) {
      column.dataIndex = key;
    }

    // 存储筛选字段的实际查询key, 如: statusDesc 的实际查询字段为 stateCode
    if (column.filterKey) {
      filterKeys[key] = column.filterKey;
    }

    // 处理 tag 类型样式
    TableUtil.handleColumnTag(column);

    // 渲染特定数据格式
    TableUtil.handleColumnFormat(column);

    // 超过宽度将自动省略
    column.ellipsis = true;

    // 自动处理横向滚动条的宽度
    xScroll += column.width;
  });

  return (
    <Page>
      {modalHolder}
      <SmartCheckMD
        visible={isShowSamrtCheckMD}
        getTableData={getTableData}
        checkInfo={checkInfo}
        onCancle={() => handleSmartCheckMDShow(false)}
      />
      <div className="authentication">
        <div className="steps">
          <Steps
            current={currentStep}
            onChange={handleStepChange}
            size="small"
          >
            <Step title="发票获取" />
            <Step title="发票勾选" />
            <Step title="发票抵扣统计" />
          </Steps>
        </div>
        <Card title="发票勾选" actions={(
          <Space>
            <Button
              type="primary"
              onClick={() => handleSmartCheckMDShow(true)}
            >
              智能勾选
            </Button>
            {isShowDeductionCheck && (
              <Button
                type="primary"
                loading={deductionCheckLoading}
                onClick={() => handleDeductionOrCancelDeduction(InputTransactionType.DEDUCTION_CHECK)}
              >
                抵扣勾选
              </Button>
            )}
            {isShowCancelDeductionCheck && (
              <Button
                type="primary"
                loading={cancelDeductionCheckLoading}
                onClick={() => handleDeductionOrCancelDeduction(InputTransactionType.CANCEL_DEDUCTION_CHECK)}
              >
                撤销抵扣勾选
              </Button>
            )}
            {isShowManualGet && (
              <Button
                type="primary"
                loading={manualGetLoading}
                onClick={handleManualGetInfo}
              >
                手动获取
              </Button>
            )}
            {isShowNoDeductionCheck && (
              <Button type="primary">不抵扣勾选</Button>
            )}
            {isShowMoreOperations && (
              <Select
                placeholder="更多操作"
                style={{ width: 100 }}
                onSelect={handleMoreOperation}
              >
                <Option
                  value="export"
                  onClick={handleExportData}
                >
                  导出数据
                </Option>
                <Option value="import">
                  <Upload
                    action={`${Env.getBaseUrl()}/input/gx/importFromExcel`}
                    headers={{
                      Authorization: Context.getToken()
                    }}
                    accept=".xlsx,.xls"
                    showUploadList={false}
                    onChange={async ({ file: { status, response = {} } }) => {
                      if (!['done', 'error'].includes(status as string)) {
                        return;
                      }

                      const { data = {}, code, msg } = response;

                      if (String(code) !== '200') {
                        Message.error(msg || '未知错误, 请联系管理员');
                        return;
                      }

                      const {
                        cancelGxSize,
                        errorFileUrl,
                        errorSize,
                        gxSize,
                        hasError,
                        successSize,
                        total
                      } = data;

                      // 导入成功
                      if (!hasError) {
                        await modal.open({
                          width: '600px',
                          content(_1, reject) {
                            return (
                              <Card
                                title="导入成功"
                                style={{ marginBottom: '0' }}
                              >
                                <Space
                                  direction="vertical"
                                  style={{ width: '100%' }}
                                >
                                  <Row justify="center">
                                    {`成功导入 ${successSize} 条数据`}
                                  </Row>
                                  <Row justify="center">
                                    <Button onClick={() => reject()}>确定</Button>
                                  </Row>
                                </Space>
                              </Card>
                            )
                          }
                        });
                        return;
                      }

                      // 导入失败
                      await modal.open({
                        width: '600px',
                        content(_1, reject) {
                          return (
                            <Card title="导入失败" style={{ marginBottom: '0' }}>
                              <Space direction="vertical" style={{ width: '100%' }}>
                                <Row justify="center">
                                  {`总共 ${total} 条， 本次需要勾选 ${gxSize} 条, 撤销勾选 ${cancelGxSize} 条，导入失败 ${errorSize} 条。`}
                                </Row>
                                <Row justify="center">
                                  <Download url={errorFileUrl} label='点击下载失败的导入记录'/>
                                </Row>
                                <Row justify="center">
                                  <Button onClick={() => reject()}>关闭</Button>
                                </Row>
                              </Space>
                            </Card>
                          );
                        }
                      });
                    }}
                  >
                    导入数据
                  </Upload>
                </Option>
              </Select>
            )}
          </Space>
        )}>
          <p>
            当前税款所属期：<span style={{color: '#f00'}}>{checkInfo.period}</span>
            （当期可进行勾选操作的截止日期为：{checkInfo.deadline}）
          </p>
          <Form
            ref={formRef}
            form={form}
            initialValues={{
              gxStatus: 0,
              invoiceType: null,
              manageStatus: null,
              statusCode: 0
            }}
            onFieldsChange={handleFormChange}
            onFinish={handleFormQuery}
          >
            <Row>
              <Col span={5}>
                <Item name='invoiceCode' className='table-query-col'>
                  <Input placeholder="发票代码" />
                </Item>
              </Col>
              <Col span={5}>
                <Item name='invoiceNo' className='table-query-col'>
                  <Input placeholder="发票号码" />
                </Item>
              </Col>
              <Col span={5}>
                <Item name='invoiceDateFromTo' className='table-query-col'>
                  <RangePicker
                    placeholder={['开票日期起', '开票日期止']}
                    format='YYYYMMDD' style={{ width: '100%' }}
                    inputReadOnly
                  />
                </Item>
              </Col>
              <Col span={5}>
                <Item name='sellerName'>
                  <Input placeholder="销方名称" />
                </Item>
              </Col>
              <Col span={4} style={{ textAlign: 'right' }}>
                <Space >
                  <Button type="primary" htmlType="submit">查询</Button>
                  <Button onClick={handleFormReset}>重置</Button>
                </Space>
              </Col>
            </Row>
            <Row>
              <Col span={5}>
                <Item name="isInnerInvoice" className='table-query-col'>
                  <Select placeholder="是否内部发票" allowClear>
                    <Option value="true">是</Option>
                    <Option value="false">否</Option>
                  </Select>
                </Item>
              </Col>
              <Col span={5}>
                <Item name="smartScreening" className='table-query-col'>
                  <Select placeholder="智能筛选" mode="multiple">
                    <Option value="0">全部</Option>
                    <Option value="1">未使用</Option>
                    <Option value="2">已使用</Option>
                    <Option value="3">已记账</Option>
                  </Select>
                </Item>
              </Col>
              <Col span={5}>
                <Item name='voucherDateFromTo' className='table-query-col'>
                  <RangePicker
                    placeholder={['过账日期起', '过账日期止']}
                    format='YYYYMMDD'
                    style={{ width: '100%' }}
                    inputReadOnly
                  />
                </Item>
              </Col>
              <Col span={5}>
                <Item name='uploadDateFromTo' className='table-query-col'>
                  <RangePicker
                    placeholder={['上传日期起', '上传日期止']}
                    format='YYYYMMDD'
                    style={{ width: '100%' }}
                    inputReadOnly
                  />
                </Item>
              </Col>
              <Col span={4}>
                {isShowCheckDate && (
                  <Item name='gxDateFromTo' className='table-query-col'>
                    <RangePicker
                      placeholder={['勾选日期起', '勾选日期止']}
                      format='YYYYMMDD'
                      style={{ width: '100%' }}
                      inputReadOnly
                    />
                  </Item>
                )}
              </Col>
            </Row>
            <Row>
              <Col>
                <Item name="gxStatus" label="勾选状态">
                  <Radio.Group onChange={handleCheckState}>
                    <Radio value={null}>全部</Radio>
                    <Radio value={0}>未勾选</Radio>
                    <Radio value={1}>抵扣勾选中</Radio>
                    <Radio value={2}>已抵扣勾选</Radio>
                    <Radio value={3}>撤销抵扣勾选中</Radio>
                    <Radio value={4}>不抵扣勾选中</Radio>
                    <Radio value={5}>已不抵扣勾选</Radio>
                    <Radio value={6}>撤销不抵扣勾选中</Radio>
                  </Radio.Group>
                </Item>
              </Col>
            </Row>
            <Row>
              <Col>
                <Item name="invoiceType" label="发票类型">
                  <Radio.Group>
                    <Radio value={null}>全部</Radio>
                    <Radio value="01">增值税专用发票</Radio>
                    <Radio value="03">机动车发票</Radio>
                    <Radio value="10">增值税电子普通发票</Radio>
                    <Radio value="14">通行费电子发票</Radio>
                    <Radio value="02">增值税电子专用发票</Radio>
                    <Radio value="31">全电发票(增值税专用发票)</Radio>
                    <Radio value="32">全电发票(普通发票)</Radio>
                  </Radio.Group>
                </Item>
              </Col>
            </Row>
            <Row>
              <Col>
                <Item name="manageStatus" label="管理状态">
                  <Radio.Group>
                    <Radio value={null}>全部</Radio>
                    <Radio value={0}>正常</Radio>
                    <Radio value={1}>异常</Radio>
                  </Radio.Group>
                </Item>
              </Col>
            </Row>
            <Row>
              <Col>
                <Item name="statusCode" label="发票状态">
                  <Radio.Group>
                    <Radio value={null}>全部</Radio>
                    <Radio value={0}>正常</Radio>
                    <Radio value={2}>已作废</Radio>
                    <Radio value={1}>已失控</Radio>
                    <Radio value={3}>已红冲</Radio>
                  </Radio.Group>
                </Item>
              </Col>
            </Row>
          </Form>
          <AdTable
            rowKey='id'
            columns={columns}
            dataSource={tableData}
            loading={loading}
            rowSelection={rowSelection}
            onChange={handOnTableChange}
            scroll={{
              x: xScroll,
              y: window.innerHeight - 300
            }}
            pagination={{
              hideOnSinglePage: false,
              showQuickJumper: true,
              showSizeChanger: true,
              showTotal: (total => `共 ${total} 条`),
              total: total,
              pageSizeOptions: pageSizeOptions,
              defaultCurrent: 1,
              pageSize
            }}
            summary={pageData => {

              // 选中数据
              let selectedAmountExcludingTax = 0
              let selectedTaxAmount = 0
              let selectedEffectiveTax = 0
              const selectedInvoice = pageData.filter(invoice => selectedRowKeys.includes(invoice.id))
              selectedInvoice.forEach(invoice => {
                selectedAmountExcludingTax += invoice.invoiceAmount
                selectedTaxAmount += invoice.taxAmount
                selectedEffectiveTax += invoice.validTaxAmount
              })
              return (
                <>
                  <AdTable.Summary.Row style={{ position: 'relative'}}>
                    <AdTable.Summary.Cell index={0}>
                      <span style={{ visibility: 'hidden'}}>看不见</span>
                    </AdTable.Summary.Cell>
                    <Row gutter={14} style={{ position: 'absolute', left: '10px', right: '0', zIndex: 100 }}>
                      <Col span={6}>
                        选中数量: {selectedRowKeys.length}
                      </Col>
                      <Col span={6}>
                        选中不含税金额: {Math.num2money(selectedAmountExcludingTax)}
                      </Col>
                      <Col span={6}>
                        选中税额: {Math.num2money(selectedTaxAmount)}
                      </Col>
                      <Col span={6}>
                        选中有效税额: {Math.num2money(selectedEffectiveTax)}
                      </Col>
                    </Row>
                  </AdTable.Summary.Row>

                  <AdTable.Summary.Row style={{ position: 'relative'}}>
                    <AdTable.Summary.Cell index={0}>
                      <span style={{ visibility: 'hidden'}}>看不见</span>
                    </AdTable.Summary.Cell>
                    <Row gutter={14} style={{ position: 'absolute', left: '10px', right: '0', zIndex: 100 }}>
                      <Col span={6}>
                        全部数量: {tableSummaryData.totalQuantity}
                      </Col>
                      <Col span={6}>
                        全部不含税金额: {Math.num2money(tableSummaryData.totalAmount)}
                      </Col>
                      <Col span={6}>
                        全部税额: {Math.num2money(tableSummaryData.totalTaxAmount)}
                      </Col>
                      <Col span={6}>
                        全部有效税额: {Math.num2money(tableSummaryData.totalValidTaxAmount)}
                      </Col>
                    </Row>
                  </AdTable.Summary.Row>
                </>
              )
            }}
          />
        </Card>
      </div>
    </Page>
  );
};

export default Check;
