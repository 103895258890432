import { Button, Card } from "@comps";
import { Http } from "@utils";
import { Form, Input, Space, message } from "antd";
import * as React from "react";

export interface Props {
  row: JSONObject;
  table: any;
  voidForm: any;
  reject: Function;
}

const ToVoidModal: React.FC<Props> = ({ row, table, voidForm, reject }) => {
  const [loading, setLoading] = React.useState(false);
  const handleToVoided = (id: number, onClose: Function) => {
    setLoading(true);
    const params = { id, cause: voidForm.getFieldValue("cause") };
    Http.post(`/tax/outputInvoices/electricCancel`, params)
      .then((res) => {
        message.success("作废成功");
        onClose();
        table?.refresh();
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <Card title="申请作废">
      <div style={{ padding: "16px" }}>
        <Form form={voidForm}>
          <Form.Item label="作废原因" name="cause">
            <Input.TextArea rows={4}></Input.TextArea>
          </Form.Item>
        </Form>
        <div style={{ textAlign: "center" }}>
          <Space>
            <Button
              onClick={() => {
                reject();
                table?.refresh();
              }}
            >
              取消
            </Button>
            <Button
              type="primary"
              onClick={() => handleToVoided(row.id, reject)}
              loading={loading}
            >
              确认
            </Button>
          </Space>
        </div>
      </div>
    </Card>
  );
};

export default ToVoidModal;
