import * as React from 'react';
import { Form, Space, Row, Col, DatePicker, Input, Spin, Table, Checkbox, Select } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { Card, Button } from '@comps';
import { DateUtils, Http, Math } from '@utils';
import _ from 'lodash';

const Option = Select.Option

export interface Props {
  onRefresh: () => void;
  onClose: () => void;
  onConfirm: () => void;
  lineId: number;
  buyerCode:string;
  isChange: boolean;
};

export interface Order {
  id: number,
  sapRequestNo: string,
  orderNo: string,
  buyerName: string,
  bizType: string,
  buyerTaxNo: string,
  invoiceNo: string,
  invoiceDate: string,
  goodsNo: string,
  goodsName: string,
  spec: string,
  invoiceUnit: string,
  taxRate: string,
  price: number,
  taxAmount: number,
  withTaxAmount: number,
  projectName: string,
  amount: number,
  quantity: number,
}

export interface SyncSummary {
  amount: number,
  quantity: number,
  withTaxAmount: number,
}

export interface ImportBatch {
  batchNo: number,
}

const OrderImportBorrowMd: React.FC<Props> = ({ onRefresh, onClose, onConfirm, lineId, buyerCode, isChange}: Props) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [tableLoading, setTableLoading] = React.useState<boolean>(false);
  const [batchNo, setBatchNo] = React.useState<number>(-1);
  const [orderPagination, setOrderPagination] = React.useState<Optional<Paging<Order>>>();
  const [isAll, setIsAll] = React.useState<boolean>(false);
  const [selectedRowKeys, setSelectedRowKeys] = React.useState<number[]>([]);
  const [syncSummary, setSyncSummary] = React.useState<SyncSummary>({
    amount: 0,
    quantity: 0,
    withTaxAmount: 0,
  });

  const withLoading = React.useCallback(async (callback: () => Promise<void>) => {
    try {
      setLoading(true);
      await callback();
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, []);

  const initFormValues = {
    date: [DateUtils.aWeekAgo(), DateUtils.now()],
    externalCustomer: true,
    companyType: '2'
  };

  const handlePageSizeChange = React.useCallback(async (batchNo: number,
    pageNum: number = 1,
    pageSize: number = 10) => {
    try {
      setTableLoading(true);
      const _orderPagination = await Http.get<Paging<Order>>('/tax/orders/lines', {
        batchNo,
        pageSize,
        pageNum,
      });
      setOrderPagination(_orderPagination);
      setSelectedRowKeys(isAll
        ?　(_orderPagination?.content?.map(order => order.id)) || []
        : []);
    } catch (error) {
      throw error;
    } finally {
      setTableLoading(false);
    }
  }, [isAll]);

  const handleQuery = React.useCallback(() => withLoading(async () => {
    let params = form.getFieldsValue();
    params = {
      ...params,
      startDate: params.date?.[0].format('YYYY-MM-DD HH:mm:ss'),
      endDate: params.date?.[1].format('YYYY-MM-DD HH:mm:ss'),
      buyerCode:buyerCode,
      addLoadItemsFlag:true
    }
    delete params.date;

    const { batchNo } = await Http.get<ImportBatch>('/tax/orders/syncOrders',
      _.pickBy(params, item => !_.isNil(item)));
    setBatchNo(batchNo);
    const [ resSyncSummary ] = await Promise.all([
      Http.get<SyncSummary>(`/tax/orders/syncSummary?batchNo=${batchNo}`),
      handlePageSizeChange(batchNo),
    ]);
    setSyncSummary(resSyncSummary);
  }), [form, withLoading, handlePageSizeChange]);


  const handleAll = React.useCallback(() => {
    const targetIsAll = !isAll;
    setIsAll(targetIsAll);
    setSelectedRowKeys(targetIsAll
      ? (orderPagination?.content?.map(order => order.id)) || []
      : []);
  }, [isAll, orderPagination]);

  const handleTableSelectChange = React.useCallback((selectedRowKeys: React.ReactText[]) => {
    if (isAll) {
      return;
    }
    setSelectedRowKeys(selectedRowKeys as number[]);
  }, [isAll]);

  const handlePullIn = React.useCallback(() => withLoading(async () => {
    isChange ?
    await Http.put('/tax/changes/importBorrow', {
      batchNo,
      lineId,
      importAll: isAll,
      addLoadItemsFlag:true,
      ids: isAll ? [] : selectedRowKeys,
    }) :
    await Http.post('/tax/outputInvoices/orderImportBorrow', {
      batchNo,
      lineId,
      importAll: isAll,
      addLoadItemsFlag:true,
      ids: isAll ? [] : selectedRowKeys,
    });
    onRefresh();
    onConfirm();

  }), [withLoading, batchNo, isAll, selectedRowKeys, onConfirm]);

  const columns: ColumnsType<Order> =[
    { dataIndex: 'orgCode', title: '公司编码', width: 100 },
    { dataIndex: 'sapRequestNo', title: '申请单号', width: 150 },
    { dataIndex: 'invoiceTypeDescription', title: '发票类型', width: 120 , ellipsis: true,},
    { dataIndex: 'orderNo', title: '销售订单', width: 130 },
    { dataIndex: 'employeeName', title: '业务员', width: 100, ellipsis: true, },
    { dataIndex: 'buyerName', title: '客户名称', width: 250, ellipsis: true, },
    { dataIndex: 'buyerCode', title: '客户编码', width: 120, ellipsis: true, },
    { dataIndex: 'bizType', title: '订单类型', width: 200 },
    { dataIndex: 'invoiceNo', title: '发票号', width: 150 },
    { dataIndex: 'invoiceLineNum', title: '发票行号', width: 120 },
    { dataIndex: 'invoiceDate', title: '发票日期', width: 120 },
    { dataIndex: 'goodsNo', title: '物料编号', width: 120, },
    { dataIndex: 'goodsName', title: '物料名称', width: 250, ellipsis: true, },
    { dataIndex: 'spec', title: '规格型号', width: 100, ellipsis: true,},
    { dataIndex: 'invoiceUnit', title: '单位', width: 100 },
    { dataIndex: 'price', title: '不含税单价', width: 130 },
    { dataIndex: 'withTaxPrice', title: '含税单价', width: 130 },
    { dataIndex: 'quantity', title: '数量', width: 100 },
    { dataIndex: 'taxRate', title: '税率', width: 80 },
    { dataIndex: 'amount', title: '不含税金额', width: 130,
      render(value: number) {
        return Math.num2money(value);
      }
    },
    { dataIndex: 'taxAmount', title: '税额', width: 120,
      render(value: number) {
        return Math.num2money(value);
      }
    },
    { dataIndex: 'withTaxAmount', title: '含税金额', width: 130,
      render(value: number) {
        return Math.num2money(value);
      }
    },
    { dataIndex: 'discountAmount', title: '折扣金额', width: 100,
      render(value: number) {
        return Math.num2money(value);
      }
    },
    { dataIndex: 'projectName', title: '项目名称', width: 200, ellipsis: true, },
    { dataIndex: 'remark', title: '备注', width: 200, ellipsis: true, },
    { dataIndex: 'profitCenterDesc', title: '利润中心', width: 100 , ellipsis: true,},
    { dataIndex: 'sellerDesc', title: '销售组织', width: 100 , ellipsis: true,},
    { dataIndex: 'sellerOfficeDesc', title: '销售办公室', width: 100 , ellipsis: true,},
  ];

  const content: React.ReactNode = (
    <Card title='引入借贷项数据' style={{ marginBottom: '0' }}>
      <Form initialValues={initFormValues} form={form}>
        <Space direction="vertical">
          <Row gutter={[8, 8]}>
            <Col span={5}>
              <Form.Item name="date"
                rules={[{
                  required: true, message: '请选择单据日期',
                }]}>
                <DatePicker.RangePicker style={{ width: '100%' }}
                  format='YYYYMMDD'
                  placeholder={["单据日期从", "单据日期至"]} inputReadOnly></DatePicker.RangePicker>
              </Form.Item>
            </Col>
            <Col span={5}>
              <Form.Item name="buyerName">
                <Input placeholder="客户名称"></Input>
              </Form.Item>
            </Col>
            <Col span={5}>
              <Form.Item >
                <Input name="buyerCode" placeholder="客户编码"  defaultValue={buyerCode} readOnly={true}></Input>
              </Form.Item>
            </Col>
            <Col span={5}>
              <Form.Item name="invoiceNo">
                <Input placeholder="发票号码"></Input>
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item name="companyType" label='公司类型'>
                <Select>
                  <Option value='1'>内部公司</Option>
                  <Option value='2'>外部公司</Option>
                  <Option value='3'>工程公司</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={5}>
              <Form.Item name="orderNo">
                <Input placeholder="销售订单"></Input>
              </Form.Item>
            </Col>
            <Col span={5}>
              <Form.Item name="profitCenterCode">
                <Input placeholder="利润中心编码"></Input>
              </Form.Item>
            </Col>
            <Col span={5}>
              <Form.Item name="saleOrgCode">
                <Input placeholder="销售组织编码"></Input>
              </Form.Item>
            </Col>
            <Col span={5}>
              <Form.Item name="saleOfficeCode">
                <Input placeholder="销售办事处编码"></Input>
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item name="invoiceType" label='发票类型'>
                <Select allowClear>
                      <Option value='S'>专票</Option>
                      <Option value='N'>普票</Option>
                    </Select>
                </Form.Item>
            </Col>
          </Row>
          <div style={{ textAlign: 'right' }}>
            <Space>
              <Button type="primary" clickCallback={async () => {
                  try {
                    await form.validateFields();
                  } catch (error) {
                    console.error(error);
                    return;
                  }
                  await handleQuery();
                }}>查询</Button>
              <Button type="primary" onClick={handleAll}>
                {isAll ? '取消全选' : '全选'}
              </Button>
              <Button type="primary" onClick={handlePullIn}>引入</Button>
              <Button onClick={() => onClose()}>返回</Button>
            </Space>
          </div>
        </Space>
      </Form>
      <Table columns={columns}
        style={{ paddingTop: '20px' }}
        rowSelection={{
          selectedRowKeys,
          onChange: handleTableSelectChange,
        }}
        loading={tableLoading}
        rowKey="id"
        scroll={{ x: columns.reduce((sum, column) => sum += Number.parseFloat(`${column.width ?? 100}`), 0)}}
        pagination={{
          hideOnSinglePage: false,
          showQuickJumper: true,
          showTotal: (total) => (`共${total}条`),
          showSizeChanger: true,
          current: orderPagination?.pageNum,
          pageSize: orderPagination?.pageSize,
          total: orderPagination?.total,
          pageSizeOptions: ["5", "10", "20", "50", "100", "500"],
          onChange(page: number, pageSize?: number) {
            handlePageSizeChange(batchNo, page, pageSize);
          }
        }}
        dataSource={orderPagination?.content}
        summary={() => {
          const selectOrders = (orderPagination?.content || [])
            .filter((order => selectedRowKeys?.includes(order.id)));
          const orderTotal = selectOrders.reduce((total, order) => {
            total.amount += order.amount;
            total.withTaxAmount += order.withTaxAmount;
            total.quantity += order.quantity;
            return total;
          }, {
            amount: 0,
            withTaxAmount: 0,
            quantity: 0,
          });
          return (<Table.Summary.Row style={{ position: 'relative', height: '50px' }}>
            <Table.Summary.Cell index={0}>
              <span style={{ visibility: 'hidden'}}>看不见</span>
            </Table.Summary.Cell>
            <Row gutter={14}
              style={{ position: 'absolute', bottom: '40px', left: '10px', right: '0' }}>
              <Col span={8}>
                选中的不含税金额: { Math.num2money(orderTotal.amount) }
              </Col>
              <Col span={8}>
                选中的含税金额: { Math.num2money(orderTotal.withTaxAmount) }
              </Col>
              <Col span={8}>
                选中的行数: {selectedRowKeys?.length || 0}
              </Col>
            </Row>
            <Row gutter={14}
              style={{ position: 'absolute', bottom: '18px', left: '10px', right: '0' }}>
              <Col span={8}>
                总不含税金额: { Math.num2money(syncSummary.amount) }
              </Col>
              <Col span={8}>
                总含税金额: { Math.num2money(syncSummary.withTaxAmount) }
              </Col>
              <Col span={8}>
                总行数: {syncSummary?.quantity}
              </Col>
            </Row>
          </Table.Summary.Row>)
        }}>
      </Table>
    </Card>
  );
  return loading ? (<Spin>{content}</Spin>) : (<span>{content}</span>);
};

export default OrderImportBorrowMd;
