import React, { FC, useState, useEffect, useRef, CSSProperties, memo } from 'react';
import { Spin, Popconfirm, Button } from 'antd';

import { Page, Modal, PermissionButton } from '@comps';
import ApplyForm from './apply-form';
import ClientForm from './client-form';
import InvoicingTable from './invoicing-table';
import RebateTable from './rebate-table';
import AnnexTable from './annex-table';
import FundTable from './fund-table';
import LogList from './log-list';
import ApproveRes from './apply-form/modal/approve-res';
import ProgressPayTips from './apply-form/modal/progress-pay-tips';
import ApproveSubmitTips from './apply-form/modal/approve-submit-tips';
import SelectCheckPeriodMD from '../modal/selectCheckPeriod.md';

import { Context, Http, Message, DateUtils, emitter, FormUtil } from '@utils';
import { 
  ApplyType,
  ApplyStatus,
  InvoiceType,
  ContractType,
  applyTypeObj,
  DATA_RELEASE_TITLE,
  ApplyTypeVal
} from '../interface';
import {
  SaleTableProps,
  OtherTableProps,
  ApplyTableProps,
  MergeTableProps,
  ToBeOpenedTableProps,
} from './invoicing-table/interface';

import '../index.css';

const { confirm } = Modal;

const ApplyDetail: FC = memo((props: JSONObject) => {
  const applyType: string = Context.getPathVar(props, 'applyType');
  const detailId: number = +Context.getPathVar(props, 'applyId') ?? 0;
  const isCreate: boolean = !detailId;
  const roleCode: string = Context.getContext().roleCold;
  
  const [baseFormId, setBaseFormId] = useState(0);
  const [baseFormData, setBaseFormData] = useState({});
  const [applyStatus, setApplyStatus] = useState('');

  const currentId = isCreate ? baseFormId : detailId;

  const applyFormRef = useRef<JSONObject>({});
  const clientFormRef = useRef<JSONObject>({});
  const invoicingTableRef = useRef<JSONObject>({});
  const rebateTableRef = useRef<JSONObject>({});
  const AnnexTableRef = useRef<JSONObject>({});

  const [isShowVoidedBTN, setIsShowVoidedBTN] = useState(false);
  const [isShowApproveBTN, setIsShowApproveBTN] = useState(false);
  const [isShowCancelApproveBTN, setIsShowCancelApproveBTN] = useState(false);
  const [isShowSubmitBTN, setIsShowSubmitBTN] = useState(false);
  const [isShowSaveBTN, setIsShowSaveBTN] = useState(false);
  const [isShowCancelBTN, setIsShowCancelBTN] = useState(false);
  const [isShowWriteOffARInvBTN, setIsShowWriteOffARInvBTN] = useState(
    applyType === ApplyType.ADVANCE
  );

  const [pageLoading, setPageLoading] = useState(false);
  const [voidedLoading, setVoidedLoading] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [cancelApproveLoading, setCancelApproveLoading] = useState(false);
  const [writeOffARInvLoading, setWriteOffARInvLoading] = useState(false);

  const [modal, modalHolder] = Modal.useModal();
  const [isShowSave, setIsShowSave] = useState(true);

  const [isSupplyOnly, setIsSupplyOnly] = useState(true);
  const isShowRebateTable =
    (applyType === ApplyType.RETAIL) ||
    (applyType === ApplyType.PROGRESS) ||
    (applyType === ApplyType.SETTLE);

  const isShowFundTable =
    (applyType === ApplyType.RETAIL) ||
    (applyType === ApplyType.OTHER);

  useEffect(() => {
    handleIsCreate();
  }, []);

  useEffect(() => {
    handleApplyType();
    judgeIdentity();
  }, []);

  const judgeIdentity = () => {
    if(Context.getContext().roleCode === 'CW' ) {
      setIsShowSave(true);
    }
  }

  const handleIsCreate = () => {
    isCreate ? getCreateFormData() : getDetailInfo();
  };

  const handleApplyType = () => {
    applyType === ApplyType.OTHER
      ? handleOtherTypeRequiredItem()
      : handleGeneralTypeRequiredItem()
  };

  const getDetailInfo = () => {
    setPageLoading(true);

    Http.get(`/request/${detailId}/detail`)
      .then(data => {
        const { contractType } = data.request;
        setIsSupplyOnly(contractType === ContractType.SUPPORT_ONLY);

        setAllModuleData(data);
        setBaseFormId(data.request.id);
        handleBTNsShow(data.request);
        handleInvoiceTypeChange(data.request.invoiceType);
      })
      .finally(() => {
        setPageLoading(false);
      });
  };

  const getCreateFormData = () => {

    setPageLoading(true);

    Http.post(`/request?requestType=${applyTypeObj[applyType]}`)
      .then(data => {
        const { contractType } = data;
        setIsSupplyOnly(contractType === ContractType.SUPPORT_ONLY);

        setBaseFormData(data);
        setBaseFormId(data.id);
        setApplyStatus(data.statusCode);

        handleBTNsShow(data);

        handleInvoiceTypeChange(data.invoiceType);
      })
      .finally(() => {
        setPageLoading(false);
      });
  };

  const getApplyFormData = (): JSONObject => {
    return applyFormRef.current.getApplyFormData();
  };

  const setApplyFormData = (formData: JSONObject) => {
    applyFormRef.current.setApplyFormData(formData);
  };

  const validateApplyFormFields = (): Promise<any> => {
    return applyFormRef.current.validateApplyFormFields();
  };

  const getClientFormData = (): JSONObject => {
    return clientFormRef.current.getClientFormData();
  };

  const setClientFormData = (formData: JSONObject) => {
    clientFormRef.current.setClientFormData(formData);
  };

  const handleGeneralInvRequiredItem = () => {
    clientFormRef.current.handleGeneralInvRequiredItem();
  };

  const handleSpecialInvRequiredItem = () => {
    clientFormRef.current.handleSpecialInvRequiredItem();
  };

  const handleEleGeneralInvRequiredItem = () => {
    clientFormRef.current.handleEleGeneralInvRequiredItem();
  };

  const handleEleSpecialInvRequiredItem = () => {
    clientFormRef.current.handleEleSpecialInvRequiredItem();
  };

  const handleGeneralTypeRequiredItem = () => {
    clientFormRef.current.handleGeneralTypeRequiredItem();
  };

  const handleOtherTypeRequiredItem = () => {
    clientFormRef.current.handleOtherTypeRequiredItem();
  };

  const setIsShowPersonalBTN = (isShow: boolean) => {
    clientFormRef.current.setIsShowPersonalBTN(isShow);
  };

  const setIsShowSaveClientBTN = (isShow: boolean) => {
    clientFormRef.current.setIsShowSaveClientBTN(isShow);
  };

  const validateClientFormFields = (): Promise<any> => {
    return clientFormRef.current.validateClientFormFields();
  };

  const validateAllFormFields = (): Promise<any> => {
    return Promise.all([
      validateApplyFormFields(),
      validateClientFormFields()
    ]);
  };

  const setInvDetailTableDataSource = (data: JSONObject) => {
    invoicingTableRef.current.setInvDetailTableDataSource(data);
  };

  const setInvDetailTableSummary = (data: JSONObject) => {
    invoicingTableRef.current.setInvDetailTableSummary(data);
  };

  const checkToBeOpenedTableHasNegativeAmount = (): boolean => {
    return invoicingTableRef.current.checkToBeOpenedTableHasNegativeAmount();
  };

  const setRebateTableDataSource = (dataSource: JSONObject[]) => {
    rebateTableRef.current.setRebateTableDataSource(dataSource);
  };

  const setRebateTableSummaryAmount = (data: JSONObject) => {
    rebateTableRef.current.setRebateTableSummaryAmount(data);
  };

  const setIsShowRebateTableAutoImportBTN = (isShow: boolean) => {
    rebateTableRef.current.setIsShowRebateTableAutoImportBTN(isShow);
  };

  const setIsShowRebateTableAddDetailBTN = (isShow: boolean) => {
    rebateTableRef.current.setIsShowRebateTableAddDetailBTN(isShow);
  };

  const setIsShowAnnexUploadBTN = (isShow: boolean) => {
    AnnexTableRef.current.setIsShowAnnexUploadBTN(isShow);
  };

  const handleInvoiceTypeChange = (invType: any) => {
    switch (invType) {
      case InvoiceType.GENERAL_INV:
        handleGeneralInvRequiredItem();
        setIsShowPersonalBTN(true);
        break;

      case InvoiceType.SPECIAL_INV:
        handleSpecialInvRequiredItem();
        setIsShowPersonalBTN(false);
        break;

      case InvoiceType.ELE_GENERAL_INV:
        handleEleGeneralInvRequiredItem();
        setIsShowPersonalBTN(true);
        break;

      case InvoiceType.ELE_SPECIAL_INV:
        handleEleSpecialInvRequiredItem();
        setIsShowPersonalBTN(false);
        break;

      default:
        break;
    }
  };

  const handleBTNsShow = (baseFormData: JSONObject) => {
    console.log(baseFormData)
    const {
      withdrawApproved,
      canApproved,
      canSubmit,
      cancelRequest,
      canSave,
      canDelete,
      statusCode
     } = baseFormData;

    setIsShowCancelApproveBTN(withdrawApproved);
    setIsShowApproveBTN(canApproved);
    setIsShowSubmitBTN(canSubmit);
    setIsShowVoidedBTN(cancelRequest);
    setIsShowSaveBTN(canSave);
    setIsShowCancelBTN(canDelete);

    if(statusCode === "CANCEL") {
      setIsShowCancelBTN(false);
    }
  };

  const handleRebateTableInfoShow = (
    applyStatus: string,
    rebateTableData: JSONObject[],
    data: JSONObject,
  ) => {

    const isCancelStatus =
      (applyStatus === ApplyStatus.CANCEL);

    setRebateTableDataSource(rebateTableData);
    setRebateTableSummaryAmount(data);

    setIsShowRebateTableAutoImportBTN(!isCancelStatus);
    setIsShowRebateTableAddDetailBTN(!isCancelStatus);
  };

  const handleIsShowCrossCheck = (
    formData: JSONObject,
    data: JSONObject,
  ) => {

    const { contractType } = formData;
    const { requestInvLines } = data;

    const isShowCrossCheck =
      (contractType !== ContractType.SUPPORT_ONLY) ||
      (applyType === ApplyType.SETTLE);

    (requestInvLines as JSONObject[]).forEach((row) => {
      row.isShowCrossCheck = isShowCrossCheck
    })
  };

  const setAllModuleData = (data: JSONObject) => {
    const { request, requestPolicyLines } = data;
    const { statusCode } = request;

    setApplyFormData(request);
    setClientFormData(request);
    handleBTNsShow(request);

    setApplyStatus(statusCode);
    if(statusCode === 'CANCEL') {
      setIsShowSave(false);
    }

    // 待开发票明细表是否显示勾稽处理
    handleIsShowCrossCheck(request, data);

    handleAllModuleBTNShowByStatus(data);
    setInvDetailTableDataSource(data);
    setInvDetailTableSummary(data);

    if (applyType === ApplyType.RETAIL) {
      handleRebateTableInfoShow(statusCode, requestPolicyLines, data);
    }
  };

  const getBaseFormData = (): JSONObject => {
    const applyFormData = getApplyFormData();
    const clientFormData = getClientFormData();

    const { promisePaymentDate } = applyFormData;

    applyFormData.promisePaymentDate = DateUtils.momentToNum(
      promisePaymentDate,
      'YYYYMMDD'
    );

    return Object.assign(applyFormData, clientFormData);
  };

  const getInvoicingTableTotalAmount = () => {
    return invoicingTableRef.current.getInvoicingTableTotalAmount();
  };

  const getRebateTableSummaryAmount = () => {
    return rebateTableRef.current.getRebateTableSummaryAmount();
  };

  const compareOriginalAndApplyTableAmount = () => {
    const {
      saleTotalAmount,
      otherTotalAmount,
      applyTotalAmount,
    } = getInvoicingTableTotalAmount();

    const isGreaterThanTotalAmount =
      (saleTotalAmount + otherTotalAmount) < applyTotalAmount

    if (isGreaterThanTotalAmount) {
      Message.warn('开票申请明细金额大于原引入明细订单总金额');
    };
  };

  const handleInvTableBTNsIsShow = (
    data: JSONObject,
    saleTable: SaleTableProps,
    otherTable: OtherTableProps,
    applyTable: ApplyTableProps,
    mergeTable: MergeTableProps,
    toBeOpenedTable: ToBeOpenedTableProps,
  ) => {

    const {
      requestSources,
      requestSourcesOther,
      requestLines,
      requestInvLines,
      requestPolicyLines
    } = data;

    (requestSources as JSONObject[]).forEach(row => {
      row.isShowDelete = saleTable.isShowDelete;
    });

    (requestSourcesOther as JSONObject[]).forEach(row => {
      row.isShowDelete = otherTable.isShowDelete;
    });

    (requestLines as JSONObject[]).forEach(row => {
      row.isShowDelete = applyTable.isShowDelete;
      row.isShowEdit = applyTable.isShowEdit;

      row.isShowCrossCheck = applyTable.isShowCrossCheck;
      row.isShowSee = applyTable.isShowSee;
      row.isShowCopy = applyTable.isShowCopy;
    });

    (requestInvLines as JSONObject[]).forEach(row => {
      row.isShowRestore = mergeTable.isShowRestore;
      row.isShowEdit = mergeTable.isShowEdit;
      row.isShowCrossCheck = mergeTable.isShowCrossCheck;
      row.isShowSee = mergeTable.isShowSee;
    });

    (requestPolicyLines as JSONObject[]).forEach(row => {
      row.isShowDelete = toBeOpenedTable.isShowDelete;
    });
  };

  const handleInvTableBTNsShowByStatus = (
    applyStatus: string,
    applyType: string,
    data: JSONObject,
  ) => {

    switch (applyStatus) {
      case ApplyStatus.NEW:
        handleInvTableBTNsIsShow(
          data,
          {
            isShowDelete: true,
          }, {
            isShowDelete: true,
          }, {
            isShowDelete: true,
            isShowEdit: true,
            isShowCrossCheck: false,
            isShowSee: false,
            isShowCopy: true,
          }, {
            isShowRestore: true,
            isShowEdit: true,
            isShowCrossCheck: false,
            isShowSee: false,
          },{
            isShowDelete: true,
          }
        );
        break;

      case ApplyStatus.INPROGRESS:
        handleInvTableBTNsIsShow(
          data,
          {
            isShowDelete: false,
          }, {
            isShowDelete: false,
          }, {
            isShowDelete: false,
            isShowEdit: true,
            isShowCrossCheck: false,
            isShowSee: false,
            isShowCopy: false,
          }, {
            isShowRestore: true,
            isShowEdit: true,
            isShowCrossCheck: false,
            isShowSee: false,
          },{
            isShowDelete: true,
          }
        );
        break;

      case ApplyStatus.WITHDRAW:
        handleInvTableBTNsIsShow(
          data,
          {
            isShowDelete: true,
          }, {
            isShowDelete: true,
          }, {
            isShowDelete: true,
            isShowEdit: true,
            isShowCrossCheck: false,
            isShowSee: false,
            isShowCopy: true,
          }, {
            isShowRestore: true,
            isShowEdit: true,
            isShowCrossCheck: false,
            isShowSee: false,
          },{
            isShowDelete: true,
          }
        );
        break;

      case ApplyStatus.APPROVED:
        handleInvTableBTNsIsShow(
          data,
          {
            isShowDelete: false,
          }, {
            isShowDelete: false,
          }, {
            isShowDelete: false,
            isShowEdit: false,
            isShowCrossCheck: ApplyTypeVal.PROGRESS=== applyType ? true : false,
            isShowSee: true,
            isShowCopy: false,
          }, {
            isShowRestore: false,
            isShowEdit: false,
            isShowCrossCheck: ApplyTypeVal.PROGRESS=== applyType ? false : true,
            isShowSee: true,
          },{
            isShowDelete: true,
          }
        );
        break;

      case ApplyStatus.REJECTED:
        handleInvTableBTNsIsShow(
          data,
          {
            isShowDelete: true,
          }, {
            isShowDelete: true,
          }, {
            isShowDelete: true,
            isShowEdit: true,
            isShowCrossCheck: false,
            isShowSee: false,
            isShowCopy: true,
          }, {
            isShowRestore: true,
            isShowEdit: true,
            isShowCrossCheck: false,
            isShowSee: false,
          },{
            isShowDelete: true,
          }
        );
        break;

      case ApplyStatus.CANCEL:
        handleInvTableBTNsIsShow(
          data,
          {
            isShowDelete: false,
          }, {
            isShowDelete: false,
          }, {
            isShowDelete: false,
            isShowEdit: false,
            isShowCrossCheck: false,
            isShowSee: false,
            isShowCopy: false,
          }, {
            isShowRestore: false,
            isShowEdit: false,
            isShowCrossCheck: false,
            isShowSee: false,
          },{
            isShowDelete: false,
          }
        );
        break;

      default:
        break;
    }

  };

  const setClientAndAnnexUploadBTNShow = (statusCode: string) => {
    switch (statusCode) {
      case ApplyStatus.NEW:
        setIsShowSaveClientBTN(true);
        setIsShowAnnexUploadBTN(true);
        break;

      case ApplyStatus.INPROGRESS:
        setIsShowSaveClientBTN(false);
        setIsShowAnnexUploadBTN(false);
        break;

      case ApplyStatus.WITHDRAW:
        setIsShowSaveClientBTN(true);
        setIsShowAnnexUploadBTN(true);
        break;

      case ApplyStatus.APPROVED:
        setIsShowSaveClientBTN(false);
        setIsShowAnnexUploadBTN(false);
        break;

      case ApplyStatus.REJECTED:
        setIsShowSaveClientBTN(true);
        setIsShowAnnexUploadBTN(true);
        break;

      case ApplyStatus.CANCEL:
        setIsShowSaveClientBTN(false);
        setIsShowAnnexUploadBTN(false);
        break;

      default:
        break;
    }
  };

  const handleAllModuleBTNShowByStatus = (data: JSONObject) => {
    const { request } = data;
    const { statusCode, requestType } = request;

    handleInvTableBTNsShowByStatus(statusCode, requestType, data);

    setClientAndAnnexUploadBTNShow(statusCode);
  };

  const checkIsCanSaveOrSubmit = () => {
    const formData = getBaseFormData();

    return Http.post('/request/leadToDelLines', formData);
  };

  const handleSave = () => {
    const formData = getBaseFormData();

    setSaveLoading(true);
    Http.post('/request/save', formData)
      .then((data) => {
        setAllModuleData(data);
        Message.success('保存成功');
      })
      .finally(() => {
        setSaveLoading(false);
      });
  };

  const handleSaveJudge = () => {
    checkIsCanSaveOrSubmit()
      .then(isCanSaveOrSubmit => {
        isCanSaveOrSubmit
          ? confirm({
              title: DATA_RELEASE_TITLE,
              onOk: handleSave
            })
          : handleSave()
    });
  };

  const handleSubmit = () => {
    const formData = getBaseFormData();

    setSubmitLoading(true);

    Http.post('/request/submit', formData)
      .then((data) => {
        setApplyFormData(data);
        handleBTNsShow(data);
        setApplyStatus(data.statusCode)
        
        Message.success('提交成功');
      })
      .finally(() => {
        setSubmitLoading(false);
      });
  };

  const isRebateTableSummaryAmountFitTheBill = () => {
    const {
      maxDiscountAmount,
      notWriteOffAmount,
      WriteOffAmount,
    } = getRebateTableSummaryAmount();

    const isFitTheBill =
      (WriteOffAmount === maxDiscountAmount) ||
      (WriteOffAmount === notWriteOffAmount);

    return isFitTheBill;
  };

  const checkProgressPayIsCanSubmit = () => {
    let isCanSubmit = true;

    if (applyType === ApplyType.PROGRESS) {
      const { allowMinusApply } = getApplyFormData();

      if (
        !allowMinusApply &&
        checkToBeOpenedTableHasNegativeAmount()
      ) {
        isCanSubmit = false;
      }
    }

    return isCanSubmit;
  };

  const handleCanSaveOrSubmit = () => {
    setPageLoading(true);

    checkIsCanSaveOrSubmit()
      .then((isCanSaveOrSubmit: boolean) => {
        isCanSaveOrSubmit
          ? confirm({
              title: DATA_RELEASE_TITLE,
              onOk: handleSubmit
            })
          : handleSubmit()
      })
      .finally(() => {
        setPageLoading(false);
      })
  };

  const checkProgressPayContractIsCanSubmit = () => {
    setPageLoading(true);

    const { contractId } = getApplyFormData();

    Http.post(`/request/${baseFormId}/completedAPAssociation/${contractId}`)
      .then(data => {
        const { allowApply, needTip, requestNo } = data;

        needTip
          ? modal.open({
              width: 500,
              content: (_1, reject) => (
                <ProgressPayTips
                  isAllowSubmit={allowApply}
                  orderNums={requestNo}
                  handleCanSaveOrSubmit={handleCanSaveOrSubmit}
                  onClose={reject}
                />
              )
            })
          : handleCanSaveOrSubmit();
      })
      .finally(() => {
        setPageLoading(false);
      })
  };

  const handleSubmitByApplyType = () => {
    switch (applyType) {
      case ApplyType.PROGRESS:
        checkProgressPayContractIsCanSubmit();
        break;

      default:
        handleCanSaveOrSubmit();
        break;
    }
  };

  const getReminderPoints = (handleNext: () => void) => {
    compareOriginalAndApplyTableAmount();

    Http.get(`/request/${baseFormId}/tip`)
      .then(data => {

        const {
          needTip,
          strongControl,
          tipContent,
        } = data;

        needTip
          ? modal.open({
              width: 500,
              content: (_1, reject) => (
                <ApproveSubmitTips
                  isAllowSubmit={!strongControl}
                  tipContent={tipContent}
                  handleNext={handleNext}
                  onClose={reject}
                />
              )
            })
          : handleNext();
      })
  };

  const handleSubmitJudge = () => {
    if (!checkProgressPayIsCanSubmit()) {
      Message.warn('不可提交含有负数的明细申请单');
      return;
    }

    validateAllFormFields()
      .then(() => {
        getReminderPoints(handleSubmitByApplyType);
      })
  };

  const showApproveResMD = () => {
    modal.open({
      width: 400,
      content: (_1, reject) => (
        <ApproveRes
          baseFormId={baseFormId}
          setApplyFormData={setApplyFormData}
          handleBTNsShow={handleBTNsShow}
          onClose={reject}
        />
      )
    });
  };

  const handleCancelBill = () => {
    Http.del(`/request/${currentId}`)
      .then(() => {
        emitter.emit('refreshApplyTable');
        Context.closeTabByTitle('申请详情', '开票申请');
        Message.success('取消成功');
        Context.closeTabByTitle('创建申请','开票申请');
      })
  };

  const handleVoided = () => {
    setVoidedLoading(true);

    Http.post(`/request/cancelRequest?requestId=${currentId}`)
      .then((data) => {
        setBaseFormData(data);
        handleBTNsShow(data);
        setApplyStatus(data.statusCode)
        Message.success('作废成功');
      })
      .finally(() => {
        setVoidedLoading(false);
      })
  };

  const handleCancelApprove = () => {
    setCancelApproveLoading(true);

    Http.post(`/request/withdraw?requestId=${currentId}`)
      .then((data) => {
        setBaseFormData(data);
        handleBTNsShow(data);
        Message.success('撤销成功');
      })
      .finally(() => {
        setCancelApproveLoading(false);
      })
  };

  const generateWriteOff = (GLDate: any,trxDate: any) => {
    Http.post(`/request/${baseFormId}/returnArInvoice?GLDate=${GLDate}&trxDate=${trxDate}`)
      .then(() => {
        Message.success('冲销成功');
      })
  }

  const writeOffARInv = () => {
    // setWriteOffARInvLoading(true);

    // Http.post(`/request/${baseFormId}/returnArInvoice`)
    //   .then(() => {
    //     Message.success('冲销成功');
    //   })
    //   .finally(() => {
    //     setWriteOffARInvLoading(false);
    //   })

    modal.open({
      width: 450,
      content: (_1, reject) => (
        <SelectCheckPeriodMD 
          onClose={reject}
          generateWriteOff={generateWriteOff}
        />
      )
    });
  };

  const showSaveBTNByRole = () => {
    let isShow = true;

    switch (roleCode) {
      case 'YWY':
        isShow = false;
        break;
    
      default:
        break;
    }

    return isShow;
  };

  const isShowBTN = (isShow: boolean): CSSProperties => {
    return { display: isShow ? 'block' : 'none' };
  };

  const importInvoiceDetail = () => {
    FormUtil.exportExcelFile(`/request/exportDetail`, 
    {
      requestId:currentId
    });
  }

  const ApplyBTNs: FC = () => {
    return (
      <div className="apply-btns">
        <Button
          type="primary"
          onClick={importInvoiceDetail}
        >
          开票明细导出
        </Button>
        <PermissionButton
          permissions="OutputRequest.returnArInvoice"
          type="primary"
          style={isShowBTN(isShowWriteOffARInvBTN)}
          loading={writeOffARInvLoading}
          onClick={writeOffARInv}
        >
          冲销AR发票
        </PermissionButton>
        <Popconfirm
          title="是否取消单据申请并删除开票申请单 ?"
          onConfirm={handleCancelBill}
        >
          <PermissionButton
            permissions="OutputRequest.delete"
            type="primary"
            style={isShowBTN(isShowCancelBTN)}
          >
            取消
          </PermissionButton>
        </Popconfirm>
        <PermissionButton
          permissions="OutputRequest.cancelRequest"
          type="primary"
          style={isShowBTN(isShowVoidedBTN)}
          loading={voidedLoading}
          onClick={handleVoided}
        >
          作废
        </PermissionButton>
        <PermissionButton
          permissions="OutputRequest.approved"
          type="primary"
          style={isShowBTN(isShowApproveBTN)}
          onClick={() => getReminderPoints(showApproveResMD)}
        >
          审批
        </PermissionButton>
        <PermissionButton
          permissions="OutputRequest.withdraw"
          type="primary"
          style={isShowBTN(isShowCancelApproveBTN)}
          loading={cancelApproveLoading}
          onClick={handleCancelApprove}
        >
          撤销审批
        </PermissionButton>
        <Popconfirm
          title="是否确定提交 ?"
          onConfirm={handleSubmitJudge}
        >
          <PermissionButton
            permissions="OutputRequest.submit"
            style={isShowBTN(isShowSubmitBTN)}
            type="primary"
            loading={submitLoading}
          >
            提交
          </PermissionButton>
        </Popconfirm>
        <Button
          loading={saveLoading}
          type="primary"
          style={isShowBTN(isShowSave)}
          onClick={handleSaveJudge}
        >
          保存
        </Button>
      </div>
    );
  };

  return (
    <Spin spinning={pageLoading}>
      {modalHolder}
      <Page style={{ position: 'relative' }}>
        <ApplyBTNs />
        <ApplyForm
          ref={applyFormRef}
          applyType={applyType}
          applyStatus={applyStatus}
          formData={baseFormData}
          handleInvoiceTypeChange={handleInvoiceTypeChange}
        />
        <ClientForm
          ref={clientFormRef}
          applyType={applyType}
          applyStatus={applyStatus}
          isCreate={isCreate}
          formData={baseFormData}
        />
        <InvoicingTable
          ref={invoicingTableRef}
          applyType={applyType}
          applyStatus={applyStatus}
          baseFormId={baseFormId}
          getBaseFormData={getBaseFormData}
          setPageLoading={setPageLoading}
          setAllModuleData={setAllModuleData}
          checkIsCanSave={checkIsCanSaveOrSubmit}
          isSupplyOnly={isSupplyOnly}
        />
        {isShowRebateTable && (
          <RebateTable
            ref={rebateTableRef}
            baseFormId={baseFormId}
            setAllModuleData={setAllModuleData}
            setPageLoading={setPageLoading}
          />
        )}
        {isShowFundTable && (
          <FundTable
            baseFormId={currentId}
            setPageLoading={setPageLoading}
          />
        )}
        <AnnexTable
          ref={AnnexTableRef}
          detailId={currentId}
          setPageLoading={setPageLoading}
        />
        {!isCreate && (
          <LogList logId={currentId} />
        )}
      </Page>
    </Spin>
  );
});

export default ApplyDetail;
