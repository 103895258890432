import React, { useState, FC } from 'react';
import { Button, Space, Input, Tag, Form } from 'antd';

import { Page, Table, Card, LinkText, Modal } from '@comps';
import AddOrEditMD from './addOrEdit.md';

import { Http, Message } from '@utils';

import { ColumnProps } from '@comps/types';

const { Item } = Form;

const RegionManage: FC = () => {
  const [deleteLoading, setDeleteLoading] = useState(false);

  const [modal, modalHolder] = Modal.useModal();

  let [table, setTable] = useState<Table>();
  const onTableRef = (tableRef: Table) => {
    setTable(tableRef);
    table = tableRef;
  };

  const handleAdd = async () => {
    await modal.open({
      width: 500,
      content: (_1, reject) => (
        <AddOrEditMD isAdd={true} rowObj={{}} onClose={reject} />
      )
    });

    table?.refresh();
  };

  const handleDelete = () => {
    const IDs = table?.getSelectedRowKeys();

    if (IDs?.length === 0) {
      Message.warn('请至少勾选一条数据');
      return;
    }

    setDeleteLoading(true);

    Http.del('/sys/regions/batchDelete', { ids: IDs })
      .then(() => {
        Message.success('删除成功');
        table?.refresh();
      })
      .finally(() => {
        setDeleteLoading(false);
      });
  };

  const handleEdit = async (row: JSONObject) => {
    await modal.open({
      width: 500,
      content: (_1, reject) => (
        <AddOrEditMD isAdd={false} rowObj={row} onClose={reject} />
      )
    });
    table?.refresh();
  };

  const handleStatusChange = (row: JSONObject) => {
    const { id, enabled } = row;

    Http.put('/sys/regions/updateStatus', { id, enabled: !enabled })
      .then(() => {
        Message.success(enabled ? '禁用成功' : '启用成功');
        table?.refresh();
      })
  };

  const columns: ColumnProps[] = [
    { key: 'name', title: '区域名称', width: 300 },
    { key: 'code', title: '区域代码', width: 300 },
    {
      key: 'enabled', title: '状态', width: 200,
      render: (enabled, row) => {
        return enabled
          ? <Tag color="green">启用</Tag>
          : <Tag color="red">禁用</Tag>
      }
    },
    {
      key: 'id', title: '操作', width: 120,
      render: (id: any, row: JSONObject) => (
        <Space size="large">
          <LinkText label="修改" onClick={() => handleEdit(row)}></LinkText>
          <LinkText label={row.enabled ? '禁用' : '启用'} onClick={() => handleStatusChange(row)}></LinkText>
        </Space>
      )
    }
  ];

  return (
    <Page>
      {modalHolder}
      <Card
        title="区域管理"
        actions={
          <Space>
            <Button type="primary" onClick={handleAdd}>新增</Button>
            <Button onClick={handleDelete}>删除</Button>
          </Space>
        }>
        <Table
          url='/sys/regions'
          onTableRef={onTableRef}
          columns={columns}
          selectedRowKeys={[]}
          scroll={{ y: window.innerHeight - 300 }}
        >
          <Item name="args">
            <Input placeholder="区域名称/区域代码" />
          </Item>
        </Table>
      </Card>
    </Page>
  );
};

export default RegionManage;