export const DATA_RELEASE_TITLE = '当前操作会导致明细行数据被释放删除是否确定要执行此操作 ?';

export enum ApplyType {
  /**
   * 零售
   */
  RETAIL = 'retail',
  /**
   * 预付款
   * */
  ADVANCE = 'advance',
  /**
   * 进度款
   */
  PROGRESS = 'progress',
  /**
   * 结算款
   */
  SETTLE = 'settle',
  /**
   * 其他
   */
  OTHER = 'other',
  /**
   * 主辅单位
   */
  MAIN_ASSISTANT = 'main-assistant'
};

export enum ApplyTypeVal {
  /**
  * 零售
  */
  RETAIL = 'RT',
  /**
   * 预付款
   */
  ADVANCE = 'AP',
  /**
   * 进度款
   */
  PROGRESS = 'PP',
  /**
   * 结算款
   */
  SETTLE = 'SP',
  /**
   * 其他
   */
  OTHER = 'OTHER',
  /**
   * 主辅单位
   */
  MAIN_ASSISTANT = 'PA'
};

export enum ApplyStatus {
  /**
   * 待提交
   */
  NEW = 'NEW',
  /**
   * 审批中
   */
  INPROGRESS = 'INPROGRESS',
  /**
   * 已撤回
   */
  WITHDRAW = 'WITHDRAW',
  /**
   * 审批通过
   */
  APPROVED = 'APPROVED',
  /**
   * 审批驳回
   */
  REJECTED = 'REJECTED',
  /**
   * 已作废
   */
  CANCEL = 'CANCEL'
};

export enum ContractType {
  /**
   * 只供货
   */
  SUPPORT_ONLY = 'S',
  /**
   * 包安装
   */
  INCLUDE_INSTALL = 'I'
};

export enum InvoiceType {
  /**
   * 专用发票
   */
  SPECIAL_INV = 0,
  /**
   * 普通发票
   */
  GENERAL_INV = 2,
  /**
   * 电子专票
   */
  ELE_SPECIAL_INV = 28,
  /**
   * 电子普票
   */
  ELE_GENERAL_INV = 51,
  /**
   *全电发票（专）
   */
  FULL_ELE_SPECIAL_INV = 31,
  /**
   * 全电发票（普）
   */
  FULL_ELE_GENERAL_INV = 32,
};

export const statusCodeObj: JSONObject = {
  NEW: '待提交',
  INPROGRESS: '审批中',
  WITHDRAW: '已撤回',
  APPROVED: '审批通过',
  REJECTED: '审批驳回',
  CANCEL: '已作废'
};

export const applyTypeObj: JSONObject = {
  [ApplyType.RETAIL]: ApplyTypeVal.RETAIL,
  [ApplyType.ADVANCE]: ApplyTypeVal.ADVANCE,
  [ApplyType.PROGRESS]: ApplyTypeVal.PROGRESS,
  [ApplyType.SETTLE]: ApplyTypeVal.SETTLE,
  [ApplyType.OTHER]: ApplyTypeVal.OTHER,
  [ApplyType.MAIN_ASSISTANT]: ApplyTypeVal.MAIN_ASSISTANT
};



