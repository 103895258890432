import { Col, Form, Input, Row, Tree } from "antd";
import React, { useCallback, useEffect, useState } from "react";

import { Card, LinkText, Modal, Page, Table } from "@comps";
// import AddCategory from './addCategory.md';
// import AddOrEdit from './addOrEdit.md';

import { ColumnProps } from "@comps/types";
import { Http } from "@utils";

const { Item } = Form;

interface Props {
  // ref: any;
  onClose: Function;
  getGoodMsg: Function;
}
const EntryNameModal: React.FC<Props> = ({ onClose, getGoodMsg }) => {
  const [selectedParentId, setSelectedParentId] = useState(-1);
  const [treeData, setTreeData] = useState<[]>();

  const [model, modalHolder] = Modal.useModal();

  let [table, setTable] = useState<Table>();
  const onTableRef = (tableRef: Table) => {
    table = tableRef;
    setTable(tableRef);
  };

  const loadTree = useCallback<() => Promise<void>>(async () => {
    const { children } = (await Http.get("/tax/goods/categories/tree")) as any;
    setTreeData(children);
  }, []);

  useEffect(() => {
    (async () => {
      await loadTree();
    })();
  }, [loadTree]);

  /**
   * 选中/取消树上节点
   * @param selectedKeys
   * @param info
   */
  const handleOnSelect = (_1: any, info: any) => {
    const selected: boolean = info.selected;
    const node = info.node;
    const key = node.key;

    if (selected) {
      setSelectedParentId(key);
    } else {
      setSelectedParentId(-1);
    }

    setTimeout(() => {
      table?.refresh();
    }, 200);
  };

  const handleChoose = (good: JSONObject) => {
    console.log(good, "======good=====");
    getGoodMsg(good);
    onClose();
  };

  const columns: ColumnProps[] = [
    {
      key: "goodsName",
      title: "商品名称",
      width: 150,
      ellipsis: true,
      fixed: "left",
    },
    { key: "goodsCode", title: "商品内部编码", width: 120 },
    { key: "taxName", title: "税收分类", width: 90 },
    { key: "taxCode", title: "税收分类编码", width: 250 },
    { key: "spec", title: "规格型号", width: 100 },
    { key: "unitPrice", title: "单价", width: 100 },
    { key: "unit", title: "单位", width: 60 },
    { key: "taxRate", title: "税率(%)", width: 100, tag: {}, fixed: "right" },
    {
      key: "id",
      title: "操作",
      width: 60,
      fixed: "right",
      render: (_1: any, good: JSONObject) => (
        <LinkText label="选择" onClick={() => handleChoose(good)} />
      ),
    },
  ];

  return (
    <Page>
      {modalHolder}
      <Card title="商品管理">
        <Row>
          <Col span={6}>
            <Tree treeData={treeData} onSelect={handleOnSelect} />
          </Col>
          <Col span={17}>
            <Table
              url={`/tax/goods?parentId=${selectedParentId}`}
              onTableRef={onTableRef}
              columns={columns}
              scroll={{ y: window.innerHeight - 300 }}
            >
              <Item name="key">
                <Input
                  placeholder="商品名称/商品编码/规格型号"
                  title="商品名称/商品编码/规格型号"
                />
              </Item>
            </Table>
          </Col>
        </Row>
      </Card>
    </Page>
  );
};

export default EntryNameModal;
