import { UserContext } from '@models';
import { Env } from '@utils';
import React, { FC, useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react';
import { useSelector } from 'react-redux';
import E from 'wangeditor'
import "./RichEditor.css"

interface CRef {
  showHtml: (val: any) => void
}

interface Props {
  getText: (val: any) => void
}

const RichEditor = forwardRef<CRef, Props>(({
  getText,
}, ref) => {
  const userInfo = useSelector<JSONObject, UserContext>((state) => state.ctx);
  const [editorContent, setEditorContent] = useState<any>()
  const editorElemMenu = useRef<HTMLDivElement>(null);
  const editorElemBody = useRef<HTMLDivElement>(null);

  let editor: any;

  const editorRef: any = useRef()

  useImperativeHandle(ref, () => ({
    showHtml

  }))

  const showHtml = (val: any) => {
    editorRef.current?.txt.html(val)
  }

  useEffect(() => {
    // console.log(nanoid, 'nanoid')
    const elemMenu = editorElemMenu.current;
    const elemBody = editorElemBody.current;
    editor = new E(elemMenu, elemBody)
    // 使用 onchange 函数监听内容的变化，并实时更新到 state 中
    editor.config.onchange = (html: any) => {
      const textHtml = editor.txt.html()
      setEditorContent(textHtml)
      getText(textHtml)
    }
    editor.config.menus = [
      'head',  // 标题
      'bold',  // 粗体
      'italic',  // 斜体
      'underline',  // 下划线
      'strikeThrough',  // 删除线
      'foreColor',  // 文字颜色
      'link',  // 插入链接
      'list',  // 列表
      'justify',  // 对齐方式 
      'quote',  // 引用
      'emoticon',  // 表情
      'image',  // 插入图片
      'video',  // 插入视频
      'undo',  // 撤销
      'redo'  // 重复
    ]
    editor.config.uploadImgShowBase64 = true

    editor.create()
    editorRef.current = editor
    return () => {
      editor.destroy()
    }
  }, [editor])

  const destoryEditor = () => {
    editor.destroy()
    editor = null
  }

  return (
    <div className="all">
      <div className="text-area" >
        <div ref={editorElemMenu}
          style={{ backgroundColor: '#f1f1f1', border: "1px solid #ccc" }}
          className="editorElem-menu">

        </div>
        <div
          style={{
            padding: "0 10px",
            overflowY: "scroll",
            height: 200,
            border: "1px solid #ccc",
            borderTop: "none",
          }}
          ref={editorElemBody} className="editorElem-body">

        </div>
      </div>
    </div>

  );
})

export default RichEditor
