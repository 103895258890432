import React, { FC, useState, useEffect, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { connect, useSelector } from 'react-redux';
import { Layout, Menu, Dropdown, Row, Col, Space, message, Breadcrumb, Tooltip } from 'antd';
// import { DownOutlined, MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import * as icons from '@ant-design/icons'
import Icon from '@ant-design/icons'
import { Scrollbars } from 'react-custom-scrollbars';
import cname from 'classnames';
import { Modal, PermissionButton } from '@comps';
import DeptTree from './deptTree';
import SwitchRole from './switchRole';
import Tabs from './tabs';

import { Context, Http, Lang } from '@utils';
import { UserContext } from '@models';

import './index.css';
import styles from './index.module.scss';

const { SubMenu } = Menu;
const { Header, Content, Sider } = Layout;

interface Props {
  logined: boolean,
  lastName?: string,
  roleName?: string,
  orgId?: number | string,
  deptName?: string,
  multiDeptFlag?: boolean,
  multiRoleFlag?: boolean,
  children: any,
  logout: Function,
}

const MainLayout: FC<Props> = ({
  logined,
  lastName,
  roleName,
  orgId,
  deptName,
  multiDeptFlag,
  multiRoleFlag,
  logout,
  children,
}) => {

  const tabsRef: any = useRef();

  const ctx = Context.getContext();
  const appName = ctx['global.app-name'];
  const userInfo = useSelector<JSONObject, UserContext>((state) => state.ctx);
  const userUnderOrg = orgId && orgId > 0;

  const { permissions: userPermissions = [] } = useSelector<JSONObject, UserContext>((state) => {
    return state.ctx;
  });
  const hasPermissions = React.useMemo(() => {
    const _permissions = ["Org.detail"];
    return _permissions
      .findIndex(permission => userPermissions.includes(permission)) > -1;
  }, [["Org.detail"], userPermissions]);


  const [model, modalHolder] = Modal.useModal();
  let [menuTree, setMenuTree] = useState<any[]>();
  let [menuID, setmenuId] = useState<any[]>();
  const newMenus: Array<[]> = [];

  const [collapsed, setCollapsed] = useState(false);
  const [currentBreadcrumb, setCurrentBreadcrumb] = useState({ name: '', route: '' })

  const minHeigh = Context.getScreenHeigh() - 60 - 10;

  document.title = appName;

  useEffect(() => {
    refreshMenu()
    // hadleDeclareMenu()
  }, []);

  const path = useLocation()

  useEffect(() => {
    ctx['panes'].map((item: any) => {
      if (item.route == path.pathname) {
        setCurrentBreadcrumb({ name: item.title, route: item.route })
      }
    })
  }, [path.pathname])

  const refreshMenu = async () => {
    const menus = await Http.get('/sys/menus/roleMenus');
    setMenuTree(menus.filter((item: JSONObject) => item.menuName !== "首页"));
  }

  const handleSwitchDept = async () => {
    await model.open({
      width: '520px',
      content(resolve, reject) {
        return (<DeptTree onConfirm={resolve} onCancel={reject} />);
      }
    });
  }

  // const hadleDeclareMenu = () => {
  //   if (userInfo.orgId) {
  //     Http.post("/emenu/loadDeclareMenu").then((res) => {
  //       if (!res || res[0].error) {
  //         message.warning("请联系管理员,获取申报菜单接口请求失败")
  //         return;
  //       }
  //       Context.dispatch({ type: 'ACTION_LOGIN', data: {
  //         accountSettingURL: res[0].url,
  //         taxInfoURL: res[1].url,
  //         volumnURL: res[2].url,
  //       }})
  //     })
  //   }
  // }

  const handleSwitchRole = async () => {
    await model.open({
      width: '520px',
      content(resolve, reject) {
        return (<SwitchRole onConfirm={resolve} onCancel={reject} />);
      }
    });

    await refreshMenu();
    Context.handleAdditionalPane('首页', `/sys/users/profile`, '/sys/users/profile')
  }

  const userOptionsOnClick = (e: any) => {
    const key = e.key;

    switch (key) {
      case '10':
        Context.handleAdditionalPane('账号信息', `/sys/users/profile`, '/sys/users/profile')
        break;
      case '20':
        Context.handleAdditionalPane('公司详情', `/org/company/${orgId}`, orgId + '')
        break;
    }
  }

  const handleTabAdd = (tab: JSONObject) => {
    tabsRef.current.handleTabAdd(tab);
  }

  const userOptions = (
    <Menu onClick={userOptionsOnClick}>
      <Menu.Item key="10">账号信息</Menu.Item>
      {userUnderOrg && hasPermissions && <Menu.Item key="20">公司信息</Menu.Item>}
      {multiDeptFlag && <Menu.Item key="30" onClick={handleSwitchDept}>切换部门</Menu.Item>}
      {multiRoleFlag && <Menu.Item key="40" onClick={handleSwitchRole}>切换角色</Menu.Item>}
    </Menu>
  )

  const rootSubmenuKeys = [newMenus];
  const [openKeys, setOpenKeys] = React.useState(['0']);

  const onOpenChange = (keys: any) => {
    const latestOpenKey = keys.find((key: any) => openKeys.indexOf(key) === -1);
    console.log(latestOpenKey);
    if (rootSubmenuKeys.indexOf(latestOpenKey) !== -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  }
  
  return (
    <Layout style={{ minHeight: '100vh' }}>
      {logined && (
        <Sider
          collapsible
          collapsed={collapsed}
          style={{ height: minHeigh }}
          width={'14%'}
          className="site-layout-background"
          trigger={null}
        >
          {/* <Scrollbars style={{ height: minHeigh }} autoHide> */}
          <div className='site-layout-logo'>
            {
              collapsed ? <span style={{ color: '#fff', fontSize: '20px' }}>{appName.slice(0, 2)}</span> : <span style={{ color: '#fff', fontSize: '20px' }} className='extra-hidden'>{appName?.includes("-") ? appName?.substr(0, appName.indexOf("-")) : appName}</span>
            }
          </div>
          <Menu
            mode="inline"
            style={{ height: '100%', borderRight: 0, overflow: 'hidden', overflowY: 'auto' }}
            openKeys={openKeys}
            onOpenChange={onOpenChange}
            theme='dark'
          >
            {menuTree?.map((menu: any) => (
              <SubMenu key={menu.menuId} title={menu.menuName} icon={<Icon component={icons[menu.icon as keyof typeof icons] as React.ForwardRefExoticComponent<any>} />}>
                {menu.subMenus.map((subMenu: any) => (
                  <Menu.Item key={subMenu.menuId} title={subMenu.menuName} icon={<Icon component={icons[subMenu.icon as keyof typeof icons] as React.ForwardRefExoticComponent<any>} />}>
                    <Tooltip placement="right" title={subMenu.menuName}>
                    <Link
                      to={subMenu.route}
                      onClick={() => handleTabAdd(subMenu)}
                    >
                      {subMenu.menuName}
                    </Link>
                    </Tooltip>
                  </Menu.Item>
                ))}
              </SubMenu>
            ))}
          </Menu>
          {/* </Scrollbars> */}
        </Sider>
      )}
      <Layout>
        <Header style={{ padding: '0 24px', backgroundColor: '#fff' }}>
          <Row gutter={0} className={cname(styles.header)} align="middle">
            <Col>
              {React.createElement(collapsed ? icons.MenuUnfoldOutlined : icons.MenuFoldOutlined, {
                className: 'trigger',
                onClick: () => setCollapsed(!collapsed),
              })}
            </Col>
            <Col>
              <Breadcrumb style={{ margin: '16px 16px' }}>
                <Breadcrumb.Item>主页</Breadcrumb.Item>
                <Breadcrumb.Item>{currentBreadcrumb.name}</Breadcrumb.Item>
              </Breadcrumb>
            </Col>
            <Col className={cname(styles.null)} />
            <Col>
              <Space size='large' className={cname(styles.navList)}>
                {/* eslint-disable-next-line */}
                <a className={cname(styles.link)}>{deptName}</a>
                <Dropdown overlay={userOptions}>
                  <a className="ant-dropdown-link" href='/' style={{ fontSize: '13px' }} onClick={e => e.preventDefault()}>
                    {lastName} <icons.DownOutlined />
                  </a>
                </Dropdown>
                <div className={cname(styles.navItem)}>
                  {/* eslint-disable-next-line */}
                  <span className={cname(styles.link)}>{roleName}</span>
                </div>
                <div className={cname(styles.navItem)}>
                  {/* eslint-disable-next-line */}
                  <a className={cname(styles.link)}
                    onClick={async () => {
                      Context.route('/sys/message/')
                    }}>
                    未读消息（{userInfo.unreadMsgCount ?? 0}）
                  </a>
                </div>
                <div className={cname(styles.navItem)}>
                  {/* eslint-disable-next-line */}
                  <a className={cname(styles.link)}
                    onClick={() => Lang.noError(async () => {
                      Modal.confirm({
                        title: '信息',
                        content: '确定要退出系统吗?',
                        async onOk() {
                          await logout();
                          Context.route('/login');
                        },
                      });
                    })}>
                    退出
                  </a>
                </div>
              </Space>
            </Col>
          </Row>
        </Header>
        <Layout>
          {modalHolder}
          <Tabs ref={tabsRef} style={{ marginLeft: 10, marginTop: 5, top: 70, width: '100%', backgroundColor: '#f7f8f8', zIndex: 99, whiteSpace: 'nowrap', overflowY: 'hidden', overflowX: 'scroll', display: 'flex' }} />
          <Content style={{ margin: 0, height: minHeigh - 50, overflow: 'hidden', overflowY: 'auto' }}>
            {children}
          </Content>
        </Layout>
      </Layout>
    </Layout>
  )
}

const mapStateToProps = (state: any) => {
  return {
    lastName: state.ctx.lastName,
    roleName: state.ctx.roleName,
    logined: state.ctx.logined,
    orgId: state.ctx.orgId,
    deptName: state.ctx.deptName,
    multiDeptFlag: state.ctx.multiDeptFlag,
    multiRoleFlag: state.ctx.multiRoleFlag,
  }
}

const mapDispatchToProps = (dispatch: Function) => {
  return {
    logout: () => {
      dispatch({ type: 'ACTION_LOGOUT' })
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MainLayout);
