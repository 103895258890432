import React, { FC, Fragment, memo, useEffect, useRef, useState, } from "react";
import { useSelector } from "react-redux";
import { UserContext } from "@models";
import { PermissionButton } from "@comps";
import { Button, Input, Checkbox, message, Row, Space } from "antd";
import { Http } from "@utils";
import "../operate.moudles.css"

const { TextArea } = Input

interface Props {
  analysisDtoList?: any[];
  knowledgeId?: any;
}
const AnalysisList: FC<Props> = (props: Props) => {
  const userInfo = useSelector<JSONObject, UserContext>((state) => state.ctx);
  const { analysisDtoList, knowledgeId } = props
  const [id, setId] = useState()
  const selectListRef = useRef<any>([])
  const [analysisList, setAnalysisList] = useState<any[]>()
  const contentRef = useRef<string>()

  useEffect(() => {
    if (analysisDtoList) {
      setAnalysisList(analysisDtoList)
    }
  }, [analysisDtoList])

  const onChange = (list: any) => {
    selectListRef.current = list
  }

  const edit = () => {
    if (selectListRef.current?.length === 0 || selectListRef.current?.length >= 2) {
      return message.warning('只能选择一个进行编辑')
    } else {
      const { analysisName } = analysisDtoList?.find((item: any) => { return item.id === selectListRef.current[0] })
      if (userInfo.roleCode === 'ADMIN' || userInfo.fullName === analysisName) {
        setId(selectListRef.current[0])
      } else {
        return message.warning('您没有这个权限')
      }
    }
  }

  const sumbit = async () => {
    if (selectListRef.current?.length === 0 || selectListRef.current?.length >= 2) {
      return message.warning('只能选择一个进行编辑')
    } else {
      const { analysisName } = analysisDtoList?.find((item: any) => { return item.id === selectListRef.current[0] })
      if (userInfo.roleCode === 'ADMIN' || userInfo.fullName === analysisName) {
        setId(selectListRef.current[0])
      } else {
        return message.warning('您没有这个权限')
      }
      if (contentRef.current?.replace(/\s*/g, "").length === 0 || contentRef.current === undefined) {
        return message.warn('您还没有编辑')
      }
      const editObj = analysisList?.find((item: { id: any; }) => item.id === selectListRef.current[0])
      editObj.analysisContent = contentRef.current
      await Http.put('/analysis', editObj)
      message.success('修改成功')
    }
  }

  const del = async () => {
    console.log(selectListRef.current);
    let ids = null
    if (selectListRef.current.length === 0) {
      return message.warning('请选择要删除的政策解读信息')
    } else {
      const { analysisName } = analysisDtoList?.find((item: any) => { return item.id === selectListRef.current[0] })
      if (userInfo.roleCode === 'ADMIN' || userInfo.fullName === analysisName) {
        setId(selectListRef.current[0])
      } else {
        return message.warning('您没有这个权限')
      }
      if (selectListRef.current.length === 1) {
        ids = selectListRef.current[0]
      } else {
        ids = selectListRef.current.join(',')
      }
      await Http.del('/analysis', { 'ids': String(ids) })
      message.success('删除成功')
      console.log(knowledgeId)
      Http.get('/analysis', { knowledgeId }).then((res) => {
        console.log(res);
        setAnalysisList(res)

      })
    }
  }

  const contentChange = (e: any) => {
    contentRef.current = e.currentTarget.value
  }

  const renderStatus = (flag: number) => {
    switch (flag) {
      case 1:
        return "审核中"
      case 2:
        return "审核通过"
      case 3:
        return "审核不通过"
      case 4:
        return "待审核"
      default:
        break;
    }
  }

  return (
    <Row >
      <div style={{ width: '1130px', borderStyle: 'solid', borderWidth: '0.5px', borderColor: '#cccccc', position: 'relative' }}>
        <div style={{ position: 'absolute', right: '0', top: '-40px' }}>
          <Space>
            <PermissionButton
              permissions='PolicyAnalysis.update'
              onClick={() => sumbit()}
            >
              保存
            </PermissionButton>

            <PermissionButton
              permissions='PolicyAnalysis.update'
              onClick={() => edit()}
            >
              编辑
            </PermissionButton>
            <PermissionButton
              permissions='PolicyAnalysis.delete'
              danger
              onClick={() => del()}
            >
              删除
            </PermissionButton>
          </Space>
        </div>
        <Checkbox.Group style={{ width: '100%' }} onChange={onChange} >
          {analysisList?.map((item) => {
            return (
              <Fragment key={item.id}>
                <Checkbox value={item.id} style={{ paddingLeft: '10px' }}></Checkbox>
                <span style={{ marginLeft: '10px', marginRight: '20px' }} >解读人：{item.analysisName}</span>
                <span style={{ marginRight: '20px' }} >{item.analysisDate} </span>
                <span >{renderStatus(item.approvalStatus)} </span>
                <TextArea style={{ paddingLeft: '30px', border: '0', backgroundColor: '#ffffff', color: 'rgba(0, 0, 0, 0.8)' }}
                  defaultValue={item.analysisContent} disabled={id ? id !== item.id : true} onChange={contentChange} name='name' autoSize></TextArea>
                <br />
              </Fragment>
            )
          })}
        </Checkbox.Group>
      </div>
    </Row >
  )
}
export default memo(AnalysisList);