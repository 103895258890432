import React, { FC, useState } from 'react';
import { Space, Form, Input, DatePicker, Button, Col } from 'antd';
import moment from 'moment';

import { Page, Card, Table, TableSummaryRow } from '@comps';

import { FormUtil, DateUtils, Math } from '@utils'; 
import { ColumnProps } from '@comps/types';

const { Item } = Form;
const { RangePicker } = DatePicker;

const Trade: FC = () => {

  let [table, setTable] = useState<Table>();
  const [totalRemainAmount, setTotalRemainAmount] = useState(0);
  const [totalInvoicedAmount, setTotalInvoicedAmount] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [totalInvoicedTaxAmount, setTotalInvoicedTaxAmount] = useState(0);
  const [totalTaxAmount, setTotalTaxAmount] = useState(0);

  const currentPeriod = moment();  

  const onTableRef = (tableRef: Table) => {
    setTable(tableRef);
    table = tableRef;
  };

  const getValidTableParams = () => {
    const formParams = table?.getQueryPanelParams() ?? {};
    const { period } = formParams;

    // if (period) {
    //   formParams.period = DateUtils.momentToStr(period, 'YYYY-MM');
    // }

    return formParams;
  };

  const period = [
    DateUtils.monthFirstDay(), 
    DateUtils.monthLastDay(),
  ];

  const exportReport = () => {
    const formParams = getValidTableParams();
    const ids = table?.getSelectedRowKeys();

    FormUtil.exportExcelFile(
      '/input/report/exportInnerReport', 
      {
        ...formParams,
        ids,
      }
    );
  };

  const columns: ColumnProps[] = [
    { key: 'productCode', title: '产品编码', width: 180, fixed: 'left' },
    { key: 'period', title: 'ERP 会计期间', width: 150 },
    { key: 'invoiceDate', title: '开票日期', width: 150 },
    { key: 'buyerName', title: '购方公司名称', width: 220 },
    { key: 'sellerName', title: '销方公司名称', width: 220 },
    { key: 'productDesc', title: '物料描述', width: 300 },
    { key: 'bigCategory', title: '物料大类', width: 150 },
    { key: 'middleCategory', title: '物料中类', width: 150 },
    { key: 'smallCategory', title: '物料小类', width: 150 },
    { key: 'amount', title: 'ERP 计税总金额', width: 150, align: 'right' },
    { key: 'taxAmount', title: 'ERP 计税总税额', width: 150, align: 'right' },
    { key: 'invoicedTaxAmount', title: '已开税额', width: 150, align: 'right' },
    { key: 'remainTaxAmount', title: '未开税额', width: 150, align: 'right' },
    { key: 'invoicedAmount', title: '已开金额', width: 110, align: 'right', fixed: 'right' },
    { key: 'remainAmount', title: '未开金额', width: 110, align: 'right', fixed: 'right' },
  ];

  const setTableSummaryData = ({
    totalRemainAmount,
    totalInvoicedAmount,
    totalAmount,
    totalInvoicedTaxAmount,
    totalTaxAmount

  }: JSONObject) => {

    setTotalRemainAmount(totalRemainAmount);
    setTotalInvoicedAmount(totalInvoicedAmount);
    setTotalAmount(totalAmount);
    setTotalInvoicedTaxAmount(totalInvoicedTaxAmount);
    setTotalTaxAmount(totalTaxAmount);
  };

  const tableSummary = () => {
    return (
      <>
        <TableSummaryRow>
          <Col span={4}>
            合计未开金额: {Math.num2money(totalRemainAmount)}
          </Col>
          <Col span={5}>
            合计已开金额: {Math.num2money(totalInvoicedAmount)}
          </Col>
          <Col span={5}>
           合计已开税额: {Math.num2money(totalInvoicedTaxAmount)}
          </Col>
          <Col span={5}>
            合计ERP 计税总金额: {Math.num2money(totalAmount)}
          </Col>
          <Col span={5}>
            合计ERP 计税总税额: {Math.num2money(totalTaxAmount)}
          </Col>
        </TableSummaryRow>
     </> 
    );
  };

  return (
    <Page>
      <Card 
        title="关联交易进项发票明细表"
        actions={
          <Space>
            <Button
              type="primary"
              onClick={exportReport}
            >
              导出
            </Button>
          </Space>
        }
      >
        <Table
          onTableRef={onTableRef}
          url="/input/report/inputInnerReport"
          queryProps={{
            initialValues: {
              period
            }
          }}
          // dateFormat={{
          //   period: 'YYYY-MM',
          // }}
          columns={columns}
          summary={tableSummary}
          setTableSummaryData={setTableSummaryData}
          scroll={{ y: window.innerHeight - 300 }}
        >
          <Item name="buyerName">
            <Input placeholder="购方名称" />
          </Item>
          <Item name="sellerName">
            <Input placeholder="销方名称" />
          </Item>
          {/* <Item name="period">
            <DatePicker 
              placeholder="会计期间" 
              picker="month"
              style={{ width: '100%' }} 
            />
          </Item> */}
          <Item name="period">
            <RangePicker
              placeholder={['会计日期从', '会计日期至']}
              format="YYYY-MM"
              picker="month"
              inputReadOnly
            />
          </Item>
          <Item name="invoiceDatePeriod">
            <RangePicker
              placeholder={['开票日期从', '开票日期至']}
              format="YYYY-MM-DD"
              inputReadOnly
            />
          </Item>
          <Item />
        </Table>
      </Card>
    </Page>
  )
};

export default Trade;