import { ColumnProps } from "@comps/types";
import {
  Card as AdCard,
  Table as AdTable,
  Button,
  Form,
  InputNumber,
  Radio,
} from "antd";
import { Rule } from "antd/lib/form";
import Decimal from "decimal.js";
import _ from "lodash";
import * as React from "react";
import { useState } from "react";

interface Props {
  // ref: any;
  onClose: Function;
  data: Array<any>;
  selectOption: Array<any>;
  form: any;
  currentTax: Boolean;
}

interface fieldObjType {
  invoiceGoodsName: undefined | string;
  taxCode: undefined | string;
  goodsName: undefined | string;
  // discountFlag: Boolean;
  discountLineFlag: Boolean;
  taxAmount: undefined | number;
  withTaxAmount: undefined | number;
  taxRate: undefined | number;
  taxRateLabel: undefined | string;
  amount: undefined | number;
  discountAmount: undefined | number;
  discountTaxAmount: undefined | number;
  discountRate: undefined | number;
  price: undefined;
  withTaxPrice: undefined;
  quantity: undefined;
}

const DiscountModal: React.FC<Props> = ({
  onClose,
  data,
  selectOption,
  form,
  currentTax,
}) => {
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [inputType, setInputType] = useState(1);
  const [discountType, setDiscountType] = useState(1);
  const [withoutTaxMoneyTotal, setWithoutTaxMoneyTotal] = useState(0);
  const [taxMoneyTotal, setTaxMoneyTotal] = useState(0);
  const [filteredData, setfilteredData] = useState<any[]>([]);
  let discountTypeRef = React.useRef(1);

  const initColumns: ColumnProps[] = [
    {
      key: "invoiceGoodsName",
      dataIndex: "invoiceGoodsName",
      title: "项目名称",
      render: (text, field, index) => {
        return <p>{filteredData[field.name]?.invoiceGoodsName}</p>;
      },
    },
    {
      key: "withTaxAmount",
      dataIndex: "withTaxAmount",
      title: "金额",
      render: (text, field, index) => {
        return (
          <p>
            {currentTax
              ? filteredData[field.name]?.withTaxAmount
              : filteredData[field.name]?.amount}
          </p>
        );
      },
    },
    {
      key: "discount",
      dataIndex: "discountMoney",
      title: "折扣金额",
      render: (text, field, index) => {
        let { discountDetail } = form.getFieldsValue();
        return (
          <Form.Item
            style={{ margin: 0 }}
            name={[
              field.name,
              currentTax ? "discountTaxMoney" : "discountWithoutTaxMoney",
            ]}
            fieldKey={[
              field.fieldKey,
              currentTax ? "discountTaxMoney" : "discountWithoutTaxMoney",
            ]}
            rules={[
              {
                required: true,
                message: "请输入折扣金额",
              },
              {
                validator: (rule: any, value: any, callback: any) =>
                  handleMoneyValidator(rule, value, callback, field),
              },
            ]}
          >
            <InputNumber
              style={{ width: "150px" }}
              controls={false}
              //@ts-ignore
              min="0"
              //@ts-ignore
              max="999999999999.9999"
              maxLength={16}
              stringMode
              placeholder="请输入"
              onBlur={(e) => handleMoneyChange(e, field)}
            />
          </Form.Item>
        );
      },
    },
  ];

  const [columns, setColumns] = useState(initColumns);

  const InputRules: Rule[] = [
    {
      required: true,
      message:
        discountTypeRef.current === 1 ? "请输入折扣金额" : "请输入折扣比例",
    },
    {
      type: "number",
      min: 0,
      message:
        discountTypeRef.current === 1
          ? "折扣金额不能小于0"
          : "折扣比例不能小于0",
    },
  ];

  const onInputTypeChange = (e: any) => {
    const { value } = e.target;
    setInputType(value);
    refreshColumns();
  };

  const onDiscountTypeChange = (e: any) => {
    const { value } = e.target;
    discountTypeRef.current = value;
    setDiscountType(value);
    refreshColumns();
  };

  const handlePercentChange = (e: any, field: any) => {
    let { discountDetail } = form.getFieldsValue();
    let { value } = e.target;
    if (currentTax) {
      discountDetail[field.name]["discountTaxPercent"] = value;
    } else {
      discountDetail[field.name]["discountWithoutTaxPercent"] = value;
    }
    form.setFieldsValue({
      discountDetail,
    });
    // refreshColumns();
  };

  const handleMoneyChange = (e: any, field: any) => {
    let { discountDetail } = form.getFieldsValue();
    let { value } = e.target;
    if (currentTax) {
      discountDetail[field.name]["discountTaxMoney"] = value;
    } else {
      discountDetail[field.name]["discountWithoutTaxMoney"] = value;
    }
    form.setFieldsValue({
      discountDetail,
    });
  };

  //按比例折扣校验
  const handlePercentValidator = (rule: any, value: any, callback: any) => {
    if (Number(value) > 100) {
      callback("折扣比例不能大于100");
    }
    if (Number(value) <= 0) {
      callback("折扣比例不能小于0");
    }
    callback();
  };
  //按金额多行明细折扣
  const handleMoneyValidator = (
    rule: any,
    value: any,
    callback: any,
    field: any
  ) => {
    let { discountDetail } = form.getFieldsValue();
    if (currentTax) {
      if (Number(value) > discountDetail[field.name]["withTaxAmount"]) {
        callback("折扣金额不能大于原金额");
      }
    } else {
      if (Number(value) > discountDetail[field.name]["amount"]) {
        callback("折扣金额不能大于原金额");
      }
    }
    if (Number(value) <= 0) {
      callback("折扣金额不能小于0");
    }
    callback();
  };
  //按金额汇总折扣
  const handleAllMoneyValidator = (rule: any, value: any, callback: any) => {
    let { discountDetail } = form.getFieldsValue();
    if (currentTax) {
      if (Number(value) > taxMoneyTotal) {
        callback("折扣金额不能大于总金额");
      }
    } else {
      if (Number(value) > withoutTaxMoneyTotal) {
        callback("折扣金额不能大于总金额");
      }
    }
    if (Number(value) <= 0) {
      callback("折扣金额不能小于0");
    }
    callback();
  };
  const refreshColumns = () => {
    let { discountDetail } = form.getFieldsValue();
    const newColumns = columns.map((item: any) => {
      if (item.key == "discount") {
        if (discountTypeRef.current == 1) {
          return {
            ...item,
            dataIndex: "discountMoney",
            title: "折扣金额",
            render: (text: any, field: any, index: any) => {
              return (
                <Form.Item
                  style={{ margin: 0 }}
                  rules={[
                    {
                      required: true,
                      message: "请输入折扣金额",
                    },
                    {
                      validator: (rule: any, value: any, callback: any) =>
                        handleMoneyValidator(rule, value, callback, field),
                    },
                  ]}
                  name={[
                    field.name,
                    currentTax ? "discountTaxMoney" : "discountWithoutTaxMoney",
                  ]}
                  fieldKey={[
                    field.fieldKey,
                    currentTax ? "discountTaxMoney" : "discountWithoutTaxMoney",
                  ]}
                  // rules={[{ validator: handlePercentValidator }]}
                  validateTrigger={["onBlur"]}
                >
                  <InputNumber
                    style={{ width: "150px" }}
                    controls={false}
                    //@ts-ignore
                    min="0"
                    //@ts-ignore
                    max="999999999999.9999"
                    maxLength={15}
                    stringMode
                    placeholder="请输入"
                    onBlur={(e) => handleMoneyChange(e, field)}
                  />
                </Form.Item>
              );
            },
          };
        } else {
          return {
            ...item,
            title: "折扣比例（%）",
            dataIndex: "discountpercent",
            render: (text: any, field: any, index: any) => {
              return (
                <Form.Item
                  style={{ margin: 0 }}
                  rules={[
                    {
                      required: true,
                      message: "请输入折扣比例",
                    },
                    {
                      validator: (rule: any, value: any, callback: any) =>
                        handlePercentValidator(rule, value, callback),
                    },
                  ]}
                  name={[
                    field.name,
                    currentTax
                      ? "discountTaxPercent"
                      : "discountWithoutTaxPercent",
                  ]}
                  fieldKey={[
                    field.fieldKey,
                    currentTax
                      ? "discountTaxPercent"
                      : "discountWithoutTaxPercent",
                  ]}
                  validateTrigger={["onBlur"]}
                >
                  <InputNumber
                    style={{ width: "150px" }}
                    controls={false}
                    //@ts-ignore
                    min="0"
                    //@ts-ignore
                    max="999999999999.9999"
                    maxLength={15}
                    stringMode
                    placeholder="请输入"
                    onBlur={(e) => handlePercentChange(e, field)}
                  />
                </Form.Item>
              );
            },
          };
        }
      }
      return item;
    });
    setColumns(newColumns);
  };
  React.useEffect(() => {
    let withoutTaxMoneyTotal = 0;
    let taxMoneyTotal = 0;
    const filteredData = data.filter((item, index) =>
      selectOption.includes(index)
    );
    setfilteredData(filteredData);
    form.setFieldsValue({
      discountDetail: filteredData,
      discountType: 1,
      inputType: 1,
    });
    filteredData.map((item) => {
      withoutTaxMoneyTotal += item?.amount;
      taxMoneyTotal += item?.withTaxAmount;
    });
    setWithoutTaxMoneyTotal(withoutTaxMoneyTotal);
    setTaxMoneyTotal(taxMoneyTotal);
  }, []);

  React.useEffect(() => {
    setColumns(initColumns);
    // refreshColumns();
  }, [filteredData]);

  const handleDiscount = () => {
    let fieldsValue = form.getFieldsValue();
    let { lines, discountDetail } = form.getFieldsValue();
    let validateName =
      discountType === 1
        ? currentTax
          ? "discountTaxMoney"
          : "discountWithoutTaxMoney"
        : currentTax
        ? "discountTaxPercent"
        : "discountWithoutTaxPercent";

    const validate1 = form.validateFields([validateName]);

    const discountDetailValid = form
      .getFieldValue("discountDetail")
      .map((item: any, index: any) => {
        return ["discountDetail", index, validateName];
      });
    const validate2 = form.validateFields(discountDetailValid);
    let fieldObj: fieldObjType = {
      invoiceGoodsName: undefined,
      goodsName: undefined,
      taxCode: undefined,
      // discountFlag: true,
      discountLineFlag: true,
      taxAmount: undefined,
      withTaxAmount: undefined,
      taxRate: undefined,
      taxRateLabel: "",
      amount: undefined,
      discountAmount: undefined,
      discountTaxAmount: undefined,
      discountRate: undefined,
      price: undefined,
      withTaxPrice: undefined,
      quantity: undefined,
    };
    //inputType为1时为单行或多行汇总（通过勾选框数组判断单行OR多行） 为2时为多行明细
    if (inputType === 1) {
      //单行折扣
      if (
        selectOption?.length == 1 &&
        fieldsValue[validateName] !== undefined && //单行折扣校验
        fieldsValue[validateName] !== null
      ) {
        if (currentTax) {
          //判断是否含税 true含税 false不含税
          if (discountType == 1) {
            //判断是金额还是折扣 1：金额  2：比例
            fieldObj["withTaxAmount"] = Number(fieldsValue[validateName]) * -1;
          } else {
            //按比例计算
            let discountRate = Number(
              new Decimal(Number(fieldsValue[validateName]) / 100).toFixed(2)
            );
            fieldObj["discountRate"] = discountRate;
            fieldObj["withTaxAmount"] = Number(
              new Decimal(taxMoneyTotal)
                .mul(new Decimal(discountRate))
                .mul(-1)
                .toFixed(2)
            );
          }
          fieldObj["taxRate"] = filteredData[0]?.taxRate;
          fieldObj["amount"] = Number(
            new Decimal(fieldObj["withTaxAmount"])
              .div(new Decimal(1 + Number(fieldObj["taxRate"])))
              .toFixed(2)
          );
          fieldObj["taxAmount"] = Number(
            new Decimal(fieldObj["withTaxAmount"])
              .sub(new Decimal(fieldObj["amount"]))
              .toFixed(2)
          );
          fieldObj["invoiceGoodsName"] = filteredData[0]?.invoiceGoodsName;
          fieldObj["goodsName"] = filteredData[0]?.goodsName;
          fieldObj["taxRateLabel"] = filteredData[0]?.taxRateLabel;
          fieldObj["taxCode"] = filteredData[0]?.taxCode;
        } else {
          //不含税时
          if (discountType == 1) {
            //判断是金额还是折扣 1：金额  2：比例
            fieldObj["amount"] = Number(fieldsValue[validateName]) * -1;
          } else {
            //按比例计算
            let discountRate = Number(
              new Decimal(fieldsValue[validateName])
                .div(new Decimal(100))
                .toFixed(2)
            );
            fieldObj["discountRate"] = discountRate;
            fieldObj["amount"] = Number(
              new Decimal(withoutTaxMoneyTotal)
                .mul(new Decimal(discountRate))
                .mul(new Decimal(-1))
                .toFixed(2)
            );
          }
          fieldObj["taxRate"] = filteredData[0].taxRate;
          fieldObj["taxAmount"] = Number(
            new Decimal(fieldObj["amount"])
              .mul(new Decimal(fieldObj["taxRate"] ?? 0))
              .toFixed(2)
          );
          fieldObj["withTaxAmount"] = Number(
            new Decimal(fieldObj["amount"])
              .add(new Decimal(fieldObj["taxAmount"]))
              .toFixed(2)
          );

          fieldObj["invoiceGoodsName"] = filteredData[0]?.invoiceGoodsName;
          fieldObj["goodsName"] = filteredData[0]?.goodsName;
          fieldObj["taxCode"] = filteredData[0]?.taxCode;
          fieldObj["taxRateLabel"] = filteredData[0]?.taxRateLabel;
        }
        lines[Number(selectOption[0])].discountFlag = true;
        fieldObj.discountAmount = fieldObj["amount"] //处理为正数
          ? Math.abs(fieldObj["amount"])
          : undefined;
        fieldObj.discountTaxAmount = fieldObj["withTaxAmount"] //处理为正数
          ? Math.abs(fieldObj["withTaxAmount"])
          : undefined;

        lines.splice(Number(selectOption[0]) + 1, 0, fieldObj);
        form.setFieldsValue({
          lines,
        });
        onClose();
        //多行汇总折扣
      } else if (
        selectOption?.length > 1 &&
        fieldsValue[validateName] !== undefined && //多行校验
        fieldsValue[validateName] !== null
      ) {
        if (currentTax) {
          ///判断是否含税 true含税 false不含税
          if (discountType == 1) {
            //判断是金额还是折扣 1：金额  2：比例
            //多行汇总折扣 按金额
            let disCountRowArr: any[] = []; //记录折扣行信息的数组
            let proportionArr = filteredData.map((item) => {
              //含税金额与总含税金额的比例数组
              return Number(
                new Decimal(item?.withTaxAmount)
                  .div(new Decimal(taxMoneyTotal))
                  .toFixed(2)
              );
            });
            proportionArr.map((item, index) => {
              fieldObj["withTaxAmount"] = Number(
                new Decimal(fieldsValue[validateName])
                  .mul(new Decimal(item))
                  .mul(-1)
                  .toFixed(2)
              );
              fieldObj["taxRate"] = filteredData[index]?.taxRate;
              fieldObj["taxRateLabel"] = filteredData[index]?.taxRateLabel;
              fieldObj["amount"] = Number(
                new Decimal(fieldObj["withTaxAmount"])
                  .div(
                    new Decimal(1).add(new Decimal(fieldObj["taxRate"] ?? 0))
                  )
                  .toFixed(2)
              );
              fieldObj["taxAmount"] = Number(
                new Decimal(fieldObj["withTaxAmount"])
                  .sub(new Decimal(fieldObj["amount"]))
                  .toFixed(2)
              );
              fieldObj["invoiceGoodsName"] =
                filteredData[index]?.invoiceGoodsName;
              fieldObj["goodsName"] = filteredData[index]?.goodsName;
              fieldObj["taxCode"] = filteredData[index]?.taxCode;

              fieldObj["discountAmount"] = fieldObj["amount"] //处理为正数
                ? Math.abs(fieldObj["amount"])
                : undefined;
              fieldObj["discountTaxAmount"] = fieldObj["withTaxAmount"] //处理为正数
                ? Math.abs(fieldObj["withTaxAmount"])
                : undefined;
              // fieldObj["discountFlag"] = true;
              fieldObj["discountLineFlag"] = true;

              let newFieldObj = Object.assign({}, fieldObj);
              console.log("fieldObj :>> ", fieldObj);
              disCountRowArr.push(newFieldObj);
            });
            //计算数组内含税金额的总和是否与输入的折扣含税金额相等，若不等将差额摊到最后一行折扣行
            const totalAmount = disCountRowArr.reduce(
              (accumulator, current) => accumulator + current.withTaxAmount,
              0
            );
            if (totalAmount !== Number(fieldsValue[validateName])) {
              disCountRowArr[disCountRowArr.length - 1] = {
                ...disCountRowArr[disCountRowArr.length - 1],
                withTaxAmount: Number(
                  new Decimal(
                    disCountRowArr[disCountRowArr.length - 1]["withTaxAmount"]
                  )
                    .sub(
                      new Decimal(fieldsValue[validateName]).add(
                        new Decimal(totalAmount)
                      )
                    )
                    .toFixed(2)
                ),
              };
            }

            //过滤出lines中勾选的数据并给他们加上折扣的标识,并在他们的后面插入折扣数据
            let newLines: any[] = [];
            let disCountRowArrIndex = 0; //记录折扣信息的索引
            const fileterLine = lines.map((item: any, index: any) => {
              if (selectOption.includes(index)) {
                //插入折扣行信息
                item["discountFlag"] = true;
                newLines.push(item);
                newLines.push(disCountRowArr[disCountRowArrIndex]);
                disCountRowArrIndex++;
              } else {
                newLines.push(item);
              }
            });
            form.setFieldsValue({
              lines: newLines,
            });
            onClose();
          } else {
            //多行汇总折扣 按比例
            //按比例计算
            let discountRate = Number(
              new Decimal(fieldsValue[validateName]).mul(100).toFixed(2)
            );
            fieldObj["discountRate"] = discountRate;
            let disCountRowArr: any[] = []; //记录折扣行信息的数组

            filteredData.map((item, index) => {
              fieldObj["withTaxAmount"] = Number(
                new Decimal(item["withTaxAmount"])
                  .mul(new Decimal(discountRate))
                  .mul(-1)
                  .toFixed(2)
              );
              fieldObj["taxRate"] = filteredData[index]?.taxRate;
              fieldObj["taxRateLabel"] = filteredData[index]?.taxRateLabel;
              fieldObj["amount"] = Number(
                new Decimal(fieldObj["withTaxAmount"]).div(
                  new Decimal(1).add(new Decimal(fieldObj["taxRate"] ?? 0))
                )
              );
              fieldObj["taxAmount"] = Number(
                new Decimal(fieldObj["withTaxAmount"])
                  .sub(new Decimal(fieldObj["amount"]))
                  .toFixed(2)
              );
              fieldObj["invoiceGoodsName"] =
                filteredData[index]?.invoiceGoodsName;
              fieldObj["goodsName"] = filteredData[index]?.goodsName;
              fieldObj["taxCode"] = filteredData[index]?.taxCode;

              fieldObj["discountAmount"] = fieldObj["amount"] //处理为正数
                ? Math.abs(fieldObj["amount"])
                : undefined;
              fieldObj["discountTaxAmount"] = fieldObj["withTaxAmount"] //处理为正数
                ? Math.abs(fieldObj["withTaxAmount"])
                : undefined;
              // fieldObj["discountFlag"] = true;
              fieldObj["discountLineFlag"] = true;
              //清空折扣行中的单价及金额
              fieldObj["price"] = undefined;

              let newFieldObj = Object.assign({}, fieldObj);
              disCountRowArr.push(newFieldObj);
            });
            //过滤出lines中勾选的数据并给他们加上折扣的标识,并在他们的后面插入折扣数据
            let newLines: any[] = [];
            let disCountRowArrIndex = 0; //记录折扣信息的索引
            const fileterLine = lines.map((item: any, index: any) => {
              if (selectOption.includes(index)) {
                //插入折扣行信息
                item["discountFlag"] = true;
                newLines.push(item);
                newLines.push(disCountRowArr[disCountRowArrIndex]);
                disCountRowArrIndex++;
              } else {
                newLines.push(item);
              }
            });
            form.setFieldsValue({
              lines: newLines,
            });
            onClose();
          }
          //不含税时
        } else {
          if (discountType == 1) {
            //判断是金额还是折扣 1：金额  2：比例
            //多行汇总折扣 按金额
            let disCountRowArr: any[] = []; //记录折扣行信息的数组
            let proportionArr = filteredData.map((item) => {
              //不含税金额与总不含税金额的比例数组
              return Number(
                new Decimal(item?.amount)
                  .div(new Decimal(withoutTaxMoneyTotal))
                  .toFixed(2)
              );
            });
            proportionArr.map((item, index) => {
              fieldObj["amount"] = Number(
                new Decimal(fieldsValue[validateName])
                  .mul(new Decimal(item))
                  .mul(-1)
                  .toFixed(2)
              );
              fieldObj["taxRate"] = filteredData[index]?.taxRate;
              fieldObj["taxRateLabel"] = filteredData[index]?.taxRateLabel;
              fieldObj["taxAmount"] = Number(
                new Decimal(fieldObj["amount"]).mul(
                  new Decimal(fieldObj["taxRate"] ?? 0)
                )
              );
              fieldObj["withTaxAmount"] = Number(
                new Decimal(fieldObj["amount"])
                  .add(new Decimal(fieldObj["taxAmount"]))
                  .toFixed(2)
              );

              fieldObj["invoiceGoodsName"] = filteredData[0]?.invoiceGoodsName;
              fieldObj["goodsName"] = filteredData[index]?.goodsName;
              fieldObj["taxCode"] = filteredData[index]?.taxCode;

              fieldObj["discountAmount"] = fieldObj["amount"] //处理为正数
                ? Math.abs(fieldObj["amount"])
                : undefined;
              fieldObj["discountTaxAmount"] = fieldObj["withTaxAmount"] //处理为正数
                ? Math.abs(fieldObj["withTaxAmount"])
                : undefined;
              // fieldObj["discountFlag"] = true;
              fieldObj["discountLineFlag"] = true;

              let newFieldObj = Object.assign({}, fieldObj);
              disCountRowArr.push(newFieldObj);
            });
            //计算数组内含税金额的总和是否与输入的折扣含税金额相等，若不等将差额摊到最后一行折扣行
            const totalAmount = disCountRowArr.reduce(
              (accumulator, current) => accumulator + current.withTaxAmount,
              0
            );
            if (totalAmount !== Number(fieldsValue[validateName])) {
              disCountRowArr[disCountRowArr.length - 1] = {
                ...disCountRowArr[disCountRowArr.length - 1],
                withTaxAmount: Number(
                  new Decimal(
                    disCountRowArr[disCountRowArr.length - 1]["withTaxAmount"]
                  )
                    .sub(
                      new Decimal(fieldsValue[validateName]).add(
                        new Decimal(totalAmount)
                      )
                    )
                    .toFixed(2)
                ),
              };
            }
            //过滤出lines中勾选的数据并给他们加上折扣的标识,并在他们的后面插入折扣数据
            let newLines: any[] = [];
            let disCountRowArrIndex = 0; //记录折扣信息的索引
            const fileterLine = lines.map((item: any, index: any) => {
              if (selectOption.includes(index)) {
                //插入折扣行信息
                item["discountFlag"] = true;
                newLines.push(item);
                newLines.push(disCountRowArr[disCountRowArrIndex]);
                disCountRowArrIndex++;
              } else {
                newLines.push(item);
              }
            });
            form.setFieldsValue({
              lines: newLines,
            });
            onClose();
          } else {
            //多行汇总折扣 按比例
            //按比例计算
            let discountRate = Number(
              new Decimal(fieldsValue[validateName])
                .div(new Decimal(100))
                .toFixed(2)
            );
            fieldObj["discountRate"] = discountRate;
            let disCountRowArr: any[] = []; //记录折扣行信息的数组

            filteredData.map((item, index) => {
              fieldObj["amount"] = Number(
                new Decimal(item["amount"])
                  .mul(new Decimal(discountRate))
                  .mul(-1)
                  .toFixed(2)
              );
              fieldObj["taxRate"] = filteredData[index]?.taxRate;
              fieldObj["taxRateLabel"] = filteredData[index]?.taxRateLabel;
              fieldObj["taxAmount"] = Number(
                new Decimal(fieldObj["amount"])
                  .mul(new Decimal(fieldObj["taxRate"] ?? 0))
                  .toFixed(2)
              );

              fieldObj["withTaxAmount"] = Number(
                new Decimal(fieldObj["amount"])
                  .add(new Decimal(fieldObj["taxAmount"]))
                  .toFixed(2)
              );
              fieldObj["invoiceGoodsName"] = filteredData[0]?.invoiceGoodsName;
              fieldObj["goodsName"] = filteredData[index]?.goodsName;
              fieldObj["taxCode"] = filteredData[index]?.taxCode;

              fieldObj["discountAmount"] = fieldObj["amount"] //处理为正数
                ? Math.abs(fieldObj["amount"])
                : undefined;
              fieldObj["discountTaxAmount"] = fieldObj["withTaxAmount"] //处理为正数
                ? Math.abs(fieldObj["withTaxAmount"])
                : undefined;
              // fieldObj["discountFlag"] = true;
              fieldObj["discountLineFlag"] = true;

              let newFieldObj = Object.assign({}, fieldObj);
              disCountRowArr.push(newFieldObj);
            });
            //过滤出lines中勾选的数据并给他们加上折扣的标识,并在他们的后面插入折扣数据
            let newLines: any[] = [];
            let disCountRowArrIndex = 0; //记录折扣信息的索引
            const fileterLine = lines.map((item: any, index: any) => {
              if (selectOption.includes(index)) {
                //插入折扣行信息
                //插入折扣行信息
                item["discountFlag"] = true;
                newLines.push(item);
                newLines.push(disCountRowArr[disCountRowArrIndex]);
                disCountRowArrIndex++;
              } else {
                newLines.push(item);
              }
            });
            form.setFieldsValue({
              lines: newLines,
            });
            onClose();
          }
        }
      }
    } else {
      //多行明细折扣
      form.validateFields(discountDetailValid).then((res: any) => {
        if (currentTax) {
          //含税
          discountDetail.map((item: any) => {
            if (discountType == 1) {
              //明细折扣 按金额
              item["withTaxAmount"] = Number(
                new Decimal(item["discountTaxMoney"]).mul(-1)
              );
            } else {
              //明细折扣 按比例
              let discountRate = Number(
                new Decimal(item["discountTaxPercent"]).div(100).toFixed(2)
              );
              item["discountRate"] = discountRate;
              item["withTaxAmount"] = Number(
                new Decimal(item["withTaxAmount"])
                  .mul(new Decimal(discountRate))
                  .mul(-1)
                  .toFixed(2)
              );
            }
            item["amount"] = Number(
              new Decimal(item["withTaxAmount"])
                .div(new Decimal(1).add(new Decimal(item["taxRate"] ?? 0)))
                .toFixed(2)
            );
            item["taxAmount"] = Number(
              new Decimal(item["withTaxAmount"])
                .sub(new Decimal(item["amount"]))
                .toFixed(2)
            );
            item["discountTaxAmount"] = Number(item["discountTaxMoney"]); //输入的折扣含税金额
            item["discountAmount"] = Math.abs(item["amount"]); //处理为正数
            // item["discountFlag"] = true;
            item["discountLineFlag"] = true;
            //折扣行不显示单价及数量
            item["price"] = undefined;
            item["withTaxPrice"] = undefined;
            item["quantity"] = undefined;
          });

          //过滤出lines中勾选的数据并给他们加上折扣的标识,并在他们的后面插入折扣数据
          let newLines: any[] = [];
          let disCountRowArrIndex = 0; //记录折扣信息的索引
          const fileterLine = lines.map((item: any, index: any) => {
            if (selectOption.includes(index)) {
              //插入折扣行信息
              item["discountFlag"] = true;
              newLines.push(item);
              newLines.push(discountDetail[disCountRowArrIndex]);
              disCountRowArrIndex++;
            } else {
              newLines.push(item);
            }
          });
          form.setFieldsValue({
            lines: newLines,
          });
          onClose();
        } else {
          //不含税
          discountDetail.map((item: any) => {
            if (discountType == 1) {
              //明细行 按金额
              item["amount"] = Number(
                new Decimal(item["discountWithoutTaxMoney"]).mul(-1)
              );
            } else {
              //明细折扣 按比例
              let discountRate = Number(
                new Decimal(item["discountWithoutTaxPercent"])
                  .div(100)
                  .toFixed(2)
              );
              item["discountRate"] = discountRate;
              item["amount"] = Number(
                new Decimal(item["amount"])
                  .mul(new Decimal(discountRate))
                  .mul(-1)
                  .toFixed(2)
              );
            }

            item["taxAmount"] = Number(
              new Decimal(item["amount"])
                .mul(new Decimal(item["taxRate"]))
                .toFixed(2)
            );
            item["withTaxAmount"] = Number(
              new Decimal(item["amount"])
                .add(new Decimal(item["taxAmount"]))
                .toFixed(2)
            );
            item["discountTaxAmount"] = Math.abs(item["withTaxAmount"]); //处理为正数
            item["discountAmount"] = Number(item["discountWithoutTaxMoney"]); //输入的折扣不含税金额
            // item["discountFlag"] = true;
            item["discountLineFlag"] = true;
            //折扣行不显示单价及数量
            item["price"] = undefined;
            item["withTaxPrice"] = undefined;
            item["quantity"] = undefined;
          });

          //过滤出lines中勾选的数据并给他们加上折扣的标识,并在他们的后面插入折扣数据
          let newLines: any[] = [];
          let disCountRowArrIndex = 0; //记录折扣信息的索引
          const fileterLine = lines.map((item: any, index: any) => {
            if (selectOption.includes(index)) {
              //插入折扣行信息
              item["discountFlag"] = true;
              newLines.push(item);
              newLines.push(discountDetail[disCountRowArrIndex]);
              disCountRowArrIndex++;
            } else {
              newLines.push(item);
            }
          });
          form.setFieldsValue({
            lines: newLines,
          });
          onClose();
        }
      });
    }
  };

  return (
    <>
      <AdCard
        title="添加折扣"
        loading={loading}
        style={{ marginBottom: "0", padding: "32px" }}
      >
        <div
          style={{
            width: "460px",
            height: "110px",
            padding: "24px",
            background: "#f9fafd",
            margin: "24px 0px",
          }}
        >
          <div>商品金额{currentTax ? "（含税）" : "（不含税）"}</div>
          <div>
            <span style={{ fontSize: "36px", marginRight: "2px" }}>
              {currentTax ? taxMoneyTotal : withoutTaxMoneyTotal}
            </span>
            <span>{`共${selectOption?.length}行`}</span>
          </div>
        </div>
        <Form
          form={form}
          layout="vertical"
          initialValues={{ discountDetail: filteredData }}
        >
          <Form.Item
            label="折扣方式"
            name="discountType"
            initialValue={discountType}
          >
            <Radio.Group
              onChange={(e) => onDiscountTypeChange(e)}
              value={discountType}
            >
              <Radio value={1}>按金额折扣</Radio>
              <Radio value={2}>按比例折扣</Radio>
            </Radio.Group>
          </Form.Item>
          {selectOption.length > 1 ? (
            <Form.Item
              label="折扣录入方式"
              name="inputType"
              initialValue={inputType}
            >
              <Radio.Group
                onChange={(e) => onInputTypeChange(e)}
                value={inputType}
              >
                <Radio value={1}>批量折扣录入</Radio>
                <Radio value={2}>逐条折扣录入</Radio>
              </Radio.Group>
            </Form.Item>
          ) : null}

          {inputType === 1 ? (
            <Form.Item
              label={
                discountType === 1
                  ? currentTax
                    ? "折扣金额（含税）"
                    : "折扣金额（不含税）"
                  : currentTax
                  ? "折扣比例（%）（含税）"
                  : "折扣比例（%）（不含税）"
              }
              name={
                discountType === 1
                  ? currentTax
                    ? "discountTaxMoney"
                    : "discountWithoutTaxMoney"
                  : currentTax
                  ? "discountTaxPercent"
                  : "discountWithoutTaxPercent"
              }
              rules={[
                {
                  required: true,
                  message:
                    discountTypeRef.current === 1
                      ? "请输入折扣金额"
                      : "请输入折扣比例",
                },
                discountTypeRef.current === 1
                  ? {
                      validator: (rule: any, value: any, callback: any) =>
                        handleAllMoneyValidator(rule, value, callback),
                    }
                  : {
                      validator: (rule: any, value: any, callback: any) =>
                        handlePercentValidator(rule, value, callback),
                    },
              ]}
            >
              <InputNumber
                style={{ width: "150px" }}
                controls={false}
                //@ts-ignore
                min="0"
                //@ts-ignore
                max="999999999999.9999"
                maxLength={16}
                stringMode
                placeholder="请输入"
              />
            </Form.Item>
          ) : (
            <Form.List name="discountDetail">
              {(fields, { add, remove }) => {
                return (
                  <div>
                    {discountType === 1
                      ? currentTax
                        ? "折扣金额（含税）"
                        : "折扣金额（不含税）"
                      : currentTax
                      ? "折扣比例（%）（含税）"
                      : "折扣比例（%）（不含税）"}
                    <AdTable
                      dataSource={fields}
                      columns={columns}
                      rowKey="key"
                      pagination={false}
                    />
                  </div>
                );
              }}
            </Form.List>
          )}
        </Form>
      </AdCard>
      <div
        style={{
          textAlign: "center",
          marginTop: "16px",
          marginBottom: "16px",
        }}
      >
        <Button onClick={() => onClose()} style={{ marginRight: "16px" }}>
          关闭
        </Button>
        <Button type="primary" onClick={() => handleDiscount()}>
          确定
        </Button>
      </div>
    </>
  );
};

export default DiscountModal;
