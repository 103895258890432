import React, { FC } from 'react';
import { Input as AdInput, Form } from 'antd';
import { InputProps } from 'antd/lib/input';
import { FormItemProps } from 'antd/lib/form';

const { Item } = Form;

const DisplayInput: FC<InputProps> = (props: InputProps) => {
  let disabled = props.disabled;
  let style = props.style;

  const defaultStyle = {
    // paddingLeft:0, 
    border: 0, 
    backgroundColor: 'white', 
    color: 'rgba(0, 0, 0, 0.75)'
  };

  if (!style) {
    style = {};
  };

  if (!disabled) {
    disabled = true;
  };

  return disabled
    ? <AdInput 
        {...props} 
        disabled 
        style={{...defaultStyle, ...style}}  
      />
    : <AdInput  {...props} />
};

/**
 * hidden状态的Form.Item
 * @param props 
 */
interface HiddenItemProps extends FormItemProps {
  value?: any
};

const HiddenFormItem: FC<HiddenItemProps> = (props: HiddenItemProps) => {
  return (
    <Item 
      style={{ display: 'none' }} 
      {...props}
    >
      <AdInput value={props.value} />
    </Item>
  );
};

export { DisplayInput, HiddenFormItem };