import React, { FC, useState, useCallback, useMemo } from "react";
import {
  Space,
  Select,
  Table as AdTable,
  Row,
  Col,
  Alert,
  Spin,
  Form,
  DatePicker,
  Input,
  Popconfirm,
  Modal as AdModal,
  message,
  Button,
} from "antd";

import {
  Page,
  Card,
  Table,
  LinkText,
  ViewInvoice,
  Modal,
  PermissionButton,
} from "@comps";
import { Context, DateUtils, Math, Http, Env, Lang, Message } from "@utils";
import { ColumnProps } from "@comps/types";
import { TableRow } from "./interface";

import "./index.css";
import { clearInterval, setInterval } from "timers";

const { Option } = Select;
const { Item } = Form;
const { confirm } = AdModal;
const { RangePicker } = DatePicker;

const AddedTax: FC = () => {
  const [viewInvoiceModal, viewInvoiceModalHolder] = Modal.useModal();
  const [loading, setLoading] = useState(false);
  const [model, modalHolder] = Modal.useModal();
  const initQuery = useMemo<JSONObject>(
    () => ({
      invDateStr: [DateUtils.monthFirstDay(), DateUtils.now()],
    }),
    []
  );

  const [table, setTable] = useState<Table>();
  const [tableSummaryInfo, setTableSummaryInfo] = useState<JSONObject>({});

  let currentExportType: number = 0;

  const [period, setPeriod] = useState<string>("");
  const [collectForm] = Form.useForm();

  const onTableRef = (tableRef: Table) => {
    setTable(tableRef);
  };

  const handleViewInvoice = useCallback(
    (picURL: string) => {
      Lang.noError(async () => {
        await viewInvoiceModal.open({
          width: 800,
          ignoreError: false,
          content(resolve, reject) {
            return <ViewInvoice onClose={reject} picUrl={picURL} />;
          },
        });
      });
    },
    [viewInvoiceModal]
  );

  const handleDelete = async (IDs: any) => {
    await Http.del(`/input/biz/delInvoice`, { ids: IDs });
    table?.refresh();
  };

  const handleBatchDelete = async () => {
    const IDs = table?.getSelectedRowKeys();
    if (IDs?.length === 0) {
      Message.warn("请至少勾选一条数据");
      return;
    }
    await Http.del(`/input/biz/delInvoice`, { ids: IDs?.join() });
    table?.refresh();
  };

  const showExportAllConfirm = () => {
    confirm({
      title: "提示",
      content: "是否导出所有数据",
      onOk: handleExportAllData,
    });
  };

  const handleSplicingURL = () => {
    let URL = `${Env.getBaseUrl()}/input/biz/InvoiceExport?accessToken=${Context.getToken()}`;

    URL += `&type=${currentExportType}`;

    // 导出时添加查询参数
    const queryParams: JSONObject = table?.getQueryPanelParams() ?? {};

    // 修改 “会计期间” 格式
    const { period } = queryParams;
    if (period) {
      queryParams.period = DateUtils.momentToNum(period);
    }

    for (let key in queryParams) {
      URL += `&${key}=${queryParams[key]}`;
    }

    return URL;
  };

  const handleExportData = () => {
    if (table?.getSelectedRowKeys()?.length === 0) {
      showExportAllConfirm();
      return;
    }

    let URL = handleSplicingURL();
    URL += `&ids=${table?.getSelectedRowKeys()?.join()}`;

    window.open(URL, "_blank");
  };

  const handleExportAllData = () => {
    const URL = handleSplicingURL();

    window.open(URL, "_blank");
  };

  const handleExportTypeChange = (type: any) => {
    currentExportType = type;
    handleExportData();
  };

  const handleCollect = () => {
    model.open({
      width: "900px",
      content(_1, reject) {
        return (
          <Card title="归集发票">
            <Form form={collectForm} layout="inline">
              <Item name="time" label="请选择归集日期">
                <DatePicker format="YYYY-MM" picker="month"></DatePicker>
              </Item>
            </Form>
            <Row>
              1、同一个开票月份的每一类发票每24小时申请下载请求不超过1次
            </Row>
            <Row>
              2、当前开票月份的每一类发票最多申请5次发票下载，历史开票月份的每一类发票每30天可申请一次发票下载
            </Row>
            <Row style={{ marginTop: 10 }}>
              <Col offset={8} span={4}>
                <Button type="primary" onClick={() => confirmCollect(reject)}>
                  确定
                </Button>
              </Col>
              <Col span={4}>
                <Button onClick={reject}>取消</Button>
              </Col>
            </Row>
          </Card>
        );
      },
    });
  };
  const confirmCollect = (close: Function) => {
    const { time } = collectForm.getFieldsValue() || {};
    if (!time) {
      message.warning("请选择归集日期");
      return;
    }
    const param = {
      getTime: DateUtils.date2str(time, "YYYY-MM"),
      invType: 1,
    };
    Http.get("/input/biz/WholeInvoice", param)
      .then((res) => {})
      .finally(() => {
        close();
      });
  };

  const getTargetRoute = React.useCallback((tableRow: TableRow) => {
    return `/income/authentication/check/${tableRow.id}`;
  }, []);

  const getTableSummaryInfo = async (queryObj: JSONObject) => {
    const summaryRes = await Http.get("/input/biz/getSumInvoices", queryObj);
    setTableSummaryInfo(summaryRes);
  };

  const handleGetAddress = () => {
    const id: Array<string> | undefined = table?.getSelectedRowKeys();
    if (id?.length !== 0) {
      let ids: string | undefined = id?.join();
      const URL = `${Env.getBaseUrl()}/input/biz/getDownloadUrl/${ids}?accessToken=${Context.getToken()}`;
      setLoading(true);
      const newwindow = window.open(URL, "_blank");
      let timer=setInterval(()=>{
        if (newwindow?.closed===true) {
            setLoading(false);
            clearInterval(timer)
        }
      },1000)
      
    } else {
      message.warning("未选中任何数据行");
    }
  };

  const handlPeriodChange = (date: any, dateString: string) => {
    setPeriod(dateString);
  };

  const columns: ColumnProps[] = [
    { key: "invoiceNo", title: "发票号码", width: 90, fixed: "left" },
    { key: "invoiceCode", title: "发票代码", width: 160, fixed: "left" },
    {
      key: "invoiceType",
      title: "发票类型",
      width: 200,
      render: (type) => {
        type = parseInt(type);
        if (type === 1) {
          return "增值税专用发票";
        } else if (type === 2) {
          return "货运运输业增值税专用发票";
        } else if (type === 3) {
          return "机动车增值税专用发票";
        } else if (type === 4) {
          return "增值税普通发票";
        } else if (type === 10) {
          return "电子普通发票";
        } else if (type === 11) {
          return "卷式发票";
        } else if (type === 13) {
          return "增值税电子专用发票";
        } else if (type === 14) {
          return "通行费发票";
        } else if (type === 15) {
          return "二手车发票";
        } else if (type === 16) {
          return "成品油发票";
        } else if (type === 17) {
          return "火车票";
        } else if (type === 18) {
          return "客运汽车票";
        } else if (type === 19) {
          return "航空运输电子行程票";
        } else if (type === 20) {
          return "出租车票";
        } else if (type === 21) {
          return "船票";
        } else if (type === 22) {
          return "过桥、过闸通行费";
        } else if (type === 23) {
          return "通用机打发票";
        } else if (type === 24) {
          return "定额发票";
        } else if (type === 31) {
          return "全电发票（增值税专用发票）";
        } else if (type === 32) {
          return "全电发票（普通发票）";
        } else if (type === 51) {
          return "定额发票";
        } else if (type === 99) {
          return "其他";
        } else {
          return "";
        }
      },
    },
    {
      key: "statusCode",
      title: "发票状态",
      width: 120,
      render: (status) => {
        if (status === 0) {
          return "正常";
        } else if (status === 1) {
          return "失控";
        } else if (status === 2) {
          return "作废";
        } else if (status === 3) {
          return "红冲";
        } else if (status === 4) {
          return "异常";
        } else {
          return "";
        }
      },
    },
    { key: "invoiceDate", title: "开票日期", width: 120 },
    { key: "voucherDate", title: "过账日期", width: 120 },
    // { key: 'uploadDate', title: '上传日期', width: 120 },
    { key: "checkCode", title: "校验码", width: 120 },
    // { key: 'checkCode', title: '验证时间' , width: 120 },
    { key: "buyerName", title: "购方名称", width: 200 },
    { key: "buyerTaxNo", title: "购方税号", width: 200 },
    { key: "sellerName", title: "销方名称", width: 200 },
    { key: "sellerTaxNo", title: "销方税号", width: 200 },
    { key: "amount", title: "不含税金额", width: 200, format: "amount" },
    { key: "taxAmount", title: "税额", width: 200, format: "amount" },
    { key: "withTaxAmount", title: "含税金额", width: 200, format: "amount" },
    { key: "remark", title: "备注", width: 200, align: "center" },
    {
      key: "gxStatus",
      title: "勾选状态",
      width: 200,
      render: (state) => {
        if (state === 0) {
          return "待勾选";
        } else if (state === 1) {
          return "抵扣勾选中";
        } else if (state === 2) {
          return "已抵扣勾选";
        } else if (state === 3) {
          return "撤销抵扣勾选中";
        } else if (state === 4) {
          return "不抵扣勾选中";
        } else if (state === 5) {
          return "已不抵扣勾选";
        } else {
          return "未知";
        }
      },
    },
    { key: "gxTime", title: "勾选时间", width: 200 },
    {
      key: "rzStatus",
      title: "认证状态",
      width: 200,
      render: (state) => {
        if (state === 0) {
          return "待认证";
        } else if (state === 1) {
          return "已认证";
        } else if (state === 2) {
          return "认证中";
        } else {
          return "未知";
        }
      },
    },
    // { key: 'approvalStatus', title: '报销状态', width: 200,
    //   render: approvalStatus => {
    //     if (approvalStatus === 0) {
    //       return '未审核'
    //     } else {
    //       return '已审批'
    //     }
    //   }
    // },
    { key: "rzTime", title: "认证时间", width: 200 },
    { key: "rzPeriod", title: "认证所属税期", width: 200 },
    // { key: 'source', title: '数据来源', width: 200, align: 'center',
    //   render: type => {
    //     type = parseInt(type)
    //     if (type === 7) {
    //       return '接口传入'
    //     } else if (type === 4) {
    //       return '手工录入'
    //     } else if (type === 10) {
    //       return '高拍仪录入'
    //     } else if (type === 11) {
    //       return '图片导入'
    //     } else if (type === 5) {
    //       return '文件导入'
    //     } else if (type === 6) {
    //       return '文件导入'
    //     } else if (type === 8) {
    //       return '文件导入'
    //     } else if (type === 9) {
    //       return '移动端录入'
    //     } else {
    //       return ''
    //     }
    //   }
    // },
    // { key: 'uploader', title: '创建人', width: 200 },
    // { key: 'uploadDate', title: '创建时间', width: 200 },
    { key: "bizNo", title: "业务单据号", width: 200 },
    { key: "period", title: "会计期间", width: 200 },
    { key: "voucherNo", title: "记账凭证号", width: 200 },
    // { key: 'documentAccount', title: '记账人', width: 200 },
    // { key: 'deductionFlag', title: '是否可抵扣', width: 140, align: 'center' },
    {
      key: "id",
      title: "操作",
      width: 160,
      fixed: "right",
      align: "center",
      render(id, row) {
        return (
          <Space>
            {/* <Link to={`/income/addedTax/${id}/detail`}>详情</Link> */}
            <LinkText
              label="查看"
              onClick={() => handleViewInvoice(row.picUrl)}
            />
            {/* <LinkText onClick={() => handleEdit(row)} label='编辑' /> */}
            <Popconfirm
              title="您确认要删除吗?"
              onConfirm={() => handleDelete(id)}
            >
              <PermissionButton
                permissions="InputInv.delBizInvoice"
                type="link"
              >
                删除
              </PermissionButton>
            </Popconfirm>
          </Space>
        );
      },
    },
  ];

  return (
    <Page>
      {viewInvoiceModalHolder}
      {modalHolder}
      <Spin spinning={loading}>
        <Card
          title="增值税发票管理"
          actions={
            <Space className="card-space">
              <PermissionButton
                permissions="InputInv.getDownloadUrl"
                type="primary"
                onClick={handleGetAddress}
              >
                获取发票地址
              </PermissionButton>
              <PermissionButton
                permissions="InputInv.wholeInvoices"
                type="primary"
                onClick={handleCollect}
              >
                发起归集
              </PermissionButton>
              <Select
                placeholder="导出"
                onSelect={handleExportTypeChange}
                style={{ width: 100 }}
              >
                <Option value={0}>汇总导出</Option>
                <Option value={1}>明细导出</Option>
              </Select>
              <PermissionButton
                permissions="InputInv.delBizInvoice"
                type="primary"
                onClick={handleBatchDelete}
              >
                批量删除
              </PermissionButton>
            </Space>
          }
        >
          <Table
            url="/input/biz/getInvoices"
            queryProps={{ initialValues: initQuery }}
            selectedRowKeys={[]}
            pagination={{ hideOnSinglePage: false }}
            columns={columns}
            onTableRef={onTableRef}
            querySpecialFormat={{ period }}
            getTableSummaryInfo={getTableSummaryInfo}
            // scroll={{ y: window.innerHeight - 300 }}
            summary={(invoices, selectIds) => {
              // 选中的数据
              const selectInvoices = invoices.filter((invoice) =>
                selectIds?.includes(invoice.id)
              );
              const invoiceTotal = selectInvoices.reduce(
                (total, invoice) => {
                  total.invoiceAmount += invoice.amount;
                  total.taxAmount += invoice.taxAmount;
                  total.totalAmount += invoice.withTaxAmount;
                  return total;
                },
                {
                  invoiceAmount: 0,
                  taxAmount: 0,
                  totalAmount: 0,
                }
              );

              return (
                <>
                  <AdTable.Summary.Row style={{ height: 36 }}>
                    <Row
                      gutter={14}
                      style={{ position: "absolute", left: 10, right: 0 }}
                    >
                      <Col span={6}>
                        选中数量: {selectInvoices?.length || 0}
                      </Col>
                      <Col span={6}>
                        选中不含税金额:{" "}
                        {Math.num2money(invoiceTotal.invoiceAmount)}
                      </Col>
                      <Col span={6}>
                        选中税额: {Math.num2money(invoiceTotal.taxAmount)}
                      </Col>
                      <Col span={6}>
                        选中含税金额: {Math.num2money(invoiceTotal.totalAmount)}
                      </Col>
                    </Row>
                  </AdTable.Summary.Row>

                  <AdTable.Summary.Row style={{ height: 36 }}>
                    <Row
                      gutter={14}
                      style={{ position: "absolute", left: 10, right: 0 }}
                    >
                      <Col span={6}>全部数量: {tableSummaryInfo.nums}</Col>
                      <Col span={6}>
                        全部不含税金额:{" "}
                        {Math.num2money(tableSummaryInfo.sumAmount)}
                      </Col>
                      <Col span={6}>
                        全部税额:{" "}
                        {Math.num2money(tableSummaryInfo.sumTaxAmount)}
                      </Col>
                      <Col span={6}>
                        全部含税金额:{" "}
                        {Math.num2money(tableSummaryInfo.sumWithTaxAmount)}
                      </Col>
                    </Row>
                  </AdTable.Summary.Row>
                </>
              );
            }}
          >
            <Item name="name">
              <Input
                placeholder="发票代码/号码;购方名称;销方名称"
                title="发票代码/号码;购方名称;销方名称"
              />
            </Item>
            <Item name="invDateStr">
              <RangePicker
                placeholder={["开票日期起", "开票日期止"]}
                format="YYYYMMDD"
                style={{ width: "100%" }}
                defaultValue={[
                  DateUtils.monthFirstDay(),
                  DateUtils.monthLastDay(),
                ]}
                inputReadOnly
              />
            </Item>
            <Item name="period" label="会计期间">
              <DatePicker
                picker="month"
                format="YYYYMM"
                onChange={handlPeriodChange}
                style={{ width: "100%" }}
                inputReadOnly
              />
            </Item>
            <Item name="invType">
              <Select placeholder="发票类型" mode="multiple">
                <Option value="">全部</Option>
                <Option value="01">增值税专用发票</Option>
                <Option value="08">增值税电子专用发票</Option>
                <Option value="04">增值税普通发票</Option>
                <Option value="10">增值税电子普通发票</Option>
                <Option value="11">卷式发票</Option>
                <Option value="14">通行费发票</Option>
                <Option value="03">机动车销售统一发票</Option>
                <Option value="15">二手车销售统一发票</Option>
                <Option value="16">成品油发票</Option>
              </Select>
            </Item>
            <Item name="voucherDateFromTo">
              <RangePicker
                placeholder={["过账日期起", "过账日期止"]}
                format="YYYYMMDD"
                style={{ width: "100%" }}
                inputReadOnly
              />
            </Item>
            {/* <Item name='createDateFromTo'>
              <RangePicker placeholder={['上传日期起', '上传日期止']} format='YYYYMMDD' style={{ width: '100%' }} inputReadOnly/>
            </Item> */}
            <Item name="invStatus">
              <Select placeholder="发票状态" mode="multiple">
                <Option value="">全部</Option>
                <Option value="0">正常</Option>
                <Option value="2">作废</Option>
                <Option value="3">红冲</Option>
                <Option value="1">失控</Option>
              </Select>
            </Item>
            {/* <Item name="source">
              <Select placeholder="数据来源" mode="multiple">
                <Option value="">全部</Option>
                <Option value="7">接口传入</Option>
                <Option value="4">手工录入</Option>
                <Option value="2">扫描仪录入</Option>
                <Option value="10">高拍仪录入</Option>
                <Option value="11">图片导入</Option>
                <Option value="5,6,8">文件导入</Option>
                <Option value="9">移动端录入</Option>
              </Select>
            </Item> */}
            {/* <Item name="documentAccount">
              <Input placeholder="记账人" />   
            </Item> */}
            <Item name="accountStatus">
              <Select placeholder="记账状态" allowClear>
                <Option value="">全部</Option>
                <Option value="1">已记账</Option>
                <Option value="0">未记账</Option>
              </Select>
            </Item>
            <Item name="voucherNo">
              <Input placeholder="记账凭证号" />
            </Item>
            {/* <Item name="deductionFlag">
              <Select placeholder="是否可抵扣" allowClear>
                <Option value={1}>是</Option>
                <Option value={0}>否</Option>
              </Select>
            </Item> */}
          </Table>
        </Card>
      </Spin>
    </Page>
  );
};

export default AddedTax;
