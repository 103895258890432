import React, { FC, useState, useEffect } from 'react'

import { Page, Card, Button, Modal, PermissionButton } from '@comps'
import { Select, Table as AdTable, Form, DatePicker, Spin } from 'antd'
import BookkeepingModal from './bookkeeping.md'
import OffBookkeepingModal from './offBookkeeping.md'

import { Http, Lang, Message } from '@utils'
import moment from 'moment'
import { Deduction } from './interface'

import './index.css'

const { Option } = Select
const { Item } = Form

const Bookkeeping: FC = () => {
  const [bookkeepingModal, bookkeepingModalHolder] = Modal.useModal()
  const [offBookkeepingModal, offBookkeepingModalHolder] = Modal.useModal()
  
  const [loading, setLoading] = useState<boolean>(false)

  const [currentType, setCurrentType] = useState<string>(Deduction.CERTIFICATION)

  const [form] = Form.useForm()

  let currentMonth: string = moment().format('YYYYMM')
  const [period, setPeriod] = useState<string>(currentMonth)
  
  const [bookkeepingState, setBookkeepingState] = useState<JSONObject>({})

  const [certificationDeductionSummary, setCertificationDeductionSummary] = useState<JSONObject>({})
  const [otherDeductionSummary, setOtherDeductionSummary] = useState<JSONObject>({})
  const [outDeductionSummary, setOutDeductionSummary] = useState<JSONObject>({})

  const [certificationDeductionDetail, setCertificationDeductionDetail] = useState<JSONObject[]>([])
  const [otherDeductionDetail, setOtherDeductionDetail] = useState<JSONObject[]>([])
  const [outDeductionDetail, setOutDeductionDetail] = useState<JSONObject[]>([])

  useEffect(() => {
    getQueryData(currentMonth)
   }, [])

  const getFormData = (): JSONObject => {
    const formData = form.getFieldsValue()
    const { period } = formData
    formData.period = moment(period).format('YYYYMM')
    return formData
  }

  const getCurrentDate = (): string => {
    const formData = form.getFieldsValue()
    let { period } = formData
    period = moment(period).format('YYYYMM')
    return period
  }

  const getQueryData = async (date: string) => {
    setLoading(true)

    const [_bookkeepingState, _certificationDeductionSummary, _otherDeductionSummary, _outDeductionSummary, _certificationDeductionDetail] = await Promise.all([
      Http.get('/input/chargeUp/checkChargeStatus', { period: date }),
      Http.get('/input/chargeUp/authenticationDeduction', { period: date }),
      Http.get('/input/chargeUp/otherDeduction', { period: date }),
      Http.get('/input/chargeUp/inputFrom', { period: date }),
      Http.get('/input/chargeUp/authenticationDeductionDetail', { period: date })
    ])

    setBookkeepingState(_bookkeepingState)
    setCertificationDeductionSummary(_certificationDeductionSummary)
    setOtherDeductionSummary(_otherDeductionSummary)
    setOutDeductionSummary(_outDeductionSummary)

    setCertificationDeductionDetail(_certificationDeductionDetail)

    setLoading(false)
  }

  const getCertificationDeductionDetailData = async () => {
    setCurrentType(Deduction.CERTIFICATION)
    const res = await  Http.get('/input/chargeUp/authenticationDeductionDetail', getFormData())
    setCertificationDeductionDetail(res)
  }

  const getOtherDeductionDetailData = async () => {
    setCurrentType(Deduction.OTHER)
    const res = await  Http.get('/input/chargeUp/otherDeductionDetails', getFormData())
    setOtherDeductionDetail(res)
  }

  const getOutDeductionDetailData = async () => {
    setCurrentType(Deduction.OUT)
    const res = await  Http.get('/input/chargeUp/inputFromDetail', getFormData())
    setOutDeductionDetail(res)
  }

  const handlePeriodChange = (date: any, dateString: string) => {
    setPeriod(dateString)
  }

  const refreshData = () => {
    getQueryData(period)
  }

  const handleWriteOffBookkeeping = () => {
    Http.get('/input/chargeUp/chargeAgainst', getFormData())
      .then(res => {
        Message.success(res.msg)
      })
  }

  const handleBookkeeping = React.useCallback(() => Lang.noError(async () => {
    const { period } = form.getFieldsValue()
    const periodStr: string = moment(period).format('YYYYMM')
    const periodNum: number = parseInt(periodStr)

    await bookkeepingModal.open({
      width: '400px',
      ignoreError: false,
      content(resolve, reject) {
        return (<BookkeepingModal period={periodNum} refreshData={refreshData} onClose={reject} />)
      }
    })
  }), [bookkeepingModal])

  const handleOffBookkeeping = React.useCallback(() => Lang.noError(async () => {
    await offBookkeepingModal.open({
      width: '400px',
      ignoreError: false,
      content(resolve, reject) {
        return (<OffBookkeepingModal refreshData={refreshData} onClose={reject} periodObj={getFormData()} />)
      }
    })
  }), [offBookkeepingModal])

  return (
    <Spin spinning={loading}>
      {bookkeepingModalHolder}
      {offBookkeepingModalHolder}

      <Page>
        <Card title="记账管理">
        <Form form={form} className="form" initialValues={{ period: moment(currentMonth) }}>
          <Item name="period" label="所属期间" rules={[{required: true}]}>
            <DatePicker picker="month" format="YYYYMM" onChange={handlePeriodChange} inputReadOnly/>
          </Item>
          <Item>
            <Button type="primary" onClick={() => {getQueryData(getCurrentDate())}}>查询</Button>
          </Item>
          <Item style={{ display: bookkeepingState.msg === '已记账' ? 'block' : 'none' }}>
            <PermissionButton permissions="ChargeUp.chargeAgainst" 
                              onClick={handleOffBookkeeping}>
                              冲销记账</PermissionButton>
          </Item> 
          <Item style={{ display: bookkeepingState.msg === '未记账' ? 'block' : 'none' }}>
            <PermissionButton permissions="ChargeUp.chargeUp" 
                              type="primary" 
                              onClick={handleBookkeeping}>
                              记账</PermissionButton>
          </Item> 
        </Form>
        <p>
          <span>记账状态：</span><span>{bookkeepingState.msg === '已记账' ? '已记账' : '未记账'}</span>
          <span className="bookkeeping-date">记账日期：</span><span>{bookkeepingState.chargeUpDate}</span>
        </p>
        <div className="bookkeeping-list">
          <div className="bookkeeping-item" style={{ borderColor: currentType === Deduction.CERTIFICATION ? '#3c95f4' : '#ccc' }}
               onClick={getCertificationDeductionDetailData}>
            <h3>认证抵扣</h3>
            <ul>
              <li>
                <span>份数</span>
                <span className="item-val">{certificationDeductionSummary.count}</span>
              </li>
              <li>
                <span>税额</span>
                <span className="item-val">{certificationDeductionSummary.taxAmount}</span>
              </li>
              <li>
                <span>金额</span>
                <span className="item-val">{certificationDeductionSummary.amount}</span>
              </li>
              <li>
                <span>凭证号</span>
                <span className="item-val">{certificationDeductionSummary.voucher}</span>
              </li>
            </ul>
          </div>
          <div className="bookkeeping-item" style={{ borderColor: currentType === Deduction.OTHER ? '#3c95f4' : '#ccc' }}
               onClick={getOtherDeductionDetailData}>
            <h3>其他抵扣</h3>
            <ul>
              <li>
                <span>份数</span>
                <span className="item-val">{otherDeductionSummary.count}</span>
              </li>
              <li>
                <span>税额</span>
                <span className="item-val">{otherDeductionSummary.taxAmount}</span>
              </li>
              <li>
                <span>金额</span>
                <span className="item-val">{otherDeductionSummary.amount}</span>
              </li>
              <li>
                <span>凭证号</span>
                <span className="item-val">{otherDeductionSummary.voucher}</span>
              </li>
            </ul>
          </div>
          <div className="bookkeeping-item" style={{ borderColor: currentType === Deduction.OUT ? '#3c95f4' : '#ccc' }}
               onClick={getOutDeductionDetailData}>
            <h3>进项转出</h3>
            <ul>
              <li>
                <span>份数</span>
                <span className="item-val">{outDeductionSummary.count}</span>
              </li>
              <li>
                <span>税额</span>
                <span className="item-val">{outDeductionSummary.taxAmount}</span>
              </li>
            </ul>
          </div>
        </div>

        {/* 认证明细 */}
        <div style={{ display: currentType === Deduction.CERTIFICATION ? 'block' : 'none' }} className="bookkeeping-table">
          <ul className="table-name">
            <li>认证抵扣汇总表</li>
          </ul>
          <ul className="table-header">
            <li>发票类型</li>
            <li>份数</li>
            <li>金额</li>
            <li>有效税额</li>
          </ul>
          { 
            certificationDeductionDetail.map((item) => {
              return (
                <ul className="table-row" key={item.name}>
                  <li>{item.name}</li>
                  <li>{item.count}</li>
                  <li>{item.amount}</li>
                  <li>{item.taxAmount}</li>
                </ul>
              )
            })
          }
        </div>

         {/* 其他明细 */}
         <div style={{ display: currentType === Deduction.OTHER ? 'block' : 'none' }} className="bookkeeping-table">
          <ul className="table-name">
            <li>其他抵扣汇总表</li>
          </ul>
          <ul className="table-header">
            <li>发票类型</li>
            <li>份数</li>
            <li>金额</li>
            <li>有效税额</li>
          </ul>
          {
            otherDeductionDetail.map((item) => {
              return (
                <ul className="table-row" key={item.name}>
                  <li>{item.name}</li>
                  <li>{item.count}</li>
                  <li>{item.amount}</li>
                  <li>{item.taxAmount}</li>
                </ul>
              )
            })
          }
        </div>

         {/* 转出明细 */}
         <div style={{ display: currentType === Deduction.OUT ? 'block' : 'none' }} className="bookkeeping-table">
          <ul className="table-name">
            <li>进项转出汇总表</li>
          </ul>
          <ul className="table-header">
            <li>转出原因</li>
            <li>份数</li>    
            <li>转出税额</li>
          </ul>
          {
            outDeductionDetail.map((item) => {
              return (
                <ul className="table-row" key={item.name}>
                  <li>{item.name}</li>
                  <li>{item.count}</li> 
                  <li>{item.taxAmount}</li>
                </ul>
              )
            })
          }
        </div>
      </Card>
    </Page>
    </Spin>
  )
}

export default Bookkeeping