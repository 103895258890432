/* eslint-disable react-hooks/exhaustive-deps */
import {
  ArrowDownOutlined,
  MinusOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { Button, Card, Modal } from "@comps";
import { Http } from "@utils";
import { Form, Input, Select, message } from "antd";
import * as React from "react";
import { Children, ReactNode } from "react";
import ChocieApproverModal from "./choiceapproverModal";

export interface Props {
  currentType: "add" | "edit";
  onCancel: () => void;
  onConfirm: () => void;
  data?: any;
  refresh?: () => void;
  Modaldata?: any;
}

let length: number = -1;
const AddapprovalProcessModal: React.FC<Props> = ({
  currentType,
  onCancel,
  onConfirm,
  data,
  refresh,
  Modaldata,
}) => {
  const [form] = Form.useForm();
  const [modal, modalHolder] = Modal.useModal();
  const [loading, setLoading] = React.useState(false);
  let [job, setJob] = React.useState<string[]>([]);
  let [ischange, setIschange] = React.useState<number>(0);
  let [nowselected, setNowselected] = React.useState<number>(0);
  let [hideamount, sethideamount] = React.useState<boolean>(true);
  const [outputInvoiceApprovalLevels, setOutputInvoiceApprovalLevels] =
    React.useState<any[]>([]);
  const [childComponent, setChildComponent] =
    React.useState<ReactNode | null>();
  const [textComponent, setTextComponent] = React.useState<ReactNode | null>();
  const layout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 5 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 17 },
    },
  };

  React.useEffect(() => {
    if (data) {
      form.setFieldsValue({ ...data });
      length = data.outputInvoiceApprovalLevels.length - 1;
      let arr: any[] = [];
      data.outputInvoiceApprovalLevels.forEach((value: any) => {
        let str = value.positionName + ":" + value.employeeName.join(",");
        arr.push(str);
      });
      setJob(arr);
      setOutputInvoiceApprovalLevels(data.outputInvoiceApprovalLevels);
      let approveType = form.getFieldValue("approveType");
      let invoiceType = form.getFieldValue("invoiceType");
      // if (
      //   (invoiceType === 31 && approveType === 2) ||
      //   (invoiceType === 32 && approveType === 1) ||
      //   (invoiceType === 85 && approveType === 2) ||
      //   (invoiceType === 86 && approveType === 2)
      // ) {
      //   sethideamount(false);
      // }
    }
  }, []);
  const onFinish = (values: any) => {
    setLoading(true);
    if (ischange) {
      message.warning("岗位信息不能为空");
      setLoading(false);
    } else if (job.includes("")) {
      message.warning("岗位信息不能为空");
    } else if (job.length === 0) {
      message.warning("请选择审批人");
      setLoading(false);
    } else {
      let params = {
        // amount: parseInt(form.getFieldValue("amount")) || null,
        amountStart: form.getFieldValue("amountStart"),
        amountEnd: form.getFieldValue("amountEnd"),
        approveName: form.getFieldValue("approveName"),
        approveType: form.getFieldValue("approveType"),
        invoiceType: form.getFieldValue("invoiceType"),
        level: outputInvoiceApprovalLevels.length,
        outputInvoiceApprovalLevels: outputInvoiceApprovalLevels,
        status: form.getFieldValue("status"),
        id: data?.id,
      };

      if (data) {
        // if (
        //   (params.invoiceType === 31 && params.approveType === 2) ||
        //   (params.invoiceType === 32 && params.approveType === 1) ||
        //   (params.invoiceType === 85 && params.approveType === 2) ||
        //   (params.invoiceType === 86 && params.approveType === 2)
        // ) {
        //   delete params.amountStart;
        //   delete params.amountEnd;
        // }
        Http.put("/outputInvoiceApprovalProcess/update", params)
          .then((res) => {
            setLoading(false);
            onConfirm();
            length = -1;
            setJob([]);
            if (refresh) refresh();
          })
          .catch((err) => {
            setLoading(false);
          });
      } else {
        Http.post("/outputInvoiceApprovalProcess/add", params)
          .then((res) => {
            setLoading(false);
            onConfirm();
            length = -1;
            setJob([]);
            if (refresh) refresh();
          })
          .catch((err) => {
            setLoading(false);
          });
      }
    }
  };

  const choicePoint = (value: any) => {
    modal.open({
      width: 800,
      content(resolve, reject) {
        return (
          <ChocieApproverModal
            onConfirm={resolve}
            onCancel={reject}
            onValueChange={getItem}
            value={value}
          ></ChocieApproverModal>
        );
      },
    });
  };

  let component: ReactNode[] = [];
  const onIdentityChange = (isrefresh: boolean = true) => {
    if (isrefresh) {
      length++;
      setIschange(1);
    }
    let componentlength = component.length;
    for (let index = componentlength; index <= length; index++) {
      component = [
        ...component,
        <div
          key={index}
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          {componentlength === index ? (
            <></>
          ) : (
            <ArrowDownOutlined
              style={{ marginBlock: "8px", fontSize: "20px" }}
            />
          )}
          <Button style={{ width: "100%" }} onClick={() => choicePoint(index)}>
            <span>{job[index]}</span> {/* 使用当前的 job 值 */}
          </Button>
        </div>,
      ];
    }
    setChildComponent(component);
  };

  const onIdentitydel = () => {
    const childArray = Children.toArray(childComponent);
    const filteredChildren = childArray.slice(0, childArray.length - 1);
    if (job.length >= childArray.length) {
      job.pop();
      outputInvoiceApprovalLevels.pop();
    }

    setIschange(0);
    if (length > -1) length--;
    setChildComponent(filteredChildren);
  };

  // let OutputInvoiceApprovalLevels:any[]=[]
  const getItem = (value: any[]) => {
    let str: string = value[0][0].jobName + ":";
    value[0][0].optionsWithDisabled.forEach((item: any) => {
      str = str + " " + item.label;
    });
    setIschange(0);
    let arr: string[] = [];
    for (let index = 0; index <= length; index++) {
      if (index === value[1]) arr.push(str);
      else {
        if (job[index] === undefined) arr.push("");
        else arr.push(job[index]);
      }
    }
    let params = {
      employeeId: value[0][0].employeeId,
      positionId: value[0][0].positionId,
      levelNo: value[1] + 1,
    };

    // outputInvoiceApprovalLevels.push(params);
    outputInvoiceApprovalLevels[value[1]] = params;
    setOutputInvoiceApprovalLevels(outputInvoiceApprovalLevels);
    setNowselected(value[1]);
    setJob(arr);
  };

  React.useEffect(() => {
    onIdentityChange(false);
  }, [job]);

  const handleValidator = (
    rule: any,
    value: any,
    callback: any,
    caseName: string
  ) => {
    const { amountStart, amountEnd } = form.getFieldsValue();
    switch (caseName) {
      case "amountStart":
        if (Number(value) < 0) {
          callback("金额不能小于0");
        }
        if ((amountEnd ?? "") !== "" && Number(value) > Number(amountEnd)) {
          callback("起始金额不能大于截止金额");
        }
        callback();
        break;
      case "amountEnd":
        if (Number(value) < 0) {
          callback("金额不能小于0");
        }
        if ((amountStart ?? "") !== "" && Number(value) < Number(amountStart)) {
          callback("截止金额不能小于起始金额");
        }
        callback();
        break;
      default:
        break;
    }
  };

  const hidetheamount = () => {
    const { invoiceType, approveType } = form.getFieldsValue();
    // if (
    //   (invoiceType === 31 && approveType === 2) ||
    //   (invoiceType === 32 && approveType === 1) ||
    //   (invoiceType === 85 && approveType === 2) ||
    //   (invoiceType === 86 && approveType === 2)
    // ) {
    //   sethideamount(false);
    // } else {
    //   sethideamount(true);
    // }
  };
  return (
    <Card title={currentType === "add" ? "新增审批" : "修改审批"}>
      {modalHolder}
      <Form
        form={form}
        // style={{ padding: "16px" }}
        {...layout}
        onFinish={onFinish}
      >
        <Form.Item
          name="approveName"
          label="审批名称"
          rules={[{ required: true, message: "请填写审批名称" }]}
        >
          <Input placeholder="请输入审批名称" />
        </Form.Item>
        <Form.Item
          name="approveType"
          label="审批类型"
          rules={[{ required: true, message: "请选择审批类型" }]}
        >
          {/* <Lookup lookupType="OUTPUT_INVOICE_APPROVE" placeholder="请选择审批类型" onChange={hidetheamount} /> */}
          <Select
            placeholder="请选择审批类型"
            onChange={hidetheamount}
            options={[
              {
                value: 1,
                label: "开票",
              },
              {
                value: 2,
                label: "红冲",
              },
            ]}
          />
        </Form.Item>
        <Form.Item
          name="invoiceType"
          label="发票类型"
          rules={[{ required: true, message: "请选择发票类型" }]}
        >
          <Select
            // onChange={onIdentityChange}
            onChange={hidetheamount}
            placeholder="请选择发票类型"
            options={[
              {
                value: 31,
                label: "数电票(增值税专用发票)",
              },
              {
                value: 32,
                label: "数电票(增值税普通发票)",
              },
              {
                value: 85,
                label: "数电纸质发票（增值税专用发票）",
              },
              {
                value: 86,
                label: "数电纸质发票（普票发票）",
              },
            ]}
          />
        </Form.Item>
        {hideamount ? (
          <>
            <Form.Item
              name="amountStart"
              label="审批金额起"
              rules={[
                { required: true, message: "请输入审批金额起" },
                {
                  validator: (rule: any, value: any, callback: any) =>
                    handleValidator(rule, value, callback, "amountStart"),
                },
              ]}
            >
              <Input
                placeholder="请输入审批金额起"
                type="number"
                onChange={() => {
                  form.validateFields(["amountStart", "amountEnd"]);
                }}
              />
            </Form.Item>
            <Form.Item
              name="amountEnd"
              label="审批金额止"
              rules={[
                { required: true, message: "请输入审批金额止" },
                {
                  validator: (rule: any, value: any, callback: any) =>
                    handleValidator(rule, value, callback, "amountEnd"),
                },
              ]}
            >
              <Input
                placeholder="请输入审批金额止"
                type="number"
                onChange={() => {
                  form.validateFields(["amountStart", "amountEnd"]);
                }}
              />
            </Form.Item>
          </>
        ) : (
          //   <Form.Item
          //     name="amount"
          //     label="审批金额"
          //     rules={[{ required: true, message: "请输入审批金额" }]}
          //   >
          //     <Input placeholder="请输入审批金额" type="number" />
          //   </Form.Item>
          <></>
        )}
        {data ? (
          <Form.Item
            name="status"
            label="状态"
            rules={[{ required: true, message: "请选择状态" }]}
          >
            <Select
              placeholder="请选择状态"
              options={[
                {
                  value: 1,
                  label: "启用",
                },
                {
                  value: 0,
                  label: "弃用",
                },
              ]}
            />
          </Form.Item>
        ) : (
          <></>
        )}
        <Form.Item label="审批流程">
          <div style={{ display: "flex" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
              }}
            >
              {childComponent}
              {/* <Button style={{ width: "100%" }}>岗位</Button>
                            <ArrowDownOutlined style={{marginBlock:"8px",fontSize:"20px"}}/>
                            <Button style={{ width: "100%" }}>岗位</Button> */}
            </div>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <Button
                style={{ marginLeft: "16px", marginBottom: "32px" }}
                type="primary"
                shape="circle"
                icon={<PlusOutlined />}
                onClick={() => onIdentityChange()}
              />
              <Button
                style={{ marginLeft: "16px" }}
                type="primary"
                shape="circle"
                icon={<MinusOutlined />}
                onClick={onIdentitydel}
              />
            </div>
          </div>
        </Form.Item>
        <div style={{ textAlign: "center" }}>
          <Button type="primary" htmlType="submit" loading={loading}>
            确认
          </Button>
          <Button
            onClick={() => {
              length = -1;
              setJob([]);
              onCancel();
            }}
            style={{ marginLeft: "16px" }}
          >
            取消
          </Button>
        </div>
      </Form>
    </Card>
  );
};

export default AddapprovalProcessModal;
