import React, { FC, useState, useEffect, memo } from 'react';
import { Space, Row, Col, Form, Button, Select } from 'antd';

import { Card, Lookup, HiddenFormItem } from '@comps';

import { Http, Message } from '@utils';

interface Props {
  isEdit: boolean;
  rowData: JSONObject;
  refreshTable: () => void;
  onClose: () => void;
};

const { Item } = Form;

const AddEditMD: FC<Props> = memo(({ 
  isEdit, 
  rowData, 
  refreshTable,
  onClose, 
}) => {

  const [form] = Form.useForm();
  const [SBUOptions, setSBUOptions] = useState<JSONObject[]>([]);
  const [remindPoints, setRemindPoints] = useState<JSONObject[]>([]); 
  const selectMode = isEdit ? undefined : "multiple";

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getSBUOptions();
    getRemindPoints();
  }, []);

  useEffect(() => {
    setFormFieldsVal();
  }, []);

  const getSBUOptions = () => {
    Http.get('/sys/sbu/findSbuByUser')
      .then((res: JSONObject[]) => {
        setSBUOptions(res);
      })
  };

  const getRemindPoints = () => {
    Http.get('/requestTip/queryTipPoint')
      .then(data => {
        setRemindPoints(data);
      })
  };

  const setFormFieldsVal = () => {
    if (isEdit) {
      form.setFieldsValue(rowData);
    };
  };

  const handleAddOrEdit = () => {
    setLoading(true);

    const formData = form.getFieldsValue();
    const url = 
      isEdit 
        ? '/requestTip/update' 
        : '/requestTip';

    Http.post(url, { ...rowData, ...formData })
      .then(() => {
        isEdit 
          ? Message.success('修改成功')
          : Message.success('新增成功');

        refreshTable();
      })
      .finally(() => {
        setLoading(false);
        onClose();
      });
  };

  return (
    <Card title={isEdit ? '修改场景' : '新增场景'}>
      <Form 
        form={form} 
        labelCol={{ span: 6 }} 
        wrapperCol={{ span: 16 }}
        onFinish={handleAddOrEdit} 
      >
        <Row>
          <Col span={24}>
            <HiddenFormItem name='id' />
            <Item 
              label="SBU 代码" 
              name={isEdit ? 'sbuId' : 'sbuIds'}
              rules={[{ required: true }]}
            >
              <Select
                mode={selectMode}
                showSearch
                optionFilterProp="key"
                options={SBUOptions.map(
                  SBUOption => ({
                    label: `${SBUOption.code} ${SBUOption.name}`,
                    value: SBUOption.id,
                  })
                )}
              />
            </Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Item 
              label="开票申请类型" 
              name="requestType"
              rules={[{ required: true }]}
            >
              <Lookup 
                mode={selectMode}
                placeholder="开票申请类型" 
                lookupType="OUTPUT_REQUEST_TYPE" 
              />
            </Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Item 
              label="业务场景提醒点" 
              name={isEdit ? 'tipId' : 'tipIds'}
              rules={[{ required: true }]}
            >
              <Select 
                mode={selectMode}
                options={remindPoints.map(
                  item => ({ 
                    label: item.tipCode, 
                    value: item.id, 
                  })
                )} 
              />
            </Item>
          </Col>
        </Row>
        <Row>
          <Col 
            span={24} 
            style={{ 
              marginTop: 14, 
              marginBottom: 14, 
              textAlign: 'center' 
            }}
          >
            <Space>
              <Button 
                type="primary" 
                htmlType="submit"
                loading={loading} 
              >
                确定
              </Button>
              <Button 
                onClick={onClose}
              >
                取消
              </Button>
            </Space>
          </Col>
        </Row>
      </Form>
    </Card >
  );
});

export default AddEditMD;
