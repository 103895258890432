import React, { FC, useState } from "react";
import { Input, Button, Space } from "antd";
import { Page, Card, Table, Modal, LinkText, PermissionButton } from "@comps";
import { ColumnProps } from "@comps/types";
import Add from "./addOrEdit";
import Stock from "./stock";
import AuthMd from "./auth.md";

const AgentList: FC = () => {
  const [model, modalHolder] = Modal.useModal();
  let [table, setTable] = useState<Table>();

  async function handleAddOrEdit(agent?: JSONObject) {
    await model.open({
      width: "600px",
      content(resolve, reject) {
        return <Add onConfirm={resolve} onCancel={reject} agent={agent} />;
      },
    });
    table?.refresh();
  }

  async function queryStock(agent?: JSONObject) {
    await model.open({
      width: "960px",
      content(resolve, reject) {
        return <Stock onConfirm={resolve} onCancel={reject} agent={agent} />;
      },
    });
    table?.refresh();
  }

  const openIfame = (row: JSONObject) => {
    const url = `https://taxadmin.jcsk100.com/admin/wap/index.html#/dzswjAuthLogin?nsrsbh=${
      row.taxNo ?? ""
    }&username=${row.taxAccountNo ?? ""}&sjhm=${
      row.taxPhone ?? ""
    }&user_password=${encodeURIComponent(row.taxPassword) ?? ""}&dlsf=${
      row.taxUserRole ?? ""
    }&reginCode=${row.taxRegionCode ?? ""}&sfzhm=${row.taxIdCardNo ?? ""}`;
    console.log("url :>> ", url);
    model.open({
      width: "600px",
      content(resolve, reject) {
        return (
          <Modal
            title="数电授权"
            visible={true}
            onCancel={reject}
            footer={null}
          >
            <iframe
              src={url}
              width="100%"
              height="700px"
              frameBorder="0"
              title="数电登录"
            />
          </Modal>
        );
      },
    });
  };

  async function grantAuth(agent: JSONObject) {
    await model.open({
      width: "960px",
      content(resolve) {
        return <AuthMd onCLose={resolve} agent={agent} />;
      },
    });
    table?.refresh();
  }

  const columns: ColumnProps[] = [
    { key: "orgName", title: "企业名称", width: 200 },
    { key: "taxNo", title: "税号", width: 150 },
    { key: "agentTypeDesc", title: "类型", width: 80 },
    { key: "taxInvoicer", title: "开票员名称", width: 80 },

    {
      key: "taxAccountNo",
      title: "电子税务局账号",
      width: 150,
      ellipsis: true,
    },
    {
      key: "id",
      title: "操作",
      width: 200,
      render: (_1, row) => (
        <Space size={0}>
          <Button type="link" onClick={() => handleAddOrEdit(row)}>
            修改
          </Button>
          <Button type="link" onClick={() => queryStock(row)}>
            查看库存
          </Button>
          <PermissionButton
            permissions="TaxAgent.grant"
            type="link"
            onClick={() => grantAuth(row)}
          >
            授权
          </PermissionButton>
          {row.agentType === "FULL_ELECTRIC" && (
            <Button type="link" onClick={() => openIfame(row)}>
              数电登录
            </Button>
          )}
        </Space>
      ),
    },
    {
      key: "online",
      title: "在线状态",
      width: 100,
      render: (value) =>
        value ? <LinkText label="在线" onClick={() => {}} /> : "不在线",
    },
  ];

  const onTableRef = (tableRef: Table) => {
    table = tableRef;
    setTable(tableRef);
  };

  return (
    <Page>
      {modalHolder}

      <Card
        title="税盘客户端"
        actions={
          <PermissionButton
            permissions="TaxAgent.create"
            type="primary"
            onClick={() => {
              handleAddOrEdit();
            }}
          >
            新增
          </PermissionButton>
        }
      >
        <Table
          url="/tax/agents"
          columns={columns}
          onTableRef={onTableRef}
          scroll={{ y: window.innerHeight - 300 }}
        >
          <Input name="key" placeholder="企业名称/税号" />
        </Table>
      </Card>
    </Page>
  );
};

export { AgentList };
