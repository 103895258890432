import TaxCode from './taxCode';
import MaterialCode from './materialCode';
import GoodsMapping from './goodsMapping';
import GoodsConversion from './goodsConversion';
import transferRoutes from './transfer/routes';
import Mail from './mail';
import MailDetail from './mail/detail/index';
import { AgentList } from './agent';
import { RouteProps } from '@models';
import Smkp from "./smkp";
import Good from "./goods";

const taxRoutes: RouteProps[] = [
  { path: '/tax/taxCodes', component: TaxCode, private: true },
  { path: '/tax/materialCode', component: MaterialCode, private: true },
  { path: '/tax/goodsMapping', component: GoodsMapping, private: true },
  { path: '/tax/goods-manage', component: Good, private: true },
  { path: '/tax/goodsConversions', component: GoodsConversion, private: true },
  { path: '/tax/agents', component: AgentList, private: true },
  { path: '/tax/mailingManagement', component: Mail, private: true },
  { path: '/tax/mail/:orderId/detail', component: MailDetail, private: true },
  { path: '/tax/outputInvoice/smkp', component: Smkp, private: true },
  ...transferRoutes,
]

export {
  taxRoutes
}
