import 
  React, { 
  FC, 
  useEffect, 
  useState, 
  useImperativeHandle, 
  useCallback, 
  useRef, 
  forwardRef, 
  ReactNode, 
  CSSProperties,
} from 'react';
import { Button, Space, Popconfirm, Col, Tag, Tabs } from 'antd';

import { Card, LinkText, Table, TableSummaryRow, Modal, Upload } from '@comps';
import ImportDetailTable from './modal/import-detail-table';
import EditApplyDetail from './modal/edit-apply-detail';
import MergeType from './modal/merge-type';
import AddOrCopyApplyDetail from './modal/add-copy-apply-detail';
import CrossCheck from './modal/cross-check';
import CrossCheckRowDetail from './modal/cross-check-row-detail';
import More from './modal/more';
import AddItemEdit from './modal/add-item-edit';

import { Http, Message, Math, FormUtil, Context } from '@utils';
import { ColumnProps } from '@comps/types';
import { 
  ApplyType, 
  ApplyStatus,
  DATA_RELEASE_TITLE,
} from '../../interface';
import { InvoicingTableType, CrossCheckType } from './interface';

import '../../index.css';

const { confirm } = Modal;
const { TabPane } = Tabs;

interface TableInfoProps {
  name: string;
  columns: ColumnProps[];
  table: Table | undefined;
  onTableRef: (tableRef: Table) => void;
  summary: () => ReactNode;
  isShowCheckBox: boolean;
  batchDelURL: string;
}

interface Props {
  ref: any;
  applyType: string;
  applyStatus: string;
  baseFormId: number;
  getBaseFormData: () => JSONObject;
  setAllModuleData: (data: JSONObject) => void;
  setPageLoading: (isLoading: boolean) => void;
  checkIsCanSave: () => Promise<any>;
  isSupplyOnly: boolean;
}

const InvoicingTable: FC<Props> = forwardRef(({
  applyType,
  applyStatus,
  baseFormId,
  getBaseFormData,
  setAllModuleData,
  setPageLoading,
  checkIsCanSave,
  isSupplyOnly,
}, ref) => {

  const baseFormIdRef = useRef(baseFormId);
  const applyStatusRef = useRef(applyStatus);
  const isSupplyOnlyRef = useRef(isSupplyOnly);

  const [activeTableIndex, setActiveTableIndex] = useState(0);

  const [isShowRefreshBTN, setIsShowRefreshBTN] = useState(false); 
  const [isShowAddBTN, setIsShowAddBTN] = useState(false);
  const [isShowMergeBTN, setIsShowMergeBTN] = useState(false);
  const [isShowRestoreBTN, setIsShowRestoreBTN] = useState(false);
  const [isShowWriteOffBTN, setIsShowWriteOffBTN] = useState(false);
  const [isShowAutoWriteOffBTN, setIsShowAutoWriteOffBTN] = useState(false);
  const [isShowIntroduceBTN, setIsShowIntroduceBTN] = useState(true);
  const [isShowMoreBTN, setIsShowMoreBTN] = useState(false);
  const [isShowBatchDelBTN, setIsShowBatchDelBTN] = useState(true);
  const [isShowAddItemRestoreBTN, setIsShowAddItemRestoreBTN] = useState(false);
  const [isShowExportBTN, setIsShowExportBTN] = useState(false);
  const [isShowImportBTN, setIsShowImportBTN] = useState(false);

  const issShowAddBTNRef = useRef(false);
  const issShowWriteOffBTNRef = useRef(false);
  const issShowMerageBTNRef = useRef(false);
  const isShowAutoWriteOffBTNRef = useRef(false);
  const isShowMoreBTNRef = useRef(false);
  const isShowAddItemRestoreBTNRef = useRef(false);
  const isShowExportBTNRef = useRef(false);
  const isShowImportBTNRef = useRef(false);
  const isShowBatchDelBTNRef = useRef(false);
  const isShowIntroduceBTNRef = useRef(false);
  const isShowRefreshBTNRef = useRef(false);

  let [saleTable, setSaleTable] = useState<Table>();
  let [otherTable, setOtherTable] = useState<Table>();
  let [applyTable, setApplyTable] = useState<Table>();
  let [mergeTable, setMergeTable] = useState<Table>();
  let [toBeOpenedTable, setToBeOpenedTable] = useState<Table>();

  const [saleTotalAmount, setSaleTotalAmount] = useState(0);
  const [saleTotalQuantity, setSaleTotalQuantity] = useState(0);
  const [otherTotalAmount, setOtherTotalAmount] = useState(0);
  const [otherTotalQuantity, setOtherTotalQuantity] = useState(0);
  const [applyTotalAmount, setApplyTotalAmount] = useState(0);
  const [applyTotalQuantity, setApplyTotalQuantity] = useState(0);
  const [mergeTotalAmount, setMergeTotalAmount] = useState(0);
  const [mergeTotalQuantity, setMergeTotalQuantity] = useState(0);
  const [toBeOpenTotalAmount, setToBeOpenTotalAmount] = useState(0);
  const [toBeOpenTotalQuantity, setToBeOpenTotalQuantity] = useState(0);
  const [toBeOpenTotalDiscount, setToBeOpenTotalDiscount] = useState(0);

  const [refreshLoading, setRefreshLoading] = useState(false);
  const [restoreLoading, setRestoreLoading] = useState(false);
  const [batchRestoreLoading, setBatchRestoreLoading] = useState(false);
  const [writeOffLoading, setWriteOffLoading] = useState(false);
  const [autoWriteOffLoading, setAutoWriteOffLoading] = useState(false);
  const [addItemRestoreLoading, setAddItemRestoreLoading] = useState(false);

  const [modal, modalHolder] = Modal.useModal();
  const [isShow, setIsShow] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  useEffect(() => {
    handleBTNShowByApplyType();
    judgeIdentity();
  }, []);

  useEffect(() => {
    baseFormIdRef.current = baseFormId;
  }, [baseFormId]);

  useEffect(() => {
    applyStatusRef.current = applyStatus;
    handleFnBTNShowByApplyStatus();
    judgeIdentity();
  }, [applyStatus]);
  
  useEffect(() => {
    isSupplyOnlyRef.current = isSupplyOnly;
  }, [isSupplyOnly]);

  const judgeIdentity = () => {
    if(Context.getContext().roleCode === 'CW' ) {
      setIsShow(true);
    }
    if(Context.getContext().roleCode === 'YWY' ) {
      setIsShow(false);
    }
  }

  useImperativeHandle(ref, () => ({
    setInvDetailTableDataSource,
    setInvDetailTableSummary,
    getInvoicingTableTotalAmount,
    checkToBeOpenedTableHasNegativeAmount,
  }));

  const handleBTNShowByApplyType = () => {
    // 自动退货核销
    isShowAutoWriteOffBTNRef.current = 
      (applyType === ApplyType.PROGRESS);

    // 增项还原、导入导出
    const _isShowAddItemRestoreBTN = 
      (applyType === ApplyType.MAIN_ASSISTANT);

    isShowImportBTNRef.current = _isShowAddItemRestoreBTN;
    isShowExportBTNRef.current = _isShowAddItemRestoreBTN;
    isShowAddItemRestoreBTNRef.current = _isShowAddItemRestoreBTN;

    // 添加
    if (
      (applyType === ApplyType.RETAIL) ||
      (applyType === ApplyType.SETTLE)
    ) {
      issShowAddBTNRef.current = false;
    } else {
      issShowAddBTNRef.current = true;
    }
    
    // 退货核销
    if (
      (applyType === ApplyType.OTHER) || 
      (applyType === ApplyType.ADVANCE) || 
      (applyType === ApplyType.PROGRESS) || 
      (applyType === ApplyType.SETTLE)
    ) {
      issShowWriteOffBTNRef.current = false;
    } else {
      issShowWriteOffBTNRef.current = true;
    }

    // 更多
    if (
      (applyType === ApplyType.RETAIL) ||
      (applyType === ApplyType.OTHER) 
    ) {
      isShowMoreBTNRef.current = true;
    } else {
      isShowMoreBTNRef.current = true; 
    }

    // 还原
    if (
      (applyType === ApplyStatus.INPROGRESS) ||
      (applyType === InvoicingTableType.MERGE) 
    ) {
      issShowMerageBTNRef.current = true;
    } else {
      issShowMerageBTNRef.current = false; 
    }

    // 批量删除
    if (
      (applyType === ApplyType.RETAIL) ||
      (applyType === ApplyType.SETTLE)
    ) {
      isShowBatchDelBTNRef.current = false;
    } else {
      isShowBatchDelBTNRef.current = true; 
    }

    // 引入明细
    if (
      (applyType === ApplyType.RETAIL) ||
      (applyType === ApplyType.SETTLE)
    ) {
      isShowIntroduceBTNRef.current = false;
    } else {
      isShowIntroduceBTNRef.current = true; 
    }

    // 刷新
    if (
      (applyType === ApplyType.RETAIL) ||
      (applyType === ApplyType.SETTLE)
    ) {
      isShowRefreshBTNRef.current = false;
    } else {
      isShowRefreshBTNRef.current = true; 
    }

  };

  const onSaleTableRef = (tableRef: Table) => {
    setSaleTable(tableRef);
    saleTable = tableRef;
  };

  const onOtherTableRef = (tableRef: Table) => {
    setOtherTable(tableRef);
    otherTable = tableRef;
  };

  const onApplyTableRef = (tableRef: Table) => {
    setApplyTable(tableRef);
    applyTable = tableRef;
  };

  const onMergeTableRef = (tableRef: Table) => {
    setMergeTable(tableRef);
    mergeTable = tableRef;
  };

  const onToBeOpenedTableRef = (tableRef: Table) => {
    setToBeOpenedTable(tableRef);
    toBeOpenedTable = tableRef;
  };

  const showImportDetailMD = () => {
    modal.open({
      width: 1400,
      content(_1, reject) {
        return (
          <ImportDetailTable
            baseFormId={baseFormId}
            applyType={applyType}
            setAllModuleData={setAllModuleData}
            onClose={reject}
          />
        )
      }
    });
  };

  const tableSummary = (
    tableData: JSONObject[],
    summaryProp: JSONObject,
  ): JSONObject => {

    const total = tableData.reduce((
      total: JSONObject, 
      row: JSONObject,
    ) => {
      
      const keys = Object.keys(summaryProp);

      keys.forEach((key) => {
        summaryProp[key] += row[key];
      })

      return total;
    }, summaryProp);

    return total;
  };

  const setInvDetailTableSummary = (data: JSONObject) => {
    const {
      requestSources,
      requestSourcesOther,
      requestLines,
      requestMergeInvLines,
      requestInvLines,
    }: { [key: string]: JSONObject[] } = data;

    const saleTotal = tableSummary(requestSources, {
      withTaxAmount: 0,
      quantity: 0
    });
    const otherTotal = tableSummary(requestSourcesOther, {
      withTaxAmount: 0,
      quantity: 0
    });
    const applyTotal = tableSummary(requestLines, {
      withTaxAmount: 0,
      quantity: 0
    });
    const mergeTotal = tableSummary(requestMergeInvLines, {
      withTaxAmount: 0,
      quantity: 0
    });
    const toBeOpenTotal = tableSummary(requestInvLines, {
      withTaxAmount: 0,
      withTaxDiscountAmount: 0,
      quantity: 0
    });

    setSaleTotalAmount(saleTotal.withTaxAmount);
    setSaleTotalQuantity(saleTotal.quantity);

    setOtherTotalAmount(otherTotal.withTaxAmount);
    setOtherTotalQuantity(otherTotal.quantity);

    setApplyTotalAmount(applyTotal.withTaxAmount);
    setApplyTotalQuantity(applyTotal.quantity);

    setMergeTotalAmount(mergeTotal.withTaxAmount);
    setMergeTotalQuantity(mergeTotal.quantity);

    setToBeOpenTotalAmount(toBeOpenTotal.withTaxAmount);
    setToBeOpenTotalDiscount(toBeOpenTotal.withTaxDiscountAmount)
    setToBeOpenTotalQuantity(toBeOpenTotal.quantity);
  };

  const setInvDetailTableDataSource = (data: JSONObject) => {
    const {
      requestSources,
      requestSourcesOther,
      requestLines,
      requestMergeInvLines,
      requestInvLines,
    } = data;

    saleTable?.setDataSource(requestSources);
    otherTable?.setDataSource(requestSourcesOther);
    applyTable?.setDataSource(requestLines);
    mergeTable?.setDataSource(requestMergeInvLines);
    toBeOpenedTable?.setDataSource(requestInvLines);
  };

  const handleInvoicingTableBTNsShow = (
    _isShowRefreshBTN: boolean,
    _isShowAddBTN: boolean,
    _isShowMergeBTN: boolean,
    _isShowRestoreBTN: boolean,
    _isShowWriteOffBTN: boolean,
    _isShowAutoWriteOffBTN: boolean,
    _isShowIntroduceBTN: boolean,
    _isShowMoreBTN: boolean,
    _isShowBatchDelBTN: boolean,
    _isShowAddItemRestoreBTN: boolean,
    _isShowExportBTN: boolean,
    _isShowImportBTN: boolean,
  ) => {

    setIsShowRefreshBTN(_isShowRefreshBTN);
    setIsShowAddBTN(_isShowAddBTN);
    setIsShowMergeBTN(_isShowMergeBTN);
    setIsShowRestoreBTN(_isShowRestoreBTN);
    setIsShowWriteOffBTN(_isShowWriteOffBTN);
    setIsShowAutoWriteOffBTN(_isShowAutoWriteOffBTN);
    setIsShowIntroduceBTN(_isShowIntroduceBTN);
    setIsShowMoreBTN(_isShowMoreBTN);
    setIsShowBatchDelBTN(_isShowBatchDelBTN);
    setIsShowAddItemRestoreBTN(_isShowAddItemRestoreBTN);
    setIsShowExportBTN(_isShowExportBTN);
    setIsShowImportBTN(_isShowImportBTN);
  };

  const handleFnBTNShowByApplyStatus = () => {
    switch (applyStatusRef.current) {
      case ApplyStatus.INPROGRESS:  
      case ApplyStatus.APPROVED:
      case ApplyStatus.REJECTED:
      case ApplyStatus.CANCEL:
        handleInvoicingTableBTNsShow(
          false,
          false, 
          false, 
          false, 
          false, 
          false,
          true, 
          false,
          true,
          false,
          false,
          false,
        );
        break;
    
      default:
        break;
    }
  };

  const handleFnBTNShow = (
    _activeTableIndex: number, 
    activeTableName: string,
  ) => {
    setActiveTableIndex(_activeTableIndex);

    switch (activeTableName) {
      case InvoicingTableType.SALE:
        handleInvoicingTableBTNsShow(
          false,
          false, 
          false, 
          false, 
          false, 
          false,
          true, // isShowIntroduceBTNRef.current, 
          false,
          true, //isShowBatchDelBTNRef.current,
          false,
          false,
          false,
        );
        break;
        
      case InvoicingTableType.OTHER:
        handleInvoicingTableBTNsShow(
          false,
          false, 
          false, 
          false, 
          false, 
          false,
          true, // isShowIntroduceBTNRef.current, 
          false,
          true, // isShowBatchDelBTNRef.current,
          false,
          false,
          false,
        );
        break;
      case InvoicingTableType.APPLY:
        handleInvoicingTableBTNsShow(
          true, // isShowRefreshBTNRef.current,
          true, // issShowAddBTNRef.current, 
          // false,
          false, 
          false, 
          false, 
          false,
          false, 
          false,
          true, // isShowBatchDelBTNRef.current,
          false,
          false,
          false,
        );

        break;
      case InvoicingTableType.MERGE:
        handleInvoicingTableBTNsShow(
          false,
          false, 
          false, 
          // issShowMerageBTNRef.current,
          true,
          false, 
          false,
          false, 
          false,
          false,
          false,
          false,
          false,
        );
        break;
      case InvoicingTableType.TOBO_OPENED:
        handleInvoicingTableBTNsShow(
          false,
          false, 
          true, 
          true, 
          // issShowWriteOffBTNRef.current, 
          false,
          isShowAutoWriteOffBTNRef.current,
          false, 
          isShowMoreBTNRef.current,
          false,
          isShowAddItemRestoreBTNRef.current,
          isShowExportBTNRef.current,
          isShowImportBTNRef.current,
        );

        break;
      default:
        break;
    };

    // if(Context.getContext().roleCode === 'CW' ) {
    //   handleFnBTNShowByApplyStatus();
    // }
  };

  const getActiveTableInfo = () => {
    return tableInfoArr[activeTableIndex];
  };

  const checkToBeOpenedTableQuantityHasNegative = () => {
    let hasNegative = false;
    const toBeOpenedTableQuantitys: number[] =
      toBeOpenedTable?.getSelectedRowAttr('quantity') ?? [];

    for(let i = 0; i < toBeOpenedTableQuantitys.length; i++) {
      if (toBeOpenedTableQuantitys[i] < 0) {
        hasNegative = true;
        break;
      }
    };

    return hasNegative;
  };

  const handleMerge = (isBatchMerge: boolean) => {
    const ids = toBeOpenedTable?.getSelectedRowKeys() ?? [];

    // 合并明细
    if (!isBatchMerge) {
      if (ids.length < 2) {
        Message.warn('请至少勾选两条以上数据');
        return;
      }
    }

    modal.open({
      width: 500,
      content: (_1, reject) => (
        <MergeType
          requestId={baseFormId}
          ids={ids}
          isBatchMerge={isBatchMerge}
          setAllModuleData={setAllModuleData}
          onClose={reject}
        />
      )
    })
  };

  const handleRestore = () => {
    const { table } = getActiveTableInfo();
    const ids = table?.getSelectedRowKeys() ?? [];

    if (ids.length === 0) {
      Message.warn('请至少一条数据');
      return;
    }

    setRestoreLoading(true);

    Http.post('/request/restoreLine', { ids })
      .then(data => {
        setAllModuleData(data);
      })
      .finally(() => {
        setRestoreLoading(false);
      });
  };

  const handleBatchRestore = () => {
    setBatchRestoreLoading(true);

    Http.post(`/request/${baseFormId}/restoreLine`)
      .then(data => {
        setAllModuleData(data);
        Message.success('还原成功');
      })
      .finally(() => {
        setBatchRestoreLoading(false);
      });
  };

  const handleWriteOff = () => {
    const ids = toBeOpenedTable?.getSelectedRowKeys() ?? [];

    if (ids.length <= 1) {
      Message.warn('请至少勾选两条以上数据');
      return;
    }

    setWriteOffLoading(true);

    Http.post('/request/cancelAfterVerification', { ids })
      .then(data => {
        setAllModuleData(data);
      })
      .finally(() => {
        setWriteOffLoading(false);
      });
  };

  const handleAutoWriteOff = () => {
    setAutoWriteOffLoading(true);

    Http.post(`/request/${baseFormId}/autoCancelAfterVerification`)
      .then(data => {
        setAllModuleData(data);
      })
      .finally(() => {
        setAutoWriteOffLoading(false);
      })
  };

  const handleSaleOrOtherTableDel = (id: number) => {
    Message.info('删除中');
    setPageLoading(true);

    Http.del(`/request/deleteSourceLine/${id}`)
      .then((data: JSONObject) => {
        setAllModuleData(data);
        Message.success('删除成功');
      })
      .finally(() => {
        setPageLoading(false);
      });
  };

  const handleMergeTableDel = (id: number) => {
    Message.info('删除中');
    setPageLoading(true);

    Http.del(`/request/deleteRequestLine/${id}`)
      .then((data: JSONObject) => {
        setAllModuleData(data);
        Message.success('删除成功');
      })
      .finally(() => {
        setPageLoading(false);
      });
  };

  const showEditApplyDetailMD = (row: JSONObject) => {
    modal.open({
      width: 1300,
      content: (_1, reject) => (
        <EditApplyDetail
          applyType={applyType}
          rowData={row}
          setAllModuleData={setAllModuleData}
          onClose={reject}
        />
      )
    })
  };

  const isShowBTN = (isShowBTN: boolean): CSSProperties => {
    return {
      display: isShowBTN ? 'block' : 'none',
      marginLeft: 8
    }
  };

  const getInvoicingTableTotalAmount = (): JSONObject => {
    return {
      saleTotalAmount,
      otherTotalAmount,
      applyTotalAmount,
    };
  };

  const handleSave = () => {
    const formData = getBaseFormData();

    Http.post('/request/save', formData)
      .then((data) => {
        setAllModuleData(data);
        showImportDetailMD();
      })
  };

  const handleIsCanSave = () => {
    checkIsCanSave()
      .then(isCanSave => {
        isCanSave 
          ? confirm({
            title: DATA_RELEASE_TITLE,
            onOk: handleSave
          })
          : handleSave()
      })
  };

  const showMoreMD = () => {
    modal.open({
      width: 1200,
      content: (_1, reject) => (
        <More
          baseFormId={baseFormIdRef.current}
          onClose={reject}
        />
      )
    })
  };

  const handleRefresh = () => {
    setRefreshLoading(true);

    const ids: number[] = applyTable?.getSelectedRowKeys() ?? [];

    Http.post(`/request/${baseFormId}/autoMatchTaxCode`, { ids })
      .then(data => {
        setAllModuleData(data);
        Message.success('刷新成功');
      })
      .finally(() => {
        setRefreshLoading(false);
      })
  };

  const handleBatchDel = (batchDelURL: string, ids: number[]) => {
    setPageLoading(true);

    Http.del(batchDelURL, { ids })
      .then((data) => {
        setAllModuleData(data);
        Message.success('删除成功');
      })
      .finally(() => {
        setPageLoading(false);
      });
  };

  const checkBatchDel = () => {
    const { table, batchDelURL } = getActiveTableInfo();
    const ids: number[] = table?.getSelectedRowKeys() ?? [];

    if (ids?.length === 0) {
      Message.warn('请至少选择一条数据');
      return;
    }

    Modal.confirm({
      title: `是否删除 ${ids.length} 条数据 ?`,
      onOk: () => handleBatchDel(batchDelURL, ids)
    })
  };

  const showAddOrCopyMD = (
    isCopy: boolean, 
    copyRowData: JSONObject
  ) => {

    modal.open({
      width: 1200,
      content: (_1, reject) => (
        <AddOrCopyApplyDetail
          baseFormId={baseFormIdRef.current}
          isCopy={isCopy}
          copyRowData={copyRowData}
          setAllModuleData={setAllModuleData}
          onClose={reject}
        />
      )
    });
  }

  const showCrossCheckMD = useCallback((
    tableName: string, 
    crossCheckRowId: number
  ) => {

    let orderType = '';
    const isToBeOpenedTable = 
      tableName === InvoicingTableType.TOBO_OPENED;
    const _isSupplyOnly = isSupplyOnlyRef.current;

    switch (tableName) {
      case InvoicingTableType.APPLY:
        orderType = 
          _isSupplyOnly 
            ? CrossCheckType.SO 
            : CrossCheckType.CARRY;
        break;

      case InvoicingTableType.TOBO_OPENED:
        orderType = CrossCheckType.SO;
        break;

      default:
        break;
    }

    modal.open({
      width: 1400,
      content: (_1, reject) => (
        <CrossCheck
          baseFormId={baseFormIdRef.current}
          crossCheckRowId={crossCheckRowId}
          setAllModuleData={setAllModuleData}
          orderType={orderType}
          isToBeOpenedTable={isToBeOpenedTable}
          onClose={reject}
        />
      )
    })
  }, [isSupplyOnly, baseFormId]);

  const showCrossCheckRowDetailMD = async (rowId: number) => {
    await modal.open({
      width: 1400,
      content: (_1, reject) => (
        <CrossCheckRowDetail
          rowId={rowId} 
          setAllModuleData={setAllModuleData} 
          onClose={reject} 
        />
      )
    });
  };

  const renderCheckStatus = (checkStatus: number) => {
    let checkStatusText = '';

    switch (checkStatus) {
      case 1:
        checkStatusText = '无需勾稽'
        break;

      case 2:
        checkStatusText = '部分勾稽'
        break;

      case 3:
        checkStatusText = '完全勾稽'
        break;

      default:
        break;
    }

    return checkStatusText;
  };

  const checkToBeOpenedTableHasNegativeAmount = () => {
    let hasNegativeAmount = false;
    const tableData: JSONObject[] = toBeOpenedTable?.getTableData();
    const amounts = tableData.map(row => row.amount);

    for (let i = 0; i < amounts.length; i++) {
      if (amounts[i] < 0) {
        hasNegativeAmount = true;
        break;
      }
    }

    return hasNegativeAmount
  };

  const showAddItemEditMD = (rowData: JSONObject) => {
    modal.open({
      width: 400,
      content: (_1, reject) => (
        <AddItemEdit
          rowData={rowData} 
          setAllModuleData={setAllModuleData} 
          onClose={reject} 
        />
      )
    });
  };

  const handleRestorePrice = (
    ids: number[], 
    isBatch: boolean = false,
  ) => {
    if (isBatch) {
      const { table } = getActiveTableInfo();

      const _ids: number[] = table?.getSelectedRowKeys() ?? [];
      if (_ids?.length === 0) {
        Message.warn('请至少选择一条数据');
        return;
      }

      setAddItemRestoreLoading(true);
      ids = _ids;
    } else {
      setPageLoading(true);
    }

    Http.post(`/request/${baseFormIdRef.current}/restorePrice`, { ids })
      .then(data => {
        setAllModuleData(data);
        Message.success('复原成功');
      })
      .finally(() => {
        isBatch
          ? setAddItemRestoreLoading(false)
          : setPageLoading(false);
      })
  };

  const renderOrderDate = (orderDate: string | null) => {
    return orderDate ? orderDate.split(' ')[0] : '';
  };

  const handleExport = () => {
    const { table } = getActiveTableInfo();
    const ids = table?.getSelectedRowKeys();

    const exportParams: JSONObject = {};
    if (ids?.length !== 0) {
      exportParams.ids = ids;
    }

    FormUtil.exportExcelFile(
      `/request/${baseFormId}/exportInvLine`, 
      exportParams
    );
  };

  const handleTableNameClick = (tableName: string) => {
    const _activeTableIndex = tableInfoArr.findIndex(
      table => table.name === tableName
    );
    
    handleFnBTNShow(_activeTableIndex, tableName);
  };

  const orderNumColum: ColumnProps[] = [
    { 
      key: '', title: '序号', width: 60, fixed: 'left',
      render: (_1, _2, index) => (index + 1)
    }
  ];

  const saleColumns: ColumnProps[] = [
    ...orderNumColum,
    { key: 'orderNo', title: '单据号', width: 180, fixed: 'left' },
    { key: 'orderTypeDesc', title: '订单类型', width: 130, fixed: 'left' },
    { key: 'orderDate', title: '单据日期', width: 120,
      render: renderOrderDate
    },
    { key: 'goodsDescription', title: '商品描述', width: 360 },
    { key: 'spec', title: '规格型号', width: 180 },
    { key: 'unit', title: '单位', width: 130 },
    { key: 'withTaxPrice', title: '含税单价', width: 130 },
    { key: 'quantity', title: '数量', width: 130 },
    { key: 'withTaxAmount', title: '含税金额', width: 130 },
    { key: 'amount', title: '不含税金额', width: 130 },
    { key: 'largeCategory', title: '物料大类', width: 130 },
    { key: 'mediumCategory', title: '物料中类', width: 130 },
    { key: 'smallCategory', title: '物料小类', width: 130 },
    { key: 'goodsNo', title: '产品编码', width: 200 },
    { key: 'id', title: '操作', width: 130, align: 'center', fixed: 'right',
      render: (id: number, row: JSONObject) => (
        row.isShowDelete && (
          <Popconfirm
            title="是否确认删除该行 ?"
            onConfirm={() => handleSaleOrOtherTableDel(id)}
          >
            <LinkText label="删除" />
          </Popconfirm>
        )
      )
    }
  ];

  const saleColumns_mainAssistant: ColumnProps[] = [
    ...orderNumColum,
    { key: 'orderNo', title: '单据号', width: 180, fixed: 'left' },
    { key: 'orderTypeDesc', title: '订单类型', width: 130, fixed: 'left' },
    { key: 'orderDate', title: '单据日期', width: 120,
      render: renderOrderDate
    },
    { key: 'goodsDescription', title: '商品描述', width: 360 },
    { key: 'spec', title: '规格型号', width: 180 },
    { key: 'unit', title: '单位', width: 130 },
    { key: 'price', title: '单价', width: 130 },
    { key: 'quantity', title: '数量', width: 130 },
    { key: 'withTaxAmount', title: '含税金额', width: 130 },
    { key: 'largeCategory', title: '物料大类', width: 130 },
    { key: 'mediumCategory', title: '物料中类', width: 130 },
    { key: 'smallCategory', title: '物料小类', width: 130 },
    { key: 'goodsNo', title: '产品编码', width: 200 },
    { key: 'id', title: '操作', width: 130, align: 'center', fixed: 'right',
      render: (id: number, row: JSONObject) => (
        row.isShowDelete && (
          <Popconfirm
            title="是否确认删除该行 ?"
            onConfirm={() => handleSaleOrOtherTableDel(id)}
          >
            <LinkText label="删除" />
          </Popconfirm>
        )
      )
    }
  ];

  const otherColumns_retail: ColumnProps[] = [
    ...orderNumColum,
    { key: 'orderNo', title: '单据号', width: 180, fixed: 'left' },
    { key: 'orderTypeDesc', title: '订单类型', width: 130, fixed: 'left' },
    { key: 'orderDate', title: '单据日期', width: 120,
      render: renderOrderDate
    },
    { key: 'goodsDescription', title: '商品描述', width: 260 },
    { key: 'spec', title: '规格型号', width: 180 },
    { key: 'unit', title: '单位', width: 130 },
    { key: 'withTaxPrice', title: '含税单价', width: 130 },
    { key: 'quantity', title: '数量', width: 130 },
    { key: 'withTaxAmount', title: '含税金额', width: 130 },
    { key: 'amount', title: '不含税金额', width: 130 },
    { key: 'largeCategory', title: '物料大类', width: 130 },
    { key: 'mediumCategory', title: '物料中类', width: 130 },
    { key: 'smallCategory', title: '物料小类', width: 130 },
    { key: 'goodsNo', title: '产品编码', width: 130 },
    { key: 'id', title: '操作', width: 130, align: 'center', fixed: 'right',
      render: (id: number, row: JSONObject) => (
        row.isShowDelete && (
          <Popconfirm
            title="是否确认删除该行 ?"
            onConfirm={() => handleSaleOrOtherTableDel(id)}
          >
            <LinkText label="删除" />
          </Popconfirm>
        )
      )
    }
  ];

  const otherColumns_engineering: ColumnProps[] = [
    ...orderNumColum,
    { key: 'orderNo', title: '单据号', width: 180, fixed: 'left' },
    { key: 'orderTypeDesc', title: '订单类型', width: 130, fixed: 'left' },
    { key: 'orderDate', title: '单据日期', width: 120,
      render: renderOrderDate
    },
    { key: 'goodsDescription', title: '商品描述', width: 260 },
    { key: 'spec', title: '规格型号', width: 180 },
    { key: 'unit', title: '单位', width: 130 },
    { key: 'withTaxPrice', title: '含税单价', width: 130 },
    { key: 'quantity', title: '数量', width: 130 },
    { key: 'withTaxAmount', title: '含税金额', width: 130 },
    { key: 'amount', title: '不含税金额', width: 130 },
    { key: 'largeCategory', title: '物料大类', width: 130 },
    { key: 'mediumCategory', title: '物料中类', width: 130 },
    { key: 'smallCategory', title: '物料小类', width: 130 },
    { key: 'goodsNo', title: '产品编码', width: 130 },
    { key: 'id', title: '操作', width: 130, align: 'center', fixed: 'right',
      render: (id: number, row: JSONObject) => (
        row.isShowDelete && (
          <Popconfirm
            title="是否确认删除该行 ?"
            onConfirm={() => handleSaleOrOtherTableDel(id)}
          >
            <LinkText label="删除" />
          </Popconfirm>
        )
      )
    }
  ];

  const otherColumns_mainAssistant: ColumnProps[] = [
    ...orderNumColum,
    { key: 'orderNo', title: '单据号', width: 180, fixed: 'left' },
    { key: 'orderTypeDesc', title: '订单类型', width: 130, fixed: 'left' },
    { key: 'orderDate', title: '单据日期', width: 120,
      render: renderOrderDate
    },
    { key: 'goodsDescription', title: '商品描述', width: 260 },
    { key: 'spec', title: '规格型号', width: 180 },
    { key: 'unit', title: '单位', width: 130 },
    { key: 'price', title: '单价', width: 130 },
    { key: 'quantity', title: '数量', width: 130 },
    { key: 'withTaxAmount', title: '含税金额', width: 130 },
    { key: 'largeCategory', title: '物料大类', width: 130 },
    { key: 'mediumCategory', title: '物料中类', width: 130 },
    { key: 'smallCategory', title: '物料小类', width: 130 },
    { key: 'goodsNo', title: '产品编码', width: 130 },
    { key: 'id', title: '操作', width: 130, align: 'center', fixed: 'right',
      render: (id: number, row: JSONObject) => (
        row.isShowDelete && (
          <Popconfirm
             title="是否确认删除该行 ?"
             onConfirm={() => handleSaleOrOtherTableDel(id)}
           >
             <LinkText label="删除" />
           </Popconfirm>
        )
      )
    }
  ];

  const applyColumns_retail: ColumnProps[] = [
    ...orderNumColum,
    { key: 'orderNo', title: '单据号', width: 180, fixed: 'left' },
    { key: 'orderTypeDesc', title: '订单类型', width: 130, fixed: 'left' },
    { key: 'goodsDescription', title: '商品描述', width: 360 },
    { key: 'goodsInvoiceName', title: '开票合并名称', width: 360 },
    { key: 'goodsName', title: '开票商品名称', width: 360 },
    { key: 'taxCode', title: '税收分类编码', width: 200 },
    { key: 'spec', title: '规格型号', width: 130 },
    { key: 'unit', title: '单位', width: 130 },
    { key: 'withTaxPrice', title: '含税单价', width: 130 },
    { key: 'quantity', title: '数量', width: 130 },
    { key: 'withTaxAmount', title: '含税金额', width: 130 },
    { key: 'amount', title: '不含税金额', width: 130 },
    { key: 'taxRate', title: '税率', width: 130 },
    { key: 'taxAmount', title: '税额', width: 130 },
    { key: 'largeCategory', title: '物料大类', width: 130 },
    { key: 'mediumCategory', title: '物料中类', width: 130 },
    { key: 'smallCategory', title: '物料小类', width: 130 },
    { key: 'goodsNo', title: '产品编码', width: 200 },
    { key: 'id', title: '操作', width: 130, align: 'center', fixed: 'right',
      render: (id: number, row: JSONObject) => (
        <Space size="large">
          {(row.isShowEdit || Context.getContext().roleCode === 'CW') && (
            <LinkText label="编辑" onClick={() => showEditApplyDetailMD(row)} />
          )}
          {row.isShowDelete && (
            <Popconfirm
              title="是否确认删除该行 ?"
              onConfirm={() => handleMergeTableDel(id)}
            >
              <LinkText label="删除" />
            </Popconfirm>
          )}
        </Space>
      )
    }
  ];

  const applyColumns_engineering: ColumnProps[] = [
    ...orderNumColum,
    { key: 'orderNo', title: '单据号', width: 180, fixed: 'left' },
    { key: 'orderTypeDesc', title: '订单类型', width: 130 },
    { key: 'goodsDescription', title: '商品描述', width: 360 },
    { key: 'goodsInvoiceName', title: '开票合并名称', width: 360 },
    { key: 'goodsName', title: '开票商品名称', width: 360 },
    { key: 'taxCode', title: '税收分类编码', width: 200 },
    { key: 'spec', title: '规格型号', width: 130 },
    { key: 'unit', title: '单位', width: 130 },
    { key: 'withTaxPrice', title: '含税单价', width: 130 },
    { key: 'quantity', title: '数量', width: 130 },
    { key: 'withTaxAmount', title: '含税金额', width: 130 },
    { key: 'amount', title: '不含税金额', width: 130 },
    { key: 'taxRate', title: '税率', width: 130 },
    { key: 'taxAmount', title: '税额', width: 130 },
    { key: 'linkStatus', title: '勾稽状态', width: 130,
      render: renderCheckStatus
    },
    { key: 'largeCategory', title: '物料大类', width: 130 },
    { key: 'mediumCategory', title: '物料中类', width: 130 },
    { key: 'smallCategory', title: '物料小类', width: 130 },
    { key: 'goodsNo', title: '产品编码', width: 200 },
    { key: 'id', title: '操作', width: 190, align: 'center', fixed: 'right',
      render: (id: number, row: JSONObject) => (
        <Space>
          {row.isShowCrossCheck && (
            <LinkText 
              label="勾稽" 
              onClick={() => showCrossCheckMD(
                InvoicingTableType.APPLY, 
                id
              )} 
            />
          )}
          {row.isShowSee && (
            <LinkText 
              label="查看" 
              onClick={() => showCrossCheckRowDetailMD(id)} 
            />
          )}   
          {row.isShowCopy && (
            <LinkText 
              label="复制" 
              onClick={() => showAddOrCopyMD(true, row)} 
            />
          )}  
          {row.isShowEdit && (
            <LinkText label="编辑" onClick={() => showEditApplyDetailMD(row)} />
          )}
          {row.isShowDelete && (  
            <Popconfirm
              title="是否确认删除该行 ?"
              onConfirm={() => handleMergeTableDel(id)}
            >
              <LinkText label="删除" />
            </Popconfirm>
          )}
        </Space>
      )
    }
  ];

  const applyColumns_mainAssistant: ColumnProps[] = [
    ...orderNumColum,
    { key: 'orderNo', title: '单据号', width: 180, fixed: 'left' },
    { key: 'useMainUnit', title: '选中单位', width: 80, fixed: 'left',
      render: (useMainUnit: boolean, row: JSONObject) => (
        useMainUnit ? row.unit : row.auxUnit
      )
    },
    { key: 'orderTypeDesc', title: '订单类型', width: 130 },
    { key: 'goodsDescription', title: '商品描述', width: 360 },
    { key: 'goodsInvoiceName', title: '开票合并名称', width: 360 },
    { key: 'goodsName', title: '开票商品名称', width: 360 },
    { key: 'taxCode', title: '税收分类编码', width: 200 },
    { key: 'spec', title: '规格型号', width: 130 },
    { key: 'unit', title: '单位', width: 80 },
    { key: 'price', title: '单价', width: 130 },
    { key: 'quantity', title: '数量', width: 130 },
    { key: 'withTaxAmount', title: '含税金额', width: 130 },
    { key: 'taxRate', title: '税率', width: 130 },
    { key: 'taxAmount', title: '税额', width: 130 },
    { key: 'largeCategory', title: '物料大类', width: 130 },
    { key: 'mediumCategory', title: '物料中类', width: 130 },
    { key: 'smallCategory', title: '物料小类', width: 130 },
    { key: 'goodsNo', title: '产品编码', width: 200 },
    { key: 'id', title: '操作', width: 190, align: 'center', fixed: 'right',
      render: (id: number, row: JSONObject) => (
        <Space>
          {(row.isShowEdit || Context.getContext().roleCode === 'CW') && (
            <LinkText label="编辑" onClick={() => showEditApplyDetailMD(row)} />
          )}
          {row.isShowDelete && (
            <Popconfirm
              title="是否确认删除该行 ?"
              onConfirm={() => handleMergeTableDel(id)}
            >
              <LinkText label="删除" />
            </Popconfirm>
          )}
        </Space>
      )
    }
  ];

  const mergeColumns: ColumnProps[] = [
    ...orderNumColum,
    { key: 'goodsNo', title: '单据号', width: 180, fixed: 'left' },
    { key: 'orderTypeDesc', title: '订单类型', width: 130, fixed: 'left' },
    { key: 'goodsInvoiceName', title: '开票合并商品名称', width: 130 },
    { key: 'taxCode', title: '税收分类编码', width: 130 },
    { key: 'spec', title: '规格型号', width: 130 },
    { key: 'unit', title: '单位', width: 130 },
    { key: 'withTaxPrice', title: '含税单价', width: 130 },
    { key: 'quantity', title: '数量', width: 130 },
    { key: 'withTaxAmount', title: '含税金额', width: 130 },
    { key: 'amount', title: '不含税金额', width: 130 },
    { key: 'taxRate', title: '税率', width: 130 },
    { key: 'taxAmount', title: '税额', width: 130 },
    { key: 'largeCategory', title: '物料大类', width: 130 },
    { key: 'mediumCategory', title: '物料中类', width: 130 },
    { key: 'smallCategory', title: '物料小类', width: 130 },
    { key: 'goodsNo', title: '产品编码', width: 130 }
  ];

  const toBeOpenedColumns_retail: ColumnProps[] = [
    ...orderNumColum,
    { key: 'goodsInvoiceName', title: '货物或应税劳务、服务名称', width: 200, fixed: 'left' },
    { key: 'spec', title: '规格型号', width: 200 },
    { key: 'unit', title: '单位', width: 100 },
    { key: 'quantity', title: '数量', width: 160 },
    { key: 'withTaxPrice', title: '含税单价', width: 160 },
    { key: 'price', title: '不含税单价', width: 160 },
    { key: 'amount', title: '金额', width: 160 },
    { key: 'taxRate', title: '税率', width: 160 },
    { key: 'taxAmount', title: '税额', width: 160 },
    { key: 'withTaxDiscountAmount', title: '折扣金额', width: 160 },
    { key: 'discountTaxAmount', title: '折扣税额', width: 160 }
  ];

  const toBeOpenedColumns_engineering: ColumnProps[] = [
    ...orderNumColum,
    { key: 'goodsInvoiceName', title: '货物或应税劳务、服务名称', width: 200, fixed: 'left' },
    { key: 'spec', title: '规格型号', width: 200 },
    { key: 'unit', title: '单位', width: 100 },
    { key: 'quantity', title: '数量', width: 160 },
    { key: 'withTaxPrice', title: '含税单价', width: 160 },
    { key: 'price', title: '不含税单价', width: 160 },
    { key: 'amount', title: '金额', width: 160 },
    { key: 'taxRate', title: '税率', width: 160 },
    { key: 'taxAmount', title: '税额', width: 160 },
    { key: 'id', title: '操作', width: 80, align: 'center', fixed: 'right',
      render: (id: number, row: JSONObject) => (
        <Space> 
          {row.isShowCrossCheck && ( 
            <LinkText 
              label="勾稽" 
              onClick={() => showCrossCheckMD(
                InvoicingTableType.TOBO_OPENED, 
                id
              )} 
            />              
          )}
          {row.isShowSee && (
            <LinkText 
              label="查看" 
              onClick={() => showCrossCheckRowDetailMD(id)} 
            />
          )}
        </Space>
      )
    }
  ];

  const toBeOpenedColumns_mainAssistant: ColumnProps[] = [
    ...orderNumColum,
    { key: 'goodsInvoiceName', title: '货物或应税劳务、服务名称', width: 200, fixed: 'left' },
    { key: 'changedPrice', title: '单价状态', width: 200,
      render: (changedPrice: boolean) => (
        changedPrice 
          ? <Tag color="green">已变更</Tag> 
          : <Tag color="orange">未变更</Tag>
      )
    },
    { key: 'spec', title: '规格型号', width: 200 },
    { key: 'unit', title: '单位', width: 100 },
    { key: 'quantity', title: '数量', width: 160 },
    { key: 'withTaxPrice', title: '含税单价', width: 160 },
    { key: 'price', title: '不含税单价', width: 160 },
    { key: 'amount', title: '不含税金额', width: 160 },
    { key: 'withTaxAmount', title: '含税金额', width: 160 },
    { key: 'taxRate', title: '税率', width: 160 },
    { key: 'taxAmount', title: '税额', width: 160 },
    { key: 'id', title: '操作', width: 130, align: 'center', fixed: 'right',
      render: (id: number, row: JSONObject) => (
        <Space> 
          {row.isShowEdit && (
            <LinkText 
              label="增项编辑" 
              onClick={() => showAddItemEditMD(row)}
            /> 
          )}
          {row.isShowRestore && (
            <Popconfirm
              title="是否确认复原该行 ?"
              onConfirm={() => handleRestorePrice([id])}
            >
              <LinkText label="复原" />
            </Popconfirm>
          )}
        </Space>
      )
    }
  ];

  // columns 映射
  const saleColumnsObj: JSONObject = {
    [ApplyType.RETAIL]: saleColumns,
    [ApplyType.OTHER]: saleColumns,
    [ApplyType.ADVANCE]: saleColumns,
    [ApplyType.PROGRESS]: saleColumns,
    [ApplyType.SETTLE]: saleColumns,
    [ApplyType.MAIN_ASSISTANT]: saleColumns_mainAssistant,
  };

  const otherColumnsObj: JSONObject = {
    [ApplyType.RETAIL]: otherColumns_retail,
    [ApplyType.OTHER]: otherColumns_retail,
    [ApplyType.ADVANCE]: otherColumns_engineering,
    [ApplyType.PROGRESS]: otherColumns_engineering,
    [ApplyType.SETTLE]: otherColumns_engineering,
    [ApplyType.MAIN_ASSISTANT]: otherColumns_mainAssistant,
  };

  const applyColumnsObj: JSONObject = {
    [ApplyType.RETAIL]: applyColumns_retail,
    [ApplyType.OTHER]: applyColumns_retail,
    [ApplyType.ADVANCE]: applyColumns_engineering,
    [ApplyType.PROGRESS]: applyColumns_engineering,
    [ApplyType.SETTLE]: applyColumns_engineering,
    [ApplyType.MAIN_ASSISTANT]: applyColumns_mainAssistant,
  };

  const toBeOpenedColumnsObj: JSONObject = {
    [ApplyType.RETAIL]: toBeOpenedColumns_retail,
    [ApplyType.OTHER]: toBeOpenedColumns_retail,
    [ApplyType.ADVANCE]: toBeOpenedColumns_engineering,
    [ApplyType.PROGRESS]: toBeOpenedColumns_engineering,
    [ApplyType.SETTLE]: toBeOpenedColumns_engineering,
    [ApplyType.MAIN_ASSISTANT]: toBeOpenedColumns_mainAssistant,
  };

  const saleSummary = () => {
    return (
      <TableSummaryRow>
        <Col span={5}>
           总金额: {Math.num2money(saleTotalAmount)}
         </Col>
         <Col span={5}>
           总数量: {Math.num2money(saleTotalQuantity)}
        </Col>
      </TableSummaryRow>
    );
  };

  const otherSummary = () => {
    return (
      <TableSummaryRow>
        <Col span={5}>
           其他单据金额: {Math.num2money(otherTotalAmount)}
         </Col>
         <Col span={5}>
           其他单据数量: {Math.num2money(otherTotalQuantity)}
        </Col>
      </TableSummaryRow>
    );
  };

  const applySummary = () => {
    return (
      <TableSummaryRow>
        <Col span={5}>
           开票申请订单总金额: {Math.num2money(applyTotalAmount)}
         </Col>
         <Col span={5}>
           开票申请订单总数量: {Math.num2money(applyTotalQuantity)}
        </Col>
      </TableSummaryRow>
    );
  };

  const mergeSummary = () => {
    return (
      <TableSummaryRow>
        <Col span={5}>
           合并明细总金额: {Math.num2money(mergeTotalAmount)}
         </Col>
         <Col span={5}>
           合并明细总数量: {Math.num2money(mergeTotalQuantity)}
        </Col>
      </TableSummaryRow>
    );
  };

  const toBeOpenSummary = () => {
    return (
      <TableSummaryRow>
        <Col span={5}>
           待开发票订单总金额: {Math.num2money(toBeOpenTotalAmount)}
         </Col>
         <Col span={5}>
           折扣总金额: {Math.num2money(toBeOpenTotalDiscount)}
        </Col>
         <Col span={5}>
           待开发票订单总数量: {Math.num2money(toBeOpenTotalQuantity)}
        </Col>
      </TableSummaryRow>
    );
  };

  const tableInfoArr: TableInfoProps[] = [
    {
      name: InvoicingTableType.SALE,
      table: saleTable,
      onTableRef: onSaleTableRef,
      columns: saleColumnsObj[applyType],
      summary: saleSummary,
      isShowCheckBox: true,
      batchDelURL: `/request/${baseFormId}/deleteSourceLines`,
    }, 
    {
      name: InvoicingTableType.OTHER,
      table: otherTable,
      onTableRef: onOtherTableRef,
      columns: otherColumnsObj[applyType],
      summary: otherSummary,
      isShowCheckBox: true,
      batchDelURL: `/request/${baseFormId}/deleteSourceLines`,
    }, 
    {
      name: InvoicingTableType.APPLY,
      table: applyTable,
      onTableRef: onApplyTableRef,
      columns: applyColumnsObj[applyType],
      summary: applySummary,
      isShowCheckBox: true,
      batchDelURL: `/request/${baseFormId}/deleteRequestLines`,
    }, 
    {
      name: InvoicingTableType.MERGE,
      table: mergeTable,
      onTableRef: onMergeTableRef,
      columns: mergeColumns,
      summary: mergeSummary,
      isShowCheckBox: true,
      batchDelURL: '',
    }, 
    {
      name: InvoicingTableType.TOBO_OPENED,
      table: toBeOpenedTable,
      onTableRef: onToBeOpenedTableRef,
      columns: toBeOpenedColumnsObj[applyType],
      summary: toBeOpenSummary,
      isShowCheckBox: true,
      batchDelURL: '',
    }
  ];

  const CardButtons =
    <div style={{ display: 'flex' }}>
      <Button 
        type="primary" 
        size="small"
        style={isShowBTN(isShowExportBTN)}
        onClick={handleExport}
      >
        导出
      </Button>
      <Upload
        label="导入"
        url="/request/updatePriceByExcel"
        size="small"
        style={isShowBTN(isShowImportBTN)} 
        onFinish={setAllModuleData}
      />
      <Button 
        type="primary" 
        size="small"
        style={isShowBTN(isShowAddItemRestoreBTN)}
        loading={addItemRestoreLoading}
        onClick={() => handleRestorePrice([], true)}
      >
        增项复原
      </Button>
      <Button
        type="primary"
        size="small"
        style={isShowBTN(isShowRefreshBTN)}
        loading={refreshLoading}
        onClick={handleRefresh}
      >
        刷新
      </Button>
      <Button
        type="primary"
        size="small"
        style={isShowBTN(isShowAddBTN)}
        onClick={() => showAddOrCopyMD(false, {})}
      >
        添加
      </Button>
      <Button
        type="primary"
        size="small"
        style={isShowBTN(isShowBatchDelBTN)}
        onClick={checkBatchDel}
      >
        批量删除
      </Button>
      <Button
        type="primary"
        size="small"
        style={isShowBTN(isShowMergeBTN)}
        onClick={() => handleMerge(false)}
      >
        合并明细
      </Button>
      <Button
        type="primary"
        size="small"
        style={isShowBTN(isShowMergeBTN)}
        onClick={() => handleMerge(true)}
      >
        批量合并
      </Button>
      <Button
        type="primary"
        size="small"
        style={isShowBTN(isShowRestoreBTN)}
        loading={restoreLoading}
        onClick={handleRestore}
      >
        还原
      </Button>
      <Popconfirm
        title="是否批量还原 ?"
        onConfirm={handleBatchRestore}
      >
        <Button
          type="primary"
          size="small"
          style={isShowBTN(isShowRestoreBTN)}
          loading={batchRestoreLoading}
        >
          批量还原
        </Button>
      </Popconfirm>
      <Button
        type="primary"
        size="small"
        style={isShowBTN(isShowWriteOffBTN)}
        loading={writeOffLoading}
        onClick={handleWriteOff}
      >
        退货核销
      </Button>
      <Button
        type="primary"
        size="small"
        style={isShowBTN(isShowAutoWriteOffBTN)}
        loading={autoWriteOffLoading}
        onClick={handleAutoWriteOff}
      >
        自动核销
      </Button>
      <Button
        type="primary"
        size="small"
        style={isShowBTN(isShowIntroduceBTN)}
        onClick={handleIsCanSave}
      >
        引入明细
      </Button>
      <Button
        type="primary"
        size="small"
        style={isShowBTN(isShowMoreBTN)}
        onClick={showMoreMD}
      >
        更多
      </Button>
    </div>;

  return (
    <Card
      title="开票明细"
      actions={CardButtons}
    >
      {modalHolder}
      <Tabs
        tabBarStyle={{ margin: 0 }}
        defaultActiveKey={tableInfoArr[0].name}
        onTabClick={handleTableNameClick}
      >
        {tableInfoArr.map((table) => (
          <TabPane
            key={table.name}
            tab={table.name}
          />
        ))}
      </Tabs>
      {tableInfoArr.map((table, index) => (
        <Table
          key={table.name}
          onTableRef={table.onTableRef}
          columns={table.columns}
          style={{
            position: 'relative',
            display: index === activeTableIndex ? 'block' : 'none',
          }}
          summary={table.summary}
          pagination={false}
          selectedRowKeys={[]}
          scroll={{ y: 400 }} 
        />
      ))}
    </Card>
  );
});

export default InvoicingTable;
