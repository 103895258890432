import React, { FC, useEffect, useState } from 'react';

import { Http } from '@utils';
import Select, { Props } from '../Select';
 
interface SBUSelectProps extends Props<string> {
  placeholder?: string;
};

const SBUSelect:FC<SBUSelectProps> = ({
  placeholder = 'SBU 代码 / SBU 名称',
  ...otherProps
}) => {
   
  const [SBUOptions, setSBUOptions] = useState<JSONObject[]>([]);

  useEffect(() => {
    getSBUOptions();
  }, [])

  const getSBUOptions = () => {
    Http.get('/sys/sbu/findSbuByUser')
      .then((res: JSONObject[]) => {
        setSBUOptions(res);
      });
  };

  return (
    <Select 
      {...otherProps}
      placeholder={placeholder}
      options={SBUOptions.map(
        SBUOption => ({ 
          value: SBUOption.id, 
          label: SBUOption.sbuMark, 
        })
      )} 
    />
  );
};

export default SBUSelect;