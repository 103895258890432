import * as React from "react";
import {
  Form,
  DatePicker,
  Input,
  message,
  Space,
  Button,
  Steps,
  Divider,
  Row,
  Col,
  Select,
  List,
} from "antd";
import {
  Page,
  Card,
  PermissionButton,
  Lookup,
  Table,
  LinkText,
  Modal,
} from "@comps";
import { DateUtils, Http, Context, Env } from "@utils";
import _ from "lodash";
import { useState } from "react";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import moment from "moment";
import InvoicePreview from "../modal/InvoicePreview";

export interface Props {
  handelStep: Function;
}

const SelectTicket: React.FC<Props> = ({ handelStep }) => {
  const { Step } = Steps;
  const [modal, modalHolder] = Modal.useModal();

  const { Option } = Select;

  const [expand, setExpand] = useState(true);
  const [pageSize, setPageSize] = useState(5);
  const [current, setCurrent] = useState(1);

  const [form] = Form.useForm();

  const onFinish = (values: any) => {
    values.invoiceDateTo = moment(values.invoiceDateTo).format("YYYYMMDD")
    values.invoiceDateFrom = moment(values.invoiceDateFrom).format("YYYYMMDD")
    console.log("Received values of form: ", values);
  };
  // const layout = { wrapperCol: { offser: 6, span: 16 } }
  const layout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 7 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 17 },
    },
  };
  const data: any = [
    {
      title: "Title 1",
    },
    {
      title: "Title 2",
    },
    {
      title: "Title 3",
    },
    {
      title: "Title 4",
    },
  ];
  // const handelNextStep = () => {

  // }
  const onNextStep = () => {
    handelStep(1);
  };
  const invoiceData = {}
  const handlePreview = () => {
    modal.open({
      width: 1200,
      content(resolve, reject) {
        return <InvoicePreview onClose={reject} invoice={invoiceData} />;
      },
    });
  };
 
  return (
    <Page>
      {modalHolder}
      <Card>
        <Form
          form={form}
          name="advanced_search"
          className="ant-advanced-search-form"
          onFinish={onFinish}
          {...layout}
          initialValues={{
            invoiceDateFrom: moment(new Date(), "YYYY-MM-DD"),
            invoiceDateTo: moment(new Date(), "YYYY-MM-DD"),
          }}
        >
          {/* <Row gutter={24}>{getFields()}</Row> */}
          <Row gutter={24}>
            <Col span={expand ? 6 : 8}>
              <Form.Item name="identity" label="购/销方选择">
                <Select
                  defaultValue={"销售方"}
                  placeholder="请选择"
                  options={[
                    {
                      value: "销售方",
                      label: "销售方",
                    },
                    {
                      value: "购买方",
                      label: "购买方",
                    },
                  ]}
                />
              </Form.Item>
            </Col>
            <Col span={expand ? 6 : 8}>
              <Form.Item name="invoiceNo" label="对方纳税人识别号">
                <Input placeholder="请输入" />
              </Form.Item>
            </Col>
            <Col span={expand ? 6 : 8}>
              <Form.Item name="taxName" label="对方纳税人名称">
                <Input placeholder="请输入" />
              </Form.Item>
            </Col>
            <Col span={6} style={{ display: expand ? "block" : "none" }}>
              <Form.Item name="invoiceDateFrom" label="开票日期起">
                <DatePicker
                  format="YYYY-MM-DD"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={6} style={{ display: expand ? "block" : "none" }}>
              <Form.Item name="invoiceDateTo" label="开票日期止">
                <DatePicker
                  format="YYYY-MM-DD"
                />
              </Form.Item>
            </Col>
            <Col span={6} style={{ display: expand ? "block" : "none" }}>
              <Form.Item name="invoiceNo" label="数电票号码">
                <Input placeholder="请输入" />
              </Form.Item>
            </Col>
            <Col span={6} style={{ display: expand ? "block" : "none" }}>
              <Form.Item name="invoiceNo" label="发票代码">
                <Input placeholder="请输入" />
              </Form.Item>
            </Col>
            <Col span={6} style={{ display: expand ? "block" : "none" }}>
              <Form.Item name="invoiceNo" label="发票号码">
                <Input placeholder="请输入" />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24} style={{ textAlign: "right" }}>
              <Button type="primary" htmlType="submit">
                查询
              </Button>
              <Button
                style={{ margin: "0 8px" }}
                onClick={() => {
                  form.resetFields();
                }}
              >
                重置
              </Button>
              {expand ? (
                <a
                  style={{ fontSize: 12 }}
                  onClick={() => {
                    setExpand(!expand);
                  }}
                >
                  <UpOutlined />
                  收起
                </a>
              ) : (
                <a
                  style={{ fontSize: 12 }}
                  onClick={() => {
                    setExpand(!expand);
                  }}
                >
                  <DownOutlined />
                  展开
                </a>
              )}
            </Col>
          </Row>
        </Form>
        <List
          style={{ margin: "24px" }}
          grid={{ gutter: 16, column: 1 }}
          dataSource={data}
          pagination={{
            onChange: (page, pageSize) => {
              console.log(page, pageSize);
              setCurrent(page as number);
              setPageSize(pageSize as number);
            },
            current: current,
            pageSize: pageSize,
            showQuickJumper: true,
            showSizeChanger: true,
            total: data.length,
            showTotal: (total): any => `共 ${total} 条`,
          }}
          renderItem={(item): any => (
            <List.Item>
              <Card style={{ border: "0.6px solid #d4d4d5" }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <div
                      style={{
                        fontSize: "16px",
                        fontWeight: 700,
                        color: "#333",
                        lineHeight: "24px",
                        marginBottom: "4px",
                      }}
                    >
                      <span
                        style={{ paddingRight: "15px" }}
                      >{`数电号码：${"23111111112232123134122412"}`}</span>
                      <span>{`价税合计：${0}`}</span>
                    </div>
                    <div
                      style={{
                        fontSize: "16px",
                        fontWeight: 700,
                        color: "#333",
                        lineHeight: "24px",
                        marginBottom: "4px",
                      }}
                    >
                      <span>{`购买方：${"广州乐税信息科技有限公司"}`}</span>
                    </div>
                    <div
                      style={{
                        fontSize: "12px",
                        fontWeight: 400,
                        color: "#666",
                        lineHeight: "20px",
                      }}
                    >
                      <span>{`开票日期：${"2023-05-19"}`}</span>
                    </div>
                  </div>
                  <div>
                    <a
                      style={{ paddingRight: "16px" }}
                      onClick={() => handlePreview()}
                    >
                      预览票据
                    </a>
                    <Button
                      type="primary"
                      style={{ borderRadius: "3px" }}
                      onClick={() => onNextStep()}
                    >
                      选择
                    </Button>
                  </div>
                </div>
              </Card>
            </List.Item>
          )}
        />
      </Card>
    </Page>
  );
};
export default SelectTicket;
