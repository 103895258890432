export enum StatisticsStateType {
  /**
   * 申请统计
   */
   NOT_COUNTED = 0,
   /**
   * 统计中
   */
    STATISTING = 1, 
   /**
   * 统计完成
   */
    WAIT_SIGN = 2,
   /**
   * 撤销统计中
   */
    CANCEL_STATISTIC = 3,
   /**
   * 确认中
   */
    SIGNING = 4,
    /**
   * 已签名
   */
     SIGN = 5,
    /**
    * 撤销签名中
    */
     CANCEL_STATISTICS = 6,
    /**
    * 无需统计
    */
     NO_STATISTICS_REQUIRED = 7,
}

export enum InputTransactionType {
  /**
   * 抵扣勾选
   */
  DEDUCTION_CHECK = 1,
   /**
   * 撤销抵扣勾选
   */
  CANCEL_DEDUCTION_CHECK = 3, 
   /**
   * 申请统计
   */
  APPLICATION_STATISTICS = 5,
   /**
   * 撤销统计
   */
  UNDO_STATISTICS = 6,
   /**
   * 申请统计确认
   */
  APPLICATION_STATISTICS_CONFIRMATION = 7,
}



