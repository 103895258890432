import React, { FC, useState, useEffect, memo } from 'react';
import { Space, Row, Col, Form, Button, Select, Input, AutoComplete } from 'antd';

import { Card, HiddenFormItem, MatterCategory } from '@comps';

import { Http, Message, FormUtil } from '@utils';

interface Props {
  isEdit: boolean,
  rowData: JSONObject,
  refreshTable: () => void
  onClose: () => void
};

const { Item } = Form;
const { Option } = Select;

const AddOrEditMD: FC<Props> = memo(({ 
  isEdit, 
  rowData, 
  refreshTable,
  onClose 
}) => {

  const [form] = Form.useForm();

  const [customerInfo, setCustomerInfo] = useState<JSONObject[]>([]);
  const [SBUOptions, setSBUOptions] = useState<JSONObject[]>([]);

  const [loading, setLoading] = useState(false);
  
  useEffect(() => {
    getSBUOptions();
    getCustomerInfo();
  }, []);

  useEffect(() => {
    handleAddOrEdit();
  }, []);

  const getCustomerInfo = () => {
    Http.get('/customer/all')
      .then(data => {
        setCustomerInfo(data);
      })
  };

  const getSBUOptions = () => {
    Http.get('/sys/sbu/findSbuByUser')
      .then((res: JSONObject[]) => {
        setSBUOptions(res);
      })
  };

  const handleAddOrEdit = async () => {
    if (isEdit) {
      FormUtil.booleanToNum(rowData, ['enabled']);

      form.setFieldsValue(rowData);
    };
  };

  const handleAddOrEditSuccess = () => {
    refreshTable();
    Message.success(isEdit ? '修改成功' : '新增成功');
  };

  const handleAddOrEditFinish = () => {
    setLoading(false);
    onClose();
  };

  const handleEdit = (formData: JSONObject) => {
    Http.put('/mainAuxiliaryUnit', formData)
      .then(() => {
        handleAddOrEditSuccess();
      })
      .finally(() => {
        handleAddOrEditFinish();
      })
  };

  const handleAdd = (formData: JSONObject) => {
    delete formData.id;

    Http.post('/mainAuxiliaryUnit/creat', formData)
      .then(() => {
        handleAddOrEditSuccess();
      })
      .finally(() => {
        handleAddOrEditFinish();
      })
  };

  const handleSave = () => {
    setLoading(true);

    const formData = form.getFieldsValue();

    if (isEdit) {
      handleEdit(formData);
    } else {
      handleAdd(formData);
    }
  };

  const handleCustomerCodeSelect = (customerCode: any) => {
    const currentSelectedCustomerInfo = customerInfo.find(
      (customer: JSONObject) => customer.customerCode === customerCode
    ) ?? {};
 
    const {
      customerName
    } = currentSelectedCustomerInfo;

    currentSelectedCustomerInfo.customerName = customerName;

    form.setFieldsValue(currentSelectedCustomerInfo);
  };

  return (
    <Card title={isEdit ? '编辑' : '新增'}>
      <Form 
        form={form} 
        labelCol={{ span: 5 }} 
        wrapperCol={{ span: 17 }}
        onFinish={handleSave} 
      >
        <Row>
          <Col span={24}>
            <HiddenFormItem name='id' />
            <Item 
              label="SBU 代码" 
              name="sbuCode"
            >
              <Select
                showSearch
                optionFilterProp="label"
                options={SBUOptions.map(SBUOption => ({
                  label: `${SBUOption.code} ${SBUOption.name}`,
                  value: SBUOption.code
                }))}
              />
            </Item>
          </Col>
          <Col span={24}>
            <Item 
              label="客户编码" 
              name="customerCode"
            >
              <AutoComplete
                allowClear
                onSelect={handleCustomerCodeSelect}
                filterOption={(inputValue, option) =>
                  option!.title.includes(inputValue)
                }
                options={customerInfo.map(customer => ({ 
                  title: `${customer.customerCode} ${customer.customerName}`, 
                  label: `${customer.customerCode} ${customer.customerName}`, 
                  value: customer.customerCode 
                }))}
              />
            </Item>
          </Col>
          <Col span={24}>
            <Item 
              label="客户名称" 
              name="customerName"
            >
              <Input />
            </Item>
          </Col>
          <MatterCategory
            form={form}
            isEdit={isEdit}
            rowData={rowData}
            categoryNames={['bigCategory', 'middleCategory', 'smallCategory']}
            colSpan={24}
          />
          <Col span={24}>
            <Item 
              label="启用辅单位开票" 
              name="enabled"
              rules={[{ required: true }]}
            >
              <Select>
                <Option value={1}>是</Option>
                <Option value={0}>否</Option>
              </Select>
            </Item>
          </Col>
        </Row>
        <Row>
          <Col 
            span={24} 
            style={{ 
              marginTop: 14, 
              marginBottom: 14, 
              textAlign: 'center' 
            }}
          >
            <Space>
              <Button 
                type="primary" 
                htmlType="submit"
                loading={loading} 
              >
                确定
              </Button>
              <Button onClick={onClose}>取消</Button>
            </Space>
          </Col>
        </Row>
      </Form>
    </Card >
  );
});

export default AddOrEditMD;
