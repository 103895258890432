import React, { useState } from "react";
import { Form, Input, Space, DatePicker, InputNumber } from "antd";
import { Card, Button } from "@comps";
import { DateUtils, Http, Message } from "@utils";

const { Item } = Form;

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 12 },
};

export interface Props {
  onCancel: () => void;
  onConfirm: () => void;
}

const ManualAdd: React.FC<Props> = ({ onCancel, onConfirm }) => {
  const [form] = Form.useForm();

  const [isShowAmount, setIsShowAmount] = useState(false);
  const [isShowCheckCode, setIsShowCheckCode] = useState(false);
  const [isShowTaxAmount, setIsShowTaxAmount] = useState(false);
  const [rules, setRules] = useState([
    { required: true, max: 12, message: "发票代码最多 12 位" },
  ]);
  const [len, setLen] = useState(20);

  const [inputLoading, setInputLoading] = useState(false);

  const handleSave = (isChecked:boolean) => {
    const formData = form.getFieldsValue();
    const invoiceDate = DateUtils.date2int(form.getFieldValue("invoiceDate"));
    const { invoiceCode, invoiceNo } = formData;
    if (invoiceNo.length === 8 && !invoiceCode) {
      Message.warn("发票号码为8位时发票代码不可为空");
      return;
    }
    if (invoiceNo.length !== 8 && invoiceNo.length !== 20) {
      Message.warn("发票号码只能为8位或20位");
      return;
    }
    setInputLoading(true);
    Http.post("/inputInvManagement/inputInv", {
      invoices: [{ ...formData, invoiceDate }],
      isChecked,
    })
      .then(() => {
        onCancel();
        Message.success("录入成功");
      })
      .finally(() => {
        setInputLoading(false);
      });
  };

  const code = ["144031539110", "131001570151", "133011501118", "111001571071"];
  const JudgeInvoiceType = (invCode: string) => {
    let eighthPlace = 0;
    let invType = "99";

    if (invCode.length == 12) {
      eighthPlace = +invCode.substring(7, 8);

      for (var i = 0; i < code.length; i++) {
        if (invCode == code[i]) {
          invType = "51";
          break;
        }
      }

      if (invType == "99") {
        //增加判断，判断是否为新版电子票
        if (invCode.charAt(0) == "0" && invCode.substring(10, 12) == "11") {
          invType = "51";

          //判断是否最新12位增值税普通发票,第1位为0且第11-12位为04或05
        } else if (
          invCode.charAt(0) == "0" &&
          (invCode.substring(10, 12) == "04" ||
            invCode.substring(10, 12) == "05")
        ) {
          invType = "2";

          //判断是否为卷式发票  第1位为0且第11-12位为06或07
        } else if (
          invCode.charAt(0) == "0" &&
          (invCode.substring(10, 12) == "06" ||
            invCode.substring(10, 12) == "07")
        ) {
          invType = "11";

          //如果还是99，且第11-12位是12，则是通行费发票
        } else if (
          invCode.charAt(0) == "0" &&
          invCode.substring(10, 12) == "12"
        ) {
          invType = "14";
        } else if (
          invCode.charAt(0) == "0" &&
          invCode.substring(10, 12) == "13"
        ) {
          invType = "08";
        }
      }

      if (invType == "99") {
        //如果还是99，且第8位是2，则是机动车发票
        if (eighthPlace == 2 && invCode.charAt(0) != "0") {
          invType = "03";
        }
      }
    } else if (invCode.length == 10) {
      eighthPlace = +invCode.substring(7, 8);

      if (eighthPlace == 1 || eighthPlace == 5) {
        invType = "0"; //专票
      } else if (eighthPlace == 6 || eighthPlace == 3) {
        invType = "2"; //普票
      } else if (eighthPlace == 7 || eighthPlace == 2) {
        invType = "02";
      }
    }

    return invType;
  };

  const handleInvCodeChange = ({ target }: JSONObject) => {
    const invCode = target.value;
    const { invoiceNo } = form.getFieldsValue();
    const invType = JudgeInvoiceType(invCode);

    if (
      invType == "2" ||
      invType == "51" ||
      invType == "11" ||
      invType == "14"
    ) {
      setIsShowAmount(false);
      setIsShowCheckCode(true);
    } else {
      setIsShowAmount(true);
      setIsShowCheckCode(false);
    }

    if (invCode) {
      setLen(8);
      invoiceNo?.length > 8
        ? form.setFieldsValue({ invoiceNo: "" })
        : form.setFieldsValue({ invoiceNo });
    } else {
      setLen(20);
      isShowAmount && setIsShowAmount(false);
    }
  };

  const handleInvNoChange = ({ target }: JSONObject) => {
    const invNo = target.value;
    if (invNo.length === 8) {
      setRules([{ required: true, max: 12, message: "发票代码最多 12 位" }]);
    }
    if (invNo.length === 20) {
      setRules([]);
      setIsShowTaxAmount(true);
      form.setFieldsValue({ invoiceCode: "" });
    } else {
      isShowTaxAmount && setIsShowTaxAmount(false);
    }
  };

  return (
    <Card title="手工录入" style={{ marginBottom: "0" }}>
      <Form {...layout} form={form} name="form" >
        <Item label="发票代码" name="invoiceCode" rules={rules}>
          <Input
            onChange={handleInvCodeChange}
            disabled={rules.length > 0 ? false : true}
          />
        </Item>
        <Item label="发票号码" name="invoiceNo" rules={[{ required: true }]}>
          <Input onChange={handleInvNoChange} minLength={8} maxLength={len} />
        </Item>
        <Item label="开票日期" name="invoiceDate" rules={[{ required: true }]}>
          <DatePicker style={{ width: "100%" }} />
        </Item>
        {isShowAmount && (
          <Item label="不含税金额" name="amount" rules={[{ required: true }]}>
            <InputNumber precision={2} style={{ width: "100%" }} />
          </Item>
        )}
        {isShowTaxAmount && (
          <Item label="价税合计" name="amount" rules={[{ required: true }]}>
            <InputNumber precision={2} style={{ width: "100%" }} />
          </Item>
        )}
        {isShowCheckCode && (
          <Item
            label="校验码（6 位）"
            name="checkCode"
            rules={[
              {
                required: true,
                pattern: new RegExp(/\d{6}/),
                message: "请输入 6 位校验码",
              },
            ]}
          >
            <Input minLength={6} maxLength={6} />
          </Item>
        )}
        <div style={{ textAlign: "center" }}>
          <Space>
            <Button type="primary" onClick={()=>handleSave(false)} loading={inputLoading}>
              保存
            </Button>
            <Button type="primary" onClick={()=>handleSave(true)} loading={inputLoading}>
              保存并验伪
            </Button>
            <Button onClick={onCancel}>取消</Button>
          </Space>
        </div>
      </Form>
    </Card>
  );
};

export default ManualAdd;
