/**
 * 路由信息
 */
import React, { Component } from 'react';
import { Router, Redirect, Route } from 'react-router-dom';
import CacheRoute from 'react-router-cache-route';
import { connect } from 'react-redux';
import { createBrowserHistory, LocationDescriptor } from 'history';
import Env from './utils/Env';

const history = createBrowserHistory();

/**
 * 自定义 BrowserRouter, 提供 history 对外使用
 */
class BrowserRouter extends Component {
  render() {
    return (
      <Router history={history} children={this.props.children} />
    );
  };
};

interface PrivateRouteProps {
  component: any,
  logined: boolean | false,
  key: number,
  path: string,
  isCancelCache: boolean | undefined
};
 
/**
 * 私有路由, 必须登陆后才能访问, 否则跳转到login
 */
class PrivateRoute extends Component<PrivateRouteProps> {
  render() {
    const loginPath: LocationDescriptor = { pathname: Env.getLoginPath() }
    const { component: Component, ...attrs } = this.props
    const { path, isCancelCache } = this.props;

    return (
      isCancelCache 
        ? <Route 
            exact  
            path={path} 
            component={Component} 
          />
        : <CacheRoute 
            cacheKey={path} 
            exact 
            {...attrs} 
            render={props => (
              this.props.logined 
                ? <Component {...props} /> 
                : <Redirect to={loginPath} />
            )} 
          />
          
    )
  };
};

/**
 * 将 store 注入 PrivateRoute
 */
const mapStateToProps = (state: any) => {
  return {
    logined: state.ctx.logined
  };
};

const WrappedPrivateRoute = connect(mapStateToProps)(PrivateRoute);

export {
  history,
  WrappedPrivateRoute as PrivateRoute,
  BrowserRouter
};