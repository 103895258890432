export interface TableRow {
  id: number,
  requestNo: string,
  statusCode: number,
  statusCodeDesc: string,
  needRedNoticeNo: boolean,
  allowRetry?: boolean,
  submitTime: string,
  submitterName: string,
  approverName: string,
  approveTime: string,
  approveRemarks: string
}

export enum Deduction {
  CERTIFICATION = 'certification',
  OTHER = 'other',
  OUT = 'out'
}



