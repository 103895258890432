import {
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Select,
  Space,
  Tag,
  message,
} from "antd";
import React, { FC, useCallback, useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";

import {
  Button,
  Card,
  LinkText,
  Lookup,
  Modal,
  Page,
  PermissionButton,
  Table,
  TableSummaryRow,
} from "@comps";
import ButtonSpace from "@comps/ButtonSpace";
import { ColumnProps } from "@comps/types";
import { Context, DateUtils, Http, Lang, Math, Message } from "@utils";
import { useForm } from "antd/lib/form/Form";
import { ApplyType, ApplyTypeVal } from "../apply/interface";
import InvoiceDetail from "../invoice/detail";
import MergeOrSplitParentTable from "./component/mergeOrSplitordersParentTable";
import { OperationType } from "./interface";
import AsyncOrderSystem from "./modal/asyncOrderSystem";
import BatchDelete from "./modal/batchDelete.md";
import ImportBillMD from "./modal/importBill.md";
import MarkInvoicingMD from "./modal/markInvoicing.md";
import MergeDocMD from "./modal/mergeDoc.md";
import SplitDocMD from "./modal/splitDoc.md";
import UpdateInvoiceType from "./modal/updateInvoiceType.md";
import UpdateOrdersModal from "./modal/updateOrdersModal";
import PaperInvoiceInfo from "./modal/paperInvoiceInfo";
import LimitInfoModal from "./modal/limitInfoModal";
import QrCodeModal from "./modal/qrCodeModal";

export interface Prompt {
  finished: boolean;
  messages: {
    title: string;
    content: string;
  }[];
}
interface NObject {
  [key: string]: string | number | undefined | null | void;
}

const { Item } = Form;
const { RangePicker } = DatePicker;
const { Option } = Select;

/**
 * 待开票记录
 */
const WaitInvoiceList: FC = () => {
  const location = useLocation<any>();
  const { state } = location;
  const params = state?.params ?? "";
  let [table, setTable] = useState<Table>();
  const timerRef = useRef(0);

  const [modal, modalHolder] = Modal.useModal();

  const [batchInvoiceLoading, setBatchInvoiceLoading] = useState(false);
  const [asToBeInvoicedLoading, setAsToBeInvoicedLoading] = useState(false);
  const [qrcodeLoading, setQrcodeLoading] = useState(false);

  const defaultApprover = Context.getCurrentUserName();
  const [approvalStatus, setApprovalStatus] = useState<NObject>({});
  const [deptList, setDeptList] = useState<any[]>([]);
  const paperInvoiceInfoRef = useRef<any>(null);
  const limitInfoRef = useRef<any>(null);
  const { permissions } = Context.getContext();
  const applyListPermissions = ["OutputInvoice.dept"];
  const hasPermissions = applyListPermissions.some((permission) =>
    permissions.includes(permission)
  );
  //审批状态枚举
  useEffect(() => {
    (async () => {
      const kvEnum: NObject = {};
      await Http.get("/sys/lookups/kv", {
        lookupType: "APPROVE_RESULT",
      }).then((result) => {
        result.map((item: any) => {
          kvEnum[item.k] = item.v;
        });
      });
      setApprovalStatus(kvEnum);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      let newDeptList: any[] = [];
      let newDeptIds: number[] = [];
      if (hasPermissions) {
        await Http.get("/tax/outputInvoices/dept").then((res) => {
          res.map((item: JSONObject) => {
            newDeptList.push({
              label: item.deptName,
              value: item.id,
            });
            newDeptIds.push(item.id);
          });
          setDeptList(newDeptList);
        });
      }
    })();
  }, [table]);

  useEffect(() => {
    return () => {
      if (timerRef.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        clearInterval(timerRef.current);
      }
    };
  }, []);

  //从手工录入或详情页跳转回来的时候添加路由pane
  useEffect(() => {
    const route = `/tax/outputInvoice/wait`;
    Context.handleAdditionalPane("待开单据", route, route);
    table?.refresh();
  }, [params]);

  const applyTypeObj: JSONObject = {
    [ApplyTypeVal.RETAIL]: ApplyType.RETAIL,
    [ApplyTypeVal.ADVANCE]: ApplyType.ADVANCE,
    [ApplyTypeVal.PROGRESS]: ApplyType.PROGRESS,
    [ApplyTypeVal.SETTLE]: ApplyType.SETTLE,
    [ApplyTypeVal.OTHER]: ApplyType.OTHER,
    [ApplyTypeVal.MAIN_ASSISTANT]: ApplyType.MAIN_ASSISTANT,
  };

  const startTimer = React.useCallback(
    (batchNo, onClose = () => {}) => {
      if (timerRef.current) {
        clearInterval(timerRef.current);
      }
      const _timerId = Lang.timer(async () => {
        const { finished = false } = await Http.get<Prompt>(
          `/tax/orders/batchInvoiceStatus?batchNo=${batchNo}`
        );

        table?.refresh();

        if (finished) {
          setBatchInvoiceLoading(false);
          paperInvoiceInfoRef.current?.handleBatchInvoiceLoading(false);
          limitInfoRef.current?.handleBatchInvoiceLoading(false);
          onClose();
          clearInterval(_timerId);
          timerRef.current = 0;
        }
      }, 2000);
    },
    [table]
  );

  const onTableRef = (tableRef: Table) => {
    setTable(tableRef);
    table = tableRef;
  };

  const handleBatchInvoice = useCallback(async () => {
    const handleBatchIssuance = async (onClose = () => {}) => {
      try {
        const ids = table?.getSelectedRowKeys();
        const batchNo = await Http.post("/tax/orders/batchInvoice", {
          ids,
        });
        startTimer(batchNo, onClose);
      } catch (error) {
        onClose();
        setBatchInvoiceLoading(false);
        paperInvoiceInfoRef.current?.handleBatchInvoiceLoading(false);
        limitInfoRef.current?.handleBatchInvoiceLoading(false);
        console.error(error);
      }
    };

    const handlePaperInvoiceBatchIssuance = (onClose = () => {}) => {
      paperInvoiceInfoRef.current?.handleBatchInvoiceLoading(true);
      handleBatchIssuance(onClose);
    };
    const handleLimitInvoiceNext = (onClose = () => {}) => {
      limitInfoRef.current?.handleBatchInvoiceLoading(true);
      handleBatchIssuance(onClose);
    };
    setBatchInvoiceLoading(true);
    const invoiceType = table?.getSelectedRowAttr("invoiceType");
    const isSameType = invoiceType?.every((item, index, array) => {
      return item === array[0];
    });

    if (!isSameType) {
      message.warning("请选择相同发票类型");
      setBatchInvoiceLoading(false);
      return;
    }
    if (invoiceType?.includes(31) || invoiceType?.includes(32)) {
      await Http.get("/tax/orders/blueInvoiceCredit")
        .then((res) => {
          modal.open({
            width: 600,
            content(resolve, reject) {
              return (
                <LimitInfoModal
                  ref={limitInfoRef}
                  invoiceInfo={res}
                  table={table}
                  reject={reject}
                  handleBatchIssuance={handleLimitInvoiceNext}
                />
              );
            },
          });
        })
        .finally(() => {
          setBatchInvoiceLoading(false);
        });
    } else if (invoiceType?.includes(85) || invoiceType?.includes(86)) {
      let [invoiceInfo, remainQuantity]: any = await Promise.all([
        Http.get(`/tax/orders/getNextInvoice?invoiceType=${invoiceType[0]}`),
        Http.get(`/tax/orders/electricQueryStock`),
      ]).finally(() => {
        setBatchInvoiceLoading(false);
      });
      await modal.open({
        width: 600,
        content(resolve, reject) {
          return (
            <PaperInvoiceInfo
              ref={paperInvoiceInfoRef}
              invoiceInfo={invoiceInfo}
              remainQuantity={remainQuantity}
              table={table}
              reject={reject}
              handleBatchIssuance={handlePaperInvoiceBatchIssuance}
            />
          );
        },
      });
    } else {
      handleBatchIssuance(() => {});
    }
  }, [table, startTimer]);

  const handleDelete = useCallback(async () => {
    const ids = table?.getSelectedRowKeys();
    await Http.post("/tax/orders/batchDelete", {
      ids,
    });
    message.success("删除成功");
    table?.refresh();
  }, [table]);

  const handleBatchDelete = useCallback(
    () =>
      Lang.noError(async () => {
        await modal.open({
          width: 800,
          ignoreError: false,
          content(resolve, reject) {
            return <BatchDelete onClose={reject} onConfirm={resolve} />;
          },
        });
        table?.refresh();
      }),
    [modal, table]
  );

  const handleUpdateInvoiceType = useCallback(
    () =>
      Lang.noError(async () => {
        const ids = table?.getSelectedRowKeys() || [];
        if (!ids.length) {
          message.warn("请至少选择一行");
          return;
        }
        await modal.open({
          width: 800,
          content(resolve) {
            return <UpdateInvoiceType ids={ids} onClose={resolve} />;
          },
        });
        table?.refresh();
      }),
    [modal, table]
  );

  const handleBatchUpdateSeller = useCallback(
    () =>
      Lang.noError(async () => {
        const ids = table?.getSelectedRowKeys();

        await Http.put("/tax/outputInvoices/batchUpdateSeller", {
          ids,
        });

        message.success("更新成功");
        table?.refresh();
      }),
    [table]
  );

  const refreshTable = () => {
    table?.refresh();
  };

  const getIds = useCallback(() => {
    return table?.getSelectedRowKeys();
  }, [table]);

  const handleMerge = (
    ids: number[],
    statusCodes: number[],
    generateWays: number[]
  ) => {
    if (ids?.length !== 1) {
      Message.warn("请选择一条记录");
      return;
    }

    let isCanMerge = true;

    for (let i = 0; i < statusCodes.length; i++) {
      if (statusCodes[i] !== 1) {
        isCanMerge = false;
        Message.warn("存在发票状态不允许合并的单据");
        break;
      }
    }

    // 拆分合并过的不能合并(数据来源值为 13)
    if (generateWays.includes(13)) {
      isCanMerge = false;
      Message.warn("选中的单据必须从未进行过合并或拆分操作");
    }

    isCanMerge &&
      modal.open({
        width: 450,
        content: (_1, reject) => (
          <MergeDocMD ids={ids} refreshTable={refreshTable} onClose={reject} />
        ),
      });
  };
  const handleSplit = (
    ids: number[],
    statusCodes: number[],
    generateWays: number[],
    notAllowMergeOrSplitStatus: number[],
    totalAmount: number[]
  ) => {
    if (ids?.length === 0) {
      Message.warn("请选择数据进行拆分!");
      return;
    } else if (ids.length > 1) {
      Message.warn("请选择一条数据进行拆分！");
      return;
    }

    if (notAllowMergeOrSplitStatus.includes(statusCodes[0])) {
      Message.warn("存在发票状态不允许拆分的单据");
      return;
    }

    if (generateWays.includes(13)) {
      Message.warn("选中的单据必须从未进行过合并或拆分操作");
      return;
    }

    modal.open({
      width: 360,
      content(_1, reject) {
        return (
          <SplitDocMD
            invId={ids[0]}
            refreshTable={refreshTable}
            onClose={reject}
            totalAmount={totalAmount[0]}
          />
        );
      },
    });
  };

  const handleRestore = () => {
    const ids = table?.getSelectedRowKeys() ?? [];
    if (ids.length === 0) {
      Message.warn("请至少选择一条数据");
      return;
    }

    // Message.info('还原中');
    Http.post("/tax/orders/reduction", { ids }).then(() => {
      table?.refresh();
      Message.success("还原成功");
    });
  };

  const handleOperationsClick = (type: any) => {
    const ids: number[] = table?.getSelectedRowKeys() ?? [];
    const statusCodes: number[] = table?.getSelectedRowAttr("statusCode") ?? [];
    const generateWays: number[] =
      table?.getSelectedRowAttr("generateWay") ?? [];
    const notAllowMergeOrSplitStatus = [2, 3, 5];
    const totalAmount: number[] =
      table?.getSelectedRowAttr("totalAmount") ?? [];

    switch (type) {
      case OperationType.MERGE:
        handleMerge(ids, statusCodes, generateWays);
        break;
      case OperationType.SPLIT:
        handleSplit(
          ids,
          statusCodes,
          generateWays,
          notAllowMergeOrSplitStatus,
          totalAmount
        );
        break;
      case OperationType.RESTORE:
        handleRestore();
        break;
      case OperationType.MERGERECEIPTS:
        handleMergeReceipts(ids);
        break;
      default:
        break;
    }
  };

  const handleMergeReceipts = (ids: number[]) => {
    if (ids?.length === 0) {
      Message.warn("请选择数据!");
      return;
    } else if (ids?.length <= 1) {
      Message.warn("至少选择2条要合并的数据!");
      return;
    } else if (ids?.length > 5) {
      Message.warn("超过单据合并数量!");
      return;
    }
    modal.open({
      width: 500,
      content(reslove, reject) {
        return (
          <Card title="信息">
            <Row
              style={{
                lineHeight: "50px",
                paddingLeft: "20px",
                fontSize: "16px",
              }}
            >
              你确定要合并所选数据吗？
            </Row>
            <Row style={{ marginTop: 10 }}>
              <Col offset={7} span={3}>
                <Button
                  type="primary"
                  onClick={() => {
                    Http.post("/tax/orders/merger", ids).then((res) => {
                      // table?.refresh();
                      // Message.success("");
                      console.log(res);
                      res.invoice.lines = res.lines;
                      modal.open({
                        width: 1400,
                        content(reslove, close) {
                          return (
                            <MergeOrSplitParentTable
                              operationType={OperationType.MERGE}
                              dataSource={[res.invoice]}
                              mergeId={res.confirmMergeID}
                              splitId={0}
                              submitdata={res}
                              onClose={close}
                              refreshTable={refreshTable}
                              closeMergeTypeMD={reject}
                              closeSplitTypeMD={() => {}}
                              closeSplitAmountMD={() => {}}
                            />
                          );
                        },
                      });
                    });
                  }}
                >
                  确定
                </Button>
              </Col>
              <Col offset={1} span={7}>
                <Button onClick={reject}>取消</Button>
              </Col>
            </Row>
          </Card>
        );
      },
    });
  };

  // const handleSplitReceipts = () => {

  // }

  // const handleRestoreReceipts = () => {
  //     const ids = table?.getSelectedRowKeys() ?? [];
  //     if (ids.length === 0) {
  //         Message.warn("请选择一条数据进行还原！");
  //         return;
  //     }
  //     modal.open({
  //         width: 500,
  //         content(reslove, reject) {
  //             return (
  //                 <Card title="信息">
  //                     <Row style={{ lineHeight: "50px", paddingLeft: "20px", fontSize: "16px" }}>
  //                         你确定要还原吗？
  //                     </Row>
  //                     <Row style={{ marginTop: 10 }}>
  //                         <Col offset={7} span={3}>
  //                             <Button type="primary">
  //                                 确定
  //                             </Button>
  //                         </Col>
  //                         <Col offset={1} span={7}>
  //                             <Button onClick={reject}>取消</Button>
  //                         </Col>
  //                     </Row>
  //                 </Card>
  //             )
  //         }
  //     })
  //     // Message.info('还原中');

  //     Http.post("/tax/orders/reduction", { ids }).then(() => {
  //         table?.refresh();
  //         Message.success("还原成功");
  //     });
  // };

  // 二维码 身份认证
  const handleQrcodeUrl = async () => {
    setQrcodeLoading(true);
    const base64 = await Http.post(`/tax/orders/qrCode`).finally(() =>
      setQrcodeLoading(false)
    );
    await modal.open({
      width: "350px",
      content(resolve, reject) {
        return <QrCodeModal onCancel={reject} base64={base64} />;
      },
    });
  };

  const showMarkInvoicingMD = async () => {
    const selectedRowData: JSONObject = table?.getSelectedRowData() || [];

    if (selectedRowData?.length !== 1) {
      Message.warn("请勾选一条数据");
      return;
    }

    const invoiceStatus = selectedRowData[0].statusCode;
    if (invoiceStatus !== 1 && invoiceStatus !== 4) {
      Message.warn("发票状态只能是 [ 待开票 ] 或 [ 开票失败 ]");
      return;
    }

    await modal.open({
      width: "600px",
      content(resolve, reject) {
        return (
          <MarkInvoicingMD
            billInfo={selectedRowData[0]}
            onResolve={resolve}
            onClose={reject}
          />
        );
      },
    });

    table?.refresh();
  };

  const handleAsToBeInvoiced = () => {
    const ids = table?.getSelectedRowKeys();
    if (ids?.length === 0) {
      Message.warn("请至少勾选一条数据");
      return;
    }

    setAsToBeInvoicedLoading(true);

    Http.post("/tax/orders/MarkInvoicing", { ids })
      .then(() => {
        Message.success("标记成功");
        table?.refresh();
      })
      .finally(() => {
        setAsToBeInvoicedLoading(false);
      });
  };

  const showDetail = (row: JSONObject) => {
    const state = {
      routerFrom: "待开单据",
    };
    if (row.requestId == 0) {
      // const route = `/tax/outputInvoice/${row.requestNo}/detail`;
      // Context.handleAdditionalPane("待开单据", route, route, state);
      modal.open({
        width: 1200,
        content(resolve, reject) {
          return <InvoiceDetail requestNo={row.requestNo} onClose={reject} />;
        },
      });
    } else {
      Http.get(`/request/${row.requestId}/detail`).then((data) => {
        const route = `/output/apply/detail/${row.requestId}/${
          applyTypeObj[data.request.requestType]
        }`;
        Context.handleAdditionalPane("申请详情", route, route, state);
      });
    }
  };

  const showImportContratMD = () => {
    modal.open({
      width: 300,
      content: (_1, reject) => <ImportBillMD onClose={reject} table={table} />,
    });
  };

  const columns: ColumnProps[] = [
    {
      key: "bizNo",
      title: "单据编号",
      width: 260,
      fixed: "left",
      render: (value, row) => (
        <>
          {/* {row.statusCode === 1 && (
            <Link to={`/tax/outputInvoice/wait/${row.requestNo}/detail`}>{value}</Link>
          )}
          {row.statusCode !== 1 && (
            <Link to={`/tax/outputInvoice/${row.requestNo}/detail`}>{value}</Link>
          )} */}
          <LinkText label={row.requestNo} onClick={() => showDetail(row)} />
        </>
      ),
    },
    { key: "requestDate", title: "单据日期", width: 110 },
    { key: "invoiceTypeDesc", title: "发票类型", width: 210 },
    { key: "industryNo", title: "业态名称", width: 140 },
    { key: "buyerName", title: "购方名称", width: 240 },
    { key: "buyerTaxNo", title: "购方税号", width: 200 },
    // { key: 'requestRemark', title: '申请说明', width: 180 },

    { key: "invoiceAmount", title: "不含税金额", format: "amount", width: 120 },
    { key: "taxAmount", title: "税额", width: 120, format: "amount" },
    { key: "totalAmount", title: "价税合计", width: 120, format: "amount" },
    { key: "sellerName", title: "销方名称", width: 250, align: "center" },
    {
      key: "mergerLineFlag",
      title: "合并标识",
      width: 110,
      render: (mergerLineFlag: boolean) =>
        mergerLineFlag ? (
          <Tag color="green">已合并</Tag>
        ) : (
          <Tag color="orange">未合并</Tag>
        ),
    },
    {
      key: "splitFlag",
      title: "拆分标识",
      width: 110,
      align: "center",
      render: (splitFlag: number) => {
        if (splitFlag === 1) {
          return <Tag color="green">正常</Tag>;
        } else if (splitFlag === 2) {
          return <Tag color="orange">拆分</Tag>;
        } else if (splitFlag === 3) {
          return <Tag color="blue">合并</Tag>;
        } else if (splitFlag === 0) {
          return <Tag color="green">正常</Tag>;
        }
      },
    },
    { key: "remark", title: "发票备注", width: 260 },
    {
      key: "approvalStatus",
      title: "审批状态",
      width: 260,
      // @ts-ignore
      render: (text, record) =>
        approvalStatus[text] ? approvalStatus[text] : "",
    },
    { key: "approvalDesc", title: "驳回原因", width: 260 },
    { key: "lastName", title: "单据引入人", width: 120 },
    { key: "sbuCode", title: "SBU 代码", width: 120 },
    // { key: 'machineNo', title: '分机号', width: 120 },
    {
      key: "generateWayDesc",
      title: "单据来源",
      width: 120,
      render: (value: string, row: JSONObject) => (
        <Tag color="processing">{value}</Tag>
      ),
    },
    { key: "sourceSystem", title: "来源系统", width: 120 },
    {
      key: "statusCodeDesc",
      title: "发票状态",
      width: 120,
      align: "center",
      fixed: "right",
    },
    {
      key: "requestNo",
      title: "操作",
      width: 150,
      align: "center",
      fixed: "right",
      render: (value, bill: JSONObject) => (
        <Space size="large">
          {/* <Link to={`/tax/outputInvoice/${bill.id}/detail`}>详情</Link> */}
          <LinkText label="详情" onClick={() => showDetail(bill)} />
          {bill?.allowUpdate && (
            <Link to={`/tax/outputInvoice/wait/${value}/detail`}>修改</Link>
          )}
        </Space>
      ),
    },
  ];

  const tableSummary = (invoices: JSONObject[], selectIds: number[] = []) => {
    const selectInvoices = invoices.filter((invoice) =>
      selectIds?.includes(invoice.id)
    );

    const invoiceTotal = selectInvoices.reduce(
      (total, invoice) => {
        total.invoiceAmount += invoice.invoiceAmount;
        total.taxAmount += invoice.taxAmount;
        total.totalAmount += invoice.totalAmount;
        return total;
      },
      {
        invoiceAmount: 0,
        taxAmount: 0,
        totalAmount: 0,
      }
    );

    return (
      <TableSummaryRow>
        <Col span={6}>选中数量: {selectIds?.length || 0}</Col>
        <Col span={6}>
          选中不含税金额: {Math.num2money(invoiceTotal.invoiceAmount)}
        </Col>
        <Col span={6}>选中税额: {Math.num2money(invoiceTotal.taxAmount)}</Col>
        <Col span={6}>
          选中含税金额: {Math.num2money(invoiceTotal.totalAmount)}
        </Col>
      </TableSummaryRow>
    );
  };

  const showAuthorization = () => {
    Http.get("/tax/orders/dzswjAuthLogin").then((res) => {
      console.log(res);
      modal.open({
        width: "600px",
        content(resolve, reject) {
          return (
            <Modal
              title="数电授权"
              visible={true}
              onCancel={reject}
              footer={null}
            >
              <iframe
                src={res}
                width="100%"
                height="450px"
                frameBorder="0"
                title="数电授权"
              />
            </Modal>
          );
        },
      });
    });
  };

  const asyncOrderSystem = () => {
    modal.open({
      width: 1000,
      content(resolve, reject) {
        return (
          <AsyncOrderSystem
            onClose={reject}
            onConfirm={resolve}
            parentTable={table}
          />
        );
      },
    });
  };

  const handleLinkToInvoiceMakeout = () => {
    const onFinish = (res: any, resolve: any) => {
      console.log(res);
      const route = `/tax/blueInvoice/invoiceMakeout/${res?.invoiceType}/${
        res?.businessType ? res?.businessType : ""
      }`;
      // history.push(route);
      Context.handleAdditionalPane("手工录入", route, route, {
        key: Math.generateRandomKey(),
      });
      resolve();
    };
    modal.open({
      width: 600,
      content(resolve, reject) {
        return (
          <Card title="手工录入">
            <Form
              onFinish={(v) => onFinish(v, resolve)}
              style={{ padding: "32px" }}
            >
              <Form.Item
                label="选择票类"
                name="invoiceType"
                rules={[{ required: true, message: "请选择票类" }]}
              >
                {/* <Lookup lookupType="TAX_INVOICE_TYPE" placeholder="请选择" /> */}
                <Select placeholder="请选择">
                  <Option value="31">数电票（增值税专用发票）</Option>
                  <Option value="32">数电票（增值税普通发票）</Option>
                  <Option value="85">数电纸质票（增值税专用发票）</Option>
                  <Option value="86">数电纸质票（增值税普通发票）</Option>
                </Select>
              </Form.Item>
              <Form.Item label="选择特定业务类型" name="businessType">
                <Lookup
                  lookupType="INVOICE_BUSINESS_TYPE"
                  placeholder="请选择"
                />
              </Form.Item>
              <div style={{ textAlign: "right" }}>
                <Button
                  htmlType="submit"
                  type="primary"
                  style={{ marginRight: "16px" }}
                  onClick={() => {}}
                >
                  确认
                </Button>
                <Button onClick={reject}>取消</Button>
              </div>
            </Form>
          </Card>
        );
      },
    });
    // history.push("/tax/blueInvoice/invoiceMakeout");
  };

  const updateOrders = async () => {
    let selectrow: JSONObject[] | undefined = table?.getSelectedRowData();
    if (selectrow?.length === 0) {
      Message.warn("请勾选一条数据");
      return;
    }
    let idArr = selectrow?.map((value: any) => {
      return value.id;
    });
    await modal.open({
      width: 600,
      content(resolve, reject) {
        return (
          <UpdateOrdersModal ids={idArr} onClose={reject}></UpdateOrdersModal>
        );
      },
    });
    table?.refresh();
  };

  return (
    <Page>
      {modalHolder}
      <Card
        title="待开单据"
        actions={
          <div style={{ display: "flex", flexDirection: "column" }}>
            <ButtonSpace style={{ alignSelf: "flex-end" }}>
              <PermissionButton
                permissions="Order.updateInvoiceType"
                type="primary"
                onClick={updateOrders}
              >
                批量修改发票
              </PermissionButton>
              <PermissionButton
                permissions="Order.importListByNoteIds"
                type="primary"
                onClick={asyncOrderSystem}
              >
                同步订单系统
              </PermissionButton>
              <PermissionButton
                permissions="Order.dzswjAuthLogin"
                type="primary"
                onClick={showAuthorization}
              >
                数电授权
              </PermissionButton>
              <PermissionButton
                permissions="Order.importOrder"
                type="primary"
                onClick={showImportContratMD}
              >
                导入单据
              </PermissionButton>
              <PermissionButton
                permissions="Order.save"
                type="primary"
                onClick={handleLinkToInvoiceMakeout}
              >
                手工录入
              </PermissionButton>
              <PermissionButton
                permissions="Order.qrCode"
                type="primary"
                loading={qrcodeLoading}
                onClick={handleQrcodeUrl}
              >
                二次身份认证
              </PermissionButton>
            </ButtonSpace>
            <ButtonSpace style={{ marginTop: "16px", alignSelf: "flex-end" }}>
              <PermissionButton
                permissions="Order.merger"
                type="primary"
                onClick={() =>
                  handleOperationsClick(OperationType.MERGERECEIPTS)
                }
              >
                合并单据
              </PermissionButton>
              <PermissionButton
                permissions="Order.markInvoice"
                type="primary"
                onClick={showMarkInvoicingMD}
              >
                标记为已开票
              </PermissionButton>
              <PermissionButton
                permissions="Order.markInvoicing"
                type="primary"
                loading={asToBeInvoicedLoading}
                onClick={handleAsToBeInvoiced}
              >
                标记为待开票
              </PermissionButton>
              <PermissionButton
                permissions="Order.mergerLine"
                type="primary"
                onClick={() => handleOperationsClick(OperationType.MERGE)}
              >
                合并明细
              </PermissionButton>
              <PermissionButton
                permissions="Order.split"
                type="primary"
                onClick={() => handleOperationsClick(OperationType.SPLIT)}
              >
                拆分
              </PermissionButton>
              <PermissionButton
                permissions="Order.reduction"
                type="primary"
                onClick={() => handleOperationsClick(OperationType.RESTORE)}
              >
                还原
              </PermissionButton>
              <PermissionButton
                permissions="Order.batchInvoice"
                type="primary"
                loading={batchInvoiceLoading}
                onClick={handleBatchInvoice}
              >
                批量开发票
              </PermissionButton>
              <PermissionButton
                type="primary"
                permissions="Order.batchDelete"
                clickCallback={handleDelete}
              >
                批量删除
              </PermissionButton>
              <PermissionButton
                type="primary"
                permissions={"OutputInvoice.batchUpdateSeller"}
                clickCallback={handleBatchUpdateSeller}
              >
                更新销方信息
              </PermissionButton>
              <PermissionButton
                type="primary"
                permissions={"OutputInvoice.batchUpdateInvType"}
                clickCallback={handleUpdateInvoiceType}
              >
                更新发票类型
              </PermissionButton>
            </ButtonSpace>
          </div>
        }
      >
        <Table
          onTableRef={onTableRef}
          url={`/tax/orders`}
          columns={columns}
          isExpandAllItem={true}
          queryProps={{
            initialValues: {
              statusCode: "1",
              approver: defaultApprover,
            },
          }}
          summary={tableSummary}
          pagination={{ hideOnSinglePage: false }}
          selectedRowKeys={[]}
          scroll={{ y: window.innerHeight - 300 }}
        >
          <Item name="requestDateFromTo">
            <RangePicker
              placeholder={["单据日期从", "单据日期至"]}
              format="YYYYMMDD"
              defaultValue={[
                DateUtils.monthFirstDay(),
                DateUtils.monthLastDay(),
              ]}
              inputReadOnly
              style={{ width: "100%" }}
            />
          </Item>
          <Item name="generateWay">
            <Select placeholder="来源系统">
              <Option value="2">立牌扫码</Option>
              <Option value="4">手工</Option>
              <Option value="7">订单系统</Option>
              {/* <Option value="14">小程序</Option> */}
            </Select>
          </Item>
          <Item name="invoiceType">
            <Select placeholder="发票类型">
              <Option value="31">数电票（增值税专用发票）</Option>
              <Option value="32">数电票（增值税普通发票）</Option>
              <Option value="85">数电纸质票（增值税专用发票）</Option>
              <Option value="86">数电纸质票（增值税普通发票）</Option>
            </Select>
          </Item>
          <Item name="statusCode">
            {/* <Lookup
              placeholder="发票状态"
              lookupType="TAX_OUTPUT_INVOICE_STATUS"
            /> */}
            <Select placeholder="发票状态">
              <Option value="1">待开票</Option>
              <Option value="3">开票成功</Option>
              <Option value="4">开票失败</Option>
              <Option value="5">待作废</Option>
              <Option value="6">已作废</Option>
            </Select>
          </Item>
          <Item name="buyerName">
            <Input placeholder="购方名称/购方税号" />
          </Item>
          {/* <Item name="batchNo">
            <Input placeholder="批次号" />
          </Item> */}
          <Item name="invoiceNo">
            <Input placeholder="发票号码" />
          </Item>
          <Item name="orderNo">
            <Input placeholder="单据编号" />
          </Item>
          <Item name="approvalStatus">
            <Lookup lookupType="APPROVE_RESULT" placeholder="审批状态" />
          </Item>
          {hasPermissions ? (
            <Item name="deptIdList">
              <Select placeholder="选择门店" options={deptList} allowClear />
            </Item>
          ) : (
            <></>
          )}
        </Table>
      </Card>
    </Page>
  );
};

export default WaitInvoiceList;
