import React, { forwardRef, Fragment, memo, useEffect, useImperativeHandle, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { UploadOutlined } from '@ant-design/icons'
import { Download, Table } from '@comps'
import { ColumnProps } from '@comps/types'
import { UserContext } from '@models'
import { Api, Context, Http, DateUtils } from '@utils'
import { Button, Col, DatePicker, Form, Input, message, Modal, Row, Select, Upload } from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import moment from 'moment'


const disabledDate = (current: any) => current && current > moment().endOf('day')

interface parmas {
  visibleVaule: boolean, title: string, action?: number, type?: string | undefined, id?: number | undefined,
  status?: number | undefined, orgId?: number | undefined
}

const InvoiveActionModal = forwardRef((props: any, ref) => {
  let columns: ColumnProps[] = [
    { title: "移交人", key: "transferPeople", width: 150 },
    { title: "交接人", key: "connectPeople", width: 150 },
    { title: "交接日期", key: "connectDateCn", width: 150 },
    {
      title: "备注", key: "remark", width: 150, render: (id, row) => {
        return (row.remark !== undefined || row.remark !== null) && <span>{row.remark}</span>
      }
    },
  ]

  let fileColumns: ColumnProps[] = [
    { title: "文件名称", key: "attachmentName", width: 150 },
    { title: "上传时间", key: "creationTime", width: 150 },
    { title: "文件类型", key: "attachmentType", width: 80 },
    { title: "文件大小", key: "attachmentSize", width: 80 },
    {
      title: "发票URL", key: "attachmentUrl", width: 180, render: (id, row) => {
        return <a target="_blank" href={`${row.attachmentUrl}?accessToken=${userInfo.token}`}>{row.attachmentUrl}</a>
      }
    },
  ]

  let fileChangeColumns: ColumnProps[] = [
    {
      title: "变更内容", key: "changeContent", width: 200, render: (id, row) => {
        return row.changeContent === 1 ? <span>档案信息</span> : <span>档案附件</span>
      }
    },
    { title: "变更人", key: "operatePeople", width: 200 },
    { title: "变更时间", key: "changeDate", width: 200 },
  ]

  const refreshTable = props.refresh
  const userInfo = useSelector<JSONObject, UserContext>((state) => state.ctx)
  const [parmas, setParmas] = useState<parmas>({ visibleVaule: false, title: '', action: 0, type: undefined, id: undefined, orgId: undefined })
  const [option, setOption] = useState([])
  const [fileList, setFileList] = useState<any[]>([])
  const [form] = Form.useForm()
  let [table, setTable] = useState<Table>()
  let [peopleTable, setPeopleTable] = useState<Table>()
  let [fileTable, setFileTable] = useState<Table>()
  const fileListRef: any = useRef([])
  const uploadRef: any = useRef()
  const fileNum = useRef<number>(0)


  useImperativeHandle(ref, () => ({
    openModal
  }));


  useEffect(() => {
    Api.getKv('/sys/users/kv?includeMobile=false')
      .then((res: any) => {
        setOption(res);
      })
  }, [])

  useEffect(() => {
    if (parmas.id && parmas.visibleVaule === true) {
      if (parmas.type === '1') {
        Http.get(`/output/document/${parmas.id}`).then((res) => {
          setFormData(res)
        })
      } else {
        Http.get(`/input/document/${parmas.id}`).then((res) => {
          setFormData(res)
        })
      }
    } else {
      form.resetFields()
    }
  }, [parmas.id, parmas.visibleVaule])

  const TableRef = (tableRef: Table) => {
    table = tableRef;
    setTable(tableRef);
  }

  const peopleTableRef = (tableRef: Table) => {
    peopleTable = tableRef;
    setPeopleTable(tableRef);
  }

  const fileTableRef = (tableRef: Table) => {
    fileTable = tableRef;
    setFileTable(tableRef);
  }




  const saveInvoice = () => {
    form.validateFields().then(async () => {
      const data = await getParams()
      // action: 0 是新增， 1 是查看 ，2是编辑   type: 1 销项发票  2 进项发票
      if (parmas.action === 0) {
        parmas.type === '1' ? await Http.post('/output/document', data) : await Http.post('/input/document', data)
      } else {
        parmas.type === '1' ? await Http.put('/output/document', data) : await Http.put('/input/document', data)
      }
      closeModal()
      fileListRef.current = []
      setFileList([])
      refreshTable()
      message.success('保存成功')

    })

  }

  const sumbitInvoive = () => {
    form.validateFields().then(async () => {
      const data = await getParams()
      data.status = 1
      if (parmas.action === 2) {
        parmas.type === '1' ? await Http.put('/output/document', data) : await Http.put('/input/document', data)
      } else {
        parmas.type === '1' ? await Http.post('/output/document', data) : await Http.post('/input/document', data)
      }
      closeModal()
      fileListRef.current = []
      setFileList([])
      refreshTable()
      message.success('发布成功')

    })
  }


  //上传文件时的处理
  const uploadOk = async () => {
    const newfileList = fileList.filter((item: {
      uid: any
    }) => !fileListRef.current.some((ele: { id: any; }) => ele.id === item.uid));
    if (fileList.length === 0) return
    for (const key in newfileList) {
      const formData = new FormData();
      formData.append('file', newfileList[key]);
      await Http.postWithFormFile("/cms/files",
        formData
      ).then((res: any) => {
        fileListRef.current.push({
          attachmentName: res.fileName,
          attachmentSize: res.humanFileSize,
          attachmentStoragePath: res.storagePath,
          attachmentType: res.fileType,
          attachmentUrl: res.fileUrl,
          id: res.id,
          uploadDate: moment().format('YYYY-MM-DD')
        })

      })
    }
  }

  const customRequest = (options: any) => {
    const { file } = options
    setFileList([...fileList, file])
    const index = file.name.lastIndexOf('.')
    const name = file.name.slice(index + 1, file.name.length);
    const newFile = {
      id: file.uid,
      attachmentName: file.name,
      creationTime: moment().format('YYYY-MM-DD HH:mm'),
      attachmentType: name,
      attachmentSize: sizeTostr(file.size)
    }
    const dataSource = table?.getTableData()
    table?.setDataSource([...dataSource, newFile])
    fileNum.current = fileNum.current + 1
    form.setFieldsValue({
      attachmentQuantity: fileNum.current
    })
  }

  const removeFile = async () => {
    const dataSource = table?.getTableData()
    const delId: any = table?.getSelectedRowKeys()
    if (delId.length === 0) return message.warn("至少选择一条数据")
    const newDataSource = dataSource.filter((item: any) => {
      return !(delId?.some((temId: any) => temId === item.id))
    })
    if (parmas.action === 2) {
      fileListRef.current = fileListRef.current.filter((item: any) => {
        return !(delId?.some((temId: any) => temId === item.id))
      })
    }
    const newFileData = fileList.filter((item: any) => {
      return !(delId?.some((temId: any) => temId === item.uid))
    })
    await setFileList(newFileData)
    form.setFieldsValue({})
    fileNum.current = newFileData?.length
    form.setFieldsValue({
      attachmentQuantity: fileNum.current
    })
    table?.setDataSource(newDataSource)
    message.success('移除成功')
  }

  //获取表单字段
  const getParams = async () => {
    await uploadOk()
    const formData = form.getFieldsValue()
    const data = {
      attachmentQuantity: Number(formData.attachmentQuantity),
      content: formData.content,
      chargePeople: formData.chargePeople,
      declareDate: Number(moment(formData.declareDate).format('YYYYMM')),
      establishDate: moment(formData.establishDate).format('YYYY-MM-DD'),
      establishPeople: formData.establishPeople || userInfo.fullName,
      id: parmas.action === 0 ? undefined : parmas.id,
      invoiceCode: formData.invoiceCode,
      invoiceNumber: formData.invoiceNumber,
      invoicingDate: Number(moment(formData.establishDate).format('YYYYMM')),
      orgId: parmas.orgId || undefined,
      status: 0,
      documentAttachmentList: fileListRef.current
    }

    if (data.chargePeople !== userInfo.fullName && data.chargePeople) {
      const objOption: any = option.find((item: any) => { return item.value === formData.chargePeople })
      if (objOption !== undefined) {
        data.chargePeople = objOption?.label
      }
    }


    return data

  }

  // 设置表单字段
  const setFormData = (data: any) => {
    form.setFieldsValue({
      billNo: data.billNo,
      attachmentQuantity: data.attachmentQuantity,
      chargePeople: data.chargePeople,
      content: data.content,
      declareDate: moment(data.declareDate, 'YYYY-MM'),
      establishDate: moment(data.establishDate),
      establishPeople: data.establishPeople,
      invoiceCode: data.invoiceCode,
      invoiceNumber: data.invoiceNumber,
      invoicingDate: moment(data.invoicingDate, 'YYYY-MM'),
      voucherNumber: data.voucherNumber,
    })
    if (data.documentAttachmentList.length !== 0) {
      fileListRef.current = [...fileListRef.current, ...data.documentAttachmentList]
      const fileList = data.documentAttachmentList?.map((item: { id: any; attachmentName: any; }) => {
        return { uid: item.id, name: item.attachmentName }
      })
      fileNum.current = data.documentAttachmentList.length
      setFileList(fileList)
    }
    table?.setDataSource(data.documentAttachmentList)
    peopleTable?.setDataSource(data.chargePeopleList)
    fileTable?.setDataSource(data.changeRecordList)
  }



  const openModal = (obj: parmas) => {
    if (obj.action === 0) {
      table?.setDataSource([])
      peopleTable?.setDataSource([])
    }
    setParmas(obj)
  }

  const closeModal = (flag?: boolean) => {
    fileListRef.current = []
    fileNum.current = 0
    setFileList([])
    if (parmas.action === 0) {
      form.resetFields()
    }
    setParmas({ ...parmas, visibleVaule: false })
  }



  const checkFileSize = (file: any) => {
    return file.size / 1024 / 1024 < 50;
  }

  const sizeTostr = (size: number) => {
    let data = "";
    if (size < 0.1 * 1024) { //如果小于0.1KB转化成B  
      data = size.toFixed(2) + "B";
    } else if (size < 0.1 * 1024 * 1024) {//如果小于0.1MB转化成KB  
      data = (size / 1024).toFixed(2) + "KB";
    } else if (size < 0.1 * 1024 * 1024 * 1024) { //如果小于0.1GB转化成MB  
      data = (size / (1024 * 1024)).toFixed(2) + "MB";
    } else { //其他转化成GB  
      data = (size / (1024 * 1024 * 1024)).toFixed(2) + "GB";
    }
    let sizestr = data + "";
    let len = sizestr.indexOf("\.");
    let dec = sizestr.substr(len + 1, 2);
    if (dec == "00") {//当小数点后为00时 去掉小数部分  
      return sizestr.substring(0, len) + sizestr.substr(len + 3, 2);
    }
    return sizestr;
  }


  return (
    <Modal visible={parmas.visibleVaule} title={parmas.title}
      width={'800px'} onCancel={() => { closeModal(false) }} onOk={sumbitInvoive}
      footer={
        parmas.id && parmas.action === 1 ?
          [
            <Button key="back" onClick={() => { closeModal(false) }}>
              取消
            </Button>,
          ]
          :
          [
            (parmas.id === undefined || parmas.status === 0) && <Button type="primary" onClick={saveInvoice}>存为草稿
            </Button>,

            <Button key="sumbit" type="primary" onClick={sumbitInvoive}>
              发布
            </Button>,
            <Button key="back" onClick={() => { closeModal(false) }}>
              取消
            </Button>,

          ]
      }
    >
      <Form layout='horizontal' form={form} initialValues={{
        establishPeople: userInfo.fullName,
        chargePeople: userInfo.fullName,
        establishDate: moment(),
        invoicingDate: moment(),
        attachmentQuantity: 0
      }}>
        <Row>
          <Col span={12}>
            <Form.Item name="invoiceCode" label="发票代码：" labelCol={{ span: 6 }} wrapperCol={{ span: 16 }}
              rules={[{ required: true, message: "请填写发票代码" }, { pattern: /^[^\u4e00-\u9fa5]+$/g, message: '不能输入中文' }]}>
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="invoiceNumber" label="发票号码：" labelCol={{ span: 6 }} wrapperCol={{ span: 16 }}
              rules={[{ required: true, message: "请填写发票号码" }, { pattern: /^[^\u4e00-\u9fa5]+$/g, message: '不能输入中文' }]}>
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <Form.Item name="establishDate" label="建档时间：" labelCol={{ span: 6 }} wrapperCol={{ span: 16 }}
              rules={[{ required: true, message: "请选择建档时间" }]} >
              <DatePicker format="YYYY-MM-DD" disabledDate={disabledDate} disabled={true} style={{ width: '100%' }} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="chargePeople" label="当前负责人：" labelCol={{ span: 6 }} wrapperCol={{ span: 16 }}
              rules={[{ required: true, message: "请填写当前负责人" }]}>
              <Select options={option} showSearch optionFilterProp="label" allowClear />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <Form.Item name="invoicingDate" label="开票月份：" labelCol={{ span: 6 }} wrapperCol={{ span: 16 }}
              rules={[{ required: true, message: "请选择开票日期" }]}>
              <DatePicker format="YYYY-MM" picker="month" disabledDate={disabledDate} inputReadOnly style={{ width: '100%' }} ></DatePicker>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="declareDate" label="申报所属期：" labelCol={{ span: 6 }} wrapperCol={{ span: 16 }}
              rules={[{ required: true, message: "请选择申报所属期" }]}>
              <DatePicker picker="month" inputReadOnly format={"YYYY-MM"} style={{ width: '100%' }} ></DatePicker>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <Form.Item name="attachmentQuantity" label="附件数量：" labelCol={{ span: 6 }} wrapperCol={{ span: 16 }}
              rules={[{ pattern: /^[0-9]+$/, message: '数量只能为正整数或0' }]}>
              <Input disabled={true} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="establishPeople" label="建档人：" labelCol={{ span: 6 }} wrapperCol={{ span: 16 }}
              rules={[{ required: true, message: "请填写建档人" }]}>
              <Input disabled={true} />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
          </Col>
        </Row>
        <Form.Item name="content" label="内容简介：" labelCol={{ span: 3 }}>
          <TextArea rows={4} placeholder="内容简介" />
        </Form.Item>
        <Form.Item label="上传资料详情:" style={{ marginTop: '10px' }}>
          {parmas.action !== 1 &&
            <Fragment>
              <Upload accept="*" customRequest={customRequest} showUploadList={false}
                beforeUpload={checkFileSize} ref={uploadRef} fileList={fileList} multiple={true}
              >
                <Button icon={<UploadOutlined />}>上传</Button>
              </Upload>
              <Button style={{ marginLeft: '20px' }} onClick={removeFile}>删除</Button>
            </Fragment>
          }
        </Form.Item>
        <Table
          columns={fileColumns}
          pagination={false}
          onTableRef={TableRef}
          selectedRowKeys={[]}
          rowKey={'id'}
        ></Table>
        <Form.Item label="负责人员变更记录：" >
        </Form.Item>
        <Table columns={columns} dataSource={[]} pagination={false} onTableRef={peopleTableRef} rowKey={'id'} ></Table>
        <Form.Item label="档案变更记录：" >
        </Form.Item>
        <Table columns={fileChangeColumns} dataSource={[]} pagination={false} onTableRef={fileTableRef} rowKey={'id'} ></Table>
        <Row style={{ marginTop: '20px', marginRight: '20px' }}>
        </Row>
      </Form>
    </Modal>
  )

})


export default memo(InvoiveActionModal)