/**
 * 初始状态
 */
let initialState: any = {
    ctx: {
        logined: false,
        appName: '',
        copyright: '',
        beian: '',
        panes: []
    }
}

export default initialState