export interface TableRow {
  id: number,
  requestNo: string,
  statusCode: number,
  statusCodeDesc: string,
  needRedNoticeNo: boolean,
  allowRetry?: boolean,
  submitTime: string,
  submitterName: string,
  approverName: string,
  approveTime: string,
  approveRemarks: string,
  picUrl: string
}

export enum BillType {
  /**
   * 全部
   */
   ALL_TYPE = '',
  /**
   * 客运车
   */
  PASSENGER_CAR = '18',
  /**
  * 火车
  */
  TRAIN = '17',
  /**
   * 航空运输电子行程单
   */
  AVIATION = '19',
  /**
  * 出租车票
  */
  TAXI = '20',
  /**
   * 船票
   */
  SHIP = '21',
  /**
  * 过桥过闸通行费发票
  */
  BRIDGE = '22',
  /**
   * 通用机打发票
   */
  MACHINE = '23',
  /**
  * 定额发票
  */
  QUOTA = '24',
  /**
  * 其他
  */
  OTHER = '99',
}


