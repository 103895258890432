import React, { FC, useState } from 'react';
import { Form, Row, Col } from 'antd';

import { Card, Button, Kv } from '@comps';
import { Http, Context, Message } from '@utils';

export interface Props {
  onCancel: () => void;
  onConfirm: () => void;
};

const { Item } = Form;

const SwitchRole: FC<Props> = ({
  onCancel, 
  onConfirm,
}) => {

  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const handleSave = async () => {
    onConfirm();
    Message.success("切换成功");

    const roleId = form.getFieldValue("roleId");
    await Http.get(`/sys/users/switchRole?roleId=${roleId}`);
    await Context.initUserContext();
      
    // 清除路由缓存
    window.location.reload();
  };

  return (
    <Card 
      title="切换角色" 
      loading={loading}
    >
      <Form form={form}>
        <Item 
          label='请选择角色' 
          name="roleId" 
          rules={[{ required: true }]}
        >
          <Kv url='/sys/users/roleKv' />
        </Item>
      </Form>
      <Row>
        <Col offset={10} span={10}>
          <Button 
            type="primary" 
            clickCallback={handleSave}
          >
            确认切换
          </Button>
        </Col>
      </Row>
    </Card>
  );
};

export default SwitchRole;