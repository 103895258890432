import * as React from 'react';

import { Form, DatePicker, Input, message, Space, Upload } from 'antd';
import { Page, Card, PermissionButton, Table, Lookup, Modal, Download, Button } from '@comps';
import { Link } from 'react-router-dom';

import { DateUtils, Lang, Http, Env, Context } from '@utils';
import { ColumnProps } from '@comps/types';

import BackMd from './back.md';
import BulkOrderMD from './bulkOrder.md';
import MailOrderMD from './mailOrder.md';

import { Transfer } from './interface';

export interface Props {};

const InvoicePage: React.FC<Props> = () => {
  const [model, modalHolder] = Modal.useModal();

  const tableRef = React.useRef<Table>();

  const initQuery = React.useMemo<JSONObject>(() => ({
    startDateFromTo: [DateUtils.monthFirstDay(), DateUtils.now()],
  }), []);

  const [uploadLoading, setUploadLoading] = React.useState<boolean>(false);

  const handleBack = React.useCallback(() => Lang.noError(async () => {
    const ids = tableRef.current?.getSelectedRowKeys();
    if (!ids || !ids.length) {
      message.warn('请至少选择一条记录');
      return;
    }
    await model.open({
      width: '700px',
      ignoreError: false,
      content(resolve, reject) {
        return (<BackMd ids={ids}
          onConfirm={resolve}
          onClose={reject} />);
      },
    });
    message.success('退回成功');
    tableRef.current?.refresh();
  }), [model]);

  const showBulkOrderMD = (mainOrderInfo: JSONObject, invoiceIds: number[]) => {
    model.open({
      width: '400px',
      ignoreError: true,
      content(resolve, reject) {
        return (
        <BulkOrderMD showMailOrderInfo={() => {showMailOrderInfo(mainOrderInfo, invoiceIds)}} onClose={reject} />);
      },
    });
  }

  const showMailOrderInfo = async (mainOrderInfo: JSONObject, invoiceIds: number[]) => {
    model.open({
      width: '450px',
      ignoreError: true,
      content(resolve, reject) {
        return (
          <MailOrderMD isWaiting={false} mainOrderInfo={mainOrderInfo} invoiceIds={invoiceIds} onClose={reject} />
        );
      }
    });
  }

  const checkIsAllowOrder = (statusCodes: number[]): boolean => {
    let isAllowOrder = true;
    const notAllowOrderStatusCodes: number[] = [0, 1, 3, 4, 6, 7, 8, 100, 101]; 
    for (let i = 0; i < statusCodes.length; i++) {
      if (notAllowOrderStatusCodes.includes(statusCodes[i])) {
        isAllowOrder = false;
        break;
      }
    }
    return isAllowOrder;
  }

  const handleMailOrder = async () => { 
    const ids = tableRef.current?.getSelectedRowKeys();
    if (!ids || !ids.length) {
      message.warn('请至少选择一条记录');
      return;
    }

    const statusCodeArr: number[] = tableRef.current?.getSelectedRowAttr('statusCode') || [];
    if (!checkIsAllowOrder(statusCodeArr)) {
      message.warn('当前交接状态不允许邮寄下单');
      return;
    }

    const buyerNameArr: string[] = tableRef.current?.getSelectedRowAttr('buyerName') || [];
    const invoiceIdsArr: number[] = tableRef.current?.getSelectedRowAttr('invoiceId') || [];
    let isSameBuyerName = true;

    for (let i = 0; i < buyerNameArr.length; i++) {
      if (buyerNameArr[0] !== buyerNameArr[i]) {
        isSameBuyerName = false;
        break;
      }
    }

    const invoiceIds = invoiceIdsArr.join();
    const mainOrderInfo: JSONObject = await Http.get('/tax/mailingManagement/mailingUserInfo', { invoiceIds });

    if (isSameBuyerName) {
      showMailOrderInfo(mainOrderInfo, invoiceIdsArr);
    } else {
      showBulkOrderMD(mainOrderInfo, invoiceIdsArr);
    }
  }

  const handleReceive = React.useCallback(() => Lang.noError(async () => {
    const ids = tableRef.current?.getSelectedRowKeys();
    if (!ids || !ids.length) {
      message.warn('请至少选择一条记录');
      return;
    }
    await Http.put('/tax/transfers/receive', { ids });
    message.success('接收成功，您可将发票交接给下一节点人员');
    tableRef.current?.refresh();
  }), []);

  const handleSend = React.useCallback(() => Lang.noError(async () => {
    const ids = tableRef.current?.getSelectedRowKeys();
    if (!ids || !ids.length) {
      message.warn('请至少选择一条记录');
      return;
    }
    await Http.put('/tax/transfers/send', { ids });
    message.success('确认成功');
    tableRef.current?.refresh();
  }), []);

  const columns: ColumnProps[] = [
    {
      key: 'invoiceCode', title: '发票代码', width: 150,
      render(value, transfer: Transfer) {
        return (<Link to={`/tax/transfer/detail/${transfer.id}`}>{value}</Link>);
      },
    },
    { key: 'invoiceNo', title: '发票号码', width: 120, },
    { key: 'invoiceDate', title: '开票日期', width: 120, },
    { key: 'startDate', title: '开始时间', width: 200, },
    { key: 'endDate', title: '结束时间', width: 200 },
    { key: 'invoicerName', title: '开票员', width: 120 },
    { key: 'financialOfficer', title: '大区财务', width: 120 },
    { key: 'clerkName', title: '业务员', width: 120, },
    { key: 'buyerName', title: '购买方', width: 200, },
    { key: 'withTaxAmount', title: '价税合计', format: 'amount', width: 200, },
    { key: 'orgName', title: '销售方', width: 200, },
    { key: 'statusCodeDesc', title: '交接状态', width: 150, fixed:'right'},
    {
      key: 'id', title: '操作', fixed: "right", width: 150,
      render (id: number, transfer: Transfer) {
        return (<Space>
          <Link to={`/tax/transfer/detail/${id}`}>详情</Link>
          {transfer.allowPreview && <Download url={transfer.signFileUrl} preview></Download>}
        </Space>);
      },
    },
  ];

  return (
    <Page>
      {modalHolder}
      <Card title='发票交接' actions={<Space>
        <PermissionButton permissions="InvoiceTransfer.back"
          type="primary" onClick={handleBack}>退回</PermissionButton>
        <Button type="primary" onClick={handleMailOrder}>邮寄下单</Button>
        <PermissionButton permissions="InvoiceTransfer.receive"
          type="primary" clickCallback={handleReceive}>确认收到发票</PermissionButton>
        <PermissionButton permissions="InvoiceTransfer.send"
          type="primary" clickCallback={handleSend}>确认寄出发票</PermissionButton>
        <Upload action={`${Env.getBaseUrl()}/tax/transfers/uploadSignFile`}
            headers={{
              Authorization: Context.getToken(),
            }}
            accept="image/*"
            data={async () => {
              const ids = (tableRef.current?.getSelectedRowKeys() || []).join(',');
              return {
                ids,
              };
            }}
            beforeUpload={(file) => {
              const ids = tableRef.current?.getSelectedRowKeys();
              if (ids && ids.length) {
                return true;
              }
              message.warn('请至少选择一条记录');
              return false;
            }}
            onChange={async ({ file: { status, response = {} } }) => {
              if (!['done', 'error'].includes(status as string)) {
                return;
              }
              setUploadLoading(false);
              message.success(status === 'done' ? '上传成功': '上传失败');
              tableRef.current?.refresh();
            }}
            showUploadList={false}>
            <PermissionButton permissions="InvoiceTransfer.uploadSignFile"
              loading={uploadLoading}
              type="primary">
                上传签收单
            </PermissionButton>
          </Upload>
      </Space>}>
        <Table url="/tax/transfers"
          queryProps={{
            initialValues: initQuery,
          }}
          onTableRef={(ref) => {
            tableRef.current = ref;
          }}
          selectedRowKeys={[]}
          columns={columns}>
          <Form.Item name="startDateFromTo">
            <DatePicker.RangePicker format='YYYYMMDD' placeholder={["开始日期从", "至"]} inputReadOnly/>
          </Form.Item>
          <Form.Item name="buyerCode">
            <Input placeholder="客户编码" />
          </Form.Item>
          <Form.Item name="statusCode">
            <Lookup lookupType="TRANSFER_STATUS" placeholder="交接状态"></Lookup>
          </Form.Item>
          <Form.Item name="invoiceNo">
            <Input placeholder="发票号码" />
          </Form.Item>
        </Table>
      </Card>
    </Page>
  );
};

export default InvoicePage;
