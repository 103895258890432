import React, { FC, useState, BaseHTMLAttributes, useEffect, useRef } from 'react';
import { InputNumber, Form } from 'antd';

import { Table, DisplayInput } from '@comps';

import { Message } from '@utils';
import { ColumnProps } from '@comps/types';
import { ApplyType } from '../../../interface';
import { useForm } from 'antd/lib/form/Form';
import { defaultCoreCipherList } from 'constants';

interface Props extends BaseHTMLAttributes<HTMLElement> {
  ref?: any,
  applyType: string,
  orderId: number,
  subTableArr: JSONObject,
  childTableIdsRef: JSONObject
};

const ImportDetailSubTable: FC<Props> = ({
  applyType,
  orderId,
  subTableArr,
  childTableIdsRef,
}) => {

  let [table, setTable] = useState<Table>();

  const onTableRef = (tableRef: Table) => {
    setTable(tableRef);
    table = tableRef;
  };

  const [form] = useForm(); 

  const handleWithTaxAmtChange = React.useCallback((
    applyQuantity: any, 
    row: JSONObject
  ) => {

    const isRemainQuantityNegative = row.remainQuantity < 0;
    const maxRemainQuantity = isRemainQuantityNegative 
      ? (row.remainQuantity) * -1 
      : row.remainQuantity;

    if (isRemainQuantityNegative) {
      if (applyQuantity > 0 || applyQuantity < row.remainQuantity) {
        Message.warn('“提交申请数量” 不能大于 “未开数量”')
        return;
      }
    } else {
      if (applyQuantity > maxRemainQuantity) {
        Message.warn('“提交申请数量” 不能大于 “未开数量”')
        return;
      };
    }

    row.applyQuantity = applyQuantity;
    row.lineId = row.id;

    const inputedRowData: JSONObject[] = subTableArr.current;
    const currentRowIndex = inputedRowData.findIndex(
      (_row: JSONObject) => _row.id === row.id
    )
    
    if (currentRowIndex === -1) {
      inputedRowData.push(row);
    } else {
      inputedRowData[currentRowIndex] = row;
    }
  }, []);
  
  const handleWithMoneyChange = React.useCallback((
    applyAmount: any, 
    row: JSONObject
  ) => {

    const isRemainAmountNegative = row.remainAmount < 0;
    const maxRemainAmount = isRemainAmountNegative 
      ? (row.remainAmount) * -1 
      : row.remainAmount;

    if (isRemainAmountNegative) {
      if (applyAmount > 0 || applyAmount < row.remainAmount) {
        Message.warn('“提交申请金额” 不能大于 未开票金额')
        return;
      }
    } else {
      if (applyAmount > maxRemainAmount) {
        Message.warn('“提交申请金额” 不能大于 未开票金额')
        return;
      };
    }

    row.applyAmount = applyAmount;
    row.lineId = row.id;

    const inputedRowData: JSONObject[] = subTableArr.current;
    const currentRowIndex = inputedRowData.findIndex(
      (_row: JSONObject) => _row.id === row.id
    )
    
    if (currentRowIndex === -1) {
      inputedRowData.push(row);
    } else {
      inputedRowData[currentRowIndex] = row;
    }
  }, []);

  const handleMainQuantityChange = React.useCallback((
    mainQuantity: any, 
    row: JSONObject, 
    index: number
  ) => {

    const isRemainQuantityNegative = row.remainQuantity < 0;
    const maxRemainQuantity = isRemainQuantityNegative 
      ? (row.remainQuantity) * -1 
      : row.remainQuantity;

    if (isRemainQuantityNegative) {
      if (mainQuantity > 0 || mainQuantity < row.remainQuantity) {
        Message.warn('“提交申请数量” 不能大于 “未开数量”')
        return;
      }
    } else {
      if (mainQuantity > maxRemainQuantity) {
        Message.warn('“提交申请数量” 不能大于 “未开数量”')
        return;
      };
    }

    const { conversionRate } = row;
    const assistantQuantity = +(mainQuantity * conversionRate).toFixed(8);
    
    form.setFieldsValue({
      ['mainQuantity' + (index + 1)]: mainQuantity, 
      ['assistantQuantity' + (index + 1)]: assistantQuantity
    })

    row.applyQuantity = mainQuantity;
    row.assistantQuantity = assistantQuantity;
    row.lineId = row.id;

    const inputedRowData: JSONObject[] = subTableArr.current;
    const currentRowIndex = inputedRowData.findIndex(
      (_row: JSONObject) => _row.id === row.id
    )
    
    if (currentRowIndex === -1) {
      inputedRowData.push(row);
    } else {
      inputedRowData[currentRowIndex] = row;
    }
  }, []);

  const handleAssistantQuantityChange = React.useCallback((
    assistantQuantity: any, 
    row: JSONObject,
    index: number
  ) => {

    const { conversionRate } = row;
    const mainQuantity = +(assistantQuantity / conversionRate).toFixed(8);
    
    form.setFieldsValue({
      ['mainQuantity' + (index + 1)]: mainQuantity, 
      ['assistantQuantity' + (index + 1)]: assistantQuantity
    })

    const isRemainQuantityNegative = row.remainQuantity < 0
    const maxRemainQuantity = isRemainQuantityNegative 
      ? (row.remainQuantity) * -1 
      : row.remainQuantity;

    if (isRemainQuantityNegative) {
      if (mainQuantity > 0 || mainQuantity < row.remainQuantity) {
        Message.warn('“提交申请数量” 不能大于 “未开数量”')
        return;
      }
    } else {
      if (mainQuantity > maxRemainQuantity) {
        Message.warn('“提交申请数量” 不能大于 “未开数量”')
        return;
      };
    }

    row.applyQuantity = mainQuantity;
    row.assistantQuantity = assistantQuantity;
    row.lineId = row.id;

    const inputedRowData: JSONObject[] = subTableArr.current;
    const currentRowIndex = inputedRowData.findIndex(
      (_row: JSONObject) => _row.id === row.id
    )
    
    if (currentRowIndex === -1) {
      inputedRowData.push(row);
    } else {
      inputedRowData[currentRowIndex] = row;
    }
  }, []);

  const onSelect = (record: JSONObject, selected: boolean, selectedRows: JSONObject[]) => {
    const { id } = record;
    const childTableIds: number[] = childTableIdsRef.current;
    const index = childTableIds.findIndex(rowId => rowId === id);

    if (selected) {
      if (index === -1) {
        childTableIds.push(id);
      }
    } else {
      childTableIds.splice(index, 1); 
    }
  }; 

  const onSelectAll = (
    selected: boolean, 
    selectedRows: JSONObject[], 
    changeRows: JSONObject[]
  ) => {
   
    let childTableIds: number[] = childTableIdsRef.current;
    const ids: number[] = [];
    changeRows.forEach(row => {
      ids.push(row.id);
    });
    
    if (selected) {
      ids.forEach(id => {
        if (!childTableIds.includes(id)) {
          childTableIds.push(id);
        }
      })
    } else {
      childTableIdsRef.current = childTableIds.filter(id => !ids.includes(id));
    }
  };

  const handleOnBlur = () => {
    table?.resetDataSource();
  }
 
  const columns: ColumnProps[] = [
    { key: 'goodsName', title: '商品描述', width: 260, fixed: 'left' },
    { key: 'goodsNo', title: '物料编码', width: 180 },
    { key: 'spec', title: '规格型号', width: 180 },  
    { key: 'unit', title: '单位', width: 180 },
    { key: 'orderQuantity', title: '订单总数量', width: 160 },
    { key: 'quantity', title: '数量', width: 160 },
    { key: 'invoicedQuantity', title: '已开数量', width: 160 },
    { key: 'remainQuantity', title: '未开数量', width: 160, align: 'center' },
    { key: '_applyQuantity', title: '提交申请数量', width: 180, align: 'right', 
      render: (_applyQuantity, row: JSONObject) => {
        const maxRemainQuantity = 
          (row.remainQuantity < 0) 
            ? (row.remainQuantity) * -1 
            : row.remainQuantity
  
        const isDisabled = row.applyAmount ? true : false

        return (
           <InputNumber
            style={{ width: 130 }} 
            max={maxRemainQuantity}
            disabled={isDisabled}
            onChange={(event) => handleWithTaxAmtChange(event, row)}
            onBlur={handleOnBlur}
          />
        )
      }
    },
    { key: '_applyAmount', title: '提交申请金额', width: 180, align: 'right', 
      render: (_applyAmount, row: JSONObject) => {
        const maxRemainAmount = 
          (row.remainAmount < 0) 
            ? (row.remainAmount) * -1 
            : row.remainAmount
        
        const isDisabled = row.applyQuantity ? true : false

        return (
          <InputNumber
            style={{ width: 130 }}
            max={maxRemainAmount}
            disabled={isDisabled}
            onChange={(event) => handleWithMoneyChange(event, row)}
            onBlur={handleOnBlur}
          />
        )
      }
    },
    { key: 'invoicedAmount', title: '已开票金额', width: 180, align: 'center' },
    { key: 'remainAmount', title: '未开票金额', width: 140 },
    { key: 'withTaxPrice', title: '含税单价', width: 140 },
    { key: 'withTaxAmount', title: '含税金额', width: 140 },
    { key: 'balanceAmount', title: '差额', width: 140 },
    { key: 'conversionRate', title: '税率', width: 40 },
    { key: 'largeCategory', title: '大类', width: 120, align: 'center' },
    { key: 'mediumCategory', title: '中类', width: 120, align: 'center' },
    { key: 'smallCategory', title: '小类', width: 120, align: 'center' },
  ];

  const columns_mainAssistant: ColumnProps[] = [
    { key: 'goodsName', title: '商品描述', width: 260, fixed: 'left' },
    { key: 'goodsNo', title: '物料编码', width: 180 },
    { key: 'spec', title: '规格型号', width: 180 },  
    { key: 'unit', title: '主单位', width: 180 },
    { key: 'auxUnit', title: '辅单位', width: 180 },
    { key: 'orderQuantity', title: '订单总数量', width: 160 },
    { key: 'withTaxPrice', title: '主单价', width: 160 },
    { key: 'auxWithTaxPrice', title: '辅单价', width: 160 },
    { key: 'quantity', title: '主数量', width: 160 },
    { key: 'auxQuantity', title: '辅数量', width: 160 },
    { key: 'invoicedQuantity', title: '已开票数量', width: 160 },
    { key: 'remainQuantity', title: '未开数量', width: 160, align: 'center' },
    { key: '_applyQuantity', title: '提交申请数量', width: 180, align: 'right', 
      render: (_applyQuantity, row: JSONObject) => {
        const maxRemainQuantity = 
          (row.remainQuantity < 0) 
            ? (row.remainQuantity) * -1 
            : row.remainQuantity

        return (
          <InputNumber
            style={{ width: 130 }} 
            max={maxRemainQuantity}
            onChange={(event) => handleWithTaxAmtChange(event, row)}
          />
        )
      }
    },
    // { key: '_applyQuantityAssistant', title: '提交申请数量（辅）', width: 180, align: 'center',
    //   render: (_applyQuantity, row: JSONObject, index: number) => {
    //     const maxRemainQuantity = 
    //       (row.remainQuantity < 0) 
    //         ? (row.remainQuantity) * -1 
    //         : row.remainQuantity

    //     return (
    //       <Item name={'assistantQuantity' + (index + 1)} style={{ marginBottom: 0 }}>
    //         <InputNumber
    //           style={{ width: 130 }} 
    //           max={maxRemainQuantity}
    //           onChange={(event) => handleAssistantQuantityChange(event, row, index)}
    //         />
    //       </Item>
    //     )
    //   }
    // },
    { key: '', title: '辅数量', width: 180, align: 'center' },
    { key: 'invoicedAmount', title: '已开票金额', width: 180, align: 'center' },
    { key: 'remainAmount', title: '未开票金额', width: 140 },
    { key: 'withTaxAmount', title: '含税金额', width: 140 },
    { key: 'conversionRate', title: '税率', width: 40 },
    { key: 'largeCategory', title: '大类', width: 120, align: 'center' },
    { key: 'mediumCategory', title: '中类', width: 120, align: 'center' },
    { key: 'smallCategory', title: '小类', width: 120, align: 'center' },
    { key: 'conversionRate', title: '换算率', width: 120, align: 'center' },
  ];

  const renderColumns = () => {
    return (applyType === ApplyType.MAIN_ASSISTANT
      ? columns_mainAssistant
      : columns
    )
  }; 

  return (
    <Form form={form}>
      <Table
        url={`/outputOrder/${orderId}/lines`}
        onTableRef={onTableRef}
        rowKey="id" 
        columns={renderColumns()}
        scroll={{ y: 450 }}
        pagination={false}
        selectedRowKeys={[]}
        className="import-detail-subtable"
        rowSelection={{ onSelect, onSelectAll }}
      />
    </Form>
  )
};

export default ImportDetailSubTable;
