import { Form, Input, Popconfirm, Row, Space, Upload, message } from "antd";
import React, { FC, useState } from "react";

import {
  Button,
  Card,
  Download,
  LinkText,
  Modal,
  Page,
  PermissionButton,
  Table,
} from "@comps";
import { CompanyDetail } from "./companyDetail";
import { CustomerDetail } from "./customerDetail";

import { ColumnProps } from "@comps/types";
import { Context, Env, FormUtil, Http, Layout, Message } from "@utils";

const { Item } = Form;

const OrgList: FC = (props: any) => {
  const path = props.match.path;
  // 是否为客户资料管理
  const isCustomerMgr = "/org/customers" === path;

  const [form] = Form.useForm();
  const [modal, modalHolder] = Modal.useModal();

  const [isModalVisible, setModalVisible] = useState(false);
  const [uploadLoading, setUploadLoading] = useState(false);

  let [table, setTable] = useState<Table>();
  const onTableRef = (tableRef: Table) => {
    table = tableRef;
    setTable(tableRef);
  };

  //删除客户资料
  const delCustomer = (id: number) => {
    Http.del(
      isCustomerMgr ? `/customer/${id}` : `/org/orgs/customer/${id}`
    ).then(() => {
      Message.ok();
      table?.refresh();
    });
  };

  const createOrg = () => {
    form.validateFields().then((values) => {
      console.log(values);
      const url = isCustomerMgr ? "/customer" : "/org/orgs";

      Http.post(url, values).then(() => {
        Message.saveOk();
        setModalVisible(false);
        form.resetFields();
        table?.refresh();
      });
    });
  };

  const enterAddModal = () => {
    setModalVisible(true);
  };

  const onCancel = () => {
    form.resetFields();
    setModalVisible(false);
  };

  const handleOrgNameClick = (orgId: number) => {
    const title = isCustomerMgr ? "客户详情" : "公司详情";
    const route = isCustomerMgr
      ? `/org/client/${orgId}`
      : `/org/company/${orgId}`;

    Context.handleAdditionalPane(title, route, orgId + "");
  };

  const columns: ColumnProps[] = [
    {
      key: isCustomerMgr ? "customerName" : "orgName",
      title: isCustomerMgr ? "公司名称" : "企业名称",
      width: 300,
      fixed: "left",
      render: (orgName: string, row) => (
        <LinkText label={orgName} onClick={() => handleOrgNameClick(row.id)} />
      ),
    },
    {
      key: isCustomerMgr ? "customerCode" : "orgCode",
      title: isCustomerMgr ? "公司编码" : "企业编码",
      width: 220,
    },
    { key: "taxNo", title: "税号", width: 220 },
    { key: "addrTel", title: "地址电话", width: 300 },
    { key: "bankAccount", title: "银行账号", width: 300 },
    {
      title: "操作",
      key: "id",
      align: "center",
      fixed: "right",
      width: 120,
      render: (id: number, row: any) => (
        <Space>
          <Popconfirm
            title={`确认将[ ${
              isCustomerMgr ? row.customerName : row.orgName
            } ]删除?`}
            placement="left"
            onConfirm={() => delCustomer(id)}
            okText="确认"
            cancelText="取消"
          >
            <LinkText label="删除" />
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const exportReport = () => {
    const formParams = table?.getQueryPanelParams() ?? {};

    FormUtil.exportExcelFile("/customer/export", {
      ...formParams,
    });
  };

  return (
    <Page>
      {modalHolder}
      <Card
        title={isCustomerMgr ? "客户资料" : "公司管理"}
        actions={
          <Space>
            {isCustomerMgr && (
              <PermissionButton
                permissions="Customer.export"
                type="primary"
                onClick={exportReport}
              >
                导出
              </PermissionButton>
            )}
            {isCustomerMgr && (
              <Download
                label="下载模板"
                url="/customer/download"
                type="button"
              />
            )}
            {isCustomerMgr && (
              <Upload
                accept=".xlsx"
                action={`${Env.getBaseUrl()}/customer/importCustomer`}
                showUploadList={false}
                headers={{
                  Authorization: Context.getToken(),
                }}
                onChange={async ({ file: { status, response = {} } }) => {
                  setUploadLoading(true);

                  if (!["done", "error"].includes(status as string)) {
                    return;
                  }

                  setUploadLoading(false);

                  const { data = {}, code, msg } = response;

                  if (String(code) !== "200") {
                    message.error(msg || "未知错误, 请联系管理员");
                    return;
                  }

                  const {
                    hasError,
                    total,
                    successSize,
                    errorSize,
                    errorFileUrl,
                  } = data;

                  if (!hasError) {
                    message.success(`全部导入成功, 共 ${total} 家客户`);
                    return;
                  }

                  await modal.open({
                    width: "800px",
                    content(_1, reject) {
                      return (
                        <Card title="导入结果" style={{ marginBottom: "0" }}>
                          <Space direction="vertical" style={{ width: "100%" }}>
                            <Row justify="center">
                              {`总数量: ${total} 条 导入成功: ${successSize} 条 导入失败${errorSize} 条`}
                            </Row>
                            <Row justify="center">
                              <a
                                href={`${errorFileUrl}?accessToken=${Context.getToken()}`}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                点击下载失败记录
                              </a>
                            </Row>
                            <Row justify="center">
                              <Button onClick={() => reject()}>关闭</Button>
                            </Row>
                          </Space>
                        </Card>
                      );
                    },
                  });
                }}
              >
                <PermissionButton
                  permissions={
                    isCustomerMgr ? "Customer.importOrg" : "Org.importCustomer"
                  }
                  type="primary"
                  loading={uploadLoading}
                >
                  导入
                </PermissionButton>
              </Upload>
            )}
            <PermissionButton
              type="primary"
              permissions={
                isCustomerMgr
                  ? ["Customer.create", "Org.createCustomer"]
                  : "Org.create"
              }
              onClick={enterAddModal}
            >
              {isCustomerMgr ? "创建客户" : "创建公司"}
            </PermissionButton>
          </Space>
        }
      >
        <Table
          url={isCustomerMgr ? "/customer" : "/org/orgs"}
          columns={columns}
          rowKey="id"
          onTableRef={onTableRef}
          scroll={{ y: window.innerHeight - 300 }}
        >
          <Item name="key">
            <Input placeholder="公司名称/税号/编码" />
          </Item>
        </Table>
      </Card>

      <Modal
        title="新增"
        visible={isModalVisible}
        onCancel={onCancel}
        onOk={createOrg}
      >
        <Form name="form" form={form} {...Layout.modal_1c}>
          <Item
            name={isCustomerMgr ? "customerName" : "orgName"}
            label="公司名称"
            required
          >
            <Input />
          </Item>
          <Item name="taxNo" label="税号" required>
            <Input />
          </Item>
          <Item name={isCustomerMgr ? "customerCode" : "orgCode"} label="编码">
            <Input placeholder="未提供时系统将自动分配" />
          </Item>
          <Item name="bankName" label="默认开户行">
            <Input />
          </Item>
          <Item name="accountNo" label="默认账号">
            <Input />
          </Item>
          <Item name="address" label="默认地址">
            <Input />
          </Item>
          <Item name="tel" label="默认电话">
            <Input />
          </Item>
        </Form>
      </Modal>
    </Page>
  );
};

export { CompanyDetail, CustomerDetail, OrgList };
