import * as React from 'react';

import { Card, Lookup } from '@comps';
import { Form, Space, DatePicker, Button, Select, Input } from 'antd';

import { Http, Message, Math } from '@utils';
import moment from 'moment'

export interface Props {
    onClose: () => void
    IDs: string,
    taxAmount: number,
    refreshTable: () => void
};

const Item = Form.Item

const Proportion: React.FC<Props> = ({ IDs, taxAmount, refreshTable, onClose }: Props) => {
  const [form] = Form.useForm();

  const transferedTaxAmount = taxAmount

  const handleTransferOutTaxInput = (e: JSONObject) => {
    const transferOutTax = parseFloat(e.target.value)
    const proportion = Math.num2money(transferOutTax / transferedTaxAmount)
    form.setFieldsValue({ proportion })
  }

  const handleProportionInput = (e: JSONObject) => {
    const proportion = parseFloat(e.target.value)
    const authenticationTaxAmount = Math.num2money(proportion * transferedTaxAmount)
    form.setFieldsValue({ transferedTaxAmount: authenticationTaxAmount })
  }

  const handleTransferOut = async () => {
    let fieldsValues = form.getFieldsValue()

    const transferedPeriod_str = moment(fieldsValues.transferedPeriod).format('YYYYMM')
    const transferedPeriod_num = parseInt(transferedPeriod_str)

    fieldsValues.transferedPeriod = transferedPeriod_num
    fieldsValues.id = IDs
    fieldsValues.isFullTransfered = false

    await Http.post('/input/certifiedManagement/invoiceTransferOut', fieldsValues)
      .then(res => {
        Message.success('转出成功')
        refreshTable()
      })
    
    onClose()
  }

    return (
      <Card title="按比例转出">
        <Form form={form} labelCol={{ span: 6 }}
              initialValues={{ transferedTaxAmount: taxAmount, proportion: 1 }}>
            <Item name='transferedTaxAmount' label="转出税额" wrapperCol={{ offset: 1 }}>
              <Input onInput={(e) => {handleTransferOutTaxInput(e)}} />
            </Item>     
            <Item name="proportion" label="转出比例" wrapperCol={{ offset: 1 }}>
              <Input placeholder="0-1，如 0.5" onInput={(e) => {handleProportionInput(e)}} />
            </Item>
            <Item name="transferedType" label="转出原因" wrapperCol={{ offset: 1 }}>
              <Lookup lookupType="TRANSFERED_REASON_TYPE"/>
            </Item>
            <Item name="transferedPeriod" label="转出所属期" wrapperCol={{ offset: 1 }}>
              <DatePicker picker="month" inputReadOnly/>
            </Item>
            <Item wrapperCol={{ offset: 7 }}>
                <Space>
                    <Button type="primary" onClick={handleTransferOut}>确定</Button>
                    <Button onClick={() => onClose()}>取消</Button>
                </Space>
            </Item>
        </Form>
    </Card >);
};

export default Proportion
