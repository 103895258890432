import React, { FC, useState, useEffect, memo } from 'react';
import { Space, Button, Row, Col, Form, Input, InputNumber, DatePicker, Select } from 'antd';
import moment from 'moment';
import { Card, DisplayInput } from '@comps';

import { Http, Message, DateUtils } from '@utils';
import { TableType } from '../interface';

interface Props {
  contractId: number;
  isCreateContract: boolean;
  contractAmount: number;
  formData: JSONObject;
  tableType: number;
  rowId: number;
  isAddRow: boolean;
  addTempData: (data: JSONObject) => void;
  editTempData: (data: JSONObject) => void;
  refreshTable: () => void;
  onClose: () => void;
}

const { Item } = Form;
const { Option } = Select;

const RowAddOrUpdate: FC<Props> = memo(({ 
  contractId,
  isCreateContract,
  contractAmount,
  formData,
  tableType,
  rowId,
  isAddRow,
  addTempData,
  editTempData,
  refreshTable,
  onClose
}) => {
 
  const [form] = Form.useForm();
 
  const [addOrUpdateLoading, setAddOrUpdateLoading] = useState(false);

  useEffect(() => {
    judgeAddOrEditRow();
  }, []);

  const judgeAddOrEditRow = () => {
    if (!isAddRow) {
      // 日期字符串转 moment
      const { planDate } = formData;

      if (planDate){
        formData.planDate = moment(planDate);
      };

      form.setFieldsValue(formData);
    }
  };

  const handleRateChange = (rate: any) => {
    rate = rate ?? 0;
    const amount = contractAmount * rate; 

    form.setFieldsValue({
      amount
    })
  };

  const ItemsRender: JSONObject = {
    [TableType.DETAIL]: <>
      <Row>
        <Col span={12}>
          <Item 
            label="商品描述" 
            name="goodsName" 
            rules={[{ required: true }]}
          >
            <Input />
          </Item>
        </Col>
        <Col span={12}>
          <Item 
            label="物料编码" 
            name="goodsNo" 
            rules={[{ required: true }]}
          >
            <Input />
          </Item>
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <Item 
            label="大类" 
            name="largeCategory" 
            rules={[{ required: true }]}
          >
            <Input />
          </Item>
        </Col>
        <Col span={12}>
          <Item 
            label="中类" 
            name="mediumCategory" 
            rules={[{ required: true }]}
          >
            <Input />
          </Item>
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <Item label="小类" name="smallCategory">
            <Input />
          </Item>
        </Col>
        <Col span={12}>
          <Item label="规则型号" name="spec">
            <Input />
          </Item>
        </Col>
        </Row>
      <Row>
        <Col span={12}>
          <Item 
            label="单位" 
            name="unit" 
            rules={[{ required: true }]}
          >
            <Input />
          </Item>
        </Col>
        <Col span={12}>
          <Item 
            label="数量" 
            name="quantity" 
            rules={[{ required: true }]}
          >
            <InputNumber style={{ width: '100%' }} />
          </Item>
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <Item 
            label="含税单价" 
            name="withTaxPrice" 
            rules={[{ required: true }]}
          >
            <InputNumber style={{ width: '100%' }} />
          </Item>
        </Col>
        <Col span={12}>
          <Item 
            label="含税金额" 
            name="withTaxAmount" 
            rules={[{ required: true }]}
          >
            <InputNumber style={{ width: '100%' }} />
          </Item>
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <Item label="税率" name="taxRate">
            <InputNumber style={{ width: '100%' }} />
          </Item>
        </Col>
      </Row>
    </>,
    [TableType.PLAN]: <>
      <Row>
        <Col span={12}>
          <Item 
            label="款项" 
            name="type" 
            rules={[{ required: true }]}
          >
            <Select>
              <Option value="AP">预付款</Option>
              <Option value="PP">进度款</Option>
              <Option value="SP">结算款</Option>
              <Option value="QP">质保金</Option>
            </Select>
          </Item>
        </Col>
        <Col span={12}>
          <Item 
            label="计划收款日期" 
            name="planDate" 
            rules={[{ required: true }]}
          >
            <DatePicker 
              format="YYYY-MM-DD" 
              style={{ width: '100%' }}
              inputReadOnly
            />
          </Item>
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <Item 
            label="收款比例（0.01 - 1）" 
            name="rate" 
            rules={[{ required: true }]}
          >
            <InputNumber
              style={{ width: '100%' }} 
              min={0.01}
              max={1}
              onChange={handleRateChange}
            />
          </Item>
        </Col>
        <Col span={12}>
          <Item 
            label="应收金额" 
            name="amount" 
            rules={[{ required: true }]}
          >
            <DisplayInput />
          </Item>
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <Item label="备注" name="comment">
            <Input />
          </Item>
        </Col>
        <Col span={12}>
        </Col>
      </Row>
    </>,
    [TableType.ANNEX]: <>
      <Row>
        <Col span={12}>
          <Item label="附件名称" name="fileName">
            <Input />
          </Item>
        </Col>
        <Col span={12}>
          <Item label="上传时间" name="uploadTime">
            <Input />
          </Item>
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <Item label="备注" name="remark">
            <Input />
          </Item>
        </Col>
        <Col span={12}>
        </Col>
      </Row>
    </>
  };

  const tableURLObj: JSONObject = {
    [TableType.DETAIL]: '/contracts/addOrUpdateContractLine',
    [TableType.PLAN]: '/contracts/addOrUpdateContractArPlan',
    [TableType.ANNEX]: ''
  };

  const handlePlanDateFormat = (formData: JSONObject) => {
    const { planDate } = formData;

    if (planDate) {
      formData.planDate = DateUtils.momentToStr(planDate, 'YYYY-MM-DD');
    };
  };

  const handleSave = () => {
    const formData = form.getFieldsValue();
    handlePlanDateFormat(formData);
   
    if (isCreateContract) { // 创建合同
      isAddRow 
        ? addTempData(formData)
        : editTempData(formData)

      onClose();
    } else { // 更新合同

      if (!isAddRow) {
        formData.id = rowId;
      }

      formData.contractId = contractId;

      setAddOrUpdateLoading(true);

      Http.post(tableURLObj[tableType], formData)
        .then(() => {
          refreshTable();
          Message.success(isAddRow ? '新增成功' : '修改成功');
        })
        .finally(() => {
          setAddOrUpdateLoading(false);
          onClose();
        })
    }
  };

  return (
    <Card title={isAddRow ? '新增' : '修改'}>
      <Form 
        form={form} 
        initialValues={{ amount: 0 }}
        onFinish={handleSave} 
        labelCol={{ span: 9 }} 
        wrapperCol={{ span: 15 }} 
        style={{ marginTop: 20 }}
      >
       {ItemsRender[tableType]}
        <Row justify="center" style={{ marginTop: 20 }}>
          <Col>
            <Space>
              <Button 
                type="primary" 
                loading={addOrUpdateLoading} 
                htmlType="submit"
              >
                确定
              </Button>
              <Button 
                onClick={onClose}
              >
                取消
              </Button>
            </Space>
          </Col>
        </Row>
      </Form>
    </Card>
  );
});

export default RowAddOrUpdate;
