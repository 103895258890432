import React, { FC, useEffect, useRef, useState } from "react";
import { Card, Lookup, Page, Table, LinkText, Download } from "@comps";
import { Form, DatePicker, Input, Space, Select, Tabs, Modal, message } from "antd"
import { ColumnProps } from '@comps/types';
import { Context, Http, Message } from "@utils";
const { Item } = Form
const { RangePicker } = DatePicker
const { Option } = Select
const { TabPane } = Tabs;



let columns: ColumnProps[] = [
  {
    title: "标题", key: "title", width: 620, render: (id: number, row: JSONObject) => {
      return (
        <LinkText onClick={() => { Context.route(`/taxKnowledge/knowledgeContent/${row.id}`) }} label={row.title} />
      )
    }
  },
  { title: "文号", key: "proof", width: 120 },
  { title: "发文日期", key: "releaseDate", width: 120 },
  { title: "所属税种", key: "taxTypeName", width: 120 },
  { title: "优惠类型", key: "discountsTypeName", width: 120 },
  { title: "发文机构", key: "publishOrgName", width: 120, ellipsis: true, },
  {
    title: "有效性", key: "validity", width: 120,
    render: (value) => {
      switch (value) {
        case 1:
          return "全文有效"
        case 2:
          return "条款失效"
        case 3:
          return "全文废止"
        default:
          break;
      }
    }
  },
  { title: "解析人", key: "analysisBy", width: 120 },
  { title: "阅读量", key: "reading", width: 120 },
  {
    key: 'id', title: '下载', width: 120,
    render: (id: number, row: JSONObject) => {
      return (

        <Space size={'middle'}>
          <Download
            label='正文'
            url={`/knowledge/downloadContent/${id}`}
            type='link'
          />
          <i style={{ color: '#4e97df' }}>|</i>
          <Download
            label='附件'
            url={`/knowledge/downloadZip/${id}`}
            type='link'
          />
        </Space>
      )
    }
  },

]


const KnowledgeConsult: FC = (props: any) => {
  const [period, setPeriod] = useState<object>();
  let [table, setTable] = useState<Table>();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [activeTab, setActiveTab] = useState<string>("1");
  const [borrowText, setBorrowText] = useState<string>("");
  const activeId: any = useRef()
  const [change, setChange] = useState(false)
  const topColumn = ({
    url: '/top', params: {
    }, columns: [{
      title: "标题", key: "title", width: 620, render: (id: number, row: JSONObject) => {
        return (
          <LinkText onClick={() => { Context.route(`/taxKnowledge/knowledgeContent/${row.knowledgeId}`) }} label={row.title} />
        )
      }
    }, columns[1], columns[2], columns[3], columns[4], columns[5], columns[6], columns[7], columns[8],
    {
      key: 'id', title: '下载', width: 120,
      render: (id: number, row: JSONObject) => {
        return (
          <Space size={'middle'}>
            <Download
              label='正文'
              url={`/knowledge/downloadContent/${row.knowledgeId}`}
              type='link'
            />
            <i style={{ color: '#4e97df' }}>|</i>
            <Download
              label='附件'
              url={`/knowledge/downloadZip/${row.knowledgeId}`}
              type='link'
            />
          </Space>
        )
      }
    }, {
      key: 'id', title: '操作', width: 120,
      render: (id: number, row: JSONObject) => {
        return (
          <Space size={'middle'}>
            <LinkText label='上移' onClick={() => { moveKnowledge(id, 1) }} />
            <i style={{ color: '#4e97df' }}>|</i>
            <LinkText label='下移' onClick={() => { moveKnowledge(id, 2) }} />
          </Space>
        )
      }
    },]
  })
  const [tableData, setTableData] = useState<any>(
    {
      url: '/knowledge/consult', params: {
        fileType: 1,
      }, columns: [columns[0], columns[1], columns[2], columns[3], columns[4], columns[5], columns[6], columns[7], columns[8], columns[9]]
    }

  );

  const onTableRef = (tableRef: Table) => {
    table = tableRef
    setTable(tableRef);
  }
  const borrowRef = useRef()

  useEffect(() => {
    table?.handleColumns()
    table?.fetchTableData()
  }, [tableData])

  useEffect(() => {
    table?.refresh()
    const { state } = props.location
    if (state && state.id !== activeId.current) {
      const { approveType } = state
      if (approveType === 2) {
        setActiveTab("3")
      }
      if (approveType === 4) {
        setActiveTab("1")
      }
      activeId.current = state.id
    }
  }, [props.location])


  // tab页切换
  const onChange = (key: string) => {
    if (key === '1') {
      setTableData({
        url: '/knowledge/consult', params: {
          fileType: Number(key)
        }, columns: [{
          title: "标题", key: "title", width: 620, render: (id: number, row: JSONObject) => {
            return (
              <LinkText onClick={() => { Context.route(`/taxKnowledge/knowledgeContent/${row.id}`) }} label={row.title} />
            )
          }
        }, columns[1], columns[2], columns[3], columns[4], columns[5], columns[6], columns[7], columns[8], columns[9]]
      })
    } else if (key === '2') {
      setTableData({
        url: '/knowledge/consult', params: {
          fileType: Number(key)
        }, columns: [{
          title: "标题", key: "title", width: 412, render: (id: number, row: JSONObject) => {
            return (
              <LinkText onClick={() => { Context.route(`/taxKnowledge/knowledgeContent/${row.id}`) }} label={row.title} />
            )
          }
        }, columns[2], columns[3], columns[4], columns[5], columns[7], columns[9]]
      })
    } else if (key === '3') {
      setTableData({
        url: '/knowledge/consult', params: {
          fileType: Number(key)
        }, columns: [{
          title: "标题", key: "title", width: 402, render: (id: number, row: JSONObject) => {
            return (
              row.borrowStatus === 1 ? <span >{row.title}</span>
                : row.borrowStatus === 2 ? <span >{row.title}</span>
                  : <LinkText onClick={() => { Context.route(`/taxKnowledge/knowledgeContent/${row.id}`) }} label={row.title} />

            )
          }
        }, columns[1], columns[2], columns[3], columns[5], {
          key: 'id', title: '操作', width: 100,
          render: (id: number, row: JSONObject) => {
            return (
              row.borrowStatus === 1 ? <LinkText label='借阅' onClick={() => { borrowKnowledge(id) }} />
                : row.borrowStatus === 2 ? <span style={{ color: '#cccccc' }}>借阅中</span>
                  : <span style={{ color: '#cccccc' }}>已借阅</span>

            )
          }
        }, {
          key: 'id', title: '下载', width: 120,
          render: (id: number, row: JSONObject) => {
            return (
              row.borrowStatus === 3 ?
                <Space size={'middle'}>
                  <Download
                    label='正文'
                    url={`/knowledge/downloadContent/${id}`}
                    type='link'
                  />
                  <i style={{ color: '#4e97df' }}>|</i>
                  <Download
                    label='附件'
                    url={`/knowledge/downloadZip/${id}`}
                    type='link'
                  />
                </Space>
                : <></>
            )
          }
        },]
      })
    } else if (key === '4') {
      setTableData({
        url: '/knowledge/consult', params: {
          fileType: Number(key)
        }, columns: [{
          title: "标题", key: "title", width: 275, render: (id: number, row: JSONObject) => {
            return (
              <LinkText onClick={() => { Context.route(`/taxKnowledge/knowledgeContent/${row.id}`) }} label={row.title} />
            )
          }
        }, columns[2], columns[3], columns[5], columns[9]]
      })
    } else {
      setTableData(topColumn)
    }
    setActiveTab(key)
  };

  // 上下移功能
  const moveKnowledge = async (id: number, type: number) => {
    await Http.post(`/top/exchange/${id}?type=${type}`)
    table?.refresh()
    Message.ok()
  }

  // 借阅
  const borrowKnowledge = async (id: any) => {
    borrowRef.current = id
    setIsModalVisible(true)
  }

  const sumbitBorrow = async () => {
    await Http.post(`/consult/borrow/${borrowRef.current}?remark=${borrowText}`,);
    message.success('提交成功')
    closeModal()
  }


  const borrowchange = (e: any) => {
    setBorrowText(e.currentTarget.value)
  }
  const closeModal = () => {
    setBorrowText('')
    setIsModalVisible(false)
  }


  const periodChange = (date: any, dateString: any) => {
    setPeriod({ releaseDateStart: dateString[0], releaseDateEnd: dateString[1] })

  }

  const resetEmptyFileds = (): JSONObject => {
    setPeriod(["", ""])
    return {
      releaseDateStart: "",
      releaseDateEnd: ""
    }
  }

  return (
    <Page>
      <Card title="知识查阅">
        <Tabs onChange={onChange} activeKey={activeTab}>
          <TabPane tab="置顶消息" key="0">
          </TabPane>
          <TabPane tab="政策法规" key="1">
          </TabPane>
          <TabPane tab="政策解读" key="2">
          </TabPane>
          <TabPane tab="稽查案例" key="3">
          </TabPane>
          <TabPane tab="稽查应对措施" key="4">
          </TabPane>
        </Tabs>
        <Table
          url={tableData.url}
          columns={tableData.columns}
          isExpandAllItem={true}
          onTableRef={onTableRef}
          scroll={{ y: window.innerHeight - 300 }}
          formAdditionalParameters={{ ...period, ...tableData.params }}
          resetEmptyFileds={resetEmptyFileds}

        >
          <Item name="taxType">
            <Lookup lookupType="TAX_TYPE" placeholder="按税种查询"></Lookup>
          </Item>
          <Item name="period">
            <RangePicker format="YYYY-MM-DD" placeholder={["发文日期起", "发文日期止"]} onChange={periodChange} inputReadOnly></RangePicker>
          </Item>
          <Item name="publishOrg">
            <Lookup lookupType="FWJG" placeholder="按发文机构查询"></Lookup>
          </Item>
          <Item name="validity">
            <Select placeholder="有效性" allowClear>
              <Option value={1}>全文有效</Option>
              <Option value={2}>条款失效</Option>
              <Option value={3}>全文废止</Option>
            </Select>
          </Item>
          <Item name="title">
            <Input placeholder="请输入标题关键字查询" />
          </Item>
          <Item name="proof">
            <Input placeholder="输入文号查询" />
          </Item>
          <Item name="discountsType">
            <Lookup lookupType="YHLX" placeholder="优惠类型"></Lookup>
          </Item>
          <Item>
          </Item>
        </Table>
        <Modal title="借阅申请" visible={isModalVisible} onOk={sumbitBorrow} onCancel={closeModal}>
          <Input value={borrowText} onChange={borrowchange} placeholder="请输入借阅理由"></Input>
        </Modal>
      </Card>

    </Page>

  )

}

export default KnowledgeConsult