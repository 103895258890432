import { DateUtils } from "@utils";
import {
  Card as AdCard,
  Cascader,
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Select,
} from "antd";
import * as React from "react";
import { useState } from "react";

interface Props {
  form: any;
  // ref: any;
  //   onClose: Function;
}

const RealEstate: React.FC<Props> = (props) => {
  const { form } = props;
  const [loading, setLoading] = useState(false);
  const cityData = require("./province-city-china.json");
  const [levelData, setLevalData] = useState(cityData);
  const { RangePicker } = DatePicker;
  const Option = Select.Option;

  return (
    <>
      <AdCard
        title="编辑不动产信息"
        loading={loading}
        style={{ marginBottom: "0" }}
      >
        <Form
          form={form}
          initialValues={{
            businessInfo: {
              BDC_QZ: [DateUtils.monthFirstDay(), DateUtils.monthLastDay()],
            },
          }}
        >
          <Row gutter={16}>
            <Col span={8}>
              <Form.Item
                label="不动产地址"
                // name="BDC_SF"
                name={["businessInfo", "BDC_SF"]}
                rules={[{ required: true, message: "请输入不动产地址" }]}
              >
                <Cascader options={levelData} placeholder="请选择" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="详细地址"
                // name="BDC_XXDZ"
                name={["businessInfo", "BDC_XXDZ"]}
                rules={[{ required: true, message: "请输入详细地址" }]}
              >
                <Input placeholder="请输入详细地址" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="租赁起止"
                // name="BDC_QZ"
                name={["businessInfo", "BDC_QZ"]}
                rules={[{ required: true, message: "请输入租赁起止" }]}
              >
                <RangePicker
                  placeholder={["开票日期起", "开票日期止"]}
                  format="YYYY-MM-DD"
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="跨地（市）标志"
                // name="BDC_KSBZ"
                name={["businessInfo", "BDC_KSBZ"]}
                rules={[{ required: true, message: "请输入跨地（市）标志" }]}
              >
                <Select placeholder="请选择">
                  <Option value="Y">是</Option>
                  <Option value="N">否</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="产权证书"
                // name="BDC_COJH"
                name={["businessInfo", "BDC_CQJH"]}
                rules={[{ required: true, message: "请输入产权证书" }]}
              >
                <Input placeholder="请输入证书编号，若没有证书填写无" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="面积单位"
                // name="BDC_PIDW"
                name={["businessInfo", "BDC_PJDW"]}
                rules={[{ required: true, message: "请输入面积单位" }]}
              >
                <Select placeholder="请选择面积单位">
                  <Option value="平方千米">平方千米</Option>
                  <Option value="平方米">平方米</Option>
                  <Option value="公顷">公顷</Option>
                  <Option value="亩">亩</Option>
                  <Option value="h㎡">h㎡</Option>
                  <Option value="k㎡">k㎡</Option>
                  <Option value="㎡">㎡</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </AdCard>
    </>
  );
};

export default RealEstate;
