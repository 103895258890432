import React, { FC, useState, useEffect } from 'react';
import { Space } from 'antd';

import { Page, Card, Button, Table, Modal, PermissionButton, Download } from '@comps';
import CancelOrderMD  from '../cancelOrder.md';
import DeleteInvoicesMD from '../deleteInvoices.md';
import AddInvoicesMD from './addInvoices.md';

import { ColumnProps } from '@comps/types';
import { Context, Http, Env, Message } from '@utils';
import { MailingStatus } from '../interface';

import './index.css';

const MailDetail: FC = (props: any) => {
  const orderId: string = Context.getPathVar(props, 'orderId');

  const [pdfURL, setPdfURL] = useState<string>('');
  const [isShowPrintBTN, setIsShowPrintBTN] = useState<boolean>(false);

  const [isOrdered, setIsOrdered] = useState<boolean>(false);

  const [modal, modalHolder] = Modal.useModal();

  const [orderInfo, setOrderInfo] = useState<JSONObject>({});
  const [logisticsInfo, setLogisticsInfo] = useState<JSONObject[]>([]);

  let isRequery: boolean = false;
  const [requeryLoading, setRequeryLoading] = useState<boolean>(false);

  const [table, setTable] = useState<Table>();
  const onTableRef = (tableRef: Table) => {
    setTable(tableRef);
  }
  
  const columns: ColumnProps[] = [
    { key: 'invoiceTypeDesc', title: '发票类型', width: 200, fixed: 'left' },
    { key: 'invoiceCode', title: '发票代码', width: 200 },
    { key: 'invoiceNo', title: '发票号码', width: 160 },
    { key: 'invoiceDate', title: '开票日期', width: 120 },
    { key: 'buyerName', title: '购买方', width: 220 },
    { key: 'invoiceAmount', title: '不含税金额' , width: 200, format: 'amount' },
    { key: 'taxAmount', title: '税额', width: 160, format: 'amount' },
    { key: 'totalAmount', title: '价税合计', width: 160, format: 'amount' },
    { key: 'sellerName', title: '销售方', width: 220, align: 'center' }
  ]

  const getOrderInfo = (orderId: string) => {
    Http.get(`/tax/mailingManagement/${orderId}/detail`, {})
      .then(res => {
        setOrderInfo(res);

        if (res.print) {
          setPdfURL(res.pathPdfUrl);
          setIsShowPrintBTN(true);
        } else {
          setIsShowPrintBTN(false);
        }

        res.mailingStatus === MailingStatus.ORDERER
          ? setIsOrdered(true)
          : setIsOrdered(false);
      })
  }

  const getLogisticsInfo = (orderId: string) => {
    Http.get(`/tax/mailingManagement/${orderId}/route`, {})
      .then(res => {
        setLogisticsInfo(res);
        if (isRequery) {
          Message.success('已更新最新物流信息');
        }
      })
      .finally(() => {
        isRequery = false;
        setRequeryLoading(false);
      })
  }

  const handleCancelOrder = (orderId: string) => {
    modal.open({
      width: '300px',
      content(_1, reject) {
        return (<CancelOrderMD orderId={ orderId }  onClose={reject} />)
      }
    });
  }

  const handleRequery = () => {
    isRequery = true;
    setRequeryLoading(true);
    getLogisticsInfo(orderId);
  }

  const showDeleteInvoiceMD = (invoiceIds: number[]) => {
    const tableRefresh = () => {
      table?.refresh();
    }

    modal.open({
      width: '300px',
      content(_1, reject) {
        return (<DeleteInvoicesMD orderId={orderId} invoiceIds={invoiceIds} tableRefresh={tableRefresh} onClose={reject} />)
      }
    });
  }

  const handleDeleteInvoice = () => {
    const invoiceIds: any[] = table?.getSelectedRowKeys() || [];
    
    if (invoiceIds?.length === 0) {
      Message.warn('请选择至少一条记录');
      return;
    }

    showDeleteInvoiceMD(invoiceIds);
  }

  const showAddInvoicesMD = () => {
    const refreshTable = () => {
      table?.refresh();
    }
    modal.open({
      width: '1200px',
      content(_1, reject) {
        return (<AddInvoicesMD orderId={orderId} refreshTable={refreshTable} onClose={reject} />)
      }
    });
  }

  useEffect(() => {
    (async () => {
      await getLogisticsInfo(orderId);
      getOrderInfo(orderId);
    })()
  }, [])

  return (
    <Page backable={true}>
      {modalHolder}

      <Card title="订单详情" actions={(
        <Space className="card-space">
          {
            isShowPrintBTN
            ? <Download url={pdfURL} label='打印面单' type="button" preview={true} />  
            : null       
          }
          {
            isOrdered
            ? <Button onClick={() => {handleCancelOrder(orderId)}}>取消下单</Button>
            : null
          }
        </Space>
      )}>
        <div className="order-info">
          <ul className="base-info">
            <li>
              <div>快递单号</div>
              <span>{orderInfo.trackingNumber}</span>
            </li>
            <li>
              <div>快递公司</div>
              <span>{orderInfo.courierCompany}</span>
            </li>
            <li>
              <div>邮寄状态</div>
              <span style={{ fontWeight: 800, color: '#000' }}>{orderInfo.mailingStatusDesc}</span>
            </li>
            <li>
              <div>订单号</div>
              <span>{orderInfo.orderId}</span>
            </li>
            <li></li>
          </ul>
          <ul className="sender-info">
              <li>
                <div>寄件人</div>
                <span>{orderInfo.sender}</span>
              </li>
              <li>
                <div>寄件人联系电话</div>
                <span>{orderInfo.senderTel}</span>
              </li>
              <li>
                <div>寄件人详细地址</div>
                <span>{orderInfo.senderAddress}</span>
              </li>
              <li></li>
              <li></li>
            </ul>
          <ul className="addressee-info">
              <li>
                <div>收件人</div>
                <span>{orderInfo.recipient}</span>
              </li>
              <li>
                <div>收件人类别</div>
                <span>{orderInfo.recipientTypeDesc}</span>
              </li>
              <li>
                <div>收件公司名称</div>
                <span>{orderInfo.receivingCompany}</span>
              </li>
              <li>
                <div>收件人联系电话</div>
                <span>{orderInfo.recipientTel}</span>
              </li>
              <li>
                <div>收件人详细地址</div>
                <span>{orderInfo.recipientAddress}</span>
              </li>
            </ul>       
        </div>
      </Card>

      <Card title="邮寄发票" actions={(
        <Space className="card-space">
          {
            isOrdered 
            ? <Button type="primary" onClick={showAddInvoicesMD}>新增发票</Button>
            : null
          }
          {
            isOrdered
            ? <Button onClick={handleDeleteInvoice}>删除发票</Button>
            : null
          }
        </Space>
      )}>
         <Table url={'/tax/mailingManagement/' + orderId + '/invoices'}
                selectedRowKeys={[]}
                pagination={{hideOnSinglePage:false}}
                columns={columns}
                onTableRef={onTableRef}> 
          </Table>
      </Card>

      <Card title="物流详情" actions={(
        <Space className="card-space">
          <Button type="primary"
                  onClick={handleRequery}
                  loading={requeryLoading}>
                  重新查询</Button>
          </Space>
        )}>
        <div className="logistics-info">
          <ul className="logistics-info-header">
            <li>物流时间</li>
            <li>物流情况</li>
          </ul>
          {
            logisticsInfo.map((item: JSONObject) => {
              return (
                <ul className="logistics-info-row" key={item.acceptTime}>
                  <li>{item.acceptTime}</li>
                  <li>{item.remark}</li>
                </ul>
              )
            })
          }
        </div>
      </Card>
    </Page>
  )
}

export default MailDetail