import React, { FC, useState } from 'react';
import { Form, Row, Col, Select, Input, Space, Button } from 'antd';

import { Card } from '@comps';

import { Http, Message } from '@utils';

const { Item } = Form;
const { Option } = Select;
const { TextArea } = Input;

interface IProps {
  baseFormId: number,
  setApplyFormData: (formData: JSONObject) => void,
  handleBTNsShow: (formData: JSONObject) => void,
  onClose: () => void
}

const ApproveRes: FC<IProps> = ({
  baseFormId,
  setApplyFormData,
  handleBTNsShow,
  onClose
}) => {
  const [form] = Form.useForm();

  const [isShowApproveRemark, setIsShowApproveRemark] = useState(false);
  const [approveLoading, setApproveLoading] = useState(false);

  const handleApproveResChange = (val: any) => {
    setIsShowApproveRemark(val === 'REJECTED');
  };

  const handleApprove = () => {
    setApproveLoading(true);

    const formData = form.getFieldsValue();

    Http.post('/request/approve', { requestId: baseFormId, ...formData })
      .then((data) => {
        handleBTNsShow(data);
        setApplyFormData(data);
        onClose();
        Message.success('审批成功');
      })
      .finally(() => {
        setApproveLoading(false);
      });
  };

  return (
    <Card title="审批结果">
      <Form form={form} onFinish={handleApprove}>
        <Row style={{ marginTop: 20 }}>
          <Col span={24}>
            <Item
              label="审批结果"
              name="statusCode"
              rules={[{ required: true }]}
            >
              <Select
                onChange={handleApproveResChange}
              >
                <Option value="APPROVED">审批通过</Option>
                <Option value="REJECTED">审批驳回</Option>
              </Select>
            </Item>
          </Col>
          <Col span={24} style={{ height: 70 }}>
            {
              isShowApproveRemark
                ? <Item name="remark">
                    <TextArea placeholder="输入驳回原因" />
                  </Item>
                : null
            }
          </Col>
          <Col span={24} style={{ textAlign: 'center' }}>
            <Space size="large">
              <Button
                type="primary"
                loading={approveLoading}
                htmlType="submit"
              >
                确定
              </Button>
              <Button onClick={onClose}>取消</Button>
            </Space>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};

export default ApproveRes;
