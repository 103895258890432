export interface TableRow {
  id: number,
  requestNo: string,
  statusCode: number,
  statusCodeDesc: string,
  needRedNoticeNo: boolean,
  allowRetry?: boolean,
  submitTime: string,
  submitterName: string,
  approverName: string,
  approveTime: string,
  approveRemarks: string
}

export enum MailingStatus {
  /**
   * 已下单
   */
  ORDERER = 1,
  /**
   * 邮寄中
   */
  MAILING = 2,
  /**
   * 已签收
   */
  SIGNED = 3,
  /**
   * 取消下单
   */
  CANCEL_ORDER = 4,
  /**
   * 退回
   */
  RETURN = 5
}


