import React, { useState } from 'react';
import { Card } from '@comps';
import { Space, Button } from 'antd';
import { Http, Message } from '@utils';

export interface Props {
  orderId: string,
  onClose: () => void
}

const Cancel: React.FC<Props> = ({ orderId, onClose }: Props) => {
  const [loading, setLoading] = useState<boolean>(false)

  const handleCancelOrder = async () => {
    setLoading(true)

    Http.put('/tax/mailingManagement/cancelOrder', { orderId })
      .then(res => {
        Message.success('取消订单成功')
      })
      .finally(() => {
        setLoading(false)
        onClose()
      })
  }

  return (
    <Card title="取消订单">
      <h3 style={{ marginTop: 24, marginBottom: 24 }}>是否确认取消订单 ?</h3>
      <Space size="large">
        <Button loading={loading} type="primary" onClick={handleCancelOrder}>确定</Button>
        <Button onClick={onClose}>取消</Button>
      </Space>
    </Card >)
}

export default Cancel
