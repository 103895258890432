import { Lookup } from "@comps";
import { Context } from "@utils";
import { Card as AdCard, Button, Col, Form, Row, Select } from "antd";
import * as React from "react";
import { useState } from "react";
import { useParams } from "react-router";

interface Props {
  // ref: any;
  onClose: Function;
  InitRef: Function;
  form: any;
}

const IssueInfo: React.FC<Props> = ({ onClose, form, InitRef }) => {
  const { Option } = Select;
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const { requestNo } = useParams<any>();
  const handleSubmit = () => {
    form.validateFields(["invoiceType"]).then((res: any) => {
      console.log(res, "==========");
      const { invoiceType, businessType } = form.getFieldsValue();
      console.log(invoiceType, businessType);
      const route = `/tax/blueInvoice/invoiceMakeout/${invoiceType}/${
        businessType ? businessType : "null"
      }/${requestNo ? requestNo : ""}`;
      form?.resetFields();
      form?.setFieldsValue({ lines: [{}] });
      Context.handleAdditionalPaneReplace("手工录入", route, route);
      onClose();
      InitRef();
    });
  };
  return (
    <>
      <AdCard
        title="选择开具信息"
        loading={loading}
        style={{ marginBottom: "0" }}
      >
        <Form form={form}>
          <Row gutter={16}>
            {/* <Col span={4}>
              <Form.Item label="电子发票/纸质发票">
                <Lookup lookupType="" placeholder="请选择" />
              </Form.Item>
            </Col> */}
            <Col span={12}>
              <Form.Item
                label="选择票类"
                name="invoiceType"
                rules={[{ required: true, message: "请选择票类" }]}
              >
                <Select placeholder="请选择">
                  <Option value="31">数电票（增值税专用发票）</Option>
                  <Option value="32">数电票（增值税普通发票）</Option>
                  <Option value="85">数电纸质票（增值税专用发票）</Option>
                  <Option value="86">数电纸质票（增值税普通发票）</Option>
                </Select>
                {/* <Lookup lookupType="TAX_INVOICE_TYPE" placeholder="请选择" /> */}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="特定业务" name="businessType">
                <Lookup
                  lookupType="INVOICE_BUSINESS_TYPE"
                  placeholder="请选择(非必选项)"
                />
              </Form.Item>
            </Col>
            {/* <Col span={4}>
              <Form.Item label="差额征税">
                <Lookup lookupType="" placeholder="请选择(非必选项)" />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item label="减按征税">
                <Lookup lookupType="" placeholder="请选择(非必选项)" />
              </Form.Item>
            </Col> */}
          </Row>
        </Form>
      </AdCard>
      <div
        style={{ textAlign: "center", marginTop: "16px", marginBottom: "16px" }}
      >
        <Button onClick={() => onClose()} style={{ marginRight: "16px" }}>
          关闭
        </Button>
        <Button type="primary" onClick={() => handleSubmit()}>
          确定
        </Button>
      </div>
    </>
  );
};

export default IssueInfo;
