import React from 'react';
import { Select as AdSelect } from 'antd';
import { SelectProps, SelectValue } from 'antd/es/select';
import { OptionProps as RcOptionProps } from 'rc-select/es/Option';
import { OptGroupProps as RcOptGroupProps } from 'rc-select/es/OptGroup';

export type OptionProps = RcOptionProps;
export type OptGroupProps = RcOptGroupProps;

export interface Props<V> extends SelectProps<V> {
  onBlurHolder?: (blur: () => void) => void;
  onFocusHolder?: (focus: () => void) => void;
};

function SelectComp<V extends SelectValue>({
  allowClear = true, 
  showSearch = true, 
  optionFilterProp = 'label',
  ...others
}: Props<V>): React.ReactElement<Props<V>> {
  const ref = React.useRef<AdSelect<V>>(null);

  return (
    <AdSelect<V> 
      ref={ref} 
      {...others} 
      allowClear={allowClear}
      showSearch={showSearch}
      optionFilterProp={optionFilterProp} 
    />
  );
};

type SelectInterface = typeof SelectComp & {
  Option: typeof AdSelect.Option,
  OptGroup: typeof AdSelect.OptGroup,
}

const Select = SelectComp as SelectInterface;
Select.Option = AdSelect.Option;
Select.OptGroup = AdSelect.OptGroup;

export default Select;
