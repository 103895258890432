import React, { FC, Fragment, useEffect, useRef, useState } from "react";
import { Card, Page, Table, LinkText, Download, DeptSelect } from "@comps";
import { Form, DatePicker, Input, Button, Space, Select, Popconfirm, Tabs, Modal, message } from "antd"
import { ColumnProps } from '@comps/types';
import { Api, Http } from "@utils";
import InvoiveActionModal from '../component/invoiveActionModal'
import { UserContext } from "@models";
import { useSelector } from "react-redux";
import TextArea from "antd/lib/input/TextArea";
import moment from "moment";

const { Item } = Form
const { RangePicker } = DatePicker
const { Option } = Select
const { TabPane } = Tabs;



let columns: ColumnProps[] = [

  {
    title: "序号",
    key: "index",
    width: 120,
    render: (text, record, index) => `${index + 1}`
  },
  {
    title: "状态", key: "status", width: 120, render: (value) => {
      switch (value) {
        case 0:
          return "草稿"
        case 1:
          return "已发布"
        default:
          break;
      }
    }
  },
  { title: "发票代码", key: "invoiceCode", width: 120 },
  { title: "发票号码", key: "invoiceNumber", width: 120 },
]

const Invoice: FC = (props: any) => {
  const actionJsx = ({
    key: 'id', title: '操作', width: 250,
    render: (id: number, row: JSONObject) => {
      return (
        <Space size={'middle'}>
          <LinkText label='查看' onClick={() => { select(id) }} />
          <LinkText label='修改' onClick={() => { edit(id, row.status) }} />
          {row.status === 0 && <Fragment>
            <Popconfirm title='您确认要发布吗?' onConfirm={() => { publicInvoice(id) }}>
              <LinkText label='发布' />
            </Popconfirm>
            <Popconfirm title='您确认要删除吗?' onConfirm={() => { deleteInvoice(id) }} >
              <LinkText label='删除' />
            </Popconfirm>
          </Fragment>
          }
          <Download
            label='下载附件'
            url={`/document/attachment/downloadZip/${id}?type=${Number(keyRef.current)}`}
            type='link'
          />
        </Space>
      )
    }
  })

  const userInfo = useSelector<JSONObject, UserContext>((state) => state.ctx);
  const [form] = Form.useForm()
  const [orgId, setOrgId] = useState<any>(userInfo.orgId === null ? '' : userInfo.orgId);
  const [archivedModal, setArchivedModal] = useState(false)
  const [peopleModal, setPeopleModal] = useState(false)
  const [period, setPeriod] = useState<object>();
  const [arachived, setArachived] = useState<any>()
  let [table, setTable] = useState<Table>();
  const [selectedItems, setSelectedItems] = useState<string[]>([]);


  const [tableData, setTableData] = useState<any>(
    {
      url: '/output/document', columns: [columns[0], columns[1], columns[2], columns[3],
      { title: "开票月份", key: "invoicingDate", width: 120 },
      { title: "申报所属期", key: "declareDate", width: 120 },
      { title: "建档时间", key: "establishDate", width: 120 },
      { title: "当前负责人", key: "chargePeople", width: 120 },
      { title: "附件数量", key: "attachmentQuantity", width: 120 },
        actionJsx]


    }
  );
  const [option, setOption] = useState([]);
  const [otherOption, setOtherOption] = useState([]);
  const onTableRef = (tableRef: Table) => {
    table = tableRef
    setTable(tableRef);
  }


  const modalRef: any = useRef()
  const keyRef: any = useRef('1')
  const type = keyRef.current === '1' ? '销项发票' : '进项发票'

  useEffect(() => {
    table?.handleColumns()
    table?.fetchTableData()
  }, [tableData])




  const publicInvoice = async (id: number) => {
    if (keyRef.current === '1') {
      await Http.post(`/output/document/release/${id}`)
    } else {
      await Http.post(`/input/document/release/${id}`)
    }
    message.success('发布成功')
    table?.refresh()
  }

  const deleteInvoice = async (id: number) => {
    if (keyRef.current === '1') {
      await Http.del(`/output/document/${id}`)
    } else {
      await Http.del(`/input/document/${id}`)
    }
    message.success('删除草稿成功')
    table?.refresh()
  }

  const modifyPeople = () => {
    const { currentPeople, selectPeople, remark } = form.getFieldsValue()
    form.validateFields().then(async () => {
      const obj2: any = option.find((item: any) => { return item.value === selectPeople })
      if (currentPeople === obj2.label) return message.error('修改前后的负责人不能是同一个')
      let params = ''
      if (remark === undefined) {
        params = `connectPeople=${obj2.label}&orgId=${orgId}&transferPeople=${currentPeople}`
      } else {
        params = `connectPeople=${obj2.label}&orgId=${orgId}&transferPeople=${currentPeople}&remark=${remark}`
      }
      if (keyRef.current === '1') {
        await Http.post(`/output/document/exchange?${params}`)
      } else {
        await Http.post(`/input/document/exchange?${params}`)
      }
      message.success("修改成功")
      resetPeopleModal()
      table?.refresh()
    })

  }

  const sumbitArchived = async () => {
    const date = moment(arachived).format('yyyyMM')
    if (keyRef.current === '1') {
      await Http.post(`/output/document/archived?date=${date}&orgId=${orgId}`)
    } else {
      await Http.post(`/input/document/archived?date=${date}&orgId=${orgId}`)
    }
    message.success('操作成功')
    resetArchivedModal()
  }

  const openPeopleModal = async () => {
    if (orgId === '') {
      return message.warn("请先选择公司")
    }
    Api.getKv('/sys/users/kv?includeMobile=false')
      .then((res: any) => {
        setOption(res);
      })

    if (keyRef.current === '1') {
      Http.get(`/output/document/getConnectPeople?orgId=${orgId}`)
        .then((res: any) => {
          const option = res.map((item: any, index: any) => {
            return { label: item, value: item }
          })

          setOtherOption(option)
          setPeopleModal(true)
        })
    } else {
      Http.get(`/input/document/getConnectPeople?orgId=${orgId}`).then(res => {
        const option = res.map((item: any, index: any) => {
          return { label: item, value: item }
        })
        setOtherOption(option)
        setPeopleModal(true)
      })

    }
  }



  const periodChange = (date: any, dateString: any) => {
    setPeriod({ establishDateStart: dateString[0], establishDateEnd: dateString[1] })
  }



  const resetEmptyFileds = (): JSONObject => {

    setPeriod({ establishDateStart: "", establishDateEnd: "" })
    return {
      establishDateStart: "",
      establishDateEnd: "",
      orgId: userInfo.orgId || '',
    }
  }

  const openModal = () => {

    if (orgId === '') {
      return message.warn("请先选择公司")
    }
    modalRef?.current.openModal({ visibleVaule: true, title: `新增${type}档案`, action: 0, type: keyRef.current, orgId: orgId })
  }

  const select = (id: number) => {
    modalRef?.current.openModal({ visibleVaule: true, title: `查看${type}档案`, action: 1, type: keyRef.current, id: id })
  }

  const edit = (id: number, status: number) => {
    modalRef?.current.openModal({ visibleVaule: true, title: `修改${type}档案`, action: 2, type: keyRef.current, id: id, status: status })
  }



  const setFormOrgNameValue = (orgNameVal: string) => {
    setOrgId(Number(orgNameVal) || '')
  }

  const archivedDateChange = (date: any, str: any) => {
    setArachived(date)
  }

  const resetArchivedModal = () => {
    setArchivedModal(false)
    setArachived('')
  }

  const resetPeopleModal = () => {
    form.resetFields()
    setPeopleModal(false)
  }


  // tab页切换
  const onChange = (key: string) => {
    if (key === '1') {
      keyRef.current = key
      setTableData({
        url: '/output/document', columns: [columns[0], columns[1], columns[2], columns[3],
        { title: "开票月份", key: "invoicingDate", width: 120 },
        { title: "申报所属期", key: "declareDate", width: 120 },
        { title: "建档时间", key: "establishDate", width: 120 },
        { title: "当前负责人", key: "chargePeople", width: 120 },
        { title: "附件数量", key: "attachmentQuantity", width: 120 },
          actionJsx]
      })
    } else {
      keyRef.current = key
      setTableData({
        url: '/input/document', pcolumns: [columns[0], columns[1], columns[2], columns[3],
        { title: "认证月份", key: "invoicingDate", width: 120 },
        { title: "建档时间", key: "establishDate", width: 120 },
        { title: "当前负责人", key: "chargePeople", width: 120 },
        { title: "报销单号", key: "billNo", width: 120 },
        { title: "凭证号", key: "voucherNumber", width: 120 },
          actionJsx]
      })
    }
  }

  return (
    <Page>
      <Card title="发票档案"
        actions={
          <Space>
            <Button type="primary" onClick={openModal}>新增档案</Button>
            <Button type="primary" onClick={() => {
              if (orgId === '') {
                return message.warn("请先选择公司")
              } setArchivedModal(true)
            }}>发起发票档案归档</Button>
            <Button type="primary" onClick={openPeopleModal}>修改当前负责人</Button>
          </Space>
        }>
        <Tabs defaultActiveKey="0" onChange={onChange} >
          <TabPane tab="销项发票" key="1">
          </TabPane>
          <TabPane tab="进项发票" key="2">
          </TabPane>
        </Tabs>
        <Table
          url={tableData.url}
          columns={tableData.columns}
          isExpandAllItem={true}
          onTableRef={onTableRef}
          formAdditionalParameters={{ ...period, orgId: orgId }}
          resetEmptyFileds={resetEmptyFileds}
          scroll={{ y: window.innerHeight - 300 }}
          dateFormat={{
            invoicingDate: 'YYYYMM',
          }}
        >
          <Item name="orgName">
            <DeptSelect isKeyAsValue={true} setFormOrgNameValue={setFormOrgNameValue} placeholder="公司" />
          </Item>
          <Item name="invoiceCode">
            <Input placeholder="发票代码" />
          </Item>
          <Item name="invoiceNumber">
            <Input placeholder="发票号码" />
          </Item>
          <Item name="status">
            <Select placeholder="发布状态" allowClear>
              <Option value={''}>全部</Option>
              <Option value={0}>草稿</Option>
              <Option value={1}>已发布</Option>
            </Select>
          </Item>
          <Item name="period">
            <RangePicker format="YYYY-MM-DD" placeholder={["建档时间起", "建档时间止"]} onChange={periodChange} inputReadOnly></RangePicker>
          </Item>
          <Item name="invoicingDate" >
            <DatePicker picker="month" placeholder="开票月份" format="YYYY-MM" style={{ width: '100%' }} />
          </Item>
        </Table>
      </Card>
      <InvoiveActionModal ref={modalRef} refresh={() => { table?.refresh() }} ></InvoiveActionModal>
      <Modal title="修改负责人" visible={peopleModal} onOk={modifyPeople} onCancel={resetPeopleModal} forceRender  >
        <Form layout='vertical' form={form}   >
          <Form.Item name="currentPeople" label="当前发票负责人："
            rules={[{ required: true, message: "请选择当前发票负责人" }]}>
            <Select options={otherOption} style={{ width: '200px' }}
              showSearch
              optionFilterProp="label"
              allowClear >
            </Select>
          </Form.Item>
          <Form.Item name="selectPeople" label="将选择的当前负责人的档案全部更改为："
            rules={[{ required: true, message: "请选择更改的负责人" }]}>
            <Select style={{ width: '200px' }} value={selectedItems} options={option}
              showSearch
              optionFilterProp="label"
              allowClear
            >
            </Select>
          </Form.Item>
          <Form.Item name="remark" label="备注：">
            <TextArea placeholder="备注" ></TextArea>
          </Form.Item>
        </Form>
      </Modal>

      <Modal title="请选择归档发票的开月日期：" width={'300px'} visible={archivedModal} onCancel={resetArchivedModal} onOk={sumbitArchived} >
        <DatePicker picker="month" format="YYYYMM" onChange={archivedDateChange} value={arachived} />
      </Modal>
    </Page>

  )
}

export default Invoice