import React, { useState, useRef, FC } from 'react';
import { Button, Form, DatePicker, Select, Input, InputNumber, Space } from 'antd';

import { Card, Table } from '@comps';

import { Http, Message } from '@utils';

import { ColumnProps } from '@comps/types';

const { Item } = Form;
const { RangePicker } = DatePicker;
const { Option } = Select;

interface Props {
  baseFormId: number,
  setAllModuleData: (data: JSONObject) => void,
  onClose: () => void
};

const ImportRebateTableMd: FC<Props> = ({
  baseFormId,
  setAllModuleData,
  onClose
}) => {
  const applyPolicyAmountArrRef = useRef<any>([]);

  const [saveLoading, setSaveLoading] = useState(false);

  let [table, setTable] = useState<Table>();
  const onTableRef = (tableRef: Table) => {
    setTable(tableRef);
    table = tableRef;
  };

  const handleApplyPolicyAmountChange = React.useCallback((applyPolicyAmount: any, row: JSONObject) => {
    const isRemainQuantityNegative = row.remainAmount < 0
    const maxRemainQuantity = isRemainQuantityNegative ? (row.remainAmount) * -1 : row.remainAmount;

    if (isRemainQuantityNegative) {
      if (applyPolicyAmount > 0 || applyPolicyAmount < row.remainAmount) {
        Message.warn('“申请核销金额” 不能大于 “未核销金额”')
        return;
      }
    } else {
      if (applyPolicyAmount > maxRemainQuantity) {
        Message.warn('“申请核销金额” 不能大于 “未核销金额”')
        return;
      };
    }

    row.applyPolicyAmount = applyPolicyAmount;
    row.lineId = row.id;

    const applyPolicyAmountArr: JSONObject[] = applyPolicyAmountArrRef.current;
    const currentRowIndex = applyPolicyAmountArr.findIndex(
      (_row: JSONObject) => _row.id === row.id
    )

    if (currentRowIndex === -1) {
      applyPolicyAmountArr.push(row);
    } else {
      applyPolicyAmountArr[currentRowIndex] = row;
    };
  }, []);

  const handleSave = () => {
    const importRowData = applyPolicyAmountArrRef.current;
    const validDataArr: JSONObject[] = [];

    for(let i = 0; i < importRowData.length; i++) {
      const currentApplyPolicyAmount = importRowData[i].applyPolicyAmount;

      if (currentApplyPolicyAmount === 0 || currentApplyPolicyAmount) {
        validDataArr.push(importRowData[i]);
      };
    };

    if (validDataArr.length === 0) {
      Message.warn('请输入申请核销金额');
      return;
    };

    setSaveLoading(true);

    const applyFormId = baseFormId;

    Http.post(`/request/${applyFormId}/importPolicyLine`, validDataArr)
      .then(data => {
        setAllModuleData(data);
        Message.success('操作成功');
        onClose();
      })
      .finally(() => {
        setSaveLoading(false);
      });
  };

  const columns: ColumnProps[] = [
    { key: 'orderNo', title: '单据号', width: 200, fixed: 'left' },
    { key: 'orderDate', title: '业务订单创建日期', width: 180, align: 'center' },
    { key: 'customerName', title: '客户名称', width: 200 },
    { key: 'description', title: '摘要', width: 150 },
    { key: 'withTaxAmount', title: '金额', width: 180, format: 'amount' },
    { key: 'invoicedAmount', title: '已核销金额', width: 180, format: 'amount' },
    { key: 'remainAmount', title: '未核销金额', width: 180, format: 'amount' },
    { key: '_applyPolicyAmount', title: '申请核销金额', width: 180,
      render: (_applyPolicyAmount, row: JSONObject) => {
        const maxRemainQuantity =
          (row.remainAmount < 0)
            ? (row.remainAmount) * -1
            : row.remainAmount;

        return <InputNumber
          style={{ width: 130 }}
          max={maxRemainQuantity}
          onChange={(event) => handleApplyPolicyAmountChange(event, row)}
        />
      }
    },
    { key: 'businessType', title: '业务类型', width: 140,
      render: (businessType: string) => (
        businessType === 'DISCOUNT' ? '折扣单据' : '返利单据'
      )
    },
    { key: 'sourceSystem', title: '来源系统', width: 140 },
    // { key: 'customerCode', title: '客户编号', width: 180 },
  ];

  return (
    <Card
      title="折扣返利单据"
      style={{ paddingRight: 50 }}
      actions={
        <Space>
          <Button
            type="primary"
            loading={saveLoading}
            onClick={handleSave}
          >
            保存
          </Button>
        </Space>
      }
    >
      <Table
        onTableRef={onTableRef}
        url={`/request/${baseFormId}/queryOutputPolicyOrder`}
        columns={columns}
        selectedRowKeys={[]}
      >
        <Item name="orderDateFrom">
          <RangePicker placeholder={['单据日期从', '单据日期至']} inputReadOnly/>
        </Item>
        <Item name="orderNo">
          <Input placeholder="单据号" />
        </Item>
        <Item name="businessType">
          <Select placeholder="业务类型">
            <Option value="DISCOUNT">折扣单据</Option>
            <Option value="RETURN">返利单据</Option>
          </Select>
        </Item>
        <Item />
      </Table>
    </Card>
  );
};

export default ImportRebateTableMd;
