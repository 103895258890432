import React, { useState, FC, memo } from 'react';
import { Space, Button, Upload, Row } from 'antd';

import { Card, Download, Modal } from '@comps';

import { Message, Context, Env } from '@utils';

interface Props {
  onClose: () => void
};

const ImportContractMD: FC<Props> = memo(({ 
  onClose
}) => {
  const [modal, modalHolder] = Modal.useModal();

  const [uploadLoading, setUploadLoading] = useState(false);
 
  return (
    <Card title="导入合同">
      {modalHolder}
      <div style={{ marginTop: 30, marginBottom: 20 }}>
        <Space size="large">
          <Upload 
            accept=".xlsx,.xls"
            action={`${Env.getBaseUrl()}/contracts/ExcelImport`}
            headers={{
              Authorization: Context.getToken()
            }}
            showUploadList={false}
            onChange={async ({ file: { status, response = {} } }) => {
              setUploadLoading(true);
              
              if (!['done', 'error'].includes(status as string)) {
                return;
              }

              const { data, code, msg } = response;          
         
              if (code === '200') { // 导入成功
                Message.success('导入成功');            
              } else if (code === '500') {  // 导入失败           
                await modal.open({
                  width: 420,
                  content(_1, reject) {
                    return (
                      <Card title="导入失败" style={{ marginBottom: '0' }}>
                        <Space direction="vertical" style={{ width: '100%' }}>
                          <Row justify="center" style={{ margin: '20px 0' }}>
                            <span>导入失败，请下载导入失败原因文件，请调整后重新尝试导入</span>
                          </Row>
                          <Row justify="center">
                            <Space size="large">
                              <Download 
                                label="下载文件" 
                                url={`/contracts/errorFile/download?fileId=${data}`} 
                                type="button" 
                              />
                              <Button onClick={() => reject()}>关闭</Button>
                            </Space>
                          </Row>
                        </Space>
                      </Card>
                    );
                  }
                });
              } else { // 其他情况
                Message.error(msg);
              }

              setUploadLoading(false); 
              onClose(); 
            }}
          >
            <Button type="primary" loading={uploadLoading}>选择文件</Button>
          </Upload>
          <Download label="下载导入合同模板" url="/contracts/template/download" />
        </Space>
      </div>
    </Card>
  );
});

export default ImportContractMD;
