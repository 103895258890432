import React, { FC, useState, memo } from 'react';
import { Space, Button, Popconfirm } from 'antd';

import { Card, Table, LinkText } from '@comps';

import { Message, Http } from '@utils';
import { ColumnProps } from '@comps/types';

interface Props {
  rowId: number;
  setAllModuleData: (data: JSONObject) => void;
  onClose: () => void;
}

const CrossCheckRowDetail: FC<Props> = memo(({ 
  rowId,
  setAllModuleData,
  onClose,
}) => {

  let [table, setTable] = useState<Table>();
  const onTableRef = (tableRef: Table) => {
    setTable(tableRef);
    table = tableRef;
  };

  const handleDel = (detailRowId: number) => {
    Http.del(`/request/deleteLinkLine/${detailRowId}`)
      .then(data => {
        // setAllModuleData(data);
        Message.success('删除成功');
        onClose();
      })
  };

  const columns: ColumnProps [] = [
    { key: 'orderNo', title: '单据号', width: 130, fixed: 'left' },
    { key: 'orderTypeDesc', title: '订单类型', width: 130, fixed: 'left' },
    { key: 'goodsName', title: '商品描述', width: 260},
    { key: 'goodsNo', title: '物料编码', width: 180 },
    { key: 'spec', title: '规格型号', width: 180 },  
    { key: 'unit', title: '单位', width: 180 },
    { key: 'orderQuantity', title: '订单总数量', width: 160 },
    { key: 'quantity', title: '数量', width: 160 },
    { key: 'invoicedQuantity', title: '已开数量', width: 160 },
    { key: 'remainQuantity', title: '未开数量', width: 160, align: 'center' },
    { key: 'invoicedAmount', title: '已开票金额', width: 180, align: 'center' },
    { key: 'remainAmount', title: '未开票金额', width: 140 },
    { key: 'withTaxPrice', title: '含税单价', width: 140 },
    { key: 'withTaxAmount', title: '含税金额', width: 140 },
    { key: 'balanceAmount', title: '差额', width: 120 },
    { key: 'conversionRate', title: '税率', width: 40 },
    { key: 'largeCategory', title: '大类', width: 120, align: 'center' },
    { key: 'mediumCategory', title: '中类', width: 120, align: 'center' },
    { key: 'smallCategory', title: '小类', width: 120, align: 'center' },
    { key: 'id', title: '操作', width: 80, align: 'center', fixed: 'right',
      render: (id: number, row: JSONObject) => (
        <Space>
          <Popconfirm
            title="是否确认删除该行 ?"
            onConfirm={() => handleDel(id)}
          >
            <LinkText label="删除" />
          </Popconfirm>
        </Space>
      )
    }
  ];

  return (
    <Card 
      title="查看" 
      style={{ paddingRight: 50 }}
    >
      <Table
        onTableRef={onTableRef}
        url={`/request/${rowId}/queryLinkLine`}
        columns={columns}
        scroll={{ y: window.innerHeight - 230 }}
        pagination={false}
      />
    </Card>
  );
});

export default CrossCheckRowDetail;
