import * as React from 'react';
import { Card, Button } from '@comps';
import { Form, Space, Input } from 'antd';
import { Http, Lang } from '@utils';

export interface Props extends React.Props<HTMLElement>,
  React.BaseHTMLAttributes<HTMLElement> {
  ids: string[],
  onConfirm: () => void,
  onClose: () => void,
};

const LinesMd: React.FC<Props> = ({ ids, onClose, onConfirm }: Props) => {
  const [form] = Form.useForm();

  const handleConfirm = React.useCallback(() => Lang.noError(async () => {
    const formData = form.getFieldsValue();
    await Http.put('/tax/transfers/back', {
      ids,
      remarks: formData.remarks,
    });
    onConfirm();
  }), [form, ids, onConfirm]);

  return (<Card title='您确认要退回吗？' style={{ marginBottom: '0' }}>
    <Form form={form}
      labelCol={{ span: 4 }}
      style={{ padding: '30px 76px 30px 145px' }}>
      <Form.Item label="退回原因"
        name="remarks">
        <Input />
      </Form.Item>
      <Form.Item wrapperCol={{ offset: 4 }}>
        <Space>
          <Button type="primary"
            clickCallback={handleConfirm}>确认退回</Button>
          <Button onClick={() => onClose()}>取消</Button>
        </Space>
      </Form.Item>
    </Form>
  </Card>);
};

export default LinesMd;
