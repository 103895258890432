import React, { FC } from 'react';
import { Space, Row, Col } from 'antd';

import { Card, Button } from '@comps';

interface Props {
  isAllowSubmit: boolean,
  orderNums: string
  handleCanSaveOrSubmit: () => void,
  onClose: () => void
};

const ProgressPayTips: FC<Props> = ({ 
  isAllowSubmit,
  orderNums,
  handleCanSaveOrSubmit,
  onClose
}) => {

  const _handleCanSaveOrSubmit = () => {
    onClose();
    handleCanSaveOrSubmit();
  };

  return (
    <Card title="提示">
      <Row>
        <Col span={24} style={{ textAlign: 'center' }}>
          <h3 style={{ color: '#000' }}>该合同存在预付款项未勾稽</h3>
        </Col>
      </Row>
      <Row>
        <Col span={24} style={{ textAlign: 'center' }}>
          <h3>开票申请单号：{orderNums}</h3>
        </Col>
      </Row>
      <Row style={{ marginTop: 12 }}>
        <Col span={24} style={{ textAlign: 'center' }}> 
          <Space size="large">
            <Button onClick={onClose}>
              确定
            </Button>
            {isAllowSubmit && (
              <Button 
                type="primary"
                onClick={_handleCanSaveOrSubmit}
              >
                继续提交
              </Button>
            )}
          </Space>
        </Col>
      </Row>
    </Card>
  );
};

export default ProgressPayTips;
