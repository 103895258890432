import React, { FC } from "react";
import { Table, Row } from 'antd';

const TableSummaryRow: FC = ({ children }) => {
  return (
    <>
      <Table.Summary.Row style={{ height: 12 }} /> 
      <Table.Summary.Row style={{ height: 30 }}>
        <Row
          style={{
            position: 'absolute',
            bottom: 16,
            left: 0,
            right: 30,
            zIndex: 100, 
            padding: 6,
            backgroundColor: '#fff'
          }}
        >
          {children}
        </Row>
      </Table.Summary.Row>
    </>
  );
};

export default TableSummaryRow;