import React, { useState, useEffect } from 'react';

import { Card, Table } from '@comps';
import { Space, Button, Form, Input } from 'antd';

import { Http, Message } from '@utils';
import { ColumnProps } from '@comps/types';
import { RecipientType } from './interface';

const Item = Form.Item;

export interface Props {
  recipientType: string,
  params: JSONObject,
  setRecipientsInfo: (recipientInfo: JSONObject) => void
  onClose: () => void
}

const SelectRecipients: React.FC<Props> = ({ recipientType, params, setRecipientsInfo, onClose }: Props) => {
  console.log(params)
  const [form] = Form.useForm()
 
  let tempUrl = ''
  for (let key in params) {
    tempUrl += `${key}=${params[key]}&`
  }

  const clientColumns: ColumnProps[] = [
    { key: 'userName', title: '姓名', width: 100 },
    { key: 'phoneNumber', title: '电话', width: 160 },
    { key: 'address', title: '地址', width: 220 }
  ]

  const otherColumns: ColumnProps[] = [
    { key: 'userName', title: '姓名', width: 100 },
    { key: 'roleName', title: '角色名称', width: 100 },
    { key: 'phoneNumber', title: '手机号', width: 160 },
    { key: 'address', title: '邮寄地址', width: 220 }
  ]

  let columns: ColumnProps[] = (recipientType === 'KH') ? clientColumns : otherColumns
     
  const [loading, setLoading] = useState<boolean>(false)

  const [table, setTable] = useState<Table>()

  const onTableRef = (tableRef: Table) => {
    setTable(tableRef)
  }

  const handleSelectRecipients = () => {
    const IDs = table?.getSelectedRowKeys()
    if (IDs?.length === 0) {
      Message.warn('请选择一条记录')
      return
    } else if (IDs?.length === 1) {
      const currentSelectedInfo: JSONObject[] = table?.getSelectedRowData() || []
      console.log('currentSelectedInfo')
      console.log(currentSelectedInfo)
      
      const recipientsInfo = {
        recipient: currentSelectedInfo[0].userName,
        recipientTel: currentSelectedInfo[0].phoneNumber,
        recipientAddress: currentSelectedInfo[0].address,
      }
      
      setRecipientsInfo(recipientsInfo)
      onClose()
    } else {
      Message.warn('请选择一条记录')
    }

  }

  useEffect(() => {
   
  }, [])

  return (
    <Card title="选择收件人">
      {/* <Form>
        <Item>
          <Input placeholder="姓名" style={{ width: 200, marginRight: 20 }} />
          <Button type="primary">查询</Button>
        </Item>
      </Form> */}

      <Table url={'/tax/mailingManagement/findUserInfo?' + tempUrl}
            selectedRowKeys={[]}
            pagination={false}
            columns={columns}
            onTableRef={onTableRef} />

      <Space size="large" style={{ marginTop: 20 }}>
        <Button loading={loading} type="primary" onClick={handleSelectRecipients}>确定</Button>
        <Button onClick={onClose}>取消</Button>
      </Space>
    </Card >)
}

export default SelectRecipients
