import React, { useState } from 'react';

import { Card } from '@comps';
import { Form, Space, Select, Button } from 'antd';

import { Http, Message } from '@utils';

export interface Props {
  periodObj: JSONObject,
  refreshData: () => void,
  onClose: () => void,
}

const Item = Form.Item
const Option = Select.Option

const OffBookkeepingModal: React.FC<Props> = ({ refreshData, periodObj, onClose }: Props) => {
  const [form] = Form.useForm()

  const [offLoading, setOffLoading] = useState<boolean>(false)

  const handleOffBookkeeping = async () => {
    setOffLoading(true)

    const fieldsValues = form.getFieldsValue()
    
    await Http.get('/input/chargeUp/chargeAgainst', {...fieldsValues, ...periodObj})
      .then(() => {
        Message.info('操作成功')
        refreshData()
      })
      .finally(() => {
        setOffLoading(false)
      })
      
    onClose()
  }

  return (
    <Card title='冲销记账'>
      <Form form={form} initialValues={{ reason: '03' }} onFinish={handleOffBookkeeping} labelCol={{ span: 5 }}>
        <Item name='reason' label='冲销原因' rules={[{required: true}]} wrapperCol={{ span: 10, offset: 1 }}>
          <Select>
            <Option value="03">当月</Option>
            <Option value="04">跨月</Option>
          </Select>
        </Item>     
        <Item wrapperCol={{ offset: 6 }}>
          <h4>是否确认冲销记账 ?</h4>
        </Item>
        <Item wrapperCol={{ span: 10, offset: 6 }}>
          <Space>
            <Button type="primary" htmlType="submit" loading={offLoading}>确定</Button>
            <Button onClick={() => onClose()}>取消</Button> 
          </Space>         
        </Item>         
      </Form>
  </Card >)
}

export default OffBookkeepingModal
