import React, { FC } from 'react';
import { Form, InputNumber, Space, Button } from 'antd';

import { Card } from '@comps';
import { SplitType } from '../interface';

const { Item } = Form;

interface SplitLimitProps {
  splitType: number,
  checkIsCanSplit: (
    splitType: number, 
    closeSplitAmountMD: () => void,
    splitAmount: number, 
  ) => void,
  onClose: () => void
};

const SplitLimitMD: FC<SplitLimitProps> = ({
  splitType,
  checkIsCanSplit,
  onClose
}) => {

  const isSplitAll = splitType === SplitType.ALL;
  const [form] = Form.useForm();
 
  return(
    <Card title={`按限额拆分（${isSplitAll ? '全部' : '部分'}）`}>
      <Form
        form={form}
        onFinish={() => checkIsCanSplit(splitType, onClose, form.getFieldValue('splitAmount') )}
        style={{ marginTop: 20, textAlign: 'center' }}
      >
        <div>
          <div>
            <Item
              label="请输入自定义拆分限额（含税金额）"
              name="splitAmount"
              rules={[{ required: true, message: '请输入拆分限额' }]}
            >
              <InputNumber style={{ width: '100%' }} />
            </Item>
          </div>
          <p style={{ color: '#f00' }}>注意：不能超过开票设置的税盘限额</p>
        </div>
        <div style={{ marginTop: 12, paddingLeft: 20 }}>
          <Space size="large">
            <Button type="primary" htmlType="submit">拆分预览</Button>
            <Button onClick={onClose}>取消</Button>
          </Space>
        </div>
      </Form>
    </Card>
  );
};

export default SplitLimitMD;
