import React, { Component, Fragment } from "react";
import { Table as AntdTable, Form } from "antd";
import { FormInstance } from "antd/lib/form";
import moment from "moment";

import Query from "./Query";

import { Http, Message } from "@utils";
import { TableUtil } from "./util";
import { ColumnProps, TableProps } from "./table.d";
import ResizeableTitle from "./ResizeableTitle";

/**
 * 表格组件, 功能特性如下:
 * - 1.支持表头字段进行[筛选、排序]
 * - 2.支持查询面板
 * - 3.支持 selectedRow, 即第一列提供复选框
 * - 4.支持列以[标签]样式显示, 可配置标签颜色
 * - 5.Column 支持自定义字段: filterkey[string], isTag[boolean]
 * - 6.支持字段自动格式化, 包含:金额字段自动千分位并右对齐, 日期字段格式化(需提供format)
 * - 7.支持将当前对象的引用传递给父组件, 父组件通过 props 传递 onTableRef 函数即可
 *
 * @see https://ant-design.gitee.io/components/table-cn/
 */

// 表格默认列宽
const DEFAULT_COL_WIDTH: number = 150;
// 默认分页参数
const FILED_PAGE_NUM: string = "pageNum";
const FILED_PAGE_SIZE: string = "pageSize";

interface State {
  // 选中行
  selectedRowKeys?: any[];
  hideOnSinglePage: boolean;
  mounted?: boolean | false;
  filterKeys: any;
  columns: any;
  dataSource: any;
  loading?: boolean | false;
  xScroll: any;
  pageSize?: number | 10;
  pageNum?: number | 1;
  pageSizeOptions?: string[] | ["5", "10", "20", "50", "100", "500"];
  total?: number | 0;

  queryParams?: object | {};
  components: JSONObject;
}

class Table extends Component<TableProps, State> {
  protected form: FormInstance | null = null;

  constructor(props: TableProps) {
    super(props);

    this.handOnTableChange = this.handOnTableChange.bind(this);
    this.handOnReset = this.handOnReset.bind(this);
    this.handOnQuery = this.handOnQuery.bind(this);
    this.fetchTableData = this.fetchTableData.bind(this);
    this.onSelectChange = this.onSelectChange.bind(this);
    this.refresh = this.refresh.bind(this);
    this.getFormParam = this.getFormParam.bind(this);
    this.setFormValue = this.setFormValue.bind(this);
    this.setDataSource = this.setDataSource.bind(this);

    let {
      columns,
      dataSource,
      selectedRowKeys,
      pagination,
      getTableSummaryInfo,
      querySpecialFormat,
      resetEmptyFileds,
      shouldFetchData,
      clearQuerySpecialFormat,
    } = this.props;

    // 表头所有筛选和排序字段, 需要传递给查询接口的字段名
    let filterKeys: any = {};

    // 表格宽度,高度
    let xScroll = 0;

    // 解析 columns
    columns?.map((column) => {
      const key = column.key;

      // 设置 Column 默认宽度
      if (!column.width) {
        column.width = DEFAULT_COL_WIDTH;
      }

      // 通过 React key 属性自动对 antd Column 的 dataIndex 赋值
      if (!column.dataIndex) {
        column.dataIndex = key;
      }

      // 存储筛选字段的实际查询key, 如: statusDesc 的实际查询字段为 stateCode
      if (column.filterKey) {
        filterKeys[key] = column.filterKey;
      }

      // 处理 tag 类型样式
      TableUtil.handleColumnTag(column);

      // 渲染特定数据格式
      TableUtil.handleColumnFormat(column);

      // 超过宽度将自动省略
      column.ellipsis = true;

      // 自动处理横向滚动条的宽度
      xScroll += column.width;
    });

    let actualPageSize = 10;
    let actualHideOnSinglePage = false;

    if (pagination) {
      let { pageSize, hideOnSinglePage } = pagination;
      if (pageSize) {
        actualPageSize = pageSize;
      }

      if (hideOnSinglePage !== undefined) {
        actualHideOnSinglePage = hideOnSinglePage;
      }
    }

    // 定义头部组件
    const components = {
      header: {
        cell: ResizeableTitle,
      },
    };

    this.state = {
      mounted: false,
      filterKeys: filterKeys,
      columns: columns,
      dataSource: dataSource,
      loading: false,
      xScroll: xScroll,
      pageSize: actualPageSize,
      pageNum: 1,
      pageSizeOptions: ["5", "10", "20", "50", "100", "500"],
      total: 0,
      queryParams: {},
      selectedRowKeys: selectedRowKeys,
      hideOnSinglePage: actualHideOnSinglePage,
      components: components,
    };
  }

  //解析columns字段
  handleColumns(columns?: ColumnProps[]) {
    // 表头所有筛选和排序字段, 需要传递给查询接口的字段名
    let filterKeys: any = {};
    // 表格宽度,高度
    let xScroll = 0;
    let { columns: columns2 } = this.props;
    let tempColumn = columns ?? columns2;
    // 解析 columns
    tempColumn?.map((column) => {
      const key = column.key;

      // 设置 Column 默认宽度
      if (!column.width) {
        column.width = DEFAULT_COL_WIDTH;
      }

      // 通过 React key 属性自动对 antd Column 的 dataIndex 赋值
      if (!column.dataIndex) {
        column.dataIndex = key;
      }

      // 存储筛选字段的实际查询key, 如: statusDesc 的实际查询字段为 stateCode
      if (column.filterKey) {
        filterKeys[key] = column.filterKey;
      }

      // 处理 tag 类型样式
      TableUtil.handleColumnTag(column);

      // 渲染特定数据格式
      TableUtil.handleColumnFormat(column);

      // 超过宽度将自动省略
      column.ellipsis = true;

      // 自动处理横向滚动条的宽度
      xScroll += column.width;
    });
    this.setState({
      columns: tempColumn,
      xScroll: xScroll,
      filterKeys: filterKeys,
    });
  }
  // 处理拖拽
  handleResize =
    (column: JSONObject) =>
    (e: any, { size }: JSONObject) => {
      this.setState(({ columns }) => {
        columns.forEach((item: JSONObject) => {
          if (item.key === column.key) {
            item.width = size.width;
          }
        });

        return { columns };
      });
    };

  /**
   * 重新获取Table数据, 该方法提供给父组件使用
   */
  refresh() {
    this.fetchTableData(null, this.state.pageSize, this.state.pageNum);
  }

  getFormParam() {
    return this.form?.getFieldsValue();
  }

  setFormValue(data: JSONObject) {
    const formValue = this.form?.getFieldsValue();
    this.form?.setFieldsValue({ ...formValue, ...data });
    // this.fetchTableData({ ...formValue, ...data }, this.state.pageSize, this.state.pageNum);
  }

  resetDataSource() {
    this.setState({
      dataSource: [...this.state.dataSource],
    });
  }

  resetDataSourceMin(dataSource = this.state.dataSource) {
    this.setState({
      dataSource: [...dataSource],
    });
  }
  resetEmptyDataSource() {
    this.setState({
      dataSource: [],
    });
  }
  /**
   * 获取选中的rowKey, 该方法提供给父组件使用
   * @returns rowKey列表
   */
  getSelectedRowKeys() {
    return this.state.selectedRowKeys ?? [];
  }

  /**
   * 获取选中行的某个属性, 该方法提供给父组件使用
   * @returns rowKey列表
   */
  getSelectedRowAttr(attr: string = "id") {
    const attrArr: any[] = [];
    const keyArr: any[] = this.getSelectedRowKeys() || [];
    const currentTableDataArr: JSONObject[] = this.getTableData();

    keyArr.forEach((key) => {
      currentTableDataArr.forEach((item) => {
        if (item.id === key) {
          attrArr.push(item[attr]);
        }
      });
    });

    return attrArr;
  }

  /**
   * 获取查询面板有效参数，该方法提供给父组件使用
   * @returns 查询参数面板参数
   */
  getQueryPanelParams() {
    let queryPanelParams = this.form?.getFieldsValue();

    if (queryPanelParams) {
      const keys = Object.keys(queryPanelParams);
      for (var key of keys) {
        const val = queryPanelParams[key];
        if (val === undefined || val === null || val === "") {
          delete queryPanelParams[key];
        }

        if (Array.isArray(val) && val.length === 0) {
          delete queryPanelParams[key];
        }
      }
    }

    return queryPanelParams;
  }

  /**
   * 获取表格选中行数据
   * @returns 表格所有数据
   */
  getSelectedRowData() {
    const selectedIDs: any[] = this.getSelectedRowKeys() || [];
    const currentTableData: JSONObject[] = this.getTableData();
    const filteredArr = currentTableData.filter((invoice) =>
      selectedIDs.includes(invoice.id)
    );
    return filteredArr;
  }

  /**
   * 获取表格所有的数据
   * @returns 表格所有数据
   */
  getTableData() {
    return this.state.dataSource ?? [];
  }

  /**
   * 新增临时行数据
   * @param data 要新增的行数据
   */
  addTemporaryData(data: JSONObject) {
    const oldTableData: JSONObject[] = this.getTableData() ?? [];
    data.id = oldTableData.length;

    this.setState({
      dataSource: [...oldTableData, data],
    });

    Message.success("新增成功");
  }

  /**
   * 修改临时行数据
   * @param data 新行数据
   * @param id 要修改行的 ID
   */
  editTemporaryData(data: JSONObject, id: number) {
    const newTableData: JSONObject[] = [...this.state.dataSource];

    const editRowIndex = newTableData.findIndex((row) => row.id === id);
    data.id = id;
    newTableData[editRowIndex] = data;

    this.setState({
      dataSource: newTableData,
    });

    Message.success("修改成功");
  }

  /**
   * 删除临时行数据
   * @param ids 要删除的临时行的 IDs
   */
  deleteTemporaryData(ids: number[]) {
    const oldTableData: JSONObject[] = [...this.state.dataSource];
    const newTableData = oldTableData.filter((row) => !ids.includes(row.id));

    this.setState({
      dataSource: newTableData,
    });

    Message.success("删除成功");
  }

  setDataSource(dataSource: JSONObject[]) {
    this.setState({ dataSource });
  }

  componentDidMount() {
    if (this.props.onTableRef) {
      this.props.onTableRef(this);
    }
    if (!this.props.shouldFetchData) {
      const { url } = this.props;
      url && this.fetchTableData(null, this.state.pageSize, this.state.pageNum);
    }
  }

  componentWillReceiveProps(nextProps: any) {
    // console.log(nextProps,'===============',nextProps.columns)
    this.handleColumns(nextProps.columns);
    // this.setState({columns: nextProps.columns});
  }
  /** checkbox 变化 */
  onSelectChange(selectedRowKeys: [], selectedRows: []) {
    this.setState({ selectedRowKeys });
  }

  /**
   * @description 表格[分页、排序、筛选]变化时触发
   * @param pagination 分页选项, https://ant.design/components/pagination-cn/
   * @param filters 表头的筛选菜单项,结构:{key1:[v1,v2], key2:[v1,v2]} https://ant.design/components/table-cn/#Column
   * @param sorter 排序选项
   * @param extr 额外信息, 仅有 currentDataSource, [[row1], [row2]]
   */
  handOnTableChange(pagination: any, filters: any, sorter: any, extr: any) {
    let queryParams: any = {};
    let { current, pageSize } = pagination;

    // 收集表头 [筛选值] 参数值
    if (filters) {
      for (let name in filters) {
        const valueArr = filters[name];
        if (valueArr) {
          const filterKey = this.state.filterKeys[name];
          queryParams[filterKey] = valueArr.join(",");
        }
      }
    }

    // 收集表头 [排序字段] 参数值, 每次仅支持一个排序字段. antd order 可选值:[descend, ascend]
    if (sorter && sorter.column) {
      const field = sorter.field;
      const order = sorter.order === "descend" ? "desc" : "asc";
      queryParams["sort"] = field + "_" + order;
    } else {
      queryParams["sort"] = "";
    }

    this.setState({ pageSize: pageSize });

    this.fetchTableData(queryParams, pageSize, current);
  }

  /**
   * 日期格式转换
   * @param formData 表单数据
   * @param dateFormat 日期格式
   * @returns
   */
  handleDateFormatChange(formData: JSONObject, dateFormat: JSONObject) {
    for (let key in dateFormat) {
      if (formData[key]) {
        formData[key] = moment(formData[key]).format(dateFormat[key]);
      }
    }

    return formData;
  }

  /**
   * 发送请求查询数据
   * @param tableHeaderParams Table Header 上查询参数, 主要为分页、状态筛选两种
   * @param pageSize
   * @param pageNum
   */
  fetchTableData(
    tableHeaderParams?: any,
    pageSize: number = 10,
    pageNum: number = 1,
    resetFlag: boolean = false
  ) {
    const {
      url = "",
      querySpecialFormat = {},
      resetEmptyFileds,
      getTableSummaryInfo = () => {},
      pagination = true,
      method = "get",
      formAdditionalParameters,
      dateFormat,
      setTableSummaryData,
      setDataSourceHierarchy,
      getOtherTableInfo,
    } = this.props;

    // 删除查询面板参数中的空值
    let queryPanelParams = this.getQueryPanelParams() ?? {};

    // 处理特殊查询面板格式
    if (Object.keys(querySpecialFormat).length !== 0) {
      queryPanelParams = { ...queryPanelParams, ...querySpecialFormat };
    }

    // 判断是否有表单额外参数
    if (formAdditionalParameters) {
      queryPanelParams = { ...queryPanelParams, ...formAdditionalParameters };
    }

    // 判断是否要转换日期格式
    if (dateFormat) {
      queryPanelParams = this.handleDateFormatChange(
        queryPanelParams,
        dateFormat
      );
    }

    // 判断是否是重置按钮且是否有重置参数
    if (resetFlag && resetEmptyFileds) {
      if (typeof resetEmptyFileds === "object") {
        queryPanelParams = { ...queryPanelParams, ...resetEmptyFileds };
        this.setFormValue(queryPanelParams);
      } else {
        const obj = resetEmptyFileds();
        queryPanelParams = { ...queryPanelParams, ...resetEmptyFileds() };
        this.setFormValue(queryPanelParams);
      }
    }

    // 查询参数 = 表头查询参数 + 查询面板参数 + 分页参数
    let queryParams = { ...tableHeaderParams, ...queryPanelParams };
    queryParams[FILED_PAGE_SIZE] = pageSize;
    queryParams[FILED_PAGE_NUM] = pageNum;

    const _this = this;
    this.setState({ loading: true });

    // 获取表格汇总数据
    getTableSummaryInfo({ ...queryPanelParams });
    Http[method](url, queryParams)
      .then((data: JSONObject) => {
        getOtherTableInfo && getOtherTableInfo(data);
        pagination
          ? _this.setState({
              dataSource: data.content,
              pageNum: data.pageNum,
              total: data.total,
            })
          : _this.setState({
              dataSource: setDataSourceHierarchy
                ? setDataSourceHierarchy(data)
                : data,
            });

        setTableSummaryData && setTableSummaryData(data);
      })
      .finally(() => {
        _this.setState({ loading: false });
      });
  }

  /**
   * 点击查询按钮发起查询
   */
  handOnQuery() {
    this.fetchTableData(null, this.state.pageSize, 1);
  }

  /**
   * 重置所有查询条件
   */
  handOnReset() {
    const { resetEmptyFileds, shouldQueryNoAfterReset = false } = this.props;
    this.form?.resetFields();
    //重置后不执行查询，并将表格内容重置为空
    if (!shouldQueryNoAfterReset) {
      this.fetchTableData(null, 10, 1, true);
    } else {
      this.resetEmptyDataSource();
      if (resetEmptyFileds) {
        if (typeof resetEmptyFileds === "object") {
          this.setFormValue(resetEmptyFileds);
        } else {
          this.setFormValue(resetEmptyFileds());
        }
      }
    }
  }

  render() {
    const {
      pageNum,
      total,
      pageSizeOptions,
      pageSize,
      hideOnSinglePage,
      dataSource,
      loading,
      xScroll,
      selectedRowKeys,
      components,
    } = this.state;

    const {
      isExpandAllItem = false,
      elementSizeOfRow = 5,
      isShowSerialNumber,
      children,
      pagination = true,
      rowKey = "id",
      scroll = {},
      queryProps = {},
      rowSelection,
      summary,
      bordered = true,
      ...others
    } = this.props;

    let { columns } = this.state;
    if (isShowSerialNumber) {
      columns = [
        {
          key: "",
          title: "序号",
          width: 60,
          fixed: "left",
          render: (val: any, row: JSONObject, index: number) => index + 1,
        },
        ...columns,
      ];
    }

    // 给每一列的onHeaderCell属性增加onResize方法，用于传递onResize事件
    columns.map((col: any) => {
      col.onHeaderCell = () => ({
        width: col.width,
        onResize: this.handleResize(col),
      });
    });

    let currentRowSelection: any = {};
    let defaultRowSelection: any = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };

    currentRowSelection = rowSelection
      ? { ...defaultRowSelection, ...rowSelection }
      : defaultRowSelection;

    // 如果 selectedRowKeys 未提供任意值, 则不展示 checkbox
    if (!selectedRowKeys) {
      currentRowSelection = undefined;
    }

    return (
      <Fragment>
        {this.props.children && (
          <Form
            {...queryProps}
            name="form"
            onFinish={this.handOnQuery}
            ref={(form) => (this.form = form)}
          >
            <Query
              elementSizeOfRow={elementSizeOfRow}
              isExpandAllItem={isExpandAllItem}
              onReset={this.handOnReset}
            >
              {children}
            </Query>
          </Form>
        )}
        <div className="components-table-resizable-column">
          <AntdTable
            {...others}
            rowSelection={currentRowSelection}
            rowKey={rowKey}
            columns={columns}
            dataSource={dataSource}
            onChange={this.handOnTableChange}
            loading={loading}
            components={components}
            bordered={bordered}
            pagination={
              pagination
                ? {
                    // 只有一页时是否隐藏分页器
                    hideOnSinglePage: hideOnSinglePage,
                    // 	是否可以快速跳转至某页
                    showQuickJumper: true,
                    // 展示 pageSize 切换器
                    showSizeChanger: true,
                    current: pageNum,
                    total,
                    pageSize,
                    pageSizeOptions,
                    // 用于显示数据总量和当前数据顺序
                    showTotal: (total) => `共 ${total} 条`,
                  }
                : false
            }
            summary={
              summary ? (data) => summary(data, selectedRowKeys) : undefined
            }
            scroll={{
              ...scroll,
              x: xScroll,
            }}
          />
        </div>
      </Fragment>
    );
  }
}

export { Table };
