import React, { FC, useEffect, memo } from 'react';
import { Form, Row, Col, Space, Input, Button } from 'antd';

import { Card, DisplayInput } from '@comps';

import { Http, Message } from '@utils';

const { Item } = Form;

interface Props {
  isAdd: boolean,
  rowObj: JSONObject,
  onClose: () => void
};

const AddOrEditMD: FC<Props> = memo(({
  isAdd,
  rowObj,
  onClose
}) => {

  const [form] = Form.useForm();

  const handleAddOrEdit = () => {
    const { id } = rowObj;
    const formData = form.getFieldsValue();
    formData.id = id;
    
    const httpMethod = 
      isAdd
        ? Http.post
        : Http.put

    httpMethod('/sys/regions', formData)
      .then(() => {
        Message.success(isAdd ? '新增成功' : '修改成功');
        onClose();
      });
  };

  useEffect(() => {
    form.setFieldsValue(rowObj);
  }, []);

  return (
    <Card title={ isAdd ? '新增' : '修改' }>
      <Form form={form}>
        <Row>
          <Col span={24}>
            <Item label="区域名称" name="name" rules={[{ required: true }]}>
              <Input />
            </Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Item label="区域代码" name="code" rules={[{ required: true }]}>
              { isAdd ?
                <Input /> :
                <DisplayInput/>
              }
            </Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Space>
              <Button type="primary" onClick={handleAddOrEdit}>确定</Button>
              <Button onClick={onClose}>取消</Button>
            </Space>
          </Col>
        </Row>
      </Form>
    </Card>
  );
});

export default AddOrEditMD;