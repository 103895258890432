import React, { FC, useState, useEffect, useCallback, useRef } from 'react'

import { Page, Card, Button, PermissionButton, Modal, Download } from '@comps'
import { Steps, Space, Upload, Row } from 'antd'

import { Context, Message, Env, Http, Lang } from '@utils'
import SyncBill from './syncBill.md'

import '../index.css'
import './index.css'

const { Step } = Steps

const Gain: FC = () => {
  const [currentStep, setCurrentStep] = useState<number>(0)

  const [syncBillModal, syncBillModalHolder] = Modal.useModal()

  const [billGainInfo, setBillGainInfo] = useState<JSONObject>({})
  const billGainInfoRef: any = useRef()
  const [uploadLoading, setUploadLoading] = useState<boolean>(false)

  const [modal, modalHolder] = Modal.useModal()

  const getBillGainInfo = useCallback(async () => {
    const res: JSONObject = await Http.get('/input/gx/period')
    setBillGainInfo(res)
    billGainInfoRef.current = res
  }, [])
  
  useEffect(() => {
    getBillGainInfo()
  }, [])

  const handleNextStep = () => {
    Context.route('/income/authentication/check')
  }

  const handleStepChange = (current: number) => {
    if (current === 1) {
      Context.route('/income/authentication/check')
    } else if (current === 2) {
      Context.route('/income/authentication/statistics')
    }
  }

  const handleSyncBill = React.useCallback(() => Lang.noError(async () => {
    await syncBillModal.open({
        width: '600px',
        ignoreError: false,
        content(resolve, reject) {
            return (<SyncBill onClose={reject} taxNo={billGainInfoRef.current.taxNo} />);
        },
    });
}), [syncBillModal]);

  return (
    <Page>
      {syncBillModalHolder}
      {modalHolder}
      
      <div className="authentication">
        <div className="steps">
          <Steps current={currentStep} onChange={handleStepChange} size="small">
            <Step title="发票获取" />
            <Step title="发票勾选" />
            <Step title="发票抵扣统计" />
          </Steps>
        </div>
        <Card title="发票获取">
          <div className="content">
            <div className="content-left"></div>
            <div className="content-right">
              <ul className="item-ul">
                <li>
                  <span>税号：</span>
                  <span>{billGainInfo?.taxNo}</span>
                </li>
                <li>
                  <span>纳税人名称：</span>
                  <span>{billGainInfo?.taxName}</span>
                </li>
                <li>
                  <span>当前税款所属期：</span>
                  <span>{billGainInfo?.period}</span>
                </li>
                <li>
                  <span>接口类型：</span>
                  <span>{billGainInfo?.gxInterfaceTypeDesc}</span>
                </li>
              </ul>
              <Space>
                <PermissionButton permissions="InputGX.syncInvoices" 
                                  type="primary" 
                                  onClick={handleSyncBill}>
                                  同步发票</PermissionButton>
                <Upload action={`${Env.getBaseUrl()}/input/gx/importFromExcel`}
                  headers={{
                    Authorization: Context.getToken()
                  }}
                  accept=".xlsx,.xls"
                  onChange={async ({ file: { status, response = {} } }) => {
                    setUploadLoading(true);
                    if (!['done', 'error'].includes(status as string)) {
                      return;
                    }
                    const { data = {}, code, msg } = response;
                  
                    if (String(code) !== '200') {
                      Message.error(msg || '未知错误, 请联系管理员');
                      setUploadLoading(false);
                      return;
                    }
                    const { cancelGxSize, errorFileUrl, errorSize, gxSize, hasError,
                      successSize, total } = data;
                    
                    setUploadLoading(false);

                    // 导入成功
                    if (!hasError) {
                      await modal.open({
                        width: '600px',
                        content(_1, reject) {
                          return (<Card title="导入成功" style={{ marginBottom: '0' }}>
                            <Space direction="vertical" style={{ width: '100%' }}>
                              <Row justify="center">
                                {`成功导入 ${successSize} 条，是否进行下一步勾选操作 ？`}
                              </Row>
                              <Row justify="center">
                                <Button type="primary" onClick={handleNextStep}>下一步</Button>
                                <Button onClick={() => reject()}>否</Button>
                              </Row>
                            </Space>
                          </Card>);
                        },
                      });

                      return;
                    }

                    // 导入失败
                    await modal.open({
                      width: '600px',
                      content(_1, reject) {
                        return (<Card title="导入失败" style={{ marginBottom: '0' }}>
                          <Space direction="vertical" style={{ width: '100%' }}>
                            <Row justify="center">
                              {`总共 ${total} 条， 本次需要勾选 ${gxSize} 条, 撤销勾选 ${cancelGxSize} 条，导入失败 ${errorSize} 条。`}
                            </Row>
                            <Row justify="center">
                              <Download url={errorFileUrl} label='点击下载失败的导入记录'/>
                            </Row>
                            <Row justify="center">
                              <Button onClick={() => reject()}>关闭</Button>
                            </Row>
                          </Space>
                        </Card>);
                      },
                    });
                  }}
                  showUploadList={false}>
                  <PermissionButton permissions="InputGX.importFromExcel" type="primary" loading={uploadLoading}>导入发票</PermissionButton>
                </Upload>
                <Button onClick={handleNextStep}>下一步</Button>
              </Space>
              <p className="update-time">
                <span className="update-img"></span>
                <span>最新同步/导入时间：</span>
                <span>{billGainInfo?.lastSyncTime}</span>
              </p>
            </div>
          </div>
        </Card>
      </div>
    </Page>
  )
}

export default Gain