import React, { FC, useState } from "react";
import { Card, DeptSelect, Page, Table, Modal, Lookup, LinkText, PermissionButton } from "@comps";
import { DatePicker, Form, Button, Space, Input, message } from 'antd'
import { ColumnProps } from "@comps/types";
import { useSelector } from "react-redux";
import { UserContext } from "@models";
import { Context, FormUtil, Http } from "@utils";
import ApprovalModal from './modal/approvalModal'
import CorrectModal from './modal/correctModal'

const { Item } = Form
const { RangePicker } = DatePicker

const zzsColumns: ColumnProps[] = [
  { key: 'projectName', title: "项目名称", width: 140, align: "center" },
  {
    key: 'amount', title: "本期发生", width: 140, align: "center",
    render(value, tableRow: JSONObject) {
      if (tableRow.projectName === "增值税税负率") {
        return value === null ? 0 : (value * 100).toFixed(2) + "%"
      } else {
        return value === null ? 0 : value.toLocaleString()
      }
    }
  },
  {
    key: 'amountAccumulated', title: "本年累计", width: 140, align: "center",
    render(value, tableRow: JSONObject) {
      if (tableRow.projectName === "增值税税负率") {
        return value === null ? 0 : (value * 100).toFixed(2) + "%"
      } else {
        return value === null ? 0 : value.toLocaleString()
      }
    }
  },
]

const otherColumns: ColumnProps[] = [
  { title: "项目", key: "projectName", width: 100 },
  {
    title: "金额", key: "amount", width: 100,
    render(value: any, record: any, index: number) {
      if (record.projectName === "企业所得税税负率") {
        return value ? value + "%" : 0
      } else {
        return value ? value.toLocaleString() : 0
      }
    }
  },
]

const Matter: FC = () => {

  // const period = moment().subtract(1, 'months')
  const [modal, modalHolder] = Modal.useModal();
  const userInfo = useSelector<JSONObject, UserContext>((state) => state.ctx);
  const [orgId, setOrgId] = useState<number>(userInfo.orgId || 0)

  let [table, setTable] = useState<Table>();
  const onTableRef = (tableRef: Table) => {
    table = tableRef;
    setTable(tableRef);
  }

  const correct = () => {
    const selectedRow = table?.getSelectedRowData()
    if (!selectedRow || selectedRow.length !== 1) {
      message.warning("请选择一条审批事项")
      return
    }
    const { approveType } = selectedRow[0]
    const { fullName } = userInfo
    const draftType = [1, 3]
    if (!draftType.includes(approveType)) {
      message.warning("只能对底稿审批记录发起更正申请！")
      return;
    }
    const param = {
      ...selectedRow[0],
      fullName,
    }
    modal.open({
      width: "600px",
      content(_1, reject) {
        return (
          <CorrectModal
            data={param}
            onClose={reject}
            query={table?.refresh}>
          </CorrectModal>
        )
      }
    })
  }

  const approval = async () => {
    const selectedRow = table?.getSelectedRowData()
    if (!selectedRow || selectedRow.length !== 1) {
      message.warning("请选择一条审批事项")
      return
    }
    const { sourceId, approveType } = selectedRow[0]
    let param = selectedRow[0]
    if (approveType === 1) {
      const [dg] = await Promise.all([
        Http.get(`/taxtypedg/manuscriptApproval/${sourceId}`)
      ])
      param = { ...param, dg }
    }
    await draftModal(param)
  }

  const draftModal = async (rowData: JSONObject) => {
    const { taxName } = rowData
    switch (taxName) {
      case "增值税":
        rowData = { ...rowData, approvalColumns: zzsColumns }
        break;
      case "企业所得税（季度）":
        rowData = { ...rowData, approvalColumns: otherColumns }
        break;
      default:
        break;
    }
    await modal.open({
      width: '800px',
      content(_1, reject) {
        return (
          <ApprovalModal
            data={rowData}
            onClose={reject}
            query={table?.refresh}></ApprovalModal>
        )
      }
    })
  }

  const setFormOrgNameValue = (orgNameVal: string) => {
    setOrgId(Number(orgNameVal || 0))
  }

  const exportExcel = () => {
    const { taxName, approveStatus, period, matterName } = table?.getFormParam() ?? {}
    FormUtil.exportExcelFile(`/approval/excelExport`, {
      orgId,
      taxName: taxName ? taxName : "",
      approveStatus: approveStatus ? approveStatus : "",
      period: period ? period : "",
      matterName: matterName ? matterName : ""
    });
  }

  // 查看功能
  const checkPage = (state: JSONObject) => {
    const { approveType } = state
    if (approveType === 1 || approveType === 3) {
      Context.handleAdditionalPane('底稿管理', '/declare/draftVAT', '/declare/draftVAT', state)
    }
    if (approveType === 2 || approveType === 4) {
      Context.handleAdditionalPane('知识查阅', '/taxKnowledge/knowledgeConsult', '/taxKnowledge/knowledgeConsult', state)
    }
  }

  const columns: ColumnProps[] = [
    { key: 'orgName', title: "公司名称", width: 250, fixed: "left" },
    { key: 'matterName', title: "事项名称", width: 140 },
    { key: 'period', title: "属期", width: 140 },
    { key: 'taxName', title: "税种", width: 140 },
    { key: 'approveStatusDesc', title: "审批状态", width: 140 },
    { key: 'pushOaStatusDesc', title: "推送状态", width: 140 },
    { key: 'submitter', title: "提交人", width: 140 },
    { key: 'approver', title: "审批人", width: 140 },
    { key: 'approvalOpinion', title: "审批意见", width: 140 },
    { key: 'approveExpirationDate', title: "审批截止日期", width: 140 },
    { key: 'lastUpdateTime', title: "修改时间", width: 140 },
    { key: 'remark', title: "借阅原因", width: 140 },
    {
      key: 'id', title: "操作", width: 100, fixed: "right",
      render: (value, record) => {
        const { periodFrom } = record
        return (
          <LinkText label="查看" onClick={() => checkPage({ ...record, period: periodFrom })} />
        )
      }
    },
  ]
  return (
    <Page>
      {modalHolder}
      <Card title="审批事项"
        actions={
          <Space>
            <PermissionButton permissions='Approval.correctApprovalStatus' type="primary" onClick={correct}>更正申请</PermissionButton>
            <PermissionButton permissions='Approval.approval' type="primary" onClick={approval}>审批</PermissionButton>
            <PermissionButton permissions='Approval.excelExport' type="primary" onClick={exportExcel}>导出</PermissionButton>
          </Space>
        }>
        <Table
          url="/approval"
          columns={columns}
          selectedRowKeys={[]}
          onTableRef={onTableRef}
          formAdditionalParameters={{
            orgId
          }}
          resetEmptyFileds={{ orgId: userInfo.orgId || 0 }}
          scroll={{ y: window.innerHeight - 350 }}
        >
          <Item name="orgId">
            <DeptSelect isKeyAsValue={true} setFormOrgNameValue={setFormOrgNameValue} placeholder="公司名称" />
          </Item>
          <Item name="taxName">
            <Lookup isKey={false} lookupType="TAX_TYPE" placeholder="税种"></Lookup>
          </Item>
          <Item name="approveStatus">
            <Lookup lookupType="SPZT" placeholder="审批状态"></Lookup>
          </Item>
          <Item name="period">
            <RangePicker placeholder={['所属期起', '所属期止']} format="YYYYMMDD" inputReadOnly />
          </Item>
          <Item name="approver">
            <Input placeholder="审批人" />
          </Item>
          <Item name="matterName">
            <Input placeholder="事项名称" />
          </Item>
        </Table>
      </Card>
    </Page>
  )
}

export default Matter
