import _ from 'lodash';

export type Callback = (...args: any[]) => Promise<any>;

/**
 * 让异步函数不抛出异常
 * @param callback
 */
export async function noError(callback: Callback) {
  try {
    await callback();
 } catch (error) {
   console.error(error);
 }
};

/**
 * 过滤对象中空值(null, undefined)的属性
 * @param object
 */
export function filterObjectNil(object: object) {
  return _.pickBy(object, item => !_.isNil(item));
}

/**
 * 数组去重
 * @param list
 * @param name 去重属性，可不传
 */
 export function uniqueList(list: Array<JSONObject>, name?: string) {
  const arr = []
  for (let i = 0; i < list.length; i++) {
    const item = list[i];
    const index = name ? arr.findIndex((opt) => opt[name] == item[name]) : arr.findIndex((opt) => opt == item)
    if (index > -1) {
      arr.splice(index, 1, item)
    } else {
      arr.push(item)
    }
  }
  return arr
}

/**
 * 启动一个计时器
 * @param func
 * @param time
 * @param maxCount
 */
export function timer(func: Function, time: number, maxCount: number = 30000) {
  let count = 0;
  const timer = setInterval(async () => {
    count += 1;
    if (count > maxCount) {
      clearInterval(timer);
      return;
    }
    try {
      func();
    } catch (error) {
      if (error === (timer as any).break) {
        clearInterval(timer);
        return;
      }
      throw error;
    }
  }, time);
  return timer;
}
timer.break = new Error();
