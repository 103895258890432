import React, { FC, useState } from 'react';
import { Form, Row, Col, Select, Input, Space, Button } from 'antd';

import { Card } from '@comps';

import { Http, Message } from '@utils';

interface IProps {
  ids: number[];
  refreshTable: () => void;
  onClose: () => void;
}

const { Item } = Form;
const { Option } = Select;
const { TextArea } = Input;

const APPROVED = 'APPROVED';
const REJECTED = 'REJECTED';

const ApproveRes: FC<IProps> = ({
  ids,
  refreshTable,
  onClose,
}) => {
  const [form] = Form.useForm();

  const [isShowApproveRemark, setIsShowApproveRemark] = useState(false);
  const [approveLoading, setApproveLoading] = useState(false);

  const handleApproveResChange = (val: any) => {
    setIsShowApproveRemark(val === REJECTED);
  };

  const handleApprove = () => {
    setApproveLoading(true);

    const formData = form.getFieldsValue();

    Http.post('/request/batchApprove', { 
      ...formData, 
      requestIds: ids, 
    })
      .then(() => {
        refreshTable();
        onClose();
        Message.success('审批成功');
      })
      .finally(() => {
        setApproveLoading(false);
      });
  };

  return (
    <Card title="审批结果">
      <Form form={form} onFinish={handleApprove}>
        <Row style={{ marginTop: 20 }}>
          <Col span={24}>
            <Item
              label="审批结果"
              name="statusCode"
              rules={[{ required: true }]}
            >
              <Select
                onChange={handleApproveResChange}
              >
                <Option value={APPROVED}>审批通过</Option>
                <Option value={REJECTED}>审批驳回</Option>
              </Select>
            </Item>
          </Col>
          <Col span={24} style={{ height: 70 }}>
            {isShowApproveRemark && (
              <Item name="remark">
                <TextArea placeholder="输入驳回原因" />
              </Item>
            )}
          </Col>
          <Col span={24} style={{ textAlign: 'center' }}>
            <Space size="large">
              <Button
                type="primary"
                htmlType="submit"
                loading={approveLoading}
              >
                确定
              </Button>
              <Button onClick={onClose}>取消</Button>
            </Space>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};

export default ApproveRes;
