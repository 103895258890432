/** 单据操作类型 */
export enum DocOperationType {
  /**
   * 复制
   */
  COPY,
  /**
   * 复制对冲
   */
  COPY_HEDGE,
  /**
   * 引入
   */
  INTRODUCE,
  /**
   * 导入
   */
  IMPORT,
  /**
   * 导出
   */
  EXPORT,
  /**
   * 同步
   */
  SYNC,
  /**
   * 随货
   */
  WITH_GOODS,
  /**
   * 月结
   */
  MONTH_PAID,
  /**
   * 后台开票
   */
  BACKGROUND_INVOICING,
}

/** 批量操作类型 */
export enum BatchOperationType {
  /**
   * 开票
   */
  INVOICING = "invoicing",
  /**
   * 删除
   */
  DELETE = "delete",
  /**
   * 修改单据类型
   */
  MODIFY_BILL_TYPE = "modify_bill_type",
}

/** 更多操作类型 */
export enum OperationType {
  /**
   * 合并明细
   */
  MERGE,
  /**
   * 合并单据
   */
  MERGERECEIPTS,

  /**
   * 拆分
   */
  SPLIT,
  /**
   * 还原
   */
  RESTORE,
}

/** 拆分类型 */
export enum SplitType {
  /**
   * 自定义限额（部分）
   */
  PART = 0,
  /**
   * 税盘
   */
  TAX_PLATE = 1,
  /**
   * 自定义限额（全部）
   */
  ALL = 2,
  /**
   * 自定义金额、份数拆分
   */
  SEPARATE = 3,
  /**
   * 按税率拆分
   */
  RATE = 5,
  /**
   * 按数量拆分
   */
  COUNT = 4,
  /**
   * 按明细拆分
   */
  DETAIL = 6,
}

/** 定时任务类型 */
export enum TimedTaskType {
  /**
   * 同步
   */
  SYNC,
  /**
   * 随货
   */
  WITH_GOODS,
  /**
   * 月结
   */
  MONTH_PAID,
}
