import qs from "qs";
const url = "https://127.0.0.1:18086";

function PrinterServer() {
  return PrinterServer.prototype;
}
PrinterServer.prototype = {
  constructor: PrinterServer,
  checkPrinterVersion: (callback) => {
    fetch(`${url}/v1/print/status?callback=printVersion`, {
      method: "GET",
      headers: new Headers({
        "Content-Type": "application/json",
      }),
    })
      .then((res) => {
        return res.text();
      })
      .then(
        (resolve) => {
          callback(resolve);
        },
        (reject) => {
          callback(reject);
        }
      );
  },
  /**
   * 调用打印服务
   * @param dylx 打印类型 0发票 1清单
   * @param type 打印类型 02发票 xhqd清单
   * @param xmlKey xml内容
   * @param callback
   */
  print: (dylx, type, xmlKey, callback) => {
    let time = new Date().getTime();
    xmlKey = xmlKey
      .replace(/=/g, "")
      .replace(/[\uD800-\uDBFF][\uDC00-\uDFFFF]|[^\x00-\x7F]|[\/]/g, "_")
      .replace(/[+]/g, "-");
    fetch(`${url}/v1/print/printer?messageID=${time}`, {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      }),
      body: qs.stringify({
        messageID: time - 1,
        dylx: dylx,
        type: type,
        XMLKey: xmlKey,
      }),
    })
      .then((res) => {
        return res.text();
      })
      .then(
        (resolve) => {
          callback(resolve);
        },
        (reject) => {
          callback(reject);
        }
      );
  },
  /**
   * 调用打印清单服务
   */
  callPrint: (xmlKey, callback) => {
    let time = new Date().getTime();
    fetch(`${url}/v1/print/status?messageID=${time}&callback=undefined`, {
      method: "GET",
    })
      .then((res) => {
        return res.text();
      })
      .then(
        (resolve) => {
          callback(resolve);
        },
        (reject) => {
          callback(reject);
        }
      );
  },
};
export default PrinterServer;
