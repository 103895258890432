import React, { useState } from 'react';

import { Card } from '@comps';
import { Form, Space, DatePicker, Button } from 'antd';

import { Http, Message } from '@utils';
import moment from 'moment'

export interface Props {
  period: number,
  refreshData: () => void
  onClose: () => void,
}

const Item = Form.Item

const BookkeepingModal: React.FC<Props> = ({ period, refreshData, onClose }: Props) => {
  const [form] = Form.useForm()

  const [bookkeepingLoading, setBookkeepingLoading] = useState<boolean>(false)

  const handleBookkeeping = async () => {
    setBookkeepingLoading(true)

    const fieldsValues = form.getFieldsValue()
    const { chargeUpDate } = fieldsValues
    const chargeUpDateStr = moment(chargeUpDate).format('YYYYMMDD')
    fieldsValues.chargeUpDate = parseInt(chargeUpDateStr)
    fieldsValues.period = period
    
    await Http.get('/input/chargeUp/chargeUp', fieldsValues)
      .then(() => {
        Message.info('操作成功')
        refreshData()
      })
      .finally(() => {
        setBookkeepingLoading(false)
      })
      
    onClose()
  }

  return (
    <Card title='记账'>
      <Form form={form} onFinish={handleBookkeeping} labelCol={{ span: 6 }}>
        <Item name='chargeUpDate' label='记账日期' wrapperCol={{ span: 10 }} rules={[{ required: true }]}>
          <DatePicker picker="date" inputReadOnly/>
        </Item>     
        <Item wrapperCol={{ offset: 6 }}>
          <h4>是否确认记账 ?</h4>
        </Item>
        <Item wrapperCol={{ span: 10, offset: 6 }}>
          <Space>
            <Button type="primary" htmlType="submit" loading={bookkeepingLoading}>确定</Button>
            <Button onClick={() => onClose()}>取消</Button> 
          </Space>         
        </Item>         
      </Form>
  </Card >)
}

export default BookkeepingModal
