import * as React from 'react';
import { Card, Button } from '@comps';
import {Form, Space, Input, DatePicker, message} from 'antd';
import { Http, Lang } from '@utils';

export interface Props {
  invoice: JSONObject,
  onClose: () => void,
};

const LinesMd: React.FC<Props> = ({ invoice, onClose }: Props) => {
  const [form] = Form.useForm();

  const handleSign = React.useCallback(() => Lang.noError(async () => {
    await form.validateFields();
    const formData = form.getFieldsValue();
    await Http.put('/tax/outputInvoices/maskAsInvoiced', { ...formData, id: invoice.id }).then(() => {
      message.info('标记成功')
    }).catch(() => {
      message.error('标记失败')
    });
    onClose();
  }), [form, onClose, invoice]);

  const dateFormat = 'YYYYMMDD';

  return (<Card title='标记为已开票' style={{ marginBottom: '0' }}>
    <Form form={form} labelCol={{ span: 6 }}
      initialValues={invoice}
      layout="vertical"
      style={{ padding: '30px 60px' }}>

      <Form.Item label="发票代码"
        name="invoiceCode"
        rules={[{ required: true, message: '请输入发票代码' }]}>
        <Input />
      </Form.Item>

      <Form.Item label="发票号码"
        name="invoiceNo"
        rules={[{ required: true, message: '请输入发票号码' }]}>
        <Input />
      </Form.Item>

      <Form.Item label="开票日期"
        name="invTime"
        rules={[{ required: true, message: '请输入开票日期' }]}>
        <DatePicker
          format={dateFormat}
          inputReadOnly
        />
      </Form.Item>

      <Form.Item label="开票人"
        name="invoicer"
        rules={[{ required: true, message: '请输入开票人' }]}>
        <Input />
      </Form.Item>

      <Form.Item wrapperCol={{ offset: 6 }}>
        <Space>
          <Button type="primary"
            clickCallback={handleSign}>标记</Button>
          <Button onClick={() => onClose()}>取消</Button>
        </Space>
      </Form.Item>

    </Form>
  </Card>);
};

export default LinesMd;
