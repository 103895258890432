import { Button, Col, Form, Row, Space, Table, message } from "antd";
import React, { FC, useEffect, useState } from "react";

import { Card, DisplayInput } from "@comps";
import { Http } from "@utils";

interface SplitNumProps {
  splitType: number;
  invId: number;
  checkIsCanSplit: (
    splitType: number,
    closeSplitAmountMD: () => void,
    splitDetail: JSONObject[]
  ) => void;
  onClose: () => void;
  detail: JSONObject[];
}

const SplitDetailMD: FC<SplitNumProps> = ({
  splitType,
  invId,
  checkIsCanSplit,
  onClose,
  detail,
}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  useEffect(() => {
    setLoading(true);
    if (detail?.length > 0) {
      form.setFieldsValue({
        splitDetails: detail,
      });
      setLoading(false);
    }
  }, [detail]);
  const columns: any[] = [
    {
      key: "lineNum",
      dataIndex: "lineNum",
      title: "序号",
      align: "center",
      width: 80,
      render: (text: any, field: any, index: any) => {
        return (
          <Row gutter={8}>
            <Col span={22}>
              <Form.Item
                style={{ margin: 0 }}
                name={[field.name, "lineNum"]}
                fieldKey={[field.fieldKey, "lineNum"]}
                key={field.name}
              >
                <DisplayInput style={{ width: "100%", textAlign: "center" }} />
              </Form.Item>
            </Col>
          </Row>
        );
      },
    },
    {
      key: "invoiceGoodsName",
      dataIndex: "invoiceGoodsName",
      title: "货物或应税劳务、服务名称",
      align: "center",
      width: 300,
      render: (text: any, field: any, index: any) => {
        return (
          <Row gutter={8}>
            <Col span={22}>
              <Form.Item
                style={{ margin: 0 }}
                name={[field.name, "invoiceGoodsName"]}
                fieldKey={[field.fieldKey, "invoiceGoodsName"]}
                key={field.name}
              >
                <DisplayInput style={{ width: "100%", textAlign: "center" }} />
              </Form.Item>
            </Col>
          </Row>
        );
      },
    },
    {
      key: "price",
      dataIndex: "price",
      title: "单价",
      align: "center",
      width: 150,
      render: (text: any, field: any, index: any) => {
        return (
          <Row gutter={8}>
            <Col span={22}>
              <Form.Item
                style={{ margin: 0 }}
                name={[field.name, "price"]}
                fieldKey={[field.fieldKey, "price"]}
                key={field.name}
              >
                <DisplayInput style={{ width: "100%", textAlign: "center" }} />
              </Form.Item>
            </Col>
          </Row>
        );
      },
    },
    {
      key: "quantity",
      dataIndex: "quantity",
      title: "数量",
      align: "center",
      width: 100,
      render: (text: any, field: any, index: any) => {
        return (
          <Row gutter={8}>
            <Col span={22}>
              <Form.Item
                style={{ margin: 0 }}
                name={[field.name, "quantity"]}
                fieldKey={[field.fieldKey, "quantity"]}
                key={field.name}
              >
                <DisplayInput style={{ width: "100%", textAlign: "center" }} />
              </Form.Item>
            </Col>
          </Row>
        );
      },
    },
    {
      key: "amount",
      dataIndex: "amount",
      title: "金额",
      align: "center",
      width: 150,
      render: (text: any, field: any, index: any) => {
        return (
          <Row gutter={8}>
            <Col span={22}>
              <Form.Item
                style={{ margin: 0 }}
                name={[field.name, "amount"]}
                fieldKey={[field.fieldKey, "amount"]}
                key={field.name}
              >
                <DisplayInput style={{ width: "100%", textAlign: "center" }} />
              </Form.Item>
            </Col>
          </Row>
        );
      },
    },
  ];

  const onSelectChange = (selectedRowKey: React.Key[]) => {
    console.log("selected row keys: ", selectedRowKey);
    const { splitDetails } = form.getFieldsValue();
    let newSelectedRowKey: any[] = selectedRowKey;
    selectedRowKey.map((item, index) => {
      console.log(
        "item :>> ",
        splitDetails[item],
        splitDetails[item]["discountLineFlag"],
        !selectedRowKey.includes((item as number) - 1)
      );
      if (
        splitDetails[item]?.discountLineFlag &&
        !selectedRowKey.includes((item as number) - 1)
      ) {
        newSelectedRowKey.splice(index as number, 1);
      }
      if (splitDetails[item]["discountFlag"]) {
        newSelectedRowKey.push((item as number) + 1);
      }
    });

    console.log(
      "newSelectedRowKeys :>> ",
      newSelectedRowKey,
      Array.from(new Set(newSelectedRowKey))
    );

    setSelectedRowKeys(Array.from(new Set(newSelectedRowKey)));
  };
  const handleSubmit = () => {
    const { splitDetails } = form.getFieldsValue();
    if (selectedRowKeys.length === 0) {
      message.warning("请选择你要拆分的明细！");
    } else {
      let splitDetail: JSONObject[] = [];
      //统计勾选项中折扣行及被折扣行的数量
      let discountLineFlagCount = 0; //折扣行
      let discountFlagCount = 0; //被折扣行
      selectedRowKeys.map((item, index) => {
        if (splitDetails[item]["discountFlag"]) {
          discountFlagCount++;
        }
        if (splitDetails[item]["discountLineFlag"]) {
          discountLineFlagCount++;
        }
      });
      let resultArr = selectedRowKeys.map((item, index) => {
        if (splitDetails[item]["discountLineFlag"] === false) {
          splitDetail.push({
            lineId: splitDetails[item]["id"],
          });
          return true;
        } else if (splitDetails[item]["discountLineFlag"] === true) {
          //包含折扣行时
          if (discountFlagCount === discountLineFlagCount) {
            splitDetail.push({
              lineId: splitDetails[item]["id"],
            });
            return true;
          } else {
            message.warning(`折扣行不能单独选择，需跟着被折扣行一起选择`);
            return false;
          }
        }
      });
      resultArr.every((item) => item === true) &&
        checkIsCanSplit(splitType, onClose, splitDetail);
    }
  };
  return (
    <Card title={`按明细拆分`}>
      <Form
        form={form}
        initialValues={{
          splitDetails: [],
        }}
        style={{ marginTop: 20, textAlign: "center" }}
        onFinish={() => handleSubmit()}
      >
        <Form.List name="splitDetails">
          {(fields, { add, remove }) => {
            return (
              <Table
                rowSelection={
                  columns.length
                    ? {
                        selectedRowKeys,
                        onChange: onSelectChange,
                        getCheckboxProps: (record: JSONObject) => {
                          const { splitDetails } = form.getFieldsValue();
                          return {
                            disabled:
                              splitDetails[record.name]["discountLineFlag"] ===
                              true, // 配置无法勾选的列
                          };
                        },
                      }
                    : { hideSelectAll: true }
                }
                loading={loading}
                dataSource={fields}
                columns={columns}
                rowKey="key"
                pagination={false}
              />
            );
          }}
        </Form.List>
        <div style={{ marginTop: 12, paddingLeft: 20, textAlign: "center" }}>
          <Space size="large">
            <Button type="primary" htmlType="submit">
              拆分预览
            </Button>
            <Button onClick={onClose}>取消</Button>
          </Space>
        </div>
      </Form>
    </Card>
  );
};

export default SplitDetailMD;
