import React, { FC, useState, useMemo } from 'react';
import { Space, Form, Input, DatePicker, Select, Col } from 'antd';
import moment from 'moment';

import { Page, Card, Button, Table, SBUSelect, TableSummaryRow, PermissionButton } from '@comps';

import { FormUtil, DateUtils, Math } from '@utils';
import { ColumnProps } from '@comps/types';

const { Item } = Form;
const { Option } = Select;
const { RangePicker } = DatePicker;

const OutputSummary: FC = () => {
  let [table, setTable] = useState<Table>();
  const [totalRemainQuantity, setTotalRemainQuantity] = useState(0);
  const [totalRemainAmount, setTotalRemainAmount] = useState(0);
  const [totalInvoicedQuantity, setTotalInvoicedQuantity] = useState(0);
  const [totalInvoicedAmount, setTotalInvoicedAmount] = useState(0);
  const [totalQuantity, setTotalQuantity] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [statisticalType, setStatisticalType] = useState('2');

  const initQuery = useMemo<JSONObject>(() => ({
    period:[
      DateUtils.monthFirstDay(), 
      DateUtils.monthLastDay(),
    ],
    statisticalType: '2'
  }), [])

  const currentPeriod = moment();

  const onTableRef = (tableRef: Table) => {
    setTable(tableRef);
    table = tableRef;
  };

  const getValidTableParams = () => {
    const formParams = table?.getQueryPanelParams() ?? {};
    
    const { period } = formParams;

    // if (period) {
    //   formParams.period = DateUtils.momentToStr(period, 'YYYY-MM');
    // }

    return formParams;
  };

  const period = [
    DateUtils.monthFirstDay(), 
    DateUtils.monthLastDay(),
  ];

  const exportReport = () => {
    const formParams = getValidTableParams();
    const ids = table?.getSelectedRowKeys();

    FormUtil.exportExcelFile(
      '/outputReport/exportSummaryReport',
      {
        ...formParams,
        ids,
      }
    );
  };

  const columns: ColumnProps[] = [
    { key: 'largeCategory', title: '物料大类', width: 150, align: 'center' },
    { key: 'mediumCategory', title: '物料中类', width: 150 },
    { key: 'smallCategory', title: '物料小类', width: 150 },
    { key: 'contractNo', title: '合同号', width: 160 },
    { key: 'period', title: '会计期间', width: 150 },
    { key: 'customerName', title: '客户名称', width: 220 },
    { key: 'customerCode', title: '客户编码', width: 150 },
    { key: 'orderQuantity', title: '销售总数量', width: 150, align: 'right' },
    { key: 'amount', title: '销售总金额', width: 150, align: 'right' },
    { key: 'sbuName', title: 'SBU 名称', width: 150, align: 'center' },
    { key: 'sbuCode', title: 'SBU 代码', width: 150 },
    { key: 'orgName', title: '公司名称', width: 220 },
    { key: 'shipmentDate', title: '发货日期', width: 150 },
    { key: 'withoutTaxAmount', title: '不含税金额', width: 150 },
    { key: 'taxAmount', title: '税额', width: 150, align: 'right' },
    { key: 'amount', title: '价税合计', width: 150, align: 'right' },
    { key: 'invoicingQuantity', title: '开票中数量汇总', width: 150, align: 'right' },
    { key: 'invoicingAmount', title: '开票中金额汇总', width: 150, align: 'right' },
    { key: 'orderQuantity', title: '已发货数量汇总', width: 150, align: 'right' },
    { key: 'invoicedQuantity', title: '已开数量汇总', width: 150, align: 'right' },
    { key: 'remainQuantity', title: '未开数量汇总', width: 150, align: 'right' },
    { key: 'amount', title: '已发货含税金额汇总', width: 150, align: 'right' },
    { key: 'invoicedAmount', title: '已开金额汇总', width: 110, align: 'right', fixed: 'right' },
    { key: 'remainAmount', title: '未开金额汇总', width: 110, align: 'right', fixed: 'right' }
  ];

  const setTableSummaryData = ({
    totalRemainQuantity,
    totalRemainAmount,
    totalInvoicedQuantity,
    totalInvoicedAmount,
    totalQuantity,
    totalAmount

  }: JSONObject) => {

    setTotalRemainQuantity(totalRemainQuantity);
    setTotalRemainAmount(totalRemainAmount);
    setTotalInvoicedQuantity(totalInvoicedQuantity);
    setTotalInvoicedAmount(totalInvoicedAmount);
    setTotalQuantity(totalQuantity);
    setTotalAmount(totalAmount);
  };

  const handleStatisticalType = (typeString: string) => {
    setStatisticalType(typeString);
  }

  const tableSummary = () => {
    return (
      <>
        <TableSummaryRow>
          <Col span={4}>
            未开数量汇总: {Math.num2money(totalRemainQuantity)}
          </Col>
          <Col span={4}>
            合计未开金额汇总: {Math.num2money(totalRemainAmount)}
          </Col>
          <Col span={4}>
            已开数量汇总: {Math.num2money(totalInvoicedQuantity)}
          </Col>
          <Col span={4}>
            合计已开金额汇总: {Math.num2money(totalInvoicedAmount)}
          </Col>
          <Col span={4}>
            合计销售总数量: {Math.numThousandth(totalQuantity)}
          </Col>
          <Col span={4}>
            合计销售总金额: {Math.num2money(totalAmount)}
          </Col>
        </TableSummaryRow>
     </> 
    );
  };

  return (
    <Page>
      <Card
        title="销售开票情况汇总表"
        actions={
          <Space>
            <PermissionButton
              permissions='OutputReport.exportSummaryReport'
              type="primary"
              onClick={exportReport}
            >
              导出
            </PermissionButton>
          </Space>
        }
      >
        <Table
          onTableRef={onTableRef}
          columns={columns}
          url="/outputReport/queryoutputSummary"
          queryProps={{
            initialValues: initQuery
            // {
            //   period,
            //   statisticalType: '2'
            // }
          }}
          // dateFormat={{
          //   period: 'YYYY-MM',
          //   shipmentPeriod: 'YYYY-MM',
          // }}
          querySpecialFormat={{ statisticalType }}
          selectedRowKeys={[]}
          summary={tableSummary}
          setTableSummaryData={setTableSummaryData}
          scroll={{ y: window.innerHeight - 300 }}
        >
          <Item name="period">
            <RangePicker 
              placeholder={['会计日期从', '会计日期至']} 
              format="YYYY-MM"
              picker="month"
              style={{ width: '100%' }}
              inputReadOnly
            />
          </Item>
          <Item name="shipmentPeriod">
            <RangePicker 
              placeholder={['发货日期从', '发货日期至']} 
              format="YYYY-MM"
              picker="month"
              style={{ width: '100%' }}
              inputReadOnly
            />
          </Item>
          <Item name="contractNo">
            <Input placeholder="合同号" />
          </Item>
          <Item name="customerCode">
            <Input placeholder="客户编码" />
          </Item>
          <Item name="largeCategory">
            <Input placeholder="物料大类" />
          </Item>
          <Item name="mediumCategory">
            <Input placeholder="物料中类" />
          </Item>
          <Item name="smallCategory">
            <Input placeholder="物料小类" />
          </Item>
          <Item name="sbuIds">
            <SBUSelect/>
          </Item>
          <Item name="sourceSystem">  
            <Select placeholder="单据来源系统" allowClear>
              <Option value="KA">KA</Option>
              <Option value="ERP">ERP</Option>
              <Option value="LINK">LINK</Option>
              <Option value="EXCEL">EXCEL</Option>
            </Select>
          </Item>
          <Item name="statisticalType">  
            <Select placeholder="类别统计" allowClear onChange={handleStatisticalType}>
              <Option value="1">大类</Option>
              <Option value="2">中类</Option>
              <Option value="3">小类</Option>
            </Select>
          </Item>
        </Table>
      </Card>
    </Page>
  )
};

export default OutputSummary;
