import React, { useMemo, Fragment } from 'react';
import { Checkbox, Row, Col } from 'antd';

const { Group } = Checkbox

function InputSpecified(props:any) {
    const { disabled, value, onChange } = props;
    let selected = [];
    if (!disabled) {
        selected = value.split(',').map((v:any) => parseInt(v, 10));
    }
    const onChangeSelected = (v:any) => onChange(v.length === 0 ? '1' : v.sort((a: number, b: number) => a - b).join(','));
 
    const checkList = useMemo(() => {
        const checks = [];
        for (let i = 0; i < 24; i++) {
            checks.push(
                <Col key={i} span={4}>
                    <Checkbox disabled={disabled} value={i}>
                        {i}
                    </Checkbox>
                </Col>,
            );
        }
        return checks;
    }, [disabled]);
 
    return (
        <Fragment>
            指定
            <br />
            <Group style={{ width: '100%' }} value={selected} onChange={onChangeSelected}>
                <Row>{checkList}</Row>
            </Group>
        </Fragment>
    );
}
 
export default InputSpecified;