import React, { FC } from "react";
import { Button, Card } from "@comps";
import { Col, Form, Row, Select } from "antd";
import { Http } from "@utils";

const { Item } = Form
const { Option } = Select;

interface Props {
  tableData: string[],
  close: () => void,
  query?: () => void,
}

const Status:FC<Props> = ({
  tableData,
  close,
  query
}) => {

  const [form] = Form.useForm()

  const handleConfirm = async() => {
    await form.validateFields()
    const { whetherReplace } = form.getFieldsValue()
    await Http.post(`/tax/means/replace/${tableData.toString()}?whetherReplace=${whetherReplace}`)
    close()
    query && query()
  }

  return (
    <Card>
      <Form form={form}>
        <Row>
          当前操作将会把选中记录的“是否被替换”状态更新为：
          <Item name="whetherReplace" rules={[{ required: true, message: "请选择是否被替换" }]}>
            <Select allowClear placeholder="是否被替换" style={{ minWidth: 100 }}>
              <Option value={1}>是</Option>
              <Option value={2}>否</Option>
            </Select>
          </Item>
        </Row>
      </Form>
      <Row style={{ justifyContent: 'center' }}>
        <Col>
          <Button type="primary" onClick={handleConfirm}>确认</Button>
        </Col>
      </Row>
    </Card>
  )
}

export default Status