import React, { FC } from 'react';
import { Space, Row, Col } from 'antd';

import { Card, Button } from '@comps';

interface Props {
  isAllowSubmit: boolean;
  tipContent: string;
  handleNext: () => void;
  onClose: () => void;
}

const ApproveSubmitTips: FC<Props> = ({ 
  isAllowSubmit,
  tipContent,
  handleNext,
  onClose
}) => {

  const _handleNext = () => {
    onClose();
    handleNext();
  };

  return (
    <Card title="提示">
      <Row>
        <Col span={24} style={{  }}>
          <h4 
            style={{ 
              padding: 10, 
              textIndent: '2em' 
            }}
          >
            {tipContent}
          </h4>
        </Col>
      </Row>
      <Row style={{ marginTop: 12 }}>
        <Col 
          span={24} 
          style={{ 
            textAlign: 'center' 
          }}
        > 
          <Space size="large">
            <Button onClick={onClose}>
              确定
            </Button>
            {isAllowSubmit && (
              <Button 
                type="primary"
                onClick={_handleNext}
              >
                下一步
              </Button>
            )}
          </Space>
        </Col>
      </Row>
    </Card>
  );
};

export default ApproveSubmitTips;
