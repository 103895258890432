import React, { FC, useEffect, useState } from 'react';
import { Tree, Row, Col, Form, Input, Space, Upload, Spin, Button } from 'antd';
import { UploadChangeParam } from 'antd/lib/upload';
import { UploadOutlined } from '@ant-design/icons';

import { Page, Card, Table } from '@comps';

import { Http, Env, Context, Message } from '@utils';

import { ColumnProps } from '@comps/types';

const { Item } = Form;

const TaxCode: FC = () => {
  const [treeData, setTreeData] = useState<[]>();
  const [selectedParentId, setSelectedParentId] = useState(-1);

  const [uploading, setUploading] = useState(false);

  let [table, setTable] = useState<Table>();
  const onTableRef = (tableRef: Table) => {
    table = tableRef;
    setTable(tableRef);
  };

  useEffect(() => {
    refreshTree();
  }, []);

  const refreshTree = () => {
    Http.get('/tax/taxCodes/tree')
      .then((data: any) => {
        setTreeData(data.children);
      })
  };

  /**
   * 选中/取消树上节点
   * @param selectedKeys 
   * @param info 
   */
  const handleOnSelect = (selectedKeys: any[], info: any) => {
    const selected: boolean = info.selected;
    const node = info.node;
    const key = node.key;

    setSelectedParentId(selected ? key : -1);
    
    setTimeout(() => table?.refresh(), 200);
  };

  /**
  * 文件上传
  * @param info 
  */
  const onUploadChange = (info: UploadChangeParam) => {
    //const [uploadingStatus, doneStatus, errorStatus] = ['uploading', 'done', 'error']
    const file: any = info.file;
    const status = file.status;

    switch (status) {
      case 'uploading':
        setUploading(true);
        Message.info("正在导入,请耐心等待15-30秒.");
        break;
      case 'error':
        setUploading(false);
        Message.error("导入失败,出现未知错误,请联系运维人员检查");
        break;
      case 'done':
        setUploading(false);

        const statusCode = file.response.code;
        const tips = file.response.msg;

        if (statusCode === '200') {
          Message.info("导入成功");
          refreshTree();
        } else {
          Message.error("导入失败:" + tips);
        }
        break;
    };
  };

  const columns: ColumnProps[] = [
    { key: 'code', title: '编码', width: 120 },
    { key: 'taxCode', title: '税收分类编码', width: 250 },
    { key: 'taxName', title: '名称', width: 200 },
    { key: 'shortName', title: '简称', width: 200 },
    { key: 'rawTaxRate', title: '税率', width: 80 },
    { key: 'description', title: '说明', width: 300 },
    { key: 'keywords', title: '关键字', width: 300 },
    { key: 'taxVersion', title: '版本', width: 80, tag: {}, fixed: 'right' },
  ];

  return (
    <Page >
      <Card title="税收分类编码" actions={
        <Space> 
          <Upload 
            name='file' 
            accept='.xml'
            showUploadList={false}
            action={`${Env.getBaseUrl()}/tax/taxCodes/import`}
            headers={{ authorization: Context.getToken() }}
            onChange={onUploadChange}
          >
            <Spin spinning={uploading}>
              <Button type='primary'>
                <UploadOutlined />重新导入
              </Button>
            </Spin>
          </Upload>
        </Space>}
      >
        <Row>
          <Col span={6}>
            <Tree
              treeData={treeData}
              onSelect={handleOnSelect}
            />
          </Col>
          <Col span={18}>
            <Table 
              url={`/tax/taxCodes?parentCode=${selectedParentId}`} 
              onTableRef={onTableRef}
              columns={columns}
              scroll={{ y: window.innerHeight - 300 }}
            >
              <Item name='key'>
                <Input placeholder="编码/名称/说明/关键字" title="编码/名称/说明/关键字" />
              </Item>
            </Table>
          </Col>
        </Row>
      </Card>
    </Page >
  );
};

export default TaxCode; 