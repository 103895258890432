/**
 * 当前上下文
 */
import { dropByCacheKey, refreshByCacheKey } from 'react-router-cache-route';

import Http from './Http';
import { store } from '../index';
import { history } from '../routes';
import { UserContext, PaneProps } from '@models';

/**
 * Oauth ClientId
 */
const clientId = process.env.REACT_APP_CLIENT_ID;
const clientSecret = process.env.REACT_APP_CLIENT_SECRET;
const spreadJSKey = process.env.REACT_APP_SPREADJS_KEY;

/**
 * 获取 context
 */
const getContext = () => {
  return store?.getState().ctx;
};

/**
 * 获取 token
 */
const getToken = (): string => {
  return getContext()?.token;
};

/**
 * 获取 token
 */
const getCurrentUserName = (): string => {
  return getContext().lastName;
};

/**
 * 是否已登陆
 */
const getIsLogined = (): boolean => {
  return getContext().logined;
};

const getPanes = (): PaneProps[] => {
  return getContext().panes ?? [];
};

const setComponentPanes = (panes: PaneProps[]) => {
  getContext().setComponentPanes(panes);
};

const getTabsActiveKey = (): string => {
  return getContext().tabsActiveKey;
};

const setComponentTabsActiveKey = (tabsActiveKey: string) => {
  getContext().setComponentTabsActiveKey(tabsActiveKey);
};

/**
 * 获取当前已打开的标签页信息
 * @returns
 */
const getTabPanes = () => {
  return getContext().panes;
};

const defaultPane: PaneProps = {
  title: '',
  key: '',
  route: '',
};

/**
 * 根据标签页标题获取对应的 tab 信息
 * @param title 标签页标题
 * @returns tab 信息
 */
const getTabPanesByTitle = (title: string) => {
  const tabPanes: PaneProps[] = getTabPanes();
  const tabPane = tabPanes.find(item => item.title === title)
    ?? defaultPane;

  return tabPane;
};

/**
 * 处理额外的标签页（如：详情页）
 * @param title 页签标题
 * @param route 页签路由
 * @param key 页签 key
 * @param state 路由状态
 */
const handleAdditionalPane = (
  title: string,
  route: string,
  key: string,
  state?: JSONObject,
) => {

  let newPanes: PaneProps[] = [];
  const currentPanes = Context.getPanes();
  const currentPane = {
    title,
    route,
    key,
    state,
  };

  const paneIndex = currentPanes.findIndex(
    pane => pane.title === title
  );

  if (paneIndex === -1) {
    newPanes = [...currentPanes, currentPane];
  } else {
    currentPanes[paneIndex] = currentPane;
    newPanes = currentPanes;
  }

  Context.setComponentPanes(newPanes);
  Context.setComponentTabsActiveKey(key);

  Context.dispatch({
    type: 'ACTION_SET_PANES',
    data: {
      panes: newPanes,
      tabsActiveKey: key
    }
  });

  Context.route(route, state);
};

/**
 * 处理额外的标签页（如：详情页）---重定向
 * @param title 页签标题
 * @param route 页签路由
 * @param key 页签 key
 * @param state 路由状态
 */
const handleAdditionalPaneReplace = (
  title: string,
  route: string,
  key: string,
  state?: JSONObject,
) => {
  let newPanes: PaneProps[] = [];
  const currentPanes = Context.getPanes();
  const currentPane = {
    title,
    route,
    key,
    state,
  };

  const paneIndex = currentPanes.findIndex(
    pane => pane.title === title
  );

  if (paneIndex === -1) {
    newPanes = [...currentPanes, currentPane];
  } else {
    currentPanes[paneIndex] = currentPane;
    newPanes = currentPanes;
  }

  Context.setComponentPanes(newPanes);
  Context.setComponentTabsActiveKey(key);

  Context.dispatch({
    type: 'ACTION_SET_PANES',
    data: {
      panes: newPanes,
      tabsActiveKey: key
    }
  });

  history.replace(route);
};

/**
 * 清除路由缓存
 * @param route 路由
 * @param clearCacheNum 清除缓存次数
 */
const clearCacheKey = (
  route: string,
  clearCacheNum: number = 4,
) => {

  for (let i = 0; i < clearCacheNum; i++) {
    refreshByCacheKey(route);
  }

  for (let i = 0; i < clearCacheNum; i++) {
    dropByCacheKey(route);
  }
};

/**
 * 关闭打开的标签
 * @param title 要关闭的标签标题
 * @param targetTagTitle 要跳转的标签标题
 */
const closeTabByTitle = (
  title: string,
  targetTagTitle?: string,
) => {

  const newPanes = getPanes().filter(item => item.title !== title);
  const closeTab = getTabPanesByTitle(title);

  let activeKey: string = getTabsActiveKey();
  let lastIndex = 0;

  if (targetTagTitle) {
    const targetTag = getTabPanesByTitle(targetTagTitle);

    if (targetTag.key !== '') {
      activeKey = targetTag.key;
      Context.route(targetTag.route);
    }
  } else {
    getPanes().forEach((pane, i: number) => {
      if (pane.key === closeTab.key) {
        lastIndex = i - 1;
      }
    });

    if (newPanes.length && activeKey === closeTab.key) {
      if (lastIndex >= 0) {
        activeKey = newPanes[lastIndex].key;
        Context.route(newPanes[lastIndex].route);
      } else {
        activeKey = newPanes[0].key;
        Context.route(newPanes[0].route);
      }
    }
  }

  setComponentPanes(newPanes);
  setComponentTabsActiveKey(activeKey);

  Context.dispatch({
    type: 'ACTION_SET_PANES',
    data: {
      panes: newPanes,
      tabsActiveKey: activeKey,
    }
  });

  dropByCacheKey(closeTab.route);
};

/**
 * 屏幕宽度
 */
const getScreenWidth = (): number => {
  return document.documentElement.clientWidth
};

/**
 * 屏幕高度
 */
const getScreenHeigh = (): number => {
  return document.documentElement.clientHeight
};

/**
 * 获取 PathVariable
 * @param props
 * @param paramName
 */
const getPathVar = (props: any, paramName?: string) => {
  if (!paramName) {
    paramName = 'id';
  }

  return props.match.params[paramName];
};

const getSpreadJSKey = () => {
  return spreadJSKey;
};

/**
 * 初始化用户上下文
 */
const initUserContext = async () => {
  const user = await Http.get<JSONObject>('/sys/users/self');

  const tabsActiveKey = '/sys/users/profile';
  const initPanes: PaneProps[] = [
    { title: '首页', key: '/sys/users/profile', route: '/sys/users/profile' }
  ];

  const userCtx: UserContext = {
    ...user,
    logined: true,
    token: Context.getToken(),
    fullName: user.lastName,
    email: user.emailAddress,
    panes: initPanes,
    tabsActiveKey,
  };

  // setComponentPanes(initPanes);
  // setComponentTabsActiveKey(tabsActiveKey);

  dispatch({ type: 'ACTION_LOGIN', data: userCtx })
  Context.route(tabsActiveKey);
};

/**
 * 路由
 */
const route = (
  pathname: string,
  state?: JSONObject,
) => {

  history.push(pathname, state);
};

/**
 * dispatch redux action
 * @param action
 */
const dispatch = (action: any) => {
  store.dispatch(action);
};

const Context = {
  clientId,
  clientSecret,
  initUserContext,
  getScreenWidth,
  getScreenHeigh,
  getContext,
  getToken,
  getIsLogined,
  getPathVar,
  getSpreadJSKey,
  getPanes,
  getTabsActiveKey,
  getCurrentUserName,
  setComponentPanes,
  setComponentTabsActiveKey,
  route,
  dispatch,
  handleAdditionalPane,
  getTabPanesByTitle,
  clearCacheKey,
  closeTabByTitle,
  handleAdditionalPaneReplace
};

export default Context;
