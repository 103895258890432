import React, { useState, useEffect, useCallback } from 'react';
import { Tree, Row, Col, Form, Input, Space } from 'antd';

import { Page, Card, Table, Button, Modal, LinkText } from '@comps';
import AddCategory from './addCategory.md';
import AddOrEdit from './addOrEdit.md';

import { Http } from '@utils';
import { ColumnProps } from '@comps/types';

const { Item } = Form;

const Good: React.FC = () => {
  const [selectedParentId, setSelectedParentId] = useState(-1);
  const [treeData, setTreeData] = useState<[]>();

  const [model, modalHolder] = Modal.useModal();

  let [table, setTable] = useState<Table>();
  const onTableRef = (tableRef: Table) => {
    table = tableRef;
    setTable(tableRef);
  };

  const loadTree = useCallback<() => Promise<void>>(async () => {
    const { children } = await Http.get('/tax/goods/categories/tree') as any
    setTreeData(children);
  }, []);

  useEffect(() => {
    ((async () => {
      await loadTree();
    })());
  }, [loadTree]);

  /**
   * 选中/取消树上节点
   * @param selectedKeys
   * @param info
   */
  const handleOnSelect = (_1: any, info: any) => {
    const selected: boolean = info.selected;
    const node = info.node;
    const key = node.key;

    if (selected) {
      setSelectedParentId(key);
    } else {
      setSelectedParentId(-1);
    }

    setTimeout(() => { table?.refresh() }, 200);
  }

  const handleAddCategory = async () => {
    await model.open({
      width: '520px',
      content(resolve, reject) {
        return(
          <AddCategory onConfirm={resolve} onCancel={reject} />
        );
      }
    });

    loadTree();
  }

  const handleAddOrEdit = async (good?: JSONObject) => {
    await model.open({
      width: '860px',
      content(resolve, reject) {
        return(
          <AddOrEdit onConfirm={resolve} onCancel={reject} good={good} />
        )
      }
    });

    table?.refresh();
  }

  const columns: ColumnProps[] = [
    { key: 'goodsName', title: '商品名称', width: 150, ellipsis: true, fixed:'left' },
    { key: 'goodsCode', title: '商品内部编码', width: 120 },
    { key: 'taxName', title: '税收分类', width: 90 },
    { key: 'taxCode', title: '税收分类编码', width: 250 },
    { key: 'spec', title: '规格型号', width: 100 },
    { key: 'unitPrice', title: '单价', width: 100 },
    { key: 'unit', title: '单位', width: 60 },
    { key: 'taxRate', title: '税率(%)', width: 100, tag:{}, fixed:'right' },
    {
      key: 'id',
      title: '操作',
      width: 60,
      fixed: 'right',
      render: (_1: any, good: JSONObject) => (
        <LinkText label='修改' onClick={() => handleAddOrEdit(good)} />
      )
    }
  ];

  return (
    <Page >
      {modalHolder}
      <Card 
        title='商品管理'
        actions={
          <Space>
            <Button type='primary' onClick={handleAddCategory}>新增类别</Button>
            <Button type='primary' onClick={() => { handleAddOrEdit() }}>新增商品</Button>
          </Space>
        }
      >
        <Row>
          <Col span={6}>
            <Tree
              treeData={treeData}
              onSelect={handleOnSelect}
            />
          </Col>
          <Col span={17}>
            <Table 
              url={`/tax/goods?parentId=${selectedParentId}`} 
              onTableRef={onTableRef}
              columns={columns}
              scroll={{ y: window.innerHeight - 300 }}
              >
              <Item name='key' >
                <Input 
                  placeholder="商品名称/商品编码/规格型号" 
                  title="商品名称/商品编码/规格型号" 
                />
              </Item>
            </Table>
          </Col>
        </Row>
      </Card>
    </Page>
  );
};

export default Good;
