import {
  Card as AdCard,
  Cascader,
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Select,
} from "antd";
import * as React from "react";
import { useState } from "react";

interface Props {
  form: any;
  // ref: any;
  //   onClose: Function;
}

const ConstructionService: React.FC<Props> = (props) => {
  const { form } = props;
  const [loading, setLoading] = useState(false);
  const cityData = require("./province-city-china.json");
  const [levelData, setLevalData] = useState(cityData);
  const { RangePicker } = DatePicker;
  const Option = Select.Option;

  return (
    <>
      <AdCard
        title="编辑建筑服务信息"
        loading={loading}
        style={{ marginBottom: "0" }}
      >
        <Form form={form}>
          <Row gutter={16}>
            <Col span={8}>
              <Form.Item
                label="建筑服务发生地"
                name={["businessInfo", "GZFW_FSD"]}
                rules={[{ required: true, message: "请选择建筑服务发生地" }]}
              >
                <Cascader options={levelData} placeholder="请选择" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="发生地详细地址"
                name={["businessInfo", "GZFW_XXDZ"]}
                rules={[{ required: true, message: "请输入发生地详细地址" }]}
              >
                <Input placeholder="请输入发生地详细地址" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="建筑项目名称"
                name={["businessInfo", "GZFW_XMMC"]}
                rules={[{ required: true, message: "请输入建筑项目名称" }]}
              >
                <Input placeholder="请输入建筑项目名称" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="士地增值税项目编号"
                name={["businessInfo", "GZFW_XMBH"]}
                rules={[
                  { required: true, message: "请输入士地增值税项目编号" },
                ]}
              >
                <Input placeholder="请输入士地增值税项目编号" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="跨地（市）标志"
                name={["businessInfo", "GZFW_KSBZ"]}
                rules={[{ required: true, message: "请输入跨地（市）标志" }]}
              >
                <Select placeholder="请选择">
                  <Option value="Y">是</Option>
                  <Option value="N">否</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </AdCard>
    </>
  );
};

export default ConstructionService;
