import React, { FC } from 'react';
import { Space, Row, Col, Button } from 'antd';

import { Card } from '@comps';

interface Props {
  amount: number;
  onClose: () => void;
}

const StatisticsAmount: FC<Props> = ({ 
  amount,
  onClose,
}) => {

  return (
    <Card title="统计勾选金额">
      <Row>
        <Col span={24}>
          <h3>&nbsp;&nbsp;目前勾选的未开票金额为：</h3>
          <h3 style={{ textAlign: 'center' }}>{`${amount}`}</h3>
        </Col>
      </Row>
      <Row style={{ marginTop: 12 }}>
        <Col span={24} style={{ textAlign: 'center' }}> 
          <Space size="large">
            <Button type="primary" onClick={onClose}>确定</Button>
          </Space>
        </Col>
      </Row>
    </Card>
  );
};

export default StatisticsAmount;
