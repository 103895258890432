import React, { FC, useEffect, useMemo, useState, forwardRef, useImperativeHandle } from "react";
import { message, Select } from "antd";
import { cityData } from "./cityData";

const { Option } = Select

interface Props {
  ref: any,
  getCityData: (list: string[]) => void,
}

const CitySelect:FC<Props> = forwardRef(({
  getCityData
}, ref) => {
  const municipality = ['北京市', '天津市', '上海市', '重庆市']
  const getmunicipality = useMemo(() => cityData.filter(city => municipality.includes(city.name)), [])

  const [province, setProvince] = useState<any>()
  const [city, setCity] = useState<any>()
  const [county, setCounty] = useState<any>()
  const [cityList, setCityList] = useState<JSONObject[]>(getmunicipality)
  const [countyList, setCountyList] = useState<string[]>([])

  useEffect(() => {
    getCityData([province, city, county].filter(item => item !== null))
  }, [province, city, county])

  useImperativeHandle(ref, () => ({
    showCity,
    validSelect,
  }))

  const handleProvince = (value: string) => {
    setProvince(value)
    setCity(null)
    setCounty(null)
    if (value) {
      const { city } = cityData.find(city => city.name === value) ?? {}
      setCityList(city ?? [])
    } else {
      setCityList(getmunicipality)
    }
  }

  const handleCity = (value: string) => {
    setCity(value)
    if (municipality.includes(value)) {
      setProvince(null)
    }
    if (value) {
      const { districtAndCounty, city } = cityList.find(city => city.name === value) ?? {}
      setCountyList(districtAndCounty ?? city[0].districtAndCounty)
    } else {
      setCountyList([])
    }
    setCounty(null)
  }

  const handleCounty = (value: string) => {
    setCounty(value)
  }

  const initCounty = (city:JSONObject, county: string) => {
    if (city instanceof Array) {
      for (let i = 0; i < city.length; i++) {
        if (county === city[i].name) {
          setCountyList(city[i].city[0].districtAndCounty)
        }
      }
    } else {
      for (let i = 0; i < city.city.length; i++) {
        const element = city.city[i];
        if (county === element.name) {
          setCountyList(element.districtAndCounty)
        }
      }
    }
  }

  const showCity = (cityList: string[]) => {
    let city_list:any = [], city:any = []
    if (cityList.length > 0) {
      if (cityList.length === 3) {
        setProvince(cityList[0])
        setCity(cityList[1])
        setCounty(cityList[2])

        city = cityList[1]
        city_list = cityData.find(city => city.name === cityList[0]) ?? {city: []}
        setCityList(city_list.city)
      }
      if (cityList.length === 2) {
        if (municipality.includes(cityList[0])) {
          setProvince(null)
          setCity(cityList[0])
          setCounty(cityList[1])

          city = cityList[0]
          city_list = getmunicipality
          setCityList(getmunicipality)
        } else {
          setProvince(cityList[0])
          setCity(cityList[1])
          setCounty(null)

          city = cityList[1]
          city_list = cityData.find(city => city.name === cityList[0]) ?? {city: []}
          setCityList(city_list.city)
        }
      }
      initCounty(city_list, city)
    } else {
      setProvince(null)
      setCity(null)
      setCounty(null)
      setCityList(getmunicipality)
      setCountyList([])
    }
  }

  const validSelect = () => {
    if (province && (!city || (!county && countyList.length > 0))) {
      message.warning("请选择完整的城市地区")
      return false
    }
    if (city && !county && countyList.length > 0) {
      message.warning("请选择完整的城市地区")
      return false
    }
    return true
  }

  return (
    <>
      <Select placeholder='省'
        style={{ minWidth: 100, marginRight: 10 }}
        value={province}
        disabled={municipality.includes(city)}
        allowClear
        showSearch
        onChange={handleProvince}
        filterOption={(input: any, option: any) => option.children && option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
          {cityData.map((item, i) => !municipality.includes(item.name) && (<Option value={item.name} key={i}>{item.name}</Option>))}
      </Select>
      <Select
        style={{ minWidth: 100, marginRight: 10 }}
        value={city}
        placeholder='市'
        showSearch
        allowClear
        onChange={handleCity}
        filterOption={(input: any, option: any) => option.children && option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
          {cityList.map((item, i) => (<Option value={item.name} key={i}>{item.name}</Option>))}
      </Select>
      <Select
        style={{ minWidth: 100 }}
        value={county}
        placeholder='区县'
        showSearch
        allowClear
        onChange={handleCounty}
        filterOption={(input: any, option: any) => option.children && option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
          {countyList.map((item, i) => (<Option value={item} key={i}>{item}</Option>))}
      </Select>
    </>
  )
})

export default CitySelect
