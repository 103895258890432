import React, { useState, useCallback, useMemo, FC } from "react";
import {
  Space,
  Select,
  Input,
  Row,
  Col,
  Form,
  DatePicker,
  Button,
  message,
  Upload,
  Table as AdTable,
  Modal as AdModal,
} from "antd";
import moment from "moment";

import {
  Page,
  Card,
  Table,
  LinkText,
  ViewInvoice,
  Modal,
  PermissionButton,
  Download,
} from "@comps";
import MarkDeduction from "./markDeduction.md";
import Secondary from "./secondary.md";

import { Context, Env, Math, Http, Lang, Message, FormUtil } from "@utils";
import { ColumnProps } from "@comps/types";
import { TableRow, SignStatus } from "./interface";

import "./index.css";

const { Item } = Form;
const { Option } = Select;
const { RangePicker } = DatePicker;
const { confirm } = AdModal;

const Deduction: FC = () => {
  const [period, setPeriod] = useState("");
  const [deductionPeriod, setDeductionPeriod] = useState("");

  const [modal, modalHolder] = Modal.useModal();
  const [markDeductionModal, markDeductionModalHolder] = Modal.useModal();

  let [table, setTable] = useState<Table>();
  const [tableSummaryInfo, setTableSummaryInfo] = useState<JSONObject>({});

  let currentExportType: number = 0;

  const [uploadLoading, setUploadLoading] = useState(false);
  const [markDeductionLoading, setMarkDeductionLoading] = useState(false);
  const [markNoDeductionLoading, setMarkNoDeductionLoading] = useState(false);
  const [unmarkLoading, setUnmarkLoading] = useState(false);

  const initQuery = useMemo<JSONObject>(
    () => ({
      deductionType: "0",
      accountStatus: "1",
    }),
    []
  );

  const onTableRef = (tableRef: Table) => {
    table = tableRef;
    setTable(tableRef);
  };

  const getTableSummaryInfo = async (queryObj: JSONObject) => {
    const summaryRes = await Http.get(
      "/input/biz/makeDeduction/total",
      queryObj
    );
    setTableSummaryInfo(summaryRes);
  };

  const showExportAllConfirm = () => {
    confirm({
      title: "提示",
      content: "是否导出所有数据",
      onOk: handleExportAllData,
    });
  };

  const handleCancleSign = () => {
    handleSign(SignStatus.UNMARK);
  };

  const handleSplicingURL = () => {
    let url = `${Env.getBaseUrl()}/input/biz/makeDeduction/export?accessToken=${Context.getToken()}`;
    url += `&type=${currentExportType}`;

    // 导出时添加查询参数
    const queryParams = table?.getQueryPanelParams();
    for (let key in queryParams) {
      if (key === "period" || key === "deductionPeriod") {
        // 修改所属期格式为 YYYYMM
        const periodStr = moment(queryParams[key]).format("YYYYMM");
        const periodNum = parseInt(periodStr);
        url += `&${key}=${periodNum}`;
      } else {
        url += `&${key}=${queryParams[key]}`;
      }
    }
    return url;
  };

  const handleExportData = () => {
    if (table?.getSelectedRowKeys()?.length === 0) {
      showExportAllConfirm();
      return;
    }

    let URL = handleSplicingURL();
    URL += `&ids=${table?.getSelectedRowKeys()?.join()}`;

    window.open(URL, "_blank");
  };

  const handleExportAllData = () => {
    const URL = handleSplicingURL();
    window.open(URL, "_blank");
  };

  const handleExportTypeChange = (type: any) => {
    currentExportType = type;
    handleExportData();
  };

  const handleViewInvoice = useCallback((picURL: string) => {
    Lang.noError(async () => {
      await modal.open({
        width: "800px",
        ignoreError: false,
        content(resolve, reject) {
          return <ViewInvoice onClose={reject} picUrl={picURL} />;
        },
      });
    });
  }, []);

  const refreshTable = () => {
    table?.refresh();
  };

  const handleMarkDeduction = useCallback((queryData: JSONObject) => {
    Lang.noError(async () => {
      await markDeductionModal.open({
        width: "400px",
        ignoreError: false,
        content(resolve, reject) {
          return (
            <MarkDeduction
              queryData={queryData}
              refreshTable={refreshTable}
              onClose={reject}
            />
          );
        },
      });
    });
  }, []);

  const checkIsAllowCancelSign = (): boolean => {
    const deductionStatus: number[] =
      table?.getSelectedRowAttr("deductionStatus") || [];
    let isAllowCancelSign: boolean = true;

    for (let i = 0; i < deductionStatus.length; i++) {
      if (deductionStatus.includes(0)) {
        isAllowCancelSign = false;
        break;
      }
    }

    return isAllowCancelSign;
  };

  const handleSign = (type: string, period?: number) => {
    const selectedRowIDs = table?.getSelectedRowKeys();
    if (selectedRowIDs?.length === 0) {
      getSecondaryData(type);
      return;
    }

    const params: any = {};
    params.type = type;
    params.ids = selectedRowIDs?.join();

    if (type === SignStatus.MARK_DEDUCTION) {
      handleMarkDeduction(params);
      return;
    } else if (type === SignStatus.MARK_NO_DEDUCTION) {
      setMarkNoDeductionLoading(true);
    } else if (type === SignStatus.UNMARK) {
      if (!checkIsAllowCancelSign()) {
        message.warn("勾选的数据的抵扣状态必须全为已抵扣或不抵扣");
        return;
      }
      setUnmarkLoading(true);
    }

    Http.get("/input/biz/makeDeduction/deduction", params)
      .then((res) => {
        Message.info("操作成功");
        table?.refresh();
      })
      .finally(() => {
        setMarkNoDeductionLoading(false);
        setUnmarkLoading(false);
      });
  };

  const handlePeriodChange = (date: any, dateString: string) => {
    setPeriod(dateString);
  };

  const handleDeductionPeriodChange = (date: any, dateString: string) => {
    setDeductionPeriod(dateString);
  };

  const getDeductionTextByType = (type: string) => {
    if (type === SignStatus.MARK_DEDUCTION) {
      return "标记抵扣";
    } else if (type === SignStatus.MARK_NO_DEDUCTION) {
      return "标记不抵扣";
    } else {
      return "取消标记";
    }
  };

  const getSecondaryData = (type: string) => {
    let formData: any = table?.getQueryPanelParams();

    formData = FormUtil.filterInvalidFormData(formData);
    formData.makeDeductionType = type;

    const checkText = getDeductionTextByType(type);

    Http.get("/input/biz/makeDeduction/total", formData).then((res) => {
      modal.open({
        width: "700px",
        content(_1, reject) {
          return (
            <Secondary
              type={type}
              title={checkText}
              statisticsData={res}
              queryData={formData}
              refreshTable={() => {
                table?.refresh();
              }}
              onClose={reject}
            />
          );
        },
      });
    });
  };

  const columns: ColumnProps[] = [
    { key: "invoiceNo", title: "发票号码/票据号码", width: 200 },
    {
      key: "invoiceType",
      title: "发票类型",
      width: 200,
      render: (type) => {
        type = parseInt(type);
        if (type === 1) {
          return "增值税专用发票";
        } else if (type === 2) {
          return "货运运输业增值税专用发票";
        } else if (type === 3) {
          return "机动车增值税专用发票";
        } else if (type === 4) {
          return "增值税普通发票";
        } else if (type === 10) {
          return "电子普通发票";
        } else if (type === 11) {
          return "卷式发票";
        } else if (type === 14) {
          return "通行费发票";
        } else if (type === 15) {
          return "二手车发票";
        } else if (type === 16) {
          return "成品油发票";
        } else if (type === 17) {
          return "火车票";
        } else if (type === 18) {
          return "客运汽车票";
        } else if (type === 19) {
          return "航空运输电子行程票";
        } else if (type === 20) {
          return "出租车票";
        } else if (type === 21) {
          return "船票";
        } else if (type === 22) {
          return "过桥、过闸通行费";
        } else if (type === 23) {
          return "通用机打发票";
        } else if (type === 24) {
          return "定额发票";
        } else if (type === 99) {
          return "其他";
        } else {
          return "";
        }
      },
    },
    { key: "invoiceDate", title: "日期", width: 80 },
    { key: "amount", title: "金额", width: 140, format: "amount" },
    { key: "taxAmount", title: "税额", format: "amount", width: 140 },
    {
      key: "deductionFlag",
      title: "是否可抵扣",
      width: 120,
      align: "center",
      render: (state) => {
        if (state === 0) {
          return "否";
        } else {
          return "是";
        }
      },
    },
    {
      key: "deductionStatus",
      title: "抵扣状态",
      width: 80,
      render: (state) => {
        if (state === 0) {
          return "未抵扣";
        } else if (state === 1) {
          return "已抵扣";
        } else if (state == 2) {
          return "不抵扣";
        } else {
          return "";
        }
      },
    },
    {
      key: "deductionMoney",
      title: "可抵扣金额",
      width: 160,
      format: "amount",
    },
    {
      key: "deductionTaxMoney",
      title: "可抵扣税额",
      width: 160,
      format: "amount",
    },
    {
      key: "deductionPeriod",
      title: "抵扣所属期",
      width: 160,
      align: "center",
    },
    { key: "voucherPeriod", title: "记账所属期", width: 160, align: "center" },
    { key: "voucherDate", title: "过账日期", width: 120 },
    { key: "uploadDate", title: "上传日期", width: 120 },
    { key: "lastUpdateTime", title: "标记时间", width: 160 },
    { key: "lastName", title: "标记用户", width: 100 },
    { key: "bizNo", title: "业务单据号", width: 200, align: "center" },
    { key: "voucherNo", title: "凭证号", width: 200, align: "center" },
    {
      key: "reimburseStatus",
      title: "报销状态",
      width: 80,
      render: (state) => {
        if (state === 1) {
          return "已报销";
        } else {
          return "未报销";
        }
      },
    },
    {
      key: "accountStatus",
      title: "记账状态",
      width: 100,
      render: (state) => {
        if (state === 1) {
          return "已记账";
        } else {
          return "未记账";
        }
      },
    },
    { key: "documentAccount", title: "记账人", width: 200 },
    {
      key: "id",
      title: "操作",
      width: 160,
      fixed: "right",
      align: "center",
      render(ID, tableRow: TableRow) {
        return (
          <Space>
            <LinkText
              label="查看"
              onClick={() => handleViewInvoice(tableRow.picUrl)}
            />
            {/* <Link to={ getTargetRoute(tableRow) }>详情</Link> */}
          </Space>
        );
      },
    },
  ];

  return (
    <Page>
      {modalHolder}
      {markDeductionModalHolder}

      <Card
        title="可抵扣票据管理"
        actions={
          <Space className="card-space">
            <PermissionButton
              permissions="InputMakeDeduction.deduction"
              type="primary"
              loading={markDeductionLoading}
              onClick={() => {
                handleSign(SignStatus.MARK_DEDUCTION);
              }}
            >
              标记抵扣
            </PermissionButton>
            <PermissionButton
              permissions="InputMakeDeduction.deduction"
              type="primary"
              loading={unmarkLoading}
              onClick={() => {
                handleSign(SignStatus.UNMARK);
              }}
            >
              取消标记
            </PermissionButton>
            <PermissionButton
              permissions="InputMakeDeduction.deduction"
              type="primary"
              loading={markNoDeductionLoading}
              onClick={() => {
                handleSign(SignStatus.MARK_NO_DEDUCTION);
              }}
            >
              标记不可抵扣
            </PermissionButton>
            <Upload
              action={`${Env.getBaseUrl()}/input/biz/makeDeduction/importDeduction`}
              accept=".xlsx,.xls"
              showUploadList={false}
              headers={{
                Authorization: Context.getToken(),
              }}
              onChange={async ({ file: { status, response = {} } }) => {
                setUploadLoading(true);

                if (!["done", "error"].includes(status as string)) {
                  return;
                }

                const { code, msg } = response;

                setUploadLoading(false);

                if (String(code) !== "200") {
                  Message.error(msg || "未知错误, 请联系管理员");
                  return;
                }

                // 导入成功
                Message.success("导入成功");
              }}
            >
              <Button type="primary" loading={uploadLoading}>
                批量导入勾选
              </Button>
            </Upload>
            <Select placeholder="更多操作" onSelect={handleExportTypeChange}>
              <Option value={0}>汇总导出</Option>
              <Option value={1}>明细导出</Option>
            </Select>
          </Space>
        }
      >
        <Table
          url="/input/biz/makeDeduction/list"
          selectedRowKeys={[]}
          queryProps={{
            initialValues: initQuery,
          }}
          querySpecialFormat={{ period, deductionPeriod }}
          pagination={{ hideOnSinglePage: false }}
          columns={columns}
          onTableRef={onTableRef}
          getTableSummaryInfo={getTableSummaryInfo}
          scroll={{ y: window.innerHeight - 300 }}
          summary={(invoices, selectIds) => {
            const selectInvoices = invoices.filter((invoice) =>
              selectIds?.includes(invoice.id)
            );
            const invoiceTotal = selectInvoices.reduce(
              (total, invoice) => {
                total.amount += invoice.amount;
                total.taxAmount += invoice.taxAmount;
                return total;
              },
              {
                amount: 0,
                taxAmount: 0,
              }
            );
            return (
              <>
                <AdTable.Summary.Row style={{ height: 36 }}>
                  <Row
                    gutter={14}
                    style={{
                      position: "absolute",
                      left: "10px",
                      right: "0",
                      zIndex: 100,
                    }}
                  >
                    <Col span={8}>选中数量: {selectInvoices?.length || 0}</Col>
                    <Col span={8}>
                      选中金额: {Math.num2money(invoiceTotal.amount)}
                    </Col>
                    <Col span={8}>
                      选中税额: {Math.num2money(invoiceTotal.taxAmount)}
                    </Col>
                  </Row>
                </AdTable.Summary.Row>

                <AdTable.Summary.Row style={{ height: 36 }}>
                  <Row
                    gutter={14}
                    style={{
                      position: "absolute",
                      left: "10px",
                      right: "0",
                      zIndex: 100,
                    }}
                  >
                    <Col span={8}>全部数量: {tableSummaryInfo.nums || 0}</Col>
                    <Col span={8}>
                      全部金额: {Math.num2money(tableSummaryInfo.sumAmount)}
                    </Col>
                    <Col span={8}>
                      全部税额: {Math.num2money(tableSummaryInfo.sumTaxAmount)}
                    </Col>
                  </Row>
                </AdTable.Summary.Row>
              </>
            );
          }}
        >
          <Item name="period" label="记账所属期">
            <DatePicker
              picker="month"
              format="YYYYMM"
              onChange={handlePeriodChange}
              style={{ width: "100%" }}
              inputReadOnly
            />
          </Item>
          <Item name="deductionPeriod" label="抵扣所属期">
            <DatePicker
              picker="month"
              format="YYYYMM"
              onChange={handleDeductionPeriodChange}
              style={{ width: "100%" }}
              inputReadOnly
            />
          </Item>
          <Item name="accountStatus">
            <Select placeholder="记账状态" allowClear>
              <Option value="0">未记账</Option>
              <Option value="1">已记账</Option>
            </Select>
          </Item>
          {/* <Item name="deductionType">
              <Select placeholder="抵扣状态" allowClear>
                <Option value="0">未抵扣</Option>
                <Option value="1">已抵扣</Option>
              </Select>
            </Item> */}
          <Item name="invNo">
            <Input placeholder="发票号码" />
          </Item>
          <Item name="invType">
            <Select placeholder="发票类型">
              <Option value="10">增值税电子普通发票</Option>
              <Option value="17">火车票</Option>
              <Option value="21">船票</Option>
              <Option value="18">客运汽车票</Option>
              <Option value="19">航空运输电子行程单</Option>
              <Option value="22">过桥闸通行费发票</Option>
            </Select>
          </Item>
          {/* <Item name="documentAccount">
              <Input placeholder="记账人" />
            </Item> */}
          {/* <Item name="bxjzStatus">
              <Select placeholder="智能筛选">
                <Option value="1">报销已完成</Option>
                <Option value="2">报销已记账</Option>
              </Select>
            </Item> */}
          <Item name="voucherDateFromTo">
            <RangePicker
              placeholder={["过账日期起", "过账日期止"]}
              format="YYYYMMDD"
              style={{ width: "100%" }}
              inputReadOnly
            />
          </Item>
          {/* <Item name='createDateFromTo'>
              <RangePicker placeholder={['上传日期起', '上传日期止']} format='YYYYMMDD' style={{ width: '100%' }} inputReadOnly/>
            </Item> */}
        </Table>
      </Card>
    </Page>
  );
};

export default Deduction;
