import React, { FC, useState, memo } from "react";
import { Button, Form, Row, Col } from "antd";

import { Card, Table } from "@comps";
import MergeOrSplitSubTable from "./mergeOrSplitSubTable";

import { Http, Message } from "@utils";
import { ColumnProps } from "@comps/types";
import { OperationType } from "../interface";

interface Props {
  operationType: number;
  dataSource: JSONObject[];
  mergeId: number;
  splitId: number;
  onClose: () => void;
  refreshTable: () => void;
  closeMergeTypeMD: () => void;
  closeSplitTypeMD: () => void;
  closeSplitAmountMD: () => void;
}

interface OperateTypeObjItemProps {
  name: string;
  determineURL: string;
}

interface OperateTypeObjProps {
  [key: number]: OperateTypeObjItemProps;
}

const MergeOrSplitParentTable: FC<Props> = memo(
  ({
    operationType,
    dataSource,
    mergeId,
    splitId,
    refreshTable,
    onClose,
    closeMergeTypeMD,
    closeSplitTypeMD,
    closeSplitAmountMD,
  }) => {
    const isMerge = operationType === OperationType.MERGE;
    const isSplit = operationType === OperationType.SPLIT;
    const parentTableDataSource: JSONObject[] = dataSource;

    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);

    const operateTypeObj: OperateTypeObjProps = {
      [OperationType.MERGE]: {
        name: "合并",
        determineURL: `/tax/orders/confirmMerge/line/${mergeId}`,
      },
      [OperationType.SPLIT]: {
        name: "拆分",
        determineURL: `/tax/orders/confirmSplit/${splitId}`,
      },
    };

    const expandedRowRender_merge = () => {
      return (
        <MergeOrSplitSubTable dataSource={parentTableDataSource[0].lines} />
      );
    };

    const expandedRowRender_split = ({ bizNo }: JSONObject) => {
      const _subTableDataSource: JSONObject =
        parentTableDataSource.find((row: JSONObject) => row.bizNo === bizNo) ??
        {};

      const detailLines: JSONObject[] = _subTableDataSource?.lines ?? [];

      return <MergeOrSplitSubTable dataSource={detailLines} />;
    };

    const closeAllMD = () => {
      onClose();

      if (isMerge) {
        closeMergeTypeMD();
      } else {
        closeSplitAmountMD();
        closeSplitTypeMD();
      }
    };

    const expandedRowRender = isMerge
      ? expandedRowRender_merge
      : expandedRowRender_split;

    const handleSure = () => {
      // 要拆的单据若存在折扣行金额比被折扣行还要大的情况，他们点【确认】时给个提示不让拆分。
      let splitFlag = true; //初始化允许拆分
      if (isSplit) {
        parentTableDataSource.map((data) => {
          const { lines = [] } = data;
          lines.map((line: JSONObject, index: number) => {
            const { withTaxAmount = 0 } = line;
            if (withTaxAmount < 0) {
              let positiveAmount = Math.abs(withTaxAmount);
              if (positiveAmount > lines[index - 1]?.withTaxAmount) {
                splitFlag = false;
              }
            }
          });
        });
      }
      if (!splitFlag) {
        Message.warn("该单据不允许拆分");
      } else {
        setLoading(true);
        Http.get(operateTypeObj[operationType].determineURL)
          .then(() => {
            refreshTable();
            closeAllMD();
            Message.success(operateTypeObj[operationType].name + "成功");
          })
          .finally(() => {
            setLoading(false);
          });
      }
    };

    const columns: ColumnProps[] = [
      { key: "bizNo", title: "单据编号", width: 220, fixed: "left" },
      { key: "buyerName", title: "购方名称", width: 300 },
      {
        key: "invoiceType",
        title: "发票类型",
        width: 130,
        render: (type: number) => {
          if (type === 0) {
            return "增值税专用发票";
          } else if (type === 2) {
            return "增值税普通发票";
          } else if (type === 51) {
            return "增值税电子发票";
          } else {
            return "";
          }
        },
      },
      { key: "lineTotalQuantity", title: "总数量", width: 130 },
      {
        key: "invoiceAmount",
        title: "不含税金额",
        width: 130,
        format: "amount",
      },
      { key: "taxAmount", title: "税额", width: 120, format: "amount" },
      { key: "totalAmount", title: "价税合计", width: 120, format: "amount" },
    ];

    return (
      <Card title={operateTypeObj[operationType].name + "预览"}>
        <Table
          columns={columns}
          dataSource={parentTableDataSource}
          expandable={{
            expandedRowRender,
            defaultExpandAllRows: true,
          }}
          pagination={false}
          scroll={{ y: window.innerHeight - 230 }}
        ></Table>
        <Form form={form} onFinish={handleSure}>
          <Row>
            <Col span={24} style={{ marginTop: 20, textAlign: "center" }}>
              <Button
                type="primary"
                loading={loading}
                style={{ marginRight: 30 }}
                htmlType="submit"
              >
                确认
              </Button>
              <Button onClick={onClose}>取消</Button>
            </Col>
          </Row>
        </Form>
      </Card>
    );
  }
);

export default MergeOrSplitParentTable;
