import React, { useState } from 'react';
import { Card } from '@comps';
import { Space, Button } from 'antd';
import { Http, Message } from '@utils';

export interface Props {
  orderId: string,
  invoiceIds: number[],
  tableRefresh: () => void,
  onClose: () => void
}

const DeleteInvoices: React.FC<Props> = ({ orderId, invoiceIds, tableRefresh, onClose }: Props) => {
  const [loading, setLoading] = useState<boolean>(false)

  const handleDeleteInvoice = async () => {
    setLoading(true)

    Http.del('/tax/mailingManagement/delInvoices', { orderId, invoiceIds })
      .then(() => {
        Message.success('删除成功')
        tableRefresh()
      })
      .finally(() => {
        onClose()
        setLoading(false)
      })
  }

  return (
    <Card title="删除发票">
      <h3 style={{ marginTop: 24, marginBottom: 24 }}>是否确认删除发票 ?</h3>
      <Space size="large">
        <Button loading={loading} type="primary" onClick={handleDeleteInvoice}>确定</Button>
        <Button onClick={onClose}>取消</Button>
      </Space>
    </Card >)
}

export default DeleteInvoices
