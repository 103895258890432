import React, { Fragment, FC, useState, ReactNode, memo } from "react";
import { Button, Form, Space } from "antd";

import "./index.css";

const { Item } = Form;

interface Props {
  children: React.ReactNode;
  isExpandAllItem: boolean;
  onReset: () => void;
  elementSizeOfRow: number;
}

interface IState {
  // 是否展开所有查询条件
  showAll: boolean;
}

/**
 * 表格查询面板
 * 1.将所有查询条件自动拆分为多行排列
 * 2.自动加上 [查询、重置、更多查询、收起查询]按钮
 */
const Query: FC<Props> = memo(
  ({ children, isExpandAllItem, onReset, elementSizeOfRow }: Props) => {
    const [state, setState] = useState<IState>({ showAll: isExpandAllItem });
    const { showAll } = state;

    // 记录整行显示 col24 的个数
    let col24Count = 1;

    // 切换[更多查询/收起查询]
    const switchShowMore = () => {
      setState({
        showAll: !state.showAll,
      });
    };

    // 查询面板中子元素, 当只有一个子元素时children为当前元素, 多个子元素时children为数组
    let childrenArr: ReactNode[] = [];

    if (children instanceof Array) {
      childrenArr = children;
    } else {
      childrenArr.push(children);
    }

    // 每行的元素个数
    // const ELEMENT_SIZE_OF_ROW: number = 5;
    const ELEMENT_SIZE_OF_ROW: number = elementSizeOfRow;

    // 是否展示[更多查询]按钮
    const childSize = childrenArr.length;

    const showMoreBtn = childSize >= ELEMENT_SIZE_OF_ROW;

    // 将所有查询条件组合为二维数组, 根据 [更多查询] 进行渲染
    const childs: React.ReactNode[][] = [];

    // 查询重置按钮
    const queryResetBTNs = (
      <div style={{ textAlign: "right" }}>
        <Space>
          {showMoreBtn ? (
            <Button key="btn-show-more" onClick={switchShowMore}>
              {showAll ? "收起" : "展开"}
            </Button>
          ) : (
            <Button></Button>
          )}
          <Button key="btn-reset" onClick={onReset}>
            重置
          </Button>
          <Button key="btn-query" type="primary" htmlType="submit">
            查询
          </Button>
        </Space>
      </div>
    );

    const newChildrenArr: ReactNode[] = [];
    childrenArr.forEach((element: any) => {
      newChildrenArr.push(element);
    });

    // 将查询重置按钮放在第一行
    newChildrenArr.splice(elementSizeOfRow - 1, 0, queryResetBTNs);

    newChildrenArr.forEach((element: any, index) => {
      // 判断当前元素属于查询面板中第几行第几个位置
      const idx = parseInt(index / ELEMENT_SIZE_OF_ROW + "");

      // 初始化每一行的元素存储容器
      if (!childs[idx]) {
        childs[idx] = [];
      }

      if (!showAll) {
        if (idx === 0) {
          childs[idx].push(element);
        }
      } else {
        if (element.props.className === "col-24") {
          childs[idx + col24Count] = [];
          childs[idx + col24Count].push(element);
          col24Count++;
        } else {
          childs[idx].push(element);
        }
      }
    });

    // 最后一行如果不够 5 个，补满
    const lastRow: ReactNode[] = childs[childs.length - 1];
    const lastRowItemsNum = lastRow.length;
    if (lastRowItemsNum < 5) {
      for (let i = 1; i <= 5 - lastRowItemsNum; i++) {
        lastRow.push(<div></div>);
      }
    }

    return (
      <Fragment>
        {childs.map((rowElements: React.ReactNode[], rowIdx: number) => (
          <div key={`table-query-row-${rowIdx}`} className="table-item-row">
            {rowElements.map((element: any, colIdx: number) =>
              element.props.className === "col-24" ? (
                <div key={`table-query-col-${colIdx}`}>
                  <Item
                    noStyle
                    key={`${rowIdx}-${colIdx}`}
                    name={element.props.name}
                  >
                    {element}
                  </Item>
                </div>
              ) : (
                <div key={`table-query-col-${colIdx}`}>
                  <Item
                    noStyle
                    key={`${rowIdx}-${colIdx}`}
                    name={element.props.name}
                  >
                    {element}
                  </Item>
                </div>
              )
            )}
          </div>
        ))}
      </Fragment>
    );
  }
);

export default Query;
