import React, { useState, useRef, FC} from 'react';
import { Space, Form, Input, Upload, Row } from 'antd';

import { Page, Card, Button, Table, Modal, Lookup, Download, PermissionButton } from '@comps';
import AddOrEditMD from './addOrEdit.md'
import DeleteMD from './delete.md';

import { Message, Env, Context } from '@utils';
import { ColumnProps } from '@comps/types';

const { Item } = Form;

const GoodsMapping: FC = () => {
  const codeTextRef: any = useRef<HTMLSpanElement>();

  const [modal, modalHoder] = Modal.useModal();
 
  const [uploadLoading, setUploadLoading] = useState(false);

  let [table, setTable] = useState<Table>();
  const onTableRef = (tableRef: Table) => {
    setTable(tableRef);
    table = tableRef;
  }

  const handleDelete = () => {
    const IDs: number[] = table?.getSelectedRowKeys() || [];

    if (IDs?.length === 0) {
      Message.warn('请至少勾选一条数据');
      return;
    };

    const refreshTable = () => {
      table?.refresh();
    };

    modal.open({
      width: '300px',
      content(_1, reject) {
        return (<DeleteMD IDs={IDs} refreshTable={refreshTable} onClose={reject} />)
      }
    });
  };

  const handleAddOrEdit = async (rowObj: JSONObject, isEdit: boolean) => {
    await modal.open({
      width: 1000,
      content(resolve, reject) {
        return (
          <AddOrEditMD 
            isEdit={isEdit} 
            formData={rowObj}
            onResolve={resolve}
            onClose={reject} 
          />
        )
      }
    });

    table?.refresh();
  };

  const handleGoodsTypeChange = (type: any) => {
    codeTextRef.current.innerHTML = 
      type === 'P' 
        ? '产品编号'
        : 'MES 订单识别码';
  };

  const columns: ColumnProps[] = [
    { key: 'code', title: <span ref={codeTextRef}>产品编号</span>, width: 180, fixed: 'left' },
    { key: 'productDesc', title: '产品描述', width: 200 },
    { key: 'goodsName', title: '开票商品名称', width: 220 },
    { key: 'taxCode', title: '税收分类编码', width: 220 },
    { key: 'taxShortName', title: '税收分类编码简称', width: 220 },
    { key: 'invoiceGoodsName', title: '开票合并名称', width: 220 },
    { key: 'lossRate', title: '损耗率', width: 120, tag: {} },
    { key: 'taxRate', title: '税率（%）', width: 120, tag: {},
      // render(taxRate: number) {
      //   return <Tag>{taxRate}</Tag>
      // }
    },
    {
      key: 'id', title: '操作', width: 130, fixed:'right', align: 'center',
      render (id, row) {
        return (
          <Space>
            <Button type="link" onClick={() => {handleAddOrEdit(row, true)}}>
              修改
            </Button>
          </Space>
        )
      }
    }
  ];

  return (
    <Page>
      {modalHoder}
      <Card title="商品税收分类映射管理" actions={(
        <Space className="card-space">
          <Download 
            label="下载模板"
            type="button" 
            url="/tax/goodsMapping/download" 
          />
          <Download 
            label="导出"
            type="button"
            url="/tax/goodsMapping/export" 
          />
          <Upload 
            accept=".xlsx,.xls"
            action={`${Env.getBaseUrl()}/tax/goodsMapping/importFromExcel`}
            showUploadList={false}
            headers={{
              Authorization: Context.getToken()
            }}
            onChange={async ({ file: { status, response = {} } }) => {
              setUploadLoading(true);

              if (!['done', 'error'].includes(status as string)) {
                return;
              }

              const { data = {}, code, msg } = response;
            
              if (String(code) !== '200') {
                Message.error(msg || '未知错误, 请联系管理员');
                setUploadLoading(false);
                return;
              }

              const { 
                errorFileUrl, 
                errorSize, 
                hasError,
                successSize, 
                total 
              } = data;
              
              setUploadLoading(false);

              // 导入成功
              if (!hasError) {
                await modal.open({
                  width: 400,
                  content(_1, reject) {
                    return (
                      <Card title="导入成功" style={{ marginBottom: '0' }}>
                        <Space direction="vertical" style={{ width: '100%' }}>
                          <Row justify="center">
                            {`成功导入 ${successSize} 条`}
                          </Row>
                          <Row justify="center">
                            <Button onClick={() => reject()}>确定</Button>
                          </Row>
                        </Space>
                      </Card>
                    );
                  },
                });
                return;
              }

              // 导入失败
              await modal.open({
                width: 400,
                content(_1, reject) {
                  return (
                    <Card title="导入失败" style={{ marginBottom: '0' }}>
                      <Space direction="vertical" style={{ width: '100%' }}>
                        <Row justify="center">
                          {`总共 ${total} 条，导入失败 ${errorSize} 条。`}
                        </Row>
                        <Row justify="center">
                          <Download url={errorFileUrl} label='点击下载失败的导入记录'/>
                        </Row>
                        <Row justify="center">
                          <Button onClick={() => reject()}>关闭</Button>
                        </Row>
                      </Space>
                    </Card>
                  );
                }
              });
            }}
          >
            <PermissionButton permissions='TaxGoodsMapping.excelImport' type="primary" loading={uploadLoading}>Excel 导入</PermissionButton>
          </Upload>
          <PermissionButton permissions='TaxGoodsMapping.create' type="primary" onClick={() => {handleAddOrEdit({}, false)}}>新增</PermissionButton>
          <PermissionButton permissions='TaxGoodsMapping.delTaxGoodsMapping' onClick={handleDelete}>删除</PermissionButton>
        </Space>
      )}>
        <Table 
          url="/tax/goodsMapping"
          onTableRef={onTableRef}
          selectedRowKeys={[]}
          columns={columns}
          scroll={{ y: window.innerHeight - 300 }}
        >
          <Item name="goodsType">
            <Lookup 
              placeholder="商品类别" 
              lookupType="TAX_GOODS_TYPE" 
              showSearch={false} 
              onChange={handleGoodsTypeChange}
            />
          </Item> 
          <Item name="key">
            <Input placeholder="产品编码/橱衣柜订单识别码" />
          </Item>
        </Table>
      </Card>
    </Page>
  );
};

export default GoodsMapping;