import { Col, DatePicker, Form, Input, Row, Space } from "antd";
import React, { FC, useEffect, useState } from "react";

import { Button, Card, DisplayInput, Lookup } from "@comps";
import { Context, DateUtils, FormUtil, Http, Message } from "@utils";

const { Item } = Form;

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

interface Props {
  billInfo: JSONObject;
  onResolve: () => void;
  onClose: () => void;
}

const MarkInvoicingMD: FC<Props> = ({ billInfo, onResolve, onClose }) => {
  const { id, bizNo, invoiceType } = billInfo;
  const [form] = Form.useForm();
  const [markLoading, setMarkLoading] = useState(false);
  const { userName } = Context.getContext();

  const handleMarkInvoicing = () => {
    const invCode = form.getFieldValue("invCode");
    if (invCode) {
      const invCodeLength = invCode.length;
      if (invCodeLength !== 10 && invCodeLength !== 12) {
        Message.warn("发票代码长度只能是 10 / 12 位");
        return;
      }
    }

    const invNoLength = form.getFieldValue("invNo").length;
    if (invNoLength !== 8 && invNoLength !== 20) {
      Message.warn("发票号码长度只能是 8 / 20 位");
      return;
    }

    setMarkLoading(true);

    const formData: JSONObject = FormUtil.filterInvalidFormData(
      form.getFieldsValue()
    );
    formData.invDate = DateUtils.momentToNum(formData.invDate, "YYYYMMDD");
    formData.invType = +formData.invType;

    Http.post("/tax/orders/markInvoiced", formData)
      .then(() => {
        Message.success("标记成功");
        onResolve();
      })
      .finally(() => {
        setMarkLoading(false);
        onClose();
      });
  };

  useEffect(() => {
    form.setFieldsValue({
      bizNo,
      invId: id,
      invType: invoiceType + "",
      invoicer: userName,
    });
  }, []);

  return (
    <Card title="标记为已开票">
      <Form {...layout} form={form} onFinish={handleMarkInvoicing}>
        <Row>
          <Col span="12">
            <Item
              name="bizNo"
              label={<label style={{ color: "#f00" }}>单据编号</label>}
            >
              <DisplayInput />
            </Item>
          </Col>
          <Col span="12">
            <Item name="invId">
              <Input style={{ display: "none" }} />
            </Item>
          </Col>
        </Row>
        <Row>
          <Col span="12">
            <Item name="invType" label="开票类型" rules={[{ required: true }]}>
              <Lookup lookupType="TAX_INVOICE_TYPE" />
            </Item>
          </Col>
          <Col span="12">
            <Item name="invDate" label="开票日期" rules={[{ required: true }]}>
              <DatePicker
                picker="date"
                format="YYYYMMDD"
                style={{ width: "100%" }}
                inputReadOnly
              />
            </Item>
          </Col>
        </Row>
        <Row>
          <Col span="12">
            <Item name="invCode" label="发票代码" rules={[{ required: false }]}>
              <Input minLength={10} maxLength={12} autoComplete="off" />
            </Item>
          </Col>
          <Col span="12">
            <Item name="invNo" label="发票号码" rules={[{ required: true }]}>
              <Input minLength={8} maxLength={20} autoComplete="off" />
            </Item>
          </Col>
        </Row>
        <Row>
          <Col span="12">
            <Item name="invoicer" label="开票人" rules={[{ required: false }]}>
              <DisplayInput />
            </Item>
          </Col>
        </Row>
        <Row>
          <Col
            span="24"
            style={{ marginTop: 20, marginBottom: 10, textAlign: "center" }}
          >
            <Space size="large">
              <Button type="primary" htmlType="submit" loading={markLoading}>
                确认
              </Button>
              <Button onClick={() => onClose()}>取消</Button>
            </Space>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};

export default MarkInvoicingMD;
