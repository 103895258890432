export enum TableType {
  /**
   * 明细表
   */
  DETAIL,
  /**
   * 计划表
   */
  PLAN,
  /**
   * 附件表
   */
  ANNEX
};

export enum ContractSourceType {
  /** 手工录入 */
  MANUAL = 'BYHAND',
  /** Excel 录入 */
  EXCEL = 'EXCEL'
};