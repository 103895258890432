/**
 * 验证工具
 */

/** Form 默认提示信息 */
const formValidateMsg = {
    // eslint-disable-next-line
    required: '请输入 ${label}',
}

const Validation = {
    formValidateMsg,
}

export default Validation