import React, { FC, Fragment, useState, useImperativeHandle, forwardRef, useEffect } from 'react';
import { Space, Row, Button, Upload as AdUpload, Col, message } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import { Card, Download, Modal, PermissionButton } from '@comps';

import { Env, Context, Message } from '@utils';
import { RcFile } from 'antd/lib/upload';
import { ButtonProps } from 'antd/es/button';

const { Dragger } = AdUpload;

interface UploadProps extends ButtonProps {
  url: string,
  label: string,
  accept?: string,
  isExcel?: boolean,
  // 按钮权限
  btnPermission?: string,
  isShowModal?: boolean,
  onFinish?: (data: JSONObject) => void,
}

interface DragProps {
  width: string | number,
  ref: any,
  maxCount?: number,
  accept?: string,
  multiple?: boolean,
  isShowModal?: boolean,
  handleOk: () => void,
  handleCancel?: () => void,
  // onChange?:(data: JSONObject) => void,
  // onDrop?:(data: JSONObject) => void
}

interface DisplayProps {
  ref: any,
  text: string,
  defaultFileList?: JSONObject[],
  maxCount?: number,
  accept?: string,
  multiple?: boolean,
  isShowModal?: boolean,
  props?: any,
  handleDelete: (file: any) => void,
}

const Upload: FC<UploadProps> = ({
  url,
  label,
  accept = '.xlsx,.xls',
  isExcel = true,
  btnPermission,
  isShowModal = false,
  onFinish,
  ...otherProps
}) => {
  const [modal, modalHolder] = Modal.useModal();
  const [uploading, setUploading] = useState(false);

  const beforeUpload = (
    file: RcFile,
    FileList: RcFile[]
  ) => {

    setUploading(true);

    if (isExcel) {
      Message.info('正在处理Excel文件,请耐心等待...');
    } else {
      Message.info('文件上传中,请耐心等待...');
    }

    return true;
  };

  return (
    <Fragment>
      {modalHolder}
      <AdUpload
        action={`${Env.getBaseUrl()}${url}`}
        headers={{ Authorization: Context.getToken() }}
        accept={accept}
        showUploadList={false}
        beforeUpload={beforeUpload}
        onChange={async ({ file: { status, response = {} } }) => {
          console.log(status)
          if (!['done', 'error'].includes(status as string)) {
            return;
          }

          setUploading(false);

          const { data = {}, code, msg } = response;
          if (String(code) !== '200') {
            Message.error(msg || '上传文件发生异常,请联系管理员排查.');
            return;
          }

          Message.success('导入成功');

          if (isExcel) {
            const { errorFileUrl, errorSize, hasError, successSize, total } = data;
            if (isShowModal) {
              await modal.open({
                width: '600px',
                content(_1, reject) {
                  return (
                    <Card
                      title="导入结果"
                      style={{ marginBottom: '0' }}
                    >
                      <Space
                        direction="vertical"
                        style={{ width: '100%' }}
                      >
                        <Row justify="center">
                          {`共 ${total} 条数据, 成功导入 ${successSize} 条, 失败 ${errorSize} 条`}
                        </Row>
                        {hasError && (
                          <Row justify="center">
                            <Download url={errorFileUrl} label='点击下载失败的导入记录' />
                          </Row>
                        )}
                        <Row justify="center">
                          <Button onClick={() => { reject(); }}>关闭</Button>
                        </Row>
                      </Space>
                    </Card>
                  );
                }
              });
            }

            if (onFinish) {
              onFinish(data);
            }
          }
          return;
        }}
      >
        {btnPermission
          ? (<PermissionButton
            {...otherProps}
            permissions={btnPermission}
            loading={uploading}
            type='primary'
          >
            {label}
          </PermissionButton>)
          : (<Button
            {...otherProps}
            loading={uploading}
            type='primary'
          >
            {label}
          </Button>)
        }
      </AdUpload>
    </Fragment>
  );
};

const DragUpload: FC<DragProps> = forwardRef(({
  width,
  maxCount = 1,
  accept = '.xlsx,.xls',
  multiple = false,
  isShowModal = false,
  handleOk,
  handleCancel,
  children
}, ref) => {

  const [fileList, setFileList] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!isShowModal) {
      setFileList([])
    }
  }, [isShowModal])

  useImperativeHandle(ref, () => ({
    fileList,
    setLoading,
  }))

  const onChange = (info: JSONObject) => {
    const { status } = info.file;
    if (status !== 'uploading') {
      console.log(info.file, info.fileList);
    }
    if (status === 'done') {
      Message.success(`${info.file.name}上传成功`);
    } else if (status === 'error') {
      Message.error(`${info.file.name}上传失败`);
    }
  }

  const beforeUpload = (file: JSONObject) => {
    const newFileList = [...fileList, file]
    if (newFileList.length > maxCount) {
      message.warning(`单次上传文件数量不可超过${maxCount}个`)
      return false
    }
    setFileList([...fileList, file])
    return false
  }

  const onRemove = (file: JSONObject) => {
    const index = fileList.indexOf(file);
    const newFileList = fileList.slice();
    newFileList.splice(index, 1);
    setFileList(newFileList);
  }

  const onDrop = (e: JSONObject) => {
    console.log('Dropped files', e.dataTransfer.files);
  }

  return (
    <Modal title="文件导入" visible={isShowModal} onOk={handleOk} onCancel={handleCancel} width={width}
      confirmLoading={loading}>
      <div style={{ display: 'flex' }}>
        <Dragger
          // action={`${Env.getBaseUrl()}${url}`}
          // headers={{ Authorization: Context.getToken() }}
          fileList={fileList}
          accept={accept}
          multiple={multiple}
          // onChange={onChange}
          beforeUpload={beforeUpload}
          onRemove={onRemove}
          style={{ width: 400 }}>
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">将文件拖到此处，或点击上传</p>
          <p className="ant-upload-hint">
            只能上传xls/xlsx文件
          </p>
        </Dragger>
        <Fragment>
          {children}
        </Fragment>
      </div>
    </Modal>
  )
})

const DisplayUpload: FC<DisplayProps> = forwardRef(({
  text,
  maxCount = 1,
  defaultFileList = [],
  accept = '.xlsx,.xls',
  multiple = false,
  props,
  handleDelete,
}, ref) => {
  const [fileList, setFileList] = useState<any[]>([]);


  useImperativeHandle(ref, () => ({
    fileList,
    setFileList,
  }))


  const beforeUpload = (file: JSONObject) => {
    const newFileList = [...fileList, file]
    if (!multiple && newFileList.length > maxCount) {
      message.warning(`单次上传文件数量不可超过${maxCount}个`)
      return false
    }
    setFileList([...fileList, file])
    return false
  }

  const onRemove = async (file: JSONObject) => {
    await handleDelete(file)
    const index = fileList.indexOf(file);
    const newFileList = fileList.slice();
    newFileList.splice(index, 1);
    await setFileList(newFileList);
  }


  return (
    <AdUpload
      {...props}
      fileList={fileList}
      multiple={multiple}
      accept={accept}
      beforeUpload={beforeUpload}
      onRemove={onRemove}
      defaultFileList={defaultFileList}
    >
      <Button type="primary">{text}</Button>
    </AdUpload>
  )
})

export { Upload, DragUpload, DisplayUpload };
