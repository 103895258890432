import React, { FC, useEffect, useState, Fragment } from "react";
import { Form, Row, Col, Input, Button } from "antd";
import {
  Card,
  Page,
  DisplayInput,
  Invoice,
  FullInvoice,
  PermissionButton,
} from "@comps";
import { Http, Context, Message } from "@utils";

const Item = Form.Item;
interface Props {
  onClose: Function;
  request: JSONObject;
}
/**
 * 发票详情
 */
const InvoiceDetail: FC<Props> = ({ onClose, request }) => {
  //   const requestNo = Context.getPathVar(props, "requestNo");
  const [loading, setLoading] = useState(false);
  const [invForm] = Form.useForm();
  const [deliveryForm] = Form.useForm();
  const [invoice, setInvoice] = useState<any>();
  const [pdfUrl, setPdfUrl] = useState();
  const [title, setTitle] = useState("");
  const [allowReopen, setAllowReopen] = useState(false);
  const [allowSyncStatus, setAllowSyncStatus] = useState(false);
  const [allowPrint, setAllowPrint] = useState(false);
  const [allowDelete, setAllowDelete] = useState(false);
  const [allowUpdateStatus, setAllowUpdateStatus] = useState(false);
  const [electronicInvoice, setElectronicInvoice] = useState(false);
  const [allowUpdateIsChangeStatus, setAllowUpdateIsChangeStatus] =
    useState(false);
  const [allowCallbackSap, setAllowCallbackSap] = useState(false);

  const fullList = [31, 32];

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    fetch();
  }, [fetch]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  async function fetch() {
    console.log("request", request);
    request.totalAmount = request.withTaxAmount;
    request.totalInvoiceAmountUpper = request.withTaxAmountUpper;
    request.lines = request.lineList;
    request.invoiceAmount = request.amount;
    console.log("updatarequest", request);
    invForm.setFieldsValue(request);
    setInvoice(request);
    setPdfUrl(request.pdfUrl);
    let cardTitle = "";
    request.fullInvoiceTypeDesc = request.invoiceTypeDesc;
    if (request.invoiceNo) {
      cardTitle = `${request.invoiceTypeDesc} | ${request.invoiceNo} | ${request.statusCodeDesc}`;
    } else {
      cardTitle = `${request.invoiceTypeDesc} | ${request.statusCodeDesc}`;
    }
    console.log(cardTitle);

    setTitle(cardTitle);
    setElectronicInvoice(request.electronicInvoice);
    setAllowReopen(request.failed);
    setAllowPrint(request.allowPrint);
    setAllowSyncStatus(request.allowSyncStatus);
    setAllowDelete(request.allowDelete);
    setAllowUpdateStatus(request.allowUpdateStatus);
    setAllowUpdateIsChangeStatus(request.allowUpdateIsChangeStatus);
    setAllowCallbackSap(request.allowCallbackSap);
  }

  //   const sendMail = () => {
  //     let email = deliveryForm.getFieldValue("receiverMail");
  //     if (email) {
  //       Http.post(
  //         `/tax/outputInvoices/sendMail?requestNo=${requestNo}&email=${email}`
  //       ).then((data) => {
  //         Message.success("发送成功");
  //       });
  //     } else {
  //       Message.warn("请填写客户邮箱");
  //     }
  //   };

  /**
   * 对失败的发票尝试重开
   */
  //   const tryReopen = () => {
  //     setLoading(true);
  //     Http.post(`/tax/outputInvoices/retry?requestNo=${requestNo}`)
  //       .then((data) => {
  //         fetch();
  //         Message.success("重试成功,请检查发票状态...");
  //       })
  //       .finally(() => {
  //         setLoading(false);
  //       });
  //   };

  /**
   * 同步开票中或开票失败发票的状态
   */
  //   const trySyncStatus = () => {
  //     setLoading(true);
  //     Http.get(`/tax/outputInvoices/status?requestNo=${requestNo}`)
  //       .then((data) => {
  //         fetch();
  //         Message.success("发票状态同步成功");
  //       })
  //       .finally(() => {
  //         setLoading(false);
  //       });
  //   };

  /**
   * 打印发票
   */
  //   const print = (printList: boolean) => {
  //     setLoading(true);
  //     Http.get(
  //       `/tax/outputInvoices/print?requestNo=${requestNo}&printList=${printList}`
  //     )
  //       .then((data) => {
  //         Message.success("发票打印中,请耐心等待...");
  //       })
  //       .finally(() => {
  //         setLoading(false);
  //       });
  //   };

  /**
   * 删除发票
   */
  //   const deleteInvoice = () => {
  //     setLoading(true);
  //     Http.del(`/tax/outputInvoices/${requestNo}`)
  //       .then((data) => {
  //         Message.success("删除成功");
  //         Context.route("/tax/outputInvoice");
  //       })
  //       .finally(() => {
  //         setLoading(false);
  //       });
  //   };

  /**
   * 修改为可改票状态
   */
  //   const updateIsChangeStatus = () => {
  //     setLoading(true);
  //     Http.put(`/tax/outputInvoices/${requestNo}/updateIsChangeStatus`)
  //       .then((data) => {
  //         Message.success("修改成功");
  //         fetch();
  //       })
  //       .finally(() => {
  //         setLoading(false);
  //       });
  //   };

  /**
   * 将开票失败的发票设置为待开票
   */
  //   const updateToWaitInvoice = () => {
  //     setLoading(true);
  //     Http.put(`/tax/outputInvoices/${requestNo}/waitInvoice`)
  //       .then((data) => {
  //         Message.success("更新成功");
  //         fetch();
  //       })
  //       .finally(() => {
  //         setLoading(false);
  //       });
  //   };

  // 重新回写SAP
  //   const callbacksap = () => {
  //     setLoading(true);
  //     Http.put(`/tax/outputInvoices/callbackSap?requestNo=${requestNo}`)
  //       .then((data) => {
  //         Message.success("已发起回写请求,无法立马返回结果,请检查回写状态");
  //         fetch();
  //       })
  //       .finally(() => {
  //         setLoading(false);
  //       });
  //   };
  const allowDownloads =
    invoice?.statusCodeDesc !== "待开票" &&
    invoice?.statusCodeDesc !== "开票失败" &&
    invoice?.invoiceType !== 85 &&
    invoice?.invoiceType !== 86;
  return (
    <Page>
      <Card
        style={{ marginTop: "48px" }}
        title={title}
        loading={loading}
        actions={
          <Fragment>
            {pdfUrl && allowDownloads && (
              <a href={pdfUrl} target="_blank" rel="noopener noreferrer">
                下载电子发票
              </a>
            )}
            {allowReopen && (
              <PermissionButton
                permissions="OutputInvoice.retry"
                type="link"
                // onClick={tryReopen}
              >
                重试开票
              </PermissionButton>
            )}
            {allowUpdateStatus && (
              <PermissionButton
                permissions="OutputInvoice.updateToWaitInvoice"
                type="link"
                // onClick={updateToWaitInvoice}
              >
                设置为待开票
              </PermissionButton>
            )}
            {allowSyncStatus && (
              <Button
                type="link"
                //    onClick={trySyncStatus}
              >
                同步开票状态
              </Button>
            )}
            {allowCallbackSap && (
              <PermissionButton
                permissions="OutputInvoice.callbackSap"
                type="link"
                // onClick={callbacksap}
              >
                回写SAP
              </PermissionButton>
            )}
            {allowPrint && (
              <Button
                type="link"
                //    onClick={() => print(false)}
              >
                打印
              </Button>
            )}
            {allowPrint && (
              <Button
                type="link"
                //   onClick={() => print(true)}
              >
                打印清单
              </Button>
            )}
            {allowUpdateIsChangeStatus && (
              <PermissionButton
                permissions="OutputInvoice.updateIsChangeStatus"
                type="link"
                // onClick={updateIsChangeStatus}
              >
                修改为可改票状态
              </PermissionButton>
            )}
            {/* {allowDelete && (
              <PermissionButton
                permissions="OutputInvoice.delete"
                type="link"
                // onClick={deleteInvoice}
              >
                删除发票
              </PermissionButton>
            )} */}
          </Fragment>
        }
      >
        {invoice ? (
          fullList.includes(invoice.invoiceType) ? (
            <FullInvoice invoice={invoice} />
          ) : (
            <Invoice invoice={invoice} />
          )
        ) : undefined}
        <Form form={invForm} layout="vertical" style={{ paddingLeft: "16px" }}>
          <br />

          {/* 开票失败 */}
          {invoice?.failed && (
            <Item name="failureReason" label="开票失败原因">
              <DisplayInput />
            </Item>
          )}

          {/* 已作废 */}
          {invoice?.canceled && (
            <Item name="lastUpdateTime" label="作废时间">
              <DisplayInput />
            </Item>
          )}

          {/* 红字发票 */}
          {invoice?.redFlag && (
            <Row>
              <Col span={4}>
                <Item name="blueInvoiceCode" label="蓝字发票代码">
                  <DisplayInput />
                </Item>
              </Col>
              <Col span={4}>
                <Item name="blueInvoiceNo" label="蓝字发票号码">
                  <DisplayInput />
                </Item>
              </Col>
              <Col span={8}>
                <Item name="redNoticeNo" label="通知单号">
                  <DisplayInput />
                </Item>
              </Col>
            </Row>
          )}
        </Form>
      </Card>
      {electronicInvoice && invoice.success && (
        <Card
          title="发票投递"
          actions={
            <Button
              type="link"
              // onClick={sendMail}
            >
              重新发送邮件
            </Button>
          }
        >
          <Form
            form={deliveryForm}
            labelAlign="right"
            style={{ paddingLeft: "16px" }}
          >
            <Row>
              <Col span={8}>
                <Item name="mailPostStatusDesc" label="邮箱发送状态">
                  <DisplayInput />
                </Item>
              </Col>
              <Col span={8}>
                <Item name="mailPostTime" label="邮箱发送时间">
                  <DisplayInput />
                </Item>
              </Col>
              <Col span={8}>
                <Item name="receiverMail" label="客户邮箱">
                  <Input />
                </Item>
              </Col>
            </Row>
          </Form>
        </Card>
      )}
      <div style={{ textAlign: "right", paddingBottom: "16px" }}>
        <Button onClick={() => onClose()}>关闭</Button>
      </div>
    </Page>
  );
};

export default InvoiceDetail;
