import React from 'react'
import GC from '@grapecity/spread-sheets'
import MathU from './Math'

// 设置Spreadjs
const setSpreadjs = (spread: JSONObject, boxEl: React.ReactNode, isShowHeader: boolean = false) => {
  // 设置 scrollbarMaxAlign 和 scrollbarShowMax 选项来限制滚动的区域。
  spread.options.scrollbarMaxAlign = true
  spread.options.scrollbarShowMax = true
  spread.options.scrollIgnoreHidden = true
  spread.options.grayAreaBackColor = '#FFFFFF'
  // 禁用添加表单按钮
  spread.options.newTabVisible = false
  // spread.options.allowContextMenu = false;
  spread.options.tabEditable = false
  // spread.options.tabNavigationVisible = false;
  spread.options.copyPasteHeaderOptions = false

  // spread.options.allowUserDragDrop = false
  // spread.options.allowUserDragMerge = false
  // spread.options.allowUserDragFill = false
  spread.options.allowContextMenu = false
  // spread.options.showDragFillSmartTag = false
  // 复制单元格的时候只复制值
  spread.options.defaultDragFillType = GC.Spread.Sheets.Fill.AutoFillType.fillWithoutFormatting
  spread.options.allowCopyPasteExcelStyle = false
  spread.options.copyPasteHeaderOptions = GC.Spread.Sheets.CopyPasteHeaderOptions.noHeaders

  for (var i = 0; i < spread.getSheetCount(); i++) {
    // 不显示头部abcd...和左侧 12345....
    spread.getSheet(i).options.rowHeaderVisible = isShowHeader
    spread.getSheet(i).options.colHeaderVisible = isShowHeader
    // 删除网格线：
    spread.getSheet(i).options.gridline.showHorizontalGridline = false
    spread.getSheet(i).options.gridline.showVerticalGridline = false
    spread.getSheet(i).options.clipBoardOptions = GC.Spread.Sheets.ClipboardPasteOptions.values
    // 设置 sheetAreaOffset 属性来设置表单绘制区域相对于左上角的偏移量。这样表单的绘制区域将具有足够的空间来绘制左/上边框和选择框
    // spread.getSheet(i).options.sheetAreaOffset = {left: 10, top: 2}
    // if (false) {
    //   spread.customData = spread.getSheet(i).comments.all()
    //   spread.getSheet(i).comments.clear()
    // }
    // if(spread.getSheet(i).isEditing()){
    spread.getSheet(i).clearSelection()
    // }
    // spread.getSheet(i).bind(GC.Spread.Sheets.Events.RangeChanged, function (sender, args) {
    //   args.changedCells.forEach(function (item) {
    //     // console.log("Cell (" + item.row + ", " + item.col + ") data has been changed. to: "
    //     //   +curSheet.getCell(item.row,item.col).value());
    //     //
    //     if(args.sheet.getCell(item.row,item.col).tag()=="hideGS"){
    //       let value = args.sheet.getCell(item.row,item.col).value();
    //       args.sheet.getCell(item.row,item.col+1).value(value);
    //     }
    //   });
    // });
    // console.log('getColumnCount:' + spread.getSheet(i).getColumnCount(GC.Spread.Sheets.SheetArea.viewport))
    // for(var j =0 ; j < spread.getSheet(i).getColumnCount(GC.Spread.Sheets.SheetArea.viewport) ;j++){
    //   spread.getSheet(i).setColumnWidth(j, "*");
    // }
  }

  if (boxEl) {
    setSpreadjsCanter(spread, boxEl)
    window.onresize = () => {
      return (() => {
        setSpreadjsCanter(spread, boxEl)
      })()
    }
  }
}

// 设置Spreadjs 隐藏公式监听事件
const setSpreadjsHideGSEvent = (spread: any) => {
  
}
// 设置Spreadjs 隐藏公式赋值
const setSpreadjsHideGSEventValue = (spread: JSONObject) => {
  for (var i = 0; i < spread.getSheetCount(); i++) {
    var sheet = spread.getSheet(i)
    var rowCount = sheet.getRowCount()
    var colCount = sheet.getColumnCount()
    for (var j = 0; j < rowCount; j++) {
      for (var k = 0; k < colCount; k++) {
        // 只把没锁的设为锁。locked方法执行有点慢
        var strName = sheet.getRange(j, k).tag()
        if (strName == 'hideGS') {
          sheet.getRange(j, k + 1).value(sheet.getRange(j, k).value())
        }
      }
    }
  }
}

// 解绑设置Spreadjs 隐藏公式监听事件
const unbindSpreadjsHideGSEvent = (spread: JSONObject) => {
  for (var i = 0; i < spread.getSheetCount(); i++) {
    spread.getSheet(i).unbind(GC.Spread.Sheets.Events.RangeChanged)
  }
}

/**
 * 重新渲染excel，主要目的是为了居中显示
 * @param spread
 * @param boxEl
 */
const setSpreadjsCanter = (spread: JSONObject, boxEl: any) => {
  if (!boxEl) {
    return
  }
  for (var i = 0; i < spread.getSheetCount(); i++) {
    const eWidth = boxEl.clientWidt
    // console.log('eWidth')
    // console.log(eWidth)
    const sheet = spread.getSheet(i)
    let colCount = sheet.getColumnCount()
    let colWidth = 0
    for (let i = 0; i < colCount; i++) {
      colWidth += sheet.getColumnWidth(i)
    }
    // console.log('colWidth')
    // console.log(colWidth)
    // 美观并不是绝对对称居中，所以容器宽度边框减10像素，再计算左侧偏移像素，看起来更舒服一些。
    if (eWidth - 10 > colWidth) {
      sheet.options.sheetAreaOffset.left = ((eWidth - colWidth - 10) / 2)
    } else {
      sheet.options.sheetAreaOffset.left = 10
    }
    sheet.options.sheetAreaOffset.top = 5
  }
}
//  给sheet绑定违规数据输入处理规则  初始化表格时this.$invalidHandle(sheet)
const invalidHandle = (sheet: any) => {

}

// 设置Spreadjs 期初数报表可以编辑的单元格
const setSpreadjsQcsEdit = (spread: JSONObject) => {
  for (var i = 0; i < spread.getSheetCount(); i++) {
    var sheet = spread.getSheet(i)
    var rowCount = sheet.getRowCount()
    var colCount = sheet.getColumnCount()
    for (var rowIndex = 0; rowIndex < rowCount; rowIndex++) {
      for (var colIndex = 0; colIndex < colCount; colIndex++) {
        if (sheet.getCell(rowIndex, colIndex).tag() && sheet.getCell(rowIndex, colIndex).tag().indexOf('qcsEdit') > -1) {
          const locked = sheet.getRange(rowIndex, colIndex, 1, 1, GC.Spread.Sheets.SheetArea.viewport).locked()
          if (locked === true) {
            sheet.getRange(rowIndex, colIndex, 1, 1, GC.Spread.Sheets.SheetArea.viewport).locked(false)
            sheet.getRange(rowIndex, colIndex, 1, 1, GC.Spread.Sheets.SheetArea.viewport).backColor('#daeff3')
          }
        } else if (sheet.getCell(rowIndex, colIndex).tag() && sheet.getCell(rowIndex, colIndex).tag().indexOf('qcsDisable') > -1) {
          const locked = sheet.getRange(rowIndex, colIndex, 1, 1, GC.Spread.Sheets.SheetArea.viewport).locked()
          if (locked === false) {
            sheet.getRange(rowIndex, colIndex, 1, 1, GC.Spread.Sheets.SheetArea.viewport).locked(true)
            sheet.getRange(rowIndex, colIndex, 1, 1, GC.Spread.Sheets.SheetArea.viewport).backColor('#ffffff')
          }
        }
      }
    }
  }
}

// 设置sheet的只读
const setSpreadReadOnly = (spread: JSONObject) => {
  console.log('开始设置只读')
  var beginTime: any = new Date()

  for (var i = 0; i < spread.getSheetCount(); i++) {
    var sheetBegin: any = new Date()
    var sheet = spread.getSheet(i)
    var rowCount = sheet.getRowCount()
    var colCount = sheet.getColumnCount()
    for (var j = 0; j < rowCount; j++) {
      for (var k = 0; k < colCount; k++) {
        // 只把没锁的设为锁。locked方法执行有点慢
        var locked = sheet.getRange(j, k, 1, 1, GC.Spread.Sheets.SheetArea.viewport).locked()
        if (locked === false) {
          sheet.getRange(j, k, 1, 1, GC.Spread.Sheets.SheetArea.viewport).locked(true)
        }
      }
    }
    var sheetEnd: any = new Date()
    console.log(i + 'sheet时间：' + (sheetEnd - sheetBegin) + 'ms')
  }

  var endTime: any = new Date()
  console.log('设置只读时间：' + (endTime - beginTime) + 'ms')
}

class SetRound extends GC.Spread.CalcEngine.Functions.Function {
  constructor() {
      super("SETROUND2", 1, 2);
  }
  evaluate (arg: any) {
      console.log(arg, "arg");
      console.log(arguments, "arguments");
      if (!isNaN(arg)) {
        if (arguments.length === 1) {
          return MathU.savePoint(arg, 0)
        }
        return MathU.savePoint(arg, arguments[1]);
      }
      return "#VALUE!";
  }
}

const SpreadJS = {
  setSpreadjs,
  setSpreadjsHideGSEvent,
  setSpreadjsHideGSEventValue,
  unbindSpreadjsHideGSEvent,
  setSpreadjsCanter,
  invalidHandle,
  setSpreadjsQcsEdit,
  setSpreadReadOnly,
  SetRound,
}

export default SpreadJS
