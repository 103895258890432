import  React, { FC, useState, useEffect, useRef, useImperativeHandle, forwardRef } from 'react';
import { useLocation } from 'react-router';
import { Form, Row, Col, Input, Select, DatePicker } from 'antd';
import moment from 'moment';

import { Card, DisplayInput, HiddenFormItem, BackTag, LinkText } from '@comps';

import { Context, Http, FormUtil } from '@utils';
import { LedgerSource } from '../../../common';
import { ApplyType, ApplyTypeVal, ApplyStatus, InvoiceType } from '../../interface';

const { Item } = Form;
const { Option } = Select;
const { TextArea } = Input;

interface Props {
  ref: any;
  applyType: string;
  applyStatus: string;
  formData: JSONObject;
  handleInvoiceTypeChange: (type: any) => void;
}

const ApplyForm: FC<Props> = forwardRef(({
  applyType,
  applyStatus,
  formData,
  handleInvoiceTypeChange,
}, ref) => {

  const [form] = Form.useForm();
  const [isDisabled, setIsDisabled] = useState(false);
  const [isNeW, setIsNew] = useState(false);

  const [SBUOptions, setSBUOptions] = useState<JSONObject[]>([]);
  const [contractInfo, setContractInfo] = useState<JSONObject[]>([]);
  const [contractNumOptions, setContractNumOptions] = useState<any[]>([]);

  const applyStatusRef = useRef('');
  const routerState: any = useLocation().state;

  useEffect(() => {
    getSBUOptions();
    handleApplyType();
  }, []);

  useEffect(() => {
    setApplyFormData(formData);
  }, [formData]);

  useEffect(() => {
    applyStatusRef.current = applyStatus;
    handleItemIsEditable();
    judgeIdentity();
  }, [applyStatus]);

  useImperativeHandle(ref, () =>({
    getApplyFormData,
    setApplyFormData,
    validateApplyFormFields
  }));

  const judgeIdentity = () => {
    if(applyStatus === 'INPROGRESS') {
      setIsDisabled(false);
      setIsNew(true);
    }
    if(applyStatus === 'APPROVED') {
      setIsDisabled(true);
      setIsNew(true);
    }
    if(applyStatus === 'NEW') {
      setIsNew(false);
    }
    if(applyStatus === 'REJECTED') {
      setIsDisabled(false);
    }
  }

  const handleItemIsEditable = () => {
    switch (applyStatusRef.current) {
      case ApplyStatus.INPROGRESS:
      case ApplyStatus.APPROVED:
      case ApplyStatus.REJECTED:
      case ApplyStatus.CANCEL:
        setIsDisabled(true);
        break;

      default:
        setIsDisabled(false);
        break;
    }
  };

  const isSelectDisable = (isDisabled: boolean) => {
    return isDisabled
      ? {
          bordered: false,
          showArrow: false,
          open: false,
        }
      : {};
  };

  const validateApplyFormFields = () => {
    return form.validateFields();
  };

  const getSBUOptions = () => {
    Http.get('/sys/sbu/findSbuByUser')
      .then((res: JSONObject[]) => {
        setSBUOptions(res);
      });
  };

  const setContractNumByContractInfo = (contractInfo: JSONObject[]) => {
    const _contractNumOptions = contractInfo.map(contractItem => ({
      label: `${contractItem.contractNo} ${contractItem.contractName}`,
      value: contractItem.contractNo,
      title: `${contractItem.contractNo} ${contractItem.contractName}`,
    }));

    setContractNumOptions(_contractNumOptions);
  }

  const getContractNum = () => {
    Http.get('/request/queryContract')
      .then(data => {
        setContractInfo(data);
        setContractNumByContractInfo(data);
      })
  }

  const handleApplyType = () => {
    switch (applyType) {
      case ApplyType.ADVANCE:
        getContractNum();
        break;
      case ApplyType.PROGRESS:
        getContractNum();
        break;
      case ApplyType.SETTLE:
        getContractNum();
        break;
      default:
        break;
    }
  }

  const getApplyFormData = () => {
    return form.getFieldsValue();
  };

  const setApplyFormData = (formData: JSONObject) => {
    const { promisePaymentDate } = formData;
    if (promisePaymentDate) {
      formData.promisePaymentDate = moment(promisePaymentDate + '')
    };

    FormUtil.booleanToNum(formData, ['allowMergeFlag', 'allowMinusApply']);

    form.setFieldsValue(formData);
  };

  const handleSBUChange = (val: any) => {
    const currentSelected = SBUOptions.find(item => item.id === val) ?? {};

    form.setFieldsValue({
      sbuCode: currentSelected.code,
      sbuName: currentSelected.name
    });
  };

  const handleContractNumChange = (contractNum: any) => {
    const currentSelected = contractInfo.find(
      contractItem => contractItem.contractNo === contractNum
    ) ?? {};

    form.setFieldsValue({
      contractId: currentSelected.id,
      contractNo: currentSelected.contractNo,
      contractType: currentSelected.contractAttr,
      contractName: currentSelected.contractName,
      sourceSystem: currentSelected.sourceSystem,
      sbuId: currentSelected.sbuId,
      sbuName: currentSelected.sbuName,
      sbuCode: currentSelected.sbuCode
    });
  }

  const handleBack = () => {
    if(routerState.routerFrom === '开票申请') {
      Context.handleAdditionalPane(
        '开票申请',
        '/output/apply',
        '/output/apply',
      );
    }

    if(routerState.routerFrom === '待开单据') {
      Context.handleAdditionalPane(
        '待开单据',
        '/output/wait',
        '/output/wait',
      );
    }

    if(routerState.routerFrom === '发票管理') {
      Context.handleAdditionalPane(
        '发票管理',
        '/output/invoice',
        '/output/invoice',
      );
    }
  };

  const showLedger = () => {
    const contractNo = form.getFieldValue('contractNo');
    const contractName = form.getFieldValue('contractName');
    const id = form.getFieldValue('contractId');
    const state = {
      contractName,
      id
    };

    const route = `/output/contract/ledger/${LedgerSource.APPLY_DETAIL}/${contractNo}`;

    Context.handleAdditionalPane(
      '合同台账',
      route,
      route,
      state
    );
  };

  const RetailItems: FC = () => {
    return (
      <Row>
        <HiddenFormItem name="id" />
        <HiddenFormItem name="sbuName" />
        <Col span={8}>
          <Item label="申请单号" name="requestNo">
            <DisplayInput />
          </Item>
        </Col>
        <Col span={8}>
          <Item label="公司名称" name="orgName">
            <DisplayInput />
          </Item>
        </Col>
        <Col span={8}>
          <Item label="申请时间" name="requestTime">
            <DisplayInput />
          </Item>
        </Col>
        <Col span={8}>
          <Item label="申请人" name="requestorName">
            <DisplayInput />
          </Item>
        </Col>
        <Col span={8}>
          <Item label="状态" name="statusCode">
            <Select {...isSelectDisable(true)}>
              <Option value={ApplyStatus.NEW}>待提交</Option>
              <Option value={ApplyStatus.INPROGRESS}>审批中</Option>
              <Option value={ApplyStatus.WITHDRAW}>已撤回</Option>
              <Option value={ApplyStatus.APPROVED}>审批通过</Option>
              <Option value={ApplyStatus.REJECTED}>审批驳回</Option>
              <Option value={ApplyStatus.CANCEL}>已作废</Option>
            </Select>
          </Item>
        </Col>
        <Col span={8}>
          <Item label="开票申请类型" name="requestType">
            <Select {...isSelectDisable(true)}>
              <Option value={ApplyTypeVal.RETAIL}>零售</Option>
              <Option value={ApplyTypeVal.ADVANCE}>预付款</Option>
              <Option value={ApplyTypeVal.PROGRESS}>进度款</Option>
              <Option value={ApplyTypeVal.SETTLE}>结算款</Option>
              <Option value={ApplyTypeVal.OTHER}>其他</Option>
              <Option value={ApplyTypeVal.MAIN_ASSISTANT}>全国性家装</Option>
            </Select>
          </Item>
        </Col>
        <Col span={8}>
          <Item label="不含税金额" name="amount">
            <DisplayInput />
          </Item>
        </Col>
        <Col span={8}>
          <Item label="税额" name="taxAmount">
            <DisplayInput />
          </Item>
        </Col>
        <Col span={8}>
          <Item label="含税金额" name="withTaxAmount">
            <DisplayInput />
          </Item>
        </Col>

        {(applyType !== ApplyType.MAIN_ASSISTANT) && (
          <>
            <Col span={8}>
              <Item label="折后不含税金额" name="discountAfterAmount">
                <DisplayInput />
              </Item>
            </Col>
             <Col span={8}>
              <Item label="折后税额" name="discountAfterTaxAmount">
                <DisplayInput />
              </Item>
            </Col>
            <Col span={8}>
              <Item label="折后含税金额" name="withTaxDiscountAfterAmount">
                <DisplayInput />
              </Item>
            </Col>
          </>
        )}

        <Col span={8}>
          <Item label="SBU 名称" name="sbuId">
            <Select
              showSearch
              optionFilterProp="key"
              onChange={handleSBUChange}
              options={SBUOptions.map(SBUOption => ({
                key: `${SBUOption.code} ${SBUOption.name}`,
                label: `${SBUOption.name}`,
                value: SBUOption.id,
              }))}
              {...isSelectDisable(isDisabled)}
            />
          </Item>
        </Col>

        {(applyType !== ApplyType.MAIN_ASSISTANT) && (
          <>
            <Col span={8}>
              <Item label="折扣税额" name="discountTaxAmount">
                <DisplayInput style={{ color: '#f00' }} />
              </Item>
            </Col>
             <Col span={8}>
              <Item label="折扣金额" name="withTaxDiscountAmount">
                <DisplayInput style={{ color: '#f00' }} />
              </Item>
            </Col>
          </>
        )}

        <Col span={8}>
          <Item label="SBU 代码" name="sbuCode">
            <DisplayInput />
          </Item>
        </Col>
        <Col span={8}>
          <Item
            label="发票类型"
            name="invoiceType"
          >
            <Select
              onChange={handleInvoiceTypeChange}
              {...isSelectDisable(isDisabled)}
            >
              <Option value={InvoiceType.SPECIAL_INV}>专用发票</Option>
              <Option value={InvoiceType.GENERAL_INV}>普通发票</Option>
              <Option value={InvoiceType.ELE_SPECIAL_INV}>电子专票</Option>
              <Option value={InvoiceType.ELE_GENERAL_INV}>电子普票</Option>
              <Option value={InvoiceType.FULL_ELE_SPECIAL_INV}>全电专票</Option>
              <Option value={InvoiceType.FULL_ELE_GENERAL_INV}>全电普票</Option>
            </Select>
          </Item>
        </Col>
        <Col span={8}>
          <Item
            label="合并标识"
            name="allowMergeFlag"
            rules={[{ required: true }]}
          >
            <Select {...isSelectDisable(isDisabled)}>
              <Option value={1}>可以合并</Option>
              <Option value={0}>不合并</Option>
            </Select>
          </Item>
        </Col>
        <Col span={8}>
          <Item label="发票备注" name="invoiceRemark">
            <TextArea autoSize  disabled={isDisabled} />
          </Item>
        </Col>
        <Col span={8}>
          <Item label="申请说明" name="remark">
            <TextArea autoSize disabled={isDisabled} />
          </Item>
        </Col>
        <Col span={8}>
          <Item label="承诺回款日期" name="promisePaymentDate">
            <DatePicker
              inputReadOnly
              format="YYYY-MM-DD"
              style={{ width: '100%' }}
              disabled={isDisabled}
            />
          </Item>
        </Col>
      </Row>
    )
  }

  const OtherItems: FC = () => {
    return (
      <Row>
        <HiddenFormItem name="id" />
        <HiddenFormItem name="sbuName" />
        <Col span={8}>
          <Item label="申请单号" name="requestNo">
            <DisplayInput />
          </Item>
        </Col>
        <Col span={8}>
          <Item label="公司名称" name="orgName">
            <DisplayInput />
          </Item>
        </Col>
        <Col span={8}>
          <Item label="申请时间" name="requestTime">
            <DisplayInput />
          </Item>
        </Col>
        <Col span={8}>
          <Item label="申请人" name="requestorName">
            <DisplayInput />
          </Item>
        </Col>
        <Col span={8}>
          <Item label="状态" name="statusCode">
            <Select {...isSelectDisable(true)}>
              <Option value={ApplyStatus.NEW}>待提交</Option>
              <Option value={ApplyStatus.INPROGRESS}>审批中</Option>
              <Option value={ApplyStatus.WITHDRAW}>已撤回</Option>
              <Option value={ApplyStatus.APPROVED}>审批通过</Option>
              <Option value={ApplyStatus.REJECTED}>审批驳回</Option>
              <Option value={ApplyStatus.CANCEL}>已作废</Option>
            </Select>
          </Item>
        </Col>
        <Col span={8}>
          <Item label="开票申请类型" name="requestType">
            <Select {...isSelectDisable(true)}>
              <Option value={ApplyTypeVal.RETAIL}>零售</Option>
              <Option value={ApplyTypeVal.ADVANCE}>预付款</Option>
              <Option value={ApplyTypeVal.PROGRESS}>进度款</Option>
              <Option value={ApplyTypeVal.SETTLE}>结算款</Option>
              <Option value={ApplyTypeVal.OTHER}>其他</Option>
              <Option value={ApplyTypeVal.MAIN_ASSISTANT}>全国性家装</Option>
            </Select>
          </Item>
        </Col>
        <Col span={8}>
          <Item label="不含税金额" name="amount">
            <DisplayInput />
          </Item>
        </Col>
        <Col span={8}>
          <Item label="税额" name="taxAmount">
            <DisplayInput />
          </Item>
        </Col>
        <Col span={8}>
          <Item label="含税金额" name="withTaxAmount">
            <DisplayInput />
          </Item>
        </Col>
        <Col span={8}>
          <Item label="SBU 名称" name="sbuId">
            <Select
              showSearch
              optionFilterProp="key"
              onChange={handleSBUChange}
              options={SBUOptions.map(SBUOption => ({
                key: `${SBUOption.code} ${SBUOption.name}`,
                label: `${SBUOption.name}`,
                value: SBUOption.id,
              }))}
              {...isSelectDisable(isDisabled)}
            />
          </Item>
        </Col>
        <Col span={8}>
          <Item label="SBU 代码" name="sbuCode">
            <DisplayInput />
          </Item>
        </Col>
        <Col span={8}>
          <Item
            label="发票类型"
            name="invoiceType"
          >
            <Select
              onChange={handleInvoiceTypeChange}
              {...isSelectDisable(isDisabled)}
            >
              <Option value={InvoiceType.SPECIAL_INV}>专用发票</Option>
              <Option value={InvoiceType.GENERAL_INV}>普通发票</Option>
              <Option value={InvoiceType.ELE_SPECIAL_INV}>电子专票</Option>
              <Option value={InvoiceType.ELE_GENERAL_INV}>电子普票</Option>
            </Select>
          </Item>
        </Col>
        <Col span={8}>
          <Item
            label="合并标识"
            name="allowMergeFlag"
            rules={[{ required: true }]}
          >
            <Select {...isSelectDisable(isDisabled)}>
              <Option value={1}>可以合并</Option>
              <Option value={0}>不合并</Option>
            </Select>
          </Item>
        </Col>
        <Col span={8}>
          <Item label="发票备注" name="invoiceRemark">
            <TextArea autoSize  disabled={isDisabled} />
          </Item>
        </Col>
        <Col span={8}>
          <Item label="申请说明" name="remark">
            <TextArea autoSize disabled={isDisabled} />
          </Item>
        </Col>
        <Col span={8}>
          <Item label="承诺回款日期" name="promisePaymentDate">
            <DatePicker
              format="YYYY-MM-DD"
              style={{ width: '100%' }}
              disabled={isDisabled}
              inputReadOnly
            />
          </Item>
        </Col>
      </Row>
    )
  }

  const EngineeringItems: FC = () => {
    return (
      <Row>
        <HiddenFormItem name="id" />
        <HiddenFormItem name="contractId" />
        <HiddenFormItem name="contractNo" />
        <HiddenFormItem name="sourceSystem" />
        <HiddenFormItem name="sbuId" />
        <Col span={8}>
          <Item label="申请单号" name="requestNo">
            <DisplayInput />
          </Item>
        </Col>
        <Col span={8}>
          <Item label="申请时间" name="requestTime">
            <DisplayInput />
          </Item>
        </Col>
        <Col span={8}>
          <Item label="合同类型" name="contractType">
             <Select disabled={isNeW}>{/*{...isSelectDisable(isDisabled)} */}
              <Option value="S">只供货</Option>
              <Option value="I">包安装</Option>
            </Select>
          </Item>
        </Col>
        <Col span={8}>
          <Item label="申请人" name="requestorName">
            <DisplayInput />
          </Item>
        </Col>
        <Col span={8}>
          <Item label="状态" name="statusCode">
            <Select {...isSelectDisable(true)}>
              <Option value={ApplyStatus.NEW}>待提交</Option>
              <Option value={ApplyStatus.INPROGRESS}>审批中</Option>
              <Option value={ApplyStatus.WITHDRAW}>已撤回</Option>
              <Option value={ApplyStatus.APPROVED}>审批通过</Option>
              <Option value={ApplyStatus.REJECTED}>审批驳回</Option>
              <Option value={ApplyStatus.CANCEL}>已作废</Option>
            </Select>
          </Item>
        </Col>
        <Col span={8}>
          <Item label="开票申请类型" name="requestType">
            <Select {...isSelectDisable(true)}>
              <Option value={ApplyTypeVal.RETAIL}>零售</Option>
              <Option value={ApplyTypeVal.ADVANCE}>预付款</Option>
              <Option value={ApplyTypeVal.PROGRESS}>进度款</Option>
              <Option value={ApplyTypeVal.SETTLE}>结算款</Option>
              <Option value={ApplyTypeVal.OTHER}>其他</Option>
              <Option value={ApplyTypeVal.MAIN_ASSISTANT}>全国性家装</Option>
            </Select>
          </Item>
        </Col>
        <Col span={8}>
          <Item label="不含税金额" name="amount">
            <DisplayInput />
          </Item>
        </Col>
        <Col span={8}>
          <Item label="税额" name="taxAmount">
            <DisplayInput />
          </Item>
        </Col>
        <Col span={8}>
          <Item label="含税金额" name="withTaxAmount">
            <DisplayInput />
          </Item>
        </Col>
        <Col span={8}>
          <Item label="项目合同编号" name="contractNo">
            <Select
              showSearch
              optionFilterProp="label"
              options={contractNumOptions}
              onChange={handleContractNumChange}
              // {...isSelectDisable(isDisabled)}
              disabled={isNeW}
            />
          </Item>
        </Col>
        <Col span={8}>
          <Item label="项目合同名称" name="contractName">
            <LinkText
              label={form.getFieldValue('contractName')}
              style={{ marginLeft: 10 }}
              onClick={showLedger}
            />
          </Item>
        </Col>
        <Col span={8}>
          <Item
            label="合并标识"
            name="allowMergeFlag"
            rules={[{ required: true }]}
          >
             <Select disabled={isNeW}>{/*{...isSelectDisable(isDisabled)} */}
              <Option value={1}>可以合并</Option>
              <Option value={0}>不合并</Option>
            </Select>
          </Item>
        </Col>
        <Col span={8}>
          <Item label="SBU 名称" name="sbuName">
            <Select
              options={SBUOptions.map(SBUOption => ({
                key: `${SBUOption.code} ${SBUOption.name}`,
                label: `${SBUOption.name}`,
                value: SBUOption.id,
              }))}
              {...isSelectDisable(true)}
            />
          </Item>
        </Col>
        <Col span={8}>
          <Item label="SBU 标识" name="sbuCode">
            <DisplayInput />
          </Item>
        </Col>
        {applyType === ApplyType.PROGRESS && (
          <Col span={8}>
            <Item label="负数申请" name="allowMinusApply">
              <Select {...isSelectDisable(isDisabled)}>
                <Option value={1}>是</Option>
                <Option value={0}>否</Option>
              </Select>
            </Item>
          </Col>
        )}
        <Col span={8}>
          <Item
            label="发票类型"
            name="invoiceType"
          >
            <Select
              onChange={handleInvoiceTypeChange}
              // {...isSelectDisable(isDisabled)}
              disabled={isNeW}
            >
              <Option value={InvoiceType.SPECIAL_INV}>专用发票</Option>
              <Option value={InvoiceType.GENERAL_INV}>普通发票</Option>
              <Option value={InvoiceType.ELE_SPECIAL_INV}>电子专票</Option>
              <Option value={InvoiceType.ELE_GENERAL_INV}>电子普票</Option>
              <Option value={InvoiceType.FULL_ELE_SPECIAL_INV}>全电专票</Option>
              <Option value={InvoiceType.FULL_ELE_GENERAL_INV}>全电普票</Option>
            </Select>
          </Item>
        </Col>
        <Col span={8}>
          <Item
            label="承诺回款日期"
            name="promisePaymentDate"
            rules={[{ required: true }]}
          >
            <DatePicker
              format="YYYY-MM-DD"
              style={{ width: '100%' }}
              disabled={isNeW}
              inputReadOnly
            />
          </Item>
        </Col>
        <Col span={8}>
          <Item label="申请说明" name="remark">
            <TextArea autoSize  disabled={isNeW}/>
          </Item>
        </Col>
        <Col span={8}>
          <Item label="发票备注" name="invoiceRemark">
            <TextArea autoSize  disabled={isDisabled} />
          </Item>
        </Col>
      </Row>
    )
  }

 const renderItems: { [key: string]: React.ReactNode } = {
   [ApplyType.RETAIL]: RetailItems,
   [ApplyType.ADVANCE]: EngineeringItems,
   [ApplyType.PROGRESS]: EngineeringItems,
   [ApplyType.SETTLE]: EngineeringItems,
   [ApplyType.OTHER]: OtherItems,
   [ApplyType.MAIN_ASSISTANT]: RetailItems
 };

  return (
    <Card title="项目合同名称" style={{ position: 'relative' }}>
      <BackTag onClick={handleBack}/>
      <Form
        form={form}
        labelCol={{ span: 7 }}
        wrapperCol={{ span: 17 }}
      >
      {renderItems[applyType]}
      </Form>
    </Card>
  );
});

export default ApplyForm;
