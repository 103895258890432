import React, { FC, useState, BaseHTMLAttributes  } from 'react';
import { InputNumber } from 'antd';

import { Table } from '@comps';

import { Message } from '@utils';
import { ColumnProps } from '@comps/types';

interface Props extends BaseHTMLAttributes<HTMLElement> {
  ref?: any,
  orderId: number,
  dataSource: JSONObject[],
  crossCheckedRowRef: JSONObject,
  childTableIdsRef: JSONObject 
};

const CrossCheckSubTable: FC<Props> = ({
  orderId,
  dataSource,
  crossCheckedRowRef,
  childTableIdsRef,
}) => {


  let [table, setTable] = useState<Table>();
  const onTableRef = (tableRef: Table) => {
    setTable(tableRef);
    table = tableRef;
  };

  const handleWithTaxAmtChange = React.useCallback((applyQuantity: any, row: JSONObject) => {
    const isRemainQuantityNegative = row.remainQuantity < 0;
    const maxRemainQuantity = 
      isRemainQuantityNegative 
        ? (row.remainQuantity) * -1 
        : row.remainQuantity;

    if (isRemainQuantityNegative) {
      if (applyQuantity > 0 || applyQuantity < row.remainQuantity) {
        Message.warn('“提交申请数量” 不能大于 “未开数量”')
        return;
      }
    } else {
      if (applyQuantity > maxRemainQuantity) {
        Message.warn('“提交申请数量” 不能大于 “未开数量”')
        return;
      };
    }

    row.applyQuantity = applyQuantity;
    row.lineId = row.id;

    const inputedRowData: JSONObject[] = crossCheckedRowRef.current;
    const currentRowIndex = inputedRowData.findIndex(
      (_row: JSONObject) => _row.id === row.id
    );
    
    currentRowIndex === -1
      ? inputedRowData.push(row)
      : inputedRowData[currentRowIndex] = row;
  }, []);

  const onSelect = (record: JSONObject, selected: boolean, selectedRows: JSONObject[]) => {
    const { id } = record;
    const childTableIds: number[] = childTableIdsRef.current;
    const index = childTableIds.findIndex(rowId => rowId === id);

    if (selected) {
      if (index === -1) {
        childTableIds.push(id);
      }
    } else {
      childTableIds.splice(index, 1); 
    }
  }; 

  const onSelectAll = (
    selected: boolean, 
    selectedRows: JSONObject[], 
    changeRows: JSONObject[]
  ) => {
   
    let childTableIds: number[] = childTableIdsRef.current;
    const ids: number[] = [];
    changeRows.forEach(row => {
      ids.push(row.id);
    });
    
    if (selected) {
      ids.forEach(id => {
        if (!childTableIds.includes(id)) {
          childTableIds.push(id);
        }
      })
    } else {
      childTableIdsRef.current = childTableIds.filter(id => !ids.includes(id));
    }
  };
 
  const columns: ColumnProps[] = [
    { key: 'goodsName', title: '商品描述', width: 260, fixed: 'left' },
    { key: 'goodsNo', title: '物料编码', width: 180 },
    { key: 'spec', title: '规格型号', width: 180 },  
    { key: 'unit', title: '单位', width: 180 },
    { key: 'orderQuantity', title: '订单总数量', width: 160 },
    { key: 'quantity', title: '数量', width: 160 },
    { key: 'invoicedQuantity', title: '已开数量', width: 160 },
    { key: 'remainQuantity', title: '未开数量', width: 160, align: 'center' },
    { key: '_applyQuantity', title: '提交申请数量', width: 180, align: 'right', 
      render: (_applyQuantity, row: JSONObject) => {
        const maxRemainQuantity = 
          (row.remainQuantity < 0) 
            ? (row.remainQuantity) * -1 
            : row.remainQuantity
  
        return (
          <InputNumber
            style={{ width: 130 }} 
            max={maxRemainQuantity}
            onChange={(event) => handleWithTaxAmtChange(event, row)}
          />
        )
      }
    },
    { key: 'invoicedAmount', title: '已开票金额', width: 180, align: 'center' },
    { key: 'remainAmount', title: '未开票金额', width: 140 },
    { key: 'withTaxPrice', title: '含税单价', width: 140, format: 'amount' },
    { key: 'withTaxAmount', title: '含税金额', width: 140, format: 'amount' },
    { key: 'balanceAmount', title: '差额', width: 120 },
    { key: 'conversionRate', title: '税率', width: 40 },
    { key: 'largeCategory', title: '大类', width: 120, align: 'center' },
    { key: 'mediumCategory', title: '中类', width: 120, align: 'center' },
    { key: 'smallCategory', title: '小类', width: 120, align: 'center' },
  ];

  return (
    <Table
      // url={`/outputOrder/${orderId}/lines`}
      dataSource={dataSource}
      onTableRef={onTableRef}
      rowKey="id" 
      columns={columns}
      scroll={{ y: 450 }}
      pagination={false}
      selectedRowKeys={[]}
      className="import-detail-subtable"
      rowSelection={{ onSelect, onSelectAll }}
    />
  )
};

export default CrossCheckSubTable;
