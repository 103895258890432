import React, { useState } from 'react';

import { Card, Table, Lookup } from '@comps';
import { Space, Button, Form, Input, DatePicker } from 'antd';

import { Http, Message, DateUtils } from '@utils';
import { ColumnProps } from '@comps/types';

interface Props {
  orderId: string,
  refreshTable: () => void,
  onClose: () => void
}

const Item = Form.Item

const AddInvoices: React.FC<Props> = ({ orderId, refreshTable, onClose }: Props) => {
  const [loading, setLoading] = useState<boolean>(false);

  const initQuery = React.useMemo<JSONObject>(() => ({
    invoiceDateFromTo: [DateUtils.monthFirstDay(), DateUtils.now()],
  }), []);

  const [table, setTable] = useState<Table>()
  
  const onTableRef = (tableRef: Table) => {
    setTable(tableRef)
  }

  const handleAddInvoices = () => {
    setLoading(true)

    const invoiceIds = table?.getSelectedRowAttr()

    if (invoiceIds?.length === 0) {
      Message.warn('请勾选至少一条记录')
      return
    }

    Http.put('/tax/mailingManagement/addInvoices', { orderId,  invoiceIds })
      .then(() => {
        Message.success('新增发票成功');
        refreshTable();
      })
      .finally(() => {
        setLoading(false);
        onClose();
      })
  }

  const columns: ColumnProps[] = [
    { key: 'invoiceNo', title: '发票号码', width: 120 },
    { key: 'invoiceCode', title: '发票代码', width: 140, },
    { key: 'invoiceTypeDesc', title: '发票类型', width: 170, },
    { key: 'invoiceDate', title: '开票日期', width: 110, },
    { key: 'employeeName', title: '业务员', width: 110, },
    { key: 'invoiceAmount', title: '不含税金额', format: 'amount', width: 120 },
    { key: 'taxAmount', title: '税额', format: 'amount', width: 120 },
    { key: 'totalAmount', title: '价税合计', width: 120, format: 'amount' },
    { key: 'buyerName', title: '购买方', width: 200, },
    { key: 'sellerName', title: '销售方', width: 200, }
  ];

  return (
    <Card title='新增发票'>
      <Table url="/tax/transfers/invoices"
        queryProps={{
          initialValues: initQuery,
        }}
        onTableRef={onTableRef}
        selectedRowKeys={[]}
        columns={columns}
      >
        <Item name="invoiceNo">
          <Input placeholder="发票号码" />
        </Item>
        <Item name="buyerCode">
          <Input placeholder="购方编码" />
        </Item>
        <Item name="invoiceDateFromTo">
          <DatePicker.RangePicker format='YYYYMMDD' placeholder={["开票日期从", "至"]} inputReadOnly/>
        </Item>
        <Item name="invoiceType">
          <Lookup lookupType="TAX_INVOICE_TYPE" placeholder="发票类型"></Lookup>
        </Item>
        
      </Table>

      <Form onFinish={handleAddInvoices} style={{ marginTop: 30 }}>
        <Item style={{ textAlign: 'center' }}>
          <Space size="large">
            <Button loading={loading} type="primary" htmlType="submit">确定</Button>
            <Button onClick={onClose}>取消</Button>
          </Space>
        </Item>
      </Form>
    </Card>
  )
}

export default AddInvoices
