import { Row } from "antd";
import React, { CSSProperties, FC } from "react";
import "./index.scss";

/**
 * 权限按钮的Space
 * @param props
 */
export interface ButtonSpaceProps {
  style?: CSSProperties;
  children: React.ReactNode[];
}

const ButtonSpace: FC<ButtonSpaceProps> = ({ children = [], style }) => {
  const defaultStyle: CSSProperties = {
    marginRight: "-8px",
  };
  return (
    <Row style={{ ...defaultStyle, ...style }} className="ButtonSpace">
      {children.map((e: any, k: any) => {
        return <div>{e}</div>;
      })}
    </Row>
  );
};

export default ButtonSpace;
