import * as React from 'react';
import { notification } from 'antd';
import * as Lang from './Lang';
import Http from './Http';

export interface Prompt {
  finished: boolean,
  messages: {
    title: string,
    content: string,
  }[],
}

export function useListen(deps: React.DependencyList): () => void {
  const timerIdRef = React.useRef<number>();

  React.useEffect(() => {
    return () => {
      if (timerIdRef.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        clearInterval(timerIdRef.current);
      }
    };
  }, []);

  const startTimer = React.useCallback(() => {
    if (timerIdRef.current) {
      clearInterval(timerIdRef.current);
    }
    const _timerId = Lang.timer(async () => {
      const {
        messages = [],
      } = await Http.get<Prompt>('/sys/messages/unreadPrompts');
      messages.forEach((message) => {
        notification.success({
          message: message.title,
          description: message.content,
        });
      });
    }, 2000);
    timerIdRef.current = Number(_timerId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...deps]);

  return startTimer;
}
