import * as React from 'react';
import cname from 'classnames';
import { Page, Card } from '@comps'
import { Row, Col, Tabs, Form, Input, DatePicker, Select, Button, Space, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { Http, Api } from '@utils';
import { useHistory } from 'react-router';
import _ from 'lodash';
import styles from './index.module.scss';

export interface Props extends React.Props<HTMLElement>,
  React.BaseHTMLAttributes<HTMLElement> {
};

export interface Message {
  id: number,
  msgSubtypeDesc: string,
  title: string,
  sendTime: string,
}

const MessagePage: React.FC<Props> = () => {
  const [query, setQuery] = React.useState<JSONObject>({
    pageSize: 10,
    pageNum: 1,
    hasRead: false,
  });
  const [messagePaging, setMessagePaging] = React.useState<Optional<Paging<Message>>>();
  const [form] = Form.useForm();
  const [ unReadCount, setUnReadCount ] = React.useState<number>(0);
  const [types, setTypes] = React.useState<LV[]>([]);
  const history = useHistory();

  const loadMessages = React.useCallback(async () => {
    const result = await Http.get<Paging<Message>>('/sys/messages', query);
    setMessagePaging(result);
  }, [query]);

  React.useEffect(() => {
    loadMessages();
  }, [loadMessages]);

  React.useEffect(() => {
    ((async () => {
      const [unReadCount, types] = await Promise.all([
        Http.get<number>('/sys/messages/unreadCount'),
        Api.getLookup('SYS_MSG_SUB_TYPE'),
      ]);
      setUnReadCount(unReadCount);
      setTypes(types);
    })());
  }, []);

  const columns: ColumnsType<Message> = [
    {title: '类型', width: 120, dataIndex: 'msgSubtypeDesc'},
    {title: '标题', width: 300, dataIndex: 'title', 
      render: (value, row, index) => (<Button size='small' type='link'onClick={() => { history.push(`/sys/message/${row.id}`); }}>{value}</Button>)},
    {title: '发送时间', width: 120, dataIndex: 'sendTime'},
    {
      title: '操作', dataIndex: 'id',
      width: 100,
      render: (value) => (
        <Button size='small' type='link'
          onClick={() => {
            history.push(`/sys/message/${value}`);
          }}>详情</Button>
      )
    }
  ];

  return (<Page>
    <Card title='消息列表'>
      <Row>
        <Col style={{ width: '120px' }}>
        <Tabs tabPosition="left"
          onChange={(activeKey: string) => {
            const _query = {
              ...query,
            };
            if (!activeKey) {
              delete _query.hasRead;
            } else {
              _query.hasRead = activeKey === 'true';
            }
            setQuery(_query);
          }}>
            <Tabs.TabPane tab={`未读(${unReadCount})`} key='false'></Tabs.TabPane>
            <Tabs.TabPane tab="已读" key='true'></Tabs.TabPane>
            <Tabs.TabPane tab="全部" key=''></Tabs.TabPane>
          </Tabs>
        </Col>
        <Col className={cname(styles.grow)}>
          <Form form={form}>
            <Row gutter={16}>
              <Col span={6}>
                <Form.Item name="title">
                  <Input placeholder="标题" />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item name="msgSubtype">
                  <Select placeholder="类型"
                    options={types}/>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item name="sendTime">
                  <DatePicker.RangePicker style={{ width: '100%' }}
                    format='YYYYMMDD'
                    placeholder={['发送时间从', '发送时间至']} 
                    inputReadOnly/>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item>
                  <Space>
                    <Button onClick={() => {
                      form?.resetFields();
                    }}>
                      重置
                    </Button>
                    <Button type="primary" onClick={() => {
                      const model = form.getFieldsValue();
                      const _query = {
                        ...query,
                        ...model,
                      };
                      _query.sendTimeFrom = _query.sendTime?.[0]?.format('YYYY-MM-DD HH:mm:ss');
                      _query.sendTimeTo = _query.sendTime?.[1]?.format('YYYY-MM-DD HH:mm:ss');
                      delete _query.sendTime;
                      setQuery(_.pickBy(_query, item => !_.isNil(item)));
                    }}>
                      查询
                    </Button>
                  </Space>
                </Form.Item>
              </Col>
            </Row>
          </Form>
          <Table columns={columns}
            rowKey="id"
            scroll={{ x: columns.reduce((sum, column) => sum += Number.parseFloat(`${column.width ?? 100}`), 0)}}
            pagination={{
              hideOnSinglePage: true,
              showQuickJumper: true,
              current: messagePaging?.pageNum,
              pageSize: messagePaging?.pageSize,
              total: messagePaging?.total,
              pageSizeOptions: ["5", "10", "20", "50", "100", "500"],
              onChange(page: number, pageSize?: number) {
                setQuery({
                  ...query,
                  pageNum: page,
                  pageSize,
                });
              }
            }}
            dataSource={messagePaging?.content}>
          </Table>
        </Col>
      </Row>
    </Card>
  </Page>);
};

export default MessagePage;
