import { RouteProps } from '@models'

import Matter from './matter';
import ApprovalProcessDefine from './approvalProcessDefine'
import InvoiceApproval from './InvoiceApproval'
import InvoiceApprovaldetailModal from './modal/invoiceApprovaldetailModal'

const approvalRoutes: RouteProps[] = [
  { path: '/approve/matter', component: Matter, private: false },
  { path: '/approve/approvalProcessDefine', component: ApprovalProcessDefine, private: false },
  { path: '/approve/InvoiceApproval', component: InvoiceApproval, private: false },
//   { path: '/approve/modal/InvoiceApprovaldetailModal', component: InvoiceApprovaldetailModal, private: false },
];

export default approvalRoutes 