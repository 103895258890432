import React, { FC } from 'react';
import { Form, Select, Input } from 'antd';

import { Card, Table } from '@comps';
import { ColumnProps } from '@comps/types';

interface Props {
  contractNo: string
};

const { Item } = Form;
const { Option } = Select;

const Statistics: FC<Props> = ({ contractNo }) => {

  const columns: ColumnProps[] = [
    { key: 'id', title: '序号', width: 120, fixed: 'left',
      render: (id: number, row: JSONObject, index: number) => (index + 1)
    },
    { key: 'bigCategory', title: '大类', width: 90, },
    { key: 'middleCategory', title: '中类', width: 90, },
    { key: 'smallCategory', title: '小类', width: 240, },
    { key: 'shippedQuantity', title: '已发货数量', width: 240, },
    { key: 'shippedAmt', title: '已发货金额', width: 320, },
    { key: 'invoicedQuantity', title: '已开票数量', width: 140, format: 'amount' },
 ];
  
  return (
    <div>
      <Card 
        title="品类开票详情"
        style={{ boxShadow: 'none' }}
      >
        <Table
          url="/contracts/ledger/invoiced/category"
          formAdditionalParameters={{ contractNo }}
          columns={columns}
        >
          <Item name="category">
            <Select placeholder="类别统计" allowClear>
              <Option value={1}>大类统计</Option>
              <Option value={2}>中类统计</Option>
              <Option value={3}>小类统计</Option>
            </Select>
          </Item>
          <Item name="categoryName">
            <Input placeholder="类别名称" />
          </Item>
        </Table>
      </Card>
    </div>
  )
};

export default Statistics;