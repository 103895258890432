import React, { FC, useState, useEffect, useImperativeHandle, forwardRef, CSSProperties } from 'react';
import { dropByCacheKey } from 'react-router-cache-route';
import { Tag } from 'antd'
import { Context } from '@utils';
import { PaneProps } from '@models';
import { useLocation } from 'react-router-dom';

import './index.css';

interface Props {
  ref: any,
  style?: CSSProperties
};

const Tabs: FC<Props> = forwardRef(({
  style
}, ref) => {

  const [tabsActiveKey, setTabsActiveKey] = useState<string>('0');

  const _panes = Context.getPanes();
  const _tabsActiveKey = Context.getTabsActiveKey();
  const [panes, setPanes] = useState<PaneProps[]>([]);

  useEffect(() => {
    setPanes([..._panes]);
    setTabsActiveKey(_tabsActiveKey);
    dispatchSetPanesAndActiveKeyFn();
  }, []);

  const path = useLocation()
  useEffect(() => {
    const ctx = Context.getContext();
    let initRouteList = [{ title: '首页', route: '/sys/users/profile', key: '/sys/users/profile',  }]
    ctx['panes'].map((item: any) => {
      if (item.route == path.pathname) {
        initRouteList.push({ title: item.title, route: item.route, key: item.route })
        setTabsActiveKey(item.route)
      }
    })
    //标签去重
    let panesList = Array.from(new Set(initRouteList.map((item: any) => JSON.stringify(item)))).map(items => JSON.parse(items as string))
    setPanes(panesList);
    Context.dispatch({
      type: 'ACTION_SET_PANES',
      data: {
        panes: panesList,
        tabsActiveKey: tabsActiveKey
      }
    });
  }, [])

  useImperativeHandle(ref, () => ({
    handleTabAdd,
  }));

  const dispatchSetPanesAndActiveKeyFn = () => {
    Context.dispatch({
      type: 'ACTION_SET_PANES',
      data: {
        setComponentPanes: setPanes,
        setComponentTabsActiveKey: setTabsActiveKey
      }
    });
  };

  const handleTabClick = (pane: JSONObject) => {
    setTabsActiveKey(pane.route);
    Context.route(pane.route, pane.state);

    Context.dispatch({
      type: 'ACTION_SET_PANES',
      data: {
        tabsActiveKey: pane.route
      }
    });
  };

  const handleTabClose = (e: React.MouseEvent<HTMLElement>, targetPane: PaneProps) => {
    let activeKey: string = tabsActiveKey;
    let lastIndex = 0;
    const newPanes = panes.filter(
      pane => pane.key !== targetPane.key
    );

    panes.forEach((pane, i: number) => {
      if (pane.key === targetPane.key) {
        lastIndex = i - 1;
      }
    });

    if (newPanes.length && activeKey === targetPane.key) {
      if (lastIndex >= 0) {
        activeKey = newPanes[lastIndex].key;
        Context.route(newPanes[lastIndex].route);
      } else {
        activeKey = newPanes[0].key;
        Context.route(newPanes[0].route);
      }
    }

    setPanes(newPanes);
    setTabsActiveKey(activeKey);

    Context.dispatch({
      type: 'ACTION_SET_PANES',
      data: {
        panes: newPanes,
        tabsActiveKey: activeKey
      }
    });

    dropByCacheKey(targetPane.route);
  };

  const handleTabAdd = (menuObj: JSONObject) => {
    // 最多开 10 个页面
    if (panes.length === 10) {
      // setPanes([...panes.splice(1, 1)])
      // alert('请先尝试关闭一些页面，再打开新页面');
      // Context.route(getCurrentActiveTab().route);
      // return;
    }

    // 判断要打开的页面是否已打开
    const menuTabIndex = panes.findIndex(
      pane => pane.key === menuObj.route
    );

    if (menuTabIndex > 0) {
      setTabsActiveKey(menuObj.route);
      return;
    }

    const newPanes = [
      ...panes,
      {
        title: menuObj.menuName,
        key: menuObj.route,
        route: menuObj.route,
        state: menuObj.state
      }
    ]

    setPanes(newPanes);
    setTabsActiveKey(menuObj.route);

    Context.dispatch({
      type: 'ACTION_SET_PANES',
      data: {
        panes: newPanes,
        tabsActiveKey: menuObj.route
      }
    });
  };

  const getCurrentActiveTab = (): PaneProps => {
    return panes.find((pane) => pane.key === tabsActiveKey)
      ?? { title: '', key: '-1', route: '' };
  };

  return (
    <div className="tabs" style={style}>
      {panes.map((pane, index: number) => (
        <Tag
          key={pane.key}
          closable={index !== 0}
          onClose={(e: any) => handleTabClose(e, pane)}
          onClick={() => handleTabClick(pane)}
          className={
            pane.key === tabsActiveKey
              ? 'tab tab-active'
              : 'tab'
          }
        >
          {pane.title}
        </Tag>
      ))}
    </div>
  );
});

export default Tabs;