import React, { FC, useState, useCallback, useMemo } from "react";
import {
  Space,
  Select,
  Table as AdTable,
  Row,
  Col,
  Form,
  DatePicker,
  Input,
  Popconfirm,
  Modal as AdModal,
} from "antd";

import {
  Page,
  Card,
  Table,
  LinkText,
  Modal,
  ViewInvoice,
  PermissionButton,
} from "@comps";
import { Context, Math, Http, Env, Message, Lang, DateUtils } from "@utils";
import { ColumnProps } from "@comps/types";
import { TableRow, BillType } from "./interface";

import "./index.css";

const { Item } = Form;
const { Option } = Select;
const { confirm } = AdModal;
const { RangePicker } = DatePicker;

const Other: FC = () => {
  const [modal, modalHolder] = Modal.useModal();
  const [currentBillType, setCurrentBillType] = useState<string>(
    BillType.ALL_TYPE
  );

  const initialFormData = useMemo<JSONObject>(
    () => ({
      invDateStr: [DateUtils.monthFirstDay(), DateUtils.monthLastDay()],
    }),
    []
  );

  let [table, setTable] = useState<Table>();
  const onTableRef = (tableRef: Table) => {
    table = tableRef;
    setTable(tableRef);
  };

  const [tableSummaryInfo, setTableSummaryInfo] = useState<JSONObject>({});

  const getTableSummaryInfo = async (queryObj: JSONObject) => {
    switch (currentBillType) {
      case BillType.AVIATION:
        queryObj.invType = BillType.AVIATION;
        break;
      case BillType.BRIDGE:
        queryObj.invType = BillType.BRIDGE;
        break;
      case BillType.MACHINE:
        queryObj.invType = BillType.MACHINE;
        break;
      case BillType.OTHER:
        queryObj.invType = BillType.OTHER;
        break;
      case BillType.PASSENGER_CAR:
        queryObj.invType = BillType.PASSENGER_CAR;
        break;
      case BillType.QUOTA:
        queryObj.invType = BillType.QUOTA;
        break;
      case BillType.SHIP:
        queryObj.invType = BillType.SHIP;
        break;
      case BillType.TAXI:
        queryObj.invType = BillType.TAXI;
        break;
      case BillType.TRAIN:
        queryObj.invType = BillType.TRAIN;
        break;
      default:
        queryObj.invType = "";
        break;
    }

    const summaryRes = await Http.get("/input/biz/sumOtherInvoices", queryObj);
    setTableSummaryInfo(summaryRes);
  };

  const handleBillTypeChange = (value: string) => {
    setCurrentBillType(value);
  };

  const handleDelete = async (ids: any) => {
    await Http.del(`/input/biz/delOtherInvoice`, { ids });
    table?.refresh();
  };

  const handleBatchDelete = () => {
    const IDs = table?.getSelectedRowKeys();
    if (IDs?.length === 0) {
      Message.warn("请勾选发票");
      return;
    }
    handleDelete(IDs?.join());
  };

  const handleSplicingURL = () => {
    let currentColumns: JSONObject[] = [];

    let URL = `${Env.getBaseUrl()}/input/biz/exportOtherInvoice?accessToken=${Context.getToken()}`;

    URL += `&invType=${currentBillType}`;

    switch (currentBillType) {
      case BillType.ALL_TYPE:
        currentColumns = allTypeColumns;
        break;
      case BillType.AVIATION:
        currentColumns = airColumns;
        break;
      case BillType.BRIDGE:
        currentColumns = bridgeColumns;
        break;
      case BillType.MACHINE:
        currentColumns = generalColumns;
        break;
      case BillType.OTHER:
        currentColumns = otherColumns;
        break;
      case BillType.PASSENGER_CAR:
        currentColumns = passengerColumns;
        break;
      case BillType.QUOTA:
        currentColumns = quotaColumns;
        break;
      case BillType.SHIP:
        currentColumns = shipColumns;
        break;
      case BillType.TAXI:
        currentColumns = taxiColumns;
        break;
      case BillType.TRAIN:
        currentColumns = trainColumns;
        break;
      default:
        break;
    }

    currentColumns.pop();

    URL += `&columns=${JSON.stringify(currentColumns)}`;

    // 导出时添加查询参数
    const queryParams = table?.getQueryPanelParams();
    for (let key in queryParams) {
      URL += `&${key}=${queryParams[key]}`;
    }

    return URL;
  };

  const handleExportAllData = () => {
    const URL = handleSplicingURL();

    window.open(encodeURI(URL), "_blank");
  };

  const showExportAllConfirm = () => {
    confirm({
      title: "提示",
      content: "是否导出所有数据",
      onOk: handleExportAllData,
    });
  };

  const handleExport = async () => {
    const IDs = table?.getSelectedRowKeys();
    if (IDs?.length === 0) {
      showExportAllConfirm();
      return;
    }

    let URL = handleSplicingURL();
    URL += `&ids=${IDs?.join()}`;

    window.open(encodeURI(URL), "_blank");
  };

  const handleViewInvoice = useCallback((picURL: string) => {
    Lang.noError(async () => {
      await modal.open({
        width: "800px",
        ignoreError: false,
        content(resolve, reject) {
          return <ViewInvoice onClose={reject} picUrl={picURL} />;
        },
      });
    });
  }, []);

  const sourceRender = (type: any) => {
    type = parseInt(type);
    if (type === 7) {
      return "接口传入";
    } else if (type === 4) {
      return "手工录入";
    } else if (type === 10) {
      return "高拍仪录入";
    } else if (type === 11) {
      return "图片导入";
    } else if (type === 5) {
      return "文件导入";
    } else if (type === 6) {
      return "文件导入";
    } else if (type === 8) {
      return "文件导入";
    } else if (type === 9) {
      return "移动端录入";
    } else {
      return "";
    }
  };

  const allTypeColumns: ColumnProps[] = [
    { key: "invoiceNo", title: "发票号码", width: 160, fixed: "left" },
    {
      key: "invoiceType",
      title: "发票类型",
      width: 160,
      fixed: "left",
      render: (type) => {
        type = parseInt(type);
        if (type === 1) {
          return "增值税专用发票";
        } else if (type === 2) {
          return "货运运输业增值税专用发票";
        } else if (type === 3) {
          return "机动车增值税专用发票";
        } else if (type === 4) {
          return "增值税普通发票";
        } else if (type === 10) {
          return "电子普通发票";
        } else if (type === 11) {
          return "卷式发票";
        } else if (type === 13) {
          return "增值税电子专用发票";
        } else if (type === 14) {
          return "通行费发票";
        } else if (type === 15) {
          return "二手车发票";
        } else if (type === 16) {
          return "成品油发票";
        } else if (type === 17) {
          return "火车票";
        } else if (type === 18) {
          return "客运汽车票";
        } else if (type === 19) {
          return "航空运输电子行程票";
        } else if (type === 20) {
          return "出租车票";
        } else if (type === 21) {
          return "船票";
        } else if (type === 22) {
          return "过桥、过闸通行费";
        } else if (type === 23) {
          return "通用机打发票";
        } else if (type === 24) {
          return "定额发票";
        } else if (type === 51) {
          return "定额发票";
        } else if (type === 99) {
          return "其他";
        } else {
          return "未知";
        }
      },
    },
    { key: "invoiceDate", title: "发票日期", width: 100 },
    { key: "voucherDate", title: "过账日期", width: 120 },
    // { key: 'uploadDate', title: '上传日期', width: 120 },
    { key: "amount", title: "金额", format: "amount", width: 120 },
    { key: "taxAmount", title: "税额", format: "amount", width: 120 },
    {
      key: "voucherTaxAmount",
      title: "记账税额",
      format: "amount",
      width: 120,
    },
    // { key: 'deductionFlag', title: '是否可抵扣', width: 120, align: 'right'},
    // { key: 'approvalStatus', title: '报销状态', width: 200,
    //   render: approvalStatus => {
    //     if (approvalStatus === '0') {
    //       return '未报销'
    //     } else if (approvalStatus === '1') {
    //       return '已报销'
    //     } else {
    //       return '未知'
    //     }
    //   }
    // },
    { key: "bizNo", title: "业务单据号", width: 200 },
    {
      key: "accountStatus",
      title: "记账状态",
      width: 200,
      render: (accountStatus) => {
        if (accountStatus === "0") {
          return "未记账";
        } else if (accountStatus === "1") {
          return "已记账";
        } else {
          return "未知";
        }
      },
    },
    { key: "voucherDate", title: "会计期间", width: 200 },
    { key: "voucherNo", title: "记账凭证号", width: 200 },
    // { key: 'uploader', title: '创建人', width: 200 },
    // { key: 'uploadDate', title: '创建时间', width: 200 },
    // { key: 'source', title: '数据来源', width: 200, render: sourceRender },
    {
      key: "id",
      title: "操作",
      width: 160,
      fixed: "right",
      align: "center",
      render(id, row: TableRow) {
        return (
          <Space>
            <LinkText
              label="查看"
              onClick={() => handleViewInvoice(row.picUrl)}
            />
            <Popconfirm
              title="您确认要删除吗?"
              onConfirm={() => handleDelete(id)}
            >
              <PermissionButton
                permissions="InputOtherInv.delOtherInvoice"
                type="link"
              >
                删除
              </PermissionButton>
            </Popconfirm>
          </Space>
        );
      },
    },
  ];

  const trainColumns: ColumnProps[] = [
    { key: "invoiceNo", title: "红色票号", width: 90, fixed: "left" },
    { key: "buyerName", title: "乘客姓名", width: 100 },
    { key: "voucherNo", title: "记账凭证号", width: 200 },
    { key: "invoiceDate", title: "乘车日期", width: 200 },
    { key: "voucherDate", title: "过账日期", width: 120 },
    // { key: 'uploadDate', title: '上传日期', width: 120 },
    { key: "amount", title: "不含税金额", width: 200, format: "amount" },
    { key: "taxAmount", title: "税额", width: 120, format: "amount" },
    { key: "withTaxAmount", title: "含税金额", format: "amount", width: 120 },
    { key: "trainNumber", title: "车次", align: "center" },
    // { key: 'startStation', title: '始发站', width: 180, },
    // { key: 'endStation', title: '终点站', width: 100, },
    // { key: 'seatType', title: '座位等级', width: 200 },
    // { key: 'source', title: '数据来源', width: 200, render: sourceRender },
    // { key: 'deductionFlag', title: '是否可抵扣', width: 120},
    // { key: 'uploadDate', title: '创建时间', width: 200 },
    { key: "documentAccount", title: "记账人", width: 200 },
    {
      key: "id",
      title: "操作",
      width: 160,
      fixed: "right",
      align: "center",
      render(ID, row: TableRow) {
        return (
          <Space>
            <LinkText
              label="查看"
              onClick={() => handleViewInvoice(row.picUrl)}
            />
            <Popconfirm
              title="您确认要删除吗?"
              onConfirm={() => handleDelete(ID)}
            >
              <PermissionButton
                permissions="InputOtherInv.delOtherInvoice"
                type="link"
              >
                删除
              </PermissionButton>
            </Popconfirm>
          </Space>
        );
      },
    },
  ];

  const passengerColumns: ColumnProps[] = [
    { key: "invoiceNo", title: "发票号码", width: 90, fixed: "left" },
    { key: "invoiceCode", title: "发票代码", width: 100, fixed: "left" },
    { key: "voucherNo", title: "记账凭证号", width: 200 },
    { key: "invoiceDate", title: "乘车日期", width: 200 },
    { key: "voucherDate", title: "过账日期", width: 120 },
    // { key: 'uploadDate', title: '上传日期', width: 120 },
    { key: "buyerName", title: "乘客姓名", width: 120 },
    { key: "amount", title: "不含税金额", format: "amount", width: 120 },
    { key: "taxAmount", title: "税额", format: "amount" },
    { key: "withTaxAmount", title: "含税金额", width: 180, format: "amount" },
    { key: "trainNumber", title: "车次", width: 160, align: "center" },
    // { key: 'startStation', title: '始发站', width: 200 },
    // { key: 'endStation', title: '到达站', width: 200 },
    // { key: 'seatType', title: '座位等级', width: 200 },
    // { key: 'source', title: '数据来源', width: 200, render: sourceRender },
    // { key: 'deductionFlag', title: '是否可抵扣', width: 120 },
    // { key: 'uploadDate', title: '创建时间', width: 200 },
    // { key: 'uploader', title: '创建用户', width: 200 },
    { key: "documentAccount", title: "记账人", width: 200 },
    {
      key: "id",
      title: "操作",
      width: 160,
      fixed: "right",
      align: "center",
      render(id, row: TableRow) {
        return (
          <Space>
            <LinkText
              label="查看"
              onClick={() => handleViewInvoice(row.picUrl)}
            />
            <Popconfirm
              title="您确认要删除吗?"
              onConfirm={() => handleDelete(id)}
            >
              <PermissionButton
                permissions="InputOtherInv.delOtherInvoice"
                type="link"
              >
                删除
              </PermissionButton>
            </Popconfirm>
          </Space>
        );
      },
    },
  ];

  const airColumns: ColumnProps[] = [
    { key: "invoiceNo", title: "电子客票号码", width: 120, fixed: "left" },
    { key: "voucherNo", title: "记账凭证号", width: 200 },
    { key: "flightDate", title: "乘机日期", width: 120 },
    { key: "voucherDate", title: "过账日期", width: 120 },
    // { key: 'uploadDate', title: '上传日期', width: 120 },
    { key: "buyerName", title: "乘客姓名", width: 200 },
    { key: "taxAmount", title: "税额", width: 120 },
    { key: "withTaxAmount", title: "合计金额", format: "amount", width: 120 },
    { key: "fare", title: "票价", format: "amount" },
    {
      key: "civilAviationFund",
      title: "民航发展基金",
      width: 160,
      format: "amount",
    },
    { key: "fuelAmount", title: "燃油附加费", width: 160, format: "amount" },
    { key: "otherTaxAmount", title: "其他税费", width: 160, format: "amount" },
    { key: "insurance", title: "保险费", width: 160, format: "amount" },
    { key: "invoiceDate", title: "填开日期", width: 200, align: "center" },
    { key: "sellerName", title: "填开单位", width: 200 },
    { key: "trainNumber", title: "航班号", width: 200 },
    // { key: 'seatType', title: '客票等级', width: 200, align: 'center' },
    // { key: 'startStation', title: '始发站', width: 200 },
    // { key: 'endStation', title: '终点站', width: 200 },
    // { key: 'source', title: '数据来源', width: 200, render: sourceRender },
    // { key: 'deductionFlag', title: '是否可抵扣', width: 120 },
    // { key: 'uploadDate', title: '创建时间', width: 200 },
    // { key: 'uploader', title: '创建用户', width: 200 },
    { key: "documentAccount", title: "记账人", width: 200 },
    {
      key: "id",
      title: "操作",
      width: 160,
      fixed: "right",
      align: "center",
      render(id, row: TableRow) {
        return (
          <Space>
            <LinkText
              label="查看"
              onClick={() => handleViewInvoice(row.picUrl)}
            />
            <Popconfirm
              title="您确认要删除吗?"
              onConfirm={() => handleDelete(id)}
            >
              <PermissionButton
                permissions="InputOtherInv.delOtherInvoice"
                type="link"
              >
                删除
              </PermissionButton>
            </Popconfirm>
          </Space>
        );
      },
    },
  ];

  const taxiColumns: ColumnProps[] = [
    { key: "invoiceNo", title: "发票号码", width: 90, fixed: "left" },
    { key: "invoiceCode", title: "发票代码", width: 160, fixed: "left" },
    { key: "voucherNo", title: "记账凭证号", width: 200 },
    { key: "invoiceDate", title: "乘车日期", width: 160, align: "center" },
    { key: "voucherDate", title: "过账日期", width: 120 },
    // { key: 'uploadDate', title: '上传日期', width: 120 },
    { key: "amount", title: "金额", width: 160, format: "amount" },
    { key: "startTime", title: "上车时间", width: 180, align: "center" },
    { key: "endTime", title: "下车时间", width: 180 },
    { key: "legend", title: "里程", width: 180 },
    // { key: 'source', title: '数据来源', width: 120, render: sourceRender },
    // { key: 'deductionFlag', title: '是否可抵扣', width: 120 },
    // { key: 'uploadDate', title: '创建时间', width: 200 },
    // { key: 'uploader', title: '创建用户', width: 200 },
    { key: "documentAccount", title: "记账人", width: 200 },
    {
      key: "id",
      title: "操作",
      width: 160,
      fixed: "right",
      align: "center",
      render(id, row: TableRow) {
        return (
          <Space>
            <LinkText
              label="查看"
              onClick={() => handleViewInvoice(row.picUrl)}
            />
            <Popconfirm
              title="您确认要删除吗?"
              onConfirm={() => handleDelete(id)}
            >
              <PermissionButton
                permissions="InputOtherInv.delOtherInvoice"
                type="link"
              >
                删除
              </PermissionButton>
            </Popconfirm>
          </Space>
        );
      },
    },
  ];

  const shipColumns: ColumnProps[] = [
    { key: "invoiceNo", title: "发票号码", width: 90, fixed: "left" },
    { key: "invoiceCode", title: "发票代码", width: 100, fixed: "left" },
    { key: "voucherNo", title: "记账凭证号", width: 200 },
    { key: "invoiceDate", title: "开船日期", width: 200 },
    { key: "voucherDate", title: "过账日期", width: 120 },
    // { key: 'uploadDate', title: '上传日期', width: 120 },
    { key: "buyerName", title: "乘客姓名", width: 120 },
    { key: "amount", title: "不含税金额", format: "amount", width: 120 },
    // { key: 'totalAmount', title: '税额', format: 'amount' },
    { key: "withTaxAmount", title: "含税金额", width: 160, format: "amount" },
    // { key: 'startStation', title: '始发站', width: 100, align: 'center' },
    // { key: 'endStation', title: '到达站', width: 200 },
    // { key: 'source', title: '数据来源', width: 200, render: sourceRender },
    // { key: 'deductionFlag', title: '是否可抵扣', width: 120 },
    // { key: 'uploadDate', title: '创建时间', width: 200 },
    // { key: 'uploader', title: '创建用户', width: 200 },
    { key: "documentAccount", title: "记账人", width: 200 },
    {
      key: "id",
      title: "操作",
      width: 160,
      fixed: "right",
      align: "center",
      render(id, row: TableRow) {
        return (
          <Space>
            <LinkText
              label="查看"
              onClick={() => handleViewInvoice(row.picUrl)}
            />
            <Popconfirm
              title="您确认要删除吗?"
              onConfirm={() => handleDelete(id)}
            >
              <PermissionButton
                permissions="InputOtherInv.delOtherInvoice"
                type="link"
              >
                删除
              </PermissionButton>
            </Popconfirm>
          </Space>
        );
      },
    },
  ];

  const bridgeColumns: ColumnProps[] = [
    { key: "invoiceNo", title: "发票号码", width: 90, fixed: "left" },
    { key: "invoiceCode", title: "发票代码", width: 100, fixed: "left" },
    { key: "voucherNo", title: "记账凭证号", width: 200 },
    { key: "invoiceDate", title: "开票日期", width: 200 },
    { key: "voucherDate", title: "过账日期", width: 120 },
    // { key: 'uploadDate', title: '上传日期', width: 120 },
    { key: "amount", title: "不含税金额", format: "amount", width: 120 },
    { key: "taxAmount", title: "税额", format: "amount" },
    { key: "withTaxAmount", title: "含税金额", width: 180, format: "amount" },
    // { key: 'startStation', title: '入口', width: 120, align: 'center' },
    // { key: 'endStation', title: '出口', width: 120 },
    // { key: 'source', title: '数据来源', width: 200, render: sourceRender },
    // { key: 'deductionFlag', title: '是否可抵扣', width: 120 },
    // { key: 'uploadDate', title: '创建时间', width: 200 },
    // { key: 'uploader', title: '创建用户', width: 200 },
    { key: "documentAccount", title: "记账人", width: 200 },
    {
      key: "id",
      title: "操作",
      width: 160,
      fixed: "right",
      align: "center",
      render(id, row: TableRow) {
        return (
          <Space>
            <LinkText
              label="查看"
              onClick={() => handleViewInvoice(row.picUrl)}
            />
            <Popconfirm
              title="您确认要删除吗?"
              onConfirm={() => handleDelete(id)}
            >
              <PermissionButton
                permissions="InputOtherInv.delOtherInvoice"
                type="link"
              >
                删除
              </PermissionButton>
            </Popconfirm>
          </Space>
        );
      },
    },
  ];

  const generalColumns: ColumnProps[] = [
    { key: "invoiceNo", title: "发票号码", width: 90, fixed: "left" },
    { key: "invoiceCode", title: "发票代码", width: 100, fixed: "left" },
    { key: "voucherNo", title: "记账凭证号", width: 200 },
    { key: "invoiceDate", title: "开票日期", width: 160, align: "center" },
    { key: "voucherDate", title: "过账日期", width: 120 },
    // { key: 'uploadDate', title: '上传日期', width: 120 },
    { key: "amount", title: "金额", width: 160, format: "amount" },
    { key: "sellerName", title: "销方名称", width: 160, align: "center" },
    { key: "sellerTaxNo", title: "销方税号", width: 180 },
    // { key: 'source', title: '数据来源', width: 120, render: sourceRender },
    // { key: 'deductionFlag', title: '是否可抵扣', width: 120 },
    // { key: 'uploadDate', title: '创建时间', width: 200 },
    // { key: 'uploader', title: '创建用户', width: 200 },
    { key: "documentAccount", title: "记账人", width: 200 },
    {
      key: "id",
      title: "操作",
      width: 160,
      fixed: "right",
      align: "center",
      render(id, row: TableRow) {
        return (
          <Space>
            <LinkText
              label="查看"
              onClick={() => handleViewInvoice(row.picUrl)}
            />
            <Popconfirm
              title="您确认要删除吗?"
              onConfirm={() => handleDelete(id)}
            >
              <PermissionButton
                permissions="InputOtherInv.delOtherInvoice"
                type="link"
              >
                删除
              </PermissionButton>
            </Popconfirm>
          </Space>
        );
      },
    },
  ];

  const quotaColumns: ColumnProps[] = [
    { key: "invoiceNo", title: "发票号码", width: 90, fixed: "left" },
    { key: "invoiceCode", title: "发票代码", width: 100, fixed: "left" },
    { key: "voucherNo", title: "记账凭证号", width: 200 },
    { key: "voucherDate", title: "过账日期", width: 120 },
    // { key: 'uploadDate', title: '上传日期', width: 120 },
    { key: "withTaxAmount", title: "金额", width: 140, format: "amount" },
    { key: "sellerName", title: "销方名称", width: 180, align: "center" },
    { key: "sellerTaxNo", title: "销方税号", width: 160 },
    // { key: 'source', title: '数据来源', render: sourceRender },
    // { key: 'deductionFlag', title: '是否可抵扣', width: 120 },
    // { key: 'uploadDate', title: '创建时间', width: 180, },
    // { key: 'uploader', title: '创建用户', width: 200 },
    { key: "documentAccount", title: "记账人", width: 200 },
    {
      key: "id",
      title: "操作",
      width: 160,
      fixed: "right",
      align: "center",
      render(id, row: TableRow) {
        return (
          <Space>
            <LinkText
              label="查看"
              onClick={() => handleViewInvoice(row.picUrl)}
            />
            <Popconfirm
              title="您确认要删除吗?"
              onConfirm={() => handleDelete(id)}
            >
              <PermissionButton
                permissions="InputOtherInv.delOtherInvoice"
                type="link"
              >
                删除
              </PermissionButton>
            </Popconfirm>
          </Space>
        );
      },
    },
  ];

  const otherColumns: ColumnProps[] = [
    { key: "invoiceNo", title: "票据号码", width: 90, fixed: "left" },
    { key: "voucherNo", title: "记账凭证号", width: 200 },
    { key: "invoiceDate", title: "票据日期", width: 100 },
    { key: "voucherDate", title: "过账日期", width: 120 },
    // { key: 'uploadDate', title: '上传日期', width: 120 },
    { key: "amount", title: "金额", width: 120, format: "amount" },
    { key: "sellerName", title: "销方名称", width: 160, align: "center" },
    { key: "sellerTaxNo", title: "销方税号", width: 120 },
    // { key: 'source', title: '数据来源', render: sourceRender },
    // { key: 'deductionFlag', title: '是否可抵扣', width: 120 },
    // { key: 'uploadDate', title: '创建时间' },
    // { key: 'uploader', title: '创建用户', width: 180, },
    { key: "documentAccount", title: "记账人", width: 200 },
    {
      key: "id",
      title: "操作",
      width: 160,
      fixed: "right",
      align: "center",
      render(id, row: TableRow) {
        return (
          <Space>
            <LinkText
              label="查看"
              onClick={() => handleViewInvoice(row.picUrl)}
            />
            <Popconfirm
              title="您确认要删除吗?"
              onConfirm={() => handleDelete(id)}
            >
              <PermissionButton
                permissions="InputOtherInv.delOtherInvoice"
                type="link"
              >
                删除
              </PermissionButton>
            </Popconfirm>
          </Space>
        );
      },
    },
  ];

  return (
    <Page>
      {modalHolder}
      <Card
        title="其他票据管理"
        className="card"
        actions={
          <Space className="card-space">
            <PermissionButton
              permissions="InputOtherInv.exportOtherInvoice"
              type="primary"
              onClick={handleExport}
            >
              导出
            </PermissionButton>
            <PermissionButton
              permissions="InputOtherInv.delOtherInvoice"
              type="primary"
              onClick={handleBatchDelete}
            >
              批量删除
            </PermissionButton>
          </Space>
        }
      >
        <Select
          value={currentBillType}
          onChange={handleBillTypeChange}
          style={{ position: "absolute", width: 180, left: 160, top: 10 }}
        >
          <Option value="">全部</Option>
          <Option value="18">客运汽车票</Option>
          <Option value="17">火车票</Option>
          <Option value="19">航空运输电子行程单</Option>
          <Option value="20">出租车票</Option>
          <Option value="21">船票</Option>
          <Option value="22">过桥过闸通行费发票</Option>
          <Option value="23">通用机打发票</Option>
          <Option value="24">定额发票</Option>
          <Option value="99">其他</Option>
        </Select>

        {/* 全部 */}
        {currentBillType === BillType.ALL_TYPE ? (
          <Table
            url="/input/biz/getOtherInvoices"
            queryProps={{ initialValues: initialFormData }}
            selectedRowKeys={[]}
            pagination={{ hideOnSinglePage: false }}
            columns={allTypeColumns}
            onTableRef={onTableRef}
            getTableSummaryInfo={getTableSummaryInfo}
            scroll={{ y: window.innerHeight - 300 }}
            summary={(invoices, selectIds) => {
              const selectInvoices = invoices.filter((invoice) =>
                selectIds?.includes(invoice.id)
              );
              const invoiceTotal = selectInvoices.reduce(
                (total, invoice) => {
                  total.invoiceAmount += invoice.amount;
                  total.taxAmount += invoice.taxAmount;
                  total.totalAmount += invoice.withTaxAmount;
                  return total;
                },
                {
                  invoiceAmount: 0,
                  taxAmount: 0,
                  totalAmount: 0,
                }
              );
              return (
                <>
                  <AdTable.Summary.Row style={{ height: 36 }}>
                    <Row
                      gutter={14}
                      style={{
                        position: "absolute",
                        left: "10px",
                        right: "0",
                        zIndex: 100,
                      }}
                    >
                      <Col span={6}>
                        选中数量: {selectInvoices?.length || 0}
                      </Col>
                      <Col span={6}>
                        选中不含税金额:{" "}
                        {Math.num2money(invoiceTotal.invoiceAmount)}
                      </Col>
                      <Col span={6}>
                        选中税额: {Math.num2money(invoiceTotal.taxAmount)}
                      </Col>
                      <Col span={6}>
                        选中含税金额: {Math.num2money(invoiceTotal.totalAmount)}
                      </Col>
                    </Row>
                  </AdTable.Summary.Row>
                  <AdTable.Summary.Row style={{ height: 36 }}>
                    <Row
                      gutter={14}
                      style={{
                        position: "absolute",
                        left: "10px",
                        right: "0",
                        zIndex: 100,
                      }}
                    >
                      <Col span={6}>全部数量: {tableSummaryInfo.nums}</Col>
                      <Col span={6}>
                        全部不含税金额:{" "}
                        {Math.num2money(tableSummaryInfo.sumAmount)}
                      </Col>
                      <Col span={6}>
                        全部税额:{" "}
                        {Math.num2money(tableSummaryInfo.sumTaxAmount)}
                      </Col>
                      <Col span={6}>
                        全部含税金额:{" "}
                        {Math.num2money(tableSummaryInfo.sumWithTaxAmount)}
                      </Col>
                    </Row>
                  </AdTable.Summary.Row>
                </>
              );
            }}
          >
            <Item name="name">
              <Input
                placeholder="发票号码/乘客姓名/记账人"
                title="发票号码/乘客姓名/记账人"
              />
            </Item>
            <Item name="invDateStr">
              <RangePicker
                placeholder={["乘车日期起", "乘车日期止"]}
                format="YYYYMMDD"
                inputReadOnly
              />
            </Item>
            {/* <Item name="source">
              <Select placeholder="数据来源" mode="multiple">
                <Option value="7">接口传入</Option>
                <Option value="4">手工录入</Option>
                <Option value="2">扫描仪录入</Option>
                <Option value="10">高拍仪录入</Option>
                <Option value="11">图片导入</Option>
                <Option value="5,6,8">文件导入</Option>
                <Option value="9">移动端录入</Option>
              </Select>
            </Item> */}
            <Item name="accountStatus">
              <Select placeholder="记账状态" allowClear>
                <Option value="">全部</Option>
                <Option value="1">已记账</Option>
                <Option value="0">未记账</Option>
              </Select>
            </Item>
            <Item name="voucherDateFromTo">
              <RangePicker
                placeholder={["过账日期起", "过账日期止"]}
                format="YYYYMMDD"
                style={{ width: "100%" }}
                inputReadOnly
              />
            </Item>
            {/* <Item name='createDateFromTo'>
              <RangePicker placeholder={['上传日期起', '上传日期止']} format='YYYYMMDD' style={{ width: '100%' }} inputReadOnly/>
            </Item> */}
            <Item name="voucherNo">
              <Input placeholder="记账凭证号" allowClear />
            </Item>
            {/* <Item name="deductionFlag">
              <Select placeholder="是否可抵扣" allowClear>
                <Option value={1}>是</Option>
                <Option value={0}>否</Option>
              </Select>
            </Item> */}
          </Table>
        ) : null}

        {/* 火车票 */}
        {currentBillType === BillType.TRAIN ? (
          <Table
            url="/input/biz/getOtherInvoices?invType=17"
            queryProps={{ initialValues: initialFormData }}
            selectedRowKeys={[]}
            pagination={{ hideOnSinglePage: false }}
            columns={trainColumns}
            onTableRef={onTableRef}
            getTableSummaryInfo={getTableSummaryInfo}
            summary={(invoices, selectIds) => {
              const selectInvoices = invoices.filter((invoice) =>
                selectIds?.includes(invoice.id)
              );
              const invoiceTotal = selectInvoices.reduce(
                (total, invoice) => {
                  total.invoiceAmount += invoice.amount;
                  total.taxAmount += invoice.taxAmount;
                  total.totalAmount += invoice.withTaxAmount;
                  return total;
                },
                {
                  invoiceAmount: 0,
                  taxAmount: 0,
                  totalAmount: 0,
                }
              );
              return (
                <>
                  <AdTable.Summary.Row style={{ height: 36 }}>
                    <Row
                      gutter={14}
                      style={{
                        position: "absolute",
                        left: "10px",
                        right: "0",
                        zIndex: 100,
                      }}
                    >
                      <Col span={6}>
                        选中数量: {selectInvoices?.length || 0}
                      </Col>
                      <Col span={6}>
                        选中不含税金额:{" "}
                        {Math.num2money(invoiceTotal.invoiceAmount)}
                      </Col>
                      <Col span={6}>
                        选中税额: {Math.num2money(invoiceTotal.taxAmount)}
                      </Col>
                      <Col span={6}>
                        选中含税金额: {Math.num2money(invoiceTotal.totalAmount)}
                      </Col>
                    </Row>
                  </AdTable.Summary.Row>
                  <AdTable.Summary.Row style={{ height: 36 }}>
                    <Row
                      gutter={14}
                      style={{
                        position: "absolute",
                        left: "10px",
                        right: "0",
                        zIndex: 100,
                      }}
                    >
                      <Col span={6}>全部数量: {tableSummaryInfo.nums}</Col>
                      <Col span={6}>
                        全部不含税金额:{" "}
                        {Math.num2money(tableSummaryInfo.sumAmount)}
                      </Col>
                      <Col span={6}>
                        全部税额:{" "}
                        {Math.num2money(tableSummaryInfo.sumTaxAmount)}
                      </Col>
                      <Col span={6}>
                        全部含税金额:{" "}
                        {Math.num2money(tableSummaryInfo.sumWithTaxAmount)}
                      </Col>
                    </Row>
                  </AdTable.Summary.Row>
                </>
              );
            }}
          >
            <Item name="name">
              <Input
                placeholder="红色票号/乘客姓名/记账人"
                title="红色票号/乘客姓名/记账人"
              />
            </Item>
            <Item name="invDateStr">
              <RangePicker
                placeholder={["乘车日期起", "乘车日期止"]}
                format="YYYYMMDD"
                inputReadOnly
              />
            </Item>
            {/* <Item name="source">
              <Select placeholder="数据来源" mode="multiple">
                <Option value="7">接口传入</Option>
                <Option value="4">手工录入</Option>
                <Option value="2">扫描仪录入</Option>
                <Option value="10">高拍仪录入</Option>
                <Option value="11">图片导入</Option>
                <Option value="5,6,8">文件导入</Option>
                <Option value="9">移动端录入</Option>
              </Select>
            </Item> */}
            <Item name="voucherDateFromTo">
              <RangePicker
                placeholder={["过账日期起", "过账日期止"]}
                format="YYYYMMDD"
                style={{ width: "100%" }}
                inputReadOnly
              />
            </Item>
            {/* <Item name='createDateFromTo'>
              <RangePicker placeholder={['上传日期起', '上传日期止']} format='YYYYMMDD' style={{ width: '100%' }} inputReadOnly/>
            </Item> */}
            <Item name="voucherNo">
              <Input placeholder="记账凭证号" allowClear />
            </Item>
            {/* <Item name="deductionFlag">
              <Select placeholder="是否可抵扣" allowClear>
                <Option value={1}>是</Option>
                <Option value={0}>否</Option>
              </Select>
            </Item> */}
          </Table>
        ) : null}

        {/* 客运汽车票 */}
        {currentBillType === BillType.PASSENGER_CAR ? (
          <Table
            url="/input/biz/getOtherInvoices?invType=18"
            queryProps={{ initialValues: initialFormData }}
            selectedRowKeys={[]}
            pagination={{ hideOnSinglePage: false }}
            columns={passengerColumns}
            onTableRef={onTableRef}
            getTableSummaryInfo={getTableSummaryInfo}
            summary={(invoices, selectIds) => {
              const selectInvoices = invoices.filter((invoice) =>
                selectIds?.includes(invoice.id)
              );
              const invoiceTotal = selectInvoices.reduce(
                (total, invoice) => {
                  total.invoiceAmount += invoice.amount;
                  total.taxAmount += invoice.taxAmount;
                  total.totalAmount += invoice.withTaxAmount;
                  return total;
                },
                {
                  invoiceAmount: 0,
                  taxAmount: 0,
                  totalAmount: 0,
                }
              );
              return (
                <>
                  <AdTable.Summary.Row style={{ height: 36 }}>
                    <Row
                      gutter={14}
                      style={{
                        position: "absolute",
                        left: "10px",
                        right: "0",
                        zIndex: 100,
                      }}
                    >
                      <Col span={6}>
                        选中数量: {selectInvoices?.length || 0}
                      </Col>
                      <Col span={6}>
                        选中不含税金额:{" "}
                        {Math.num2money(invoiceTotal.invoiceAmount)}
                      </Col>
                      <Col span={6}>
                        选中税额: {Math.num2money(invoiceTotal.taxAmount)}
                      </Col>
                      <Col span={6}>
                        选中含税金额: {Math.num2money(invoiceTotal.totalAmount)}
                      </Col>
                    </Row>
                  </AdTable.Summary.Row>
                  <AdTable.Summary.Row style={{ height: 36 }}>
                    <Row
                      gutter={14}
                      style={{
                        position: "absolute",
                        left: "10px",
                        right: "0",
                        zIndex: 100,
                      }}
                    >
                      <Col span={6}>全部数量: {tableSummaryInfo.nums}</Col>
                      <Col span={6}>
                        全部不含税金额:{" "}
                        {Math.num2money(tableSummaryInfo.sumAmount)}
                      </Col>
                      <Col span={6}>
                        全部税额:{" "}
                        {Math.num2money(tableSummaryInfo.sumTaxAmount)}
                      </Col>
                      <Col span={6}>
                        全部含税金额:{" "}
                        {Math.num2money(tableSummaryInfo.sumWithTaxAmount)}
                      </Col>
                    </Row>
                  </AdTable.Summary.Row>
                </>
              );
            }}
          >
            <Item name="name">
              <Input
                placeholder="发票代码/发票代码/乘客姓名/记账人"
                title="发票代码/发票代码/乘客姓名/记账人"
              />
            </Item>
            <Item name="invDateStr">
              <RangePicker
                placeholder={["乘车日期起", "乘车日期止"]}
                format="YYYYMMDD"
                inputReadOnly
              />
            </Item>
            {/* <Item name="source">
              <Select placeholder="数据来源" mode="multiple">
                <Option value="7">接口传入</Option>
                <Option value="4">手工录入</Option>
                <Option value="2">扫描仪录入</Option>
                <Option value="10">高拍仪录入</Option>
                <Option value="11">图片导入</Option>
                <Option value="5,6,8">文件导入</Option>
                <Option value="9">移动端录入</Option>
              </Select>
            </Item> */}
            <Item name="voucherDateFromTo">
              <RangePicker
                placeholder={["过账日期起", "过账日期止"]}
                format="YYYYMMDD"
                style={{ width: "100%" }}
                inputReadOnly
              />
            </Item>
            {/* <Item name='createDateFromTo'>
              <RangePicker placeholder={['上传日期起', '上传日期止']} format='YYYYMMDD' style={{ width: '100%' }} inputReadOnly/>
            </Item> */}
            <Item name="voucherNo">
              <Input placeholder="记账凭证号" allowClear />
            </Item>
            {/* <Item name="deductionFlag">
              <Select placeholder="是否可抵扣" allowClear>
                <Option value={1}>是</Option>
                <Option value={0}>否</Option>
              </Select>
            </Item> */}
          </Table>
        ) : null}

        {/* 航空客票 */}
        {currentBillType === BillType.AVIATION ? (
          <Table
            url="/input/biz/getOtherInvoices?invType=19"
            queryProps={{ initialValues: initialFormData }}
            selectedRowKeys={[]}
            pagination={{ hideOnSinglePage: false }}
            columns={airColumns}
            onTableRef={onTableRef}
            getTableSummaryInfo={getTableSummaryInfo}
            summary={(invoices, selectIds) => {
              const selectInvoices = invoices.filter((invoice) =>
                selectIds?.includes(invoice.id)
              );
              const invoiceTotal = selectInvoices.reduce(
                (total, invoice) => {
                  total.invoiceAmount += invoice.amount;
                  total.taxAmount += invoice.taxAmount;
                  total.totalAmount += invoice.withTaxAmount;
                  return total;
                },
                {
                  invoiceAmount: 0,
                  taxAmount: 0,
                  totalAmount: 0,
                }
              );
              return (
                <>
                  <AdTable.Summary.Row style={{ height: 36 }}>
                    <Row
                      gutter={14}
                      style={{
                        position: "absolute",
                        left: "10px",
                        right: "0",
                        zIndex: 100,
                      }}
                    >
                      <Col span={8}>
                        选中数量: {selectInvoices?.length || 0}
                      </Col>
                      <Col span={8}>
                        选中税额: {Math.num2money(invoiceTotal.taxAmount)}
                      </Col>
                      <Col span={8}>
                        选中合计金额: {Math.num2money(invoiceTotal.totalAmount)}
                      </Col>
                    </Row>
                  </AdTable.Summary.Row>

                  <AdTable.Summary.Row style={{ height: 36 }}>
                    <Row
                      gutter={14}
                      style={{
                        position: "absolute",
                        left: "10px",
                        right: "0",
                        zIndex: 100,
                      }}
                    >
                      <Col span={8}>全部数量: {tableSummaryInfo.nums}</Col>
                      <Col span={8}>
                        全部税额:{" "}
                        {Math.num2money(tableSummaryInfo.sumTaxAmount)}
                      </Col>
                      <Col span={8}>
                        全部合计金额:{" "}
                        {Math.num2money(tableSummaryInfo.sumWithTaxAmount)}
                      </Col>
                    </Row>
                  </AdTable.Summary.Row>
                </>
              );
            }}
          >
            <Item name="name">
              <Input
                placeholder="电子客票号码/记账人/乘客姓名"
                title="电子客票号码/记账人/乘客姓名"
              />
            </Item>
            <Item name="invDateStr">
              <RangePicker
                placeholder={["乘机日期起", "乘机日期止"]}
                format="YYYYMMDD"
                inputReadOnly
              />
            </Item>
            {/* <Item name="source">
              <Select placeholder="数据来源" mode="multiple">
                <Option value="7">接口传入</Option>
                <Option value="4">手工录入</Option>
                <Option value="2">扫描仪录入</Option>
                <Option value="10">高拍仪录入</Option>
                <Option value="11">图片导入</Option>
                <Option value="5,6,8">文件导入</Option>
                <Option value="9">移动端录入</Option>
              </Select>
            </Item> */}
            <Item name="buyerName">
              <Input placeholder="乘客姓名" />
            </Item>
            <Item name="voucherDateFromTo">
              <RangePicker
                placeholder={["过账日期起", "过账日期止"]}
                format="YYYYMMDD"
                style={{ width: "100%" }}
                inputReadOnly
              />
            </Item>
            {/* <Item name='createDateFromTo'>
              <RangePicker placeholder={['上传日期起', '上传日期止']} format='YYYYMMDD' style={{ width: '100%' }} inputReadOnly/>
            </Item> */}
            <Item name="voucherNo">
              <Input placeholder="记账凭证号" allowClear />
            </Item>
            {/* <Item name="deductionFlag">
              <Select placeholder="是否可抵扣" allowClear>
                <Option value={1}>是</Option>
                <Option value={0}>否</Option>
              </Select>
            </Item> */}
          </Table>
        ) : null}

        {/* 出租车票 */}
        {currentBillType === BillType.TAXI ? (
          <Table
            url="/input/biz/getOtherInvoices?invType=20"
            queryProps={{ initialValues: initialFormData }}
            selectedRowKeys={[]}
            pagination={{ hideOnSinglePage: false }}
            columns={taxiColumns}
            onTableRef={onTableRef}
            getTableSummaryInfo={getTableSummaryInfo}
            summary={(invoices, selectIds) => {
              const selectInvoices = invoices.filter((invoice) =>
                selectIds?.includes(invoice.id)
              );
              const invoiceTotal = selectInvoices.reduce(
                (total, invoice) => {
                  total.invoiceAmount += invoice.amount;
                  total.taxAmount += invoice.taxAmount;
                  total.totalAmount += invoice.withTaxAmount;
                  return total;
                },
                {
                  invoiceAmount: 0,
                  taxAmount: 0,
                  totalAmount: 0,
                }
              );
              return (
                <>
                  <AdTable.Summary.Row style={{ height: 36 }}>
                    <Row
                      gutter={14}
                      style={{
                        position: "absolute",
                        left: "10px",
                        right: "0",
                        zIndex: 100,
                      }}
                    >
                      <Col span={12}>
                        选中数量: {selectInvoices?.length || 0}
                      </Col>
                      <Col span={12}>
                        选中金额: {Math.num2money(invoiceTotal.invoiceAmount)}
                      </Col>
                    </Row>
                  </AdTable.Summary.Row>

                  <AdTable.Summary.Row style={{ height: 36 }}>
                    <Row
                      gutter={14}
                      style={{
                        position: "absolute",
                        left: "10px",
                        right: "0",
                        zIndex: 100,
                      }}
                    >
                      <Col span={12}>全部数量: {tableSummaryInfo.nums}</Col>
                      <Col span={12}>
                        全部金额: {Math.num2money(tableSummaryInfo.sumAmount)}
                      </Col>
                    </Row>
                  </AdTable.Summary.Row>
                </>
              );
            }}
          >
            <Item name="name">
              <Input
                placeholder="发票号码/发票代码/记账人"
                title="发票号码/发票代码/记账人"
              />
            </Item>
            <Item name="invDateStr">
              <RangePicker
                placeholder={["乘车日期起", "乘车日期止"]}
                format="YYYYMMDD"
                inputReadOnly
              />
            </Item>
            {/* <Item name="source">
              <Select placeholder="数据来源" mode="multiple">
                <Option value="7">接口传入</Option>
                <Option value="4">手工录入</Option>
                <Option value="2">扫描仪录入</Option>
                <Option value="10">高拍仪录入</Option>
                <Option value="11">图片导入</Option>
                <Option value="5,6,8">文件导入</Option>
                <Option value="9">移动端录入</Option>
              </Select>
            </Item> */}
            <Item name="voucherDateFromTo">
              <RangePicker
                placeholder={["过账日期起", "过账日期止"]}
                format="YYYYMMDD"
                style={{ width: "100%" }}
                inputReadOnly
              />
            </Item>
            {/* <Item name='createDateFromTo'>
              <RangePicker placeholder={['上传日期起', '上传日期止']} format='YYYYMMDD' style={{ width: '100%' }} inputReadOnly/>
            </Item> */}
            <Item name="voucherNo">
              <Input placeholder="记账凭证号" allowClear />
            </Item>
            {/* <Item name="deductionFlag">
              <Select placeholder="是否可抵扣" allowClear>
                <Option value={1}>是</Option>
                <Option value={0}>否</Option>
              </Select>
            </Item> */}
          </Table>
        ) : null}

        {/* 船票 */}
        {currentBillType === BillType.SHIP ? (
          <Table
            url="/input/biz/getOtherInvoices?invType=21"
            queryProps={{ initialValues: initialFormData }}
            selectedRowKeys={[]}
            pagination={{ hideOnSinglePage: false }}
            columns={shipColumns}
            onTableRef={onTableRef}
            getTableSummaryInfo={getTableSummaryInfo}
            summary={(invoices, selectIds) => {
              const selectInvoices = invoices.filter((invoice) =>
                selectIds?.includes(invoice.id)
              );
              const invoiceTotal = selectInvoices.reduce(
                (total, invoice) => {
                  total.invoiceAmount += invoice.amount;
                  total.taxAmount += invoice.taxAmount;
                  total.totalAmount += invoice.withTaxAmount;
                  return total;
                },
                {
                  invoiceAmount: 0,
                  taxAmount: 0,
                  totalAmount: 0,
                }
              );
              return (
                <>
                  <AdTable.Summary.Row style={{ height: 36 }}>
                    <Row
                      gutter={14}
                      style={{
                        position: "absolute",
                        left: "10px",
                        right: "0",
                        zIndex: 100,
                      }}
                    >
                      <Col span={6}>
                        选中数量: {selectInvoices?.length || 0}
                      </Col>
                      <Col span={6}>
                        选中不含税金额:{" "}
                        {Math.num2money(invoiceTotal.invoiceAmount)}
                      </Col>
                      <Col span={6}>
                        选中税额: {Math.num2money(invoiceTotal.taxAmount)}
                      </Col>
                      <Col span={6}>
                        选中含税金额: {Math.num2money(invoiceTotal.totalAmount)}
                      </Col>
                    </Row>
                  </AdTable.Summary.Row>

                  <AdTable.Summary.Row style={{ height: 36 }}>
                    <Row
                      gutter={14}
                      style={{
                        position: "absolute",
                        left: "10px",
                        right: "0",
                        zIndex: 100,
                      }}
                    >
                      <Col span={6}>全部数量: {tableSummaryInfo.nums}</Col>
                      <Col span={6}>
                        全部不含税金额:{" "}
                        {Math.num2money(tableSummaryInfo.sumAmount)}
                      </Col>
                      <Col span={6}>
                        全部税额:{" "}
                        {Math.num2money(tableSummaryInfo.sumTaxAmount)}
                      </Col>
                      <Col span={6}>
                        全部含税金额:{" "}
                        {Math.num2money(tableSummaryInfo.sumWithTaxAmount)}
                      </Col>
                    </Row>
                  </AdTable.Summary.Row>
                </>
              );
            }}
          >
            <Item name="name">
              <Input
                placeholder="发票号码/发票代码/乘客姓名/记账人"
                title="发票号码/发票代码/乘客姓名/记账人"
              />
            </Item>
            <Item name="invDateStr">
              <RangePicker
                placeholder={["开船日期起", "开船日期止"]}
                format="YYYYMMDD"
                inputReadOnly
              />
            </Item>
            {/* <Item name="source">
              <Select placeholder="数据来源" mode="multiple">
                <Option value="7">接口传入</Option>
                <Option value="4">手工录入</Option>
                <Option value="2">扫描仪录入</Option>
                <Option value="10">高拍仪录入</Option>
                <Option value="11">图片导入</Option>
                <Option value="5,6,8">文件导入</Option>
                <Option value="9">移动端录入</Option>
              </Select>
            </Item> */}
            <Item name="voucherDateFromTo">
              <RangePicker
                placeholder={["过账日期起", "过账日期止"]}
                format="YYYYMMDD"
                style={{ width: "100%" }}
                inputReadOnly
              />
            </Item>
            {/* <Item name='createDateFromTo'>
              <RangePicker placeholder={['上传日期起', '上传日期止']} format='YYYYMMDD' style={{ width: '100%' }} inputReadOnly/>
            </Item> */}
            <Item name="voucherNo">
              <Input placeholder="记账凭证号" allowClear />
            </Item>
            {/* <Item name="deductionFlag">
              <Select placeholder="是否可抵扣" allowClear>
                <Option value={1}>是</Option>
                <Option value={0}>否</Option>
              </Select>
            </Item> */}
          </Table>
        ) : null}

        {/* 过桥过闸通行费发票 */}
        {currentBillType === BillType.BRIDGE ? (
          <Table
            url="/input/biz/getOtherInvoices?invType=22"
            queryProps={{ initialValues: initialFormData }}
            selectedRowKeys={[]}
            pagination={{ hideOnSinglePage: false }}
            columns={bridgeColumns}
            onTableRef={onTableRef}
            getTableSummaryInfo={getTableSummaryInfo}
            summary={(invoices, selectIds) => {
              const selectInvoices = invoices.filter((invoice) =>
                selectIds?.includes(invoice.id)
              );
              const invoiceTotal = selectInvoices.reduce(
                (total, invoice) => {
                  total.invoiceAmount += invoice.amount;
                  total.taxAmount += invoice.taxAmount;
                  total.totalAmount += invoice.withTaxAmount;
                  return total;
                },
                {
                  invoiceAmount: 0,
                  taxAmount: 0,
                  totalAmount: 0,
                }
              );
              return (
                <>
                  <AdTable.Summary.Row style={{ height: 36 }}>
                    <Row
                      gutter={14}
                      style={{
                        position: "absolute",
                        left: "10px",
                        right: "0",
                        zIndex: 100,
                      }}
                    >
                      <Col span={6}>
                        选中数量: {selectInvoices?.length || 0}
                      </Col>
                      <Col span={6}>
                        选中不含税金额:{" "}
                        {Math.num2money(invoiceTotal.invoiceAmount)}
                      </Col>
                      <Col span={6}>
                        选中税额: {Math.num2money(invoiceTotal.taxAmount)}
                      </Col>
                      <Col span={6}>
                        选中含税金额: {Math.num2money(invoiceTotal.totalAmount)}
                      </Col>
                    </Row>
                  </AdTable.Summary.Row>

                  <AdTable.Summary.Row style={{ height: 36 }}>
                    <Row
                      gutter={14}
                      style={{
                        position: "absolute",
                        left: "10px",
                        right: "0",
                        zIndex: 100,
                      }}
                    >
                      <Col span={6}>全部数量: {tableSummaryInfo.nums}</Col>
                      <Col span={6}>
                        全部不含税金额:{" "}
                        {Math.num2money(tableSummaryInfo.sumAmount)}
                      </Col>
                      <Col span={6}>
                        全部税额:{" "}
                        {Math.num2money(tableSummaryInfo.sumTaxAmount)}
                      </Col>
                      <Col span={6}>
                        全部含税金额:{" "}
                        {Math.num2money(tableSummaryInfo.sumWithTaxAmount)}
                      </Col>
                    </Row>
                  </AdTable.Summary.Row>
                </>
              );
            }}
          >
            <Item name="name">
              <Input
                placeholder="发票号码/发票代码/记账人"
                title="发票号码/发票代码/记账人"
              />
            </Item>
            <Item name="invDateStr">
              <RangePicker
                placeholder={["开票日期起", "开票日期止"]}
                format="YYYYMMDD"
                inputReadOnly
              />
            </Item>
            {/* <Item name="source">
              <Select placeholder="数据来源" mode="multiple">
                <Option value="7">接口传入</Option>
                <Option value="4">手工录入</Option>
                <Option value="2">扫描仪录入</Option>
                <Option value="10">高拍仪录入</Option>
                <Option value="11">图片导入</Option>
                <Option value="5,6,8">文件导入</Option>
                <Option value="9">移动端录入</Option>
              </Select>
            </Item> */}
            <Item name="voucherDateFromTo">
              <RangePicker
                placeholder={["过账日期起", "过账日期止"]}
                format="YYYYMMDD"
                style={{ width: "100%" }}
                inputReadOnly
              />
            </Item>
            {/* <Item name='createDateFromTo'>
              <RangePicker placeholder={['上传日期起', '上传日期止']} format='YYYYMMDD' style={{ width: '100%' }} inputReadOnly/>
            </Item> */}
            <Item name="voucherNo">
              <Input placeholder="记账凭证号" allowClear />
            </Item>
            {/* <Item name="deductionFlag">
              <Select placeholder="是否可抵扣" allowClear>
                <Option value={1}>是</Option>
                <Option value={0}>否</Option>
              </Select>
            </Item> */}
          </Table>
        ) : null}

        {/* 通用机打发票 */}
        {currentBillType === BillType.MACHINE ? (
          <Table
            url="/input/biz/getOtherInvoices?invType=23"
            queryProps={{ initialValues: initialFormData }}
            selectedRowKeys={[]}
            pagination={{ hideOnSinglePage: false }}
            columns={generalColumns}
            onTableRef={onTableRef}
            getTableSummaryInfo={getTableSummaryInfo}
            summary={(invoices, selectIds) => {
              const selectInvoices = invoices.filter((invoice) =>
                selectIds?.includes(invoice.id)
              );
              const invoiceTotal = selectInvoices.reduce(
                (total, invoice) => {
                  total.invoiceAmount += invoice.amount;
                  total.taxAmount += invoice.taxAmount;
                  total.totalAmount += invoice.withTaxAmount;
                  return total;
                },
                {
                  invoiceAmount: 0,
                  taxAmount: 0,
                  totalAmount: 0,
                }
              );
              return (
                <>
                  <AdTable.Summary.Row style={{ height: 36 }}>
                    <Row
                      gutter={14}
                      style={{
                        position: "absolute",
                        left: "10px",
                        right: "0",
                        zIndex: 100,
                      }}
                    >
                      <Col span={12}>
                        选中数量: {selectInvoices?.length || 0}
                      </Col>
                      <Col span={12}>
                        选中金额: {Math.num2money(invoiceTotal.invoiceAmount)}
                      </Col>
                    </Row>
                  </AdTable.Summary.Row>

                  <AdTable.Summary.Row style={{ height: 36 }}>
                    <Row
                      gutter={14}
                      style={{
                        position: "absolute",
                        left: "10px",
                        right: "0",
                        zIndex: 100,
                      }}
                    >
                      <Col span={12}>全部数量: {tableSummaryInfo.nums}</Col>
                      <Col span={12}>
                        全部金额: {Math.num2money(tableSummaryInfo.sumAmount)}
                      </Col>
                    </Row>
                  </AdTable.Summary.Row>
                </>
              );
            }}
          >
            <Item name="name">
              <Input
                placeholder="发票号码/发票代码/销方名称/销方税号/记账人"
                title="发票号码/发票代码/销方名称/销方税号/记账人"
              />
            </Item>
            <Item name="invDateStr">
              <RangePicker
                placeholder={["开票日期起", "开票日期止"]}
                format="YYYYMMDD"
                inputReadOnly
              />
            </Item>
            {/* <Item name="source">
              <Select placeholder="数据来源" mode="multiple">
                <Option value="7">接口传入</Option>
                <Option value="4">手工录入</Option>
                <Option value="2">扫描仪录入</Option>
                <Option value="10">高拍仪录入</Option>
                <Option value="11">图片导入</Option>
                <Option value="5,6,8">文件导入</Option>
                <Option value="9">移动端录入</Option>
              </Select>
            </Item> */}
            <Item name="voucherDateFromTo">
              <RangePicker
                placeholder={["过账日期起", "过账日期止"]}
                format="YYYYMMDD"
                style={{ width: "100%" }}
                inputReadOnly
              />
            </Item>
            {/* <Item name='createDateFromTo'>
              <RangePicker placeholder={['上传日期起', '上传日期止']} format='YYYYMMDD' style={{ width: '100%' }} inputReadOnly/>
            </Item> */}
            <Item name="voucherNo">
              <Input placeholder="记账凭证号" allowClear />
            </Item>
            {/* <Item name="deductionFlag">
              <Select placeholder="是否可抵扣" allowClear>
                <Option value={1}>是</Option>
                <Option value={0}>否</Option>
              </Select>
            </Item> */}
          </Table>
        ) : null}

        {/* 定额发票 */}
        {currentBillType === BillType.QUOTA ? (
          <Table
            url="/input/biz/getOtherInvoices?invType=24"
            selectedRowKeys={[]}
            pagination={{ hideOnSinglePage: false }}
            columns={quotaColumns}
            onTableRef={onTableRef}
            getTableSummaryInfo={getTableSummaryInfo}
            summary={(invoices, selectIds) => {
              const selectInvoices = invoices.filter((invoice) =>
                selectIds?.includes(invoice.id)
              );
              const invoiceTotal = selectInvoices.reduce(
                (total, invoice) => {
                  total.invoiceAmount += invoice.amount;
                  total.taxAmount += invoice.taxAmount;
                  total.totalAmount += invoice.withTaxAmount;
                  return total;
                },
                {
                  invoiceAmount: 0,
                  taxAmount: 0,
                  totalAmount: 0,
                }
              );
              return (
                <>
                  <AdTable.Summary.Row style={{ height: 36 }}>
                    <Row
                      gutter={14}
                      style={{
                        position: "absolute",
                        left: "10px",
                        right: "0",
                        zIndex: 100,
                      }}
                    >
                      <Col span={12}>
                        选中数量: {selectInvoices?.length || 0}
                      </Col>
                      <Col span={12}>
                        选中金额: {Math.num2money(invoiceTotal.totalAmount)}
                      </Col>
                    </Row>
                  </AdTable.Summary.Row>

                  <AdTable.Summary.Row style={{ height: 36 }}>
                    <Row
                      gutter={14}
                      style={{
                        position: "absolute",
                        left: "10px",
                        right: "0",
                        zIndex: 100,
                      }}
                    >
                      <Col span={12}>全部数量: {tableSummaryInfo.nums}</Col>
                      <Col span={12}>
                        全部金额:{" "}
                        {Math.num2money(tableSummaryInfo.sumWithTaxAmount)}
                      </Col>
                    </Row>
                  </AdTable.Summary.Row>
                </>
              );
            }}
          >
            <Item name="name">
              <Input
                placeholder="发票号码/发票代码/销方名称/销方税号/记账人"
                title="发票号码/发票代码/销方名称/销方税号/记账人"
              />
            </Item>
            {/* <Item name="source">
              <Select placeholder="数据来源" mode="multiple">
                <Option value="7">接口传入</Option>
                <Option value="4">手工录入</Option>
                <Option value="2">扫描仪录入</Option>
                <Option value="10">高拍仪录入</Option>
                <Option value="11">图片导入</Option>
                <Option value="5,6,8">文件导入</Option>
                <Option value="9">移动端录入</Option>
              </Select>
            </Item> */}
            <Item name="voucherDateFromTo">
              <RangePicker
                placeholder={["过账日期起", "过账日期止"]}
                format="YYYYMMDD"
                style={{ width: "100%" }}
                inputReadOnly
              />
            </Item>
            {/* <Item name='createDateFromTo'>
              <RangePicker placeholder={['上传日期起', '上传日期止']} format='YYYYMMDD' style={{ width: '100%' }} inputReadOnly/>
            </Item> */}
            <Item name="voucherNo">
              <Input placeholder="记账凭证号" allowClear />
            </Item>
            {/* <Item name="deductionFlag">
              <Select placeholder="是否可抵扣" allowClear>
                <Option value={1}>是</Option>
                <Option value={0}>否</Option>
              </Select>
            </Item> */}
          </Table>
        ) : null}

        {/* 其他发票 */}
        {currentBillType === BillType.OTHER ? (
          <Table
            url="/input/biz/getOtherInvoices?invType=99"
            queryProps={{ initialValues: initialFormData }}
            selectedRowKeys={[]}
            pagination={{ hideOnSinglePage: false }}
            columns={otherColumns}
            onTableRef={onTableRef}
            getTableSummaryInfo={getTableSummaryInfo}
            summary={(invoices, selectIds) => {
              const selectInvoices = invoices.filter((invoice) =>
                selectIds?.includes(invoice.id)
              );
              const invoiceTotal = selectInvoices.reduce(
                (total, invoice) => {
                  total.invoiceAmount += invoice.amount;
                  total.taxAmount += invoice.taxAmount;
                  total.totalAmount += invoice.withTaxAmount;
                  return total;
                },
                {
                  invoiceAmount: 0,
                  taxAmount: 0,
                  totalAmount: 0,
                }
              );
              return (
                <>
                  <AdTable.Summary.Row style={{ height: 36 }}>
                    <Row
                      gutter={14}
                      style={{
                        position: "absolute",
                        left: "10px",
                        right: "0",
                        zIndex: 100,
                      }}
                    >
                      <Col span={12}>
                        选中数量: {selectInvoices?.length || 0}
                      </Col>
                      <Col span={12}>
                        选中金额: {Math.num2money(invoiceTotal.invoiceAmount)}
                      </Col>
                    </Row>
                  </AdTable.Summary.Row>

                  <AdTable.Summary.Row style={{ height: 36 }}>
                    <Row
                      gutter={14}
                      style={{
                        position: "absolute",
                        left: "10px",
                        right: "0",
                        zIndex: 100,
                      }}
                    >
                      <Col span={12}>全部数量: {tableSummaryInfo.nums}</Col>
                      <Col span={12}>
                        全部金额: {Math.num2money(tableSummaryInfo.sumAmount)}
                      </Col>
                    </Row>
                  </AdTable.Summary.Row>
                </>
              );
            }}
          >
            <Item name="name">
              <Input
                placeholder="票据号码/销方号码/销方税号/记账人"
                title="票据号码/销方号码/销方税号/记账人"
              />
            </Item>
            <Item name="invDateStr">
              <RangePicker
                placeholder={["票据日期起", "票据日期止"]}
                format="YYYYMMDD"
                inputReadOnly
              />
            </Item>
            {/* <Item name="source">
              <Select placeholder="数据来源" mode="multiple">
                <Option value="7">接口传入</Option>
                <Option value="4">手工录入</Option>
                <Option value="2">扫描仪录入</Option>
                <Option value="10">高拍仪录入</Option>
                <Option value="11">图片导入</Option>
                <Option value="5,6,8">文件导入</Option>
                <Option value="9">移动端录入</Option>
              </Select>
            </Item> */}
            <Item name="voucherDateFromTo">
              <RangePicker
                placeholder={["过账日期起", "过账日期止"]}
                format="YYYYMMDD"
                style={{ width: "100%" }}
                inputReadOnly
              />
            </Item>
            {/* <Item name='createDateFromTo'>
              <RangePicker placeholder={['上传日期起', '上传日期止']} format='YYYYMMDD' style={{ width: '100%' }} inputReadOnly/>
            </Item> */}
            <Item name="voucherNo">
              <Input placeholder="记账凭证号" allowClear />
            </Item>
            {/* <Item name="deductionFlag">
              <Select placeholder="是否可抵扣" allowClear>
                <Option value={1}>是</Option>
                <Option value={0}>否</Option>
              </Select>
            </Item> */}
          </Table>
        ) : null}
      </Card>
    </Page>
  );
};

export default Other;
