import { Col, Form, Radio, Row, Space } from "antd";
import React, { FC, useEffect, useState } from "react";

import { Button, Card, Modal } from "@comps";
import MergeOrSplitParentTable from "../component/mergeOrSplitordersParentTable";
import SplitLimitMD from "./splitLimit.md";
import SplitSeparate from "./splitSeparate.md";

import { Http } from "@utils";
import { OperationType, SplitType } from "../interface";
import SplitNumMD from "./splitNum";
import SplitDetailMD from "./splitDetail";

const { Item } = Form;
const { Group } = Radio;

interface Props {
  invId: number;
  refreshTable: () => void;
  onClose: () => void;
  totalAmount: number;
}

const SplitDocMD: FC<Props> = ({
  invId,
  refreshTable,
  onClose,
  totalAmount,
}) => {
  const [splitTypeForm] = Form.useForm();
  const [modal, modalHolder] = Modal.useModal();
  const [detail, setDetail] = useState<JSONObject[]>([]);
  const [canSplitDetail, setCanSplitDetail] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    Http.get(`/tax/outputInvoices/${invId}/lines?pageSize=500&pageNum=1`)
      .then((res) => {
        console.log(res);
        setDetail(res?.content);
        if (res?.content.length === 1) {
          setCanSplitDetail(true);
        }
        let discountedLineCount = 0;
        res?.content.map((item: JSONObject) => {
          if (!item.discountLineFlag) {
            discountedLineCount++;
          }
        });
        if (discountedLineCount === 1) {
          setCanSplitDetail(true);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const checkIsCanSplit = (
    splitType: number,
    closeSplitAmountMD: () => void,
    customSplitAmt: JSONObject[] | String | number = 0
  ) => {
    let param = {};
    if (splitType === SplitType.COUNT || splitType === SplitType.DETAIL) {
      param = { splitNumbers: customSplitAmt };
    } else {
      param = { customSplitAmt };
    }
    Http.post("/tax/orders/split", {
      invId,
      splitType,
      ...param,
    }).then((data: JSONObject) => {
      modal.open({
        width: 1400,
        content(_1, reject) {
          return (
            <MergeOrSplitParentTable
              operationType={OperationType.SPLIT}
              dataSource={data.invList}
              mergeId={0}
              splitId={data.confirmId}
              onClose={reject}
              refreshTable={refreshTable}
              closeMergeTypeMD={() => {}}
              closeSplitTypeMD={onClose}
              closeSplitAmountMD={closeSplitAmountMD}
            />
          );
        },
      });
    });
  };

  const showSplitPreviewMD = () => {
    const { splitType } = splitTypeForm.getFieldsValue();

    if (splitType === SplitType.PART || splitType === SplitType.ALL) {
      modal.open({
        width: 500,
        content: (_1, reject) => (
          <SplitLimitMD
            splitType={splitType}
            checkIsCanSplit={checkIsCanSplit}
            onClose={reject}
          />
        ),
      });
    } else if (
      splitType === SplitType.TAX_PLATE ||
      splitType === SplitType.RATE
    ) {
      checkIsCanSplit(splitType, () => {});
    } else if (splitType === SplitType.SEPARATE) {
      modal.open({
        width: 550,
        content: (_1, reject) => (
          <SplitSeparate
            splitType={splitType}
            checkIsCanSplit={checkIsCanSplit}
            handleClose={onClose}
            onClose={reject}
            totalAmount={totalAmount}
            invId={invId}
            refreshTable={refreshTable}
          />
        ),
      });
    } else if (splitType === SplitType.COUNT) {
      modal.open({
        width: 950,
        content: (_1, reject) => (
          <SplitNumMD
            splitType={splitType}
            checkIsCanSplit={checkIsCanSplit}
            onClose={reject}
            invId={invId}
            detail={detail}
          />
        ),
      });
    } else if (splitType === SplitType.DETAIL) {
      modal.open({
        width: 950,
        content: (_1, reject) => (
          <SplitDetailMD
            splitType={splitType}
            checkIsCanSplit={checkIsCanSplit}
            onClose={reject}
            invId={invId}
            detail={detail}
          />
        ),
      });
    }
  };

  return (
    <Card title="拆分单据" loading={loading}>
      {modalHolder}
      <Form
        form={splitTypeForm}
        onFinish={showSplitPreviewMD}
        initialValues={{ splitType: 1 }}
        style={{ marginTop: 20 }}
      >
        <Row>
          <Col span={24}>
            <Item name="splitType">
              <Group>
                <Space direction="vertical" size="large">
                  {/* <Radio value={SplitType.TAX_PLATE}>税盘限额</Radio>
                  <Radio value={SplitType.PART}>自定义金额拆分（部分拆分）</Radio>
                  <Radio value={SplitType.ALL}>自定义金额拆分（全部拆分）</Radio>
                  <Radio value={SplitType.SEPARATE}>自定义金额、份数拆分</Radio> */}
                  <Radio value={SplitType.RATE}>按税率拆分</Radio>
                  <Radio value={SplitType.COUNT}>按数量拆分</Radio>
                  <Radio value={SplitType.DETAIL} disabled={canSplitDetail}>
                    按明细拆分
                  </Radio>
                </Space>
              </Group>
            </Item>
          </Col>
        </Row>
        <Row style={{ marginTop: 12 }}>
          <Col span={24} style={{ textAlign: "center" }}>
            <Space size="large">
              <Button type="primary" htmlType="submit">
                确定
              </Button>
              <Button onClick={onClose}>取消</Button>
            </Space>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};

export default SplitDocMD;
