import React, { FC, useState, useEffect, useRef, Fragment } from 'react'

import { Page, Card, Button, PermissionButton } from '@comps'
import { Steps, Space, Modal, DatePicker, Form } from 'antd'

import { Context, Http, Message } from '@utils'
import moment from 'moment'
import { StatisticsStateType, InputTransactionType } from './interface'

import '../index.css'
import './index.css'

const { Step } = Steps
const { Item } = Form

const Statistics: FC = () => {
  const [currentStep, setCurrentStep] = useState<number>(2)

  const [dateInfo, setDateInfo] = useState<JSONObject>({})
  const [isCurrentPeriod, setIsCurrentPeriod] = useState<boolean>(true)
  const [historyPeriodVal, setHistoryPeriodVal] = useState<number>()
  const periodRef: any = useRef({})
  const [statisticsState, setStatisticsState] = useState<JSONObject>({})

  const [tableData, setTableData] = useState<JSONObject[]>([])
  const [tableData_1, setTable1Data] = useState<JSONObject[]>([])
  const [tableData_2, setTable2Data] = useState<JSONObject[]>([])

  const [refreshResLoading, setRefreshResLoading] = useState<boolean>(false)
  const [applyStatisticsLoading, setApplyStatisticsLoading] = useState<boolean>(false)
  const [cancelStatisticsLoading, setCancelStatisticsLoading] = useState<boolean>(false)
  const [witnessingLoading, setWitnessingLoading] = useState<boolean>(false)
  const [syncTaxBureauLoading, setSyncTaxBureauLoading] = useState<boolean>(false)

  const [form] = Form.useForm()

  const getDateInfo = async () => {
    const res = await Http.get('/input/gx/period')
    setDateInfo(res)

    // 当前所属期
    periodRef.current = res.period
    setHistoryPeriodVal(res.period)
    
    getStatisticsState()
  }

  const getStatisticsState = async () => {
    setRefreshResLoading(true)

    const period = periodRef.current ? periodRef.current : ''
    const res = await Http.get('/input/tj/getInputStats', { period })

    setRefreshResLoading(false)
    setStatisticsState(res)
    setTableData(res.inputStatsLines)
    setTable1Data(res.inputStatsFarmLineList)
    setTable2Data(res.inputStatsInfoList)

    if (res.failMsg) {
      Modal.warning({
        title: '最近操作失败原因',
        content: res.failMsg
      });
    }
  }

  const getHistoricalTaxPeriod = async (period: number) => {
    const res = await Http.get('/input/tj/getInputStats', { period })
    setStatisticsState(res)
    setTableData(res.inputStatsLines)
    setTable1Data(res.inputStatsFarmLineList)
    setTable2Data(res.inputStatsInfoList)

    if (res.failMsg) {
      Modal.warning({
        title: '最近操作失败原因',
        content: res.failMsg
      });
    }
  }

  useEffect(() => {
    getDateInfo()
  }, [])

  const getPeroidData = async (isCurrent: boolean) => {
    setIsCurrentPeriod(isCurrent)
    getStatisticsState()
  }

  const handleStepChange = (current: number) => {
    if (current === 0) {
      Context.route('/input/gx')
    } else if (current === 1) {
      Context.route('/income/authentication/check')
    }
  }

  const handleApplyOrCandelStatistics = async (type: number) => {
    if (type === InputTransactionType.APPLICATION_STATISTICS) {
      setApplyStatisticsLoading(true)
    } else {
      setCancelStatisticsLoading(true)
    }

    await Http.get('/input/tj/tj', {
      inputTrxType: type,
      period: dateInfo.period
    }).then(async res => {
      if (type === 5 && dateInfo.gxInterfaceTypeDesc === "全电接口") {
        await Http.get('/input/tj/quaryStatisticStatus', { period: periodRef.current })
      }
      Message.ok()
      await getStatisticsState()
    }).finally(() => {
      setApplyStatisticsLoading(false)
      setCancelStatisticsLoading(false)
    })
  }

  const handleWitnessing = async (type: number) => {
    setWitnessingLoading(true)
    
    await Http.get('/input/tj/tjqr', {
      inputTrxType: type,
      period: dateInfo.period
    }).then(res => {
      Message.ok()
      getStatisticsState()
    }).finally(() => {
      setWitnessingLoading(false)
    })
  }

  const handleCurrentHistoryPeroid = () => {
    const formObj = form.getFieldsValue()
    if (formObj) {
      const { period } = formObj
      const periodStr = moment(period).format('YYYYMM')
      getHistoricalTaxPeriod(parseInt(periodStr))
    } else {
      Message.error('获取当前税款所属期失败')
    }
  }

  const handleSyncTaxBureau = (isHistory:boolean) => {
    let parmas
    if(isHistory){
      const formObj = form.getFieldsValue()
    if (formObj) {
      const { period } = formObj
      const periodStr = moment(period).format('YYYYMM')
      parmas = {period:periodStr}
    }
    }else{
      parmas={ period: periodRef.current }
    }
    setSyncTaxBureauLoading(true)
    Http.get('/input/tj/quaryStatisticStatus', parmas)
      .then(res => {
        getStatisticsState()
      })
      .finally(() => {
        setSyncTaxBureauLoading(false)
      })
  }

  return (
    <Page>
      <div className="authentication">
        <div className="steps">
          <Steps current={currentStep} onChange={handleStepChange} size="small">
            <Step title="发票获取" />
            <Step title="发票勾选" />
            <Step title="发票抵扣统计" />
          </Steps>
        </div>
        <Card title="发票抵扣统计" actions={(
          <Space>
            <PermissionButton permissions="InputGX.queryTaxPeriod" 
                              type={isCurrentPeriod ? "primary" : "default"} 
                              onClick={() => {getPeroidData(true)}}>
                              当前税款所属期</PermissionButton>
            <PermissionButton permissions="InputGX.queryTaxPeriod"  
                              type={isCurrentPeriod ? "default" : "primary"} 
                              onClick={() => {getPeroidData(false)}}>
                              历史税款所属期</PermissionButton>
          </Space>)}>
          <div style={{ display: isCurrentPeriod ? 'block' : 'none' }}>
              <p>
                当前税款所属期：<span style={{ color: '#f00' }}>{statisticsState.period}</span>
               （当期可进行勾选操作的截止日期为：{dateInfo.deadline}）
              </p>
              <div className="time-step">
             {/* 统计前 */}
             {
                statisticsState.statusCode === StatisticsStateType.NOT_COUNTED ?
                <>
                  <div className="time-step-left">
                    <Steps current={-1} size="small">
                      <Step title="申请统计" />
                      <Step title="统计完成" />
                      <Step title="确认签名" />
                    </Steps>
                  </div>
                  <div className="time-step-right">
                    <Button loading={syncTaxBureauLoading} 
                            onClick={()=>handleSyncTaxBureau(false)}>
                            同步税局</Button>
                    <Button>打印</Button>
                    <Button type="primary" 
                            loading={applyStatisticsLoading}
                            onClick={() => {handleApplyOrCandelStatistics(InputTransactionType.APPLICATION_STATISTICS)}}>
                            申请统计</Button>
                  </div>
                </> : null
              }

              {/* 统计中 */}
              {
                statisticsState.statusCode === StatisticsStateType.STATISTING ?
                <>
                  <div className="time-step-left">
                    <Steps current={0} size="small">
                      <Step title="申请统计" subTitle="申请统计中" description={statisticsState.statsApplyTime} />
                      <Step title="统计完成" />
                      <Step title="确认签名" />
                    </Steps>
                  </div>
                  <div className="time-step-right">
                    <Button loading={syncTaxBureauLoading} 
                            onClick={()=>handleSyncTaxBureau(false)}>
                            同步税局</Button>
                    <Button loading={refreshResLoading} 
                            onClick={getStatisticsState}>
                            刷新结果</Button>
                    <Button>打印</Button>
                    <Button disabled>申请统计</Button>
                  </div>
                </> : null
              }

              {/* 统计完成 */}
              {
                statisticsState.statusCode === StatisticsStateType.WAIT_SIGN ?
                <>
                  <div className="time-step-left">
                    <Steps current={1} size="small">
                      <Step title="申请统计" description={statisticsState.statsApplyTime} />
                      <Step title="统计完成" description={statisticsState.statsTime} />
                      <Step title="确认签名" />
                    </Steps>
                  </div>
                  <div className="time-step-right">
                    <Button loading={syncTaxBureauLoading} 
                            onClick={()=>handleSyncTaxBureau(false)}>
                            同步税局</Button>
                    <Button loading={refreshResLoading} 
                            onClick={getStatisticsState}>
                            刷新结果</Button>
                    <Button>打印</Button>
                    <Button type="primary" 
                            loading={witnessingLoading}
                            onClick={() => {handleWitnessing(InputTransactionType.APPLICATION_STATISTICS_CONFIRMATION)}}>
                            确认签名</Button>
                    <Button loading={cancelStatisticsLoading}
                            onClick={() => {handleApplyOrCandelStatistics(InputTransactionType.UNDO_STATISTICS)}}>
                            撤销统计</Button>
                  </div>
                </> : null
              }

              {/* 确认中 */}
              {
                statisticsState.statusCode === StatisticsStateType.SIGNING ?
                <>
                  <div className="time-step-left">
                    <Steps current={1} size="small">
                      <Step title="申请统计" description={statisticsState.statsApplyTime} />
                      <Step title="统计完成" subTitle="确认中" description={statisticsState.statsTime} />
                      <Step title="确认签名" />
                    </Steps>
                  </div>
                  <div className="time-step-right">
                    <Button loading={syncTaxBureauLoading} 
                            onClick={()=>handleSyncTaxBureau(false)}>
                            同步税局</Button>
                    <Button loading={refreshResLoading}  
                            onClick={getStatisticsState}>
                            刷新结果</Button>
                    <Button>打印</Button>
                    <Button type="primary" disabled>确认签名</Button>
                    <Button type="primary" disabled>撤销统计</Button>
                  </div>
                </> : null
              }

              {/* 确认签名 */}
              {
                statisticsState.statusCode === StatisticsStateType.SIGN ?
                <>
                  <div className="time-step-left">
                    <Steps current={2} size="small">
                      <Step title="申请统计" description={statisticsState.statsApplyTime} />
                      <Step title="统计完成" description={statisticsState.statsTime} />
                      <Step title="确认签名" description={statisticsState.signTime} />
                    </Steps>
                  </div>
                  <div className="time-step-right">
                    <Button loading={syncTaxBureauLoading} 
                            onClick={()=>handleSyncTaxBureau(false)}>
                            同步税局</Button>
                    <Button loading={refreshResLoading} 
                            onClick={getStatisticsState}>
                            刷新结果</Button>
                    <Button>打印</Button>
                    <Button loading={cancelStatisticsLoading}
                            onClick={() => {handleApplyOrCandelStatistics(InputTransactionType.UNDO_STATISTICS)}}>撤销统计</Button>
                  </div>
                </> : null
              }

              {/* 撤销签名中 */}
              {
                statisticsState.statusCode === StatisticsStateType.CANCEL_STATISTICS ?
                <>
                  <div className="time-step-left">
                    <Steps current={1} size="small">
                      <Step title="申请统计" description={statisticsState.statsApplyTime} />
                      <Step title="统计完成" description={statisticsState.statsTime} />
                      <Step title="确认签名" description={statisticsState.signTime} />
                    </Steps>
                  </div>
                  <div className="time-step-right">
                    <Button loading={syncTaxBureauLoading} 
                            onClick={()=>handleSyncTaxBureau(false)}>
                            同步税局</Button>
                    <Button loading={refreshResLoading} 
                            onClick={getStatisticsState}>
                            刷新结果</Button>
                    <Button>打印</Button>
                    <Button type="primary" disabled>确认签名</Button>
                    <Button type="primary" disabled>撤销统计</Button>
                  </div>
                </> : null
              }

              {/* 撤销统计中 */}
              {
                statisticsState.statusCode === StatisticsStateType.CANCEL_STATISTIC ?
                <>
                  <div className="time-step-left">
                    <Steps current={0} size="small">
                      <Step title="申请统计" subTitle="撤销统计中" description={statisticsState.statsApplyTime} />
                      <Step title="统计完成" />
                      <Step title="确认签名" />
                    </Steps>
                  </div>
                  <div className="time-step-right">
                    <Button loading={syncTaxBureauLoading} 
                            onClick={()=>handleSyncTaxBureau(false)}>
                            同步税局</Button>
                    <Button loading={refreshResLoading}  
                            onClick={getStatisticsState}>
                            刷新结果</Button>
                    <Button>打印</Button>
                    <Button disabled>申请统计</Button>
                  </div>
                </> : null
              }
          </div>       
            </div> 
          <div className="history-peroid" style={{ display: isCurrentPeriod ? 'none' : 'block' }}>
              <div className="history-peroid-inner">
                <div className="history-peroid-left">
                  <span>当前税款所属期：</span>
                  <Form form={form} style={{ marginTop: 10 }}>
                    <Item name="period">
                      <DatePicker picker="month" defaultValue={moment(periodRef.current)} inputReadOnly/>
                    </Item>
                  </Form>
                  <Button type="primary" onClick={handleCurrentHistoryPeroid}>查询</Button>
                 </div>
                <div className="history-peroid-right">
                  <Button>打印</Button>
                  <Button 
                    loading={syncTaxBureauLoading} 
                    onClick={()=>handleSyncTaxBureau(true)}
                  >
                    同步税局
                  </Button>

                </div>        
              </div>
            </div>      
          
          {dateInfo.gxInterfaceTypeDesc === "全电接口" ? <><h3 style={{fontWeight: "bold"}}>增值税申报进项抵扣汇总</h3>
          <div className="table">
            <div className="table-header">
              <div className="header-center">
                <div className="header-center-fir-row">进项抵扣类型</div>
              </div>
              <div className="header-right">
                <div className="header-center-fir-row">份数</div>
              </div>
              <div className="header-right">
                <div className="header-center-fir-row">金额</div>
              </div>
              <div className="header-right">
                <div className="header-center-fir-row">税额</div>
              </div>
            </div>
            <div className="table-content">
              {
                statisticsState.statusCode === StatisticsStateType.NOT_COUNTED ||
                statisticsState.statusCode === StatisticsStateType.STATISTING ||
                statisticsState.statusCode === StatisticsStateType.CANCEL_STATISTIC ?
                <div className="no-data">
                  无符合条件的数据
                </div> :  
                (tableData_2.map((row: JSONObject) => {
                  return (
                    <div className="table-content-row" key={row.id}>
                      <div style={{ fontSize: 12 }}>{row.invoiceTypeDesc || "--"}</div>
                      <div>{row.invoiceCopies || "--"}</div>
                      <div>{row.totalAmount || "--"}</div>
                      <div>{row.totalTaxAmount || "--"}</div>
                    </div>)
                }))            
              }
             </div>
          </div>
          <h3 style={{fontWeight: "bold"}}>抵扣类勾选统计结果</h3>
          <div className="table">
            <div className="table-header">
              <div className="header-center">
                <div className="header-center-fir-row double-row">发票类型</div>
              </div>
              <div className="header-right">
                <div className="header-center-fir-row double-row">合计份数</div>
              </div>
              <div className="header-right">
                <div className="header-center-fir-row double-row">有效抵扣税额合计</div>
              </div>
              <div className="header-right">
                <div className="header-center-fir-row double-row">出口转内销证明份数</div>
              </div>
              <div className="header-right">
                <div className="header-center-fir-row double-row" style={{ height: 24, lineHeight: "24px" }}>出口转内销证明有效抵扣</div>
                <div className="header-center-fir-row double-row" style={{ height: 24, lineHeight: "24px" }}>税额合计</div>
              </div>
              <div className="header-right">
                <div className="header-center-fir-row double-row">其他发票份数</div>
              </div>
              <div className="header-right">
                <div className="header-center-fir-row double-row" style={{ height: 24, lineHeight: "24px" }}>其他发票有效抵扣</div>
                <div className="header-center-fir-row double-row" style={{ height: 24, lineHeight: "24px" }}>税额合计</div>
              </div>
            </div>
            <div className="table-content">
              {
                statisticsState.statusCode === StatisticsStateType.NOT_COUNTED ||
                statisticsState.statusCode === StatisticsStateType.STATISTING ||
                statisticsState.statusCode === StatisticsStateType.CANCEL_STATISTIC ?
                <div className="no-data">
                  无符合条件的数据
                </div> :  
                (tableData.map((row: JSONObject) => {
                  return (
                    <div className="table-content-row" key={row.id}>
                      <div style={{ fontSize: 12 }}>{row.invoiceTypeDesc || "--"}</div>
                      <div>{row.deductibleQuantity || "--"}</div>
                      <div>{row.deductibleTaxAmount || "--"}</div>
                      <div>{row.divertCertCopies || "--"}</div>
                      <div>{row.divertCertValidTaxAmount || "--"}</div>
                      <div>{row.otherInvoiceCopies || "--"}</div>
                      <div>{row.otherInvoiceValidTaxAmount || "--"}</div>
                    </div>)
                }))            
              }
             </div>
          </div>
          <h3 style={{fontWeight: "bold"}}>农产品加计扣除勾选统计结果</h3>
          <div className="table">
            <div className="table-header">
              <div className="header-left" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                <div>发票类型</div>
              </div>
              <div className="header-right">
                <div className="header-center-fir-row">正数发票</div>
                <div className="header-center-sec-row">
                  <div>份数</div>
                  <div>本期有效抵扣税额合计</div>
                </div>
              </div>
              <div className="header-right">
                <div className="header-center-fir-row">负数发票</div>
                <div className="header-center-sec-row">
                  <div>份数</div>
                  <div>本期有效抵扣税额合计</div>
                </div>
              </div>
            </div>
            <div className="table-content">
              {
                statisticsState.statusCode === StatisticsStateType.NOT_COUNTED ||
                statisticsState.statusCode === StatisticsStateType.STATISTING ||
                statisticsState.statusCode === StatisticsStateType.CANCEL_STATISTIC ?
                <div className="no-data">
                  无符合条件的数据
                </div> :  
                (tableData_1.map((row: JSONObject) => {
                  return (
                    <div className="table-content-row" key={row.id}>
                      <h5>{row.invoiceTypeDesc || "--"}</h5>
                      <div>{row.positiveInvoiceCopies || "--"}</div>
                      <div>{row.positiveValidTaxAmount || "--"}</div>
                      <div>{row.negativeInvoiceCopies || "--"}</div>
                      <div>{row.negativeValidTaxAmount || "--"}</div>
                    </div>)
                }))            
              }
             </div>
          </div></> : 
          <div className="table">
            <div className="table-header">
              <div className="header-left">
                <span className="purpose">用途</span>
                <span className="bill-type">发票类型</span>
                <div className="diagonal"></div>
              </div>
              <div className="header-center">
                <div className="header-center-fir-row">抵扣</div>
                <div className="header-center-sec-row">
                  <div>份数</div>
                  <div>金额</div>
                  <div>有效税额</div>
                </div>
              </div>
              <div className="header-right">
                <div>不抵扣</div>
                <div className="header-right-sec-row">
                  <div>份数</div>
                  <div>金额</div>
                  <div>有效税额</div>
                </div>
              </div>
            </div>
            <div className="table-content">
              {
                statisticsState.statusCode === StatisticsStateType.NOT_COUNTED ||
                statisticsState.statusCode === StatisticsStateType.STATISTING ||
                statisticsState.statusCode === StatisticsStateType.CANCEL_STATISTIC ?
                <div className="no-data">
                  无符合条件的数据
                </div> :  
                (tableData.map((row: JSONObject) => {
                  return (
                    <div className="table-content-row" key={row.id}>
                      <h5>{row.invoiceTypeDesc}</h5>
                      <div>{row.deductibleQuantity}</div>
                      <div>{row.deductibleAmount}</div>
                      <div>{row.deductibleTaxAmount}</div>
                      <div>{row.undeductibleQuantity}</div>
                      <div>{row.undeductibleAmount}</div>
                      <div>{row.undeductibleTaxAmount}</div>
                    </div>)
                }))            
              }
            </div>
          </div>}
        </Card>
      </div>
    </Page>
  )
}

export default Statistics