import { RouteProps } from '@models'

import AddedTax from './addedTax'
import AddedTaxDetail from './addedTax/detail'
import Other from './other'
import Gain from './authentication/gain'
import Check from './authentication/check'
import Statistics from './authentication/statistics'
import Deduction from './deduction'
import Certified from './certified'
import Bookkeeping from './bookkeeping'
import PersonalTicket from './personalTicket'

const incomeRoutes: RouteProps[] = [
  { path: '/input/biz', component: AddedTax, private: true },
  { path: '/income/addedTax/:addedTaxID/detail', component: AddedTaxDetail, private: true },
  { path: '/income/other', component: Other, private: true },
  { path: '/input/gx', component: Gain, private: true },
  { path: '/income/authentication/check', component: Check, private: true },
  { path: '/income/authentication/statistics', component: Statistics, private: true },
  { path: '/income/deduction', component: Deduction, private: true },
  { path: '/input/certifiedManagement', component: Certified, private: true },
  { path: '/income/bookkeeping', component: Bookkeeping, private: true },
  { path: '/income/personalTicket', component: PersonalTicket, private: true },

]

export { incomeRoutes }
