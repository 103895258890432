import { MoreOutlined, PlusOutlined } from "@ant-design/icons";
import { Modal } from "@comps";
import { ColumnProps } from "@comps/types";
import { Math as MyMath } from "@utils";
import {
  Card as AdCard,
  Table as AdTable,
  AutoComplete,
  Button,
  Col,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Switch,
  Tag,
  message,
} from "antd";
import Decimal from "decimal.js";
import * as React from "react";
import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router";
import DetailModal from "../drawer/detailModal";
import DiscountModal from "../drawer/discountModal";
import EntryNameModal from "../drawer/entryNameModal";
import IssueInfo from "../drawer/issueInfo";

interface Props {
  form: any;
  getTotalInfo: Function;
  invoiceTypeEnum: any;
  businessTypeEnum: any;
}

const TableFormList: React.FC<Props> = (props) => {
  const { form, getTotalInfo, invoiceTypeEnum, businessTypeEnum } = props;
  const { Option } = Select;

  const [levelData, setLevalData] = useState([]);
  const [modal, modalHolder] = Modal.useModal();
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [nameOption, setNameOption] = useState([]);
  const [nameValue, setNameValue] = useState<string | undefined>();
  const [currentTax, setCurrentTax] = useState(true);
  const { type, businessType, requestNo } = useParams<any>();

  let numberRef = useRef("");
  let taxPriceRef = useRef("");
  let withoutTaxPriceRef = useRef("");
  let taxMoneyRef = useRef("");
  let withoutTaxMoneyRef = useRef("");
  let taxRateRef = useRef<null | number | undefined>(null);
  let taxAmountRef = useRef("");
  const [totalMoney, setTotalMoney] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [refresh, setRefresh] = useState<boolean>(false);
  let invoiceGoodsNameRef = useRef("");

  let totalMoneyRef = useRef(0);
  let totalAmountRef = useRef(0);

  //   console.log(type, businessType, "=======================");
  const onNameSelect = () => {};
  const onNameSearch = () => {};
  const onNameChange = (data: string) => {
    console.log(data, "=====");
    setNameValue(data);
  };

  //===初始化Ref的值====
  const InitRef = () => {
    invoiceGoodsNameRef.current = "";
    numberRef.current = "";
    taxPriceRef.current = "";
    withoutTaxPriceRef.current = "";
    taxMoneyRef.current = "";
    withoutTaxMoneyRef.current = "";
    taxRateRef.current = undefined;
    taxAmountRef.current = "";
  };
  useEffect(() => {
    getTotalInfo(totalAmountRef.current, totalMoneyRef.current);
  }, [totalAmountRef.current, totalMoneyRef.current]);

  const isNoNull = (value: any) => {
    if (value === null) {
      return false;
    } else if (value === undefined) {
      return false;
    } else if (value === "") {
      return false;
    } else {
      return true;
    }
  };
  const CalculateMethods = function (field: JSONObject) {
    let { lines } = form.getFieldsValue();

    const getLines = () => {
      return lines[field.name] ?? {};
    };

    const getLineVal = (key: string) => {
      return getLines()[key] ?? 0;
    };
    this.quantity = getLineVal("quantity");
    this.price = getLineVal("price");
    this.withTaxPrice = getLineVal("withTaxPrice");
    this.amount = getLineVal("amount");
    this.withTaxAmount = getLineVal("withTaxAmount");
    this.taxAmount = getLineVal("taxAmount");
    this.taxRate = getLineVal("taxRate");

    //根据数量和含税单价进行计算
    this.calculateQuantityWithTaxPrice = () => {
      //不含税单价
      this.price = Number(
        new Decimal(this.withTaxPrice)
          .div(new Decimal(1).add(new Decimal(this.taxRate)))
          .toFixed(13)
      );
      //含税金额
      this.withTaxAmount = Number(
        new Decimal(this.quantity).mul(this.withTaxPrice).toFixed(2)
      );
      //不含税金额
      this.amount = Number(
        new Decimal(this.withTaxAmount)
          .div(new Decimal(1).add(new Decimal(this.taxRate)))
          .toFixed(2)
      );
      //税额
      this.taxAmount = Number(
        new Decimal(this.withTaxAmount).sub(new Decimal(this.amount)).toFixed(2)
      );
    };

    //根据数量和含税金额进行计算
    this.calculateQuantityWithTaxAmount = () => {
      //含税单价
      this.withTaxPrice = Number(
        new Decimal(this.withTaxAmount)
          .div(new Decimal(this.quantity))
          .toFixed(13)
      );
      //不含税单价
      this.price = Number(
        new Decimal(this.withTaxPrice)
          .div(new Decimal(1).add(new Decimal(this.taxRate)))
          .toFixed(13)
      );
      //不含税金额
      this.amount = Number(
        new Decimal(this.withTaxAmount)
          .div(new Decimal(1).add(new Decimal(this.taxRate)))
          .toFixed(2)
      );
      //税额
      this.taxAmount = Number(
        new Decimal(this.withTaxAmount).sub(new Decimal(this.amount)).toFixed(2)
      );
    };

    //根据含税单价和含税金额来计算
    this.calculateWithTaxPriceWithTaxAmount = () => {
      //数量
      this.quantity = Number(
        new Decimal(this.withTaxAmount).div(this.withTaxPrice).toFixed(13)
      );
      //单价
      this.price = Number(
        new Decimal(this.withTaxPrice)
          .div(new Decimal(1).add(new Decimal(this.taxRate)))
          .toFixed(13)
      );
      //金额
      this.amount = Number(
        new Decimal(this.withTaxAmount)
          .div(new Decimal(1).add(new Decimal(this.taxRate)))
          .toFixed(2)
      );
      //税额
      this.taxAmount = Number(
        new Decimal(this.withTaxAmount).sub(new Decimal(this.amount)).toFixed(2)
      );
    };

    //根据数量和不含税单价
    this.calculateQuantityPrice = () => {
      //含税单价
      this.withTaxPrice = Number(
        new Decimal(this.price)
          .mul(new Decimal(1).add(new Decimal(this.taxRate)))
          .toFixed(13)
      );
      //不含税金额
      this.amount = Number(
        new Decimal(this.quantity).mul(this.price).toFixed(2)
      );
      //税额
      this.taxAmount = Number(
        new Decimal(this.amount).mul(new Decimal(this.taxRate)).toFixed(2)
      );
      //含税金额
      this.withTaxAmount = Number(
        new Decimal(this.amount).add(new Decimal(this.taxAmount)).toFixed(2)
      );
    };
    //根据数量和不含税金额计算
    this.calculateQuantityAmount = () => {
      //不含税单价
      this.price = Number(
        new Decimal(this.amount).div(this.quantity).toFixed(13)
      );
      //含税单价
      this.withTaxPrice = Number(
        new Decimal(this.price)
          .mul(new Decimal(1).add(new Decimal(this.taxRate)))
          .toFixed(13)
      );
      //税额
      this.taxAmount = Number(
        new Decimal(this.amount).mul(new Decimal(this.taxRate)).toFixed(2)
      );
      //含税金额
      this.withTaxAmount = Number(
        new Decimal(this.amount).add(new Decimal(this.taxAmount)).toFixed(2)
      );
    };
    //根据不含税单价和不含税金额进行计算
    this.calculatePriceAmount = () => {
      //数量
      this.quantity = Number(
        new Decimal(this.amount).div(new Decimal(this.price)).toFixed(13)
      );
      //含税单价
      this.withTaxPrice = Number(
        new Decimal(this.price)
          .mul(new Decimal(1).add(new Decimal(this.taxRate)))
          .toFixed(13)
      );
      //税额
      this.taxAmount = Number(
        new Decimal(this.amount).mul(new Decimal(this.taxRate)).toFixed(2)
      );
      //含税金额
      this.withTaxAmount = Number(
        new Decimal(this.amount).add(new Decimal(this.taxAmount)).toFixed(2)
      );
    };
    //获取计算结果
    this.getCalculateResult = () => {
      return {
        quantity: this.quantity,
        price: this.price,
        amount: this.amount,
        taxRate: this.taxRate,
        taxAmount: this.taxAmount,
        withTaxPrice: this.withTaxPrice,
        withTaxAmount: this.withTaxAmount,
      };
    };
  };
  //   ======数量变化======
  const handleNumberChange = (e: any, field: any) => {
    let { lines } = form.getFieldsValue();
    let calculateMethods = new (CalculateMethods as any)(field);
    if (isNoNull(lines[field.name]["withTaxPrice"])) {
      calculateMethods.calculateQuantityWithTaxPrice();
    } else if (isNoNull(lines[field.name]["withTaxAmount"])) {
      calculateMethods.calculateQuantityWithTaxAmount();
    } else if (isNoNull(lines[field.name]["price"])) {
      calculateMethods.calculateQuantityPrice();
    } else if (isNoNull(lines[field.name]["amount"])) {
      calculateMethods.calculateQuantityAmount();
    }
    const result = calculateMethods.getCalculateResult();
    lines[field.name] = { ...lines[field.name], ...result };
    form.setFieldsValue({
      lines,
    });
    console.log("result :>> ", result);
  };

  //含税单价改变
  const handleTaxPrice = (e: any, field: any) => {
    let { lines } = form.getFieldsValue();
    let calculateMethods = new (CalculateMethods as any)(field);
    if (isNoNull(lines[field.name]["quantity"])) {
      calculateMethods.calculateQuantityWithTaxPrice();
    } else if (isNoNull(lines[field.name]["withTaxAmount"])) {
      calculateMethods.calculateWithTaxPriceWithTaxAmount();
    }
    const result = calculateMethods.getCalculateResult();
    lines[field.name] = { ...lines[field.name], ...result };
    form.setFieldsValue({
      lines,
    });
  };

  //不含税单价改变
  const handleWithoutTaxPrice = (e: any, field: any) => {
    let { lines } = form.getFieldsValue();
    let calculateMethods = new (CalculateMethods as any)(field);
    if (isNoNull(lines[field.name]["quantity"])) {
      calculateMethods.calculateQuantityPrice();
    } else if (isNoNull(lines[field.name]["amount"])) {
      calculateMethods.calculatePriceAmount();
    }
    const result = calculateMethods.getCalculateResult();
    lines[field.name] = { ...lines[field.name], ...result };
    form.setFieldsValue({
      lines,
    });
  };

  //不含税金额变化
  const handleWithoutTaxMoney = (e: any, field: any) => {
    let { lines } = form.getFieldsValue();
    let calculateMethods = new (CalculateMethods as any)(field);
    if (isNoNull(lines[field.name]["price"])) {
      calculateMethods.calculatePriceAmount();
    } else if (isNoNull(lines[field.name]["quantity"])) {
      calculateMethods.calculateQuantityAmount();
    }
    const result = calculateMethods.getCalculateResult();
    lines[field.name] = { ...lines[field.name], ...result };
    form.setFieldsValue({
      lines,
    });
  };
  //含税金额变化
  const handleTaxMoney = (e: any, field: any) => {
    let { lines } = form.getFieldsValue();
    let calculateMethods = new (CalculateMethods as any)(field);
    if (isNoNull(lines[field.name]["withTaxPrice"])) {
      calculateMethods.calculateWithTaxPriceWithTaxAmount();
    } else if (isNoNull(lines[field.name]["quantity"])) {
      calculateMethods.calculateQuantityWithTaxAmount();
    }
    const result = calculateMethods.getCalculateResult();
    lines[field.name] = { ...lines[field.name], ...result };
    form.setFieldsValue({
      lines,
    });
  };

  //   =======税率变化=======
  const handleTaxRate = (value: any, field: any) => {
    console.log("value :>> ", value);
    let { lines } = form.getFieldsValue();
    // taxRateRef.current = lines[field.name]["taxRate"];
    lines[field.name]["taxAmount"] = isNoNull(lines[field.name]["amount"])
      ? Number(
          new Decimal(lines[field.name]["taxRate"])
            .mul(new Decimal(lines[field.name]["amount"]))
            .toDecimalPlaces(2)
        )
      : "";

    taxAmountRef.current = lines[field.name]["taxAmount"];
    form.setFieldsValue({
      lines,
    });
    console.log(
      value,
      taxAmountRef.current,
      lines[field.name]["taxAmount"],
      lines,
      "=========TaxRate"
    );
  };

  const handleGoodMsg = (row: any, field: any) => {
    console.log(row, field, "=====row======");
    let { lines } = form.getFieldsValue();
    lines[field.name]["invoiceGoodsName"] = row?.invoiceGoodsName;
    lines[field.name]["goodsName"] = row?.goodsName;
    lines[field.name]["taxRate"] = row?.taxRate / 100;
    lines[field.name]["taxCode"] = row?.taxCode;
    lines[field.name]["useDiscountPolicy"] = row?.hasDiscountPolicy;
    lines[field.name]["discountPolicyRemark"] = row?.policyName;
    lines[field.name]["goodsId"] = row?.id;
    lines[field.name]["unit"] = row?.unit;
    lines[field.name]["spec"] = row?.spec;
    lines[field.name]["taxZeroType"] = row?.policyId;
    lines[field.name]["withTaxPrice"] = "";
    lines[field.name]["withTaxAmount"] = "";
    lines[field.name]["taxAmount"] = "";
    lines[field.name]["price"] = "";
    lines[field.name]["amount"] = "";
    lines[field.name]["quantity"] = "";
    if ((row?.includeTaxFlag ?? "") !== "" && (row?.unitPrice ?? "") !== "") {
      if (row?.includeTaxFlag === true) {
        lines[field.name]["withTaxPrice"] = row?.unitPrice;
        lines[field.name]["price"] = Number(
          new Decimal(row?.unitPrice)
            .div(new Decimal(1).add(new Decimal(row?.taxRate).div(100)))
            .toDecimalPlaces(8)
        );
      }
      if (row?.includeTaxFlag === false) {
        lines[field.name]["price"] = row?.unitPrice;
        lines[field.name]["withTaxPrice"] = Number(
          new Decimal(row?.unitPrice)
            .mul(new Decimal(1).add(new Decimal(row?.taxRate).div(100)))
            .toDecimalPlaces(8)
        );
      }
    }
    lines[field.name]["taxRateLabel"] =
      (row?.policyName ?? "") !== "" ? row.policyName : `${row?.taxRate}%`;
    InitRef();
    invoiceGoodsNameRef.current = row?.invoiceGoodsName;
    taxRateRef.current = row?.taxRate / 100;
    form.setFieldsValue({ lines }, () => {
      // 手动触发必填字段的校验
      form.validateFields(["invoiceGoodsName", "taxRate"]).then((res: any) => {
        console.log(res);
      });
    });
    console.log(lines, "=====");
  };
  const handleEntryName = (field: any) => {
    modal.open({
      width: "1600px",
      content(_1, reject) {
        return (
          <div key={Math.random()}>
            <EntryNameModal
              onClose={reject}
              getGoodMsg={(row: any) => handleGoodMsg(row, field)}
            />
          </div>
        );
      },
    });
  };

  const initColumns: ColumnProps[] = [
    {
      key: "",
      title: "序号",
      width: 60,
      fixed: "left",
      render: (text: any, field: any, index: any) => (
        <Form.Item
          key={field.name}
          style={{ margin: 0 }}
          name={[field.name, "lineNum"]}
          fieldKey={[field.fieldKey, "lineNum"]}
        >
          {index + 1}
        </Form.Item>
      ),
    },
    {
      key: "invoiceGoodsName",
      dataIndex: "invoiceGoodsName",
      // title: "项目名称",
      title: (
        <div>
          <span style={{ color: "red" }}>*</span>项目名称
        </div>
      ),
      align: "left",
      width: 300,
      render: (text: any, field: any, index: any) => {
        return (
          <Row gutter={8}>
            <Col span={22}>
              <Form.Item
                style={{ margin: 0 }}
                rules={[{ required: true, message: "请输入项目名称" }]}
                name={[field.name, "invoiceGoodsName"]}
                fieldKey={[field.fieldKey, "invoiceGoodsName"]}
                validateTrigger={["onSubmit"]}
                key={field.name}
              >
                <AutoComplete
                  // value={nameValue}
                  allowClear={true}
                  backfill={true}
                  options={nameOption}
                  onSelect={onNameSelect}
                  onSearch={onNameSearch}
                  // onChange={onNameChange}
                  placeholder="请输入项目名称"
                />
                {/* <Input placeholder="请输入" /> */}
              </Form.Item>
            </Col>
            <Col span={1}>
              <Button
                type="primary"
                ghost
                icon={<MoreOutlined />}
                onClick={() => handleEntryName(field)}
              />
            </Col>
          </Row>
        );
      },
    },
    {
      key: "spec",
      dataIndex: "spec",
      title: "规格型号",
      align: "left",
      render: (text: any, field: any, index: any) => {
        return (
          <Form.Item
            key={field.name}
            style={{ margin: 0 }}
            name={[field.name, "spec"]}
            fieldKey={[field.fieldKey, "spec"]}
          >
            <Input placeholder="请输入" />
          </Form.Item>
        );
      },
    },
    {
      key: "unit",
      dataIndex: "unit",
      title: "单位",
      align: "left",
      render: (text: any, field: any, index: any) => {
        return (
          <Form.Item
            key={field.name}
            style={{ margin: 0 }}
            name={[field.name, "unit"]}
            fieldKey={[field.fieldKey, "unit"]}
          >
            <Input placeholder="请输入" />
          </Form.Item>
        );
      },
    },
    {
      key: "quantity",
      dataIndex: "quantity",
      title: "数量",
      align: "left",
      width: 160,
      render: (text: any, field: any, index: any) => {
        return (
          <Form.Item
            key={field.name}
            style={{ margin: 0 }}
            name={[field.name, "quantity"]}
            fieldKey={[field.fieldKey, "quantity"]}
          >
            <InputNumber
              style={{ width: "150px" }}
              //   precision={2}
              controls={false}
              //@ts-ignore
              min="0"
              //@ts-ignore
              max="999999999999.9999"
              maxLength={15}
              stringMode
              placeholder="请输入"
              onBlur={(e) => handleNumberChange(e, field)}
            />
          </Form.Item>
        );
      },
    },
    {
      key: "withTaxPrice",
      dataIndex: "withTaxPrice",
      title: "单价（含税）",
      align: "left",
      width: 160,
      render: (text: any, field: any, index: any) => {
        return (
          <Form.Item
            key={field.name}
            style={{ margin: 0 }}
            name={[field.name, "withTaxPrice"]}
            fieldKey={[field.fieldKey, "withTaxPrice"]}
          >
            <InputNumber
              style={{ width: "150px" }}
              //   precision={2}
              controls={false}
              //@ts-ignore
              min="0"
              //@ts-ignore
              max="999999999999.9999"
              maxLength={15}
              stringMode
              placeholder="请输入"
              onBlur={(e) => handleTaxPrice(e, field)}
            />
          </Form.Item>
        );
      },
    },
    {
      key: "withTaxAmount",
      dataIndex: "withTaxAmount",
      width: 160,
      title: (
        <div>
          <span style={{ color: "red" }}>*</span>金额（含税）
        </div>
      ),
      align: "left",
      render: (text: any, field: any, index: any) => {
        return (
          <Form.Item
            key={field.name}
            style={{ margin: 0 }}
            rules={[{ required: true, message: "请输入金额(含税)" }]}
            name={[field.name, "withTaxAmount"]}
            fieldKey={[field.fieldKey, "withTaxAmount"]}
            validateTrigger={["onSubmit"]}
          >
            <InputNumber
              style={{ width: "150px" }}
              precision={2}
              controls={false}
              //@ts-ignore
              min="0"
              //@ts-ignore
              max="999999999999.9999"
              maxLength={15}
              stringMode
              placeholder="请输入"
              onBlur={(e) => handleTaxMoney(e, field)}
            />
          </Form.Item>
        );
      },
    },
    {
      key: "taxRate",
      dataIndex: "taxRate",
      width: 160,
      title: (
        <div>
          <span style={{ color: "red" }}>*</span>税率/征收率
        </div>
      ),
      align: "left",
      render: (text: any, field: any, index: any) => {
        const { lines = [] } = form.getFieldsValue();
        return (
          <Form.Item
            key={field.name}
            style={{ margin: 0 }}
            rules={[{ required: true, message: "请输入税率/征收率" }]}
            name={[field.name, "taxRate"]}
            fieldKey={[field.fieldKey, "taxRate"]}
            validateTrigger={["onSubmit"]}
          >
            <Select
              placeholder="请输入"
              options={[
                {
                  value: lines[field.name]?.taxRate ?? "",
                  label: lines[field.name]?.taxRateLabel ?? "",
                },
              ]}
              onChange={(value) => handleTaxRate(value, field)}
            />
          </Form.Item>
        );
      },
    },
    {
      key: "taxAmount",
      dataIndex: "taxAmount",
      width: 160,
      title: (
        <div>
          <span style={{ color: "red" }}>*</span>税额
        </div>
      ),
      align: "left",
      render: (text: any, field: any, index: any) => {
        return (
          <Form.Item
            key={field.name}
            style={{ margin: 0 }}
            rules={[{ required: true, message: "请输入税额" }]}
            name={[field.name, "taxAmount"]}
            fieldKey={[field.fieldKey, "taxAmount"]}
            validateTrigger={["onSubmit"]}
          >
            <InputNumber
              style={{ width: "150px" }}
              precision={2}
              controls={false}
              //@ts-ignore
              min="0"
              //@ts-ignore
              max="999999999999.9999"
              maxLength={15}
              stringMode
              placeholder="请输入"
            />
          </Form.Item>
        );
      },
    },
  ];
  const [columns, setColumns] = useState(initColumns);

  useEffect(() => {
    const levelData = require("./province-city-china.json");
    console.log(levelData, "-------");
    function formatLevelData(data: any) {
      data.forEach((item: any) => {
        item["value"] = item.name;
        item["label"] = item.name;
        if (item.children) {
          formatLevelData(item.children);
        } else {
          return;
        }
      });
    }
    formatLevelData(levelData);
    setLevalData(levelData);
  }, []);

  const handleTableAdd = (add: any) => {
    let tableObj = form.getFieldValue("lines");
    if (tableObj.length) {
      let index = Object.keys(tableObj).length - 1 + "";
      let values = tableObj[index] ? tableObj[index] : {};
      let requiredArr = ["invoiceGoodsName", "withTaxAmount", "taxAmount"]; //表格表单必填字段
      for (let i in requiredArr) {
        if (values[requiredArr[i]] == undefined) {
          message.warning("请完善发票行信息，再来增加新的行");
          return;
        }
      }
      if (businessType == "4") {
        message.warning("当前模式只能开具一行明细行");
        return;
      }
      console.log(form.getFieldValue("lines"));

      InitRef();
      add();
    } else {
      InitRef();
      add();
    }
  };

  const handleTableDel = (remove: any, fields: any) => {
    let { lines } = form.getFieldsValue();
    let removeItem: any = [];
    selectedRowKeys.map((item, index) => {
      fields.map((field: any) => {
        if (field.key == item) {
          console.log(field.key, item, field.name);
          if (lines[field.name]["discountLineFlag"]) {
            lines[field.name - 1]["discountFlag"] = false;
          }
          if (
            lines[field.name]["discountFlag"] &&
            lines[field.name + 1]["discountLineFlag"]
          ) {
            message.warn("请先删除折扣行");
            return;
          }
          removeItem.unshift(field.name);
        }
      });
    });
    let newLines = JSON.parse(JSON.stringify(lines));
    console.log(lines, newLines);
    removeItem.forEach((index: number) => {
      newLines.splice(index, 1);
    });

    form.resetFields(["lines"]);
    form.setFieldsValue({
      lines: newLines,
    });
    setRefresh(!refresh); //用来刷新表单视图，保证数据正常渲染
    setSelectedRowKeys([]);
  };

  const handleDiscount = (fields: any) => {
    let { lines } = form.getFieldsValue();

    let selectionIndex: any[] = [];
    selectedRowKeys.map((item: any) =>
      // selectedRowKeys.indexOf(item)
      fields.map((field: any) => {
        if (field.key == item) {
          selectionIndex.push(field.name);
        }
      })
    );
    if (selectionIndex.length) {
      console.log(selectionIndex.length, fields, selectionIndex);
      let isValided = selectionIndex.map((index: any) => {
        //判断选择折扣的行是否已经完善信息 金额必填
        console.log(lines[index]["withTaxAmount"], index);
        if (
          currentTax &&
          (lines[index]["withTaxAmount"] == "" ||
            lines[index]["withTaxAmount"] == undefined ||
            lines[index]["withTaxAmount"] == 0)
        ) {
          return false;
        } else if (
          !currentTax &&
          (lines[index]["amount"] == "" ||
            lines[index]["amount"] == undefined ||
            lines[index]["amount"] == 0)
        ) {
          return false;
        } else {
          return true;
        }
      });
      let discountFlaged = selectionIndex
        .map((index: any) => {
          //判断当前选择折扣行是否已折扣
          if (
            lines[index]["discountFlag"] ||
            lines[index]["discountLineFlag"] == true
          ) {
            return true;
          } else {
            return false;
          }
        })
        .some((val) => val);
      console.log(discountFlaged, isValided, "==========isValided=======");
      if (discountFlaged) {
        message.warn("不允许重复增加折扣");
        return;
      } else if (isValided.every((val) => val)) {
        modal
          .open({
            width: "600px",
            content(_1, reject) {
              return (
                <div key={Math.random()}>
                  <DiscountModal
                    form={form}
                    currentTax={currentTax}
                    selectOption={selectionIndex}
                    data={lines}
                    onClose={reject}
                  />
                </div>
              );
            },
          })
          .then((res) => {
            // console.log(res,'res====res')
            let { lines } = form.getFieldsValue();
            refreshColumns();
          });
      } else {
        message.warn("添加折扣时项目金额不能为空或为零");
        return;
      }
    } else {
      message.warn("请至少选择一条数据");
      return;
    }
    setSelectedRowKeys([]); //清空勾选
  };
  const refreshColumns = () => {
    let { lines } = form.getFieldsValue();
    const newColumns: ColumnProps[] = [
      {
        key: "",
        title: "序号",
        width: 60,
        fixed: "left",
        render: (text: any, field: any, index: any) => (
          <Form.Item
            key={field.name}
            style={{ margin: 0 }}
            name={[field.name, "lineNum"]}
            fieldKey={[field.fieldKey, "lineNum"]}
          >
            {index + 1}
          </Form.Item>
        ),
      },
      {
        key: "invoiceGoodsName",
        dataIndex: "invoiceGoodsName",
        title: (
          <div>
            <span style={{ color: "red" }}>*</span>项目名称
          </div>
        ),
        align: "left",
        width: 300,
        render: (text: any, field: any, index: any) => {
          return (
            <Row gutter={8}>
              <Col span={22}>
                <Form.Item
                  key={field.name}
                  style={{ margin: 0 }}
                  rules={[{ required: true, message: "请输入项目名称" }]}
                  name={[field.name, "invoiceGoodsName"]}
                  fieldKey={[field.fieldKey, "invoiceGoodsName"]}
                  validateTrigger={["onSubmit"]}
                >
                  {lines[field.name]?.discountFlag ||
                  lines[field.name]?.discountLineFlag ? (
                    <div style={{ padding: "0 11px" }}>
                      {lines[field.name]?.discountLineFlag ? (
                        <span>
                          <Tag color="red">折扣</Tag>
                          {lines[field.name]?.invoiceGoodsName}
                        </span>
                      ) : (
                        lines[field.name]?.invoiceGoodsName
                      )}
                    </div>
                  ) : (
                    <AutoComplete
                      // value={nameValue}
                      allowClear={true}
                      backfill={true}
                      options={nameOption}
                      onSelect={onNameSelect}
                      onSearch={onNameSearch}
                      // onChange={onNameChange}
                      placeholder="请输入项目名称"
                    />
                  )}
                </Form.Item>
              </Col>
              <Col span={1}>
                {lines[field.name]?.discountFlag ||
                lines[field.name]?.discountLineFlag ? null : (
                  <Button
                    type="primary"
                    ghost
                    icon={<MoreOutlined />}
                    onClick={() => handleEntryName(field)}
                  />
                )}
              </Col>
            </Row>
          );
        },
      },
      {
        key: "spec",
        dataIndex: "spec",
        title: "规格型号",
        align: "left",
        render: (text: any, field: any, index: any) => {
          return (
            <Form.Item
              key={field.name}
              style={{ margin: 0 }}
              name={[field.name, "spec"]}
              fieldKey={[field.fieldKey, "spec"]}
            >
              {lines[field.name]?.discountFlag ||
              lines[field.name]?.discountLineFlag ? (
                lines[field.name]?.spec
              ) : (
                <Input placeholder="请输入" />
              )}
            </Form.Item>
          );
        },
      },
      {
        key: "unit",
        dataIndex: "unit",
        title: "单位",
        align: "left",
        render: (text: any, field: any, index: any) => {
          return (
            <Form.Item
              key={field.name}
              style={{ margin: 0 }}
              name={[field.name, "unit"]}
              fieldKey={[field.fieldKey, "unit"]}
            >
              {lines[field.name]?.discountFlag ||
              lines[field.name]?.discountLineFlag ? (
                lines[field.name]?.unit
              ) : (
                <Input placeholder="请输入" />
              )}
            </Form.Item>
          );
        },
      },
      {
        key: "quantity",
        dataIndex: "quantity",
        title: "数量",
        align: "left",
        width: 160,
        render: (text: any, field: any, index: any) => {
          return (
            <Form.Item
              key={field.name}
              style={{ margin: 0 }}
              name={[field.name, "quantity"]}
              fieldKey={[field.fieldKey, "quantity"]}
            >
              {lines[field.name]?.discountFlag ||
              lines[field.name]?.discountLineFlag ? (
                lines[field.name]?.quantity
              ) : (
                <InputNumber
                  style={{ width: "150px" }}
                  //   precision={2}
                  controls={false}
                  //@ts-ignore
                  min="0"
                  //@ts-ignore
                  max="999999999999.9999"
                  maxLength={15}
                  stringMode
                  placeholder="请输入"
                  onBlur={(e) => handleNumberChange(e, field)}
                />
              )}
            </Form.Item>
          );
        },
      },
      {
        key: "withTaxPrice",
        dataIndex: "taxRate",
        align: "left",
        width: 160,
        title: `${currentTax ? "单价（含税）" : "单价（不含税）"}`,
        render: currentTax
          ? (text: any, field: any, index: any) => {
              return (
                <Form.Item
                  key={field.name}
                  style={{ margin: 0 }}
                  name={[field.name, "withTaxPrice"]}
                  fieldKey={[field.fieldKey, "withTaxPrice"]}
                >
                  {lines[field.name]?.discountFlag ||
                  lines[field.name]?.discountLineFlag ? (
                    <div style={{ padding: "0 11px" }}>
                      {lines[field.name]?.withTaxPrice}
                    </div>
                  ) : (
                    <InputNumber
                      style={{ width: "150px" }}
                      //   precision={2}
                      controls={false}
                      //@ts-ignore
                      min="0"
                      //@ts-ignore
                      max="999999999999.9999"
                      maxLength={15}
                      stringMode
                      placeholder="请输入"
                      onBlur={(e) => handleTaxPrice(e, field)}
                    />
                  )}
                </Form.Item>
              );
            }
          : (text: any, field: any, index: any) => {
              return (
                <Form.Item
                  key={field.name}
                  style={{ margin: 0 }}
                  name={[field.name, "price"]}
                  fieldKey={[field.fieldKey, "price"]}
                >
                  {lines[field.name]?.discountFlag ||
                  lines[field.name]?.discountLineFlag ? (
                    <div style={{ padding: "0 11px" }}>
                      {lines[field.name]?.price}
                    </div>
                  ) : (
                    <InputNumber
                      style={{ width: "150px" }}
                      controls={false}
                      //@ts-ignore
                      min="0"
                      //@ts-ignore
                      max="999999999999.9999"
                      maxLength={15}
                      stringMode
                      placeholder="请输入"
                      onBlur={(e) => handleWithoutTaxPrice(e, field)}
                    />
                  )}
                </Form.Item>
              );
            },
      },
      {
        key: "withTaxAmount",
        dataIndex: "taxRate",
        width: 160,
        align: "left",
        title: currentTax ? (
          <div>
            <span style={{ color: "red" }}>*</span>金额（含税）
          </div>
        ) : (
          <div>
            <span style={{ color: "red" }}>*</span>金额（不含税）
          </div>
        ),
        render: currentTax
          ? (text: any, field: any, index: any) => {
              return (
                <Form.Item
                  key={field.name}
                  style={{ margin: 0 }}
                  rules={[{ required: true, message: "请输入金额(含税)" }]}
                  // name={[
                  //   field.name,
                  //   lines[field.name]?.discountFlag &&
                  //   lines[field.name]?.discountLineFlag
                  //     ? "discountAmount"
                  //     : "withTaxAmount",
                  // ]}
                  name={[
                    field.name,
                    lines[field.name]?.discountFlag
                      ? "withTaxAmount"
                      : lines[field.name]?.discountLineFlag
                      ? "discountAmount"
                      : "withTaxAmount",
                  ]}
                  fieldKey={[
                    field.fieldKey,
                    lines[field.name]?.discountFlag
                      ? "withTaxAmount"
                      : lines[field.name]?.discountLineFlag
                      ? "discountAmount"
                      : "withTaxAmount",
                  ]}
                  validateTrigger={["onSubmit"]}
                >
                  {lines[field.name]?.discountFlag ||
                  lines[field.name]?.discountLineFlag ? (
                    lines[field.name]?.withTaxAmount
                  ) : (
                    <InputNumber
                      style={{ width: "150px" }}
                      precision={2}
                      controls={false}
                      //@ts-ignore
                      min="0"
                      //@ts-ignore
                      max="999999999999.9999"
                      maxLength={15}
                      stringMode
                      placeholder="请输入"
                      onBlur={(e) => handleTaxMoney(e, field)}
                    />
                  )}
                </Form.Item>
              );
            }
          : (text: any, field: any, index: any) => {
              return (
                <Form.Item
                  key={field.name}
                  style={{ margin: 0 }}
                  rules={[{ required: true, message: "请输入金额(不含税)" }]}
                  name={[
                    field.name,
                    lines[field.name]?.discountFlag
                      ? "amount"
                      : lines[field.name]?.discountLineFlag
                      ? "discountWithTaxAmount"
                      : "amount",
                  ]}
                  fieldKey={[
                    field.fieldKey,
                    lines[field.name]?.discountFlag
                      ? "amount"
                      : lines[field.name]?.discountLineFlag
                      ? "discountWithTaxAmount"
                      : "amount",
                  ]}
                  validateTrigger={["onSubmit"]}
                >
                  {lines[field.name]?.discountFlag ||
                  lines[field.name]?.discountLineFlag ? (
                    lines[field.name]?.amount
                  ) : (
                    <InputNumber
                      style={{ width: "150px" }}
                      precision={2}
                      controls={false}
                      //@ts-ignore
                      min="0"
                      //@ts-ignore
                      max="999999999999.9999"
                      maxLength={15}
                      stringMode
                      placeholder="请输入"
                      onBlur={(e) => handleWithoutTaxMoney(e, field)}
                    />
                  )}
                </Form.Item>
              );
            },
      },
      {
        key: "taxRate",
        dataIndex: "taxRate",
        width: 160,
        title: (
          <div>
            <span style={{ color: "red" }}>*</span>税率/征收率
          </div>
        ),
        align: "left",
        render: (text: any, field: any, index: any) => {
          const { lines = [] } = form.getFieldsValue();
          return (
            <Form.Item
              key={field.name}
              style={{ margin: 0 }}
              rules={[{ required: true, message: "请输入税率/征收率" }]}
              name={[field.name, "taxRate"]}
              fieldKey={[field.fieldKey, "taxRate"]}
              validateTrigger={["onSubmit"]}
            >
              {lines[field.name]?.discountFlag ||
              lines[field.name]?.discountLineFlag ? (
                <Select
                  options={[
                    {
                      value: lines[field.name]?.taxRate ?? "",
                      label: lines[field.name]?.taxRateLabel ?? "",
                    },
                  ]}
                  open={false}
                  bordered={false}
                  showArrow={false}
                />
              ) : (
                <Select
                  placeholder="请输入"
                  options={[
                    {
                      value: lines[field.name]?.taxRate ?? "",
                      label: lines[field.name]?.taxRateLabel ?? "",
                    },
                  ]}
                  onChange={(value) => handleTaxRate(value, field)}
                />
              )}
            </Form.Item>
          );
        },
      },
      {
        key: "taxAmount",
        dataIndex: "taxAmount",
        width: 160,
        title: (
          <div>
            <span style={{ color: "red" }}>*</span>税额
          </div>
        ),
        align: "left",
        render: (text: any, field: any, index: any) => {
          return (
            <Form.Item
              key={field.name}
              style={{ margin: 0 }}
              rules={[{ required: true, message: "请输入税额" }]}
              name={[field.name, "taxAmount"]}
              fieldKey={[field.fieldKey, "taxAmount"]}
              validateTrigger={["onSubmit"]}
            >
              {lines[field.name]?.discountFlag ||
              lines[field.name]?.discountLineFlag ? (
                lines[field.name]?.taxAmount
              ) : (
                <InputNumber
                  style={{ width: "150px" }}
                  precision={2}
                  controls={false}
                  //@ts-ignore
                  min="0"
                  //@ts-ignore
                  max="999999999999.9999"
                  maxLength={15}
                  stringMode
                  placeholder="请输入"
                />
              )}
            </Form.Item>
          );
        },
      },
    ];
    setColumns(newColumns);
  };
  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    console.log("selectedRowKeys changed: ", newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };
  useEffect(() => {
    refreshColumns();
    console.log("refreshColumns=======================");
  }, [currentTax, refresh]);

  const onTaxChange = (e: any) => {
    setCurrentTax(e);
    let { lines } = form.getFieldsValue();
    form.setFieldsValue({
      lines,
    });
  };
  const colorArr = ["blue", "geekblue", "purple", "cyan", "green"];
  return (
    <>
      {modalHolder}
      <Form form={form}>
        <Form.List name="lines" key="lines">
          {(fields, { add, remove }) => {
            return (
              <>
                <Row style={{ marginTop: "16px" }}>
                  <Col span={24}>
                    <AdCard
                      title="开票信息"
                      extra={
                        <Row
                          style={{
                            position: "absolute",
                            left: "100px",
                            top: "14px",
                          }}
                        >
                          {type !== undefined &&
                          type !== "" &&
                          type !== "null" ? (
                            <Tag color={colorArr[4]} style={{ padding: "5px" }}>
                              {invoiceTypeEnum[type]}
                            </Tag>
                          ) : null}
                          {businessType !== undefined &&
                          businessType !== "" &&
                          businessType !== "null" ? (
                            <Tag color={colorArr[1]} style={{ padding: "5px" }}>
                              {businessTypeEnum[businessType]}
                            </Tag>
                          ) : null}
                        </Row>
                      }
                    >
                      <Row justify="space-between">
                        <Col>
                          <Row style={{ alignItems: "center" }}>
                            <Button
                              style={{ marginRight: "5px" }}
                              type="primary"
                              icon={<PlusOutlined />}
                              // onClick={() => add()}
                              onClick={() => handleTableAdd(add)}
                            >
                              增行
                            </Button>
                            <Button
                              style={{ marginRight: "5px" }}
                              disabled={selectedRowKeys.length ? false : true}
                              onClick={() => handleTableDel(remove, fields)}
                            >
                              删除
                            </Button>
                            <Button
                              style={{ marginRight: "5px" }}
                              // onClick={() =>
                              //   detailDrawer.current?.isDetailDrawerShow()
                              // }
                              onClick={() => {
                                const getTableData = (value: any) => {
                                  form.setFieldsValue({ lines: value });
                                };
                                modal.open({
                                  width: 1000,
                                  content(resolve, reject) {
                                    return (
                                      <DetailModal
                                        onClose={reject}
                                        getTableData={getTableData}
                                      />
                                    );
                                  },
                                });
                              }}
                            >
                              明细导入
                            </Button>
                            <Button
                              style={{ marginRight: "5px" }}
                              onClick={() => handleDiscount(fields)}
                            >
                              添加折扣
                            </Button>
                            <Switch
                              style={{ marginLeft: "16px" }}
                              checkedChildren="含税"
                              unCheckedChildren="不含税"
                              defaultChecked
                              onChange={onTaxChange}
                            />
                          </Row>
                        </Col>
                        <Col>
                          <Row>
                            <Button
                              style={{ marginRight: "5px" }}
                              onClick={() => {
                                modal.open({
                                  width: 1000,
                                  content(resolve, reject) {
                                    return (
                                      <IssueInfo
                                        form={form}
                                        onClose={reject}
                                        InitRef={InitRef}
                                      />
                                    );
                                  },
                                });
                              }}
                            >
                              重选发票类型
                            </Button>
                            <Button
                              onClick={() => {
                                InitRef();
                                form.setFieldsValue({
                                  lines: [
                                    {
                                      invoiceGoodsName: "",
                                      discountFlag: false,
                                    },
                                  ],
                                });
                                refreshColumns();
                              }}
                            >
                              清空重填
                            </Button>
                          </Row>
                        </Col>
                      </Row>
                    </AdCard>
                  </Col>
                </Row>
                <AdTable
                  dataSource={fields}
                  columns={columns}
                  rowKey="key"
                  pagination={false}
                  scroll={{ x: 1000 }}
                  rowSelection={{
                    onChange: onSelectChange,
                    selectedRowKeys,
                    preserveSelectedRowKeys: false,
                  }}
                  summary={() => {
                    totalAmountRef.current = 0;
                    totalMoneyRef.current = 0;
                    let { lines } = form.getFieldsValue();
                    lines?.map((item: any) => {
                      if (item?.amount) {
                        totalMoneyRef.current =
                          totalMoneyRef.current + parseFloat(item?.amount);
                      }
                      if (item?.taxAmount) {
                        totalAmountRef.current =
                          totalAmountRef.current + parseFloat(item?.taxAmount);
                      }
                    });
                    setTotalMoney(totalMoneyRef.current);
                    setTotalAmount(totalAmountRef.current);
                    return <></>;
                  }}
                />
                <Row
                  style={{
                    display: "fixed",
                    border: "none",
                    textAlign: "center",
                  }}
                >
                  <div
                    style={{ height: "45px", padding: "13px 0", width: "100%" }}
                  >
                    <span style={{ marginLeft: "46px", float: "left" }}>
                      合计
                    </span>
                    <div
                      style={{
                        float: "right",
                        marginLeft: "160px",
                        paddingRight: "8px",
                      }}
                    >
                      {`${"￥" + MyMath.num2money(totalAmountRef.current)}`}
                    </div>
                    <div style={{ float: "right" }}>
                      {`${"￥" + MyMath.num2money(totalMoneyRef.current)}`}
                    </div>
                  </div>
                </Row>
                <div>
                  <Row style={{ padding: "13px 0" }}>
                    <Col
                      span={5}
                      offset={16}
                      style={{ fontWeight: 700, fontSize: "14px" }}
                    >
                      {`价税合计（大写）：${MyMath.convertToChinese(
                        // _.add(totalAmountRef.current, totalMoneyRef.current)
                        Number(
                          new Decimal(totalAmountRef.current)
                            .add(new Decimal(totalMoneyRef.current))
                            .toDecimalPlaces(2)
                        )
                      )}`}
                    </Col>
                    <Col
                      span={3}
                      offset={0}
                      style={{ fontWeight: 700, fontSize: "14px" }}
                    >
                      {`价税合计（小写）：${MyMath.num2money(
                        // _.add(totalAmountRef.current, totalMoneyRef.current)
                        Number(
                          new Decimal(totalAmountRef.current)
                            .add(new Decimal(totalMoneyRef.current))
                            .toDecimalPlaces(2)
                        )
                      )}`}
                    </Col>
                  </Row>
                </div>
              </>
            );
          }}
        </Form.List>
      </Form>
    </>
  );
};

export default TableFormList;
