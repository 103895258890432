import { RouteProps } from '@models';
import Invoice from './invoice';
import Index from './index';
import Detail from './detail';

const changeRoutes: RouteProps[] = [
  { path: '/tax/transfer/invoice', component: Invoice, private: true },
  { path: '/tax/transfer/detail/:id', component: Detail, private: true },
  { path: '/tax/transfer', component: Index, private: true },
];

export default changeRoutes;
