import { Math as MyMath } from "@utils";
import { Button, Descriptions } from "antd";
import cname from "classnames";
import Decimal from "decimal.js";
import * as React from "react";
import { Scrollbars } from "react-custom-scrollbars";
import { useParams } from "react-router";
import styles from "./index.module.scss";
import FreightTransportView from "./specialViewer/freightTransportView";

export interface Props
  extends React.Props<HTMLElement>,
    React.BaseHTMLAttributes<HTMLElement> {
  invoice: JSONObject;
  onClose: () => void;
}

const InvoicePreview: React.FC<Props> = ({ invoice, onClose }: Props) => {
  const { type, businessType } = useParams<any>();
  const renderEmptyRow = () => {
    const invoiceRowNum = (invoice.lines || []).length;
    if (invoiceRowNum < 10) {
      const emptyRowNum = 10 - invoiceRowNum;
      const emptyRowArr = [];

      for (let i = 0; i < emptyRowNum; i++) {
        emptyRowArr.push(i);
      }

      return emptyRowArr.map((index) => (
        <div
          key={index}
          className={cname(styles.tableRow)}
          style={{
            display: "flex",
            height: 30,
            maxHeight: 30,
          }}
        >
          <div style={{ textAlign: "left", width: "30.3%" }} />
          <div style={{ textAlign: "right", width: "10.1%" }} />
          <div style={{ textAlign: "center", width: "9.6%" }} />
          <div style={{ textAlign: "right", width: "7%" }} />
          <div style={{ textAlign: "right", width: "13%" }} />
          <div style={{ textAlign: "right", width: "10%" }} />
          <div style={{ textAlign: "right", width: "10%" }} />
          <div style={{ textAlign: "right", width: "10%" }} />
        </div>
      ));
    }
  };
  const isConstructionService = businessType == "1"; //特定类型为建筑服务
  const isFreightTransport = businessType == "2"; //特定类型为货物运输服务
  const isRealEstateSales = businessType == "3"; //特定类型为不动产销售服务
  const isRealEstate = businessType == "4"; //特定类型为不动产经营租赁服务
  const renderDetailTitle = () => {
    if (isRealEstate || isRealEstateSales) {
      return (
        <>
          <div className={cname(styles.titleText)} style={{ width: "20%" }}>
            项目名称
          </div>
          <div
            className={cname(styles.titleText)}
            style={{ width: "20%", textAlign: "left" }}
          >
            房屋产权证书/不动产权证号
          </div>
          <div className={cname(styles.titleText)} style={{ width: "10%" }}>
            面积单位
          </div>
          <div
            className={cname(styles.titleText)}
            style={{ width: "7%", textAlign: "right" }}
          >
            数量
          </div>
          <div
            className={cname(styles.titleText)}
            style={{ width: "13%", textAlign: "right" }}
          >
            单价
          </div>
          <div
            className={cname(styles.titleText)}
            style={{ width: "10%", textAlign: "right" }}
          >
            金额
          </div>
          <div className={cname(styles.titleText)} style={{ width: "10%" }}>
            税率/征收率
          </div>
          <div
            className={cname(styles.titleText)}
            style={{ width: "10%", textAlign: "right" }}
          >
            税额
          </div>
        </>
      );
    } else if (isConstructionService) {
      return (
        <>
          <div className={cname(styles.titleText)} style={{ width: "20%" }}>
            项目名称
          </div>
          <div
            className={cname(styles.titleText)}
            style={{ width: "30%", textAlign: "center" }}
          >
            建筑服务发生地
          </div>
          <div className={cname(styles.titleText)} style={{ width: "20%" }}>
            建筑项目名称
          </div>
          <div
            className={cname(styles.titleText)}
            style={{ width: "10%", textAlign: "right" }}
          >
            金额
          </div>
          <div className={cname(styles.titleText)} style={{ width: "10%" }}>
            税率/征收率
          </div>
          <div
            className={cname(styles.titleText)}
            style={{ width: "10%", textAlign: "right" }}
          >
            税额
          </div>
        </>
      );
    } else {
      return (
        <>
          <div className={cname(styles.titleText)} style={{ width: "30.3%" }}>
            项目名称
          </div>
          <div className={cname(styles.titleText)} style={{ width: "10.1%" }}>
            规格型号
          </div>
          <div className={cname(styles.titleText)} style={{ width: "9.6%" }}>
            单位
          </div>
          <div
            className={cname(styles.titleText)}
            style={{ width: "7%", textAlign: "right" }}
          >
            数量
          </div>
          <div
            className={cname(styles.titleText)}
            style={{ width: "13%", textAlign: "right" }}
          >
            单价
          </div>
          <div
            className={cname(styles.titleText)}
            style={{ width: "10%", textAlign: "right" }}
          >
            金额
          </div>
          <div className={cname(styles.titleText)} style={{ width: "10%" }}>
            税率/征收率
          </div>
          <div
            className={cname(styles.titleText)}
            style={{ width: "10%", textAlign: "right" }}
          >
            税额
          </div>
        </>
      );
    }
  };

  const renderDetailContent = (line: JSONObject) => {
    if (isRealEstate || isRealEstateSales) {
      return (
        <>
          <div
            style={{
              textAlign: "left",
              width: "20%",
              paddingLeft: 10,
            }}
          >{`${line.invoiceGoodsName}`}</div>
          <div
            style={{
              textAlign: "left",
              width: "20%",
              paddingBottom: 16,
            }}
          >
            {isRealEstate
              ? !line?.discountLineFlag &&
                (invoice?.businessInfo?.BDC_CQJH ?? "")
              : !line?.discountLineFlag &&
                (invoice?.businessInfo?.BDCXS_CQZH ?? "")}
          </div>
          <div style={{ textAlign: "center", width: "10%" }}>
            {isRealEstate
              ? !line?.discountLineFlag &&
                (invoice?.businessInfo?.BDC_PJDW ?? "")
              : !line?.discountLineFlag &&
                (invoice?.businessInfo?.BDCXS_MJDW ?? "")}
          </div>
          <div style={{ textAlign: "right", width: "7%" }}>{line.quantity}</div>
          <div style={{ textAlign: "right", width: "13%" }}>
            {line.price
              ? `${String(
                  new Decimal(line.price).toFixed(13, Decimal.ROUND_HALF_UP)
                )}`
              : ""}
          </div>
          <div style={{ textAlign: "right", width: "10%" }}>
            {line.amount
              ? `${String(
                  new Decimal(line.amount).toFixed(2, Decimal.ROUND_HALF_UP)
                )}`
              : ""}
          </div>
          <div style={{ textAlign: "center", width: "10%" }}>
            {(line.discountPolicyRemark ?? "") !== ""
              ? line.discountPolicyRemark
              : `${line.taxRate}%`}
          </div>
          <div style={{ textAlign: "right", width: "10%" }}>
            {line.taxAmount
              ? `${String(
                  new Decimal(line.taxAmount).toFixed(2, Decimal.ROUND_HALF_UP)
                )}`
              : ""}
          </div>
        </>
      );
    } else if (isConstructionService) {
      return (
        <>
          <div
            style={{
              textAlign: "left",
              width: "20%",
              paddingLeft: 10,
            }}
          >
            {`${line.invoiceGoodsName}`}
          </div>
          <div
            style={{
              textAlign: "center",
              width: "30%",
              paddingBottom: 16,
            }}
          >
            {`${invoice?.businessInfo?.GZFW_FSD ?? ""}${
              invoice?.businessInfo?.GZFW_XXDZ ?? ""
            }`}
          </div>
          <div style={{ textAlign: "center", width: "20%" }}>
            {`${invoice?.businessInfo?.GZFW_XMMC ?? ""}`}
          </div>
          <div style={{ textAlign: "center", width: "10%" }}>
            {line.amount
              ? `${String(
                  new Decimal(line.amount).toFixed(2, Decimal.ROUND_HALF_UP)
                )}`
              : ""}
          </div>
          <div style={{ textAlign: "center", width: "10%" }}>
            {(line.discountPolicyRemark ?? "") !== ""
              ? line.discountPolicyRemark
              : `${line.taxRate}%`}
          </div>
          <div style={{ textAlign: "right", width: "10%" }}>
            {line.taxAmount
              ? `${String(
                  new Decimal(line.taxAmount).toFixed(2, Decimal.ROUND_HALF_UP)
                )}`
              : ""}
          </div>
        </>
      );
    } else {
      return (
        <>
          <div
            style={{
              textAlign: "left",
              width: "30.3%",
              paddingLeft: 10,
            }}
          >{`${line.invoiceGoodsName}`}</div>
          <div
            style={{
              textAlign: "center",
              width: "10.1%",
              paddingBottom: 16,
            }}
          >
            {line.spec}
          </div>
          <div style={{ textAlign: "center", width: "9.6%" }}>{line.unit}</div>
          <div style={{ textAlign: "right", width: "7%" }}>{line.quantity}</div>
          <div style={{ textAlign: "right", width: "13%" }}>
            {line.price
              ? `${String(
                  new Decimal(line.price).toFixed(13, Decimal.ROUND_HALF_UP)
                )}`
              : ""}
          </div>
          <div style={{ textAlign: "right", width: "10%" }}>
            {line.amount
              ? `${String(
                  new Decimal(line.amount).toFixed(2, Decimal.ROUND_HALF_UP)
                )}`
              : ""}
          </div>
          <div style={{ textAlign: "center", width: "10%" }}>
            {(line.discountPolicyRemark ?? "") !== ""
              ? line.discountPolicyRemark
              : `${line.taxRate}%`}
          </div>
          <div style={{ textAlign: "right", width: "10%" }}>
            {line.taxAmount
              ? `${String(
                  new Decimal(line.taxAmount).toFixed(2, Decimal.ROUND_HALF_UP)
                )}`
              : ""}
          </div>
        </>
      );
    }
  };

  const renderRemarkContent = () => {
    if (isRealEstate) {
      return (
        <>
          <div
            className={cname(styles.line)}
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <div>
              <div className={cname(styles.label)}>
                <span>不动产地址</span>
              </div>
              <div className={cname(styles.value)}>
                {`${invoice?.businessInfo?.BDC_SF ?? ""}${
                  invoice?.businessInfo?.BDC_XXDZ ?? ""
                }`}
              </div>
            </div>
            <div>
              <div className={cname(styles.label)}>
                <span>租赁期起止</span>
              </div>
              <div className={cname(styles.value)}>
                {`${invoice?.businessInfo?.BDC_QSRQ ?? ""} ${
                  invoice?.businessInfo?.BDC_JZRQ ?? ""
                }`}
              </div>
            </div>
            <div>
              <div className={cname(styles.label)}>
                <span>跨地（市）标志</span>
              </div>
              <div className={cname(styles.value)}>
                {invoice?.businessInfo?.BDC_KSBZ === "Y" ? "是" : "否"}
              </div>
            </div>
          </div>
        </>
      );
    } else if (isRealEstateSales) {
      return (
        <>
          <Descriptions column={5}>
            <Descriptions.Item
              label={
                <span style={{ color: "#a15f3b" }}>
                  不动产单元代码/网签合同备案编号
                </span>
              }
              span={2}
            >
              {`${invoice?.businessInfo?.BDCXS_HTBH ?? ""}`}
            </Descriptions.Item>

            <Descriptions.Item
              label={<span style={{ color: "#a15f3b" }}>跨地（市）标志</span>}
            >
              {invoice?.businessInfo?.BDCXS_KQBZ === "Y" ? "是" : "否"}
            </Descriptions.Item>
            <Descriptions.Item
              span={2}
              label={
                <span style={{ color: "#a15f3b" }}>土地增值税项目编号</span>
              }
            >
              {`${invoice?.businessInfo?.BDCXS_XMBH ?? ""}`}
            </Descriptions.Item>
            <Descriptions.Item
              span={
                invoice?.businessInfo?.BDCXS_DQDZ.length +
                  invoice?.businessInfo?.BDCXS_XXDZ.length >
                24
                  ? 3
                  : 2
              }
              label={<span style={{ color: "#a15f3b" }}>不动产地址</span>}
            >{`${invoice?.businessInfo?.BDCXS_DQDZ ?? ""}${
              invoice?.businessInfo?.BDCXS_XXDZ ?? ""
            }`}</Descriptions.Item>
            <Descriptions.Item
              label={<span style={{ color: "#a15f3b" }}>核定计税价格</span>}
            >
              {`${invoice?.businessInfo?.BDCXS_JSJG ?? ""}`}
            </Descriptions.Item>
            <Descriptions.Item
              label={<span style={{ color: "#a15f3b" }}>实际成交含税金额</span>}
            >
              {invoice?.businessInfo?.BDCXS_CJJE ?? ""}
            </Descriptions.Item>
          </Descriptions>
        </>
      );
    } else if (isConstructionService) {
      return (
        <>
          <div className={cname(styles.line)}>
            <div className={cname(styles.label)}>
              <span>土地增值税项目编号</span>
            </div>
            <div className={cname(styles.value)}>
              {invoice?.businessInfo?.GZFW_XMBH}
            </div>
          </div>
          <div className={cname(styles.line)}>
            <div className={cname(styles.label)}>
              <span>跨地（市）标志</span>
            </div>
            <div className={cname(styles.value)}>
              {invoice?.businessInfo?.GZFW_KSBZ === "Y" ? "是" : "否"}
            </div>
          </div>
        </>
      );
    }
  };
  return (
    <>
      <div className={styles.invoice}>
        <nav className={cname(styles.header, styles.titleText)}>
          {businessType ? (
            <div className={cname(styles.businessTypeBox)}>
              <div className={cname(styles.businessTypeContent)}>
                <p>{invoice.businessTypeDesc}</p>
              </div>
            </div>
          ) : null}
          <span className={cname(styles.text)}>{invoice.invoiceTypeDesc}</span>
          <div className={cname(styles.meta)}>
            <div className={cname(styles.line)}>
              <div className={cname(styles.label)}>
                <span>发</span>
                <span>票</span>
                <span>号</span>
                <span>码</span>
              </div>
              <div className={cname(styles.value)}>{invoice.invoiceNo}</div>
            </div>
            <div className={cname(styles.line)}>
              <div className={cname(styles.label)}>
                <span>开</span>
                <span>票</span>
                <span>日</span>
                <span>期</span>
              </div>
              <div className={cname(styles.value)}>{invoice.invoiceDate}</div>
            </div>
          </div>
        </nav>
        <section className={styles.body}>
          <div className={cname(styles.row, styles.infos)}>
            <div className={cname(styles.group, styles.titleText, styles.cell)}>
              <span>购</span>
              <span>买</span>
              <span>方</span>
              <span>信</span>
              <span>息</span>
            </div>
            <div
              className={cname(styles.dataset, styles.cell)}
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-around",
              }}
            >
              <div className={cname(styles.line)}>
                <div className={cname(styles.label)}>
                  <span>名</span>
                  <span>称</span>
                </div>
                <div className={cname(styles.value)}>{invoice.buyerName}</div>
              </div>
              <div className={cname(styles.line)}>
                <div className={cname(styles.label)}>
                  统一社会信用代码/纳税人识别号
                </div>
                <div className={cname(styles.value)}>
                  {invoice.buyerTaxNo ?? ""}
                </div>
              </div>
            </div>
            <div className={cname(styles.group, styles.titleText, styles.cell)}>
              <span>销</span>
              <span>售</span>
              <span>方</span>
              <span>信</span>
              <span>息</span>
            </div>
            <div
              className={cname(styles.dataset, styles.cell)}
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-around",
              }}
            >
              <div className={cname(styles.line)}>
                <div className={cname(styles.label)}>
                  <span>名</span>
                  <span>称</span>
                </div>
                <div className={cname(styles.value)}>{invoice.sellerName}</div>
              </div>
              <div className={cname(styles.line)}>
                <div className={cname(styles.label)}>
                  统一社会信用代码/纳税人识别号
                </div>
                <div className={cname(styles.value)}>
                  {invoice.sellerTaxNo ?? ""}
                </div>
              </div>
            </div>
          </div>
          <div className={cname(styles.table)}>
            <div
              className={cname(styles.thead)}
              style={{ display: "flex", height: 30 }}
            >
              {renderDetailTitle()}
            </div>
            <Scrollbars
              style={businessType ? { height: 200 } : { height: 300 }}
              autoHide
            >
              {(invoice.lines || []).map((line: JSONObject, index: number) => (
                <div
                  className={cname(styles.tableRow)}
                  style={{ display: "flex", minHeight: 30 }}
                  key={line.id}
                >
                  {renderDetailContent(line)}
                </div>
              ))}
              {renderEmptyRow()}
            </Scrollbars>
            <div
              className={cname(styles.tableRow)}
              style={
                businessType
                  ? { display: "flex", paddingBottom: "10px" }
                  : { display: "flex" }
              }
            >
              <div
                className={cname(styles.titleText, cname(styles.totalTitle))}
                style={{ width: "30.3%" }}
              >
                合计
              </div>
              <div style={{ width: "10.1%" }} />
              <div style={{ width: "9.6%" }} />
              <div style={{ width: "7%" }} />
              <div style={{ width: "13%" }} />
              <div style={{ width: "10%" }} />
              <div style={{ width: "10%" }}>
                ¥
                {`${String(
                  new Decimal(invoice.invoiceAmount).toFixed(
                    2,
                    Decimal.ROUND_HALF_UP
                  )
                )}`}
              </div>
              <div style={{ width: "10%", textAlign: "right" }}>
                ¥
                {`${String(
                  new Decimal(invoice.taxAmountTotal).toFixed(
                    2,
                    Decimal.ROUND_HALF_UP
                  )
                )}`}
              </div>
            </div>
          </div>
          {isFreightTransport ? (
            <FreightTransportView invoice={invoice} />
          ) : null}

          <div
            className={cname(styles.row, styles.totalPriceAndTax)}
            style={{ height: "40px", lineHeight: "40px" }}
          >
            <div
              className={cname(styles.titleText, styles.cell)}
              style={{ textAlign: "center", width: "27%" }}
            >
              价税合计(大写)
            </div>
            <div className={cname(styles.grow, styles.cell, styles.data)}>
              <div className={cname(styles.row)}>
                <div style={{ width: "65%" }}>
                  {MyMath.convertToChinese(
                    Number(
                      new Decimal(invoice.taxAmountTotal)
                        .add(new Decimal(invoice.invoiceAmount))
                        .toFixed(2, Decimal.ROUND_HALF_UP)
                    )
                  )}
                </div>
                <div style={{ width: "35" }}>
                  <span className={cname(styles.titleText)}>（小写）</span>¥
                  {`${String(
                    new Decimal(invoice.taxAmountTotal)
                      .add(new Decimal(invoice.invoiceAmount))
                      .toFixed(2, Decimal.ROUND_HALF_UP)
                  )}`}
                </div>
              </div>
            </div>
          </div>
          <div className={cname(styles.row, styles.infos)}>
            <div className={cname(styles.group, styles.titleText, styles.cell)}>
              <span>备</span>
              <span>注</span>
            </div>
            <div className={cname(styles.dataset1, styles.cell)}>
              {renderRemarkContent()}
              {invoice.remark}
            </div>
          </div>
        </section>
        <div className={cname(styles.footer)}>
          <div className={cname(styles.item)}>
            <div className={cname(styles.label)}>开&nbsp;票&nbsp;人</div>
            <div className={cname(styles.value)}>{invoice.invoicer}</div>
          </div>
        </div>
      </div>
      <div style={{ margin: " 0 32px 32px 0", textAlign: "right" }}>
        <Button onClick={onClose}>关闭</Button>
      </div>
    </>
  );
};

export default InvoicePreview;
