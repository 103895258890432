import cname from "classnames";
import * as React from "react";
import { Scrollbars } from "react-custom-scrollbars";
import styles from "../index.module.scss";

export interface Props extends React.BaseHTMLAttributes<HTMLElement> {
  invoice: JSONObject;
}

const FreightTransportView: React.FC<Props> = ({ invoice }: Props) => {
  const renderEmptyRow = () => {
    const invoiceRowNum = (invoice.lines || []).length;

    if (invoiceRowNum < 10) {
      const emptyRowNum = 10 - invoiceRowNum;
      const emptyRowArr = [];

      for (let i = 0; i < emptyRowNum; i++) {
        emptyRowArr.push(i);
      }

      return emptyRowArr.map((index) => (
        <div
          key={index}
          className={cname(styles.tableRow)}
          style={{
            display: "flex",
            height: 30,
            maxHeight: 30,
          }}
        >
          <div style={{ textAlign: "left", width: "20%" }} />
          <div style={{ textAlign: "right", width: "20%" }} />
          <div style={{ textAlign: "center", width: "20%" }} />
          <div style={{ textAlign: "right", width: "20%" }} />
          <div style={{ textAlign: "right", width: "20%" }} />
        </div>
      ));
    }
  };
  return (
    <div>
      <div className={cname(styles.table)}>
        <div
          className={cname(styles.thead)}
          style={{
            display: "flex",
            height: 30,
            borderTop: "1px solid #972E2E",
            borderBottom: "1px solid #972E2E",
          }}
        >
          <div
            className={cname(styles.titleText)}
            style={{ width: "20%", lineHeight: "30px" }}
          >
            运输工具种类
          </div>
          <div
            className={cname(styles.titleText)}
            style={{ width: "20%", lineHeight: "30px" }}
          >
            运输工具号牌
          </div>
          <div
            className={cname(styles.titleText)}
            style={{ width: "20%", lineHeight: "30px" }}
          >
            起运地
          </div>
          <div
            className={cname(styles.titleText)}
            style={{ width: "20%", lineHeight: "30px" }}
          >
            到达地
          </div>
          <div
            className={cname(styles.titleText)}
            style={{ width: "20%", lineHeight: "30px" }}
          >
            运输货物名称
          </div>
        </div>
        <Scrollbars style={{ height: 200 }} autoHide>
          <div
            className={cname(styles.tableRow)}
            style={{ display: "flex", minHeight: 30 }}
            key={invoice.id}
          >
            <div
              style={{
                textAlign: "center",
                width: "20%",
              }}
            >
              {invoice?.businessInfo?.HWYS_YSGJ}
            </div>
            <div
              style={{
                textAlign: "center",
                width: "20%",
              }}
            >
              {invoice?.businessInfo?.HWYS_YSGJTH}
            </div>
            <div style={{ textAlign: "center", width: "20%" }}>
              {invoice?.businessInfo?.HWYS_QYD}
            </div>
            <div style={{ textAlign: "center", width: "20%" }}>
              {invoice?.businessInfo?.HWYS_DDD}
            </div>
            <div style={{ textAlign: "center", width: "20%" }}>
              {invoice?.businessInfo?.HWYS_YSHWMC}
            </div>
          </div>
          {renderEmptyRow()}
        </Scrollbars>
      </div>
    </div>
  );
};
export default FreightTransportView;
