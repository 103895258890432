import {
  Card as AdCard,
  Cascader,
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Select,
} from "antd";
import * as React from "react";
import { useState } from "react";

interface Props {
  form: any;
}

const FreightTransport: React.FC<Props> = (props) => {
  const { form } = props;
  const [loading, setLoading] = useState(false);
  const cityData = require("./province-city-china.json");
  const [levelData, setLevalData] = useState(cityData);
  const { RangePicker } = DatePicker;
  const Option = Select.Option;

  return (
    <>
      <AdCard
        title="编辑货物运输信息"
        loading={loading}
        style={{ marginBottom: "0" }}
      >
        <Form form={form}>
          <Row gutter={16}>
            <Col span={8}>
              <Form.Item
                label="货物运输起运地"
                // name="HWYS_QYD"
                name={["businessInfo", "HWYS_QYD"]}
                rules={[{ required: true, message: "请输入货物运输起运地" }]}
              >
                <Cascader options={levelData} placeholder="请选择" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="货物运输到达地"
                // name="HWYS_DDD"
                name={["businessInfo", "HWYS_DDD"]}
                rules={[{ required: true, message: "请输入货物运输到达地" }]}
              >
                <Cascader options={levelData} placeholder="请选择" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="货物运输运输工具"
                // name="HWYS_YSGJ"
                name={["businessInfo", "HWYS_YSGJ"]}
                rules={[{ required: true, message: "请输入货物运输运输工具" }]}
              >
                <Select placeholder="请选择货物运输运输工具">
                  <Option value="铁路运输">铁路运输</Option>
                  <Option value="公路运输">公路运输</Option>
                  <Option value="水路运输">水路运输</Option>
                  <Option value="航空运输">航空运输</Option>
                  <Option value="管道运输">管道运输</Option>
                  <Option value="其他运输工具">其他运输工具</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="货物运输运输工具牌号"
                // name="HWYS_YSGJTH"
                name={["businessInfo", "HWYS_YSGJTH"]}
                rules={[
                  {
                    required: true,
                    message: "请输入货物运输运输工具牌号",
                  },
                ]}
              >
                <Input placeholder="请输入货物运输运输工具牌号" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="货物运输货物名称"
                // name="HWYS_YSHWMC"
                name={["businessInfo", "HWYS_YSHWMC"]}
                rules={[{ required: true, message: "请输入货物运输货物名称" }]}
              >
                <Input placeholder="请输入货物运输货物名称" />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </AdCard>
    </>
  );
};

export default FreightTransport;
