import React, { useState, FC, memo } from 'react';
import { Space, Button, Upload, Row, Col, Form, Input } from 'antd';
import { UploadOutlined } from '@ant-design/icons';

import { Card } from '@comps';

import { Http, Context, Message } from '@utils';

const { Item } = Form;

interface Props {
  baseFormId: number,
  refreshTable: () => void,
  onClose: () => void
};

const UploadAnnexMd: FC<Props> = memo(({
  baseFormId,
  refreshTable,
  onClose
}) => {
  const [form] = Form.useForm();
  const [fileList, setFileList] = useState<any[]>([]);

  const [uploadLoading, setUploadLoading] = useState(false);

  const handleSave = () => {
    setUploadLoading(true);

    const formData = new FormData();
    formData.append('id', baseFormId + '');

    const formInfo = form.getFieldsValue();
    const { remark } = formInfo;
    remark && formData.append('remark', remark);

    fileList.forEach(file => {
      formData.append('files', file);
    });

    Http.post('/request/uploadAttachment', formData)
      .then(() => {
        Message.success('新增成功');
        refreshTable();
        onClose();
      })
      .finally(() => {
        setUploadLoading(false);
      });
  };

  return (
    <Card title="上传附件">
      <Form form={form} onFinish={handleSave}>
        <Row style={{ marginTop: 20 }}>
          <Col span={24} style={{ textAlign: 'center' }}>
            <Item>
              <Upload
                accept=".xlsx,.xls"
                listType="picture"
                headers={{
                  Authorization: Context.getToken()
                }}
                multiple
                beforeUpload={(_1, newFileList) => {
                  setFileList([...fileList, ...newFileList]);
                  return false;
                }}
                onRemove={removeFile => {
                  const { name } = removeFile;
                  setFileList(fileList.filter(file => file.name !== name));
                }}
              >
                <Button type="link" icon={<UploadOutlined/>}>选择文件</Button>
              </Upload>
            </Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Item label="备注" name="remark">
              <Input />
            </Item>
          </Col>
        </Row>
        <Row>
          <Col span={24} style={{ textAlign: 'center' }}>
            <Space size="large">
              <Button
                type="primary"
                htmlType="submit"
                loading={uploadLoading}
              >
                确定
              </Button>
              <Button onClick={onClose}>取消</Button>
            </Space>
          </Col>
        </Row>
      </Form>
    </Card >
  );
});

export default UploadAnnexMd;
