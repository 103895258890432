import { RouteProps } from '@models';

import OutputDetail from './output-detail';
import OutputSummary from './output-summary';
import IncomeDetail from './income-detail';
import Trade from './trade';

const reportRoutes: RouteProps[] = [
  {
    path: '/report/output-detail',
    component: OutputDetail,
    private: true,
  },
  {
    path: '/report/output-summary',
    component: OutputSummary,
    private: true,
  },
  {
    path: '/report/income-detail',
    component: IncomeDetail,
    private: true,
  },
  {
    path: '/report/trade',
    component: Trade,
    private: true,
  },
];

export default reportRoutes;
