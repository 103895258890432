import * as React from 'react';
import { Page, Card, Button, Lookup, Modal } from '@comps';
import { Http, Context } from '@utils';
import { message, Form, Space, Alert } from 'antd';
import Input, { InputProps } from 'antd/es/input';
import InputNumber, { InputNumberProps } from 'antd/es/input-number';
import Kv, { Props as KvProps} from '@comps/Kv';
import _ from 'lodash';
import Table, { ColumnsType } from 'antd/es/table';
import useSWR from 'swr';
import EmailInputMd from './emailInput.md';

interface CptAmountParam {
  invoiceAmount?: number,
  taxRate?: number,
  price?: number,
  quantity?: number,
}

interface TaxNoTaxSwitch {
  invoiceAmt: number,
  price: number,
  quantity: number,
  taxAmt: number,
  withTaxAmt: number,
  withTaxPrice: number,
  withoutTaxAmt: number,
  withoutTaxPrice: number,
}

type WithIndexOnChange = (index: number, good: JSONObject) => void;

interface ChangeWithIndex {
  onChange?: WithIndexOnChange;
}

const Smkp: React.FC = () => {
  const [goods, setGoods] = React.useState<JSONObject[]>([]);
  const {
    data: defaultGoods = {},
  } = useSWR('/tax/outputInvoices/defaultGoods',
    () => {
      return Http.get('/tax/outputInvoices/defaultGoods')
    });
  const goodsWithIndex = React.useMemo(() => goods
    .map((good, index) => ({ ...good, index })), [goods])
  const [invoiceType, setInvoiceType] = React.useState<string>('51');
  const [invoiceTypeError, setInvoiceTypeError] = React.useState<boolean>(false);
  const [model, modalHolder] = Modal.useModal();
  function addDefaultGoods(defaultGoods: JSONObject) {
    setGoods([...goods, {
      goodsId: defaultGoods.goodsId,
      taxRate: defaultGoods.taxRate,
      price: defaultGoods.unitPrice,
      unit: defaultGoods.unit,
      spec: defaultGoods.spec,
    }]);
  }

  function resetToDefaultGoods() {
    setGoods([{
      goodsId: defaultGoods.goodsId,
      taxRate: defaultGoods.taxRate,
      price: defaultGoods.unitPrice,
      unit: defaultGoods.unit,
      spec: defaultGoods.spec,
    }]);
  }

  React.useEffect(() => {
    if (!goods.length && defaultGoods.goodsId) {
      addDefaultGoods(defaultGoods);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultGoods]);

  const inputRender = (prop: string,
                       props: Omit<InputProps, 'onChange'> & ChangeWithIndex = {}) => ((value: any,
                                                                                        _1: any, index: number) => {
    return (<Input {...props} value={value} onChange={value => {
      const good = goods[index];
      good[prop] = value.target.value;
      props?.onChange?.(index, good);
      setGoods([...goods]);
    }} />);
  });
  const inputNumberRender = (prop: string,
                             props: Omit<InputNumberProps, 'onChange'> & ChangeWithIndex = {}) => ((value: any,
                                                                                                    _1: any, index: number) => {
    return (<InputNumber {...props} value={value}
                         style={{ width: '100%' }}
                         onChange={value => {
                           const good = goods[index];
                           good[prop] = value;
                           props?.onChange?.(index, good);
                           setGoods([...goods]);
                         }} />);
  });
  const kvRender = (prop: string,
                    props: Omit<KvProps, 'onChange'> & ChangeWithIndex) => ((value: any,
                                                                             _1: any, index: number) => {
    return (<Kv {...props} value={value}
                style={{ width: '100%' }}
                onChange={(value) => {
                  const good = goods[index];
                  good[prop] = value;
                  props?.onChange?.(index, good);
                  setGoods([...goods]);
                }} />);
  });

  const refGoods = React.useRef({ goods });
  refGoods.current.goods = goods;

  const _cptAmount = React.useCallback<WithIndexOnChange>(async (index: number, {
    invoiceAmount, taxRate, price, quantity,
  }: CptAmountParam) => {
    function resetValue (number: number | undefined): any {
      const num = Number.parseFloat(String(number));
      if (!_.isNumber(num)) {
        return '';
      }
      if (_.isNaN(num)) {
        return '';
      }
      return num;
    }
    const taxNoTaxSwitch =  await Http
      .post<TaxNoTaxSwitch>('/tax/outputInvoices/taxNoTaxSwitch', {
        hasTax: true,
        invoiceAmt: resetValue(invoiceAmount),
        price: resetValue(price),
        quantity: resetValue(quantity),
        taxRate: resetValue(taxRate),
      });
    const currentGoods = refGoods.current.goods;
    currentGoods[index] = {
      ...currentGoods[index],
      invoiceAmount: taxNoTaxSwitch.invoiceAmt,
      price: taxNoTaxSwitch.price,
      quantity: taxNoTaxSwitch.quantity,
      taxAmt: taxNoTaxSwitch.taxAmt,
      withoutTaxAmt: taxNoTaxSwitch.withoutTaxAmt,
    };
    console.log(currentGoods);
    setGoods([...currentGoods]);
  }, []);

  const cptAmount = React.useMemo<WithIndexOnChange>(() => {
    return _.debounce(_cptAmount, 500, { leading: false, trailing: true });
  }, [_cptAmount]);

  const columns: ColumnsType<any> = [
    { dataIndex: 'goodsId', title: '商品名称', width: 150,
      render: kvRender('goodsId', { url: '/tax/goods/kv' }) },
    { dataIndex: 'invoiceAmount', title: '开票金额(含税)', width: 150,
      render: inputNumberRender('invoiceAmount', { onChange: cptAmount }) },
    { dataIndex: 'taxRate', title: '税率(%)', width: 120,
      render: kvRender('taxRate', { url: '/tax/rates/kv', onChange: cptAmount }) },
    { dataIndex: 'price', title: '单价(含税)', width: 150,
      render: inputNumberRender('price', { min: 0, onChange: cptAmount }) },
    { dataIndex: 'quantity', title: '数量', width: 80,
      render: inputNumberRender('quantity', { onChange: cptAmount }) },
    { dataIndex: 'unit', title: '单位', width: 80,
      render: inputRender('unit') },
    { dataIndex: 'spec', title: '规格型号', width: 150, render: inputRender('spec') },
    { dataIndex: 'taxAmt', title: '税额', width: 150, },
    { dataIndex: 'withoutTaxAmt', title: '金额(不含税)', width: 150 },
  ];

  async function handleCreateQrCode() {
    if (invoiceType === '') {
      message.error('请先选择发票类型');
      setInvoiceTypeError(true);
      return;
    }
    /*if (invoiceType !== '51' && invoiceType!== '08' && invoiceType!== '31' && invoiceType!== '32' ) {
      const invoiceStock = await Http.get<{
        orgName: string,
        nextInvoiceCode: string,
        nextInvoiceNo: string,
        invoiceStock: number,
      }>('/tax/invoicer/stock', { invoiceType });
      const labelCol = { span: 11 };
      try {
        await model.open({
          width: '450px',
          ignoreError: false,
          content(resolve, reject) {
            return(<Card title="确认发票信息" style={{ marginBottom: '0' }}>
              <Form.Item style={{marginTop: '24px'}}
                         labelAlign="right"
                         labelCol={labelCol}
                         label="销方名称"> {invoiceStock.orgName} </Form.Item>
              <Form.Item labelAlign="right"
                         labelCol={labelCol}
                         label="下一张发票代码"> {invoiceStock.nextInvoiceCode} </Form.Item>
              <Form.Item labelAlign="right"
                         labelCol={labelCol}
                         label="下一张发票号码"> {invoiceStock.nextInvoiceNo} </Form.Item>
              <Form.Item labelAlign="right"
                         labelCol={labelCol}
                         label="剩余张数"> {invoiceStock.invoiceStock} </Form.Item>
              <div style={{ textAlign: 'center' }}>
                <Space>
                  <Button type="primary" onClick={() => resolve()}>确认开票</Button>
                  <Button onClick={() => reject()}>取消</Button>
                </Space>
              </div>
            </Card>);
          },
        });
      } catch (error) {
        return;
      }
    }*/
    const QRCodeInfo = await Http.post<JSONObject>('/tax/outputInvoices/dynamicQrCode', {
      invoiceType,
      lines: goods,
    });
    await model.open({
      width: '780px',
      content(resolve) {
        return(<Card title="创建成功" style={{ marginBottom: '0' }}>
          <Alert
            message="提示"
            description={(<Space direction="vertical">
              <div>
                1.您可将二维码提供给客户，客户通过微信或支付宝扫一扫提交发票抬头信息，提交后将自动开票。
              </div>
              <div>
                2.若开具了电子发票，客户可保存至微信或支付宝卡包。开具成功后系统将自动发送至客户邮箱。
              </div>
            </Space>)}
            type="success"
          />
          <div style={{ padding: '36px 0', textAlign: 'center' }}>
            <img src={QRCodeInfo.url}
                 style={{ width: '160px', height: '160px' }}
                 alt="二维码" />
          </div>
          <div style={{ textAlign: 'center' }}>
            <Space>
              <Button danger onClick={() => {
                let { url } = QRCodeInfo;
                url = `${url}${(url as string).includes('?') ? '&' : '?'}download=true`
                window.open(url);
              }}>下载二维码</Button>
              <Button danger onClick={async () => {
                try {
                  await model.open({
                    width: '780px',
                    ignoreError: false,
                    content(resolve, reject) {
                      return (
                        <EmailInputMd onConfirm={resolve} onCancel={reject}
                                      requestNo={QRCodeInfo.requestNo} />)
                    },
                  });
                } catch (error) {
                  return;
                }
                message.success('发送成功');
              }}>发送二维码到邮箱</Button>
              <Button danger onClick={() => {
                resetToDefaultGoods();
                resolve();
              }}>继续开票</Button>
              <Button danger onClick={() => {
                Context.route(`/tax/outputInvoice/wait/${QRCodeInfo.requestNo}/detail`);
                resolve();
              }}>查看详情</Button>
            </Space>
          </div>
        </Card>);
      },
    });
  }

  return (
    <Page >
      {modalHolder}
      <Card title='扫码开票'
            actions={<Button type='primary' onClick={() => {
              addDefaultGoods(defaultGoods);
            }}>新增发票行</Button>}>
        <div style={{ marginBottom: '24px' }}>
          <Form.Item validateStatus={ invoiceTypeError ? "error" : ''}>
            <Lookup lookupType='TAX_INVOICE_TYPE'
                    style={{ width: '200px' }}
                    value={invoiceType}
                    onChange={(value) => {
                      setInvoiceType(value);
                      setInvoiceTypeError(false);
                    }}
                    placeholder="请选择发票类型" />
          </Form.Item>
        </div>
        <Table dataSource={goodsWithIndex}
               pagination={false}
               rowKey="index"
               columns={columns}
               scroll={{
                 x: _.sumBy(columns, column => (Number.parseFloat(String(column.width ?? 100)))),
               }}/>
        <div style={{ paddingTop: '20px', textAlign: 'center' }}>
          <Button danger onClick={handleCreateQrCode}>创建开票申请</Button>
        </div>
      </Card>
    </Page >
  );
};

export default Smkp;
