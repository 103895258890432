import React, { useState } from 'react';

import { Card } from '@comps'
import { Form, Space, DatePicker, Button } from 'antd'

import { Http, Message } from '@utils'
import { SignStatus } from './interface'
import moment from 'moment'

export interface Props {
  type: string,
  title: string,
  statisticsData: JSONObject,
  queryData: JSONObject,
  refreshTable: () => void,
  onClose: () => void
}

const Item = Form.Item

const Secondary: React.FC<Props> = ({ type, title, statisticsData, queryData, refreshTable, onClose }: Props) => {
  const [form] = Form.useForm()

  const [loading, setLoading] = useState<boolean>(false)

  const fontColorStyle = { color: '#3C95F4' }

  const handleSecondary = async () => {
    setLoading(true)

    if (type === SignStatus.MARK_DEDUCTION) {
      const { deductionPeriod } = form.getFieldsValue()
      const periodStr = moment(deductionPeriod).format('YYYYMM')
      const periodNum = parseInt(periodStr)
      queryData.deductionPeriod = periodNum
    }

    Http.get('/input/biz/makeDeduction/deductionAll', queryData)
      .then(res => {
        Message.info('操作完成')
        refreshTable()
      })
      .finally(() => {
        setLoading(false)
        onClose()
      })
  }

  return (
    <Card title={title}>
      <Form form={form} onFinish={handleSecondary}>
        <Item>
          <p style={{ fontSize: 14, marginTop: 16 }}>
            本次勾选<span style={fontColorStyle}> {statisticsData.nums} </span> 张，
            可抵扣金额<span style={fontColorStyle}> {statisticsData.sumAmount} </span>元，
            可抵扣税额<span style={fontColorStyle}> {statisticsData.sumTaxAmount} </span>元</p>
        </Item>
        {
          type === SignStatus.MARK_DEDUCTION ?
          <Item name='deductionPeriod' label='标记所属期' rules={[{ required: true }]}>
            <DatePicker picker="month" inputReadOnly/>
          </Item> : null
        }
        <Item>
          <p style={{ fontSize: 14, marginTop: 10 }}>是否确认进行{title} ?</p>
        </Item>
        <Item>
          <Space size="large">
            <Button loading={loading} type="primary" htmlType="submit">确定</Button>
            <Button onClick={onClose}>取消</Button>
          </Space>
        </Item>
      </Form>
    </Card >)
}

export default Secondary
