import React, { FC, useState, useEffect} from 'react';
import { Space, Tag, Form, Input, Select } from 'antd';

import { Page, Card, Modal, Table, Button, LinkText, Lookup } from '@comps';
import AddEditMd from './modal/add-edit';

import { Message, Http } from '@utils';
import { ColumnProps } from '@comps/types';

const { Item } = Form;

const Remind: FC = () => {

  let [table, setTable] = useState<Table>();
  const onTableRef = (tableRef: Table) => {
    setTable(tableRef);
    table = tableRef;
  };

  const [remindPoints, setRemindPoints] = useState<JSONObject[]>([]);

  const [modal, modalHoder] = Modal.useModal();

  useEffect(() => {
    getRemindPoints();
  }, []);

  const getRemindPoints = () => {
    Http.get('/requestTip/queryTipPoint')
      .then(data => {
        setRemindPoints(data);
      })
  };

  const handleDelete = (
    ids: number[],
    isBatch: boolean = false
  ) => {

    if (isBatch) {
      ids = table?.getSelectedRowKeys() || [];

      if (ids?.length === 0) {
        Message.warn('请至少勾选一条数据');
        return;
      };
    }

    Http.del('/requestTip', { ids })
      .then(() => {
        table?.refresh();
        Message.success('删除成功');
      })
  };

  const refreshTable = () => {
    table?.refresh();
  };

  const handleEnableOrDisable = (row: JSONObject) => {
    const isEnable: boolean = row.enable;
    row.enable = !isEnable;

    Http.post('/requestTip/update', row)
      .then(() => {
        table?.refresh();
        Message.success(isEnable ? '禁用成功' : '启用成功');
      })
  };

  const handleAddOrEdit = (
    rowObj: JSONObject,
    isEdit: boolean
  ) => {

    modal.open({
      width: 600,
      content(_1, reject) {
        return (
          <AddEditMd
            isEdit={isEdit}
            rowData={rowObj}
            refreshTable={refreshTable}
            onClose={reject}
          />
        )
      }
    });
  };

  const sceneColumns: ColumnProps[] = [
    { key: '', title: '序号', width: 80,
      render: (_1, _2, index) => (index + 1)
    },
    { key: 'sbuCode', title: 'SBU 代码', width: 130 },
    { key: 'requestTypeDesc', title: '开票申请类型', width: 130 },
    { key: 'tipCode', title: '场景代码', width: 130 },
    { key: 'enable', title: '状态', width: 130,
      render: (isEnable: boolean) =>
        isEnable
          ? <Tag color="green">启用</Tag>
          : <Tag color="red">禁用</Tag>
    },
    { key: 'id', title: '操作', width: 160, align: 'center', fixed:'right',
      render: (id: number, row: JSONObject) => (
        <Space size="large">
          <LinkText
            label="修改"
            onClick={() => handleAddOrEdit(row, true)}
          />
          <LinkText
            label={row.enable ? '禁用' : '启用'}
            onClick={() => handleEnableOrDisable(row)}
          />
          <LinkText
            label="删除"
            onClick={() => handleDelete([id])}
          />
        </Space>
      )
    }
  ];

  const remindContentColumns: ColumnProps[] = [
    { key: 'tipCode', title: '提醒点编码', width: 130 },
    { key: 'controlNode', title: '控制节点', width: 130 },
    { key: 'strongControl', title: '是否强控', width: 100,
      render: (isStrongControl: boolean) => isStrongControl ? '是' : '否'
    },
    { key: 'tipContent', title: '提醒内容', width: 280 }
  ];

  return (
    <Page>
      {modalHoder}
      <Card title="业务场景提醒" actions={(
        <Space>
          <Button
            type="primary"
            onClick={() => {handleAddOrEdit({}, false)}}
          >
            新增
          </Button>
          <Button
            type="primary"
            onClick={() => handleDelete([], true)}
          >
            批量删除
          </Button>
        </Space>
      )}>
        <Table
          url="/requestTip"
          onTableRef={onTableRef}
          columns={sceneColumns}
          selectedRowKeys={[]}
          scroll={{ y: window.innerHeight - 300 }}
        >
          <Item name="sbuCode">
            <Input placeholder="SBU 代码" />
          </Item>
          <Item name="requestType">
            <Lookup placeholder="开票申请类型" lookupType="OUTPUT_REQUEST_TYPE" />
          </Item>
          <Item name="tipCode">
            <Select
              placeholder="业务场景提醒点"
              options={remindPoints.map(
                (item) => ({ label: item.tipCode, value: item.tipCode })
              )}
            />
          </Item>
          <Item />
        </Table>
      </Card>
      <Card title="提醒内容">
        <Table
          url="/requestTip/queryTipPoint"
          columns={remindContentColumns}
          pagination={false}
        />
      </Card>
    </Page>
  );
};

export default Remind;
