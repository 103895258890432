import React, { FC, useState, useEffect } from 'react';
import { Row, Col, Tree, Input } from 'antd';

import { Card, Button } from '@comps';
import { Http, Message, Context } from '@utils';

export interface Props {
  onCancel: () => void;
  onConfirm: () => void;
}

const { Search } = Input;

const DeptTree: FC<Props> = ({
  onCancel, 
  onConfirm,
}) => {

  const [treeData, setTreeData] = useState<any[]>([]);
  const [selectedDeptId, setSelectedDeptId] = useState(-1);
  const [loading, setLoading] = useState(false);

  const [searchValue, setSearchValue] = useState('');
  const [expandedKeys, setExpandedKeys] = useState<any[]>([]);
  const [autoExpandParent, setAutoExpandParent] = useState(true);

  useEffect(() => {
    getTreeData();
  }, [])

  const getTreeData = () => {
    Http.get('/hr/depts/deptTreeForSwitchDept/antd')
      .then(childrens => {
        setTreeData(childrens);

        const initExpandeKey: any = childrens[0].key; 
        setExpandedKeys([initExpandeKey]);
      })
  };

  const handleSave = async () => {
    if (selectedDeptId < 0) {
      Message.warn('请选择要切换的部门');
    } else {
      onConfirm();
      Message.success("切换成功");

      await Http.get(`/sys/users/switchDept?deptId=${selectedDeptId}`);
      await Context.initUserContext();

      // 清除路由缓存
      window.location.reload();
    }
  };

  const onSelect = (selectedKeys: any[], info: any) => {
    const selected = info.selected;

    if (selected) {
      const node = info.node;
      const deptId = node.key;
      setSelectedDeptId(deptId);
    } else {
      setSelectedDeptId(-1);
    }
  };

  // tree 树形匹配方法
  const getParentKey = ( key: number | string, tree: any): any => {
    let parentKey;

    for (let i = 0; i < tree.length; i++) {
      const node = tree[i];

      if (node.children) {
        if (node.children.some((item: any) => item.key === key)) {
          parentKey = node.key;
        } else if (getParentKey(key, node.children)) {
          parentKey = getParentKey(key, node.children);
        }
      }
    }

    return parentKey;
  };

  // 将树形节点改为一维数组
  const generateList = (data: any, dataList: any[]) => {
    for (let i = 0; i < data.length; i++) {
      const node = data[i];
      const { key, title } = node;

      dataList.push({ key, title, });

      if (node.children) {
        generateList(node.children, dataList);
      }
    }

    return dataList;
  };

  // 搜索节点
  const onChange = (e: any) => {
    let { value } = e.target;
    value = String(value).trim();
    const dataList: any[] = generateList(treeData, []);

    let expandedKeys: any = dataList
      .map((item: any) => {
        if (item.title.indexOf(value) > -1) {
          return getParentKey(item.key, treeData)
        }

        return null;
      })
      .filter((item: any, i: number, self: any) => item && self.indexOf(item) === i);
  
    setExpandedKeys(expandedKeys);
    setAutoExpandParent(true);
    setSearchValue(value);
  };

  const loop = (data: any) => {
    return data.map((item: any) => {
      const index = item.title.indexOf(searchValue);
      const beforeStr = item.title.substr(0, index);
      const afterStr = item.title.substr(index + searchValue.length);

      const title =
        index > -1 ? (
          <span>
            {beforeStr}
            <span className="site-tree-search-value">{searchValue}</span>
            {afterStr}
          </span>
        ) : (
          <span>{item.title}</span>
        );

      if (item.children && item.children.length > 0) {
        const children = loop(item.children);
        return {
          ...item,
          title: index > -1 || children.length ? title : null,
          children: children.length ? children : undefined
        };
      }

      return index > -1
        ? {
            ...item,
            title
          }
        : {
            ...item,
            title: null
          };
    }).filter((item: any) => !!item.title);
  };

  // 树节点展开/收缩
  const onExpand = (expandedKeys: any) => {
    setExpandedKeys(expandedKeys);
    setAutoExpandParent(false);
  };

  return (
    <Card 
      title="切换部门" 
      loading={loading}
    >
      <Row>
        <Search 
          style={{ marginBottom: 8 }} 
          placeholder="搜索" 
          onChange={onChange} 
        />
        {treeData && (
          <Tree
            treeData={loop(treeData)}
            showLine
            defaultExpandAll
            height={600}
            style={{ width: 476 }}
            onSelect={onSelect}
            expandedKeys={expandedKeys}
            autoExpandParent={autoExpandParent}
            onExpand={onExpand}
          />
        )}
      </Row>
      <Row>
        <Col offset={10} span={10}>
          <Button 
            type="primary" 
            clickCallback={handleSave}
          >
            确认切换
          </Button>
        </Col>
      </Row>
    </Card>
  );
};

export default DeptTree;
