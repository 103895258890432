export interface Transfer {
  id: number,
  allowPreview: boolean,
  signFileUrl: string,
  invoiceCode: string,
  invoiceNo: string,
  invoiceDate: string,
  orgName: string,
  buyerName: string,
  invoicerName: string,
  financialOfficer: string,
  clerkName: string,
  invoiceTypeDesc: string,
  withTaxAmount: string,
}

export enum RecipientType {
  /**
   * 客户
   */
  CLIENT = 'KH',
  /**
   * 大区财务
   */
  REGIONAL_FINANCE = 'DQCW',
  /**
   * 业务员
   */
  SALESMAN = 'YWY',
}
