import React, { FC, useState } from 'react';
import { Space, Tag, Form, Input } from 'antd';

import { Page, Card, Modal, Table, Button, LinkText } from '@comps';
import AddOrEditMd from './modal/AddOrEdit';

import { Message, Http } from '@utils';
import { ColumnProps } from '@comps/types';

const { Item } = Form;

const MainAssistant: FC = () => { 

  const [modal, modalHoder] = Modal.useModal();

  let [table, setTable] = useState<Table>();
  const onTableRef = (tableRef: Table) => {
    setTable(tableRef);
    table = tableRef;
  };

  const handleDelete = (
    ids: number[], 
    isBatch: boolean = false
  ) => {

    if (isBatch) {
      ids = table?.getSelectedRowKeys() || [];

      if (ids?.length === 0) {
        Message.warn('请至少勾选一条数据');
        return;
      };
    }

    Http.del(`/mainAuxiliaryUnit/${ids[0]}`)
      .then(() => {
        table?.refresh();
        Message.success('删除成功');
      })
  };

  const refreshTable = () => {
    table?.refresh();
  };

  const handleEnableOrDisable = (row: JSONObject) => {
    const isEnable: boolean = row.enabled;
    row.status = !isEnable;

    Http.get('/mainAuxiliaryUnit/editStatus', row)
      .then(() => {
        table?.refresh();
        Message.success(isEnable ? '禁用成功' : '启用成功');
      })
  };

  const handleAddOrEdit = (
    rowData: JSONObject, 
    isEdit: boolean
  ) => {
  
    modal.open({
      width: 600,
      content(_1, reject) {
        return (
          <AddOrEditMd  
            isEdit={isEdit} 
            rowData={rowData}
            refreshTable={refreshTable}
            onClose={reject} 
          />
        )
      }
    });
  };

  const columns: ColumnProps[] = [
    { key: '', title: '序号', width: 80, fixed: 'left',
      render: (_1, _2, index) => (index + 1)
    },
    { key: 'sbuCode', title: 'SBU 代码', width: 220 },
    { key: 'customerCode', title: '客户编码', width: 160 },
    { key: 'customerName', title: '客户名称', width: 220 },
    { key: 'bigCategory', title: '大类', width: 130 },
    { key: 'middleCategory', title: '中类', width: 130 },
    { key: 'smallCategory', title: '小类', width: 130 },
    { key: 'enabled', title: '启用辅单位开票', width: 120, align: 'center', fixed:'right', 
      render: (enabled: boolean) => 
        enabled 
          ? <Tag color="green">是</Tag>
          : <Tag color="red">否</Tag>
    },
    { key: 'id', title: '操作', width: 160, align: 'center', fixed:'right', 
      render: (id: number, row: JSONObject) => (
        <Space size="large">
          <LinkText 
            label="修改" 
            onClick={() => handleAddOrEdit(row, true)} 
          />
          <LinkText 
            label={row.enabled ? '禁用' : '启用'}
            onClick={() => handleEnableOrDisable(row)}
          />
          <LinkText 
            label="删除" 
            onClick={() => handleDelete([id])} 
          />
        </Space>
      )
    }
  ];

  return (
    <Page>
      {modalHoder}
      <Card title="主辅单位配置" actions={(
        <Space>
          <Button 
            type="primary" 
            onClick={() => {handleAddOrEdit({}, false)}}
          >
            新增
          </Button>
        </Space>
      )}>
        <Table 
          url="/mainAuxiliaryUnit"
          onTableRef={onTableRef} 
          columns={columns}
          selectedRowKeys={[]}
          scroll={{ y: window.innerHeight - 300 }}
        >
          <Item name="sbuCode">
            <Input placeholder="SBU 代码" />
          </Item>
        </Table>
      </Card>
    </Page>
  );
};

export default MainAssistant;