import { Card } from "@comps";
import { Http } from "@utils";
import { message } from "antd";
import * as React from "react";
import { useEffect } from "react";

export interface Props {
  onCancel: () => void;
  base64: JSONObject;
}

const QrCodeModal: React.FC<Props> = ({ onCancel, base64 }) => {
  let intervalId: any = null;

  const startInterVal = () => {
    clearTimeout(intervalId);
    intervalId = setTimeout(() => {
      Http.get(`/tax/orders/auth-result/${base64.authId}`).then((res) => {
        if (res.status == 2) {
          message.success("认证成功！");
          onCancel();
        } else {
          startInterVal();
        }
      });
    }, 3000);
  };

  useEffect(() => {
    let timer: any = null;
    if (base64.authId) {
      timer = setTimeout(() => {
        Http.get(`/tax/orders/auth-result/${base64.authId}`).then((res) => {
          if (res.status == 2) {
            message.success("认证成功！");
            onCancel();
          } else {
            startInterVal();
          }
        });
      }, 25000);
    }
    return () => {
      timer && clearTimeout(timer);
      intervalId && clearTimeout(intervalId);
    };
  }, []);

  return (
    <Card style={{ display: "flex", justifyContent: "center" }}>
      <p style={{ color: "#f00", margin: "16px" }}>
        请使用 税务APP 扫描二维码进行扫脸认证
      </p>
      <img
        style={{ width: "100%" }}
        src={"data:image/jpg;base64," + base64.qrCode}
      ></img>
    </Card>
  );
};

export default QrCodeModal;
