import  React, { FC, useState, useEffect, useRef, useImperativeHandle, forwardRef } from 'react';
import { Form, Row, Col, Input, Button, AutoComplete, Switch } from 'antd';

import { Card, DisplayInput } from '@comps';

import { Http, Message, Context } from '@utils';
import { ApplyType, ApplyStatus, ApplyTypeVal } from '../../interface';

const { Item } = Form;

interface Props {
  ref: any;
  applyType: string;
  applyStatus: string;
  isCreate: boolean;
  formData: JSONObject;
}

const ClientForm: FC<Props> = forwardRef(({
  formData,
  applyType,
  applyStatus,
  isCreate,
}, ref) => {

  const [form] = Form.useForm();
  const formLayout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 20 }
  };

  const isCustomerCodeAndNameReadonly =
    (applyType === ApplyType.ADVANCE) ||
    (applyType === ApplyType.PROGRESS) ||
    (applyType === ApplyType.SETTLE);

  const [isBankAccountRequired, setIsBankAccountRequired] = useState(false);
  const [isAddrTelRequired, setIsAddrTelRequired] = useState(false);
  const [isEmailRequired, setIsEmailRequired] = useState(false);
  const [isCustomerCodeRequired, setIsCustomerCodeRequired] = useState(true);
  const [isCustomerNameRequired, setIsCustomerNameRequired] = useState(false);
  const [isTaxNoRequired, setIsTaxNoRequired] = useState(true);

  const [customerInfo, setCustomerInfo] = useState<JSONObject[]>([]);
  const [contactsList, setContactsList] = useState<JSONObject[]>([]);
  const [bankAccountList, setBankAccountList] = useState<JSONObject[]>([]);

  const [saveLoading, setSaveLoading] = useState(false);
  const [isShowPersonalBTN, setIsShowPersonalBTN] = useState(false);
  const [isShowSaveClientBTN, setIsShowSaveClientBTN] = useState(true);

  const applyStatusRef = useRef('');
  const [isDisabled, setIsDisabled] = useState(false);
  const [isNew, setIsNew] = useState(false);
  const [isEditComp, setIsEditComp] = useState(false);

  let tempBankAccount = '';
  let tempContacts = '';

  useEffect(() => {
    getCustomerInfo();
    setFormFieldsVal();
  }, []);

  useEffect(() => {
    applyStatusRef.current = applyStatus;
    handleItemIsEditable();
    judgeIdentity();
  }, [applyStatus]);

  const judgeIdentity = () => {
    // if(Context.getContext().roleCode === 'CW' ) {
    //   setIsDisabled(false);
    // }
    if(applyStatus === 'INPROGRESS') {
      setIsDisabled(false);
      setIsNew(true);
    }
    if(applyStatus === 'APPROVED') {
      setIsDisabled(true);
      setIsNew(true);
    }
    if(applyStatus === 'REJECTED') {
      setIsDisabled(false);
    }
    if(applyStatus === 'NEW') {
      if(applyType === 'advance' || applyType === 'progress' || applyType === 'settle') {
        setIsEditComp(false);
      }
    }
    if(applyStatus === 'WITHDRAW') {
      if(applyType === 'advance' || applyType === 'progress' || applyType === 'settle') {
        setIsEditComp(false);
      }
    }
    if(applyStatus === 'REJECTED') {
      if(applyType === 'advance' || applyType === 'progress' || applyType === 'settle') {
        setIsEditComp(false);
      }
    }
    if(applyType === 'retail') {
      if(applyStatus === 'REJECTED' || applyStatus === 'NEW' || applyStatus === 'WITHDRAW') {
        setIsEditComp(true);
      }
    }
    if(applyType === 'main-assistant') {
      if(applyStatus === 'NEW' || applyStatus === 'WITHDRAW' || applyStatus === 'REJECTED') {
        setIsEditComp(true);
      }
    }
  }

  useImperativeHandle(ref, () => ({
    getClientFormData,
    setClientFormData,
    validateClientFormFields,
    handleGeneralInvRequiredItem,
    handleSpecialInvRequiredItem,
    handleEleGeneralInvRequiredItem,
    handleEleSpecialInvRequiredItem,
    handleGeneralTypeRequiredItem,
    handleOtherTypeRequiredItem,
    setIsShowPersonalBTN,
    setIsShowSaveClientBTN
  }));

  const handleItemIsEditable = () => {
    switch (applyStatusRef.current) {
      case ApplyStatus.INPROGRESS:
      case ApplyStatus.APPROVED:
      case ApplyStatus.REJECTED:
      case ApplyStatus.CANCEL:
        setIsDisabled(true);
        break;

      default:
        setIsDisabled(false);
        break;
    }
  };

  const setFormFieldsVal = () => {
    !isCreate && form.setFieldsValue(formData);
  };

  const getClientFormData = () => {
    return form.getFieldsValue();
  }

  const setClientFormData = (formData: JSONObject) => {
    form.setFieldsValue(formData);
  }

  const getCustomerInfo = () => {
    Http.get('/customer/all')
      .then(data => {
        setCustomerInfo(data);
      })
  }

  const getContactsOptions = () => {
    return contactsList.map(contacts => ({
      label: contacts.fullName,
      value: contacts.fullName
    }))
  }

  const getContactsList = (customerId: number) => {
    Http.get(`/customer/${customerId}/contacts`)
      .then((data: JSONObject[]) => {
        setContactsList(data);

        data.length === 0
          ? form.setFieldsValue({
              customerContacts: '',
              customerEmail: ''
            })
          : form.setFieldsValue({
              customerContacts: data[0].fullName,
              customerEmail: data[0].email
            });
      })
  }

  const getBankAccountOptions = () => {
    return bankAccountList.map(backAccount => ({
      label: backAccount.bankAccount,
      value: backAccount.bankAccount
    }));
  }

  const handleBankAccountChange = (customerBankAccount: any) => {
    const currentBankAccountInfo = bankAccountList.find(
      bankAccount => bankAccount.addrTel === customerBankAccount
    ) ?? {};

    form.setFieldsValue({ customerAddrTel: currentBankAccountInfo.addrTel });
  }

  const getBankAccountList = (customerId: number) => {
    Http.get(`/customer/${customerId}/invoiceTitles`)
      .then(data => {
        setBankAccountList(data);

        data.length === 0
          ? form.setFieldsValue({
              customerBankAccount: '',
              customerAddrTel: ''
            })
          : form.setFieldsValue({
              customerBankAccount: data[0].bankAccount,
              customerAddrTel: data[0].addrTel
            })
      })
  }

  const handleCustomerCodeSelect = (value: any) => {
    const currentSelectedCustomerInfo = customerInfo.find(
      (customer: JSONObject) => customer.customerCode === value
    ) ?? {};

    const {
      id,
      taxNo,
      shortName
    } = currentSelectedCustomerInfo;

    currentSelectedCustomerInfo.customerTaxNo = taxNo;
    currentSelectedCustomerInfo.bigCustomerName = shortName;

    form.setFieldsValue(currentSelectedCustomerInfo);

    getContactsList(id);
    getBankAccountList(id);
  };

  const handleContractsChange = (customerContacts: any) => {
    const currentContactsInfo = contactsList.find(
      contacts => contacts.fullName === customerContacts
    ) ?? {};

    form.setFieldsValue({ customerEmail: currentContactsInfo.email });
  }

  const setCustomerCodeOptions = () => {
    return customerInfo.map((customer) => {
      return {
        key: customer.id,
        label: `${customer.customerCode} ${customer.customerName}`,
        value: customer.customerCode
      }
    });
  }

  const handleSpecialInvRequiredItem = () => {
    setIsBankAccountRequired(true);
    setIsAddrTelRequired(true);
    setIsEmailRequired(false);
  }

  const handleGeneralInvRequiredItem = () => {
    setIsBankAccountRequired(false);
    setIsAddrTelRequired(false);
    setIsEmailRequired(false);
  }

  const handleEleGeneralInvRequiredItem = () => {
    setIsBankAccountRequired(false);
    setIsAddrTelRequired(false);
    setIsEmailRequired(true);
  }

  const handleEleSpecialInvRequiredItem = () => {
    setIsBankAccountRequired(true);
    setIsAddrTelRequired(true);
    setIsEmailRequired(true);
  }

  const handleGeneralTypeRequiredItem = () => {
   setIsCustomerCodeRequired(true);
   setIsCustomerNameRequired(false);
  }

  const handleOtherTypeRequiredItem = () => {
   setIsCustomerCodeRequired(false);
   setIsCustomerNameRequired(true);
  }

  const validateClientFormFields = () => {
    return form.validateFields();
  }

  const handleSaveClientInfo = () => {
    setSaveLoading(true);

    Http.post('/request/saveCustomer', form.getFieldsValue())
      .then(() => {
        Message.success('保存成功');
      })
      .finally(() => {
        setSaveLoading(false);
      })
  }

  const handlePersonalBTNChange = (isChecked: boolean) => {
    isChecked
      ? setIsTaxNoRequired(false)
      : setIsTaxNoRequired(true)
  }

  const handleBackAccountFocus = () => {
    tempBankAccount = form.getFieldValue('customerBankAccount');
    form.setFieldsValue({ customerBankAccount: '' });
  }

  const handleBackAccountBlur = () => {
    if (form.getFieldValue('customerBankAccount') === '') {
      form.setFieldsValue({ customerBankAccount: tempBankAccount });
    }
  }

  const handleContactsFocus = () => {
    tempContacts = form.getFieldValue('customerContacts');
    form.setFieldsValue({ customerContacts: '' });
  }

  const handleContactsBlur = () => {
    if (form.getFieldValue('customerContacts') === '') {
      form.setFieldsValue({ customerContacts: tempContacts });
    }
  }

  return (
    <Form
      form={form}
      {...formLayout}
      onFinish={handleSaveClientInfo}
    >
      <Card
        title="客户信息"
        actions={
          isShowSaveClientBTN
            ? <Button
                type="primary"
                loading={saveLoading}
                htmlType="submit"
              >
                保存客户信息
              </Button>
            : <></>
        }
      >
        <Row>
          <Col span={10}>
            <Item
              label="客户编码"
              name="customerCode"
              rules={[{ required: isCustomerCodeRequired }]}
              labelCol={{ span: 5, offset: 0 }}
              wrapperCol={{ span: 19 }}
            >
              {/* isCustomerCodeAndNameReadonly */}
              {(isDisabled || !isEditComp)
                ? (<DisplayInput />)
                : (<AutoComplete
                    allowClear
                    onSelect={handleCustomerCodeSelect}
                    filterOption={(inputValue, option) =>
                      option!.title.includes(inputValue)
                    }
                    options={customerInfo.map(customer => ({
                      title: `${customer.customerCode} ${customer.shortName}`,
                      label: `${customer.customerCode} ${customer.shortName}`,
                      value: customer.customerCode
                    }))}
                  />
              )}
            </Item>
          </Col>
          <Col span={2}>
            {isShowPersonalBTN
              ? (<Item
                  label="个人"
                  valuePropName="checked"
                  labelCol={{ span: 10, offset: 3 }}
                  wrapperCol={{ span: 14 }}
                >
                  <Switch onChange={handlePersonalBTNChange} />
                </Item>)
              : <Item />
            }
          </Col>
          <Col span={12}>
            <Item
              label="开户行及账号"
              name="customerBankAccount"
              labelCol={{ span: 5 }}
              rules={[{ required: isBankAccountRequired }]}
            >
              {isDisabled
                ? (<DisplayInput />)
                : (
                <AutoComplete
                    allowClear
                    // onFocus={handleBackAccountFocus}
                    // onBlur={handleBackAccountBlur}
                    onChange={handleBankAccountChange}
                    filterOption={(inputValue, option) =>
                      option!.title.includes(inputValue)
                    }
                    options={bankAccountList.map(item => ({
                      title: item.bankAccount + ' ',
                      label: item.bankAccount,
                      value: item.bankAccount
                    }))}
                  />
                  )
                }
            </Item>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <Item label="客户名称" name="bigCustomerName">
            {isDisabled || !isEditComp
                ? (<DisplayInput />)
                : (
                <Input />
                )
            }
            </Item>
          </Col>
          <Col span={12}>
            <Item
              label="地址电话"
              name="customerAddrTel"
              labelCol={{ span: 5 }}
              rules={[{ required: isAddrTelRequired }]}
            >
              {isDisabled
                ? (<DisplayInput />)
                : (
                <Input />
                )
              }
            </Item>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <Item
              label="公司名称"
              name="customerName"
              rules={[{ required: isCustomerNameRequired }]}
            >
              {/*(isCustomerCodeAndNameReadonly ||  */}
               {isDisabled || !isEditComp
                ? (<DisplayInput />)
                : (
                <Input />
              )}
            </Item>
          </Col>
          <Col span={12}>
            <Item
              label="纳税人识别号"
              name="customerTaxNo"
              labelCol={{ span: 5 }}
              rules={[{
                required: isTaxNoRequired,
                pattern: new RegExp(/^[A-Z\d]+$/),
                message: '只能输入数字和大写字母',
              }]}
            >
              {isDisabled
                ? (<DisplayInput />)
                : (
                <Input />
                 )
              }
            </Item>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <Item label="联系人" name="customerContacts">
              {isNew
                ?
                (<DisplayInput />)
                : (<AutoComplete
                    allowClear
                    onFocus={handleContactsFocus}
                    onBlur={handleContactsBlur}
                    onChange={handleContractsChange}
                    filterOption={(inputValue, option) =>
                      option!.title.includes(inputValue)
                    }
                    options={contactsList.map(item => ({
                      title: item.fullName,
                      label: item.fullName,
                      value: item.fullName
                    }))}
                  />)
              }
            </Item>
          </Col>
          <Col span={12}>
            <Item
              label="邮箱"
              name="customerEmail"
              labelCol={{ span: 5 }}
              rules={[{ required: isEmailRequired }]}
            >
              {isNew
                ? (<DisplayInput />)
                : (<Input />)
              }
            </Item>
          </Col>
          <Col span={12}>
            <Item
              label="联系人地址"
              name="contactsAddress"
            >
              {isNew
                ? (<DisplayInput />)
                : (<Input />)
              }
            </Item>
          </Col>
          <Col span={12}>
            <Item
              label="联系人电话"
              name="contactsPhone"
              labelCol={{ span: 5 }}
            >
              {isNew
                ? (<DisplayInput />)
                : (<Input />)
              }
            </Item>
          </Col>
        </Row>
      </Card>
    </Form>
  );
});

export default ClientForm;
