import React, { FC, Fragment, useState } from 'react'
import { Link } from 'react-router-dom'
import { Input, Button, Form, Select } from 'antd'
import { FormInstance } from 'antd/lib/form'

import { Page, Card, Table, Modal } from '@comps'
import { ColumnProps } from '@comps/types'
import { Http, Context, Validation, Layout, Message } from '@utils'
import { Edit } from './edit'

const Item = Form.Item
const columns: ColumnProps[] = [
    {
        key: 'ruleCode',
        title: '规则编码'
    },
    {
        key: 'ruleName',
        title: '规则名称'
    },
    {
        key: 'levelDesc',
        title: '层级',
    },
    {
        key: 'enabled',
        title: '启用',
        render: (value, record, index) => (value ? '启用' : '失效'),
    },
    {
        key: 'inUsing',
        title: '在使用中',
        render: (value, record, index) => (value ? '使用中' : '暂未使用'),
    },
    {
        key: 'lastFetchTime',
        title: '最后产生流水时间',
        format: 'datetime',
    },
    {
        key: 'id',
        title: '操作',
        render: (value, record, index) => (<Link to={`/sys/codeRules/${value}/edit`}>修改</Link>),
    },
]

const options = [
    {
        key: 'GLOBAL',
        value: '全局层',
    },
    {
        key: 'TENANT',
        value: '租户层',
    },
    {
        key: 'ORG',
        value: '组织层',
    }
]

const List: FC = () => {

    let [visible, setVisible] = useState(false)

    let addForm: FormInstance

    const actions = <Fragment><Button type='primary' onClick={() => { setVisible(true) }}>新增</Button></Fragment>

    const saveAdd = () => {
        addForm.validateFields().then(values => {
            Http.post('/sys/codeRules', values).then((data: any) => {
                addForm.resetFields()
                Message.saveOk()
                setVisible(false)
                Context.route(`/sys/codeRules`)
            })
        })
    }

    return (
        <Page>
            <Card title='编码规则' actions={actions}>
                <Table url='/sys/codeRules' columns={columns} scroll={{ y: window.innerHeight - 300 }}>
                    <Input name='ruleCode' placeholder='规则编码' />
                    <Input name='ruleName' placeholder='规则名称' />
                </Table>
            </Card>

            <Modal visible={visible} title='新增'
                onCancel={() => setVisible(false)}
                onOk={() => saveAdd()}
            >
                <Form {...Layout.modal_1c} name='addForm'
                    ref={form => { if (form) { addForm = form } }}
                    validateMessages={Validation.formValidateMsg}
                >
                    <Item name='ruleCode' label='规则编码' rules={[{ required: true }]}>
                        <Input />
                    </Item>
                    <Item name='ruleName' label='规则名称' rules={[{ required: true }]}>
                        <Input />
                    </Item>
                    <Item name='level' label='层级' rules={[{ required: true }]}>
                        <Select options={options} />
                    </Item>

                </Form>
            </Modal>

        </Page>
    )
}

export {
    List as CodeRuleList,
    Edit as CodeRuleEdit,
}