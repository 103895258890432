import React, { FC, useState, useEffect, memo } from 'react';
import { Form, Col, Select } from 'antd';

import { Http } from '@utils';

import { FormInstance } from 'antd/lib/form'; 

interface Props {
  form: FormInstance;
  isEdit: boolean;
  rowData: JSONObject;
  colSpan: number;
  categoryNames: [string, string, string];
  isMiddleCategoryRequired?: boolean;
};

const { Item } = Form;

const MatterCategory: FC<Props> = memo(({
  form,
  isEdit,
  rowData,
  colSpan,
  categoryNames,
  isMiddleCategoryRequired = false,
}) => {

  const bigCategoryName = categoryNames[0];
  const middleCategoryName = categoryNames[1];
  const smallCategoryName = categoryNames[2];

  const [bigCategories, setBigCategories] = useState<JSONObject[]>([]);
  const [middleCategories, setMiddleCategories] = useState<JSONObject[]>([]);
  const [smallbigCategories, setSmallCategories] = useState<JSONObject[]>([]);
  
  useEffect(() => { 
    setCatagoryOptions();
  }, []);
  
  const setMiddleSmallCategoryOptions = (
    bigCategoryOptions: JSONObject[]
  ) => {
   
    const bigCategory = rowData[bigCategoryName];
    const middleCategory = rowData[middleCategoryName];
  
    const middleCategoryOptions = setMiddleCagegoryOptionsByBig(
      bigCategoryOptions, 
      bigCategory,
    );
  
    setSmallCagegoryOptionsByMiddle(
      middleCategoryOptions, 
      middleCategory,
    );
  };
  
  const setCatagoryOptions = () => {
    Http.get('/tax/goodsCategoryMapping/categories/tree')
      .then(data => {
        setBigCategories(data.children);
  
        isEdit && setMiddleSmallCategoryOptions(data.children);
      })
  };
  
  const setMiddleCagegoryOptionsByBig = (
    bigCategoriesArr: JSONObject[], 
    bigCategoryVal: string,
  ) => {
  
    const currentBigCategory = bigCategoriesArr.find(
      bigCategory => (
        bigCategory.title === bigCategoryVal
      )
    ) ?? {};
  
    const currentMiddleCategory: JSONObject[] = 
      currentBigCategory?.children ?? [];
  
    setMiddleCategories(currentMiddleCategory);
  
    return currentMiddleCategory;
  };
  
  const setSmallCagegoryOptionsByMiddle = (
    middleCategoriesArr: JSONObject[], 
    middleCategoryVal: string,
  ) => {
  
    const currentMiddleCategory = middleCategoriesArr.find(
      middleCategory => (
        middleCategory.title === middleCategoryVal
      )
    ) ?? {};
  
    const currentSmallCategory: JSONObject[] = 
      currentMiddleCategory?.children ?? [];
  
    setSmallCategories(currentSmallCategory);
  
    return currentSmallCategory; 
  };
  
  const handleBigCategoryChange = (selectedBigCategory: any) => {
    setMiddleCagegoryOptionsByBig(
      bigCategories, 
      selectedBigCategory
    );
    
    form.setFieldsValue({
      [middleCategoryName]: '',
      [smallCategoryName]: '',
    });
  };
  
  const handleMiddleCategoryChange = (selectedMiddleCategory: any) => {
    setSmallCagegoryOptionsByMiddle(
      middleCategories, 
      selectedMiddleCategory
    );
    
    form.setFieldsValue({
      [smallCategoryName]: '',
    });
  };
  
  return (
    <>
      <Col span={colSpan}>
        <Item 
          label="物料大类" 
          name={bigCategoryName}
          rules={[{ required: true }]}
        >
          <Select
            showSearch 
            options={bigCategories.map(
              option => ({ 
                label: option.title, 
                value: option.title, 
                title: option.title, 
              })
            )} 
            onChange={handleBigCategoryChange}
          />
        </Item>
      </Col>
      <Col span={colSpan}>
        <Item 
          label="物料中类" 
          name={middleCategoryName}
          rules={[{ required: isMiddleCategoryRequired }]}
        >
          <Select
            showSearch 
            options={middleCategories.map(
              option => ({ 
                label: option.title, 
                value: option.title, 
              })
            )} 
            onChange={handleMiddleCategoryChange}
          />
        </Item>
      </Col>
      <Col span={colSpan}>
        <Item 
          label="物料小类" 
          name={smallCategoryName}
        > 
          <Select 
            showSearch 
            options={smallbigCategories.map(
              option => ({ 
                label: option.title, 
                value: option.title, 
              })
            )} 
          />
        </Item>
      </Col>
    </>
  );
});

export default MatterCategory;
