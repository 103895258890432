import React, { FC, useEffect, useRef, useState } from "react";
import { useSelector } from 'react-redux';
import { Page, Card, Lookup, RichEditor, DisplayUpload, LinkText, Modal } from "@comps";
import { Button, Col, DatePicker, Form, Input, Row, Space, Select, message, Spin } from "antd";
import ArticleList from "./component/articlesList"
import AnalysisList from "./component/analysisList"
import moment from "moment";
import { Http, Context, Api } from "@utils";
import "./operate.moudles.css"
import { UserContext } from '@models';

const { Item, List } = Form
const { Option } = Select
const { TextArea } = Input
const disabledDate = (current: any) => current && current > moment().endOf('day')

const layout = {
  labelCol: { span: 2 },
  wrapperCol: { span: 18 },
}

const cellLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
}

interface CRef {
  showHtml: (val: any) => void
}

const Operate: FC = (props: any) => {
  const id = props.match.params.id;
  const status = props.match.params.status
  const userInfo = useSelector<JSONObject, UserContext>((state) => state.ctx);
  const [option, setOption] = useState([]);
  const [allowFlag, setAllowFlag] = useState(true)
  const [analysisDtoList, setAnalysisDtoList] = useState([])
  const [modal, modalHolder] = Modal.useModal();
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm()
  const editorRef = useRef<CRef>(null)
  const uploadRef: any = useRef()
  const fileListRef: any = useRef([])
  const allowRef: any = useRef([])
  const validateParams: any = useRef()

  useEffect(() => {
    Api.getKv('/org/orgs/kv')
      .then((data: any) => {
        setOption(data);
      });
    getKonwledge()
  }, [id, props.location])

  const getKonwledge = async () => {
    if (id === undefined) return
    setLoading(true)
    await Http.get(`/knowledge/${id}`).then(res => {
      setAnalysisDtoList(res.analysisDtoList)
      setFormVal(res)
    }).finally(() => {
      setLoading(false)
    })
  }

  //设置表单对应字段
  const setFormVal = async (res: any) => {
    if (id === undefined) return
    editorRef.current?.showHtml(res.content)
    form?.setFieldsValue({
      ...res,
      publishOrg: res.publishOrg ? res.publishOrg.split(",") : []
    });
    const articles = []
    for (let i in res.correlationMap) {
      articles.push({ k: [i], v: res.correlationMap[i] })
    }
    form?.setFieldsValue({
      allow: res.consultList.map((item: { orgId: any; }) => item.orgId),
      policy: res?.analysisDraft?.analysisContent,
      date: moment(res.releaseDate),
      articles: articles
    })
    if (res.fileList.length !== 0) {
      fileListRef.current = [...fileListRef.current, ...res.fileList]
      const fileList = res.fileList?.map((item: { id: any; fileName: any; }) => {
        return { uid: item.id, name: item.fileName }
      })
      uploadRef.current.setFileList(fileList)
    }
    validateParams.current = { title: res.title, proof: res.proof }
    if (res.fileType === 3) {
      setAllowFlag(false)
    }
  }

  // 获取正文
  const getContext = (content: string) => {
    form.setFieldsValue({
      content
    })
  }

  //上传文件时的处理
  const uploadOk = async () => {
    const fileList = uploadRef.current.fileList.filter((item: {
      uid: any
    }) => !fileListRef.current.some((ele: { id: any; }) => ele.id === item.uid));
    if (fileList.length === 0) return
    for (const key in fileList) {
      const formData = new FormData();
      formData.append('file', fileList[key]);
      await Http.postWithFormFile("/cms/files",
        formData
      ).then((res: any) => {
        console.log(res);
        fileListRef.current.push({
          id: res.id,
          fileName: res.fileName,
          fileUrl: res.fileUrl,
          storagePath: res.storagePath,
          uploadTime: moment().format('YYYY-MM-DD')
        })
      })
    }
  }

  //删除文件
  const uploadDelete = async (file: any) => {
    fileListRef.current = fileListRef.current.filter((ele: { id: any; }) => ele.id !== file.uid)
  }

  //  获取参数
  const getParams = async () => {
    await uploadOk()
    const formData = form.getFieldsValue();
    const data = {
      title: formData.title,
      proof: formData.proof,
      validity: formData.validity,
      releaseDate: formData.date ? moment(formData.date).format("YYYY-MM-DD") : moment().format("YYYY-MM-DD"),
      taxType: formData.taxType || '',
      fileType: formData.fileType,
      discountsType: formData.discountsType || '',
      publishOrg: formData.publishOrg ? formData.publishOrg.join(",") : '',
      content: formData.content || '',
      netUrl: formData.netUrl || '',
      correlationId: formData.articles?.map((item: { k: any; }) => item.k).join(',') || '',
      status: 1,
      policyAnalysisParamList: formData.policy && [{
        status: 1,
        analysisContent: formData.policy,
        analysisDate: moment().format("YYYY-MM-DD"),
        analysisBy: userInfo.userId,
        analysisName: userInfo.fullName,
        knowledgeId: id
      }],
      fileList: fileListRef.current,
      consultList: allowRef.current?.map((item: { value: any; label: any; }) => { return { orgId: item.value, orgName: item.label } }) || [],
      whetherOpen: allowRef.current?.length === 0 ? 1 : 2,
      id: undefined
    }
    return data
  }

  const allowSelected = (value: any, option: any) => {
    allowRef.current = option
  }

  const showActile = async () => {
    await modal.open({
      width: 700,
      content: (_1, reject) => (
        <ArticleList onClose={reject} onSelected={getArticlesList} ></ArticleList>
      )
    });
  }

  const resetKnowledge = () => {
    form.resetFields();
    fileListRef.current = []
    Context.route('/taxKnowledge/publish', { back: new Date() });
  }

  const getArticlesList = (list?: JSONObject[]) => {
    form.setFieldsValue({
      articles: [
        ...list!
      ]
    })
  }

  const fileTypeSelect = (value: any) => {
    if (value === 3) {
      setAllowFlag(false)
    } else {
      setAllowFlag(true)
    }
    if (value === 1) {
      form.setFieldsValue(
        {
          validity: 1
        }
      )
    } else {
      form.setFieldsValue(
        {
          validity: undefined
        }
      )
    }
  }

  const saveDraft = async () => {
    const data = await getParams()
    data.status = 0
    if (data.policyAnalysisParamList !== undefined) {
      data.policyAnalysisParamList[0].status = 0
    }
    form?.validateFields()
      .then(() => {
        setLoading(true)
        if (id) {
          data.id = id
          Http.put('/knowledge', data)
            .then(() => {
              message.success('编辑草稿成功')
              resetKnowledge()
            }).finally(() => {
              setLoading(false)
            })
        } else {
          Http.post('/knowledge', data)
            .then(() => {
              message.success('新增草稿成功')
              resetKnowledge()
            }).finally(() => {
              setLoading(false)
            })
        }
      });
  }

  const publishKnowledge = async () => {
    const data = await getParams()
    form?.validateFields()
      .then(() => {
        setLoading(true)
        if (id) {
          data.id = id
          Http.put('/knowledge', data)
            .then(() => {
              message.success('编辑成功')
              resetKnowledge()
            }).finally(() => {
              setLoading(false)
            })
        } else {
          Http.post('/knowledge', data)
            .then(() => {
              message.success('新增成功')
              resetKnowledge()
            })
            .finally(() => {
              setLoading(false)
            })
        }
      });
  }

  return (
    <Page>
      {modalHolder}
      <Spin spinning={loading}>
        <Card title={id !== undefined ? '编辑知识查阅' : '新增知识查阅'}
          actions={
            <Space>
              {(id === undefined || status === '0') && <Button type="primary" onClick={saveDraft}>存为草稿</Button>}
              <Button type="primary" onClick={publishKnowledge}>发布</Button>
              <Button onClick={resetKnowledge} >取消</Button>
            </Space>
          }>
          <Form form={form} {...layout} >
            <Item name="title" label="标题" wrapperCol={{ span: 10 }}
              validateTrigger="onBlur"
              rules={[{
                required: true, message: " "
              }, {
                validator: async () => {
                  const title = form.getFieldValue('title')
                  if (title === '' || title === undefined) {
                    return Promise.reject('请输入税务知识标题');
                  }
                  let url
                  if (id) {
                    url = `/knowledge/check?id=${id}&title=${title}`
                  } else {
                    url = `/knowledge/check?title=${title}`
                  }
                  if ((title !== '' && title !== undefined) && validateParams.current?.title !== title) {
                    await Http.post(url).then((res: any) => {
                    }).catch(error => {
                      if (error) {
                        return Promise.reject('已存在重复的税务知识标题');
                      }
                    })
                  }
                }
              }]}
            >
              <Input disabled={status === '1' ? true : false} />
            </Item>
            <Item name="proof" label="文号" wrapperCol={{ span: 10 }}
              validateTrigger="onBlur"
              rules={[{
                validator: async () => {
                  const proof = form.getFieldValue('proof')
                  const fileType = form.getFieldValue('fileType')
                  if (fileType === 1 && !proof) {
                    return Promise.reject('文件类型为政策法规时,文号必须填写')
                  }
                  let url
                  if (id) {
                    url = `/knowledge/check?id=${id}&proof=${proof}`
                  } else {
                    url = `/knowledge/check?proof=${proof}`
                  }
                  if ((proof !== '' && proof !== undefined) && validateParams.current?.proof !== proof) {
                    await Http.post(url).then((res) => {
                    }).catch((error) => {
                      if (error) {
                        return Promise.reject('已存在重复的税务知识文号');
                      }
                    })
                  }
                  return Promise.resolve();
                }
              }]}>
                <Input disabled={status === '1' ? true : false} />
            </Item>
            <Row>
              <Col span={6}>
                <Item name="validity" label="有效性" {...cellLayout}
                  validateTrigger="onBlur"
                  rules={[{
                    validator: async () => {
                      const validity = form.getFieldValue('validity')
                      const fileType = form.getFieldValue('fileType')
                      if (fileType === 1 && !validity) {
                        return Promise.reject('有效性必须填写')
                      }
                      return Promise.resolve();
                    }
                  }]}>
                    <Select style={{ width: '100%' }} allowClear={true}>
                      <Option value={1}>全文有效</Option>
                      <Option value={2}>条款失效</Option>
                      <Option value={3}>全文废止</Option>
                    </Select>
                </Item>
              </Col>
              <Col span={6}>
                <Item name="date" label="发文日期" {...cellLayout} rules={[{ required: true, message: "请选择税务知识的发文日期" }]}>
                  <DatePicker disabledDate={disabledDate} inputReadOnly style={{ width: '100%' }}></DatePicker>
                </Item>
              </Col>
              <Col span={6}>
                <Item name="taxType" label="所属税种" {...cellLayout}>
                  <Lookup lookupType="TAX_TYPE" style={{ width: '100%' }}></Lookup>
                </Item>
              </Col>
            </Row>
            <Row>
              <Col span={6}>
                <Item name="fileType" label="文件类型" {...cellLayout} rules={[{ required: true, message: "请选择税务知识的文件类型" }]}>
                  <Select onSelect={fileTypeSelect}>
                    <Option value={1}>政策法规</Option>
                    <Option value={2}>政策解读</Option>
                    <Option value={3}>稽查案例</Option>
                    <Option value={4}>稽查应对措施</Option>
                  </Select>
                </Item>
              </Col>
              <Col span={6}>
                <Item name="discountsType" label="优惠类型" {...cellLayout}>
                  <Lookup lookupType="YHLX" placeholder="优惠类型"></Lookup>
                </Item>
              </Col>
            </Row>
            <Item name="publishOrg" label="发文机构">
              <Lookup lookupType="FWJG" placeholder="发文机构" showSearch optionFilterProp="label" mode="multiple"></Lookup>
            </Item>
            <Item name="allow" label="允许查阅对象" extra="为空时默认全公开">
              <Select showSearch optionFilterProp="label" mode="multiple" options={option} onChange={allowSelected} disabled={allowFlag} />
            </Item>
            <Item name="content" label="正文" rules={[{ required: true, message: "请填写税务知识的正文内容" }]}>
              <RichEditor getText={getContext} ref={editorRef}  ></RichEditor>
            </Item>
            {/* {status === '1' && analysisDtoList.length !== 0 &&
              <Form.Item label="政策解读" style={{ marginTop: '45px' }}>
                <AnalysisList analysisDtoList={analysisDtoList} knowledgeId={id}></AnalysisList>
              </Form.Item>
            }
            <span style={{ fontSize: '12px', color: 'gray', marginLeft: '100px' }}>解析人：{userInfo.fullName}</span>
            <span style={{ fontSize: '12px', color: 'gray', marginLeft: '20px' }}>{moment().format("YYYY-MM-DD")}</span>
            {status === "1" && <Item name="policy" label="添加政策解读">
              <TextArea autoSize={{ minRows: 3 }}>
              </TextArea>
            </Item>} */}
            <Item name="addition" label="附件">
              <DisplayUpload ref={uploadRef}
                text="点击选择文件"
                multiple={true}
                accept=".pdf,.doc,.docx,.xls,.xlsx"
                handleDelete={uploadDelete} />
            </Item>
            <Item label="相关文章">
              <Button type="primary" onClick={showActile}>点击选择相关文章</Button>
            </Item>
            <List name="articles">
              {(fields: any) => (
                <>
                  {fields.map((field: JSONObject, index: number) => (
                    <Space key={field.key}>
                      <Item
                        noStyle
                        shouldUpdate
                      >
                        {() => {
                          const { articles } = form.getFieldsValue()
                          const curValue = articles[index]
                          return (
                            <LinkText style={{ display: 'block', width: '1000px', marginLeft: '35px', marginBottom: '10px' }} label={curValue.v} onClick={() => { Context.route(`/taxKnowledge/knowledgeContent/${curValue.k}`) }}></LinkText>
                          )
                        }}
                      </Item>
                    </Space>
                  ))}
                </>
              )}
            </List>
            <Item name="netUrl" label="网站链接">
              <Input placeholder="税务知识来源网站地址" />
            </Item>
          </Form>
        </Card>
      </Spin>
    </Page>
  )
}

export default Operate
