import React, { FC, useState, useImperativeHandle, forwardRef } from 'react';
import { Button, Popconfirm, Space } from 'antd';

import { Card, Table, LinkText, Modal } from '@comps';
import ImportRebateTableMd from './modal/import-rebate-table';

import { Http, Message, Math } from '@utils';
import { ColumnProps } from '@comps/types';

interface Props {
  ref?: any,
  baseFormId: number,
  setAllModuleData: (data: JSONObject) => void,
  setPageLoading: (isLoading: boolean) => void
};

const RebateTable: FC<Props> = forwardRef(({
  baseFormId,
  setAllModuleData,
  setPageLoading
}, ref) => {

  let [table, setTable] = useState<Table>();

  const [maxDiscountAmount, setMaxDiscountAmount] = useState(0);
  const [WriteOffAmount, setWriteOffAmount] = useState(0);
  const [notWriteOffAmount, setNotWriteOffAmount] = useState(0);

  const [isShowRebateTableAutoImportBTN, setIsShowRebateTableAutoImportBTN] = useState(true);
  const [isShowRebateTableAddDetailBTN, setIsShowRebateTableAddDetailBTN] = useState(true);

  const [modal, modalHolder] = Modal.useModal();

  const [autoImportLoading, setAutoImportLoading] = useState(false);

  useImperativeHandle(ref, () => ({
    setRebateTableDataSource,
    setRebateTableSummaryAmount,
    getRebateTableSummaryAmount,
    setIsShowRebateTableAutoImportBTN,
    setIsShowRebateTableAddDetailBTN
  }));

  const onTableRef = (tableRef: Table) => {
    setTable(tableRef);
    table = tableRef;
  };

  const setRebateTableDataSource = (dataSource: JSONObject[]) => {
    table?.setDataSource(dataSource);
  };

  const setRebateTableSummaryAmount = (data: JSONObject) => {
    const { maxDiscountAmount, invDiscountAmount, remDiscountAmount } = data;

    setMaxDiscountAmount(maxDiscountAmount);
    setWriteOffAmount(remDiscountAmount);
    setNotWriteOffAmount(invDiscountAmount);
  };

  const getRebateTableSummaryAmount = (): JSONObject => {
    return {
      maxDiscountAmount,
      WriteOffAmount,
      notWriteOffAmount
    };
  };

  const showRebateDetailMD = () => {
    modal.open({
      width: 1400,
      content: (_1, reject) => (
        <ImportRebateTableMd
          baseFormId={baseFormId}
          setAllModuleData={setAllModuleData}
          onClose={reject}
        />
      )
    });
  };

  const handleRebateTableDel = (id: number) => {
    Message.info('删除中');
    setPageLoading(true);

    Http.del(`/request/deletePolicyLine/${id}`)
      .then((data: JSONObject) => {
        setAllModuleData(data);
        Message.success('删除成功');
      })
      .finally(() => {
        setPageLoading(false);
      });
  };

  const handleAutoImport = () => {
    setAutoImportLoading(true);

    Http.post(`/request/${baseFormId}/autoImportPolicyLine`)
      .then(data => {
        setAllModuleData(data);
        Message.success('引入成功');
      })
      .finally(() => {
        setAutoImportLoading(false);
      })
  };

  const columns: ColumnProps[] = [
    { key: 'orderNo', title: '单据号', width: 200, fixed: 'left' },
    { key: 'customerCode', title: '客户编码', width: 200 },
    { key: 'customerName', title: '客户名称', width: 230 },
    { key: 'businessType', title: '业务类型', width: 150,
      render: (businessType: string) => (
        businessType === 'DISCOUNT' ? '折扣单据' : '返利单据'
      )
    },
    { key: 'withTaxAmount', title: '金额', width: 160 },
    { key: 'invoicedAmount', title: '已核销金额', width: 160 },
    { key: 'remainAmount', title: '未核销金额', width: 160 },
    { key: 'applyAmount', title: '申请核销金额', width: 160 },
    { key: 'orderDate', title: '业务订单创建日期', width: 150 },
    { key: 'sourceSystem', title: '来源系统', width: 150 },
    { key: 'description', title: '摘要', width: 200 },
    { key: 'policyLineId', title: '操作', width: 130, align: 'center', fixed: 'right',
      render: (policyLineId: number, row: JSONObject) => (
        row.isShowDelete && (
          <Popconfirm
            title="是否确认删除该行 ?"
            onConfirm={() => handleRebateTableDel(policyLineId)}
          >
            <LinkText label="删除" />
          </Popconfirm>
        )
      )
    }
  ];

  const RedFontAmount: FC = ({children}) => {
    return (
      <span style={{ color: '#f00' }}>
        {children}&nbsp;&nbsp;
      </span>
    );
  };

  return (
    <Card title="折扣返利明细" actions={
      <Space>
        <div>
          <span>最大可折扣金额：</span>
          <RedFontAmount>{Math.num2money(maxDiscountAmount)}</RedFontAmount>
        </div>
        <div>
          <span>未核销总金额：</span>
          <RedFontAmount>{Math.num2money(WriteOffAmount)}</RedFontAmount>
        </div>
        <div>
          <span>核销总金额：</span>
          <RedFontAmount>{Math.num2money(notWriteOffAmount)}</RedFontAmount>
        </div>
        {isShowRebateTableAutoImportBTN && (
          <Popconfirm 
            title="是否自动引入 ?"
            onConfirm={handleAutoImport}
          >
            <Button type="primary" loading={autoImportLoading}>自动引入</Button>
          </Popconfirm>
        )}
        {isShowRebateTableAddDetailBTN && (
          <Button type="primary" onClick={showRebateDetailMD}>添加明细</Button>
        )}
      </Space>
    }>
      {modalHolder}
      <Table
        onTableRef={onTableRef}
        columns={columns}
      />
    </Card>
  );
});

export default RebateTable;
