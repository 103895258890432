export interface TableRow {
  id: number,
  requestNo: string,
  statusCode: number,
  statusCodeDesc: string,
  needRedNoticeNo: boolean,
  allowRetry?: boolean,
  submitTime: string,
  submitterName: string,
  approverName: string,
  approveTime: string,
  approveRemarks: string
}

export enum InputTransactionType {
  /**
   * 抵扣勾选
   */
  DEDUCTION_CHECK = '1',
   /**
   * 撤销抵扣勾选
   */
  CANCEL_DEDUCTION_CHECK = '3', 
   /**
   * 申请统计
   */
  APPLICATION_STATISTICS = '5',
   /**
   * 撤销统计
   */
  UNDO_STATISTICS = '7',
   /**
   * 申请统计确认
   */
  APPLICATION_STATISTICS_CONFIRMATION = '9',
}



