import React, { FC, useEffect, useState, useRef} from 'react';
import { Modal, Form, Row, Col, Space, Button, InputNumber, DatePicker } from 'antd';

import { Page, Card, DisplayInput } from '@comps';

import { Http, FormUtil, DateUtils } from '@utils';

import CheckSreultMD from '../modal/check-result';

interface Props {
  visible: boolean;
  checkInfo: JSONObject;
  getTableData: (params: JSONObject) => void;
  onCancle: () => void;
}

const { Item } = Form;
const { RangePicker } = DatePicker;

const SmartCheckMD: FC<Props> = ({
  visible,
  checkInfo,
  getTableData,
  onCancle,
}) => {
  const [form] = Form.useForm();
  const resRef: any = useRef();

  const [inputTaxLoading, setInputTaxLoading] = useState(false);
  const [refValueLoading, setRefValueLoading] = useState(false);
  const [isShowSamrtCheckMD, setIsShowSmartCheckMD] = useState(false);
  // const [isSelf, setIsSelf] = useState(false);
  const [isShowCheckSreultMD, setIsShowCheckSreultMD] = useState(false);
  const [checkedCurrentPeriod, setCheckedCurrentPeriod] = useState(false);

  useEffect(() => {
    getSmartCheckInfo();
  }, []);

  // useEffect(() => {
  //   setIsSelf(visible)
  // }, [visible]);

  useEffect(() => {
    form.setFieldsValue({
      invoiceDateFromTo: [
        DateUtils.someMonthFirstDay(checkInfo.period + ''),
        DateUtils.someMonthLastDay(checkInfo.period + '')
      ]
    })
  }, [checkInfo]);

  const recalculateInputTax = () => {
    setInputTaxLoading(true);

    const formData = FormUtil.filterInvalidFormData(form.getFieldsValue());

    Http.post('/input/gx/calSmartTick', formData)
      .then(data => {
        form.setFieldsValue(data);
      })
      .finally(() => {
        setInputTaxLoading(false);
      })
  };

  const getSmartCheckInfo = (isManual: boolean = false) => {
    isManual && setRefValueLoading(true);

    Http.get('/input/gx/smartTick')
      .then(data => {
        form.setFieldsValue(data);
        setCheckedCurrentPeriod(data.gxTaxAmount)
      })
      .finally(() => {
        isManual && setRefValueLoading(false);
      })
  };

  const handleRetrieveInvoice = () => {
    setIsShowCheckSreultMD(true);
    const formData = FormUtil.filterInvalidFormData(form.getFieldsValue());
    getTableData(formData);

    // onCancle();
  };

  const getCheckResult = (checkResultData: JSONObject) => {
    resRef.current.getCheckResult(checkResultData);
  }

  const FILED_PAGE_NUM: string = "pageNum";
  const FILED_PAGE_SIZE: string = "pageSize";
  const [loading, setLoading] = useState(false);
  const [checkResultData, setCheckResultData] = useState<JSONObject>({});

  const handleSmartCheckMDShow = async (
    isShow: boolean,
    tableHeaderParams?: any,
    pageSize: number = 10,
    pageNum: number = 1
  ) => {
    const values = await form.validateFields();
    setLoading(true);
    let queryPanelParams = form.getFieldsValue();
    queryPanelParams = filterInvalidFormData(queryPanelParams);
    const queryParams = { ...tableHeaderParams, ...queryPanelParams };
    queryParams[FILED_PAGE_SIZE] = pageSize;
    queryParams[FILED_PAGE_NUM] = pageNum;
    await Http.get('/input/gx/smartGxData',queryParams)
    .then((data:any) => {
      onCancle()
      // setIsSelf(false)
      setIsShowSmartCheckMD(isShow);
      // setIsSelf(false)
      setLoading(false);
      setCheckResultData(data);
      getCheckResult(data);
    },).finally(() => {
      setLoading(false);
    })
  }

  const filterInvalidFormData = (formData: JSONObject): JSONObject => {
    const keys = Object.keys(formData);

    for (var key of keys) {
      const val = formData[key];
      if (typeof val === 'undefined') {
        delete formData[key];
      } else if (val === null) {
        delete formData[key];
      }
    };

    return formData;
  };

  return (
    <Page>
      <CheckSreultMD
        ref={resRef}
        visible={isShowSamrtCheckMD}
        getTableData={getTableData}
        onCancle={() => handleSmartCheckMDShow(false)}
        checkedCurrentPeriod={checkedCurrentPeriod}
      />
      <Modal
        centered
        width={820}
        footer={null}
        visible={visible}
        onCancel={onCancle}
      >
        <Card
          title="智能勾选"
          style={{
            padding: 0,
            boxShadow: 'none'
          }}
        >
          <Form
            form={form}
            labelCol={{ span: 8 }}
            style={{ paddingRight: 30 }}
            onFinish={handleRetrieveInvoice}
          >
            <Row style={{ marginTop: 20 }}>
              <Col span={12}>
                <Item label="销项税额" name="salesTax">
                  <InputNumber style={{ width: '100%' }} />
                </Item>
              </Col>
              <Col span={12}>
                <Item label="容差率" name="toleranceValue">
                  <InputNumber
                    style={{ width: '100%' }}
                    min={0}
                    max={1}
                  />
                </Item>
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <Item label="营业收入" name="operatingIncome">
                  <InputNumber style={{ width: '100%' }} />
                </Item>
              </Col>
              <Col span={12}>
                <Item label="税负率" name="industryTaxRate">
                  <InputNumber
                    style={{ width: '100%' }}
                    min={0}
                    max={1}
                  />
                </Item>
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <Item label="计算抵扣税额" name="totalDeductibleBills">
                  <InputNumber style={{ width: '100%' }} />
                </Item>
              </Col>
              <Col span={12}>
                <Item label="进项税额转出" name="transferOutTax">
                  <InputNumber style={{ width: '100%' }} />
                </Item>
              </Col>
            </Row>
            <Row>
              <Col span={8}>
                <Item
                  label="本期税额区间"
                  name="inputTaxAmountMin"
                  labelCol={{ span: 12 }}
                >
                  <InputNumber style={{ width: '100%' }} />
                </Item>
              </Col>
              <Col span={1} style={{ textAlign: 'center' }}>
                <span>~</span>
              </Col>
              <Col span={7}>
                <Item name="inputTaxAmountMax">
                  <InputNumber style={{ width: '60%' }} />
                </Item>
              </Col>
              <Col span={8}>
                <Space>
                  <Button
                    loading={inputTaxLoading}
                    onClick={recalculateInputTax}
                  >
                    重算进项税额
                  </Button>
                  <Button
                    loading={refValueLoading}
                    onClick={() => getSmartCheckInfo(true)}
                  >
                    重新获取参考值
                  </Button>
                </Space>
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <Item label="本期已勾选税额" name="gxTaxAmount">
                  <DisplayInput />
                  {/* <InputNumber style={{ width: '100%' }} /> */}
                </Item>
              </Col>
              <Col span={12}>
                <Item label="发票池日期区间" name="invoiceDateFromTo" rules={[{required: true}]}>
                  <RangePicker
                      placeholder={['发票池日期起', '发票池日期止']}
                      format='YYYYMMDD' style={{ width: '100%' }}
                      inputReadOnly
                    />
                </Item>
              </Col>
            </Row>
            <Row>
              <Col
                span={24}
                style={{
                  marginTop: 20,
                  textAlign: 'center',
                }}
              >
                <Button
                  type="primary"
                  htmlType="submit"
                  onClick={() => handleSmartCheckMDShow(true)}
                  loading={loading}
                >
                  开始检索发票
                </Button>
              </Col>
            </Row>
          </Form>
        </Card>
      </Modal>
    </Page>
  );
};

export default SmartCheckMD;