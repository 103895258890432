import React, { FC, memo, useState, } from "react";
import { Card, Table } from "@comps";
import { Button, Input, Form, message } from "antd";
import { ColumnProps } from '@comps/types';

interface Props {
  onSelected: (list: JSONObject[]) => void
  onClose: () => void
}

const { Item } = Form;


const ArticleList: FC<Props> = (props: Props) => {
  const { onSelected, onClose } = props

  let [table, setTable] = useState<Table>();
  const onTableRef = (tableRef: Table) => {
    setTable(tableRef);
    table = tableRef;
  };

  const columns: ColumnProps[] = [
    { title: "标题", key: "v", width: 400, },
  ]

  const selectedArticles = () => {
    const data = table?.getTableData()
    const keys = table?.getSelectedRowKeys()
    if (keys?.length === 0) {
      return message.warning("请先选择一个或多个文章")
    } else {
      const arr: any[] = []
      keys?.forEach(k => {
        const obj = data.find((item: { k: any; }) => item.k === k)
        arr.push(obj)
      })
      onSelected(arr)
    }
    onClose()


  }
  return (
    <Card>
      <Table
        onTableRef={onTableRef}
        url="/knowledge/kv"
        columns={columns}
        selectedRowKeys={[]}
        rowKey='k'

      >
        <Item name="title">
          <Input placeholder="请输入标题关键字查询" style={{ width: '200px' }} />
        </Item>
      </Table>
      <Button type="primary" style={{ marginRight: '20px' }} onClick={selectedArticles}>确定</Button>
      <Button onClick={onClose}>取消</Button>
    </Card>
  )
}
export default memo(ArticleList);
