import React, { useState } from 'react';

import { Card } from '@comps';
import { Form, Space, DatePicker, Button } from 'antd';

import moment from 'moment'
import { Message, Http } from '@utils'
import { SignStatus } from './interface'

const Item = Form.Item

export interface Props {
  onClose: () => void,
  queryData: JSONObject,
  refreshTable: () => void
};

const MarkDeduction: React.FC<Props> = ({ onClose, refreshTable, queryData }: Props) => {
    const [form] = Form.useForm();

    const [loading, setLoading] = useState<boolean>(false)

    const handlePeriod = () => {
      setLoading(true)

      const { deductionPeriod } = form.getFieldsValue()
      const periodStr = moment(deductionPeriod).format('YYYYMM')
      const periodNum = parseInt(periodStr)
      queryData.deductionPeriod = periodNum

      Http.get('/input/biz/makeDeduction/deduction', queryData)
      .then(res => {
        Message.info('操作成功')
        refreshTable()
      })
      .finally(() => {
        setLoading(false)
        onClose()
      })
    }

    return (
      <Card title='标记抵扣'>
        <Form form={form} onFinish={handlePeriod} labelCol={{ span: 6 }}>
          <Item name='deductionPeriod' label='标记所属期' rules={[{ required: true }]}>
            <DatePicker picker="month" inputReadOnly/>
          </Item>
          <Item>
            <h4>是否确认标记抵扣 ?</h4>
          </Item>
          <Item>
            <Space size="large">
              <Button loading={loading} type="primary" htmlType="submit">确定</Button>
              <Button onClick={() => onClose()}>取消</Button>
            </Space>
          </Item>
        </Form>
    </Card >)
}

export default MarkDeduction
