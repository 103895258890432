import React, { FC, useState, useRef, useCallback, memo, ReactNode } from 'react';
import { Space, Button, Form, Input, InputNumber, DatePicker } from 'antd';

import { Card, Table, Modal, Lookup } from '@comps';
import ImportDetailSubTable from '../component/import-detail-subtable';
import ImportDetailTips from './import-detail-tips';
import StatisticsAmount from './statistics-amount';

import { Message, Http } from '@utils';
import { ColumnProps } from '@comps/types';
import { ApplyType } from '../../../interface';

const { Item } = Form;
const { RangePicker } = DatePicker;

interface Props {
  applyType: string;
  baseFormId: number;
  setAllModuleData: (data: JSONObject) => void;
  onClose: () => void;
}

interface TableItemProps {
  table: ReactNode;
}

interface TableProps {
  [key: string]: TableItemProps;
}

const ImportDetail: FC<Props> = memo(({ 
  applyType,
  baseFormId,
  setAllModuleData,
  onClose,
}) => {

  const subTableArr = useRef<JSONObject[]>([]);
  const childTableIdsRef = useRef<number[]>([]);
  const advanceTableRef = useRef<JSONObject[]>([]);

  const [statisticsAmountLoading, setStatisticsAmountLoading] = useState(false);
  const [autoImportLoading, setAutoImportLoading] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);

  let [table, setTable] = useState<Table>();
  const onTableRef = (tableRef: Table) => {
    setTable(tableRef);
    table = tableRef;
  };

  const [modal, modalHolder] = Modal.useModal();

  const businessTypeObj: JSONObject = {
    PROJECT: '工程类',
    RETAIL: '零售类',
    OTHER: '其它类型'
  };

  const expandedRowRender = ({ id }: JSONObject) => {
    return (
      <ImportDetailSubTable 
        applyType={applyType}
        orderId={id} 
        subTableArr={subTableArr} 
        childTableIdsRef={childTableIdsRef}
      />
    );
  };

  const handleAutoImport = () => {
    const parentTableIds: number[] = table?.getSelectedRowKeys() ?? [];
    const childTableIds = childTableIdsRef.current;
   
    let ids: number[] = [];
    let lineIds: number[] = [];

    if (applyType === ApplyType.ADVANCE) {
      ids = [];
      lineIds = parentTableIds;
    } else {
      ids = parentTableIds;
      lineIds = childTableIds;
    }

    setAutoImportLoading(true);

    Http.post(`/request/${baseFormId}/autoImportLine`, {
      ids,
      lineIds
    })
      .then(data => {
        setAllModuleData(data);
        onClose();
        Message.success('引入成功');
      })
      .finally(() => {
        setAutoImportLoading(false);
      });
  };

  const getValidRowData = (importRowData: JSONObject[]) => {
    const validDataArr: JSONObject[] = [];

    for (let i = 0; i < importRowData.length; i++) {
      const currentApplyQuantity = importRowData[i].applyQuantity;
      const currentApplyAmount = importRowData[i].applyAmount;

      if (currentApplyQuantity === 0 || currentApplyQuantity) {
        validDataArr.push(importRowData[i]);
      };
      if (currentApplyAmount === 0 || currentApplyAmount) {
        validDataArr.push(importRowData[i]);
      };
    };

    return validDataArr;
  };

  const getCurrentValidImportRowData = () => {
    let currentImportRowData: JSONObject[] = [];

    switch (applyType) {
      case ApplyType.RETAIL:
        currentImportRowData = subTableArr.current;
        break;

      case ApplyType.OTHER:
        currentImportRowData = subTableArr.current;
        break;

      case ApplyType.ADVANCE:
        currentImportRowData = advanceTableRef.current;
        break;

      case ApplyType.PROGRESS:
        currentImportRowData = subTableArr.current;
        break;

      case ApplyType.SETTLE:
        currentImportRowData = subTableArr.current;
        break;

      case ApplyType.MAIN_ASSISTANT:
        currentImportRowData = subTableArr.current;
        break;

      default:
        break;
    }

    return getValidRowData(currentImportRowData);
  };

  const handleSave = (importRowData: JSONObject[]) => {
    const validDataArr: JSONObject[] = getValidRowData(importRowData);

    if (validDataArr.length === 0) {
      Message.warn('请输入提交申请数量');
      return;
    };
  
    setSaveLoading(true);

    Http.post(`/request/${baseFormId}/importLine`, validDataArr)
      .then(data => {
        setAllModuleData(data);
        Message.success('操作成功');
        onClose();
      })
      .finally(() => {
        setSaveLoading(false);
      })
  };

  const handleSaveSure = () => {
    switch (applyType) {
      case ApplyType.RETAIL:
        handleSave(subTableArr.current);
        break;
      case ApplyType.OTHER:
        handleSave(subTableArr.current);
        break;
      case ApplyType.ADVANCE:
        handleSave(advanceTableRef.current);
        break;
      case ApplyType.PROGRESS:
        handleSave(subTableArr.current);
        break;
      case ApplyType.SETTLE:
        handleSave(subTableArr.current);
        break;
      case ApplyType.MAIN_ASSISTANT:
        handleSave(subTableArr.current);
        break;
      default:
        break;
    }
  };

  const dateArrToString = (queryParam: JSONObject) => {
    const { 
      orderDateFromTo, 
      signDateFromTo  
    } = queryParam;

    if (orderDateFromTo) {
      queryParam.orderDateFromTo = orderDateFromTo.join();
    }

    if (signDateFromTo) {
      queryParam.signDateFromTo = signDateFromTo.join(); 
    }
  };

  const getStatisticsAmount = () => {
    const parentTableIds: number[] = table?.getSelectedRowKeys() ?? [];
    const childTableIds = childTableIdsRef.current;

    if (
      parentTableIds.length === 0 &&
      childTableIds.length === 0 
    ) {
      Message.warn('请至少勾选一条数据');
      return
    }

    setStatisticsAmountLoading(true);

    Http.post(`/request/${baseFormId}/autoImportLineTip`, {
      ids: parentTableIds,
      lineIds: childTableIds,
    })
      .then((data) => {
        const { totalWithTaxAmount } = data ?? {};
        modal.open({
          width: 400,
          content: (_1, reject) => (
            <StatisticsAmount
              amount={totalWithTaxAmount}
              onClose={reject}
            />
          )
        })
      })
      .finally(() => {
        setStatisticsAmountLoading(false);
      })
  };

  const getRemindPoints = (
    isAutoImport: boolean, 
    handleNext: () => void
  ) => {
 
    const tableQueryParams: JSONObject = table?.getQueryPanelParams() ?? {};
    dateArrToString(tableQueryParams);

    const otherParams: JSONObject = {};
    
    if (isAutoImport) {
      const parentTableIds: number[] = table?.getSelectedRowKeys() ?? [];
      const childTableIds = childTableIdsRef.current;
  
      if (parentTableIds.length === 0) {
        Message.warn('请至少勾选一条数据');
        return;
      }

      const autoParam = {
        ids: parentTableIds,
        lineIds: childTableIds
      };
      
      otherParams.autoParam = autoParam;
    } else {
      otherParams.param = getCurrentValidImportRowData();
    }

    Http.post(`/request/${baseFormId}/importLineTipPoint`, {
      queryParam: tableQueryParams,
      ...otherParams
    })
      .then(data => {

        const {
          needTip,
          strongControl,
          tipContent
        } = data;

        needTip
          ? modal.open({
              width: 500,
              content: (_1, reject) => (
                <ImportDetailTips
                  isAllowSubmit={!strongControl}
                  tipContent={tipContent}
                  handleNext={handleNext}
                  onClose={reject}
                />
              )
            })
          : handleNext();
      })
  };

  const handleRemainQuantityChange = useCallback((applyQuantity: any, row: JSONObject) => {
    const remainApQuantity = row.remainApQuantity;
    const isRemainQuantityNegative = remainApQuantity < 0 ;
    const maxRemainQuantity = isRemainQuantityNegative 
      ? (remainApQuantity) * -1 
      : remainApQuantity;

    if (isRemainQuantityNegative) {
      if (applyQuantity > 0 || applyQuantity < remainApQuantity) {
        Message.warn('“提交申请数量” 不能大于 “未开数量”')
        return;
      }
    } else {
      if (applyQuantity > maxRemainQuantity) {
        Message.warn('“提交申请数量” 不能大于 “未开数量”')
        return;
      };
    }

    row.applyQuantity = applyQuantity;
    row.lineId = row.id;

    const inputedRowData: JSONObject[] = advanceTableRef.current; 
    const currentRowIndex = inputedRowData.findIndex(
      (_row: JSONObject) => _row.id === row.id
    )
    
    if (currentRowIndex === -1) {
      inputedRowData.push(row);
    } else {
      inputedRowData[currentRowIndex] = row;
    }
  }, []);
 
  const contractDetailColumns: ColumnProps[] = [
    { key: 'goodsName', title: '商品描述', width: 180, fixed: 'left' },
    { key: 'goodsNo', title: '物料编码', width: 180},
    { key: 'spec', title: '规格型号', width: 150 },
    { key: 'unit', title: '单位', width: 80, align: 'center' },
    { key: 'invoicedAmount', title: '已开票金额', width: 170 },
    { key: 'remainAmount', title: '未开票金额', width: 170 },
    { key: 'quantity', title: '合同数量', width: 170 },
    { key: 'invoicedApQuantity', title: '预付款已开数量', width: 170 },
    { key: 'remainApQuantity', title: '预付款未开数量', width: 170 },
    { key: '_applyQuantity', title: '待开数量', width: 170,
      render: (_applyQuantity, row: JSONObject) => {
        const maxRemainQuantity = 
          (row.remainApQuantity < 0) 
            ? (row.remainApQuantity) * -1 
            : row.remainApQuantity

        return (
          <InputNumber
            style={{ width: 130 }} 
            max={maxRemainQuantity}
            onChange={(event) => handleRemainQuantityChange(event, row)}
          />
        )
      }
    },
    { key: 'withTaxPrice', title: '含税单价', width: 170 },
    { key: 'withTaxAmount', title: '预付款含税金额', width: 170 },
    { key: 'taxRate', title: '税率', width: 120, align: 'center' },
    { key: 'largeCategory', title: '大类', width: 130 },
    { key: 'mediumCategory', title: '中类', width: 130 },
    { key: 'smallCategory', title: '小类', width: 130 }
  ];
  
  const billcolumns: ColumnProps[] = [
    { key: 'orderNo', title: '单据号', width: 180, fixed: 'left' },
    { key: 'salerName', title: '业务员', width: 140 },
    { key: 'orderDate', title: '单据日期', width: 140 },
    { key: 'customerName', title: '客户名称', width: 280 },
    { key: 'sourceSystem', title: '来源系统', width: 130, align: 'center' },
    { key: 'mesNo', title: '橱衣柜订单识别码', width: 160 },
    { key: 'sbuName', title: 'SBU 名称', width: 140 },
    { key: 'withTaxAmount', title: '总金额', width: 140, format: 'amount' },
    { key: 'invoicedAmount', title: '已开发票金额', width: 140, format: 'amount' },
    { key: 'remainAmount', title: '未开发票金额', width: 140, format: 'amount' },
    { key: 'contractNo', title: '项目合同编码', width: 180, align: 'center' },
    { key: 'mesNo', title: 'MES 订单号码', width: 140 },
    { key: 'custPoNo', title: '客户 PO 单据号', width: 140 },
    { key: 'businessType', title: '业务类型', width: 140, align: 'center',
      render: (businessType: string) => businessTypeObj[businessType]
    },
    { key: 'orderTypeDesc', title: '订单类型', width: 140, align: 'center' },
    { key: 'sbuCode', title: 'SBU 代码', width: 140 },
    { key: 'statusCode', title: '更新状态', width: 120, align: 'center', fixed: 'right',
      render: (statusCode: string) => statusCode === '2' ? '已更新' : '未更新'
    }
  ];

  const billTable =  
    <Table
      onTableRef={onTableRef}
      url={`/request/${baseFormId}/queryOuputOrder`}
      columns={billcolumns}
      isExpandAllItem={true}
      selectedRowKeys={[]} 
      scroll={{ y: window.innerHeight - 230 }}
      expandable={{ expandedRowRender }}
      pagination={false}
    >
      <Item name="orderDateFromTo">
        <RangePicker placeholder={['单据日期从', '单据日期至']} inputReadOnly/>
      </Item>
      <Item name="orderType">
        <Lookup placeholder="订单类型" lookupType="OUTPUT_ORDER_TYPE" />
      </Item>
      <Item name="orderNo">
        <Input placeholder="单据号" />
      </Item>
      <Item name="custPoNo">
        <Input placeholder="客户 PO 单据号" />
      </Item>
      <Item name="contractNo">
        <Input placeholder="合同编号" />
      </Item>
      <Item name="salerName">
        <Input placeholder="业务员" />
      </Item>
      <Item name="mesNo">
        <Input placeholder="橱衣柜订单识别码" />
      </Item>
      <Item name="customerName">
        <Input placeholder="客户名称" />
      </Item>
    </Table>

  const contractDetailTable = 
    <Table
      onTableRef={onTableRef} 
      url={`/request/${baseFormId}/queryContractLine`}
      columns={contractDetailColumns}
      pagination={false}
      selectedRowKeys={[]}
    >
      <Item name="key">
        <Input placeholder="合同名称/编号/签署主体" />
      </Item>
      <Item name="contractAttr">
        <Lookup lookupType="CONTRACT_ATTR" placeholder="合同属性" />
      </Item>
      <Item name="signDateFromTo">
        <RangePicker placeholder={['签署日期从', '签署日期至']} format="YYYYMMDD" inputReadOnly/>
      </Item>
      <Item></Item>
    </Table>;

  const tableObj: TableProps = {
    [ApplyType.RETAIL]: {
      table: billTable
    },
    [ApplyType.OTHER]: {
      table: billTable
    },
    [ApplyType.ADVANCE]: {
      table: contractDetailTable
    },
    [ApplyType.PROGRESS]: {
      table: billTable
    },
    [ApplyType.SETTLE]: {
      table: billTable
    },
    [ApplyType.MAIN_ASSISTANT]: {
      table: billTable
    }
  };

  return (
    <Card 
      title="引入明细" 
      style={{ paddingRight: 50 }}
      actions={
        <Space>
          {(applyType !== ApplyType.ADVANCE) && (
            <Button 
              type="primary"
              loading={statisticsAmountLoading}
              onClick={getStatisticsAmount}
            >
              统计勾选金额
            </Button>
          )}
          <Button 
            type="primary"
            loading={autoImportLoading}
            onClick={() => getRemindPoints(true, handleAutoImport)}
          >
            自动引入
          </Button>
          <Button 
            type="primary"
            loading={saveLoading}
            onClick={() => getRemindPoints(false, handleSaveSure)}
          >
            保存
          </Button>
        </Space>
      }
    > 
    {modalHolder}
    {tableObj[applyType].table}
    </Card>
  );
});

export default ImportDetail;
