import React, { FC, Fragment, useEffect, useState } from "react";
import { Card, Lookup, Page, Table, LinkText, Download } from "@comps";
import { Form, DatePicker, Input, Button, Space, Select, Popconfirm } from "antd"
import { ColumnProps } from '@comps/types';
import { Context, Http, Message } from "@utils";

const { Item } = Form
const { RangePicker } = DatePicker
const { Option } = Select

const Publish: FC = (props: any) => {
  const [period, setPeriod] = useState<string[]>();
  let [table, setTable] = useState<Table>();

  useEffect(() => {
    table?.refresh()
  }, [props.location])

  const onTableRef = (tableRef: Table) => {
    table = tableRef;
    setTable(tableRef);
  }

  const addTaxKnowledge = (id?: number, row?: JSONObject) => {
    let title = '新增知识查阅'
    if (id !== undefined) {
      title = '编辑知识查阅'
      Context.route(`/taxKnowledge/publish/operate/${id}/${row?.status}`)
    } else {
      Context.route(`/taxKnowledge/publish/operate`)
    }

  }

  const periodChange = (date: any, dateString: any) => {
    setPeriod(dateString)
  }

  const resetEmptyFileds = (): JSONObject => {
    setPeriod(["", ""])
    return {
      releaseDateStart: "",
      releaseDateEnd: ""
    }
  }

  const deleteKnowledge = async (id: any) => {
    await Http.del(`/knowledge/${id}`);
    Message.ok();
    table?.refresh()
  }

  const publicKnowledge = async (id: any) => {
    await Http.post(`/knowledge/release/${id}`);
    Message.ok();
    table?.refresh()
  }

  const pushKnowledge = async (id: any) => {
    await Http.post(`/knowledge/push/${id}`);
    Message.ok();
    table?.refresh()
  }

  const columns: ColumnProps[] = [
    {
      title: "发布状态", key: "status", width: 120,
      render: (value) => {
        switch (value) {
          case 0:
            return "草稿"
          case 1:
            return "已发布"
          default:
            break;
        }
      }
    },
    {
      title: "标题", key: "title", width: 520, render: (id: number, row: JSONObject) => {
        return (
          <LinkText onClick={() => { Context.route(`/taxKnowledge/publish/operate/${row.id}/${row?.status}`) }} label={row.title} />
        )
      }
    },
    { title: "文号", key: "proof", width: 120 },
    { title: "发文日期", key: "releaseDate", width: 120 },
    { title: "所属税种", key: "taxTypeName", width: 120 },
    {
      title: "文件类型", key: "fileType", width: 120,
      render: (value) => {
        switch (value) {
          case 1:
            return "政策法规"
          case 2:
            return "政策解读"
          case 3:
            return "稽查案例"
          case 4:
            return "稽查应对措施"
          default:
            break;
        }
      }
    },
    { title: "优惠类型", key: "discountsTypeName", width: 120 },
    { title: "发文机构", key: "publishOrgName", width: 120 },
    {
      title: "有效性", key: "validity", width: 120,
      render: (value) => {
        switch (value) {
          case 1:
            return "全文有效"
          case 2:
            return "条款失效"
          case 3:
            return "全文废止"
          default:
            break;
        }
      }
    },
    {
      key: 'id', title: '操作', width: 150,
      render: (id: number, row: JSONObject) => {
        return (
          <Space size={'middle'}>
            <LinkText label='修改' onClick={() => { addTaxKnowledge(id, row) }} />
            {row.status === 0 ? <Fragment>
              <LinkText label='发布' onClick={() => { publicKnowledge(id) }} />
              <Popconfirm title='您确认要删除吗?' onConfirm={() => deleteKnowledge(id)}>
                <LinkText label='删除' />
              </Popconfirm>
            </Fragment>
              : <Popconfirm title='您确认要推OA吗?' onConfirm={() => pushKnowledge(id)}>
                <LinkText label='推OA' />
              </Popconfirm>

            }
          </Space>
        )
      }
    },
    {
      key: 'id', title: '下载', width: 150, align: 'center',
      render: (id: number, row: JSONObject) => {
        return (
          <Space size={'middle'}>
            <Download
              label='正文'
              url={`/knowledge/downloadContent/${id}`}
              type='link'
            />
            <i style={{ color: '#4e97df' }}>|</i>
            <Download
              label='附件'
              url={`/knowledge/downloadZip/${id}`}
              type='link'
            />
          </Space>
        )
      }
    },
  ]

  return (
    <Page>
      <Card title="知识发布"
        actions={
          <Space>
            <Button type="primary" onClick={() => { addTaxKnowledge() }}>新增</Button>
          </Space>
        }>
        <Table
          url="/knowledge"
          columns={columns}
          onTableRef={onTableRef}
          formAdditionalParameters={{
            releaseDateStart: period ? period[0] : "",
            releaseDateEnd: period ? period[1] : "",

          }}
          resetEmptyFileds={resetEmptyFileds}>
          <Item name="taxType">
            <Lookup lookupType="TAX_TYPE" placeholder="税种"></Lookup>
          </Item>
          <Item name="period">
            <RangePicker format="YYYYMMDD" placeholder={["发文日期起", "发文日期止"]} onChange={periodChange} inputReadOnly></RangePicker>
          </Item>
          <Item name="status">
            <Select placeholder="发布状态" allowClear>
              <Option value={0}>草稿</Option>
              <Option value={1}>已发布</Option>
            </Select>
          </Item>
          <Item name="publishOrg">
            <Lookup lookupType="FWJG" placeholder="发文机构"></Lookup>
          </Item>
          <Item name="validity">
            <Select placeholder="有效性" allowClear>
              <Option value={1}>全文有效</Option>
              <Option value={2}>条款失效</Option>
              <Option value={3}>全文废止</Option>
            </Select>
          </Item>
          <Item name="title">
            <Input placeholder="标题" />
          </Item>
          <Item name="proof">
            <Input placeholder="文号" />
          </Item>
          <Item name="fileType">
            <Select placeholder="文件类型" allowClear>
              <Option value={1}>政策法规</Option>
              <Option value={2}>政策解读</Option>
              <Option value={3}>稽查案例</Option>
              <Option value={4}>稽查应对措施</Option>
            </Select>
          </Item>
          <Item name="discountsType">
            <Lookup lookupType="YHLX" placeholder="优惠类型"></Lookup>
          </Item>
        </Table>
      </Card>
    </Page>
  )
}

export default Publish
