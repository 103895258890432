import { InfoCircleFilled } from "@ant-design/icons";
import { Table } from "@comps";
import { ColumnProps } from "@comps/types";
import { Context, Env } from "@utils";
import { Card as AdCard, Alert, Button, Col, Row, Upload, message } from "antd";
import { RcFile } from "antd/lib/upload";
import _ from "lodash";
import * as React from "react";
import { useRef, useState } from "react";

interface Props {
  // ref: any;
  onClose: Function;
  getTableData: Function;
}
const DetailModal: React.FC<Props> = (props, ref) => {
  const [dataSource, setDataSource] = useState<JSONObject[]>([]);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const childRef: any = useRef();
  const [uploadLoading, setUploadLoading] = useState(false);
  const [fileList, setFileList] = useState<RcFile[]>([]);
  // useImperativeHandle(ref, () => ({
  //   isDetailModalShow() {
  //     setVisible(!visible);
  //   },
  // }));

  const columns: ColumnProps[] = [
    {
      key: "",
      title: "序号",
      width: 70,
      render: (text, record, index) => index + 1,
    },
    {
      key: "projectName",
      dataIndex: "projectName",
      title: "项目名称",
      align: "left",
    },
    {
      key: "spec",
      dataIndex: "spec",
      title: "规格型号",
      align: "left",
    },
    { key: "unit", dataIndex: "unit", title: "单位", align: "left" },
    { key: "quantity", dataIndex: "quantity", title: "数量", align: "right" },
    {
      key: "price",
      dataIndex: "price",
      title: "单价",
      align: "right",
    },
    {
      key: "amount",
      dataIndex: "amount",
      title: "金额",
      align: "right",
    },
    {
      key: "taxRate",
      dataIndex: "taxRate",
      title: "税率/征收率",
      align: "right",
    },
    {
      key: "taxAmount",
      dataIndex: "taxAmount",
      title: "税额",
      align: "right",
    },
    // {
    //     key: "action",
    //     dataIndex: "taxDate",
    //     title: "结果",
    //     align: "left",
    // },
  ];
  const uploadProps = {
    name: "file",
    accept: ".xlsx,.xls",
    action: `${Env.getBaseUrl()}/input/gx/importFromExcel`,
    headers: {
      Authorization: Context.getToken(),
    },
    maxCount: 1,
    onChange(info: any) {
      setUploadLoading(true);
      // setFileList([...info.fileList]);
      if (info.file.status !== "uploading") {
        console.log("触发2", info.file, info.fileList);
      }
      if (info.file.status === "done") {
        let file = info.file;
        if (file.response.code !== "200") {
          message.error(file.response.msg);
          // console.log([...info.fileList]);
        } else {
          message.success(`上传成功`);
          setFileList([...info.fileList]);
          const formData = new FormData();
          fileList.forEach((file: RcFile) => {
            formData.append("file", file as RcFile);
          });
          fetch(
            `${Env.getBaseUrl()}/tax/orders/importDetail?accessToken=${Context.getToken()}`,
            {
              method: "POST",
              body: formData,
            }
          )
            .then((res) => {
              return res.json();
            })
            .then((res) => {
              if (res.code === "200") {
                res.data.forEach((value: any) => {
                  value.taxAmount = _.round(
                    parseFloat(value.amount) * value.taxRate,
                    2
                  );
                });
                childRef.current.setDataSource(res.data);
                setDataSource(res.data);
              } else {
                message.error(res.msg);
              }
            });
        }
        setUploadLoading(false);
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} 上传失败`);
        setUploadLoading(false);
      } else if (info.file.status === "removed") {
        setFileList(info.fileList);
        setUploadLoading(false);
      }
    },
  };

  return (
    <>
      {/* <Drawer
        title="明细导入"
        placement="right"
        closable={false}
        onClose={() => setVisible(false)}
        visible={visible}
        width={1000}
        footer={
          <div style={{ textAlign: "left", marginTop: "10px" }}>
            <Button type="primary" style={{ marginRight: "10px" }}>
              确定
            </Button>
            <Button>取消</Button>
          </div>
        }
      > */}
      <AdCard loading={loading} style={{ marginTop: "40px" }}>
        <Alert
          message={
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  color: "#4285f4",
                  fontSize: "20px",
                  marginRight: "10px",
                }}
              >
                <InfoCircleFilled />
              </div>
              <div>
                请先下载模板
                <a href="/excelTemp/房产税从租合同导入模板.xlsx">
                  《发票开具-项目信息批量导入模板》
                </a>
                ，按照模板填写后上传。
              </div>
            </div>
          }
          type="info"
          style={{
            marginBottom: "16px",
            padding: "16px 24px",
            borderRadius: "6px",
            backgroundColor: "#d2e7fb",
          }}
        />
        <Row
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: "16px",
          }}
        >
          <Col span={12}>
            <Upload
              beforeUpload={(file, fileList) => {
                setFileList([...fileList]);
                return true;
              }}
              {...uploadProps}
              fileList={fileList}
            >
              <Button loading={uploadLoading}>选择文件</Button>
            </Upload>
          </Col>
        </Row>

        <Table
          columns={columns}
          dataSource={dataSource}
          //   rowKey={record=>record.invNo}
          pagination={{
            showQuickJumper: true,
            showSizeChanger: true,
            // showTotal: (total) => (`共 ${total} 条`)
          }}
          ref={childRef}
        />
        <div style={{ textAlign: "center", marginTop: "32px" }}>
          <Button
            type="primary"
            style={{ marginRight: "10px" }}
            onClick={() => {
              console.log(dataSource);

              if (dataSource.length !== 0) {
                dataSource.map((value: any) => {
                  value.invoiceGoodsName = value.projectName;
                  value.withTaxPrice = _.round(
                    parseFloat(value.price) * (1 + parseFloat(value.taxRate)),
                    13
                  );
                  value.withTaxAmount = _.round(
                    parseFloat(value.amount) + parseFloat(value.taxAmount),
                    2
                  );
                });
                props.getTableData(dataSource);
                props.onClose();
              } else {
                message.warning("请导入数据");
              }
            }}
          >
            确定
          </Button>
          <Button
            onClick={() => {
              props.onClose();
            }}
          >
            取消
          </Button>
        </div>
      </AdCard>
      {/* </Drawer> */}
    </>
  );
};

export default DetailModal;
