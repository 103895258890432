import {
  DownOutlined,
  ExclamationCircleFilled,
  ProfileOutlined,
  QuestionCircleOutlined,
  UpOutlined,
} from "@ant-design/icons";
import { Card, Modal, Page } from "@comps";
import { Context, Http } from "@utils";
import {
  Card as AdCard,
  AutoComplete,
  Button,
  Checkbox,
  Col,
  Divider,
  Form,
  Input,
  Row,
  Select,
  Spin,
  Tooltip,
  message,
} from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import * as React from "react";
import { useState } from "react";
import { useLocation, useParams } from "react-router";
import { history } from "routes";
import ConstructionService from "./component/constructionService";
import FreightTransport from "./component/freightTransport";
import RealEstate from "./component/realEstate";
import RealEstateSales from "./component/realEstateSales";
import TableFormList from "./component/tableFormList";
import InvoicePreview from "./drawer/InvoicePreview";
import NameModal from "./drawer/nameModal";

export interface Props {}
interface NObject {
  [key: string]: string | number | undefined | null | void;
}

const { Option } = Select;

const BlueInvoice: React.FC<Props> = () => {
  const [modal, modalHolder] = Modal.useModal();
  const [submitLoading, setSubmitLoading] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [expand, setExpand] = useState(false);
  const [remarkExpand, setRemarkExpand] = useState(true);
  const [agentExpand, setAgentExpand] = useState(true);
  const [form] = Form.useForm();
  const [totalAmount, setTotalAmount] = useState(0);
  const [totalMoney, setTotalMoney] = useState(0);
  const { type, businessType, requestNo } = useParams<any>();
  const [businessCode, setbusinessCode] = useState("");
  const [currentKey, setCurrentKey] = useState<string | number>();
  const [invoiceTypeEnum, setInvoiceTypeEnum] = useState<NObject>({});
  const [businessTypeEnum, setBusinessTypeEnum] = useState<NObject>({});
  const [searchValue, setSearchValue] = useState<string | undefined>(undefined);
  const [options, setOptions] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const [isANaturalPerson, setIsANaturalPerson] = useState<Boolean>(false);
  const location = useLocation<any>();
  const { state } = location;
  const key = state ? state.key : "";
  const invoiceId = state ? state.id : "";

  const getTotalInfo = (totalAmount: any, totalMoney: any) => {
    console.log(totalAmount, totalMoney);
    setTotalAmount(totalAmount);
    setTotalMoney(totalMoney);
  };

  React.useEffect(() => {
    (async () => {
      let res = await Http.get("/org/orgs/invoiceTitle");
      const { orgName, taxNo, bankName, accountNo, address, tel } = res;
      if ((key ?? "") !== "" && key !== currentKey) {
        form.resetFields();
        setOptions([]);
        setCurrentKey(key);
        form.setFieldsValue({
          lines: [{}],
        });
      }
      form.setFieldsValue({
        sellerName: orgName,
        sellerTaxNo: taxNo,
        sellerBankName: bankName,
        sellerBankAccount: accountNo,
        sellerAddress: address,
        sellerTel: tel,
        naturalPersonFlag: false,
        invoiceType: type,
        businessType: businessType,
      });
      setbusinessCode(businessType);
    })();
  }, [key]);

  React.useEffect(() => {
    (async () => {
      if (requestNo) {
        let [invoice, invoiceLines] = await Promise.all([
          Http.get(`/tax/outputInvoices/${requestNo}`),
          Http.get(
            `/tax/outputInvoices/${requestNo}/lines?pageSize=500&pageNum=1`
          ),
        ]);
        form.setFieldsValue({
          ...invoice,
          lines: invoiceLines.content,
        });
      }
    })();
  }, [requestNo]);

  //发票类型枚举
  React.useEffect(() => {
    (async () => {
      const kvEnum: NObject = {};
      await Http.get("/sys/lookups/kv", {
        lookupType: "TAX_INVOICE_TYPE",
      }).then((result) => {
        result.map((item: any) => {
          kvEnum[item.k] = item.v;
        });
      });
      setInvoiceTypeEnum(kvEnum);
    })();
  }, []);

  //特定类型枚举
  React.useEffect(() => {
    (async () => {
      const kvEnum: NObject = {};
      await Http.get("/sys/lookups/kv", {
        lookupType: "INVOICE_BUSINESS_TYPE",
      }).then((result) => {
        result.map((item: any) => {
          kvEnum[item.k] = item.v;
        });
      });
      setBusinessTypeEnum(kvEnum);
    })();
  }, []);

  const handleCustomerName = (row: any) => {
    console.log(row, "=====row====");
    const {
      customerName,
      taxNo,
      address,
      tel,
      bankName,
      bankAccount,
      customerCode,
    } = row;
    const words = bankAccount?.split(" ");
    form.setFieldsValue({
      buyerName: customerName,
      buyerTaxNo: taxNo,
      buyerAddress: address,
      buyerTel: tel,
      buyerBankName: bankName,
      buyerBankAccount: words.length ? words[1] : "",
      customerBankAccount: bankAccount,
      customerCode,
    });
  };

  const handleSubmit = (onlySave: Boolean) => {
    form
      .validateFields()
      .then((res) => {
        !onlySave && setSubmitLoading(true);
        onlySave && setSaveLoading(true);
        if (invoiceId !== "") {
          res["invoiceId"] = invoiceId;
        }
        const { lines } = res;
        if (lines?.length > 0) {
          lines.forEach((res: any, index: number) => {
            lines[index]["lineNum"] = index + 1;
            if (lines[index]["taxRate"]) {
              lines[index]["taxRate"] = lines[index]["taxRate"] * 100;
            }
          });
        }
        res["invoiceType"] = Number(form.getFieldValue("invoiceType"));
        res["businessType"] = form.getFieldValue("businessType");
        res["statusCode"] = 1;
        if (businessType == "1") {
          res["businessInfo"]["GZFW_FSD"] =
            res["businessInfo"]["GZFW_FSD"].join("");
        }
        if (businessType == "2") {
          res["businessInfo"]["HWYS_QYD"] =
            res["businessInfo"]["HWYS_QYD"].join("");
          res["businessInfo"]["HWYS_DDD"] =
            res["businessInfo"]["HWYS_DDD"].join("");
        }
        if (businessType == "3") {
          res["businessInfo"]["BDCXS_DQDZ"] =
            res["businessInfo"]["BDCXS_DQDZ"].join("");
        }
        if (businessType == "4") {
          res["businessInfo"]["BDC_SF"] =
            res["businessInfo"]["BDC_SF"]?.join("");
          res["businessInfo"]["BDC_QSRQ"] =
            res["businessInfo"]["BDC_QZ"][0].format("YYYY-MM-DD");
          res["businessInfo"]["BDC_JZRQ"] =
            res["businessInfo"]["BDC_QZ"][1].format("YYYY-MM-DD");
          delete res["businessInfo"]["BDC_QZ"];
        }
        console.log(res);

        Http.post("/tax/orders/save", res)
          .then((res: any) => {
            if (onlySave) {
              message.success("保存成功");
              history.replace("/tax/outputInvoice/wait", { params: res?.id });
              Context.closeTabByTitle("手工录入", "待开单据");
            } else {
              Http.post("/tax/orders/batchInvoice", { ids: [res?.id] }).then(
                (res) => {
                  message.success("开具成功");
                  history.replace("/tax/outputInvoice/wait", {
                    params: res,
                  });
                  Context.closeTabByTitle("手工录入", "待开单据");
                }
              );
            }
          })
          .finally(() => {
            !onlySave && setSubmitLoading(false);
            onlySave && setSaveLoading(false);
          });
      })
      .catch((err) => {
        message.warning("请完善录入信息");
        console.log(form.getFieldsValue());
        console.log(err, "========err=====");
      });
  };

  const handlePreView = async () => {
    await form
      .validateFields()
      .then(async (res) => {
        console.log(res, "======");
        const { lines } = res;
        if (lines?.length > 0) {
          lines.forEach((res: any, index: number) => {
            lines[index]["lineNum"] = index + 1;
            if (lines[index]["taxRate"]) {
              lines[index]["taxRate"] = lines[index]["taxRate"] * 100;
            }
          });
        }
        if (businessType == "1") {
          res["businessInfo"]["GZFW_FSD"] =
            res["businessInfo"]["GZFW_FSD"]?.join("");
        }
        if (businessType == "2") {
          res["businessInfo"]["HWYS_QYD"] =
            res["businessInfo"]["HWYS_QYD"]?.join("");
          res["businessInfo"]["HWYS_DDD"] =
            res["businessInfo"]["HWYS_DDD"]?.join("");
        }
        if (businessType == "3") {
          res["businessInfo"]["BDCXS_DQDZ"] =
            res["businessInfo"]["BDCXS_DQDZ"]?.join("");
        }
        if (businessType == "4") {
          res["businessInfo"]["BDC_SF"] =
            res["businessInfo"]["BDC_SF"]?.join("");
          res["businessInfo"]["BDC_QSRQ"] =
            res["businessInfo"]["BDC_QZ"][0].format("YYYY-MM-DD");
          res["businessInfo"]["BDC_JZRQ"] =
            res["businessInfo"]["BDC_QZ"][1].format("YYYY-MM-DD");
          delete res["businessInfo"]["BDC_QZ"];
        }
        //插入合计信息
        res.invoiceAmount = totalMoney;
        res.taxAmountTotal = totalAmount;
        res.invoiceTypeDesc = invoiceTypeEnum[type];
        res.businessTypeDesc = businessTypeEnum[businessType];
        modal.open({
          width: 1200,
          content(resolve, reject) {
            return <InvoicePreview onClose={reject} invoice={res} />;
          },
        });
      })
      .catch((err) => {
        console.log(err);
        message.warning("请完善录入信息");
      });
  };
  const onNaturePersonalChange = (e: CheckboxChangeEvent) => {
    console.log(`checked = ${e.target.checked}`);
    const { checked } = e.target;
    setIsANaturalPerson(checked as Boolean);
    if (checked) {
      modal.open({
        width: 600,
        content(resolve, reject) {
          return (
            <div style={{ padding: "32px" }}>
              <span style={{ fontSize: "16px" }}>
                <ExclamationCircleFilled
                  style={{ color: "#1890ff", marginRight: "5px" }}
                />
                <b>请确认</b>
              </span>
              <div style={{ padding: "16px" }}>
                <p style={{ margin: 0 }}>除特定业务外的普通发票：</p>
                <p style={{ margin: 0 }}>
                  1、如受票方（发票抬头）为自然人，请根据实际需要提供姓名或姓。如您的姓名为张某某。可在名称栏次填写：张某某、张先生或张女士；
                </p>
                <p style={{ margin: 0 }}>
                  2、如受票方（发票抬头）为自然人，并要求能将发票归集在个人票夹中展示，需要提供姓名及身份证号（自然人纳税人识别号）；
                </p>
                <p style={{ margin: 0 }}>
                  3、如受票方（发票抬头）为个体工商户，需提供社会统一信用代码或纳税人识别号，请勿勾选此标识。
                </p>
              </div>
              <div style={{ textAlign: "right" }}>
                <Button type="primary" onClick={resolve}>
                  知道了
                </Button>
              </div>
            </div>
          );
        },
      });
    }
  };
  //特定信息编辑更新
  const renderSpecialInfo = React.useCallback(
    () =>
      businessType == "1" ? (
        <ConstructionService form={form} />
      ) : businessType == "2" ? (
        <FreightTransport form={form} />
      ) : businessType == "3" ? (
        <RealEstateSales form={form} />
      ) : businessType == "4" ? (
        <RealEstate form={form} />
      ) : null,
    [businessType]
  );
  const handleInputChange = (value: string) => {
    setSearchValue(value);
  };

  React.useEffect(() => {
    let debounceTimer: NodeJS.Timeout;
    const debounceHandleInputChange = (value: string) => {
      setLoading(true);
      // 在这里执行您的处理逻辑
      simulateApiCall(value)
        .then((result) => {
          setOptions(result);
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error:", error);
          setLoading(false);
        });
    };

    debounceTimer = setTimeout(() => {
      debounceHandleInputChange(searchValue!);
    }, 500);

    return () => {
      clearTimeout(debounceTimer);
    };
  }, [searchValue]);

  const simulateApiCall = (value: string): Promise<string[]> => {
    return new Promise((resolve) => {
      // 在此处调用远程接口传递搜索关键字，并返回 Promise
      // 这里使用 setTimeout 模拟异步请求
      setTimeout(() => {
        Http.get(`/tax/orders/title?name=${value}`).then((res) => {
          console.log(res);
          if (res !== null) {
            let Options: any[] = res.map((value: any) => {
              return {
                label: value.name,
                key: value.taxId,
              };
            });
            resolve(Options);
          }
        });
      }, 0);
    });
  };

  const handleOptionClick = (value: string) => {
    console.log("value", value);
    options.forEach((item: any) => {
      if (item.label === value) {
        console.log(item.key);
        form.setFieldsValue({ buyerTaxNo: item.key });
      }
    });
  };

  const handleBlur = () => {};

  return (
    <Page>
      {modalHolder}
      <Card title="手工录入" showBackTag>
        <Form
          form={form}
          // onFinish={onFinish}
          initialValues={{
            naturalPersonFlag: false,
            lines: [{}],
          }}
        >
          <Row justify="space-around" gutter={16}>
            <Col span={12}>
              <AdCard
                title="购买方信息"
                extra={
                  <>
                    <Form.Item
                      style={{
                        position: "absolute",
                        left: "111px",
                        top: "13px",
                      }}
                      name="naturalPersonFlag"
                      valuePropName="checked"
                    >
                      <Checkbox onChange={onNaturePersonalChange}>
                        是否开票给自然人
                      </Checkbox>
                    </Form.Item>

                    {expand ? (
                      <a
                        style={{ fontSize: 12 }}
                        onClick={() => {
                          console.log(expand);
                          setExpand(!expand);
                        }}
                      >
                        <UpOutlined />
                        收起更多
                      </a>
                    ) : (
                      <a
                        style={{ fontSize: 12 }}
                        onClick={() => {
                          console.log(expand);

                          setExpand(!expand);
                        }}
                      >
                        <DownOutlined />
                        查看更多
                      </a>
                    )}
                  </>
                }
              >
                <Row>
                  <Col span={22}>
                    <Form.Item
                      name="buyerName"
                      label="名称"
                      rules={[{ required: true, message: "请输入名称" }]}
                    >
                      {/* <Select
                        allowClear
                        placeholder="请选择选项"
                        value={searchValue}
                        // onChange={handleInputChange}
                        optionLabelProp="label"
                        showSearch
                        onBlur={handleBlur}
                        notFoundContent={loading ? <Spin size="small" /> : null}
                        filterOption={false}
                        onSelect={handleOptionClick}
                        onSearch={handleInputChange}
                        style={{ width: "100%" }}
                      >
                        {options.map((option: any) => (
                          <Option
                            key={option.taxId}
                            value={option.label}
                            label={option.label}
                          >
                            {option.label}
                          </Option>
                        ))}
                      </Select> */}
                      <AutoComplete
                        allowClear
                        placeholder="请选择选项"
                        onSelect={handleOptionClick}
                        onSearch={handleInputChange}
                        style={{ width: "100%" }}
                        notFoundContent={loading ? <Spin size="small" /> : null}
                        filterOption={false}
                        options={options.map((option: any) => ({
                          label: option.label,
                          value: option.label,
                          key: option.taxId,
                        }))}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={1}>
                    <Button
                      style={{ marginLeft: "15px" }}
                      type="primary"
                      ghost
                      onClick={() => {
                        modal.open({
                          width: 1500,
                          content(resolve, reject) {
                            return (
                              <NameModal
                                onClose={reject}
                                handleChoose={handleCustomerName}
                              />
                            );
                          },
                        });
                      }}
                      icon={<ProfileOutlined />}
                    />
                  </Col>
                </Row>
                <Form.Item
                  name="buyerTaxNo"
                  label="统一社会信用代码/纳税人识别号"
                  rules={[
                    {
                      required: isANaturalPerson ? false : true,
                      pattern: new RegExp(/^[A-Z\d]+$/),
                      message: "只能输入数字和大写字母",
                    },
                  ]}
                >
                  <Input placeholder="请输入" />
                </Form.Item>
                <Form.Item
                  name="buyerAddress"
                  label="购买方地址"
                  style={{ display: expand ? "" : "none" }}
                >
                  <Input placeholder="请输入" />
                </Form.Item>
                <Form.Item
                  name="buyerTel"
                  label="电话"
                  style={{ display: expand ? "" : "none" }}
                >
                  <Input placeholder="请输入" />
                </Form.Item>
                <Form.Item
                  name="buyerBankName"
                  label="购方开户银行"
                  style={{ display: expand ? "" : "none" }}
                >
                  <Input placeholder="请输入" />
                </Form.Item>
                <Form.Item
                  name="buyerBankAccount"
                  label="银行账号"
                  style={{ display: expand ? "" : "none" }}
                >
                  <Input placeholder="请输入" />
                </Form.Item>
              </AdCard>
            </Col>
            <Col span={12}>
              <AdCard
                title="销售方信息"
                extra={
                  <>
                    <QuestionCircleOutlined
                      style={{
                        position: "absolute",
                        left: "111px",
                        fontSize: "16px",
                        top: "22px",
                        color: "#1980FF",
                      }}
                    />
                    <Tooltip placement="top" title={""} trigger="click">
                      <span
                        style={{
                          position: "absolute",
                          left: "140px",
                          color: "#1980FF",
                          cursor: "pointer",
                        }}
                      >
                        查看当前可用授信额度
                      </span>
                    </Tooltip>
                    {expand ? (
                      <a
                        style={{ fontSize: 12 }}
                        onClick={() => {
                          setExpand(!expand);
                        }}
                      >
                        <UpOutlined />
                        收起更多
                      </a>
                    ) : (
                      <a
                        style={{ fontSize: 12 }}
                        onClick={() => {
                          setExpand(!expand);
                        }}
                      >
                        <DownOutlined />
                        查看更多
                      </a>
                    )}
                  </>
                }
              >
                <Form.Item name="sellerName" label="名称">
                  <Input placeholder="请输入" disabled />
                </Form.Item>
                <Form.Item
                  name="sellerTaxNo"
                  label="统一社会信用代码/纳税人识别号"
                >
                  <Input placeholder="请输入" disabled />
                </Form.Item>
                <Form.Item
                  name="sellerAddress"
                  label="销方地址"
                  style={{ display: expand ? "" : "none" }}
                >
                  <Input placeholder="请输入" />
                </Form.Item>
                <Form.Item
                  name="sellerTel"
                  label="电话"
                  style={{ display: expand ? "" : "none" }}
                >
                  <Input placeholder="请输入" disabled />
                </Form.Item>
                <Form.Item
                  name="sellerBankName"
                  label="销方开户银行"
                  style={{ display: expand ? "" : "none" }}
                >
                  <Input placeholder="请输入" />
                </Form.Item>
                <Form.Item
                  name="sellerBankAccount"
                  label="银行账号"
                  style={{ display: expand ? "" : "none" }}
                >
                  <Input placeholder="请输入" />
                </Form.Item>
              </AdCard>
            </Col>
          </Row>
          <TableFormList
            form={form}
            getTotalInfo={getTotalInfo}
            invoiceTypeEnum={invoiceTypeEnum}
            businessTypeEnum={businessTypeEnum}
          />
          <Divider />
          <Form layout="vertical" form={form}>
            <Row>
              <Col span={24}>
                <AdCard
                  title="备注信息"
                  extra={
                    <>
                      {remarkExpand ? (
                        <a
                          style={{ fontSize: 12 }}
                          onClick={() => {
                            setRemarkExpand(!remarkExpand);
                          }}
                        >
                          <UpOutlined />
                          收起更多
                        </a>
                      ) : (
                        <a
                          style={{ fontSize: 12 }}
                          onClick={() => {
                            setRemarkExpand(!remarkExpand);
                          }}
                        >
                          <DownOutlined />
                          查看更多
                        </a>
                      )}
                    </>
                  }
                >
                  <Row>
                    <Col span={20}>
                      <Form.Item name="remark" label="备注">
                        <Input.TextArea placeholder="请输入" />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col span={8}>
                      <Form.Item name="cashier" label="收款人">
                        <Input placeholder="请输入" />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item name="checker" label="复核人">
                        <Input placeholder="请输入" />
                      </Form.Item>
                    </Col>
                  </Row>
                  {/* <Row style={{ display: remarkExpand ? "" : "none" }}>
                    <Col span={10}>
                      <Form.Item name="invoiceTy4pe" label="选择场景模板">
                        <Select
                          showSearch
                          placeholder="Select a person"
                          optionFilterProp="children"
                          onChange={onBgChange}
                          onSearch={onBgSearch}
                          filterOption={(input: any, option: any) =>
                            (option?.label ?? "")
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                          options={[
                            {
                              value: "jack",
                              label: "Jack",
                            },
                            {
                              value: "lucy",
                              label: "Lucy",
                            },
                            {
                              value: "tom",
                              label: "Tom",
                            },
                          ]}
                        />
                      </Form.Item>
                    </Col>
                  </Row> */}
                </AdCard>
              </Col>
            </Row>
            {/* 根据不同业务类型进行渲染 */}
            <Row style={{ marginTop: "16px" }}>
              <Col span={24}>{renderSpecialInfo()}</Col>
            </Row>
            <Row style={{ marginTop: "16px" }}>
              <Col span={24}>
                <AdCard
                  title="经办人信息"
                  extra={
                    <>
                      {agentExpand ? (
                        <a
                          style={{ fontSize: 12 }}
                          onClick={() => {
                            setAgentExpand(!agentExpand);
                          }}
                        >
                          <UpOutlined />
                          收起更多
                        </a>
                      ) : (
                        <a
                          style={{ fontSize: 12 }}
                          onClick={() => {
                            setAgentExpand(!agentExpand);
                          }}
                        >
                          <DownOutlined />
                          查看更多
                        </a>
                      )}
                    </>
                  }
                >
                  <Row gutter={16}>
                    <Col
                      span={6}
                      style={{ display: agentExpand ? "" : "none" }}
                    >
                      <Form.Item name="operatorName" label="姓名">
                        <Input placeholder="请输入" />
                      </Form.Item>
                    </Col>
                    <Col
                      span={6}
                      style={{ display: agentExpand ? "" : "none" }}
                    >
                      <Form.Item name="operatorCuntry" label="国籍（地区）">
                        <Input placeholder="请输入" />
                      </Form.Item>
                    </Col>
                    <Col
                      span={6}
                      style={{ display: agentExpand ? "" : "none" }}
                    >
                      <Form.Item name="operatorCertificate" label="证件类型">
                        <Input placeholder="请输入" />
                      </Form.Item>
                    </Col>
                    <Col
                      span={6}
                      style={{ display: agentExpand ? "" : "none" }}
                    >
                      <Form.Item name="operatorIdCard" label="证件号码">
                        <Input placeholder="请输入" />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col
                      span={6}
                      style={{ display: agentExpand ? "" : "none" }}
                    >
                      <Form.Item
                        name="operatorAccount"
                        label="自然人纳税人识别号"
                      >
                        <Input placeholder="请输入" />
                      </Form.Item>
                    </Col>
                  </Row>
                </AdCard>
              </Col>
            </Row>
          </Form>
          <div style={{ position: "fixed", left: "50%", bottom: "20px" }}>
            <Button style={{ marginRight: "16px" }} onClick={handlePreView}>
              预览发票
            </Button>
            <Button
              loading={saveLoading}
              style={{ marginRight: "16px" }}
              onClick={() => handleSubmit(true)}
            >
              保存发票
            </Button>
            <Button
              loading={submitLoading}
              type="primary"
              onClick={() => handleSubmit(false)}
            >
              发票开具
            </Button>
          </div>
          {/* <Button type="primary" htmlType="submit">
            查询
          </Button> */}
        </Form>
      </Card>
    </Page>
  );
};

export default BlueInvoice;
