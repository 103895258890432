import React, { FC, Fragment, useState } from 'react'
import { Input, Button, Space, Popconfirm, Upload, Row } from 'antd'
import { Page, Card, Table, Modal, LinkText, PermissionButton, Download } from '@comps'
import { ColumnProps } from '@comps/types'
import { Context, Env, Http, Message } from '@utils'
import Add from './addOrEdit'

const List: FC = () => {
    const [uploadLoading, setUploadLoading] = React.useState<boolean>(false);
    const [modal, modalHolder] = Modal.useModal();
    let [table, setTable] = useState<Table>()

    const columns: ColumnProps[] = [
        { key: 'srcName', title: 'ERP商品名称', width: 300, fixed: 'left' },
        { key: 'srcSpec', title: 'ERP规格型号', width: 120, },
        { key: 'srcUnit', title: 'ERP单位', width: 80, },
        { key: 'newName', title: '商品名称', width: 300, },
        { key: 'newSpec', title: '规格型号', width: 120, },
        { key: 'newUnit', title: '单位', width: 80, },
        { key: 'conversionRate', title: '对换率', width: 80, },
        { key: 'customerName', title: '对碰客户名称', width: 250, },
        { key: 'goodsCode', title: '商品编码', width: 120, },
        {
            key: 'id',
            fixed: 'right',
            title: '操作',
            width: 100,
            render: (id, row) => (
                <Space>
                    <LinkText onClick={() => handleAddOrEdit(row)} label='修改' />
                    <Popconfirm title='您确认要删除吗?' onConfirm={() => handleDelete(id)}><LinkText label='删除' /></Popconfirm>
                </Space>
            )
        }
    ]

    const onTableRef = (tableRef: Table) => {
        table = tableRef
        setTable(tableRef)
    }

    async function handleAddOrEdit(goods?: JSONObject) {
        await modal.open({
            width: '600px',
            content(resolve, reject) {
                return (<Add onConfirm={resolve} onCancel={reject} goods={goods} />);
            },
        });
        table?.refresh()
    }

    async function handleDelete(id: number) {
        await Http.del(`/tax/goodsConversion/${id}`)
        table?.refresh()
    }

    async function handleDeleteAll() {
        await Http.del('/tax/goodsConversion')
        table?.refresh()
    }

    return (
        <Page>
            {modalHolder}
            <Card title='商品对应表' actions={<Fragment>
                <PermissionButton permissions="TaxGoodsConversion.create" type="primary" onClick={() => { handleAddOrEdit() }}>新增</PermissionButton>
                <Download url='/tax/goodsConversion/exportTemplate' label='下载模板' type='button' />
                <Download url='/tax/goodsConversion/export' label='导出' type='button' />
                <Upload action={`${Env.getBaseUrl()}/tax/goodsConversion/import`}
                    headers={{
                        Authorization: Context.getToken(),
                    }}
                    accept=".xlsx"
                    onChange={async ({ file: { status, response = {} } }) => {
                        setUploadLoading(true);
                        if (!['done', 'error'].includes(status as string)) {
                            return;
                        }
                        const { data = {}, code, msg } = response;

                        if (String(code) !== '200') {
                            Message.error(msg || '未知错误, 请联系管理员');
                            setUploadLoading(false);
                            return;
                        }
                        const { hasError, total, successSize, errorSize, errorFileUrl } = data;
                        if (!hasError) {
                            Message.success(`全部导入成功,共${total}家客户`);
                            return;
                        }

                        table?.refresh()

                        await modal.open({
                            width: '600px',
                            content(_1, reject) {
                                return (<Card title="导入结果" style={{ marginBottom: '0' }}>
                                    <Space direction="vertical" style={{ width: '100%' }}>
                                        <Row justify="center">
                                            {`总数量: ${total} 条 导入成功: ${successSize} 条 导入失败${errorSize} 条`}
                                        </Row>
                                        <Row justify="center">
                                            <Download url={errorFileUrl} label='点击下载失败记录'></Download>
                                        </Row>
                                        <Row justify="center">
                                            <Button onClick={() => reject()}>关闭</Button>
                                        </Row>
                                    </Space>
                                </Card>);
                            },
                        });
                        setUploadLoading(false);
                    }}
                    showUploadList={false}>
                    <PermissionButton permissions="TaxGoodsConversion.imports" type="primary" loading={uploadLoading}>导入</PermissionButton>
                </Upload>
                <Popconfirm title='您确认要全部删除吗?' onConfirm={() => handleDeleteAll()}>
                        <PermissionButton permissions="TaxGoodsConversion.deleteAll" type="primary">全部删除</PermissionButton>
                    </Popconfirm>
            </Fragment>
            }>

                <Table url='/tax/goodsConversion' columns={columns} onTableRef={onTableRef}>
                    <Input name='srcName' placeholder='ERP商品名称' />
                    <Input name='newName' placeholder='商品名称' />
                    <Input name='customerName' placeholder='客户名称' />
                </Table>
            </Card>
        </Page>
    )
}

export default List;
