import  React, { FC } from 'react';

import { Card, LinkText, Table, Modal } from '@comps';
import InvoicedDetail from './invoiced-detail';

import { Context } from '@utils';
import { ColumnProps } from '@comps/types';

interface Props {
  rowId: number;
};

const InvoicedListMD: FC<Props> = ({ 
  rowId, 
}) => {

  const [modal, modalHolder] = Modal.useModal();

  const showInvDetail = (invoiceNo: number) => {
    modal.open({
      width: 1300,
      content: (_1, _2) => (
        <InvoicedDetail invoiceNo={invoiceNo} />
      )
    })
  };

  const columns: ColumnProps[] = [
    { key: 'invoiceCode', title: '发票代码', width: 150 },
    { key: 'invoiceNo', title: '发票号码', width: 150,
      render: (invoiceNo: number, row: JSONObject) => (
        <LinkText 
          label={invoiceNo + ''} 
          onClick={() => showInvDetail(row.id)} 
        />
      )
    },
    { key: 'invoiceTime', title: '开票时间', width: 150 },
    { key: 'invoicer', title: '开票人', width: 150 },
  ];

  return (
    <Card title="已开发票列表">
      {modalHolder}
      <Table
        url={`/request/${rowId}/queryInvoice`}
        columns={columns}
        isShowSerialNumber={true}
        pagination={false}
      />
    </Card>
  )
};

export default InvoicedListMD;