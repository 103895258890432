import React, { Component, Fragment } from "react";
import { Row, Col, Space, Spin } from "antd";
import "./index.css";
import BackTag from "@comps/BackTag";

interface Props extends React.HTMLAttributes<any> {
  title?: string;
  actions?: React.ReactNode;
  loading?: boolean;
  showBackTag?: boolean;
}

export default class Card extends Component<Props> {
  static defaultProps = {
    loading: false,
  };

  render() {
    return (
      <Spin spinning={this.props.loading}>
        <div className="card" style={this.props.style}>
          <div className="card-header">
            <Row>
              {this.props.showBackTag && (
                <Col style={{ minWidth: "3%" }}>
                  <BackTag
                    style={{
                      left: 0,
                      top: "50%",
                      transform: "translateY(-50%)",
                    }}
                  />
                </Col>
              )}

              <Col span={8}>
                {this.props.title && (
                  <Fragment>
                    <span className="card-header-logo">|</span>
                    <span className="card-header-title">
                      {this.props.title}
                    </span>
                  </Fragment>
                )}
              </Col>
              <Col span={this.props.showBackTag ? 15 : 16}>
                {this.props.actions && (
                  <Space style={{ float: "right" }}>{this.props.actions}</Space>
                )}
              </Col>
            </Row>
          </div>
          <div className="card-content">{this.props.children}</div>
        </div>
      </Spin>
    );
  }
}
