import * as React from "react";
import {
  Form,
  DatePicker,
  Input,
  message,
  Space,
  Button,
  Divider,
  Descriptions,
  Table as AdTable,
  Row,
  Col,
  Radio,
  Switch,
} from "antd";
import {
  Page,
  Card,
  PermissionButton,
  Lookup,
  //   Table,
  LinkText,
  Modal,
} from "@comps";
import { ColumnProps } from "@comps/types";
import { DateUtils, Http, Context, Env, Math } from "@utils";
import _ from "lodash";
import { useState } from "react";
import { RollbackOutlined } from "@ant-design/icons";

interface Props {
  handelStep: Function;
}

const Confirm: React.FC<Props> = ({ handelStep }) => {
  const [dataSource, setDataSource] = useState<JSONObject[]>([]);
  const [loading, setLoading] = useState(false);
  const [currentRadio, setCurrentRadio] = useState(1);
  const [currentTax, setCurrentTax] = useState(true);

  const initColumns: ColumnProps[] = [
    { key: "invCode", dataIndex: "invCode", title: "项目名称", align: "left" },
    { key: "invNo", dataIndex: "invNo", title: "规格型号", align: "left" },
    { key: "taxDate", dataIndex: "taxDate", title: "单位", align: "left" },
    { key: "checkCode", dataIndex: "checkCode", title: "数量", align: "right" },
    {
      key: "taxPrice",
      dataIndex: "taxDate",
      title: "单价（含税）",
      align: "center",
    },
    {
      key: "taxMoney",
      dataIndex: "taxDate",
      title: "金额（含税）",
      align: "center",
    },
    {
      key: "taxDate",
      dataIndex: "taxDate",
      title: "税率/征收率",
      align: "right",
    },
    { key: "taxDate", dataIndex: "taxDate", title: "税额", align: "right" },
  ];
  const [columns, setColumns] = useState(initColumns);

  const onRadioChange = (e: any) => {
    console.log(e, "------------");
  };
  const onPreStep = () => {
    handelStep(0);
  };
  const onTaxChange = (e: any) => {
    console.log(e);
    setCurrentTax(e);
    // table?.refresh();
    const newColumns = columns.map((item) => {
      if (item.key === "taxPrice") {
        return {
          ...item,
          title: `${currentTax ? "单价（不含税）" : "单价（含税）"}`,
        };
      } else if (item.key === "taxMoney") {
        return {
          ...item,
          title: `${currentTax ? "金额（不含税）" : "金额（含税）"}`,
        };
      }
      return item;
    });
    console.log(newColumns);
    setColumns(newColumns);
    // setTimeout(()=>{
    //     currentTax ? console.log('intax') : console.log('extax')
    // },0)
  };
  //   const renderColums = () => {
  //     console.log(currentTax);
  //     return currentTax ? inTaxColumns : exTaxColumns;
  //   };
  return (
    <Card loading={loading} style={{ marginBottom: "0" }}>
      <div>
        <div style={{ height: "24px" }}>
          <span style={{ float: "left", fontSize: "16px", fontWeight: 700 }}>
            已选发票
          </span>
          <span
            style={{
              float: "right",
              fontSize: "16px",
              fontWeight: 700,
              color: "#1890ff",
              cursor: "pointer",
            }}
            onClick={onPreStep}
          >
            <RollbackOutlined />
            返回重选
          </span>
        </div>
        <div
          style={{
            padding: "10px",
            marginTop: "20px",
            border: "0.6px solid #d4d4d5",
          }}
        >
          <div
            style={{ paddingBottom: "20px", fontSize: "16px", fontWeight: 700 }}
          >
            {`票面金额：${Math.num2money(0.99)}`}
            <Divider type="vertical" />
            {`数电票号码：${23442000000070405632}`}
          </div>
          <Descriptions layout="vertical" column={4}>
            <Descriptions.Item label="（销售方）纳税人名称">{`${"广东简意科技有限公司"}`}</Descriptions.Item>
            <Descriptions.Item label="（销售方）统一社会信用代码/纳税人识别号">{`${"91440101MA5ATUTQ2D"}`}</Descriptions.Item>
            <Descriptions.Item label="（购买方）纳税人名称">{`${"广州乐税信息科技有限公司"}`}</Descriptions.Item>
            <Descriptions.Item label="（购买方）统一社会信用代码/纳税人识别号">{`${"91440101MA59ERG49P"}`}</Descriptions.Item>
            <Descriptions.Item label="开票日期">{`${"2023-05-17"}`}</Descriptions.Item>
            <Descriptions.Item label="增值税用途状态">{`${"未勾选"}`}</Descriptions.Item>
            <Descriptions.Item label="消费税用途状态">{`${"未勾选"}`}</Descriptions.Item>
            <Descriptions.Item label="入账状态">{`${"未入账"}`}</Descriptions.Item>
          </Descriptions>
        </div>
        <Descriptions
          layout="vertical"
          style={{ marginTop: "20px" }}
          column={1}
        >
          <Descriptions.Item label="数电票票种">
            <Radio.Group onChange={onRadioChange} value={currentRadio} disabled>
              <Radio value={1}>电子发票</Radio>
              <Radio value={2}>与原蓝票一致票种的纸质发票</Radio>
            </Radio.Group>
          </Descriptions.Item>
          <div>
            {`尊敬的纳税人，您选择${
              currentRadio === 1 ? "电子发票" : "与原蓝票一致票种的纸质发票"
            }，保存成功即开票成功。`}
          </div>
          <Descriptions.Item label="开具红字发票原因">
            <Lookup
              lookupType="TAX_INVOICE_TYPE"
              placeholder="请选择"
              style={{ width: 200 }}
            />
            <Switch
              style={{ marginLeft: "16px" }}
              checkedChildren="含税"
              unCheckedChildren="不含税"
              defaultChecked
              onChange={onTaxChange}
            />
          </Descriptions.Item>
          <Descriptions.Item label="数电票票种">
            <Button type="primary" disabled style={{marginRight:'16px'}}>增加</Button>
            <Button disabled>删行</Button>
          </Descriptions.Item>
        </Descriptions>
      </div>
      <AdTable
        columns={columns}
        dataSource={dataSource}
        //   rowKey={record=>record.invNo}
        pagination={{
          showQuickJumper: true,
          showSizeChanger: true,
          // showTotal: (total) => (`共 ${total} 条`)
        }}
        // summary={() => (
        //   <AdTable.Summary.Row
        //     style={{ display: "fixed", border: "none", textAlign: "center" }}
        //   >
        //     <div style={{ height: "45px", padding: "13px 0"}}>
        //       <span style={{ marginLeft: "16px", float: "left" }}>合计</span>
        //       <div
        //         style={{
        //           float: "right",
        //           marginLeft: "160px",
        //           paddingRight: "8px",
        //         }}
        //       >{`${Math.num2money(-50)}`}</div>
        //       <div style={{ float: "right" }}>{`${Math.num2money(-50)}`}</div>
        //     </div>
        //   </AdTable.Summary.Row>
        // )}
      />
      <Row style={{ display: "fixed", border: "none", textAlign: "center" }}>
        <div style={{ height: "45px", padding: "13px 0", width: "100%"  }}>
          <span style={{ marginLeft: "16px", float: "left"}}>
            合计
          </span>
          <div
            style={{
              float: "right",
              marginLeft: "160px",
              paddingRight: "8px",
            }}
          >{`${Math.num2money(-50)}`}</div>
          <div style={{ float: "right" }}>{`${Math.num2money(-50)}`}</div>
        </div>
      </Row>
      <div>
        <Row style={{ padding: "13px 0" }}>
          <Col
            span={5}
            offset={16}
            style={{ fontWeight: 700, fontSize: "14px" }}
          >
            {`价税合计（大写）：${-50}`}
          </Col>
          <Col
            span={3}
            offset={0}
            style={{ fontWeight: 700, fontSize: "14px" }}
          >
            {`价税合计（小写）：${Math.num2money(-50)}`}
          </Col>
        </Row>
      </div>
      <Divider />
    </Card>
  );
};

export default Confirm;
