import { RouteProps } from '@models'

import Login from './login';
import { LoginLogList } from './loginLog';

const authRoutes: RouteProps[] = [
  { path: '/login', component: Login, private: false },
  { path: '/login?code=:code', component: Login, private: false },
  { path: '/auth/loginLogs', component: LoginLogList, private: true },
];

export { authRoutes };
