import React, { FC, useEffect, useState, useRef } from "react";
import {
  Input,
  Form,
  DatePicker,
  Space,
  message,
  Col,
  Tag,
  Select,
} from "antd";
import { ColumnProps } from "@comps/types";
import {
  Page,
  Card,
  Table,
  Lookup,
  Modal,
  Button,
  PermissionButton,
  TableSummaryRow,
  LinkText,
} from "@comps";

import { DateUtils, Lang, Http, Math, Message, Context } from "@utils";

interface Props {
  ids: any[] | undefined;
  onClose: () => void;
}

const { Item } = Form;
const { RangePicker } = DatePicker;
const { Option } = Select;

const UpdateOrdersModal: FC<Props> = ({ onClose, ids }) => {
  const [loading, setLoading] = useState(false);
  let [table, setTable] = useState<Table>();
  const onTableRef = (tableRef: Table) => {
    table = tableRef;
    setTable(tableRef);
  };
  useEffect(() => {}, []);

  const onFinish = (res: any) => {
    setLoading(true);
    console.log(res.invoiceType);
    let params = {
      ids: ids,
      invoiceType: res.invoiceType,
    };
    console.log(params);
    Http.post("/tax/orders/update/invoiceType", params).then((res) => {
      setLoading(false);
      onClose();
    });
  };

  return (
    <Card title="批量修改发票类型">
      <Form style={{ padding: "32px" }} onFinish={onFinish}>
        <Form.Item
          label="发票类型"
          name="invoiceType"
          rules={[{ required: true, message: "请选择票类" }]}
        >
          <Select
            placeholder="请选择"
            options={[
              {
                value: "32",
                label: "数电(增值税普通发票)",
              },
              {
                value: "31",
                label: "数电(增值税专用发票)",
              },
              {
                value: "85",
                label: "纸质发票（增值税专用发票）",
              },
              {
                value: "86",
                label: "纸质发票（普通发票）",
              },
            ]}
          />
        </Form.Item>
        <div style={{ textAlign: "right" }}>
          <Button
            htmlType="submit"
            type="primary"
            style={{ marginRight: "16px" }}
            loading={loading}
          >
            确认
          </Button>
          <Button onClick={onClose}>取消</Button>
        </div>
      </Form>
    </Card>
  );
};

export default UpdateOrdersModal;
