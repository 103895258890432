import { Card, Modal, Table } from "@comps";
import { ColumnProps } from "@comps/types";
import { DateUtils } from "@utils";
import { Button, Descriptions, Form, Input } from "antd";
import moment from "moment";
import React, { FC, useEffect, useState } from "react";
import Makesuretheapprove from "./makesuretheapprove";

const { TextArea } = Input;

interface Props {
  data: JSONObject;
  record: JSONObject;
  onClose: () => void;
  query?: () => void;
  table: any;
}

const InvoiceApprovaldetailModal: FC<Props> = ({
  data,
  record,
  onClose,
  query,
  table,
}) => {
  const [correctForm] = Form.useForm();
  const [infoForm] = Form.useForm();
  const [approvalLoading, setApprovalLoading] = useState<boolean>(false);
  const [status, setStatus] = useState<string>("");
  const [isPeriod, setIsPeriod] = useState<boolean>(false);
  const [dataSource, setDataSource] = useState<JSONObject[]>(
    data.outputInvoiceLines
  );
  const [dataSourceoperate, setDataSourceoperate] = useState<JSONObject[]>(
    data.outputInvoiceApprovalLogs
  );
  const lastMonth = moment(DateUtils.preMonthStr()).valueOf();
  const [modal, modalHolder] = Modal.useModal();
  useEffect(() => {
    switch (data.approvalStatus) {
      case 2:
        setStatus("审批不通过");
        break;
      case 1:
        setStatus("审批通过");
        break;
      default:
        setStatus("审批中");
        break;
    }
  }, []);

  const detailcolumns: ColumnProps[] = [
    {
      key: "",
      title: "序号",
      width: 70,
      render: (text, record, index) => index + 1,
      align: "center",
    },
    { key: "goodsName", title: "商品", width: 140, align: "center" },
    { key: "spec", title: "规格型号", width: 140, align: "center" },
    { key: "unit", title: "单位", width: 140, align: "center" },
    { key: "quantity", title: "数量", width: 140, align: "center" },
    { key: "price", title: "单价(不含税)", width: 140, align: "center" },
    { key: "amount", title: "金额(不含税)", width: 140, align: "center" },
    { key: "taxRate", title: "税率", width: 140, align: "center" },
    { key: "taxAmount", title: "税额", width: 140, align: "center" },
  ];

  const operationcolumns: ColumnProps[] = [
    { key: "createTime", title: "审批时间", width: 140, align: "center" },
    { key: "operator", title: "审批人", width: 140, align: "center" },
    {
      key: "approveType",
      title: "操作类型",
      width: 140,
      align: "center",
      render(value, record, index) {
        return value === 1 ? (
          <span>审批通过</span>
        ) : value === 2 ? (
          <span>审批不通过</span>
        ) : (
          <span>审批中</span>
        );
      },
    },
    // { key: 'bizNo', title: "备注", width: 140, align: "center" },
  ];
  const approveData = (record: any) => {
    modal.open({
      width: 500,
      content(reslove, reject) {
        return (
          <Makesuretheapprove
            refersh={table?.refresh}
            data={record}
            onClose={reject}
            closeAllModal={onClose}
          />
        );
      },
    });
  };
  return (
    <div style={{ position: "relative", width: "100%", paddingBottom: "18px" }}>
      {modalHolder}
      <Card title="开票申请单">
        {/* <Form
                    name="org"
                      form={infoForm} 
                    labelCol={{ span: 10 }}
                    wrapperCol={{ span: 12 }}
                >
                    <Row>
                        <Col span={5}>
                            <Form.Item
                                label="申请单号"
                                name='bizNo'
                            >
                            </Form.Item>
                        </Col>
                        <Col span={5}>
                            <Form.Item
                                label="申请时间"
                                name="creationTime"
                            >
                            </Form.Item>
                        </Col>
                        <Col span={5}>
                            <Form.Item
                                label="申请人"
                                name="applicant"
                            >
                            </Form.Item>
                        </Col>
                        <Col span={5}>
                            <Form.Item
                                label="当前审批人"
                                name="approvaler"
                            >
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item
                                label="发票类型"
                                name="invoiceType"
                            >
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={5}>
                            <Form.Item
                                label="门店名称"
                                name='deptName'
                            >
                            </Form.Item>
                        </Col>
                        <Col span={5}>
                            <Form.Item
                                label="总不含税金额"
                                name="amount"
                            >
                            </Form.Item>
                        </Col>
                        <Col span={5}>
                            <Form.Item
                                label="总税额"
                                name="taxAmount"
                            >
                            </Form.Item>
                        </Col>
                        <Col span={5}>
                            <Form.Item
                                label="价税合计"
                                name="totalAmount"
                            >
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item
                                label="状态"
                                name="approvalStatus"
                            >
                            </Form.Item>
                        </Col>
                    </Row>
                </Form> */}
        <Descriptions column={5}>
          <Descriptions.Item label={<span>申请单号</span>} span={1}>
            {data.bizNo}
          </Descriptions.Item>
          <Descriptions.Item label={<span>申请时间</span>}>
            {data.creationTime}
          </Descriptions.Item>
          <Descriptions.Item span={1} label={<span>申请人</span>}>
            {data.applicant}
          </Descriptions.Item>
          <Descriptions.Item span={1} label={<span>当前审批人</span>}>
            {data.approvaler}
          </Descriptions.Item>
          <Descriptions.Item span={1} label={<span>发票类型</span>}>
            {data.invoiceType === 31
              ? "电子发票（增值税专用发票）"
              : data.invoiceType === 32
              ? "电子发票（普票发票）"
              : ""}
          </Descriptions.Item>
          <Descriptions.Item label={<span>门店名称</span>} span={2}>
            {data.deptName}
          </Descriptions.Item>
          <Descriptions.Item label={<span>不含税金额</span>}>
            {data.amount}
          </Descriptions.Item>
          <Descriptions.Item span={1} label={<span>税额</span>}>
            {data.taxAmount}
          </Descriptions.Item>
          <Descriptions.Item span={1} label={<span>价税合计</span>}>
            {data.totalAmount}
          </Descriptions.Item>
          <Descriptions.Item span={1} label={<span>状态</span>}>
            {status}
          </Descriptions.Item>
        </Descriptions>
      </Card>
      <Card title="客户信息">
        {/* <Form
                    form={correctForm}
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 14 }}
                >
                    <Row>
                        <Col span={6}>
                            <Form.Item
                                label="客户名称："
                                name='customerName'
                            >
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                label="纳税人识别号："
                                name="erpCustomerName"
                            >
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                label="邮箱："
                                name="shortName"
                            >
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                label="支付方式："
                                name="taxNo"
                            >
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={6}>
                            <Form.Item
                                label="备注："
                                name='customerName'
                            >
                            </Form.Item>
                        </Col>
                    </Row>
                </Form> */}
        <Descriptions column={4}>
          <Descriptions.Item label={<span>客户名称</span>} span={1}>
            {data.information.buyerName}
          </Descriptions.Item>
          <Descriptions.Item label={<span>纳税人识别号</span>}>
            {data.information.buyerTaxNo}
          </Descriptions.Item>
          <Descriptions.Item span={1} label={<span>邮箱</span>}>
            {}
          </Descriptions.Item>
          <Descriptions.Item span={1} label={<span>支付方式</span>}>
            {}
          </Descriptions.Item>
          <Descriptions.Item span={2} label={<span>备注</span>}>
            {data.information.remark}
          </Descriptions.Item>
        </Descriptions>
      </Card>
      <Card title="开票明细">
        <Table
          columns={detailcolumns}
          dataSource={dataSource}
          selectedRowKeys={[]}
          pagination={false}
          scroll={{ y: window.innerHeight - 350 }}
        ></Table>
      </Card>
      <Card title="审批日志">
        <Table
          columns={operationcolumns}
          dataSource={dataSourceoperate}
          scroll={{ y: window.innerHeight - 350 }}
          pagination={false}
        ></Table>
      </Card>
      <Button
        type="primary"
        style={{
          position: "sticky",
          bottom: 0,
          left: "50%",
          transform: "translateX(-50%)",
          zIndex: 999,
        }}
        onClick={() => approveData(record)}
        disabled={record.approvalStatus === 1 || record.approvalStatus === 2}
      >
        审批
      </Button>
    </div>
  );
};

export default InvoiceApprovaldetailModal;
