import React, { FC, memo } from 'react';
import { Row, Col, Button } from 'antd';

import { Card, Table } from '@comps';

import { ColumnProps } from '@comps/types';

interface Props {
  baseFormId: number;
  onClose: () => void;
}

const More: FC<Props> = memo(({ 
  baseFormId,
  onClose,
}) => {
  const columns: ColumnProps [] = [
    { key: 'largeCategory', title: '大类', width: 130 },
    { key: 'mediumCategory', title: '中类', width: 130},
    { key: 'shippedQuantity', title: '已发货数量', width: 200, align: 'right' },
    { key: 'shippedAmount', title: '已发货金额', width: 200, format: 'amount' },
    { key: 'invoicedQuantity', title: '已开票数量', width: 200, align: 'right' },
    { key: 'invoicedAmount', title: '已开票金额', width: 200, format: 'amount' }
  ];

  return (
    <Card 
      title="更多" 
    >
      <Table
        url={`/request/${baseFormId}/statisticsInvLine`}
        columns={columns}
        pagination={false}
      />
      <Row style={{ marginTop: 12 }}>
        <Col span={24} style={{ textAlign: 'center' }}>
          <Button onClick={onClose}>关闭</Button>
        </Col>
      </Row>
    </Card>
  );
});

export default More;
