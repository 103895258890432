import React, { FC } from 'react';
import { Space, Row, Col } from 'antd';

import { Card, Button } from '@comps';

interface Props {
  isAllowSubmit: boolean;
  tipContent: string;
  handleNext: () => void;
  onClose: () => void;
}

const ApproveSubmitTips: FC<Props> = ({ 
  isAllowSubmit,
  tipContent,
  handleNext,
  onClose,
}) => {

  const tipContentArr = tipContent.split(';');
  
  const _handleNext = () => {
    onClose();
    handleNext();
  };

  return (
    <Card title="提示">
      <Row>
        <Col 
          span={24} 
          style={{ 
            paddingTop: 12, 
            paddingLeft: 12 
          }}
        >
          {tipContentArr.map((_tipContent, index) => (
            <h4 key={index}>{`${index + 1}. ${_tipContent}`}</h4>
          ))} 
        </Col> 
      </Row>
      <Row style={{ marginTop: 20 }}>
        <Col 
          span={24} 
          style={{ 
            textAlign: 'center' 
          }}
        > 
          <Space size="large">
            <Button onClick={onClose}>
              确定
            </Button>
            {isAllowSubmit && (
              <Button 
                type="primary"
                onClick={_handleNext}
              >
                下一步
              </Button>
            )}
          </Space>
        </Col>
      </Row>
    </Card>
  );
};

export default ApproveSubmitTips;
