import React, { FC, useCallback, useEffect, useState } from "react";
import { Button, Col, DatePicker, Form, message, Row, Spin, Input } from "antd";
import { Card, DisplayInput } from "@comps";
import { DateUtils, Http } from "@utils";
import moment from "moment";

const { Item } = Form
const { TextArea } = Input

interface Props {
  data: JSONObject,
  onClose: () => void,
  query?: () => void,
}

const CorrectModal:FC<Props> = ({
  data,
  onClose,
  query,
}) => {
  const [correctForm] = Form.useForm()
  const [approvalLoading, setApprovalLoading] = useState<boolean>(false)
  const [isPeriod, setIsPeriod] = useState<boolean>(false)
  const lastMonth = moment(DateUtils.preMonthStr()).valueOf()

  useEffect(() => {
    const { correctMonth } = data
    correctForm.setFieldsValue({
      ...data,
      remark: "",
      correctMonth: correctMonth ? moment(correctMonth) : DateUtils.preMonth()
    })
    setIsPeriod(_isPeriod())
  }, [])

  const _isPeriod = useCallback(() => {
    const { periodFrom, periodTo } = data
    return moment(periodFrom).valueOf() <= lastMonth && moment(periodTo).valueOf() >= lastMonth
  }, [])

  // 确认更正申请
  const handleCorrectConfirm = async () => {
    await correctForm.validateFields()
    const { id } = data
    let { correctMonth, remark } = correctForm.getFieldsValue()
    correctMonth = DateUtils.date2str(correctMonth, "YYYYMM")
    setApprovalLoading(true)
    if (isPeriod) {
      await Http.put(`/approval/correctApplication/${id}?correctMonth=${correctMonth}`)
      .finally(() => setApprovalLoading(false))
    } else {
      await Http.put(`/approval/correctApproval/${id}?remark=${remark}`, { id, remark })
      .finally(() => setApprovalLoading(false))
    }
    message.success("操作成功")
    onClose()
    query && query()
  }

  return (
    <Spin spinning={approvalLoading}>
      <Card title="更正申请">
        <Form form={correctForm} labelCol={{ span: 6 }} autoComplete="off">
          <Item label="申请人所属机构" name="orgName"><DisplayInput /></Item>
          <Item label="申请人" name="fullName"><DisplayInput /></Item>
          <Item label="底稿状态" name="approveStatusDesc"><DisplayInput /></Item>
          <Item label="发起更正月份" name="correctMonth" rules={[{ required: true, message: "请选择更正月份" }]}>
            <DatePicker picker="month" format="YYYYMM" inputReadOnly />
          </Item>
          {!isPeriod && <>
            <Item label="审批人" name="_approver">
              <span>税务经理</span>
            </Item>
            <Item label="审批原因" name="remark" rules={[{ required: true, message: "请填写审批原因" }]}>
              <TextArea></TextArea>
            </Item>
          </>}
          <Item>
            <Row style={{ justifyContent: 'right' }}>
              <Col style={{ marginRight: 10 }}>
                <Button type="primary" onClick={handleCorrectConfirm}>确认</Button>
              </Col>
              <Col>
                <Button onClick={onClose}>取消</Button>
              </Col>
            </Row>
          </Item>
        </Form>
      </Card>
    </Spin>
  )
}

export default CorrectModal
