import { RouteProps } from '@models'

import Publish from './publish';
import Operate from './operate';
import KnowledgeConsult from './knowledgeConsult';
import KnowledgeContent from './knowledgeContent'
import Invoice from './invoice/invoice'
import Means from './means/means'

const taxKnowledgeRoutes: RouteProps[] = [
  { path: '/taxKnowledge/publish', component: Publish, private: false },
  { path: '/taxKnowledge/publish/operate/:id/:status', component: Operate, private: false },
  { path: '/taxKnowledge/publish/operate', component: Operate, private: false },
  { path: '/taxKnowledge/knowledgeConsult', component: KnowledgeConsult, private: false },
  { path: '/taxKnowledge/knowledgeContent/:id', component: KnowledgeContent, private: false, isCancelCache: true },
  { path: '/taxKnowledge/invoice', component: Invoice, private: false },
  { path: '/taxKnowledge/means', component: Means, private: false }
];

export default taxKnowledgeRoutes 