import React, { useState, useEffect } from 'react';
import { Tabs, Button } from 'antd';
import SecondPane from './SecondPane';
import MinutePane from './MinutePane';
import HourPane from './HourPane';
import DayPane from './DayPane';
import MonthPane from './MonthPane';
import WeekPane from './WeekPane';
import YearPane from './YearPane';
import { minuteRegex, hourRegex, dayRegex, monthRegex, weekRegex, yearRegex } from './cron-regex.d';
 
const { TabPane } = Tabs;
const tabPaneStyle = { paddingLeft: 10, paddingBottom: 8, marginTop: -10 };
const getTabTitle = (text: {} | null | undefined) => <div style={{ width: 50, textAlign: 'center' }}>{text}</div>;
 
function Cron(props: { value: any; onOk: any; }) {
  const { value, onOk } = props;
  const [currentTab, setCurrentTab] = useState<string>('1');
  const [second, setSecond] = useState<string>('*');
  const [minute, setMinute] = useState<string>('*');
  const [hour, setHour] = useState<string>('*');
  const [day, setDay] = useState<string>('*');
  const [month, setMonth] = useState<string>('*');
  const [week, setWeek] = useState<string>('?');
  const [year, setYear] = useState<string>();
 
  const onParse = () => {
    if (value) {
      try {
        let [secondVal, minuteValue, hourVal, dayVal, monthVal, weekVal, yearVal] = value.split(' ');
        secondVal = secondVal ? secondVal : '*';
        minuteValue = minuteRegex.test(minuteValue) ? minuteValue : '*';
        hourVal = hourRegex.test(hourVal) ? hourVal : '*';
        dayVal = dayRegex.test(dayVal) ? dayVal : '*';
        monthVal = monthRegex.test(monthVal) ? monthVal : '*';
        weekVal = weekRegex.test(weekVal) ? weekVal : '?';
        yearVal = yearRegex.test(yearVal) ? yearVal : undefined;
        setSecond(secondVal);
        setMinute(minuteValue);
        setHour(hourVal);
        setDay(dayVal);
        setMonth(monthVal);
        setWeek(weekVal);
        setYear(yearVal);
      } catch (error) {
        setSecond('*');
        setMinute('*');
        setHour('*');
        setDay('*');
        setMonth('*');
        setWeek('?');
        setYear(undefined);
      }
    }
  };
 
  useEffect(() => {
    onParse();
  }, [value]);

  useEffect(() => {
    onOk([second, minute, hour, day, month, week, year].join(' '));
  }, [second, minute, hour, day, month, week, year]);
 
  return (
    <Tabs
      style={{
        width: 440
      }}
      tabBarGutter={0}
      animated
      // destroyInactiveTabPane
      activeKey={currentTab}
      onChange={setCurrentTab}>
        <TabPane tab={getTabTitle('秒')} key="1" style={tabPaneStyle}>
          <SecondPane value={second} onChange={setSecond} />
        </TabPane>
        <TabPane tab={getTabTitle('分')} key="2" style={tabPaneStyle}>
          <MinutePane value={minute} onChange={setMinute} />
        </TabPane>
        <TabPane tab={getTabTitle('时')} key="3" style={tabPaneStyle}>
          <HourPane value={hour} onChange={setHour} />
        </TabPane>
        <TabPane tab={getTabTitle('日')} key="4" style={tabPaneStyle}>
          <DayPane value={day} onChange={setDay} />
        </TabPane>
        <TabPane tab={getTabTitle('月')} key="5" style={tabPaneStyle}>
          <MonthPane value={month} onChange={setMonth} />
        </TabPane>
        <TabPane tab={getTabTitle('周')} key="6" style={tabPaneStyle}>
          <WeekPane value={week} onChange={setWeek} />
        </TabPane>
        <TabPane tab={getTabTitle('年')} key="7" style={tabPaneStyle}>
          <YearPane value={year} onChange={setYear} />
        </TabPane>
    </Tabs>
  );
}
 
export default Cron;