import * as React from "react";
import {
    Form,
    DatePicker,
    Input,
    message,
    Space,
    Button,
    Divider,
    Descriptions,
    Table as AdTable,
    Row,
    Col,
} from "antd";
import {
    Page,
    Card,
    PermissionButton,
    Lookup,
    Table,
    LinkText,
    Modal,

} from "@comps";
import { ColumnProps } from "@comps/types";
import { DateUtils, Http, Context, Env, Math } from "@utils";
import _ from "lodash";
import { useState } from "react";

interface Props {
    data: any;
    reject: () => void;
}
interface NObject {
    [key: string]: string | number | undefined | null | void;
}

const DetailViewer: React.FC<Props> = ({ reject, data }) => {

    const [dataSource, setDataSource] = useState<JSONObject[]>([]);
    const [loading, setLoading] = useState(false);

    const columns: ColumnProps[] = [
        {
            key: "goodsName",
            dataIndex: "goodsName",
            title: "项目名称",
            align: "left",
        },
        { key: "spec", dataIndex: "spec", title: "规格型号", align: "left" },
        { key: "unit", dataIndex: "unit", title: "单位", align: "left" },
        { key: "quantity", dataIndex: "quantity", title: "数量", align: "right" },
        {
            key: "price",
            dataIndex: "price",
            title: "单价（不含税）",
            align: "center",
        },
        {
            key: "amount",
            dataIndex: "amount",
            title: "金额（不含税）",
            align: "center",
        },
        {
            key: "taxRate",
            dataIndex: "taxRate",
            title: "税率/征收率",
            align: "right",
        },
        { key: "taxAmount", dataIndex: "taxAmount", title: "税额", align: "right" },
    ];
    const [requestStatus, setRequestStatus] = useState<NObject>({});
    const [totalPrice, setTotalPrice] = useState(0);
    const [amount, setAmount] = useState(0);
    const [taxAmount, setTaxAmount] = useState(0);
    React.useEffect(() => {
        (async () => {
            await Http.get("/sys/lookups/kv", {
                lookupType: "RED_REQUEST_STATUS",
            }).then((res) => {
                console.log(res);
                let reasonObj = {} as NObject;
                res.map((item: any) => {
                    console.log(item.k, item.v);
                    reasonObj[item.k] = item.v;
                });
                console.log(reasonObj, res);
                setRequestStatus(reasonObj);
            });
        })();
    }, []);

    const handlePreview = (confirmType: number) => {

        Http.post(`/tax/output-red-request/audit?confirmType=${confirmType}&redRequestId=${data?.id}`).then((res) => {
            console.log(res);

        })
    }


    return (
        <Card title="详情信息" loading={loading} style={{ marginBottom: "0" }}>
            <div>
                <div
                    style={{ paddingBottom: "20px", fontSize: "16px", fontWeight: 700, display: "flex", justifyContent: "space-between" }}
                >{`红字发票信息确认单状态：${requestStatus[data?.requestStatus]}`}
                    {
                        data?.requestStatus === "03" ? <div>
                            <Button style={{ marginLeft: "8px" }} type="primary" onClick={() => handlePreview(1)}>确认</Button>
                            <Button style={{ marginLeft: "8px" }} onClick={() => handlePreview(0)}>拒绝</Button>
                        </div>
                            : <></>
                    }

                </div>
                {/* <div
          style={{ paddingBottom: "20px", fontSize: "16px", fontWeight: 700 }}
        >{`已实际抵扣税额：${Math.num2money(
          0
        )}  已加抵扣农产品进项税额（元）：${Math.num2money(0)}`}</div> */}
                <div
                    style={{ paddingBottom: "20px", fontSize: "16px", fontWeight: 700 }}
                >
                    已选发票
                </div>
                <div style={{ padding: "10px", border: "0.6px solid #d4d4d5" }}>
                    <div
                        style={{ paddingBottom: "20px", fontSize: "16px", fontWeight: 700 }}
                    >
                        {data?.lines.map((item: any) => {
                            let amount = 0;
                            if (item.amount) {
                                amount += item.amount;
                            }
                            return `票面金额：${Math.num2money(-amount)}`;
                        })}
                        <Divider type="vertical" />
                        {`数电票号码：${data?.blueInvoiceNo}`}
                    </div>
                    <Descriptions layout="vertical" column={4}>
                        <Descriptions.Item label="（销售方）纳税人名称">{`${data?.sellerName ? data?.sellerName : ""
                            }`}</Descriptions.Item>
                        <Descriptions.Item label="（销售方）统一社会信用代码/纳税人识别号">{`${data?.sellerTaxNo ? data?.sellerTaxNo : ""
                            }`}</Descriptions.Item>
                        <Descriptions.Item label="（购买方）纳税人名称">{`${data?.buyerName ? data?.buyerName : ""
                            }`}</Descriptions.Item>
                        <Descriptions.Item label="（购买方）统一社会信用代码/纳税人识别号">{`${data?.buyerTaxNo ? data?.buyerTaxNo : ""
                            }`}</Descriptions.Item>
                        <Descriptions.Item label="开票日期">{`${data?.redInvoiceDate ? data?.redInvoiceDate : ""
                            }`}</Descriptions.Item>
                        <Descriptions.Item label="增值税用途状态">{`${data?.saleTaxStatus ? data?.saleTaxStatus : ""
                            }`}</Descriptions.Item>
                        <Descriptions.Item label="入账状态">{`${data?.recordedStatus ? data?.recordedStatus : ""
                            }`}</Descriptions.Item>
                    </Descriptions>
                </div>
                <Descriptions
                    layout="vertical"
                    style={{ marginTop: "20px" }}
                    column={1}
                >
                    <Descriptions.Item label="开具红字发票原因">
                        <Lookup
                            value={data?.redReason}
                            style={{ width: 200 }}
                            disabled
                            lookupType="RED_REQUEST_APPLY_REASON"
                            placeholder="请选择"
                        />
                    </Descriptions.Item>
                </Descriptions>
            </div>
            <Table
                columns={columns}
                dataSource={data?.lines}
                //   rowKey={record=>record.invNo}
                pagination={false}
                summary={() => (
                    <AdTable.Summary.Row
                        style={{ display: "fixed", border: "none", textAlign: "center" }}
                    >
                        <div style={{ height: "45px", padding: "13px 0", width: "1100px" }}>
                            <span style={{ marginLeft: "16px", float: "left" }}>合计</span>
                            <span
                                style={{
                                    float: "right",
                                    marginLeft: "160px",
                                    paddingRight: "8px",
                                }}
                            >
                                {data?.lines.map((item: any) => {
                                    let taxAmount = 0;
                                    if (item.taxAmount) {
                                        taxAmount += item.taxAmount;
                                    }
                                    setTaxAmount(Number(Math.num2money(taxAmount)));
                                    return Math.num2money(taxAmount);
                                })}
                            </span>
                            <span style={{ float: "right" }}>
                                {data?.lines.map((item: any) => {
                                    let amount = 0;
                                    if (item.amount) {
                                        amount += item.amount;
                                    }
                                    setAmount(Number(Math.num2money(amount)));
                                    return Math.num2money(amount);
                                })}
                            </span>
                        </div>
                    </AdTable.Summary.Row>
                )}
            ></Table>
            <div>
                <Row style={{ padding: "13px 0" }}>
                    <Col
                        span={5}
                        offset={11}
                        style={{ fontWeight: 700, fontSize: "14px" }}
                    >
                        {`价税合计（大写）：${Math.convertToChinese(taxAmount + amount)}`}
                    </Col>
                    <Col
                        span={5}
                        offset={3}
                        style={{ fontWeight: 700, fontSize: "14px" }}
                    >
                        {`价税合计（小写）：${Math.num2money(taxAmount + amount)}`}
                    </Col>
                </Row>
            </div>
            <Divider />
            <div style={{ textAlign: "right", marginTop: 10 }}>
                <Space>
                    <Button onClick={reject}>关闭</Button>
                </Space>
            </div>
        </Card>
    );
};

export default DetailViewer;
