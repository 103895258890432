import React, { FC, useEffect, useState } from 'react';
import { Spin } from 'antd';

import { Page, Card, Invoice, FullInvoice } from '@comps';

import { Http } from '@utils';

interface Props {
  invoiceNo: number;
};

const InvoicedDetail: FC<Props> = ({ invoiceNo }) => {

  const [pageLoading, setPageLoading] = useState(false);
  const [title, setTitle] = useState('');
  const [invoiceInfo, setInvoiceInfo] = useState<JSONObject>({});

  const fullList = [31, 32]

  useEffect(() => {
    getInvoiceInfo();
  }, []);

  const getInvoiceInfo = async () => {
    setPageLoading(true);

    const [invoice, invoiceLinesResult] = await Promise.all([
      Http.get(`/tax/outputInvoices/${invoiceNo}`),
      Http.get(`/tax/outputInvoices/${invoiceNo}/lines?pageSize=4000&pageNum=1`),
    ]);

    invoice.lines = invoiceLinesResult.content;
    setInvoiceInfo(invoice);

    let cardTitle = ''
    if (invoice.invoiceNo) {
      cardTitle = `${invoice.invoiceTypeDesc} | ${invoice.invoiceNo} | ${invoice.statusCodeDesc}`;
    } else {
      cardTitle = `${invoice.invoiceTypeDesc} | ${invoice.statusCodeDesc}`;
    }

    setTitle(cardTitle);
    setPageLoading(false);
  };

  return (
    <Spin spinning={pageLoading}>
      <Page>
        <Card title={title}>
          {fullList.includes(invoiceInfo.invoiceType) ? <FullInvoice invoice={invoiceInfo} /> : <Invoice invoice={invoiceInfo} />}
        </Card>
      </Page>
    </Spin>
  )
};

export default InvoicedDetail;