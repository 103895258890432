import React, { FC, useState, memo  } from 'react';

import { Table } from '@comps';

import { ColumnProps } from '@comps/types';

interface Props  {
  orderId: number;
};

const BillSubTable: FC<Props> = memo(({
  orderId,
}) => {

  let [table, setTable] = useState<Table>();

  const onTableRef = (tableRef: Table) => {
    setTable(tableRef);
    table = tableRef;
  };

  const columns: ColumnProps[] = [
    { key: 'goodsName', title: '商品描述', width: 260, fixed: 'left' },
    { key: 'withTaxAmount', title: '价税合计', width: 170, align: 'right' },
    { key: 'invoicedAmount', title: '已开金额', width: 170, align: 'right' },
    { key: 'remainAmount', title: '未开金额', width: 170, align: 'right' },
    { key: 'orderQuantity', title: '订单总数量', width: 120, align: 'right' },
    { key: 'quantity', title: '数量', width: 120, align: 'right' },
    { key: 'auxQuantity', title: '辅数量', width: 120, align: 'right' },
    { key: 'invoicedQuantity', title: '已开数量', width: 120, align: 'right' },
    { key: 'remainQuantity', title: '未开数量', width: 120, align: 'right' },
    { key: 'withTaxPrice', title: '主计量单价', width: 170, align: 'right' },
    { key: 'auxWithTaxPrice', title: '辅计量单价', width: 170, align: 'right' },
    { key: 'unit', title: '主计量单位', width: 170, align: 'center' },
    { key: 'auxUnit', title: '辅计量单位', width: 170 },
    { key: 'spec', title: '规格型号', width: 260 },
    { key: 'goodsNo', title: '物料编码', width: 180 },
    { key: 'conversionRate', title: '税率', width: 70, align: 'right' },
    { key: 'largeCategory', title: '大类', width: 120, align: 'center' },
    { key: 'mediumCategory', title: '中类', width: 120 },
    { key: 'smallCategory', title: '小类', width: 200 },
    { key: 'lossRate', title: '损耗率', width: 120, align: 'right' },
    { key: 'lossQuantity', title: '损耗数量', width: 120, align: 'right' }, 
    { key: 'lossAmount', title: '损耗金额', width: 120, align: 'right' },
    { key: 'balanceAmount', title: '差额', width: 120, align: 'right' },
  ];

  return (
    <Table
      onTableRef={onTableRef}
      url={`/outputOrder/${orderId}/lines`}
      columns={columns}
      pagination={false}
      className="subtable"
    />
  )
});

export default BillSubTable;
