import * as React from "react";
import { Scrollbars } from "react-custom-scrollbars";
import cname from "classnames";
import styles from "./index.module.scss";

export interface Props
  extends React.Props<HTMLElement>,
    React.BaseHTMLAttributes<HTMLElement> {
  invoice: JSONObject;
}

const Invoice: React.FC<Props> = ({ invoice }: Props) => {
  const renderEmptyRow = () => {
    const invoiceRowNum = (invoice.lines || []).length;

    if (invoiceRowNum < 10) {
      const emptyRowNum = 10 - invoiceRowNum;
      const emptyRowArr = [];

      for (let i = 0; i < emptyRowNum; i++) {
        emptyRowArr.push(i);
      }

      return emptyRowArr.map((index) => (
        <div
          key={index}
          className={cname(styles.tableRow)}
          style={{
            display: "flex",
            height: 30,
            maxHeight: 30,
          }}
        >
          <div style={{ textAlign: "left", width: "30.3%" }} />
          <div style={{ textAlign: "right", width: "10.1%" }} />
          <div style={{ textAlign: "center", width: "5.6%" }} />
          <div style={{ textAlign: "right", width: "10%" }} />
          <div style={{ textAlign: "right", width: "10%" }} />
          <div style={{ textAlign: "right", width: "10%" }} />
          <div style={{ textAlign: "right", width: "10%" }} />
          <div style={{ textAlign: "right", width: "4%" }} />
          <div style={{ textAlign: "right", width: "10%" }} />
        </div>
      ));
    }
  };

  return (
    <div className={styles.invoice}>
      <nav className={cname(styles.header, styles.titleText)}>
        <span className={cname(styles.text)}>
          {invoice.fullInvoiceTypeDesc}
        </span>
        <div className={cname(styles.meta)}>
          <div className={cname(styles.line)}>
            <div className={cname(styles.label)}>
              <span>发</span>
              <span>票</span>
              <span>代</span>
              <span>码</span>
            </div>
            <div className={cname(styles.value)}>{invoice.invoiceCode}</div>
          </div>
          <div className={cname(styles.line)}>
            <div className={cname(styles.label)}>
              <span>发</span>
              <span>票</span>
              <span>号</span>
              <span>码</span>
            </div>
            <div className={cname(styles.value)}>{invoice.invoiceNo}</div>
          </div>
          <div className={cname(styles.line)}>
            <div className={cname(styles.label)}>
              <span>开</span>
              <span>票</span>
              <span>日</span>
              <span>期</span>
            </div>
            <div className={cname(styles.value)}>{invoice.invoiceDate}</div>
          </div>
          <div className={cname(styles.line)}>
            <div className={cname(styles.label)}>
              <span>检</span>
              <span>验</span>
              <span>码</span>
            </div>
            <div className={cname(styles.value)}>{invoice.checkCode}</div>
          </div>
        </div>
      </nav>
      <section className={styles.body}>
        <div className={cname(styles.row, styles.infos)}>
          <div className={cname(styles.group, styles.titleText, styles.cell)}>
            <span>购</span>
            <span>买</span>
            <span>方</span>
          </div>
          <div className={cname(styles.dataset, styles.cell)}>
            <div className={cname(styles.line)}>
              <div className={cname(styles.label)}>
                <span>名</span>
                <span>称</span>
              </div>
              <div className={cname(styles.value)}>{invoice.buyerName}</div>
            </div>
            <div className={cname(styles.line)}>
              <div className={cname(styles.label)}>
                <span>纳</span>
                <span>税</span>
                <span>人</span>
                <span>识</span>
                <span>别</span>
                <span>号</span>
              </div>
              <div className={cname(styles.value)}>{invoice.buyerTaxNo}</div>
            </div>
            <div className={cname(styles.line)}>
              <div className={cname(styles.label)}>
                <span>地</span>
                <span>址</span>
                <span>、</span>
                <span>电</span>
                <span>话</span>
              </div>
              <div className={cname(styles.value)}>{invoice.buyerAddrTel}</div>
            </div>
            <div className={cname(styles.line)}>
              <div className={cname(styles.label)}>
                <span>开</span>
                <span>户</span>
                <span>行</span>
                <span>及</span>
                <span>账</span>
                <span>号</span>
              </div>
              <div className={cname(styles.value)}>{invoice.buyerBank}</div>
            </div>
          </div>
          <div className={cname(styles.group, styles.titleText, styles.cell)}>
            <span>密</span>
            <span>码</span>
            <span>区</span>
          </div>
          <div className={cname(styles.dataset, styles.cell)}>{"   "}</div>
        </div>
        <div className={cname(styles.table)}>
          <div
            className={cname(styles.thead)}
            style={{ display: "flex", height: 30 }}
          >
            <div className={cname(styles.titleText)} style={{ width: "30.3%" }}>
              货物或应税劳务、服务名称
            </div>
            <div className={cname(styles.titleText)} style={{ width: "10.1%" }}>
              规格型号
            </div>
            <div className={cname(styles.titleText)} style={{ width: "5.6%" }}>
              单位
            </div>
            {/* origin 11.3 */}
            <div className={cname(styles.titleText)} style={{ width: "10%" }}>
              数量
            </div>
            {/* origin 11.8 */}
            <div className={cname(styles.titleText)} style={{ width: "10%" }}>
              不含税单价
            </div>
            <div className={cname(styles.titleText)} style={{ width: "10%" }}>
              含税单价
            </div>
            {/* origin 15.2 */}
            <div className={cname(styles.titleText)} style={{ width: "10%" }}>
              金额
            </div>
            <div className={cname(styles.titleText)} style={{ width: "4%" }}>
              税率
            </div>
            {/* origin 15 */}
            <div className={cname(styles.titleText)} style={{ width: "10%" }}>
              税额
            </div>
          </div>
          <Scrollbars
            style={{ height: 300, borderRight: "1px solid #972E2E" }}
            autoHide
          >
            {(invoice.lines || []).map((line: JSONObject, index: number) => (
              <div
                className={cname(styles.tableRow)}
                style={{ display: "flex", minHeight: 30 }}
                key={line.id}
              >
                <div
                  style={{ textAlign: "left", width: "30.3%", paddingLeft: 10 }}
                >{`${index + 1} ${line.invoiceGoodsName}`}</div>
                <div
                  style={{
                    textAlign: "right",
                    width: "10.1%",
                    paddingBottom: 16,
                  }}
                >
                  {line.spec}
                </div>
                <div style={{ textAlign: "center", width: "5.6%" }}>
                  {line.unit}
                </div>
                <div style={{ textAlign: "right", width: "10%" }}>
                  {line.quantity}
                </div>
                <div style={{ textAlign: "right", width: "10%" }}>
                  {line.price}
                </div>
                <div style={{ textAlign: "right", width: "10%" }}>
                  {line.withTaxPrice}
                </div>
                <div style={{ textAlign: "right", width: "10%" }}>
                  {line.amount}
                </div>
                <div style={{ textAlign: "right", width: "4%" }}>
                  {(line.discountPolicyRemark ?? "") !== "" && line.taxRate == 0
                    ? line.discountPolicyRemark
                    : `${line.taxRate}%`}
                </div>
                <div style={{ textAlign: "right", width: "10%" }}>
                  {(line.discountPolicyRemark ?? "") !== "" && line.taxRate == 0
                    ? ""
                    : line.taxAmount}
                </div>
              </div>
            ))}
            {renderEmptyRow()}
          </Scrollbars>
          <div
            className={cname(styles.tableRow)}
            style={{ display: "flex", borderRight: "1px solid #972E2E" }}
          >
            <div
              className={cname(styles.titleText, cname(styles.totalTitle))}
              style={{ width: "30.3%" }}
            >
              合计
            </div>
            <div style={{ width: "10.1%" }} />
            <div style={{ width: "5.6%" }} />
            <div style={{ width: "10%" }} />
            <div style={{ width: "10%" }} />
            <div style={{ width: "10%" }} />
            <div style={{ width: "10%", textAlign: "right" }}>
              ¥{invoice.invoiceAmount}
            </div>
            <div style={{ width: "4%" }} />
            <div style={{ width: "10%", textAlign: "right" }}>
              ¥{invoice.taxAmount}
            </div>
          </div>
        </div>
        <div
          className={cname(styles.row, styles.totalPriceAndTax)}
          style={{ height: "40px", lineHeight: "40px" }}
        >
          <div
            className={cname(styles.titleText, styles.cell)}
            style={{ textAlign: "center", width: "27%" }}
          >
            价税合计(大写)
          </div>
          <div className={cname(styles.grow, styles.cell, styles.data)}>
            <div className={cname(styles.row)}>
              <div style={{ width: "65%" }}>
                {invoice.totalInvoiceAmountUpper}
              </div>
              <div style={{ width: "35" }}>
                <span className={cname(styles.titleText)}>(小写)</span>¥
                {invoice.totalAmount}
              </div>
            </div>
          </div>
        </div>
        <div className={cname(styles.row, styles.infos)}>
          <div className={cname(styles.group, styles.titleText, styles.cell)}>
            <span>销</span>
            <span>售</span>
            <span>方</span>
          </div>
          <div className={cname(styles.dataset, styles.cell)}>
            <div className={cname(styles.line)}>
              <div className={cname(styles.label)}>
                <span>名</span>
                <span>称</span>
              </div>
              <div className={cname(styles.value)}>{invoice.sellerName}</div>
            </div>
            <div className={cname(styles.line)}>
              <div className={cname(styles.label)}>
                <span>纳</span>
                <span>税</span>
                <span>人</span>
                <span>识</span>
                <span>别</span>
                <span>号</span>
              </div>
              <div className={cname(styles.value)}>{invoice.sellerTaxNo}</div>
            </div>
            <div className={cname(styles.line)}>
              <div className={cname(styles.label)}>
                <span>地</span>
                <span>址</span>
                <span>、</span>
                <span>电</span>
                <span>话</span>
              </div>
              <div className={cname(styles.value)}>
                {invoice.sellerAddrTel !== null ? (
                  <div className={cname(styles.value)}>
                    <div style={{ display: "inline" }}>
                      {invoice.sellerAddrTel.split(" ")[0]}
                    </div>
                    <br />
                    {invoice.sellerAddrTel.split(" ")[1]}
                  </div>
                ) : (
                  <div>{invoice.sellerAddrTel}</div>
                )}
              </div>
            </div>
            <div className={cname(styles.line)}>
              <div className={cname(styles.label)}>
                <span>开</span>
                <span>户</span>
                <span>行</span>
                <span>及</span>
                <span>账</span>
                <span>号</span>
              </div>
              <div className={cname(styles.value)}>
                {invoice.sellerBank !== null ? (
                  <div className={cname(styles.value)}>
                    {invoice.sellerBank.split(" ")[0]}
                    <br />
                    {invoice.sellerBank.split(" ")[1]}
                  </div>
                ) : (
                  <div>{invoice.sellerBank}</div>
                )}
              </div>
            </div>
          </div>
          <div className={cname(styles.group, styles.titleText, styles.cell)}>
            <span>备</span>
            <span>注</span>
          </div>
          <div className={cname(styles.dataset, styles.cell)}>
            {invoice.remark}
          </div>
        </div>
      </section>
      <div className={cname(styles.footer)}>
        <div className={cname(styles.item)}>
          <div className={cname(styles.label)}>收&nbsp;款&nbsp;人</div>
          <div className={cname(styles.value)}>{invoice.cashier}</div>
        </div>
        <div className={cname(styles.item)}>
          <div className={cname(styles.label)}>复&nbsp;核</div>
          <div className={cname(styles.value)}>{invoice.checker}</div>
        </div>
        <div className={cname(styles.item)}>
          <div className={cname(styles.label)}>开&nbsp;票&nbsp;人</div>
          <div className={cname(styles.value)}>{invoice.invoicer}</div>
        </div>
      </div>
    </div>
  );
};

export default Invoice;
