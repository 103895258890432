import * as React from 'react';

import { Form, DatePicker, Input, message, Space } from 'antd';
import { Page, Card, PermissionButton, Table, Lookup, Modal, Button } from '@comps';
import { Link } from 'react-router-dom';

import TransferMd from './transfer.md';
import UpdateEmployee from './updateEmployee.md';
import MailOrderMD from '../mailOrder.md';
import BulkOrderMD from '../bulkOrder.md';
import OrderFailed from '../orderFailed.md';

import { DateUtils, Lang, Http } from '@utils';
import { ColumnProps } from '@comps/types';

export interface Props {};

const InvoicePage: React.FC<Props> = () => {
  const [model, modalHolder] = Modal.useModal();
  const tableRef = React.useRef<Table>();
  const initQuery = React.useMemo<JSONObject>(() => ({
    invoiceDateFromTo: [DateUtils.monthFirstDay(), DateUtils.now()],
  }), []);

  const showMailOrderInfo = async (mainOrderInfo: JSONObject, invoiceIds: number[]) => {
    model.open({
      width: '450px',
      ignoreError: true,
      content(resolve, reject) {
        return (
          <MailOrderMD isWaiting={true} mainOrderInfo={mainOrderInfo} invoiceIds={invoiceIds} onClose={reject} />
        );
      }
    });
  }

  const showBulkOrderMD = (mainOrderInfo: JSONObject, invoiceIds: number[]) => {
    model.open({
      width: '400px',
      ignoreError: true,
      content(resolve, reject) {
        return (
        <BulkOrderMD showMailOrderInfo={() => {showMailOrderInfo(mainOrderInfo, invoiceIds)}} onClose={reject} />);
      },
    });
  }

  const handleMailOrder = async () => {
    const ids = tableRef.current?.getSelectedRowKeys();
    if (!ids || !ids.length) {
      message.warn('请至少选择一条记录');
      return;
    }

    const buyerNameArr: string[] = tableRef.current?.getSelectedRowAttr('buyerName') || []
    const invoiceIdsArr: number[] = tableRef.current?.getSelectedRowAttr('id') || []
    let isSameBuyerName = true

    for (let i = 0; i < buyerNameArr.length; i++) {
      if (buyerNameArr[0] !== buyerNameArr[i]) {
        isSameBuyerName = false
        break
      }
    }

    const invoiceIds = invoiceIdsArr.join();
    const mainOrderInfo: JSONObject = await Http.get('/tax/mailingManagement/mailingUserInfo', { invoiceIds });

    if (isSameBuyerName) {
      showMailOrderInfo(mainOrderInfo, invoiceIdsArr);
    } else {
      showBulkOrderMD(mainOrderInfo, invoiceIdsArr);
    }
  }

  // const showMailOrderInfo = async () => {
  //   const mainOrderInfo: JSONObject = await Http.get('', {});

  //   model.open({
  //     width: '300px',
  //     ignoreError: false,
  //     content(resolve, reject) {
  //       return (
  //       <MailOrderMD mainOrderInfo={mainOrderInfo} onClose={reject} />);
  //     },
  //   });
  // }

  const handleOrderFailed = () => {
    model.open({
      width: '300px',
      ignoreError: false,
      content(resolve, reject) {
        return (
        <OrderFailed onClose={reject} />);
      },
    });
  }

  const handleTransfer = React.useCallback(() => Lang.noError(async () => {
    const ids = tableRef.current?.getSelectedRowKeys();
    if (!ids || !ids.length) {
      message.warn('请至少选择一条记录');
      return;
    }
    await model.open({
      width: '700px',
      ignoreError: false,
      content(resolve, reject) {
        return (<TransferMd ids={ids}
          onConfirm={resolve}
          onClose={reject} />);
      },
    });
    message.success('提交成功');
    tableRef.current?.refresh();
  }), [model]);

  const handleUpdateEmployee = React.useCallback((invoice: JSONObject) => Lang.noError(async () => {
    await model.open({
      width: '700px',
      ignoreError: false,
      content(resolve, reject) {
        return (<UpdateEmployee id={invoice.id}
          onConfirm={resolve}
          onClose={reject} />);
      },
    });
    message.success('修改成功');
    tableRef.current?.refresh();
  }), [model]);

  const columns: ColumnProps[] = [
    {
      key: 'invoiceNo', title: '发票号码', width: 120,
      render(value, invoice) {
        return (<Link to={`/tax/outputInvoice/${invoice.requestNo}/detail`}>{value}</Link>);
      },
    },
    { key: 'invoiceCode', title: '发票代码', width: 140, },
    { key: 'invoiceTypeDesc', title: '发票类型', width: 170, },
    { key: 'invoiceDate', title: '开票日期', width: 110, },
    { key: 'employeeName', title: '业务员', width: 110, },
    { key: 'invoiceAmount', title: '不含税金额', format: 'amount', width: 120 },
    { key: 'taxAmount', title: '税额', format: 'amount', width: 120 },
    { key: 'totalAmount', title: '价税合计', width: 120, format: 'amount' },
    { key: 'buyerName', title: '购买方', width: 200, },
    { key: 'sellerName', title: '销售方', width: 200, },
    {
      key: 'id', title: '操作', fixed: 'right',
      render: (_1, invoice: JSONObject) => <PermissionButton
        permissions={'OutputInvoice.updateEmp'}
        type='link'
        onClick={() => { handleUpdateEmployee(invoice) }}>修改业务员</PermissionButton>
    },
  ];

  return (
    <Page>
      {modalHolder}
      <Card title='待交接发票' actions={
        <Space>
          <Button type="primary" onClick={handleMailOrder}>邮寄下单</Button>
          <PermissionButton
            permissions="InvoiceTransfer.submit"
            type="primary"
            onClick={handleTransfer}>交接发票</PermissionButton>
        </Space>
      }>
        <Table url="/tax/transfers/invoices"
          queryProps={{
            initialValues: initQuery,
          }}
          onTableRef={(ref) => {
            tableRef.current = ref;
          }}
          selectedRowKeys={[]}
          columns={columns}>
          <Form.Item name="invoiceNo">
            <Input placeholder="发票号码" />
          </Form.Item>
          <Form.Item name="buyerCode">
            <Input placeholder="购方编码" />
          </Form.Item>
          <Form.Item name="invoiceType">
            <Lookup lookupType="TAX_INVOICE_TYPE" placeholder="发票类型"></Lookup>
          </Form.Item>
          <Form.Item name="invoiceDateFromTo">
            <DatePicker.RangePicker format='YYYYMMDD' placeholder={["开票日期从", "至"]} inputReadOnly/>
          </Form.Item>
        </Table>
      </Card>
    </Page>
  );
};

export default InvoicePage;
