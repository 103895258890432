import React, { FC, useState, useEffect } from 'react';
import { Col } from 'antd';

import BillSubTable from './component/billSubTable';

import { Card, Table, TableSummaryRow } from '@comps';

import { Http, Math } from '@utils';
import { ColumnProps } from '@comps/types';

interface Props {
  contractNo: string
}

interface TableSummaryProps {
  count: number;
  invoicedAmount: number;
  totalAmount: number;
  unInvoicedAmount: number;
}

const BillDetail: FC<Props> = ({ contractNo }) => {

  let [amountTable, setAmountTable] = useState<Table>();
  let [billTable, setBillTable] = useState<Table>();

  const [tableSummaryData, setTableSummaryData] = useState<TableSummaryProps>({
    count: 0,
    invoicedAmount: 0,
    totalAmount: 0,
    unInvoicedAmount: 0,
  });

  useEffect(() => {
    getContractTotalAmount();
    getTableSummaryData();
  }, []);

  const onAmountTableRef = (tableRef: Table) => {
    setAmountTable(tableRef);
    amountTable = tableRef;
  };

  const onBillTableRef = (tableRef: Table) => {
    setBillTable(tableRef);
    billTable = tableRef;
  };

  const getContractTotalAmount = () => {
    Http.get(`/contracts/ledger/order/sum/${contractNo}`)
      .then(data => {
        amountTable?.setDataSource([data]);
      })
  };

  const getTableSummaryData = () => {
    Http.get(`/contracts/ledger/order/out/sum/${contractNo}`)
      .then(data => {
        setTableSummaryData(data);
      })
  };

  const contractColumns: ColumnProps[] = [
    { key: 'contractAmt', title: '合同金额', width: 150, },
    { key: 'actualAmt', title: '实发金额', width: 150, },
    { key: '', title: '含税销售金额', width: 150, },
    { key: '', title: '预开票计销项税', width: 150, },
    { key: '', title: '收款金额', width: 150, },
    { key: '', title: '应收余额', width: 150, },
    { key: 'invoicedAmt', title: '已开票金额', width: 150, },
    { key: '', title: '流程金额', width: 150 },
    { key: 'uninvoicedAmt', title: '剩余未开票金额', width: 150 },
    { key: '', title: '开票未收款余额', width: 150, }
  ];

  const billColumns: ColumnProps[] = [
    { key: 'orderNo', title: '单据号', width: 180, fixed: 'left' },
    { key: 'customerCode', title: '客户编码', width: 160 },
    { key: 'customerName', title: '客户名称', width: 260 },
    { key: 'withTaxAmount', title: '总金额', width: 160, format: 'amount' },
    { key: 'invoicedAmount', title: '已开发票金额', width: 160, format: 'amount' },
    { key: 'remainAmount', title: '未开发票金额', width: 160, format: 'amount' },
    { key: 'sbuCode', title: 'SBU 代码', width: 180, align: 'center' },
    { key: 'sbuName', title: 'SBU 名称', width: 160 },
    { key: 'orderTypeDesc', title: '订单类型', width: 160, align: 'center' },
    { key: 'businessType', title: '业务类型', width: 160, format: 'amount' }, 
    { key: 'sourceSystem', title: '来源系统', width: 130 },
    { key: 'mesNo', title: '橱衣柜订单识别码', width: 160 },
    { key: 'contractNo', title: '项目合同编码', width: 180 },
    { key: 'mesNo', title: 'MES 订单号码', width: 180 },
    { key: 'custPoNo', title: '客户 PO 单据号', width: 180 },
    { key: 'orderDate', title: '单据日期', width: 140,
      render: (orderDate: string) => orderDate.split(' ')[0]
    },
    { key: 'statusCode', title: '更新状态', width: 140, 
      render: (statusCode: string) => statusCode === '2' ? '已更新' : '未更新'
    },
    { key: 'salerCode', title: '业务员编码', width: 160 },
    { key: 'salerName', title: '业务员', width: 160 },
    { key: 'customerTaxNo', title: '纳税人识别号', width: 160 }
  ];

  const expandedRowRender = ({ id }: JSONObject) => {
    return (
      <BillSubTable orderId={id} />
    );
  };

  const tableSummary = () => {
    return (
      <TableSummaryRow>
        <Col span={6}>
          单据数量: {Math.numThousandth(tableSummaryData.count)}
        </Col>
        <Col span={6}>
          总金额汇总: {Math.num2money(tableSummaryData.totalAmount)}
        </Col>
        <Col span={6}>
          已开发票金额汇总: {Math.num2money(tableSummaryData.invoicedAmount)}
        </Col>
        <Col span={6}>
          未开发票金额汇总: {Math.num2money(tableSummaryData.unInvoicedAmount)}
        </Col>
      </TableSummaryRow>
    );
  };

  return (
    <div>
      <Card 
        title="合同总金额" 
        style={{ boxShadow: 'none' }}
      >
        <Table
          onTableRef={onAmountTableRef}
          columns={contractColumns}
          pagination={false}
        />
      </Card>
      <Card 
        title="单据管理"
        style={{ boxShadow: 'none' }}
      >
        <Table
          onTableRef={onBillTableRef}
          url={`/contracts/ledger/order/${contractNo}`}
          columns={billColumns}
          expandable={{ expandedRowRender }}
          summary={tableSummary} 
          scroll={{ y: window.innerHeight - 370 }}
        />
      </Card>
    </div>
  )
};

export default BillDetail;