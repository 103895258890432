import React, { FC, useState, Fragment } from 'react'
import { Page, Table, Card, Modal } from '@comps'
import { Layout, Http, Message } from '@utils'
import { ColumnProps } from '@comps/types'
import { Button, Form, Input, Checkbox, Select } from 'antd'

import { FileList } from './file'
import { Link } from 'react-router-dom'

const Item = Form.Item

const BucketList: FC = () => {
    const [form] = Form.useForm()
    let [isModalVisible, setModalVisible] = useState(false)
    let [selectedProvider, setSelectedProvider] = useState('')

    const onAddOrEditClick = () => {
        form.resetFields()
        setModalVisible(true)
    }

    const onProviderSelect = (value: any, option: any) => {
        setSelectedProvider(value)
    }

    const saveBucket = () => {
        form.validateFields().then(values => {
            Http.post('/cms/buckets', values).then(data => {
                Message.saveOk()
                setModalVisible(false)
            })
        })
    }

    const columns: ColumnProps[] = [
        {
            key: 'providerDesc',
            title: '存储服务商',
            render: (value, row) => (<Link to={{ pathname: `/cms/buckets/${row.id}/files`, search: `bucketName=${row.bucketName}` }} >{value}</Link>),
            fixed: 'left',
        },
        {
            key: 'bucketCode',
            title: '桶编码',
            width: 120,
        },
        {
            key: 'bucketName',
            title: '桶名称',
            width: 120,
        },
        {
            key: 'capacity',
            title: '使用量',
            width: 100,
        },
        {
            key: 'publicFlag',
            title: '访问权限',
            width: 100,
            render: (value) => (value ? '公开访问' : '授权访问'),
        },
        {
            key: 'rootPath',
            title: '根目录(本地磁盘)',
            width: 180,
        },
        {
            key: 'accessKey',
            title: 'Access Key',
            width: 180,
        },
        {
            key: 'endpoint',
            title: 'Endpoint',
            width: 250,
        },
        {
            key: 'domain',
            title: 'Domain(域名)',
            width: 300,
        },
        {
            key: 'id',
            title: '操作',
            fixed: 'right',
            width: 120,
            render: (value, row) => (<Link to={{ pathname: `/cms/buckets/${value}/files`, search: `bucketName=${row.bucketName}` }} >进入文件库</Link>),
        },
    ]
    return (
        <Page>
            <Card title='文件存储' actions={<Button type='primary' onClick={() => onAddOrEditClick()}>创建Bucket</Button>}>
                <Table url='/cms/buckets' columns={columns} scroll={{ y: window.innerHeight - 300 }} />
            </Card>

            <Modal title='创建 Bucket' visible={isModalVisible} onOk={saveBucket} onCancel={() => setModalVisible(false)}>
                <Form form={form} {...Layout._1c_1_2}>
                    <Item label='服务商' name='provider' required>
                        <Select onSelect={onProviderSelect} options={[{ label: '本地磁盘', value: 'LOCAL' }, { label: '阿里云', value: 'ALIYUN' }]} />
                    </Item>
                    <Item label='Bucket 编码' name='bucketCode' required><Input /></Item>
                    <Item label='Bucket 名称' name='bucketName' required><Input /></Item>
                    <Item label='公开访问' name='publicFlag' valuePropName='checked' required><Checkbox /></Item>
                    <Item label='Domain(域名)' name='domain' required><Input placeholder='作为文件URL域名' /></Item>

                    {selectedProvider === 'LOCAL' && <Item label='根路径' name='rootPath' required><Input placeholder='eg:/u01/cms/repo1' /></Item>}
                    {selectedProvider === 'ALIYUN' &&
                        <Fragment>
                            <Item label='Endpoint' name='endpoint'><Input /></Item>
                            <Item label='Access Key' name='accessKey' ><Input /></Item>
                            <Item label='Access Secret' name='accessSecret' ><Input /></Item>
                        </Fragment>
                    }
                </Form>
            </Modal>
        </Page>
    )
}

export { BucketList, FileList }