import React, { FC, BaseHTMLAttributes, memo  } from 'react';

import { Table } from '@comps';
import { ColumnProps } from '@comps/types';

interface Props extends BaseHTMLAttributes<HTMLElement> {
  dataSource: JSONObject[]
};

const MergeOrSplitSubTable: FC<Props> = memo(({
  dataSource
}) => {

  const columns: ColumnProps[] = [
    { key: 'lineNum', title: '序号', width: 40 },
    { key: 'goodsName', title: '商品名称', width: 300 },
    { key: 'spec', title: '规格型号', width: 160, },
    { key: 'unit', title: '单位', width: 60, },
    { key: 'quantity', title: '数量', width: 100, align: 'right' },
    { key: 'price', title: '不含税单价', width: 140, align: 'right',
      render: (price: number) => price.toFixed(6)
    },
    { key: 'withTaxPrice', title: '含税单价', width: 140, align: 'right',
      render: (withTaxPrice: number) => withTaxPrice.toFixed(6)
    },
    { key: 'amount', title: '不含税金额', format: 'amount', width: 120, align: 'right' },
    { key: 'taxAmount', title: '税额', width: 120, align: 'center' },
    { key: 'withTaxAmount', title: '含税金额', width: 100, format: 'amount' }
  ];

  return (
    <Table
      columns={columns}
      dataSource={dataSource}
      scroll={{ y: 450 }}
      pagination={false}
    />
  )
});

export default MergeOrSplitSubTable;
