import React, { FC, useState } from 'react';
import { Space, Form, Input, DatePicker, Button, Col } from 'antd';
import moment from 'moment';

import { Page, Card, Table, TableSummaryRow } from '@comps';

import { FormUtil, DateUtils, Math } from '@utils'; 
import { ColumnProps } from '@comps/types';

const { Item } = Form;
const { RangePicker } = DatePicker;

const IncomeDetail: FC = () => {

  let [table, setTable] = useState<Table>();
  const [totalInvoiceAmount, setTotalInvoiceAmount] = useState(0);
  const [totalValidTaxAmount, setTotalValidTaxAmount] = useState(0);
  const [totalWithTaxAmount, setTotalWithTaxAmount] = useState(0);
  const [totalAmountDiff, setTotalAmountDiff] = useState(0);
  const [totalTaxAmountDiff, setTotalTaxAmountDiff] = useState(0);

  const currentPeriod = moment(); 

  const onTableRef = (tableRef: Table) => {
    setTable(tableRef);
    table = tableRef;
  };

  const getValidTableParams = () => {
    const formParams = table?.getQueryPanelParams() ?? {};
    const { period } = formParams;

    // if (period) {
    //   formParams.period = DateUtils.momentToStr(period, 'YYYY-MM');
    // }

    return formParams;
  };

  const period = [
    DateUtils.monthFirstDay(), 
    DateUtils.monthLastDay(),
  ];

  const exportReport = () => {
    const formParams = getValidTableParams();
    const ids = table?.getSelectedRowKeys();

    FormUtil.exportExcelFile(
      '/input/report/exportPurchaseReport', 
      {
        ...formParams,
        ids,
      }
    );
  };

  const columns: ColumnProps[] = [
    { key: 'supplierName', title: '供应商名称', width: 180, fixed: 'left' },
    { key: 'period', title: 'ERP 会计期间', width: 150 },
    { key: 'module', title: '模块', width: 150 },
    { key: 'billNo', title: '报销单号', width: 180 },
    { key: 'voucherNumber', title: '日记账凭证号', width: 180 },
    { key: 'orderNo', title: '单据序列', width: 180 },
    { key: 'physicalInvoiceNo', title: '实物发票号', width: 180 },
    { key: 'billingParty', title: '开票方', width: 180 },
    { key: 'rzTime', title: '勾选日期', width: 150 },
    { key: 'rzPeriod', title: '认证期间', width: 150 },
    { key: 'amount', title: '不含税金额', width: 150, align: 'right' },
    { key: 'taxRate', title: '税率', width: 150, align: 'right' },
    { key: 'taxAmount', title: '税额', width: 150, align: 'right' },
    { key: 'withTaxAmount', title: '价税合计金额', width: 150, align: 'right' },
    { key: 'entryUnit', title: '入账单位', width: 200, align: 'center' },
    { key: 'entryDate', title: '入账日期', width: 150 },
    { key: 'expenseAccounting', title: '费用会计', width: 150 },
    { key: 'invoiceCode', title: '已认证发票代码', width: 180 },
    { key: 'invoiceNo', title: '已认证发票号码', width: 180 },
    { key: 'sellerName', title: '已认证开票方', width: 150 },
    { key: 'invoiceAmount', title: '已认证不含税金额', width: 150, align: 'right' },
    { key: 'validTaxAmount', title: '已认证税额', width: 150, align: 'right' },
    { key: 'amountDiff', title: '金额差异', width: 110, align: 'right', fixed: 'right' },
    { key: 'taxAmountDiff', title: '税额差异', width: 110, align: 'right', fixed: 'right' },
  ];

  const setTableSummaryData = ({
    totalInvoiceAmount,
    totalValidTaxAmount,
    totalWithTaxAmount,
    totalAmountDiff,
    totalTaxAmountDiff

  }: JSONObject) => {

    setTotalInvoiceAmount(totalInvoiceAmount);
    setTotalValidTaxAmount(totalValidTaxAmount);
    setTotalWithTaxAmount(totalWithTaxAmount);
    setTotalAmountDiff(totalAmountDiff);
    setTotalTaxAmountDiff(totalTaxAmountDiff);
  };

  const tableSummary = () => {
    return (
      <>
        <TableSummaryRow>
          <Col span={5}>
            合计已认证不含税金额: {Math.num2money(totalInvoiceAmount)}
          </Col>
          <Col span={4}>
            合计已认证税额: {Math.num2money(totalValidTaxAmount)}
          </Col>
          <Col span={4}>
            合计价税金额: {Math.num2money(totalWithTaxAmount)}
          </Col>
          <Col span={4}>
            合计金额差异: {Math.num2money(totalAmountDiff)}
          </Col>
          <Col span={4}>
            合计税额差异: {Math.num2money(totalTaxAmountDiff)}
          </Col>
        </TableSummaryRow>
     </> 
    );
  };

  return (
    <Page>
      <Card 
        title="进项发票税会差异跟踪表"
        actions={
          <Space>
            <Button
              type="primary"
              onClick={exportReport}
            >
              导出
            </Button>
          </Space>
        }
      >
        <Table
          onTableRef={onTableRef}
          url="/input/report/inputPurchaseReport"
          queryProps={{
            initialValues: {
              period
            }
          }}
          // dateFormat={{
          //   period: 'YYYY-MM',
          // }}
          columns={columns}
          summary={tableSummary}
          setTableSummaryData={setTableSummaryData}
          scroll={{ y: window.innerHeight - 300 }}
        >
          <Item name="invoiceNo">
            <Input placeholder="发票号码" />
          </Item>
          {/* <Item name="period">
            <DatePicker 
              placeholder="会计期间" 
              picker="month"
              style={{ width: '100%' }} 
            />
          </Item> */}
          <Item name="period">
            <RangePicker 
              placeholder={['会计日期从', '会计日期至']} 
              format="YYYY-MM"
              picker="month"
              style={{ width: '100%' }}
              inputReadOnly
            />
          </Item>
          <Item name="billNo">
            <Input placeholder="报销单号" />
          </Item>
          <Item name="supplierName">
            <Input placeholder="供应商名称" />
          </Item>
          <Item name="physicalInvoiceNo">
            <Input placeholder="实物发票号" />
          </Item>
          <Item name="voucherNumber">
            <Input placeholder="日记账凭证号" />
          </Item>
        </Table>
      </Card>
    </Page>
  )
};

export default IncomeDetail;