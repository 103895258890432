import React, { FC, useState, useEffect } from 'react';
import { Http } from '@utils';
import Select, { Props as SelectProps } from '../Select';

export interface Prop extends SelectProps<string> {
  KVList?:(list:JSONObject[]) => void,
  lookupType: string,
  isKey?: boolean,
  showOptionTitle?: boolean,
  isFilter?: boolean,
  setOption?: (value: string) => void,
}

const { Option } = Select

const Lookup: FC<Prop> = ({
  KVList,
  showOptionTitle = false,
  isFilter = false,
  isKey = true,
  lookupType,
  setOption,
  ...resetProps
}: Prop) => {
  const [kvList, setKvList] = useState<KV[]>([]);
  const [value, setValue] = React.useState();

  const filterOption = (input: any, option: any) => {
    return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
  };

  const handleSelectChange = (option: any) => {
    setValue(option);
    setOption && setOption(option)
  };

  useEffect(() => {
    ((async () => {
      const kvs = await Http.get<KV[]>('/sys/lookups/kv', {
        lookupType,
      });
      setKvList(kvs);
      KVList && KVList(kvs)
    })());
  }, [lookupType]);
  return isFilter ? (<Select {...resetProps}
    value={value}
    showSearch
    filterOption={filterOption}
    onChange={handleSelectChange}
    >
      {
        kvList.map((kv) => {
          return (
            <Option key={kv.k}
                    label={kv.v}
                    value={isKey ? kv.k : kv.v}
                    title={showOptionTitle ? kv.v : ''}>{kv.v}
            </Option>
          )
        })
      }
    </Select>) : (<Select {...resetProps} options={kvList.map(kv => ({ label: kv.v, value: isKey ? kv.k : kv.v, title: showOptionTitle ? kv.v : '' }))} />);
};

export default Lookup;
