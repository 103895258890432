import React, { useEffect, useState, useRef, FC } from 'react';
import { Form, Input, Row, Col, InputNumber, Space, Select, Checkbox, Divider } from 'antd';

import { Page, Table, Card, DisplayInput, Modal, Button, PermissionButton, Lookup } from '@comps';
import TitleMgr from './addOrEditTitle.md';

import { Http, Context, Message, FormUtil } from '@utils';
import { ColumnProps } from '@comps/types';

import './index.css';

const { Item } = Form;
const { Option } = Select;

const CompanyDetail: FC = (props: any) => {
  const [id, setId] = useState(-1);
  const orgId = Context.getPathVar(props);
  const [payType, setPayType] = useState(0);
  const originBaseInfo = useRef<JSONObject>({});

  const [isRequiredIncomePassword, setIsRequiredIncomePassword] = useState(true);
  const [baseIsEdit, setBaseIsEdit] = useState(false);

  const [infoForm] = Form.useForm();
  const [userForm] = Form.useForm();
  const [inputForm] = Form.useForm();
  const [voucherForm] = Form.useForm();
  const [mailForm] = Form.useForm();
  const [propsForm] = Form.useForm();
  const [finaForm] = Form.useForm();

  const [inputSaveLoading, setInputSaveLoading] = useState(false);

  const [model, modalHolder] = Modal.useModal();

  let [titleTable, setTitleTable] = React.useState<Table>();

  useEffect(() => {
    getDetailInfo();
    // initVoucherProps(1)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getSubjectInfo = () => {
    Http.get(`/input/subject/get/${orgId}`)
      .then(data => {
        inputForm.setFieldsValue(data);
      })
  };

  const getDetailInfo = () => {
    Http.get(`/org/orgs/${orgId}`)
      .then(data => {
        data.gxInterfaceType = data.gxInterfaceType + '';
        data.invoiceType = data.invoiceType + '';

        FormUtil.booleanToNum(data, [
          'calMergerPriceOrQuantity', 
          'calSplitPriceOrQuantity'
        ]);
        
        infoForm.setFieldsValue(data);
        setId(data.id);
        finaForm.setFieldsValue(data);
        userForm.setFieldsValue(data);
        propsForm.setFieldsValue(data);
        inputForm.setFieldsValue(data);
        getSubjectInfo();

        setPayType(data.sfMonthlyType);
        mailForm.setFieldsValue(data);
        propsForm.setFieldsValue({
          mergeProject: !data.mergeProject,
          mergeOrderNo: !data.mergeOrderNo,
          mergeRequestNo: !data.mergeRequestNo,
          mergeRemark: !data.mergeRemark
        });
    })
  };

  const onTitleTableRef = (tableRef: Table) => {
    titleTable = tableRef;
    setTitleTable(tableRef);
  };

  /**
   * 保存开票人信息
   */
  const saveInvoiceUserInfo = () => {
    userForm.validateFields()
      .then(values => {
        Http.put('/org/orgs/invoiceUsers', { ...values, id: orgId })
          .then(() => {
            Message.saveOk();
          })
    })
  };

  const saveInputConfigurationInfo = () => {
    inputForm.validateFields()
      .then(values => {
        setInputSaveLoading(true);

        Promise.all([
          Http.put('/org/orgs/inputInfo', { ...values, id: orgId }),
          Http.post('/input/subject', { ...values, orgId }),
        ])
          .then(() => {
            Message.saveOk();
          })
          .finally(() => {
            setInputSaveLoading(false);
          })
    })
  };

  const saveMailConfigurationInfo = () => {
    mailForm.validateFields()
      .then(values => {
        Http.put('/org/orgs/mailingInfo', { ...values, id: orgId })
          .then(res => {
            Message.saveOk()
          })
    })
  };

  const saveFinancialInfo = () => {
    finaForm.validateFields()
      .then(values => {
        Http.put(`/org/orgs/updateBaseDataSource?id=${id}&baseDataSource=${values.baseDataSource}`)
          .then(res => {
            Message.saveOk()
          })
    })
  };

  /**
   * 保存开票属性
   */
  const saveInvoiceProps = () => {
    propsForm.validateFields()
      .then(values => {
        values.invoiceType = +(values.invoiceType);
        Http.put('/org/orgs/invoiceProps', {
          ...values, 
          id: orgId,
          mergeProject: !values.mergeProject,
          mergeOrderNo: !values.mergeOrderNo,
          mergeRequestNo: !values.mergeRequestNo,
          mergeRemark: !values.mergeRemark,
        })
        .then(res => {
          Message.saveOk();
        })
    })
  };

  /**
   * 新增或更新发票抬头
   * @param title
   */
  const handleTitleAddOrEdit = async (title?: JSONObject) => {
    await model.open({
      width: '600px',
      content(resolve, reject) {
        return (
          <TitleMgr
            onConfirm={resolve}
            onCancel={reject}
            title={title}
          />
        );
      },
    });

    titleTable?.refresh();
  };

  const handleBaseUpdate = () => {
    originBaseInfo.current = infoForm.getFieldsValue();
    setBaseIsEdit(true);
  };

  const handleBaseCancel = () => {
    infoForm.setFieldsValue(originBaseInfo.current);
    setBaseIsEdit(false);
  };

  const handleBaseSave = async () => {
    await infoForm.validateFields();
    const baseInfo = infoForm.getFieldsValue();
    await Http.put('/org/orgs', { id, ...baseInfo });

    setBaseIsEdit(false);
  };

  const initVoucherProps = (bizType: number) => {
    Http.get('/input/subject/get', { bizType, orgId, type: 1 })
      .then(data => {
        if (data.length === 0) {
          voucherForm.resetFields();
          voucherForm.setFieldsValue({ bizType });
        } else {
          const fieldsValue = data[0]
          fieldsValue.subjectCode2 = data[1].subjectCode;
          fieldsValue.subjectName2 = data[1].subjectName;
          fieldsValue.direction2 = data[1].direction;

          voucherForm.setFieldsValue(fieldsValue);
        }
      })
  };

  const handleChangePayType = (type: any) => {
    setPayType(type);
  };

  const handleInterfaceTypeChange = (val: any) => {
    const isRequired = val !== '0';
    setIsRequiredIncomePassword(isRequired);
  };

  const headerColumns: ColumnProps[] = [
    { key: 'defaultFlag', title: '默认抬头', width: 80,
      render: (value) => (value ? '是' : '否')
    },
    { key: 'bankName', title: '开户行', width: 200, },
    { key: 'accountNo', title: '银行账号', width: 200, },
    { key: 'tel', title: '电话', width: 150, },
    { key: 'address', title: '地址', width: 250, },
    {
      key: 'id', title: '操作',
      render: (id, row) => (
        <Button type='link' onClick={() => { handleTitleAddOrEdit(row) }}>修改</Button>
      )
    }
  ];

  return (
    <Page>
      {modalHolder}
      <Card
        title="基本信息"
        actions={
          baseIsEdit
            ? <Space>
                <PermissionButton
                  permissions="Org.update"
                  clickCallback={handleBaseSave}
                >
                  保存
                </PermissionButton>
                <PermissionButton
                  permissions="Org.update"
                  onClick={handleBaseCancel}
                >
                  取消
                </PermissionButton>
              </Space>
            : <PermissionButton
                permissions="Org.update"
                onClick={handleBaseUpdate}
              >
                修改
              </PermissionButton>
        }
      >
        <Form
          name="org"
          form={infoForm}
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 16 }}
        >
          <Row>
            <Col span={10}>
              <Item
                label="公司名称"
                name="orgName"
                rules={[{ required: baseIsEdit, message: '请输入公司名称' }]}
              >
                {baseIsEdit ? (<Input />) : (<DisplayInput />)}
              </Item>
            </Col>
            <Col span={8}>
              <Item
                label="税号"
                name="taxNo"
                rules={[{ required: baseIsEdit, message: '请输入税号' }]}
              >
                {baseIsEdit ? (<Input />) : (<DisplayInput />)}
              </Item>
            </Col>
            <Col span={6}>
              <Item
                label="编码"
                name="orgCode"
                rules={[{ required: baseIsEdit, message: '请输入编码' }]}
              >
                {baseIsEdit ? (<Input />) : (<DisplayInput />)}
              </Item>
            </Col>
          </Row>
        </Form>
      </Card>
      <Card
        title='财务信息获取'
        actions={
          <Button type='primary' onClick={saveFinancialInfo}>
            保存
          </Button>
        }
      >
        <Form
          name="org"
          form={finaForm}
          labelCol={{ span: 10 }}
          wrapperCol={{ span: 28 }}
        >
          <Row>
            <Col span={8}>
              <Item name="baseDataSource" label="基础数据获取方式">
                <Select
                  placeholder="请选择"
                  style={{ width: 160 }}
                >
                  <Option value='1'>ERP获取</Option>
                  <Option value='2'>导入</Option>
                </Select>
              </Item>
            </Col>
          </Row>
        </Form>
      </Card>
      <Card
        title="发票抬头"
        actions={
          <Button
            type="primary"
            onClick={() => { handleTitleAddOrEdit({ orgId: orgId }) }}
          >
            新增
          </Button>
        }
      >
        <Table
          url={`/org/orgs/${orgId}/invoiceTitles`}
          onTableRef={onTitleTableRef}
          columns={headerColumns}>
        </Table>
      </Card>
      <Card
        title="开票设置"
        actions={
          <Button
            type="primary"
            onClick={saveInvoiceProps}
          >
            保存
          </Button>
        }
      >
        <Form
          name="org"
          form={propsForm}
          labelCol={{ span: 11 }}
          wrapperCol={{ span: 13 }}
        >
          <Divider orientation="center" plain>
            待开单据配置
          </Divider>
          <Row>
            <Col span={6}>
              <Item
                label="电子专票限额"
                name="electricitySpecialAmtLimit"
                rules={[{ required: true }]}
              >
                <Input type="number" />
              </Item>
            </Col>
            <Col span={6}>
              <Item
                label="专票限额"
                name="invoiceAmtLimit"
                rules={[{ required: true }]}
              >
                <Input type="number" />
              </Item>
            </Col>
            <Col span={6}>
              <Item
                label="普票限额"
                name="normalInvoiceAmtLimit"
                rules={[{ required: true }]}
              >
                <Input type="number" />
              </Item>
            </Col>
            <Col span={6}>
              <Item 
                label="合并默认单价不变" 
                name="priceSameFlag"
                valuePropName="checked"
              >
                <Checkbox />
              </Item>
            </Col>
            <Col span={6}>
              <Item
                label="电子普票限额"
                name="electricityInvoiceAmtLimit"
              >
                <Input type="number" />
              </Item>
            </Col>
            <Col span={6}>
              <Item
                name="invoiceLineLimit"
                label="行数量限制"
                rules={[{ required: true }]}
              >
                <InputNumber 
                  type="number" 
                  style={{ width: '100%' }}
                  precision={0} 
                />
              </Item>
            </Col>
            <Col span={6}>
              <Item 
                label="待开单据合并规则" 
                name="calMergerPriceOrQuantity"
              >
                <Select>
                  <Option value={1}>数量重算</Option>
                  <Option value={0}>单价重算</Option>
                </Select>
              </Item>
            </Col>
            <Col span={6}>
              <Item 
                label="待开单据拆分规则" 
                name="calSplitPriceOrQuantity"
              >
                <Select>
                  <Option value={1}>数量重算</Option>
                  <Option value={0}>单价重算</Option>
                </Select>
              </Item>
            </Col>
            <Col span={6}>
              <Item 
                label="进项公司代码" 
                name="inputOrgCode"
              >
                <Input/>
              </Item>
            </Col>
            <Col span={6}>
              <Item 
                label="进项公司类型" 
                name="inputOrgType"
              >
                <Select>
                  <Option value={0}>借方</Option>
                  <Option value={1}>贷方</Option>
                </Select>
              </Item>
            </Col>
          </Row>
          <br />
          <Divider orientation="center" plain>
            批量开票申请配置
          </Divider>
          <Row>
            <Col span={8}>
              <Item 
                label="合并规则" 
                name="mergeRule"
                labelCol={{ span: 7 }}
                wrapperCol={{ span: 17 }}
              >
                <Select>
                  <Option value={1}>开票商品名称、规格型号、单位、单价</Option>
                  <Option value={2}>开票商品名称、规格型号、单价</Option>
                  <Option value={3}>开票商品名称、单位、单价</Option>
                  <Option value={4}>开票商品名称、单价</Option>
                  <Option value={5}>开票商品名称、规格型号、单位</Option>
                  <Option value={6}>开票商品名称、规格型号</Option>
                  <Option value={7}>开票商品名称、单位</Option>
                  <Option value={8}>开票商品名称</Option>
                </Select>
              </Item>
            </Col>
            <Col span={5}>
              <Item 
                label="开票类型" 
                name="invoiceType"
                labelCol={{ span: 9 }}
                wrapperCol={{ span: 10 }}
              >
                <Lookup lookupType='TAX_INVOICE_TYPE' />
              </Item>
            </Col>
            <Col span={5}>
              <Item 
                label="单价合并精度" 
                name="mergePrecision"
                labelCol={{ span: 12 }}
                wrapperCol={{ span: 14 }}
              >
                <InputNumber 
                  min={0}
                  max={8}
                  style={{ width: '100%' }}
                />
              </Item>
            </Col>
            <Col span={6}>
              <Item 
                label="规格型号为空" 
                name="specIsBlank"
                valuePropName="checked"
                labelCol={{ span: 11 }}
                wrapperCol={{ span: 10 }}
              >
                <Checkbox />
              </Item>
            </Col>
          </Row>
        </Form>
      </Card>
      <Card
        title="开票人信息"
        actions={
          <Button
            type="primary"
            onClick={saveInvoiceUserInfo}
          >
            保存
          </Button>
        }
      >
        <Form
          name="org"
          form={userForm}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          initialValues={{ invoicerType: 1 }}
        >
          <Row>
            <Col span={6}>
              <Item
                name="invoicer"
                label="开票人"
                rules={[{ required: true }]}
              >
                <Input />
              </Item>
            </Col>
            <Col span={6}>
              <Item
                name="invoicerType"
                label="开票人类型"
                rules={[{ required: true }]}
              >
                <Select> 
                  <Option value={1}>默认用户</Option>
                  <Option value={2}>登录用户</Option>
                  <Option value={3}>审批用户</Option>
                </Select>
              </Item>
            </Col>
            <Col span={6}>
              <Item name="cashier" label="收款人">
                <Input />
              </Item>
            </Col>
            <Col span={6}>
              <Item name="checker" label="复核人">
                <Input />
              </Item>
            </Col>
          </Row>
        </Form>
      </Card>
      <Card
        title="进项配置信息"
        actions={
          <Button 
            type="primary" 
            loading={inputSaveLoading}
            onClick={saveInputConfigurationInfo}
          >
            保存
          </Button>
        }
      >
        <Form
          name="org"
          form={inputForm}
          labelCol={{ span: 12 }}
          wrapperCol={{ span: 12 }}
        >
          <Row>
            <Col span={8}>
              <Item
                label="密码"
                name="rzSign"
                rules={[{ required: isRequiredIncomePassword }]}
              >
                <Input type="password" />
              </Item>
            </Col>
            <Col span={8}>
              <Item name="gxInterfaceType" label="接口类型" rules={[{ required: true }]}>
                <Lookup 
                  lookupType="GX_INTERFACE_TYPE"
                  placeholder="请选择" 
                  onChange={handleInterfaceTypeChange}
                />
              </Item>
            </Col>
            <Col span={8}>
              <Item
                label="账套 ID"
                name="account"
                rules={[{ required: true }]}
              >
                <Input />
              </Item>
            </Col>
            <Col span={8}>
              <Item
                label="应交税额-销项税额科目"
                name="taxOutputSubject"
              >
                <Input />
              </Item>
            </Col>
            <Col span={8}>
              <Item
                label="应交税额-进项税额-转出税额"
                name="taxInputSubject"
              >
                <Input />
              </Item>
            </Col>
            <Col span={8}>
              <Item
                label="应交税费-待认证进项税"
                name="inputTaxCertified"
              >
                <Input />
              </Item>
            </Col>
            <Col span={8}>
              <Item
                label="应交税费-应交增值税-进项税额"
                name="inputTaxPayable"
              >
                <Input />
              </Item>
            </Col>
            <Col span={8}>
              <Item
                label="主营业务收入科目"
                name="mainIncomeSubject"
              >
                <Input />
              </Item>
            </Col>
            <Col span={8}>
              <Item
                label="其他业务收入科目"
                name="otherIncomeSubject"
              >
                <Input />
              </Item>
            </Col>
            <Col span={8}>
              <Item
                label="行业税负率"
                name="industryTaxRate"
              >
                <InputNumber style={{ width: '100%' }} />
              </Item>
            </Col>
            <Col span={8}>
              <Item
                label="容差率"
                name="toleranceValue"
              >
                <InputNumber style={{ width: '100%' }} />
              </Item>
            </Col>
          </Row>
        </Form>
      </Card>
      <Card
        title='邮寄参数'
        actions={
          <Button type='primary' onClick={saveMailConfigurationInfo}>
            保存
          </Button>
        }
      >
        <Form
          name="org"
          form={mailForm}
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 28 }}
        >
          <Row>
            <Col span={8}>
              <Item name="sfMonthlyType" label="支付方式">
                <Select
                  placeholder="请选择"
                  onChange={handleChangePayType}
                  style={{ width: 160 }}
                >
                  <Option value={1}>月结</Option>
                  <Option value={2}>现结</Option>
                </Select>
              </Item>
            </Col>
            <Col span={8}>
              <Item name="sfMonthlyCard" label="月结号">
                <Input disabled={payType === 2} />
              </Item>
            </Col>
            <Col span={8}>
              <Item name="sfCollectEmpCode" label="揽收员工号">
                <Input />
              </Item>
            </Col>
          </Row>
        </Form>
      </Card>
    </Page>
  );
};

export { CompanyDetail };
