import React, { FC, useState, useEffect, memo } from 'react';
import { Form, Row, Col, Space, Input, InputNumber, Button, Checkbox } from 'antd';

import { Card, HiddenFormItem } from '@comps';

import { Http, Message } from '@utils';

const { Item } = Form;

interface Props {
  isAdd: boolean,
  rowObj: JSONObject,
  refreshTable: () => void,
  onClose: () => void
};

const AddOrEditMD: FC<Props> = memo(({
  isAdd,
  rowObj,
  refreshTable,
  onClose
}) => {

  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    form.setFieldsValue(rowObj);
  }, []);

  const handleAddOrEdit = () => {
    setLoading(true);

    const { enabled } = rowObj;
    const formData = form.getFieldsValue();
    let httpMethod = null;
    
    if (isAdd) {
      httpMethod = Http.post;
    } else {
      httpMethod = Http.put;
      formData.enabled = enabled;
    }

    httpMethod('/sys/sbu', formData)
      .then(() => {
        refreshTable();
        onClose();
        Message.success(isAdd ? '新增成功' : '修改成功');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Card title={ isAdd ? '新增' : '修改' }>
      <Form 
        form={form} 
        initialValues={{ 
          returnFlag: true,
          applyProgressPayment: true 
        }}
        labelCol={{ span: 8 }}
        style={{ marginTop: 20 }}
        onFinish={handleAddOrEdit}
      >
        <Row>
          <Col span={24}>
            <HiddenFormItem name="id" />
            <Item 
              label="上级 SBU" 
              name="superiorSbu" 
              rules={[{ required: true }]}
            >
              <Input />
            </Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Item 
              label="SBU 名称" 
              name="name" 
              rules={[{ required: true }]}
            >
              <Input />
            </Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Item 
              label="SBU 代码" 
              name="code" 
              rules={[{ required: true }]}
            >
              <Input />
            </Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Item 
              label="应收发票事务名称" 
              name="invRecTransactionName" 
            >
              <Input />
            </Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Item 
              label="红字应收发票事务名称" 
              name="redInvRecTransactionName" 
            >
              <Input />
            </Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Item 
              label="允许最大折扣金额比例" 
              name="discountRate" 
              rules={[{ required: true }]}
            >
              <InputNumber max={1} style={{ width: '100%' }} />
            </Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Item 
              label="预付款是否回写 ERP" 
              name="returnFlag" 
              valuePropName="checked"
            >
              <Checkbox />
            </Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Item 
              label="预付款未勾稽可开进度款" 
              name="applyProgressPayment" 
              valuePropName="checked"
            >
              <Checkbox />
            </Item>
          </Col>
        </Row>
        <Row>
          <Col span={24} style={{ textAlign: 'center' }}>
            <Space>
              <Button 
                type="primary" 
                loading={loading}
                htmlType="submit"
              >
                确定
              </Button>
              <Button onClick={onClose}>取消</Button>
            </Space>
          </Col>
        </Row>
      </Form>
    </Card>
  );
});

export default AddOrEditMD;