import * as React from "react";
import { Scrollbars } from "react-custom-scrollbars";
import cname from "classnames";
import styles from "./index.module.scss";
import { Button } from "antd";

export interface Props
  extends React.Props<HTMLElement>,
    React.BaseHTMLAttributes<HTMLElement> {
  invoice: JSONObject;
  onClose: () => void;
}

const InvoicePreview: React.FC<Props> = ({ invoice, onClose }: Props) => {
  const renderEmptyRow = () => {
    const invoiceRowNum = (invoice.lines || []).length;

    if (invoiceRowNum < 10) {
      const emptyRowNum = 10 - invoiceRowNum;
      const emptyRowArr = [];

      for (let i = 0; i < emptyRowNum; i++) {
        emptyRowArr.push(i);
      }

      return emptyRowArr.map((index) => (
        <div
          key={index}
          className={cname(styles.tableRow)}
          style={{
            display: "flex",
            height: 30,
            maxHeight: 30,
          }}
        >
          <div style={{ textAlign: "left", width: "30.3%" }} />
          <div style={{ textAlign: "right", width: "10.1%" }} />
          <div style={{ textAlign: "center", width: "9.6%" }} />
          <div style={{ textAlign: "right", width: "10%" }} />
          <div style={{ textAlign: "right", width: "10%" }} />
          <div style={{ textAlign: "right", width: "10%" }} />
          <div style={{ textAlign: "right", width: "10%" }} />
          <div style={{ textAlign: "right", width: "10%" }} />
        </div>
      ));
    }
  };

  return (
    <>
      <div className={styles.invoice}>
        <nav className={cname(styles.header, styles.titleText)}>
          <span className={cname(styles.text)}>{invoice.invoiceTypeDesc}</span>
          <div className={cname(styles.meta)}>
            <div className={cname(styles.line)}>
              <div className={cname(styles.label)}>
                <span>发</span>
                <span>票</span>
                <span>号</span>
                <span>码</span>
              </div>
              <div className={cname(styles.value)}>{invoice.invoiceNo}</div>
            </div>
            <div className={cname(styles.line)}>
              <div className={cname(styles.label)}>
                <span>开</span>
                <span>票</span>
                <span>日</span>
                <span>期</span>
              </div>
              <div className={cname(styles.value)}>{invoice.invoiceDate}</div>
            </div>
          </div>
        </nav>
        <section className={styles.body}>
          <div className={cname(styles.row, styles.infos)}>
            <div className={cname(styles.group, styles.titleText, styles.cell)}>
              <span>购</span>
              <span>买</span>
              <span>方</span>
              <span>信</span>
              <span>息</span>
            </div>
            <div
              className={cname(styles.dataset, styles.cell)}
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-around",
              }}
            >
              <div className={cname(styles.line)}>
                <div className={cname(styles.label)}>
                  <span>名</span>
                  <span>称</span>
                </div>
                <div className={cname(styles.value)}>{invoice.buyerName}</div>
              </div>
              <div className={cname(styles.line)}>
                <div className={cname(styles.label)}>
                  统一社会信用代码/纳税人识别号
                </div>
                <div className={cname(styles.value)}>{invoice.buyerTaxNo}</div>
              </div>
            </div>
            <div className={cname(styles.group, styles.titleText, styles.cell)}>
              <span>销</span>
              <span>售</span>
              <span>方</span>
              <span>信</span>
              <span>息</span>
            </div>
            <div
              className={cname(styles.dataset, styles.cell)}
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-around",
              }}
            >
              <div className={cname(styles.line)}>
                <div className={cname(styles.label)}>
                  <span>名</span>
                  <span>称</span>
                </div>
                <div className={cname(styles.value)}>{invoice.buyerName}</div>
              </div>
              <div className={cname(styles.line)}>
                <div className={cname(styles.label)}>
                  统一社会信用代码/纳税人识别号
                </div>
                <div className={cname(styles.value)}>{invoice.buyerTaxNo}</div>
              </div>
            </div>
          </div>
          <div className={cname(styles.table)}>
            <div
              className={cname(styles.thead)}
              style={{ display: "flex", height: 30 }}
            >
              <div
                className={cname(styles.titleText)}
                style={{ width: "30.3%" }}
              >
                项目名称
              </div>
              <div
                className={cname(styles.titleText)}
                style={{ width: "10.1%" }}
              >
                规格型号
              </div>
              <div
                className={cname(styles.titleText)}
                style={{ width: "9.6%" }}
              >
                单位
              </div>
              <div
                className={cname(styles.titleText)}
                style={{ width: "10%", textAlign: "right" }}
              >
                数量
              </div>
              <div
                className={cname(styles.titleText)}
                style={{ width: "10%", textAlign: "right" }}
              >
                单价
              </div>
              <div
                className={cname(styles.titleText)}
                style={{ width: "10%", textAlign: "right" }}
              >
                金额
              </div>
              <div className={cname(styles.titleText)} style={{ width: "10%" }}>
                税率/征收率
              </div>
              <div
                className={cname(styles.titleText)}
                style={{ width: "10%", textAlign: "right" }}
              >
                税额
              </div>
            </div>
            <Scrollbars style={{ height: 300 }} autoHide>
              {(invoice.lineList || []).map(
                (line: JSONObject, index: number) => (
                  <div
                    className={cname(styles.tableRow)}
                    style={{ display: "flex", minHeight: 30 }}
                    key={line.id}
                  >
                    <div
                      style={{
                        textAlign: "left",
                        width: "30.3%",
                        paddingLeft: 10,
                      }}
                    >{`${line.goodsName}`}</div>
                    <div
                      style={{
                        textAlign: "center",
                        width: "10.1%",
                        paddingBottom: 16,
                      }}
                    >
                      {line.spec}
                    </div>
                    <div style={{ textAlign: "center", width: "9.6%" }}>
                      {line.unit}
                    </div>
                    <div style={{ textAlign: "right", width: "10%" }}>
                      {line.quantity}
                    </div>
                    <div style={{ textAlign: "right", width: "10%" }}>
                      {line.price}
                    </div>
                    <div style={{ textAlign: "right", width: "10%" }}>
                      {line.withTaxAmount}
                    </div>
                    <div style={{ textAlign: "center", width: "10%" }}>
                      {line.taxRate}%
                    </div>
                    <div style={{ textAlign: "right", width: "10%" }}>
                      {line.taxAmount}
                    </div>
                  </div>
                )
              )}
              {renderEmptyRow()}
            </Scrollbars>
            <div className={cname(styles.tableRow)} style={{ display: "flex" }}>
              <div
                className={cname(styles.titleText, cname(styles.totalTitle))}
                style={{ width: "30.3%" }}
              >
                合计
              </div>
              <div style={{ width: "10.1%" }} />
              <div style={{ width: "9.6%" }} />
              <div style={{ width: "10%" }} />
              <div style={{ width: "10%" }} />
              <div style={{ width: "10%" }} />
              <div style={{ width: "10%" }}>¥{invoice.invoiceAmount}</div>
              <div style={{ width: "10%", textAlign: "right" }}>
                ¥{invoice.taxAmount}
              </div>
            </div>
          </div>
          <div
            className={cname(styles.row, styles.totalPriceAndTax)}
            style={{ height: "40px", lineHeight: "40px" }}
          >
            <div
              className={cname(styles.titleText, styles.cell)}
              style={{ textAlign: "center", width: "27%" }}
            >
              价税合计(大写)
            </div>
            <div className={cname(styles.grow, styles.cell, styles.data)}>
              <div className={cname(styles.row)}>
                <div style={{ width: "65%" }}>
                  {invoice.totalInvoiceAmountUpper}
                </div>
                <div style={{ width: "35" }}>
                  <span className={cname(styles.titleText)}>（小写）</span>¥
                  {invoice.totalAmount}
                </div>
              </div>
            </div>
          </div>
          <div className={cname(styles.row, styles.infos)}>
            <div className={cname(styles.group, styles.titleText, styles.cell)}>
              <span>备</span>
              <span>注</span>
            </div>
            <div className={cname(styles.dataset1, styles.cell)}>
              {invoice.remark}
            </div>
          </div>
        </section>
        <div className={cname(styles.footer)}>
          <div className={cname(styles.item)}>
            <div className={cname(styles.label)}>开&nbsp;票&nbsp;人</div>
            <div className={cname(styles.value)}>{invoice.invoicer}</div>
          </div>
        </div>
      </div>
      <div style={{ margin: " 0 32px 32px 0", textAlign: "right" }}>
        <Button onClick={onClose}>
          关闭
        </Button>
      </div>
    </>
  );
};

export default InvoicePreview;
