import React, { FC, useEffect, useState } from "react";
import { Card, Lookup } from "@comps";
import { DateUtils, Http } from "@utils";
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  message,
  Row,
  Space,
} from "antd";
import moment from "moment";

const Item = Form.Item;
const { RangePicker } = DatePicker;
const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 12 },
};

export interface Props {
  row: JSONObject;
  onCancel: () => void;
  isScanGun: boolean;
}

const AddOrEdit: FC<Props> = ({ row, onCancel, isScanGun = false }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [currentType, setCurrentType] = useState("");

  useEffect(() => {
    console.log(Object.keys(row).length, "============");
    Object.keys(row).length && setFormFieldsVal();
  }, []);

  useEffect(() => {
    if (isScanGun) {
      scanGun();
    }
  }, []);

  useEffect(() => {
    if (Object.keys(row).length === 0) {
      setCurrentType("01");
      // form.setFieldsValue({
      //   invKind: "01",
      // });
    } else {
      setCurrentType(row?.invoiceType);
      form.setFieldsValue({
        invKind: row?.invoiceType,
      });
    }
  }, []);

  const scanGun = () => {
    let code = "";
    let lastTime: any, nextTime;
    let lastCode: any, nextCode;
    window.addEventListener("keypress", function (e) {
      nextCode = e.which;
      nextTime = new Date().getTime();
      if (lastCode != null && lastTime != null && nextTime - lastTime <= 30) {
        code += String.fromCharCode(lastCode);
      } else if (
        lastCode != null &&
        lastTime != null &&
        nextTime - lastTime > 100
      ) {
        code = "";
      }
      lastCode = nextCode;
      lastTime = nextTime;
      if (e.which == 13) {
        let invoiceMsgArr = code.split(",");
        let partten = /(\d{4})(\d{2})(\d{2})/;
        let formateDate = invoiceMsgArr[5].replace(partten, "$1-$2-$3");
        if (code !== "") {
          form.setFieldsValue({
            invCode: invoiceMsgArr[2],
            invNo: invoiceMsgArr[3],
            invAmount: invoiceMsgArr[4],
            taxDate: moment(formateDate),
          });
        }
        code = "";
      }
    });
  };

  const setFormFieldsVal = () => {
    form.setFieldsValue({
      ...row,
      invoiceDate: row.invoiceDate ? moment(row.invoiceDate + "") : null,

      // checkCode: row.checkCode ? row.checkCode.substring(14,20) : ''
    });
  };

  const handleSave = async (isChecked: boolean) => {
    await form.validateFields();
    const formData = form.getFieldsValue();
    formData.invoiceDate = DateUtils.momentToStr(
      formData.invoiceDate,
      "YYYYMMDD"
    );
    // formData.invoiceType = undefined;

    // formData.invCodeNo = formData.invCode === undefined ? "_" + formData.invNo : formData.invCode + "_" + formData.invNo;
    if (currentType === "31" || currentType === "32") {
      formData.invoiceCode = undefined;
    }
    if (Object.keys(row).length) {
      const submitData = {
        id: row?.id,
        ...formData,
      };
      Http.post("/inputInvManagement/update", submitData).then((res) => {
        message.success("修改成功！");
        onCancel();
      });
    } else {
      setLoading(true);
      Http.post("/inputInvManagement/inputInv", {
        invoices: [{ ...formData }],
        isChecked,
      })
        .then(() => {
          onCancel();
          message.success("录入成功");
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };
  const handleChange = (val: string, option: any) => {
    setCurrentType(val);
  };

  return (
    <Card
      title={Object.keys(row).length ? "编辑" : "新增"}
      style={{ marginBottom: "0" }}
    >
      <Form {...layout} form={form}>
          <Row>
            <Col span={24}>
              <Item
                label="发票类型"
                name="invoiceType"
                rules={[{ required: true, message: "请选择发票类型" }]}
              >
                <Lookup
                  lookupType="INPUT_INVOICE_TYPE"
                  placeholder="请选择发票类型"
                  onChange={handleChange}
                />
              </Item>
            </Col>
          </Row>
        {!(
          currentType === "31" ||
          currentType === "32" ||
          currentType === "81" ||
          currentType === "82"
        ) && (
          <Row>
            <Col span={24}>
              <Item
                label="发票代码"
                name="invoiceCode"
                rules={[{ required: true, message: "请输入发票代码" }]}
              >
                <Input placeholder="请输入发票代码" autoFocus={isScanGun} />
              </Item>
            </Col>
          </Row>
        )}
        <Row>
          <Col span={24}>
            <Item
              label="发票号码"
              name="invoiceNo"
              rules={[{ required: true, message: "请输入发票号码" }]}
            >
              <Input placeholder="请输入发票号码" />
            </Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Item
              label="开票日期"
              name="invoiceDate"
              rules={[{ required: true, message: "请选择开票日期" }]}
            >
              <DatePicker
                format="YYYY-MM-DD"
                style={{ width: "100%" }}
                inputReadOnly
              />
            </Item>
          </Col>
        </Row>
        {(currentType === "01" ||
          currentType === "13" ||
          currentType === "03" ||
          currentType === "15") && (
          <Row>
            <Col span={24}>
              <Item
                label={currentType !== "15" ? "不含税金额" : "车价合计"}
                name="amount"
                rules={[
                  {
                    required: true,
                    message:
                      currentType !== "15"
                        ? "请输入不含税金额"
                        : "请输入车价合计",
                  },
                ]}
              >
                <InputNumber
                  placeholder={
                    currentType !== "15" ? "请输入不含税金额" : "请输入车价合计"
                  }
                  style={{ width: "100%" }}
                  formatter={(value) =>
                    `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  }
                  parser={(value) => value!.replace(/\$\s?|(,*)/g, "")}
                  step="0.01"
                />
              </Item>
            </Col>
          </Row>
        )}
        {(currentType === "04" || currentType === "10") && (
          <Row>
            <Col span={24}>
              <Item
                label="校验码后六位"
                name="checkCode"
                rules={[{ required: true, message: "请输入校验码后六位" }]}
              >
                <Input placeholder="请输入校验码后六位" maxLength={6} />
              </Item>
            </Col>
          </Row>
        )}
        {(currentType === "31" ||
          currentType === "32" ||
          currentType === "81" ||
          currentType === "82") && (
          <Row>
            <Col span={24}>
              <Item
                label="价税合计"
                name="withTaxAmount"
                rules={[{ required: true, message: "请输入价税合计" }]}
              >
                <InputNumber
                  placeholder="请输入价税合计"
                  style={{ width: "100%" }}
                  formatter={(value) =>
                    `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  }
                  parser={(value) => value!.replace(/\$\s?|(,*)/g, "")}
                  step="0.01"
                />
              </Item>
            </Col>
          </Row>
        )}
      </Form>
      <div style={{ textAlign: "center" }}>
        <Space>
          <Button type="primary" onClick={() => handleSave(false)}>
            保存
          </Button>
          {/* {!Object.keys(row).length ? (
            <Button
              type="primary"
              onClick={() => handleSave(true)}
              loading={loading}
            >
              保存并验伪
            </Button>
          ) : null} */}
          <Button onClick={onCancel}>取消</Button>
        </Space>
      </div>
    </Card>
  );
};

export default AddOrEdit;
