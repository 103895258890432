import {
  Button,
  Col,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Space,
} from "antd";
import React, { FC, memo, useEffect, useState } from "react";

import { Card, DisplayInput, HiddenFormItem } from "@comps";
import { Http, Message } from "@utils";

const { Item } = Form;

interface Props {
  rowData: JSONObject;
  refreshTable: () => void;
  onClose: () => void;
}

const EditRowMD: FC<Props> = memo(({ rowData, refreshTable, onClose }) => {
  const { id } = rowData;

  const [form] = Form.useForm();
  const [rates, setRates] = useState<KV[]>([]);
  const [taxCodes, setTaxCodes] = useState<KV[]>([]);
  const [policies, setPolicies] = useState<KV[]>([]);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    console.log(rowData);

    getTaxCodes();
  }, []);

  const getTaxCodes = async () => {
    form.setFieldsValue(rowData);

    Http.get<KV[]>("/tax/taxCodes/kv").then((data) => {
      // form.setFieldsValue(rowData)
    });
  };

  const handleQuantityChange = (quantity: any) => {
    const { price, withTaxPrice } = form.getFieldsValue();
    console.log(price);

    const amount = (price * quantity).toFixed(2);
    const withTaxAmount = (
      price *
      quantity *
      (1 + rowData.taxRate / 100)
    ).toFixed(2);
    // const withTaxPrice = +(withTaxAmount /quantity).toFixed(8);
    const taxAmount = (
      price * quantity * (1 + rowData.taxRate / 100) -
      price * quantity
    ).toFixed(2);
    form.setFieldsValue({
      amount,
      withTaxAmount,
      taxAmount,
    });
  };

  const handlePriceChange = (price: any) => {
    const { amount, withTaxAmount } = form.getFieldsValue();

    const quantity = +(amount / price).toFixed(6);
    const withTaxPrice = +(withTaxAmount / quantity).toFixed(8);

    form.setFieldsValue({
      quantity,
      withTaxPrice,
    });
  };

  const handleWithTaxPriceChange = (withTaxPrice: any) => {
    const { amount, withTaxAmount } = form.getFieldsValue();

    const quantity = +(withTaxAmount / withTaxPrice).toFixed(6);
    const price = +(amount / quantity).toFixed(8);

    form.setFieldsValue({
      quantity,
      price,
    });
  };

  const handleSave = () => {
    setLoading(true);

    const formData = form.getFieldsValue();

    Http.put("/tax/outputInvoices/lines", formData)
      .then(() => {
        refreshTable();
        onClose();
        Message.success("编辑成功");
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const handleQuantityValidator = (rule: any, value: any, callback: any) => {
    if (Number(value) > rowData.quantity) {
      callback("修改的数量不能大于原数量");
    }
    if (Number(value) < 1) {
      callback("修改的数量不能小于1");
    }
    callback();
  };

  return (
    <Card title="修改">
      <Form
        form={form}
        onFinish={handleSave}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 13 }}
      >
        <Row>
          <Col span={12}>
            <HiddenFormItem name="id" />
            <Item
              name="goodsName"
              label="开票商品名称"
              rules={[{ required: true }]}
            >
              <DisplayInput />
            </Item>
          </Col>
          <Col span={12}>
            <Item
              name="taxCode"
              label="税收分类编码"
              rules={[{ required: true }]}
            >
              <Select
                disabled
                showSearch
                optionFilterProp="label"
                options={taxCodes.map(({ k, v }) => ({
                  label: v,
                  value: k,
                  title: v,
                }))}
              />
            </Item>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <Item label="规格型号" name="spec">
              <Input />
            </Item>
          </Col>
          <Col span={12}>
            <Item label="单位" name="unit">
              <Input />
            </Item>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <Item
              label="含税单价"
              name="withTaxPrice"
              rules={[{ required: true }]}
            >
              <DisplayInput />
              {/* <InputNumber 
              style={{ width: '100%' }} 
              onChange={handleWithTaxPriceChange}
            /> */}
            </Item>
          </Col>
          <Col span={12}>
            <Item label="不含税单价" name="price" rules={[{ required: true }]}>
              <DisplayInput />
              {/* <InputNumber 
                style={{ width: '100%' }} 
                onChange={handlePriceChange}
              /> */}
            </Item>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <Item
              label="数量"
              name="quantity"
              rules={[
                { required: true },
                {
                  validator: (rule, value, callback) => {
                    handleQuantityValidator(rule, value, callback);
                  },
                },
              ]}
            >
              <InputNumber
                min={1}
                style={{ width: "100%" }}
                onChange={handleQuantityChange}
              />
            </Item>
          </Col>
          <Col span={12}>
            <Item label="税额" name="taxAmount">
              <DisplayInput />
            </Item>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <Item label="含税金额" name="withTaxAmount">
              <DisplayInput />
            </Item>
          </Col>
          <Col span={12}>
            <Item label="不含税金额" name="amount">
              <DisplayInput />
            </Item>
          </Col>
        </Row>
        <Row>
          <Col
            span={24}
            style={{ marginTop: 14, marginBottom: 14, textAlign: "center" }}
          >
            <Space size="large">
              <Button loading={loading} type="primary" htmlType="submit">
                确定
              </Button>
              <Button onClick={onClose}>取消</Button>
            </Space>
          </Col>
        </Row>
      </Form>
    </Card>
  );
});

export default EditRowMD;
