let numeral = require("numeral");

/**
 * 数学相关工具类
 */

/**
 * 小数转百分数
 * @param percent 小数
 */
const num2Percent = (percent: number): string => {
  return percent * 100 + "%";
};

/**
 * 小数转百分数，默认不保留小数位
 * @param percent 小数
 */
const num2Percent2 = (percent: number, point: number = 0): string => {
  return (percent * 100).toFixed(point) + "%";
};

/**
 * 数字转金额(带千分位)
 * @param num 数字
 */
const num2money = (num: number): string => {
  return numeral(num).format("0,0.00");
};

/**
 * 数字带千分位
 * @param num 数字
 */
const numThousandth = (num: number): string => {
  return numeral(num).format("0,0");
};

/**
 * 数字带千分位，并保留小数位
 * @param num 数字
 * @param dig 保留位数
 */
const numToThousandth = (num: number, dig: number): string => {
  const _list: any[] = num.toString().split(".");
  _list[0] = Number(_list[0]).toLocaleString();
  if (_list.length === 2) {
    // 先四舍五入，再截取
    _list[1] = (savePoint(Number(_list[1]) / dig / 100, dig) * dig * 100)
      .toString()
      .substring(0, dig);
  }
  return _list.join(".");
};

/**
 * 带千分位的数字转数字，并保留小数位
 * @param num 数字
 * @param dig 保留位数
 */
const thousandToNum = (num: string, dig: number): number => {
  return savePoint(Number(num.replace(/,/g, "")), 2);
};

/**
 * 百分数(字符串)转小数
 * @param percent 百分数
 */
const percent2Point = (percent: string) => {
  return Number(percent.replace("%", "")) / 100;
};

/**
 * 保留小数位
 * @param num 数字
 * @param digit 小数位数
 */
const savePoint = (num: number, digit: number) => {
  num = Number(num);
  if (isNaN(num)) {
    return 0;
  }
  return Number(
    (
      Math.round((num + Number.EPSILON) * Math.pow(10, digit)) /
      Math.pow(10, digit)
    ).toFixed(digit)
  );
};

/**
 * 金额转大写
 * @param number 数字
 */
function convertToChinese(number: number) {
  const units = ["", "拾", "佰", "仟"];
  const digits = ["", "壹", "贰", "叁", "肆", "伍", "陆", "柒", "捌", "玖"];

  const convertSmallNumber = (num: number) => {
    let str = "";

    if (num < 10) {
      str = digits[num];
    } else if (num < 20) {
      str = "拾" + digits[num % 10];
    } else {
      const unit = Math.floor(Math.log10(num));
      str =
        digits[Math.floor(num / Math.pow(10, unit))] +
        units[unit] +
        convertSmallNumber(num % Math.pow(10, unit));
    }

    return str;
  };

  const convertBigNumber = (num: number) => {
    let str = "";
    const thousandUnits = ["", "万", "亿", "万亿"];

    if (num < 10000) {
      str = convertSmallNumber(num);
    } else {
      const unit = Math.floor(Math.log10(num) / 4);
      str =
        convertBigNumber(Math.floor(num / Math.pow(10000, unit))) +
        thousandUnits[unit] +
        convertBigNumber(num % Math.pow(10000, unit));
    }

    return str;
  };

  const currencyArray = ["圆", "角", "分"];
  const numArray = String(Math.abs(number)).split(".");
  let integerPart = parseInt(numArray[0]); // 整数部分
  let decimalPart = parseInt((numArray[1] || "0").padEnd(2, "0")); // 将小数部分转换为整数形式，并补齐两位

  let result = "";
  if (integerPart !== 0) {
    result = convertBigNumber(integerPart) + currencyArray[0];
  } else {
    result = "零" + currencyArray[0];
  }

  if (decimalPart !== 0) {
    let jiao = Math.floor(decimalPart / 10);
    let fen = decimalPart % 10;
    if (jiao !== 0) {
      result += digits[jiao] + currencyArray[1];
    }
    if (fen !== 0) {
      result += digits[fen] + currencyArray[2];
    }
  } else {
    result += "整";
  }

  if (number < 0) {
    result = "负" + result;
  }

  return result;
}

const generateRandomKey = () => {
  const randomKey = Math.floor(Math.random() * 10000);
  return randomKey;
};

const MathU = {
  num2Percent,
  num2money,
  numThousandth,
  numToThousandth,
  percent2Point,
  num2Percent2,
  savePoint,
  thousandToNum,
  convertToChinese,
  generateRandomKey,
};

export default MathU;
