import * as React from "react";
import {
  Form,
  DatePicker,
  Input,
  message,
  Space,
  Button,
  Steps,
  Divider,
  Result,
  Checkbox,
  Row,
} from "antd";
import {
  Page,
  Card,
  PermissionButton,
  Lookup,
  Table,
  LinkText,
  Modal,
} from "@comps";
import SelectTicket from "./component/selectTicket";
import { ColumnProps } from "@comps/types";
import { DateUtils, Http, Context, Env } from "@utils";
import DetailViewer from "./modal/detailViewer";
import _ from "lodash";
import { useState } from "react";
import Confirm from "./component/confirm";
import InvoiceResult from "./component/invoiceResult";

export interface Props {}

const InfoConfirm: React.FC<Props> = () => {
  const { Step } = Steps;
  const [modal, modalHolder] = Modal.useModal();
  const [current, setCurrent] = useState(0);
  const onStep = (step: any) => {
    // return (step:any)=>{
    //     console.log(step,'----')
    // }
    console.log(step, "000000000000000");
    setCurrent(step);
  };
  const handleTipsModal = (e: any) => {
    console.log(e);
  };
  const handleSubmit = (step: any) => {
    modal.open({
      width: 480,
      content(resolve, reject) {
        return (
          <Result
            title="温馨提示"
            extra={
              <div>
                <div style={{ marginBottom: "32px" }}>
                  尊敬的纳税人，您选择红字发票票种是“电子发票”，提交后系统将直接生成红字发票，请确认是否继续提交？
                  <Row justify="center" style={{ marginTop: "16px" }}>
                    <Checkbox onChange={handleTipsModal}>不再提醒</Checkbox>
                  </Row>
                </div>
                <Button
                  style={{ marginRight: "16px" }}
                  onClick={() => reject()}
                >
                  取消
                </Button>
                <Button
                  type="primary"
                  onClick={() => {
                    onsubmit();
                    reject();
                  }}
                >
                  确定
                </Button>
              </div>
            }
          />
        );
      },
    });
  };
  const onsubmit = () => {
    onStep(2);
  };
  return (
    <Page>
      {modalHolder}
      <Card title="红字发票确认信息" style={{ paddingBottom: "60px" }}>
        <Steps current={current}>
          <Step title="选择票据" />
          <Step title="信息确认" />
          <Step title="提交成功" />
        </Steps>
        <Divider />
        <div className="steps-content">
          {current === 0 ? (
            <SelectTicket handelStep={onStep} />
          ) : current === 1 ? (
            <Confirm handelStep={onStep} />
          ) : (
            <InvoiceResult handelStep={onStep} />
          )}
        </div>
        <div
          className="steps-action"
          style={{
            textAlign: "center",
            // backgroundColor:'#fff',
            width: "inherit",
            position: "fixed",
            bottom: 21,
            right: -170,
            left: 0,
          }}
        >
          {current === 1 && (
            <Button onClick={() => onStep(0)} style={{ marginRight: "5px" }}>
              上一步
            </Button>
          )}
          {current === 1 && (
            <Button type="primary" onClick={() => handleSubmit(2)}>
              提交
            </Button>
          )}
        </div>
      </Card>
    </Page>
  );
};

export default InfoConfirm;
