/**
 * 权限列表
 */
import React, { FC, useState, Fragment } from 'react'
import { Page, Table, Card, LinkText, Modal, DisplayInput } from '@comps'
import { ColumnProps } from '@comps/types'
import { Button, Space, Form, Input, Checkbox, Col, Row, Select } from 'antd'
import { Http, Message } from '@utils'

const Item = Form.Item

const requestMethodOptions = [
    { value: 'get', key: 'get' },
    { value: 'post', key: 'post' },
    { value: 'put', key: 'put' },
    { value: 'delete', key: 'delete' },
]

const typeOptions = [
    { value: 'public', key: 'public' },
    { value: 'protected', key: 'protected' },
    { value: 'private', key: 'private' },
    { value: 'internal', key: 'internal' },
]

const PermissionList: FC = () => {
    const [form] = Form.useForm()
    let [isModalVisible, setModalVisible] = useState(false)
    let [isVirtual, setIsVirtual] = useState(false)
    let [selectedId, setSelectedId] = useState(-1)
    let [table, setTable] = useState<Table>()

    let isAddMode = selectedId === -1

    const save = () => {
        form.validateFields().then(values => {
            if (selectedId < 0) {
                Http.post('/sys/permissions', values).then(data => {
                    Message.saveOk()
                    table?.refresh()
                })
            } else {
                Http.put('/sys/permissions', { ...values, id: selectedId}).then(data => {
                    Message.saveOk()
                    table?.refresh()
                })
            }
            setModalVisible(false)
            form.resetFields()
        })
    }

    async function onAddOrEditClick(id: number = -1, row?: any) {
        await setSelectedId(id)
        await setModalVisible(true)
        await setIsVirtual(row?.isVirtual)
        if (id > 0) {
            form.setFieldsValue({ ...row, isVirtual: row.isVirtual ? true : false })
        }
    }

    const onTableRef = (tableRef: Table) => {
        setTable(tableRef)
    }

    const onVirtualChanged = (e: any) => {
        let isVirtual = form.getFieldValue('isVirtual')
        setIsVirtual(isVirtual)
    }

    const columns: ColumnProps[] = [
        {
            key: 'permissionName',
            title: '权限名称',
            fixed: 'left',
        },
        {
            key: 'permissionCode',
            title: '权限编码',
            width: 220,
        },
        {
            key: 'requestMethod',
            title: '请求方式',
            width: 100,
            tag: { colors: { 'get': 'green', 'delete': 'red', 'post': 'orange', 'put': 'blue' } },
        },
        {
            key: 'requestUri',
            title: '请求URI',
            width: 220,
            tag: {},
        },
        {
            key: 'type',
            title: '权限类型',
            tag: { colors: { 'private': 'red', 'protected': 'blue', 'public': 'green' } },
            width: 100,
        },
        {
            key: 'isVirtual',
            title: '权限载体',
            render: (value: boolean, row, index) => (value ? '前端元素' : '后端接口'),
            width: 100,
        },
        {
            key: 'isAudit',
            title: '审计',
            render: (value: boolean, row, index) => (value ? '是' : '否'),
            width: 60,
        },
        {
            key: 'isSensitive',
            title: '敏感接口',
            render: (value: boolean, row, index) => (value ? '敏感' : '不敏感'),
            width: 100,
        },
        {
            key: 'isExpense',
            title: '计费',
            render: (value: boolean, row, index) => (value ? '是' : '否'),
            width: 60,
        },
        {
            key: 'id',
            title: '操作',
            render: (id, row, index) => (<LinkText label='修改' onClick={() => onAddOrEditClick(id, row)}></LinkText>),
        },
    ]
    return (
        <Page>
            <Card title='权限管理' actions={<Button type='primary' onClick={() => onAddOrEditClick()}>新增</Button>}>
                <Table url='/sys/permissions' columns={columns} onTableRef={onTableRef} scroll={{ y: window.innerHeight - 300 }} >
                    <Input name='permissionName' placeholder='权限名称' />
                    <Input name='permissionCode' placeholder='权限编码' />
                    <Input name='requestUri' placeholder='请求URI' />
                </Table>
            </Card>

            <Modal title={isAddMode ? '新增权限' : '修改权限'} visible={isModalVisible}
                width={800}
                onCancel={() => { form.resetFields(); setModalVisible(false); setSelectedId(-1) }}
                onOk={save}>
                <Form name='form' form={form}>
                    <Row>
                        {isAddMode && <Item name='isVirtual' label='虚拟权限' valuePropName='checked' required>
                            <Checkbox onChange={onVirtualChanged} />
                        </Item>}
                        {!isAddMode && <Item name='isVirtual' label='虚拟权限' ><DisplayInput /></Item>}
                    </Row>
                    <Row>
                        <Col span={11}>
                            <Item name='permissionName' label='权限名称' required><Input /></Item>
                            {!isVirtual &&
                                <Fragment>
                                    <Item name='requestMethod' label='请求方式' required>
                                        {isAddMode ? <Select options={requestMethodOptions} /> : <DisplayInput />}
                                    </Item>
                                    <Item name='type' label='权限类型' required>
                                        <Select options={typeOptions} />
                                    </Item>
                                </Fragment>
                            }
                        </Col>
                        <Col offset={1} span={11}>
                            <Item name='permissionCode' label='权限编码' required={isAddMode}><DisplayInput disabled={!isAddMode} /></Item>
                            {!isVirtual &&
                                <Item name='requestUri' label='请求URI' required={isAddMode}><DisplayInput disabled={!isAddMode} /></Item>
                            }
                        </Col>
                    </Row>
                    {!isVirtual &&
                        <Row>
                            <Space size='large'>
                                <Item name='isAudit' label='审计' valuePropName='checked' required><Checkbox /></Item>
                                <Item name='isSensitive' label='敏感接口' valuePropName='checked' required><Checkbox /></Item>
                                <Item name='isExpense' label='计费' valuePropName='checked' required><Checkbox /></Item>
                            </Space>
                        </Row>
                    }
                </Form>
            </Modal>
        </Page>
    )
}

export { PermissionList }