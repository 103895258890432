import { Card, Modal } from "@comps";
import { Http } from "@utils";
import { Button, Col, Form, Input, Row } from "antd";
import React, { FC } from "react";

interface Props {
  data: JSONObject;
  closeAllModal?: () => void;
  onClose: () => void;
  refersh?: () => void;
}

const Makesuretheapprove: FC<Props> = ({
  data,
  onClose,
  refersh,
  closeAllModal,
}) => {
  const [modal, modalHolder] = Modal.useModal();
  const [Loading, setLoading] = React.useState<boolean>(false);
  const desc = React.useRef<Input>(null);
  const [form] = Form.useForm();
  const approveCallBack = () => {
    modal.open({
      width: 500,
      content(resolve, reject) {
        return (
          <Card title="驳回原因">
            {modalHolder}
            <Row
              style={{ width: "70%", marginInline: "15%", marginBlock: "20px" }}
            >
              <Input placeholder="请输入驳回原因" ref={desc}></Input>
            </Row>
            <Row style={{ marginTop: 10 }}>
              <Col offset={8} span={3}>
                <Button
                  type="primary"
                  onClick={() => {
                    let params = [
                      {
                        approvalId: data.id,
                        approvalStatus: 2,
                        desc: desc.current?.state.value,
                      },
                    ];
                    Http.post(`/outputInvoiceApproval/approval`, params)
                      .then((res) => {
                        reject();
                        onClose();
                        closeAllModal && closeAllModal();
                        if (refersh) refersh();
                      })
                      .catch((error) => {
                        // 处理请求失败的逻辑
                        onClose();
                      })
                      .finally(() => {
                        onClose();
                      });
                  }}
                >
                  确定
                </Button>
              </Col>
              <Col offset={1} span={4}>
                <Button onClick={reject}>取消</Button>
              </Col>
            </Row>
          </Card>
        );
      },
    });
  };

  const approvethereq = () => {
    setLoading(true);

    let params = [
      {
        approvalId: data.id,
        approvalStatus: 1,
        desc: "",
      },
    ];
    Http.post(`/outputInvoiceApproval/approval`, params)
      .then((res) => {
        setLoading(false);
        if (refersh) refersh();
        onClose();
        closeAllModal && closeAllModal();
      })
      .finally(() => {
        setLoading(false);
        onClose();
      });
  };
  return (
    <Card title="审批">
      {modalHolder}
      <Form form={form} onFinish={approvethereq}>
        <Row
          style={{ lineHeight: "50px", paddingLeft: "20px", fontSize: "16px" }}
        >
          是否通过该审批流程
        </Row>
        <Row style={{ marginTop: 10 }}>
          <Col offset={5} span={3}>
            <Button type="primary" loading={Loading} htmlType="submit">
              确定
            </Button>
          </Col>
          <Col offset={3} span={3}>
            <Button
              onClick={() => {
                approveCallBack();
              }}
            >
              驳回
            </Button>
          </Col>
          <Col offset={3} span={7}>
            <Button onClick={onClose}>取消</Button>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};

export default Makesuretheapprove;
