import { RouteProps } from '@models';

import { authRoutes } from '../pages/auth/routes';
import { sysRoutes } from '../pages/sys/routes';
import { orgRoutes } from '../pages/org/routes';
import { taxRoutes } from '../pages/tax/routes';
import { incomeRoutes } from '../pages/income/routes';
import outputRoutes from 'pages/output/routes';
import reportRoutes from 'pages/report/routes';
import approvalRoutes from 'pages/approval/routes';
import taxKnowledgeRoutes from 'pages/taxKnowledge/routes';

/**
 * 组合应用所有路由信息
 */
const routes: RouteProps[] = [];
routes.push.apply(routes, authRoutes);
routes.push.apply(routes, sysRoutes);
routes.push.apply(routes, orgRoutes);
routes.push.apply(routes, taxRoutes);
routes.push.apply(routes, outputRoutes);
routes.push.apply(routes, incomeRoutes);
routes.push.apply(routes, reportRoutes);
routes.push.apply(routes, approvalRoutes);
routes.push.apply(routes, taxKnowledgeRoutes);


export { routes };
